import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';

type Props = {
  isLoggedIn: boolean;
} & RouteComponentProps;

/**
 * NoAccessRedirect fallback redirect wrapper
 *
 * When the user is not logged in - redirect the user back
 * to the oidc login route url and set the location state to the current location.
 * When the user is logged in - redirect to the error route with forbidden error code
 */
const NoAccessRedirect: React.FC<Props> = ({ location, isLoggedIn }) => {
  // User is not logged in - redirect to login
  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: routeFactory.login.getUrlPath(),
          state: routeFactory.login.getRouteState({
            location: location,
          }),
        }}
      />
    );
  }

  // User is logged in but does not have permissions to access
  return (
    <Redirect
      to={{
        pathname: routeFactory.forbidden.getUrlPath(),
        state: { from: location },
      }}
    />
  );
};

export default NoAccessRedirect;
