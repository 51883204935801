import { numericUtil, timeUtil } from '@cmg/common';
import { Theme, useMediaQuery } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import React, { useMemo } from 'react';

import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import {
  getFormattedCurrency,
  getFormattedCurrencyInMillion,
  getFormattedPercentageValue,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { CardRowItem, emptyValue, StructurePricingRowLabels } from '../../shared/types';

type DtcItem = { name: string; value: React.ReactNode; isHidden?: boolean };

const useGetDtc = (offeringProfile: OfferingProfile_ListQuery) => {
  const { attributes, atmAttributes, pricingCurrency, hasForwardAgreement } =
    offeringProfile.offeringById ?? {};
  const {
    marketCapPreOffering,
    pctMarketCapPreOffering,
    preOfferingAdtv,
    adtvRangeLowDate,
    adtvRangeHighDate,
    lastTradeBeforeFiling,
  } = attributes ?? {};
  const { lastTradeBeforeFilingSplitAdjusted } = atmAttributes || {};

  return useMemo(() => {
    return {
      [StructurePricingRowLabels.marketCapPre]: {
        name: StructurePricingRowLabels.marketCapPre,
        value: isNumber(marketCapPreOffering)
          ? getFormattedCurrencyInMillion(pricingCurrency, marketCapPreOffering)
          : emptyValue,
      },
      [StructurePricingRowLabels.marketCapPrePct]: {
        name: StructurePricingRowLabels.marketCapPrePct,
        value: isNumber(pctMarketCapPreOffering)
          ? getFormattedPercentageValue(pctMarketCapPreOffering)
          : emptyValue,
      },
      [StructurePricingRowLabels.lastTradeBeforeFiling]: {
        name: StructurePricingRowLabels.lastTradeBeforeFiling,
        value: getFormattedCurrency(pricingCurrency, lastTradeBeforeFiling),
      },
      [StructurePricingRowLabels.lastTradeBeforeFilingSplitAdjusted]: {
        name: StructurePricingRowLabels.lastTradeBeforeFilingSplitAdjusted,
        value: getFormattedCurrency(pricingCurrency, lastTradeBeforeFilingSplitAdjusted),
        isHidden:
          isNil(lastTradeBeforeFilingSplitAdjusted) ||
          lastTradeBeforeFiling === lastTradeBeforeFilingSplitAdjusted,
      },
      [StructurePricingRowLabels.thirtyDayADTV]: {
        name: StructurePricingRowLabels.thirtyDayADTV,
        value: numericUtil.getDisplayValueForInteger(preOfferingAdtv) ?? emptyValue,
      },
      [StructurePricingRowLabels.ADTVDateRange]: {
        name: StructurePricingRowLabels.ADTVDateRange,
        value:
          adtvRangeLowDate && adtvRangeHighDate
            ? `${timeUtil.formatAsMonthDateYear(
                adtvRangeLowDate
              )}\u2013${timeUtil.formatAsMonthDateYear(adtvRangeHighDate)}`
            : emptyValue,
      },
      [StructurePricingRowLabels.forwardAgreement]: {
        name: StructurePricingRowLabels.forwardAgreement,
        value: <BooleanIcon value={hasForwardAgreement} />,
      },
    };
  }, [
    adtvRangeHighDate,
    adtvRangeLowDate,
    hasForwardAgreement,
    lastTradeBeforeFiling,
    lastTradeBeforeFilingSplitAdjusted,
    marketCapPreOffering,
    pctMarketCapPreOffering,
    preOfferingAdtv,
    pricingCurrency,
  ]);
};

export type StructurePricingSectionData = {
  structurePricingRows: {
    structurePricingCol1: CardRowItem[];
    structurePricingCol2: CardRowItem[];
    structurePricingCol3: CardRowItem[];
  };
};

export const useGetAtmStructurePricingSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): StructurePricingSectionData => {
  const smallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down(1201));
  const mediumScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down(1491));
  const dtc = useGetDtc(offeringProfile);

  return React.useMemo(() => {
    if (smallScreen) {
      return {
        structurePricingRows: {
          structurePricingCol1: [
            dtc[StructurePricingRowLabels.marketCapPre],
            dtc[StructurePricingRowLabels.marketCapPrePct],
            dtc[StructurePricingRowLabels.lastTradeBeforeFiling],
            dtc[StructurePricingRowLabels.lastTradeBeforeFilingSplitAdjusted],
            dtc[StructurePricingRowLabels.thirtyDayADTV],
            dtc[StructurePricingRowLabels.ADTVDateRange],
            dtc[StructurePricingRowLabels.forwardAgreement],
          ].filter((row: DtcItem) => !row.isHidden),
          structurePricingCol2: [],
          structurePricingCol3: [],
        },
      };
    }
    if (mediumScreen) {
      return {
        structurePricingRows: {
          structurePricingCol1: [
            dtc[StructurePricingRowLabels.marketCapPre],
            dtc[StructurePricingRowLabels.marketCapPrePct],
            dtc[StructurePricingRowLabels.lastTradeBeforeFiling],
            dtc[StructurePricingRowLabels.lastTradeBeforeFilingSplitAdjusted],
          ].filter((row: DtcItem) => !row.isHidden),
          structurePricingCol2: [
            dtc[StructurePricingRowLabels.thirtyDayADTV],
            dtc[StructurePricingRowLabels.ADTVDateRange],
            dtc[StructurePricingRowLabels.forwardAgreement],
          ].filter((row: DtcItem) => !row.isHidden),
          structurePricingCol3: [],
        },
      };
    }
    return {
      structurePricingRows: {
        structurePricingCol1: [
          dtc[StructurePricingRowLabels.marketCapPre],
          dtc[StructurePricingRowLabels.marketCapPrePct],
        ].filter((row: DtcItem) => !row.isHidden),
        structurePricingCol2: [
          dtc[StructurePricingRowLabels.lastTradeBeforeFiling],
          dtc[StructurePricingRowLabels.lastTradeBeforeFilingSplitAdjusted],
        ].filter((row: DtcItem) => !row.isHidden),
        structurePricingCol3: [
          dtc[StructurePricingRowLabels.thirtyDayADTV],
          dtc[StructurePricingRowLabels.ADTVDateRange],
          dtc[StructurePricingRowLabels.forwardAgreement],
        ].filter((row: DtcItem) => !row.isHidden),
      },
    };
  }, [dtc, mediumScreen, smallScreen]);
};
