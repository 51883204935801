import { Popover } from '@cmg/common';
import React from 'react';

import {
  SItemIconWrapper,
  SItemLabelWrapper,
  STooltipContent,
  StyledIcon,
} from './HeaderLabelRenderer.styles';

export type Props = {
  label: string;
  tooltip: string;
};

const HeaderLabelRenderer: React.FC<Props> = ({ label, tooltip }) => {
  return (
    <Popover
      variant="TOOLTIP"
      placement="topRight"
      hideArrow
      content={<STooltipContent>{tooltip}</STooltipContent>}
    >
      <SItemLabelWrapper>{label}</SItemLabelWrapper>
      <SItemIconWrapper>
        <StyledIcon name="info-circle" />
      </SItemIconWrapper>
    </Popover>
  );
};

export default HeaderLabelRenderer;
