import { loggerUtil } from '@cmg/common';
import React from 'react';

export type MixpanelLogProps = {
  logMixpanel: ReturnType<typeof loggerUtil.useMixpanel>['log'];
};

/**
 * WithMixpanelLogger is a HOC component that provides the mixpanel Logger to the wrapped component
 *
 * @param WrappedComponent
 */
function withMixpanelLogger<T>(WrappedComponent: React.ComponentType<T & MixpanelLogProps>) {
  return function WithMixpanelLogger(props: T) {
    const { log } = loggerUtil.useMixpanel();
    return <WrappedComponent {...props} logMixpanel={log} />;
  };
}

export default withMixpanelLogger;
