import { styled, SxProps, TableCell, Theme } from '@cmg/design-system';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: theme.spacing(14),
  height: theme.spacing(3.75),
  paddingTop: 0,
  paddingBottom: 0,
}));

export const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(14),
}));

export const totalRowStyle: SxProps<Theme> = {
  bgcolor: theme => '#EDF1F9',
}; // This is temporary until design team add more color to our palette.

export const combinedRowStyle: SxProps<Theme> = {
  bgcolor: theme => theme.palette.background.lightGrey,
  borderTop: 'none',
};

export const combinedInvestmentsRowStyle: SxProps<Theme> = {
  '&:nth-last-of-type(n+2) td': { borderBottom: 'none' }, // select all but last child
};
