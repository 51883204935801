import { FormField } from '@cmg/common';
import { suggestedUserButtonSelector } from '@cmg/e2e-selectors';
import React from 'react';

import { User } from '../../../../types/domain/user/user';
import {
  SSuggestedPeople,
  SSuggestedUserButton,
  SSuggestedUserButtonIcon,
} from './UserButtonList.styles';

export type Props = {
  label: string;
  users: User[];
  onClickUserButton: (suggestedUser: User) => void;
};

export const UserButtonList: React.FC<Props> = ({ label, users, onClickUserButton }) => {
  if (users.length === 0) {
    return null;
  }

  return (
    <FormField label={label}>
      <SSuggestedPeople>
        {users.map((user: User) => (
          <SSuggestedUserButton
            data-test-id={suggestedUserButtonSelector.testId}
            key={user.id}
            type="button"
            onClick={() => onClickUserButton(user)}
          >
            <span>{user.username}</span>
            <SSuggestedUserButtonIcon name="plus-circle" />
          </SSuggestedUserButton>
        ))}
      </SSuggestedPeople>
    </FormField>
  );
};

export default UserButtonList;
