import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum AfterMarketPerfOfferButtons {
  OFFER_TO_OPEN = 'offer_to_open',
  OFFER_TO_DAY = 'offer_to_day',
  OFFER_TO_MONTH = 'offer_to_month',
  OFFER_TO_90_DAY = 'offer_to_three_months',
  OFFER_TO_180_DAY = 'offer_to_six_months',
  OFFER_TO_CURRENT = 'offer_to_current',
}

export const afterMarketPerfOfferButtonsConfig: ButtonsConfig = {
  [AfterMarketPerfOfferButtons.OFFER_TO_OPEN]: {
    label: 'Offer to Open',
    countValue: 'offer_to_open_count',
  },
  [AfterMarketPerfOfferButtons.OFFER_TO_DAY]: {
    label: 'Offer to 1 Day',
    countValue: 'offer_to_day_count',
  },
  [AfterMarketPerfOfferButtons.OFFER_TO_MONTH]: {
    label: 'Offer to 30 Day',
    countValue: 'offer_to_month_count',
  },
  [AfterMarketPerfOfferButtons.OFFER_TO_90_DAY]: {
    label: 'Offer to 90 Day',
    countValue: 'offer_to_three_months_count',
  },
  [AfterMarketPerfOfferButtons.OFFER_TO_180_DAY]: {
    label: 'Offer to 180 Day',
    countValue: 'offer_to_six_months_count',
  },
  [AfterMarketPerfOfferButtons.OFFER_TO_CURRENT]: {
    label: 'Offer to Current',
    countValue: 'offer_to_current_count',
  },
};

export default getButtonConfigArray(afterMarketPerfOfferButtonsConfig);
