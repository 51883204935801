import { Icon, TextInput } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const StyledFilterInput = styled(TextInput)`
  input {
    color: ${({ theme }) => theme.text.color.white};
    background: ${({ theme }) => theme.background.color.menu};
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    margin: 3px 0;
    height: 30px;
  }
`;

export const StyledInputIcon = styled(Icon)`
  margin: 0 10px;
`;

export const SFooter = styled.div`
  margin: 10px 10px 0 10px;
  text-align: center;
`;

export const SHeader = styled.div`
  ${({ theme }) => css`
    min-width: 150px;
    font-size: ${theme.text.size.mediumLarge};
    color: ${theme.text.color.white};
    margin-bottom: 10px;
    text-align: center;
  `}
`;
