import { FlagComponent } from '@cmg/common';
import { Box, Typography } from '@cmg/design-system';
import React from 'react';
import { useTheme } from 'styled-components/macro';

export type Props = {
  countryCode: string | null;
  countryDisplayName: string | null;
};

const CountryRenderer: React.FC<Props> = ({ countryCode, countryDisplayName }) => {
  const theme = useTheme();
  if (!countryCode || !countryDisplayName) {
    return null;
  }

  return (
    <Box display="flex" alignItems="flex-start" gap={0.5}>
      <Box display="flex">
        <FlagComponent
          countryCode={countryCode}
          countryDisplayName={countryDisplayName}
          styledConfig={{ borderColor: theme.color.gray4 }}
        />
      </Box>
      <Box>
        <Typography variant="highlight2">{countryDisplayName}</Typography>
      </Box>
    </Box>
  );
};

export default CountryRenderer;
