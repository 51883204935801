import {
  countriesList,
  DateRangePreset,
  DateRangePresetOptions,
  DateRangePresetTypes,
  dateRangeUtil,
} from '@cmg/common';
import { getYear } from 'date-fns';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import memoize, { EqualityFn } from 'memoize-one';

import { Option } from '../../../common/components/form/selects/types';
import { getFeatureToggles } from '../../../config/appSettings';
import { SpacFilterOptions } from '../../../types/domain/offering/constants';
import { UserSettings } from '../../../types/domain/user/userSettings';
import { ExtendedOfferingTypes, OfferingOptions } from '../../shared/constants/constants';
import { DatalabScreens } from '../constants';
import {
  DatalabFilterValues,
  DatalabFormValues,
  DatalabQueryObject,
  FilterSettings,
  SPACFilterValue,
} from '../types';
import { getAdvancedFiltersConfig } from './advanced-filters-config';
import {
  getInternationalPermission,
  isInternationalOfferingsOn,
  isInternationalOfferingTypesOn,
} from './utils';

const stringOrBoolToBool = (value?: 'true' | 'false' | boolean) =>
  value === undefined ? undefined : `${value}` === 'true';
const stringOrBoolToBoolOrNull = (value?: 'true' | 'false' | boolean) =>
  stringOrBoolToBool(value) === undefined ? null : stringOrBoolToBool(value);

const stringToNumber = (value?: string) => (value === undefined ? undefined : parseFloat(value));

const stringToArray = (value?: string) =>
  value === '' || value === undefined ? [] : value.split(',').filter(v => v !== '');

const flattenCountries = (value: string[]) => {
  const countries = value.map(val => val.split(','));
  return flatten(countries);
};
export const memoizeIsEqual = (newArgs, oldArgs) => isEqual(newArgs, oldArgs);

enum datalabScreenGroupIds {
  IPO_GROUP = 'IPO',
  FO_GROUP = 'FO',
}

function getFilterSettingsFactory(screen: DatalabScreens): FilterSettings {
  const ipoScreens = [
    DatalabScreens.IPO_STRUCTURE,
    DatalabScreens.IPO_PRICING,
    DatalabScreens.IPO_BACKLOG,
  ];
  const followOnScreens = [DatalabScreens.FOLLOW_ON_STRUCTURE, DatalabScreens.FOLLOW_ON_PRICING];

  const isInIPO = ipoScreens.includes(screen);
  const isInFO = followOnScreens.includes(screen);

  const showYearSelect = screen === DatalabScreens.CAPITAL_RAISED_MARKET;

  const dateRangePresetOptions: DateRangePresetOptions | undefined =
    screen === DatalabScreens.LOCK_UP_EXPIRATION
      ? dateRangeUtil.getFuturePresetOptions(new Date())
      : undefined;

  const shouldFilterForAllocations = [
    DatalabScreens.IOI_PARTICIPATION,
    DatalabScreens.IOI_LEAGUE_TABLE,
    DatalabScreens.PL_LEAGUE_TABLE,
    DatalabScreens.BROKER_PL_OFFERINGS,
    DatalabScreens.FUND_IOI_PARTICIPATION,
    DatalabScreens.FUND_IOI_LEAGUE_TABLE,
  ].includes(screen);

  const filterIoiTypeEnabled = [
    DatalabScreens.IOI_PARTICIPATION,
    DatalabScreens.IOI_LEAGUE_TABLE,
  ].includes(screen);

  const filterFundsEnabled = [DatalabScreens.FUND_IOI_PARTICIPATION].includes(screen);

  const advancedFiltersEnabled = ![DatalabScreens.IPO_BACKLOG].includes(screen);

  const spacSelectEnabled = ![
    DatalabScreens.FOLLOW_ON_PRICING,
    DatalabScreens.FOLLOW_ON_STRUCTURE,
  ].includes(screen);

  const showInternational = getInternationalPermission(screen);
  const offeringTypeOptions = getOfferingTypeConfig(screen).options;
  return {
    showYearSelect,
    dateRangePresetOptions,
    isInIPO,
    isInFO,
    shouldFilterForAllocations,
    filterIoiTypeEnabled,
    filterFundsEnabled,
    advancedFiltersEnabled,
    marketCapFiltersEnabled: screen !== DatalabScreens.IPO_BACKLOG,
    offeringSizeFiltersEnabled: screen !== DatalabScreens.IPO_BACKLOG,
    sellingShareFiltersEnabled: screen !== DatalabScreens.IPO_BACKLOG,
    offeringTypeOptions,
    spacSelectEnabled,
    showInternational,
    advancedFilterFieldConfig: getAdvancedFiltersConfig(showInternational),
  };
}

const getFilterSettingsFactoryEqualityFn: EqualityFn<typeof getFilterSettingsFactory> = (
  newArgs,
  lastArgs
) => {
  return isEqual(newArgs[0], lastArgs[0]);
};

export const getFilterSettings = memoize(
  getFilterSettingsFactory,
  getFilterSettingsFactoryEqualityFn
);

export function getScreenGroupId(screen: DatalabScreens): string {
  switch (screen) {
    case DatalabScreens.IPO_STRUCTURE:
    case DatalabScreens.IPO_PRICING:
      return datalabScreenGroupIds.IPO_GROUP;

    case DatalabScreens.FOLLOW_ON_STRUCTURE:
    case DatalabScreens.FOLLOW_ON_PRICING:
      return datalabScreenGroupIds.FO_GROUP;
  }

  return screen;
}

export const getDateRangeValues = ({
  showYearSelect,
  queryObject,
  presets,
  defaultPreset,
}: {
  showYearSelect: boolean;
  queryObject: DatalabQueryObject;
  presets?: DateRangePreset[];
  defaultPreset: string;
}): {
  typeDateValue?: string;
  startDateValue?: string;
  endDateValue?: string;
} => {
  const { dateRangeType, dateRangeStart, dateRangeEnd } = queryObject;

  let values;

  if (showYearSelect) {
    const thisYear = getYear(new Date());
    values = {
      startDateValue: `${thisYear - 1}-01-01`,
      endDateValue: `${thisYear}-12-31`,
    };
  } else {
    const typeDateValue = dateRangeType || defaultPreset;
    const preset = dateRangeUtil.getPreset(typeDateValue, presets);

    values = {
      typeDateValue: preset.value.type,
      startDateValue:
        preset.value.type === DateRangePresetTypes.CUSTOM ? dateRangeStart : preset.value.start,
      endDateValue:
        preset.value.type === DateRangePresetTypes.CUSTOM ? dateRangeEnd : preset.value.end,
    };
  }

  return values;
};

export const defaultCountriesFilter = [countriesList.Usa.countryDisplayName];

/**
 * Determine the list of possible offering types given info on if we are in an ipo and/or follow-on capable screen.
 * When no options are selected by the user, we use all values returned from here to send to the api.
 */
export function getOfferingTypeConfig(screen?: DatalabScreens) {
  const isIntlOn = isInternationalOfferingsOn();
  const isIntlTypeOn = isInternationalOfferingTypesOn();
  const { isNewDirectOfferingFieldsOn: isViewDirectsOn } = getFeatureToggles();

  const filterNull = (option): option is Exclude<typeof option, null> => option !== null;
  // Options
  const allOptions = [
    OfferingOptions[ExtendedOfferingTypes.IPO],
    OfferingOptions[ExtendedOfferingTypes.IPO_SPACS],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.FULLY_MARKETED_OFFERING] : null,
    OfferingOptions[ExtendedOfferingTypes.MARKETED_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.ABB_ABO] : null,
    OfferingOptions[ExtendedOfferingTypes.OVERNIGHT_FO],
    isIntlOn ? OfferingOptions[ExtendedOfferingTypes.RIGHTS] : null,
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.BLOCK] : null,
    OfferingOptions[ExtendedOfferingTypes.REGISTERED_BLOCK],
    OfferingOptions[ExtendedOfferingTypes.UNREGISTERED_BLOCK],
    isViewDirectsOn ? OfferingOptions[ExtendedOfferingTypes.DIRECT_LISTING] : null,
    isViewDirectsOn ? OfferingOptions[ExtendedOfferingTypes.REGISTERED_DIRECT] : null,
  ].filter(filterNull);

  const ipoOptions = [
    OfferingOptions[ExtendedOfferingTypes.IPO],
    OfferingOptions[ExtendedOfferingTypes.IPO_SPACS],
  ];

  const followOnOptions = [
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.FULLY_MARKETED_OFFERING] : null,
    OfferingOptions[ExtendedOfferingTypes.MARKETED_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.ABB_ABO] : null,
    OfferingOptions[ExtendedOfferingTypes.OVERNIGHT_FO],
    isIntlOn ? OfferingOptions[ExtendedOfferingTypes.RIGHTS] : null,
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.BLOCK] : null,
    OfferingOptions[ExtendedOfferingTypes.REGISTERED_BLOCK],
    OfferingOptions[ExtendedOfferingTypes.UNREGISTERED_BLOCK],
  ].filter(filterNull);

  const brokerOptions = [
    OfferingOptions[ExtendedOfferingTypes.IPO],
    OfferingOptions[ExtendedOfferingTypes.IPO_SPACS],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.FULLY_MARKETED_OFFERING] : null,
    OfferingOptions[ExtendedOfferingTypes.MARKETED_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.ABB_ABO] : null,
    OfferingOptions[ExtendedOfferingTypes.OVERNIGHT_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.BLOCK] : null,
    OfferingOptions[ExtendedOfferingTypes.REGISTERED_BLOCK],
    OfferingOptions[ExtendedOfferingTypes.UNREGISTERED_BLOCK],
  ].filter(filterNull);

  const allOptionsWithoutSpac = [
    OfferingOptions[ExtendedOfferingTypes.IPO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.FULLY_MARKETED_OFFERING] : null,
    OfferingOptions[ExtendedOfferingTypes.MARKETED_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.ABB_ABO] : null,
    OfferingOptions[ExtendedOfferingTypes.OVERNIGHT_FO],
    isIntlOn ? OfferingOptions[ExtendedOfferingTypes.RIGHTS] : null,
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.BLOCK] : null,
    OfferingOptions[ExtendedOfferingTypes.REGISTERED_BLOCK],
    OfferingOptions[ExtendedOfferingTypes.UNREGISTERED_BLOCK],
    isViewDirectsOn ? OfferingOptions[ExtendedOfferingTypes.DIRECT_LISTING] : null,
    isViewDirectsOn ? OfferingOptions[ExtendedOfferingTypes.REGISTERED_DIRECT] : null,
  ].filter(filterNull);

  const brokerSelectedOptions = [
    OfferingOptions[ExtendedOfferingTypes.IPO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.FULLY_MARKETED_OFFERING] : null,
    OfferingOptions[ExtendedOfferingTypes.MARKETED_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.ABB_ABO] : null,
    OfferingOptions[ExtendedOfferingTypes.OVERNIGHT_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.BLOCK] : null,
    OfferingOptions[ExtendedOfferingTypes.REGISTERED_BLOCK],
    OfferingOptions[ExtendedOfferingTypes.UNREGISTERED_BLOCK],
  ].filter(filterNull);

  const defaultOptions = [
    OfferingOptions[ExtendedOfferingTypes.IPO],
    OfferingOptions[ExtendedOfferingTypes.IPO_SPACS],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.FULLY_MARKETED_OFFERING] : null,
    OfferingOptions[ExtendedOfferingTypes.MARKETED_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.ABB_ABO] : null,
    OfferingOptions[ExtendedOfferingTypes.OVERNIGHT_FO],
    isIntlTypeOn ? OfferingOptions[ExtendedOfferingTypes.BLOCK] : null,
    OfferingOptions[ExtendedOfferingTypes.REGISTERED_BLOCK],
    OfferingOptions[ExtendedOfferingTypes.UNREGISTERED_BLOCK],
  ].filter(filterNull);

  // Default Selected Values
  const allValues = [
    ExtendedOfferingTypes.IPO,
    ExtendedOfferingTypes.IPO_SPACS,
    isIntlTypeOn ? ExtendedOfferingTypes.FULLY_MARKETED_OFFERING : null,
    ExtendedOfferingTypes.MARKETED_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.ABB_ABO : null,
    ExtendedOfferingTypes.OVERNIGHT_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.BLOCK : null,
    ExtendedOfferingTypes.REGISTERED_BLOCK,
    ExtendedOfferingTypes.UNREGISTERED_BLOCK,
    isViewDirectsOn ? ExtendedOfferingTypes.REGISTERED_DIRECT : null,
    isViewDirectsOn ? ExtendedOfferingTypes.DIRECT_LISTING : null,
  ].filter(filterNull);

  const ipoValues = [ExtendedOfferingTypes.IPO];

  const followOnValues = [
    isIntlTypeOn ? ExtendedOfferingTypes.FULLY_MARKETED_OFFERING : null,
    ExtendedOfferingTypes.MARKETED_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.ABB_ABO : null,
    ExtendedOfferingTypes.OVERNIGHT_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.BLOCK : null,
    ExtendedOfferingTypes.REGISTERED_BLOCK,
    ExtendedOfferingTypes.UNREGISTERED_BLOCK,
  ].filter(filterNull);

  const brokerDefaultValues = [
    ExtendedOfferingTypes.IPO,
    isIntlTypeOn ? ExtendedOfferingTypes.FULLY_MARKETED_OFFERING : null,
    ExtendedOfferingTypes.MARKETED_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.ABB_ABO : null,
    ExtendedOfferingTypes.OVERNIGHT_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.BLOCK : null,
    ExtendedOfferingTypes.REGISTERED_BLOCK,
    ExtendedOfferingTypes.UNREGISTERED_BLOCK,
  ].filter(filterNull);

  const allValuesWithoutSpac = [
    ExtendedOfferingTypes.IPO,
    isIntlTypeOn ? ExtendedOfferingTypes.FULLY_MARKETED_OFFERING : null,
    ExtendedOfferingTypes.MARKETED_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.ABB_ABO : null,
    ExtendedOfferingTypes.OVERNIGHT_FO,
    isIntlTypeOn ? ExtendedOfferingTypes.BLOCK : null,
    ExtendedOfferingTypes.REGISTERED_BLOCK,
    ExtendedOfferingTypes.UNREGISTERED_BLOCK,
    isViewDirectsOn ? ExtendedOfferingTypes.REGISTERED_DIRECT : null,
    isViewDirectsOn ? ExtendedOfferingTypes.DIRECT_LISTING : null,
  ].filter(filterNull);

  if (!screen) {
    return {
      options: allOptions,
      defaultSelectedOption: allOptions,
      defaultSelectedValues: allValues,
    };
  }

  switch (screen) {
    case DatalabScreens.MARKET_PULSE:
    case DatalabScreens.CAPITAL_RAISED_MARKET:
    case DatalabScreens.IOI_PARTICIPATION:
    case DatalabScreens.FUND_IOI_LEAGUE_TABLE:
      return {
        options: allOptions,
        defaultSelectedOption: allOptionsWithoutSpac,
        defaultSelectedValues: allValuesWithoutSpac,
      };
    case DatalabScreens.IOI_LEAGUE_TABLE:
    case DatalabScreens.PL_LEAGUE_TABLE:
      return {
        options: brokerOptions,
        defaultSelectedOption: brokerSelectedOptions,
        defaultSelectedValues: brokerDefaultValues,
      };
    case DatalabScreens.IPO_STRUCTURE:
    case DatalabScreens.IPO_PRICING:
    case DatalabScreens.IPO_BACKLOG:
      return {
        options: ipoOptions,
        defaultSelectedOption: ipoOptions,
        defaultSelectedValues: ipoValues,
      };
    case DatalabScreens.FOLLOW_ON_STRUCTURE:
    case DatalabScreens.FOLLOW_ON_PRICING:
      return {
        options: followOnOptions,
        defaultSelectedOption: followOnOptions,
        defaultSelectedValues: followOnValues,
      };
    default: // All the other options
      return {
        options: defaultOptions,
        defaultSelectedOption: allOptionsWithoutSpac,
        defaultSelectedValues: allValuesWithoutSpac,
      };
  }
}

export function getSpacByOfferingType(offeringTypes?: string[]): SPACFilterValue {
  const includesSpac = (offeringTypes ?? []).includes(ExtendedOfferingTypes.IPO_SPACS.valueOf());
  if (includesSpac && offeringTypes?.length === 1) {
    return 'only';
  }
  return includesSpac ? 'include' : 'exclude';
}

export function getOfferingTypeInternal({
  offeringTypeValues = [],
  spac = 'exclude',
}: {
  offeringTypeValues?: string[];
  spac?: string;
}): string[] {
  return [...offeringTypeValues, ...(spac !== 'exclude' ? [ExtendedOfferingTypes.IPO_SPACS] : [])];
}

export const getOfferingTypeWithoutInternalTypes = (offeringTypes?: string[]): string[] =>
  (offeringTypes ?? []).filter(
    o =>
      o !== ExtendedOfferingTypes.IPO_SPACS &&
      o !== ExtendedOfferingTypes.FULLY_MARKETED_OFFERING &&
      o !== ExtendedOfferingTypes.ABB_ABO &&
      o !== ExtendedOfferingTypes.BLOCK
  );

export const getInternationalOfferingTypes = (offeringTypes?: string[]): string[] | undefined => {
  if (!isInternationalOfferingTypesOn()) {
    return undefined;
  }
  return (offeringTypes ?? []).filter(
    t =>
      t === ExtendedOfferingTypes.FULLY_MARKETED_OFFERING.valueOf() ||
      t === ExtendedOfferingTypes.ABB_ABO.valueOf() ||
      t === ExtendedOfferingTypes.BLOCK.valueOf()
  );
};

function getFilterDefaultValuesFactory(
  queryObject: DatalabQueryObject,
  screen: DatalabScreens,
  userSettings: UserSettings
): DatalabFilterValues {
  const { showYearSelect, shouldFilterForAllocations, dateRangePresetOptions, showInternational } =
    getFilterSettings(screen);

  const defaultPreset =
    screen === DatalabScreens.LOCK_UP_EXPIRATION
      ? DateRangePresetTypes.N90D
      : DateRangePresetTypes.LTM;

  const offeringTypeValues = getOfferingTypeConfig(screen).defaultSelectedValues;

  const avgMethod =
    screen === DatalabScreens.SPONSOR_LEAGUE_TABLE || screen === DatalabScreens.AFTERMARKET_MATRIX
      ? 'mean'
      : undefined;
  const plCredit =
    screen === DatalabScreens.PL_LEAGUE_TABLE || screen === DatalabScreens.BROKER_PL_OFFERINGS
      ? 'economics'
      : undefined;

  const spac = getSpacByOfferingType(offeringTypeValues);
  const mappedFromUrl: DatalabFilterValues = {
    groupId: getScreenGroupId(screen),
    ...getDateRangeValues({
      showYearSelect,
      queryObject,
      presets: dateRangeUtil.getPresetsArray(dateRangePresetOptions),
      defaultPreset,
    }),
    leftLeadOnly: stringOrBoolToBoolOrNull(queryObject.leftLeadOnly),
    offeringTypeValues: offeringTypeValues,
    internationalOfferingTypeValues: getInternationalOfferingTypes(offeringTypeValues),
    offeringTypeInternal: getOfferingTypeInternal({ offeringTypeValues, spac }),
    bySectorsValues: stringToArray(queryObject.sector),
    byCustomSectorsValues: stringToArray(queryObject.customSector),
    underwritersValues: stringToArray(queryObject.underwriter),
    sponsorsValues: stringToArray(queryObject.sponsor),
    advisoriesValues: stringToArray(queryObject.advisory) || [],
    fundsValues: stringToArray(queryObject.fund) || [],
    marketCapValueMin: stringToNumber(queryObject.marketCapMin),
    marketCapValueMax: stringToNumber(queryObject.marketCapMax),
    offeringSizeValueMin: stringToNumber(queryObject.offeringSizeMin),
    offeringSizeValueMax: stringToNumber(queryObject.offeringSizeMax),
    sellingShareholderPctMin: stringToNumber(queryObject.sellingShareholderPctMin),
    sellingShareholderPctMax: stringToNumber(queryObject.sellingShareholderPctMax),
    advancedQuery: {
      id: 'g-6b19ed44-ad43-4008-8a27-c82e444cf3a4', // todo probably needs to generate new UUID
      rules: [],
      combinator: 'and',
    },
    ioiType: [],
    filterForAllocations: shouldFilterForAllocations ? [] : false,
    useCustomSectors: stringOrBoolToBool(queryObject.useCustomSectors),
    avgMethod: avgMethod,
    plCredit: plCredit,
    spac,
    countries: showInternational
      ? stringToArray(queryObject.country) ?? []
      : defaultCountriesFilter,
  };

  // list of fields which shoud be grabbed from userSettings if undefined
  const overwriteFields: (keyof UserSettings)[] = ['useCustomSectors'];
  let defaultValues = mappedFromUrl;
  overwriteFields.forEach(field => {
    if (mappedFromUrl[field] === undefined) {
      defaultValues[field] = userSettings[field];
    }
  });

  return defaultValues;
}

export const getFilterDefaultValues: typeof getFilterDefaultValuesFactory = memoize(
  getFilterDefaultValuesFactory,
  memoizeIsEqual
);

/**
 * Determines when datalab filters should be reset just based on previous and next screen
 * @param prevScreen
 * @param nextScreen
 */
export function shouldResetFilters(prevScreen: DatalabScreens, nextScreen: DatalabScreens) {
  if (prevScreen === nextScreen) {
    return false;
  }

  const ipoScreens = [DatalabScreens.IPO_STRUCTURE, DatalabScreens.IPO_PRICING];
  const foScreens = [DatalabScreens.FOLLOW_ON_STRUCTURE, DatalabScreens.FOLLOW_ON_PRICING];

  const isInIpoScreens = ipoScreens.includes(prevScreen) && ipoScreens.includes(nextScreen);
  const isInFollowOnScreens = foScreens.includes(prevScreen) && foScreens.includes(nextScreen);

  return !(
    isInIpoScreens ||
    isInFollowOnScreens ||
    shouldAddUnderwriterToFilters(prevScreen, nextScreen) ||
    shouldAddSponsorToFilters(prevScreen, nextScreen)
  );
}

export function shouldAddUnderwriterToFilters(
  prevScreen: DatalabScreens,
  nextScreen: DatalabScreens
) {
  const goingToAftermarketUnderwriterOfferings =
    (prevScreen === DatalabScreens.LEAGUE_TABLE ||
      prevScreen === DatalabScreens.AFTERMARKET_MATRIX) &&
    nextScreen === DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS;

  const goingToUnderwriterOfferings =
    prevScreen === DatalabScreens.LEAGUE_TABLE &&
    nextScreen === DatalabScreens.UNDERWRITER_OFFERINGS;

  const goingToBrokerPlOfferings =
    prevScreen === DatalabScreens.PL_LEAGUE_TABLE &&
    nextScreen === DatalabScreens.BROKER_PL_OFFERINGS;

  const goingToParticipationSummary =
    prevScreen === DatalabScreens.IOI_LEAGUE_TABLE &&
    nextScreen === DatalabScreens.IOI_PARTICIPATION;

  return (
    goingToAftermarketUnderwriterOfferings ||
    goingToUnderwriterOfferings ||
    goingToBrokerPlOfferings ||
    goingToParticipationSummary
  );
}

export function shouldAddSponsorToFilters(prevScreen: DatalabScreens, nextScreen: DatalabScreens) {
  return (
    prevScreen === DatalabScreens.SPONSOR_LEAGUE_TABLE && nextScreen === DatalabScreens.MARKET_PULSE
  );
}

export function getNextFilterValues(
  prevScreen: DatalabScreens,
  nextScreen: DatalabScreens,
  prevScreenType: 'chart' | 'table',
  nextScreenType: 'chart' | 'table',
  nextUrlQuery: { [key: string]: string | undefined },
  defaultFilterValues: DatalabFilterValues,
  currentFilterValues: DatalabFilterValues,
  userSettings: UserSettings
): { nextFilters: DatalabFilterValues; nextDefaultFilters: DatalabFilterValues } {
  // we want to keep filters untouched when user navigates between chart and table views
  if (
    prevScreen === nextScreen &&
    ((prevScreenType === 'table' && nextScreenType === 'chart') ||
      (prevScreenType === 'chart' && nextScreenType === 'table'))
  ) {
    return { nextFilters: currentFilterValues, nextDefaultFilters: defaultFilterValues };
  }

  const nextDefaultFilters = getFilterDefaultValues(nextUrlQuery, nextScreen, userSettings);

  // we want to keep filters only if they are in a specific group(s) or we are moving from or to
  // specific screens, otherwise we reset them to defaults
  if (shouldResetFilters(prevScreen, nextScreen)) {
    return { nextFilters: nextDefaultFilters, nextDefaultFilters: nextDefaultFilters };
  }

  // we want to set just underwriter value into current filters, so other values persist
  if (shouldAddUnderwriterToFilters(prevScreen, nextScreen)) {
    if (nextUrlQuery.underwriter) {
      const nextFilters: DatalabFilterValues = {
        ...currentFilterValues,
        groupId: getScreenGroupId(nextScreen),
        underwritersValues: stringToArray(nextUrlQuery.underwriter),
      };
      return { nextFilters: nextFilters, nextDefaultFilters: nextFilters };
    } else {
      return { nextFilters: nextDefaultFilters, nextDefaultFilters: nextDefaultFilters };
    }
  }

  // we want to set just sponsor value into current filters, so other values persist
  if (shouldAddSponsorToFilters(prevScreen, nextScreen)) {
    if (nextUrlQuery.sponsor) {
      const nextFilters = {
        ...currentFilterValues,
        groupId: getScreenGroupId(nextScreen),
        sponsorsValues: stringToArray(nextUrlQuery.sponsor),
      };
      return { nextFilters: nextFilters, nextDefaultFilters: nextFilters };
    } else {
      return { nextFilters: nextDefaultFilters, nextDefaultFilters: nextDefaultFilters };
    }
  }

  // we keep values untouched cause we are still within specific groups (IPO, FO)
  return { nextFilters: currentFilterValues, nextDefaultFilters: defaultFilterValues };
}

export function mapDefaultValues(values: DatalabFilterValues): DatalabFormValues {
  const {
    groupId,
    startDateValue,
    endDateValue,
    typeDateValue,
    offeringTypeValues,
    offeringTypeInternal,
    bySectorsValues,
    byCustomSectorsValues,
    underwritersValues,
    sponsorsValues,
    advisoriesValues,
    fundsValues,
    marketCapValueMin,
    marketCapValueMax,
    offeringSizeValueMin,
    offeringSizeValueMax,
    sellingShareholderPctMin,
    sellingShareholderPctMax,
    spac,
    ...restValues
  } = values;
  const spacFromOfferingTypes = getSpacByOfferingType(offeringTypeInternal);
  return {
    // groupId is used to detect whenever we should reset values based on current location change
    // we use Formik's enablereinitialize property to make it working
    // see https://jaredpalmer.com/formik/docs/api/formik#enablereinitialize-boolean
    groupId,
    date: { start: startDateValue, end: endDateValue, type: typeDateValue },
    offeringType: offeringTypeValues,
    offeringTypeInternal: offeringTypeInternal,
    marketCap: { min: marketCapValueMin, max: marketCapValueMax },
    offeringSize: { min: offeringSizeValueMin, max: offeringSizeValueMax },
    sellingShareholderPct: { min: sellingShareholderPctMin, max: sellingShareholderPctMax },
    sector: bySectorsValues,
    customSector: byCustomSectorsValues,
    underwriters: underwritersValues,
    sponsors: sponsorsValues,
    advisories: advisoriesValues,
    funds: fundsValues,
    spac: spacFromOfferingTypes,
    ...restValues,
  };
}

export function mapSubmittedValues(values: DatalabFormValues, filterSettings: FilterSettings) {
  const { shouldFilterForAllocations, showInternational } = filterSettings;
  const {
    date,
    marketCap,
    offeringSize,
    sellingShareholderPct,
    offeringType,
    offeringTypeInternal,
    sector,
    customSector,
    underwriters,
    sponsors,
    advisories,
    funds,
    groupId,
    countries,
    spac,
    ...restValues
  } = values;

  // When the BE accepts spacs we can remove this
  const offeringTypeValues = offeringTypeInternal?.filter(
    t =>
      t !== ExtendedOfferingTypes.IPO_SPACS.valueOf() &&
      t !== ExtendedOfferingTypes.FULLY_MARKETED_OFFERING.valueOf() &&
      t !== ExtendedOfferingTypes.ABB_ABO.valueOf() &&
      t !== ExtendedOfferingTypes.BLOCK.valueOf()
  );
  const spacFromOfferingType = getSpacByOfferingType(offeringTypeInternal);

  const internationalOfferingTypeValues = getInternationalOfferingTypes(offeringTypeInternal);
  return {
    groupId,
    startDateValue: date.start,
    endDateValue: date.end,
    typeDateValue: date.type,
    offeringTypeValues,
    internationalOfferingTypeValues,
    offeringTypeInternal,
    marketCapValueMin: marketCap.min,
    marketCapValueMax: marketCap.max,
    offeringSizeValueMin: offeringSize.min,
    offeringSizeValueMax: offeringSize.max,
    sellingShareholderPctMin: sellingShareholderPct.min,
    sellingShareholderPctMax: sellingShareholderPct.max,
    bySectorsValues: values.useCustomSectors ? [] : sector,
    byCustomSectorsValues: values.useCustomSectors ? customSector : [],
    underwritersValues: underwriters,
    sponsorsValues: sponsors,
    advisoriesValues: advisories,
    fundsValues: funds,
    filterForAllocations: shouldFilterForAllocations && !!values.ioiType ? values.ioiType : false,
    countries: showInternational ? flattenCountries(countries ?? []) : defaultCountriesFilter,
    // For now spacs depends on the offering type
    spac: spacFromOfferingType,
    ...restValues,
  };
}

export const spacOptions: Option[] = [
  {
    label: 'Exclude SPACs',
    value: SpacFilterOptions.EXCLUDE.valueOf(),
  },
  {
    label: 'Include SPACs',
    value: SpacFilterOptions.INCLUDE.valueOf(),
  },
  {
    label: 'SPACs Only',
    value: SpacFilterOptions.ONLY.valueOf(),
  },
];
