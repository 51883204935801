import React from 'react';

import { StyledHighlightWords } from './TextHighlighter.styles';

type Props = {
  text: string;
  search: string;
};

const TextHighlighter: React.FC<Props> = ({ text, search }) => {
  return (
    <StyledHighlightWords
      textToHighlight={text}
      searchWords={[search]}
      highlightClassName="highlight"
    />
  );
};

export default TextHighlighter;
