import { numericUtil } from '@cmg/common';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';

import { OfferingType } from '../../../../../../graphql/__generated__';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../../shared/OfferingProfileCardItem';
import KeyValueItem from '../components/KeyValueItem';
import { useGetPerformanceData } from '../hooks/useGetPerformanceData';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const subHeaderSizes: RegularBreakpoints = { xl: 4, lg: 6, md: 12, sm: 12 };
const tableSectionSizes: RegularBreakpoints = { xl: 6, lg: 6, md: 12, sm: 12 };

const PerformanceWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { firstDayVolume, firstDayTurnover } = offeringProfile.offeringById?.attributes ?? {};

  const isLargeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const performance = useGetPerformanceData(offeringProfile);
  const title = 'Performance';
  const isAtm = offeringProfile?.offeringById?.type === OfferingType.Atm;

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {!isAtm && (
            <Grid container columnSpacing={3}>
              <Grid item {...subHeaderSizes}>
                <KeyValueItem
                  size="small"
                  label="First Day Volume"
                  value={
                    firstDayVolume ? numericUtil.getDisplayValueForInteger(firstDayVolume) : '-'
                  }
                />
              </Grid>
              <Grid item {...subHeaderSizes}>
                <KeyValueItem
                  size="small"
                  label="First Day Turnover"
                  value={
                    firstDayTurnover
                      ? `${numericUtil.getDisplayValueForNumber(firstDayTurnover, 1)}x`
                      : '-'
                  }
                />
              </Grid>
            </Grid>
          )}
          <TableContainer>
            <Grid container columnSpacing={3}>
              <Grid item {...tableSectionSizes}>
                <Table size="medium">
                  <TableHead>
                    <TableRow color="text.secondary">
                      <TableCell></TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">%</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performance.slice(0, isAtm ? 5 : 6).map(({ name, value, value2 }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell width="33%">{name}</TableCell>
                          <TableCell align="right" width="33%">
                            {value}
                          </TableCell>
                          <TableCell align="right" width="33%">
                            {value2}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item {...tableSectionSizes}>
                <Table size="medium">
                  {isLargeScreen && (
                    <TableHead>
                      <TableRow color="text.secondary">
                        <TableCell></TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">%</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {performance.slice(isAtm ? 5 : 6).map(({ name, value, value2 }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell width="33%">{name}</TableCell>
                          <TableCell align="right" width="33%">
                            {value}
                          </TableCell>
                          <TableCell align="right" width="33%">
                            {value2}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PerformanceWidget;
