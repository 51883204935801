import styled, { css } from 'styled-components/macro';

/**
 * BlurOverlay absolute positioned blurred overlay
 *
 * we could not use filter due to the lack of support in IE11
 */
const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;

  ${({ theme }) =>
    css`
      z-index: ${theme.zIndex.contentOverlay};
      background-color: ${theme.background.color.white};
    `}
`;

export default BlurOverlay;
