import { datalabApi } from '@cmg/api';
import { numericUtil, Popover, Tag } from '@cmg/common';
import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import * as Types from '../../../../../../../graphql/__generated__';
import { IoiType } from '../../../../../../../types/domain/offering/ioi/constants';
import { CMGTheme } from '../../../../../../../types/vendor/styled-components/styled-components';

enum ParticipationTagLabel {
  ALLO = 'ALLO',
  PASS = 'PASS',
  REG_M = 'REG-M',
}

export type Props = {
  ioiType: datalabApi.IoiType | null;
  allocation: number | null;
  className?: string;
};

/**
 * determines the color of the tag based on the label
 */
const participationColorByLabel = (label: ParticipationTagLabel, theme: CMGTheme) => {
  switch (label) {
    case 'ALLO':
      return theme.brand.color.info;
    case 'PASS':
      return theme.text.color.light;
    case 'REG-M':
      return theme.brand.color.danger;
  }

  return;
};

/**
 * ParticipationTag displays a tag with the participation indicator
 * for the recently priced deal.
 *
 * If an allocation exists for the deal it will display ALLO in blue
 * If the ioiType is PASS it will display PASS in gray
 * If the ioiType is REG_M it will display REG_M in red
 */
const ParticipationTag: React.FC<Props> = ({ ioiType, allocation, className }) => {
  let label: ParticipationTagLabel;

  if (allocation) {
    label = ParticipationTagLabel.ALLO;
  } else if (ioiType === Types.IoiType.Pass || ioiType === Types.IoiType.RegM) {
    label = ParticipationTagLabel[ioiType];
  } else {
    return null;
  }

  return (
    <ThemeConsumer>
      {(theme: CMGTheme) => (
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          content={<div>Allocation in Shares: {numericUtil.formatNumber(allocation, 0)}</div>}
          placement="top"
          disabled={ioiType === IoiType.PASS || ioiType === IoiType.REG_M}
        >
          <Tag
            testId="participation-tag"
            className={className}
            outline
            color={participationColorByLabel(label, theme)}
          >
            {label}
          </Tag>
        </Popover>
      )}
    </ThemeConsumer>
  );
};

export default ParticipationTag;
