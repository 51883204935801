import { numericUtil } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import {
  type Calendar_Offering_IndicationOfInterestFieldsFragment,
  PricingType,
  UnitType,
} from '../graphql';

function formatIoiValue(ioi: Calendar_Offering_IndicationOfInterestFieldsFragment) {
  switch (ioi.unitType) {
    case UnitType.Shares:
      return numericUtil.formatInteger(ioi.shares);
    case UnitType.Dollars:
      return numericUtil.formatCurrencyInMillions(ioi.dollars, true);
    case UnitType.Percentage:
      return numericUtil.formatPercents(ioi.percentage);
    default:
      return null;
  }
}

function getPricing(ioi: Calendar_Offering_IndicationOfInterestFieldsFragment) {
  if (ioi.pricingType === PricingType.Limit) {
    return numericUtil.formatCurrency(ioi.limitPriceUsd);
  }

  return ioi.pricingType;
}

export const SIoiSummary = styled.table`
  text-align: justify;
  text-transform: capitalize;
`;

export type Props = {
  iois: Calendar_Offering_IndicationOfInterestFieldsFragment[] | null | undefined;
};

const IoiSummary: React.FC<Props> = ({ iois }) => (
  <SIoiSummary>
    <tbody>
      {iois &&
        iois.map((ioi, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'right' }}>{formatIoiValue(ioi)}</td>
            <td>&nbsp;@ {getPricing(ioi)}</td>
          </tr>
        ))}
    </tbody>
  </SIoiSummary>
);

export default IoiSummary;
