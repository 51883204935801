import { CheckboxField } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledFiltersRow = styled.div`
  margin: 0 15px 5px;
  align-items: flex-end;
  flex-wrap: wrap;
  > * {
    width: 250px;
    margin-right: 15px;
  }
`;
export const StyledWrapper = styled.div`
  padding-top: 14px;
`;

export const StyledCheckboxField = styled(CheckboxField)`
  div[aria-checked='true'] {
    background-color: ${({ theme }) => theme.color.blue2};
    border: none;
    svg {
      color: ${({ theme }) => theme.color.white};
      margin-top: 3px;
    }
  }
`;
