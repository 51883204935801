import { timeUtil } from '@cmg/common';
import { addWeeks, addYears, previousSaturday, subWeeks, subYears } from 'date-fns';

export const yearsFromNow = (years: number): string =>
  timeUtil.formatAsISODate(addYears(new Date(), years))!;
export const weeksFromNow = (weeks: number): string =>
  timeUtil.formatAsISODate(addWeeks(new Date(), weeks))!;
export const yearsAgo = (years: number): string =>
  timeUtil.formatAsISODate(subYears(new Date(), years))!;
export const weeksAgo = (weeks: number): string =>
  timeUtil.formatAsISODate(subWeeks(new Date(), weeks))!;
export const startOfCurrentYear = (): string => `${new Date().getUTCFullYear()}-01-01`;
export const lastSaturday = () => timeUtil.formatAsISODate(previousSaturday(new Date()));
