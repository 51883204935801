import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  RegularBreakpoints,
  SearchTextField,
  SxProps,
  Theme,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetSECFeedData } from '../model/Shared.model';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const cardStyle: SxProps<Theme> = {
  maxHeight: { xl: 300, lg: 300, md: 100, sm: 100 },
  overflow: 'auto',
  ...cardContentStyle,
};
const listStyle: SxProps<Theme> = { padding: 0 };

const NewsWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const [query, setQuery] = React.useState<string | null>('');
  const news = useGetSECFeedData(offeringProfile);
  const cardTitle = 'SEC Feed';
  const visibleNews = React.useMemo(() => {
    return news.filter(({ description, title }) =>
      title
        .concat(description)
        .toLowerCase()
        .includes((query || '').toLowerCase())
    );
  }, [news, query]);
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {cardTitle}
            </Typography>
          }
          aria-label={cardTitle}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardStyle}>
          <SearchTextField
            label="Search"
            value={query || ''}
            onChange={e => setQuery(e.target.value)}
            placeholder="Search keywords"
          />
          <List sx={listStyle}>
            {visibleNews.map(({ title, url, description }) => {
              return (
                <ListItem key={title} divider={true} disableGutters={true}>
                  <ListItemText>
                    <Link href={url} target="_blank">
                      {title}
                    </Link>
                    <Typography variant="body1" color="text.secondary">
                      {description}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NewsWidget;
