import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SRecentDeals = styled.div`
  display: flex;
  flex-direction: column;
  /*
    Fixes an issue with flexbox and overflow child containers not expanding correctly
    @see {https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/}
  */
  min-height: 0;

  /** IE11 fixes paint issues */
  height: 100%;

  /* consistent width so regardless of list contents we have a reliable minimum size */
  min-width: 280px;
`;

export const STitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCalendarIcon = styled(Icon)`
  margin-right: 15px;
  color: ${({ theme }) => theme.background.color.dark};
`;

export const SListScrollLoading = styled.div.attrs(() => ({
  'data-testid': 'slist-scroll-loading',
}))`
  width: 100%;
  margin: 50px 0;
  text-align: center;
`;

export const SListInitialLoading = styled.div.attrs(() => ({
  'data-testid': 'slist-initial-loading',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SInfiniteList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
