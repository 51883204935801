import React from 'react';

/**
 * SidebarContext saves sidebar state and provides it to the rest of the app
 *
 * currently we save collapsed state but in the future it could be expanded to serve other sidebar state
 */
const SidebarContext = React.createContext<{ collapsed: boolean }>({
  collapsed: false,
});

export default SidebarContext;
