import memoize from 'memoize-one';
import { matchPath } from 'react-router';

import getSideBarConfig, { SidebarConfigArgs, SidebarConfigItem } from './SidebarConfig';

export const getConfigData: (
  locationString,
  userPermissions,
  featureToggles,
  showLiveAndFiled,
  isInternationalOffering,
  offeringType
) => SidebarConfigArgs = memoize(
  (
    locationString,
    userPermissions,
    featureToggles,
    showLiveAndFiled,
    isInternationalOffering,
    offeringType
  ) => {
    const location = JSON.parse(locationString);

    const configData: SidebarConfigArgs = {
      params: {},
      location,
      userPermissions,
      featureToggles,
      showLiveAndFiled,
      isInternationalOffering,
      offeringType,
    };
    return configData;
  }
);

function isItemVisible(item: SidebarConfigItem, configData: SidebarConfigArgs) {
  return item.isHidden ? !item.isHidden(configData) : true;
}

const getVisibleItems = memoize((configData: SidebarConfigArgs) =>
  getSideBarConfig().filter(item => isItemVisible(item, configData))
);

const isItemMatchingFilter = (item: SidebarConfigItem, filter) => {
  return filter === '' || item.label.toLowerCase().includes(filter);
};

export const getMenuItems: (
  filter: string,
  configData: SidebarConfigArgs | null
) => SidebarConfigItem[] = memoize((filter: string, configData: SidebarConfigArgs | null) => {
  const items: SidebarConfigItem[] = [];

  if (configData === null) {
    return items;
  }

  getVisibleItems(configData).forEach(item => {
    // If item isn't parent and matches filter, show it
    if (!item.children && isItemMatchingFilter(item, filter)) {
      items.push(item);
    } else if (item.children) {
      const { children } = item;
      const altItem: SidebarConfigItem = { ...item, children: undefined };

      // If parent matches filter, show all its visible children
      if (isItemMatchingFilter(altItem, filter)) {
        altItem.children = children.filter(child => isItemVisible(child, configData));
      } else {
        // otherwise show visible children which match filter
        altItem.children = children.filter(
          child => isItemVisible(child, configData) && isItemMatchingFilter(child, filter)
        );
      }

      // If parent has children, show it
      if (altItem.children && altItem.children.length > 0) {
        items.push(altItem);
      }
    }
  });

  return items;
});

/**
 * Extracts parameters from current url path by given match strings.
 */
const getPathParams = (paramsMatch: string | string[], pathname: string) => {
  const params = matchPath(pathname, {
    path: paramsMatch,
  });

  return params ? params.params : {};
};

export const getPath = (
  item: SidebarConfigItem,
  configData: SidebarConfigArgs | null,
  paramsMatch: string | string[] = []
) => {
  if (configData === null) {
    return '';
  }
  if (item.getPath) {
    return item.getPath({
      ...configData,
      params: getPathParams(paramsMatch, configData.location.pathname),
    });
  } else if (item.path) {
    return item.path;
  }
  return '';
};

export function isParentActive(item: SidebarConfigItem, configData: SidebarConfigArgs | null) {
  if (!configData || !item.children) {
    return false;
  }

  const pathname = configData.location.pathname.split('?')[0];

  if (item.isActive) {
    return item.isActive(pathname);
  }

  return item.children.some(child => {
    if (child.isActive) {
      return child.isActive(pathname);
    }

    const path = getPath(child, configData, item.paramsMatch).split('?')[0];
    return (
      pathname !== '' &&
      path !== '' &&
      pathname !== '/' &&
      (pathname.includes(path) || path.includes(pathname))
    );
  });
}

export function getActiveParent(configData: SidebarConfigArgs) {
  return getVisibleItems(configData)
    .filter(item => item.children)
    .find(parent => isParentActive(parent, configData));
}
