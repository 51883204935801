import { numericUtil } from '@cmg/common';
import React from 'react';

import { SValueRenderer } from './Renderer.styles';

type Props = {
  value?: number;
  precision?: number;
};

const NumericValueRenderer: React.FC<Props> = ({ value, precision = 2 }) => (
  <SValueRenderer>{numericUtil.formatNumber(value, precision)}</SValueRenderer>
);

export default NumericValueRenderer;
