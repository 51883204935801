import styled from 'styled-components/macro';

import { SNotificationFilterGroupInner } from './NotificationFilterGroup.styles';

export const SNotificationFilter = styled.div<{ hideTree?: boolean }>`
  text-align: left;
  line-height: 24px;
  position: relative;

  ${SNotificationFilterGroupInner} &:before {
    content: '';
    margin-top: 14px;
    margin-left: -7px;
    border-top: ${({ theme }) => theme.border.width.small} solid
      ${({ theme, hideTree }) => (hideTree ? 'transparent' : theme.color.gray7)};
    width: 7px;
    position: absolute;
  }

  ${SNotificationFilterGroupInner} &:after {
    content: '';
    border-left: ${({ theme }) => theme.border.width.small} solid
      ${({ theme, hideTree }) => (hideTree ? 'transparent' : theme.color.gray7)};
    height: 26px;
    bottom: 12px;
    left: -7px;
    position: absolute;
  }

  &:first-child {
    &:after {
      height: 18px;
      bottom: 12px;
    }
  }
`;
