import React from 'react';

import PerformancePercentsWithTooltip from '../../../../../../common/components/format/performance-percents-with-tooltip/PerformancePercentsWithTooltip';
import { OfferingType } from '../../../../../../graphql/__generated__';
import { getFormattedCurrency } from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { CardRowItem, emptyValue, OfferingPerformanceFieldName } from '../types';

export const useGetPerformanceData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { type, pricingCurrency } = offeringProfile.offeringById || {};
    const {
      pctOfferToOpen,
      offerToVwap1Day,
      pctOfferTo1Day,
      pctOfferTo3Day,
      pctOfferTo7Day,
      pctOfferTo14Day,
      pctOfferTo30Day,
      pctOfferTo90Day,
      pctOfferTo180Day,
      pctOfferToOneYear,
      pctOfferToPriorQuarter,
      pctOfferToCurrent,
      openingSharePrice,
      day1VwapPrice,
      day1SharePrice,
      day3SharePrice,
      day7SharePrice,
      day14SharePrice,
      day30SharePrice,
      day90SharePrice,
      day180SharePrice,
      oneYearSharePrice,
      priorQuarterSharePrice,
      currentSharePrice,
    } = offeringProfile.offeringById?.attributes ?? {};
    const pricingCurrencyCode = pricingCurrency as string;
    const precision = 2;
    const isAtm = type === OfferingType.Atm;

    return [
      {
        name: OfferingPerformanceFieldName.pctOfferToOpen,
        value: getFormattedCurrency(pricingCurrencyCode, openingSharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferToOpen}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo1DayVWAP,
        value: getFormattedCurrency(pricingCurrencyCode, day1VwapPrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={offerToVwap1Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
        isHidden: isAtm,
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo1Day,
        value: getFormattedCurrency(pricingCurrencyCode, day1SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo1Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo3Day,
        value: getFormattedCurrency(pricingCurrencyCode, day3SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo3Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo7Day,
        value: getFormattedCurrency(pricingCurrencyCode, day7SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo7Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo14Day,
        value: getFormattedCurrency(pricingCurrencyCode, day14SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo14Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo30Day,
        value: getFormattedCurrency(pricingCurrencyCode, day30SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo30Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo90Day,
        value: getFormattedCurrency(pricingCurrencyCode, day90SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo90Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo180Day,
        value: getFormattedCurrency(pricingCurrencyCode, day180SharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo180Day}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo1Year,
        value: getFormattedCurrency(pricingCurrencyCode, oneYearSharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferToOneYear}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
        isHidden: isAtm,
      },
      {
        name: OfferingPerformanceFieldName.pctOfferToPriorQuarter,
        value: getFormattedCurrency(pricingCurrencyCode, priorQuarterSharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferToPriorQuarter}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
        isHidden: isAtm,
      },
      {
        name: OfferingPerformanceFieldName.pctOfferToCurrent,
        value: getFormattedCurrency(pricingCurrencyCode, currentSharePrice),
        value2: (
          <PerformancePercentsWithTooltip
            value={pctOfferToCurrent}
            precision={precision}
            nullValue={emptyValue}
          />
        ),
      },
    ].filter(row => !row.isHidden);
  }, [offeringProfile.offeringById]);
