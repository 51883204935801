import { createFormikField, DateRangePresetTypes, FieldControlProps, Select } from '@cmg/common';
import { datalabYearFromFilterSelector, datalabYearToFilterSelector } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

const SYearRange = styled.div`
  display: flex;
`;
const StyledYearSelect = styled(Select)`
  flex: 1 1 auto;
  min-width: auto;

  &:last-child {
    margin-left: -1px;
  }
`;

export function styledConfig({ isFirst, isLast }: { isFirst?: boolean; isLast?: boolean }) {
  return {
    control: ({ menuIsOpen }, originalStyles) => ({
      borderBottomRightRadius: isFirst || menuIsOpen ? 0 : originalStyles.borderBottomRightRadius,
      borderTopRightRadius: isFirst ? 0 : originalStyles.borderTopRightRadius,
      borderBottomLeftRadius: isLast || menuIsOpen ? 0 : originalStyles.borderBottomLeftRadius,
      borderTopLeftRadius: isLast ? 0 : originalStyles.borderTopRightRadius,
    }),
  };
}

export function getOptions(start: number, end: number, suffix: '-01-01' | '-12-31') {
  return Array.from(Array(end - start + 1)).map((item, index) => ({
    value: `${end - index}${suffix}`,
    label: `${end - index}`,
  }));
}

type YearRangeValue = {
  end: string;
  start: string;
  type: DateRangePresetTypes.CUSTOM;
};

export interface Props extends FieldControlProps<YearRangeValue, YearRangeValue> {
  value: YearRangeValue;
}

export default class DatalabYearsRangeFilter extends React.Component<Props> {
  handleOnStartChange = newStart => {
    const { value, onChange } = this.props;
    onChange && onChange({ start: newStart, end: value.end, type: DateRangePresetTypes.CUSTOM });
  };

  handleOnEndChange = newEnd => {
    const { value, onChange } = this.props;
    onChange && onChange({ start: value.start, end: newEnd, type: DateRangePresetTypes.CUSTOM });
  };

  render() {
    const { value } = this.props;

    const startYear = 2006;
    const currentYear = new Date().getFullYear();

    const optionsStart = getOptions(startYear, currentYear, '-01-01');
    const optionsEnd = getOptions(startYear, currentYear, '-12-31');

    return (
      <SYearRange>
        <StyledYearSelect
          isClearable={false}
          placeholder="Start Year"
          options={optionsStart}
          styledConfig={styledConfig({ isFirst: true })}
          value={value.start}
          onChange={this.handleOnStartChange}
          testId={datalabYearFromFilterSelector.testId}
        />
        <StyledYearSelect
          isClearable={false}
          placeholder="End Year"
          options={optionsEnd}
          styledConfig={styledConfig({ isLast: true })}
          value={value.end}
          onChange={this.handleOnEndChange}
          testId={datalabYearToFilterSelector.testId}
        />
      </SYearRange>
    );
  }
}

export const YearRangePickerField = createFormikField<
  YearRangeValue,
  YearRangeValue,
  HTMLInputElement,
  Props
>(DatalabYearsRangeFilter);
