import { Box, Column, Row } from '@cmg/common';
import {
  capitalRaisedMatrixSelector,
  capitalRaisedMatrixYearWidgetSelector,
} from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import ColumnToggler from '../../../../obsolete/components/ui/table/shared/enhancers/ColumnToggler';
import OrderGroup from '../../../../obsolete/components/ui/table/shared/enhancers/OrderGroup';
import { applyOrdering } from '../../../../obsolete/components/ui/table/shared/table-helpers';
import StandardTable from '../../../../obsolete/components/ui/table/standard-table/StandardTable';
import WidgetWrapper from '../../../../obsolete/components/ui/table/widget-wrapper/WidgetWrapper';
import {
  selectCapitalRaisedMatrixCharts,
  selectVisibleColumns,
  setVisibleColumns,
} from '../../ducks';
import { isDownloadExcelOn } from '../../model/utils';

const SMatrixTables = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 15px;
`;
const SMatrixTable = styled.div`
  flex-grow: 1;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  table thead tr th button.title {
    white-space: nowrap;
  }
`;
const SYear = styled.div`
  font-size: 15px;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  text-align: center;
`;

const matrixTabs = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
];

type OwnProps = {
  columnsConfig: any;
  staticColumns?: string[];
  filtersCollapsed: boolean;
  downloadExport: Function;
  orderBy: string;
  orderByType: string;
  title: string;
};

const mapStateToProps = state => ({
  datalab: selectCapitalRaisedMatrixCharts(state),
  visibleColumns: selectVisibleColumns(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setVisibleColumns,
    },
    dispatch
  ),
});

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
  activeTab: { value: string; label: string };
};

export class CapitalRaisedMatrixScreenComponent extends React.Component<Props, State> {
  state: State = {
    activeTab: matrixTabs[1],
  };

  onChangeTab = activeTab =>
    new Promise<void>(resolve => {
      this.setState({ activeTab }, () => resolve());
    });

  handleToggleViewableFields = (visibleColumns: string[]) => {
    const { actions } = this.props;
    actions.setVisibleColumns({ visibleColumns });
  };

  render() {
    const {
      downloadExport,
      filtersCollapsed,
      columnsConfig,
      datalab,
      visibleColumns,
      orderBy,
      orderByType,
      title,
      staticColumns,
    } = this.props;

    const { activeTab } = this.state;

    const years = datalab[activeTab.value];
    const sortedYears = Object.keys(years).sort((a, b) => parseInt(b) - parseInt(a));

    return (
      <Row testId={capitalRaisedMatrixSelector.testId}>
        <Column xs={24}>
          <Box>
            <ColumnToggler
              columnsConfig={columnsConfig}
              handleToggleViewableFields={this.handleToggleViewableFields}
              visibleColumns={visibleColumns}
            >
              {({
                columns,
                simpleColumns,
                toggleColumnVisibility,
                visibleColumns: visibleColumnsComp,
              }) => (
                <OrderGroup
                  orderBy={orderBy}
                  orderByType={orderByType}
                  visibleColumns={visibleColumnsComp}
                >
                  {({ handleOrderBy, orderBy: orderByComp, orderByType: orderByTypeComp }) => (
                    <WidgetWrapper
                      activeTab={activeTab}
                      downloadExport={isDownloadExcelOn() ? downloadExport : undefined}
                      fillViewport
                      filtersCollapsed={filtersCollapsed}
                      hidePaginationBottom
                      hidePaginationTop
                      onChangeTab={this.onChangeTab}
                      screen="capital_raised_matrix"
                      simpleColumns={simpleColumns}
                      tabs={matrixTabs}
                      title={title}
                      toggleColumnVisibility={toggleColumnVisibility}
                      staticColumns={staticColumns}
                    >
                      <SMatrixTables>
                        {sortedYears.map(year => {
                          const data = years[year];
                          const orderedRows = applyOrdering(
                            data.rows,
                            orderByComp,
                            orderByTypeComp
                          );

                          return (
                            <SMatrixTable
                              key={year}
                              data-test-id={capitalRaisedMatrixYearWidgetSelector(year).testId}
                            >
                              <SYear>{year}</SYear>
                              <StandardTable
                                columns={columns}
                                handleOrderBy={handleOrderBy}
                                orderBy={orderByComp}
                                orderByType={orderByTypeComp}
                                responsive={false}
                                rows={data.rows}
                                simpleColumns={simpleColumns}
                                summaries={data.summaries}
                                visibleRows={orderedRows}
                              />
                            </SMatrixTable>
                          );
                        })}
                      </SMatrixTables>
                    </WidgetWrapper>
                  )}
                </OrderGroup>
              )}
            </ColumnToggler>
          </Box>
        </Column>
      </Row>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CapitalRaisedMatrixScreenComponent);
