/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import {
  Atm_Attributes_FieldsFragmentDoc,
  Atm_Selldown_FieldsFragmentDoc,
  Atm_Selldown_Term_FieldsFragmentDoc,
} from '../../../../common/graphql/__generated__/commonFragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanyProfile_IssuerProfile_FieldsFragment = {
  __typename?: 'IssuerProfile';
  id: string;
  cik?: string | null;
  contactEmail?: string | null;
  description?: string | null;
  industryGroupName?: string | null;
  industryName?: string | null;
  headquarters?: string | null;
  hqHomepage?: string | null;
  hqPhone?: string | null;
  hqCity?: string | null;
  hqProvince?: string | null;
  sectorName?: string | null;
  standardName?: string | null;
  yearOfEstablishment?: number | null;
  fiscalYearEnd?: Types.Month | null;
  auditor?: string | null;
  exchangeCode?: Types.ExchangeCode | null;
  sicCode?: { __typename?: 'SicCode'; code?: number | null; name?: string | null } | null;
  naicsCode?: { __typename?: 'NaicsCode'; code?: number | null; name?: string | null } | null;
};

export type CompanyProfile_OfferingAttributes_FieldsFragment = {
  __typename?: 'OfferingAttributes';
  offeringId: string;
  leftLeadId?: string | null;
  leftLeadName?: string | null;
  exchangeCountry?: Types.Country | null;
  pricingCurrency?: Types.Currency | null;
  latestGrossProceedsTotalUsd?: number | null;
  latestGrossProceedsTotal?: number | null;
  latestSizeInSecuritiesTotal?: number | null;
  marketCapAtPricing?: number | null;
  marketCapAtPricingUsd?: number | null;
  pctMarketCapAtPricing?: number | null;
  latestOfferPrice?: number | null;
  latestOfferPriceUsd?: number | null;
  netPrice?: number | null;
  splitAdjustedOfferPrice?: number | null;
  lastTradeBeforeOffer?: number | null;
  lastTradeBeforeOfferUsd?: number | null;
  pctToLastTrade?: number | null;
  openingSharePrice?: number | null;
  pctOfferToOpen?: number | null;
  day1SharePrice?: number | null;
  pctOfferTo1Day?: number | null;
  currentSharePrice?: number | null;
  pctOfferToCurrent?: number | null;
  marketCapPreOffering?: number | null;
  pctMarketCapPreOffering?: number | null;
  isSupersededBy?: string | null;
};

export type CompanyProfile_FundAllocation_FieldsFragment = {
  __typename?: 'FundAllocation';
  id: string;
  shares?: number | null;
  percentOfFirmAllocation?: number | null;
  fund?: { __typename?: 'Fund'; id: string; name?: string | null } | null;
};

export type CompanyProfile_Manager_FieldsFragment = {
  __typename?: 'OfferingManager';
  name?: string | null;
  managerId?: string | null;
  pctUnderwritingBaseShares?: number | null;
  roleDisplayName?: string | null;
};

export type CompanyProfile_LastAllocation_FieldsFragment = {
  __typename?: 'Allocation';
  id: string;
  ioiType?: Types.IoiType | null;
  shares?: number | null;
  fillPercentage?: number | null;
};

export type CompanyProfile_Offering_FieldsFragment = {
  __typename?: 'Offering';
  id: string;
  type: Types.OfferingType;
  typeDisplayName?: string | null;
  status: Types.OfferingStatus;
  statusDisplayName?: string | null;
  publicFilingDate?: string | null;
  firstTradeDate?: string | null;
  marketTimingDisplayName?: string | null;
  terminatedDate?: string | null;
  statusRelevantDate?: string | null;
  pctOfDeal?: number | null;
  attributes?: {
    __typename?: 'OfferingAttributes';
    offeringId: string;
    leftLeadId?: string | null;
    leftLeadName?: string | null;
    exchangeCountry?: Types.Country | null;
    pricingCurrency?: Types.Currency | null;
    latestGrossProceedsTotalUsd?: number | null;
    latestGrossProceedsTotal?: number | null;
    latestSizeInSecuritiesTotal?: number | null;
    marketCapAtPricing?: number | null;
    marketCapAtPricingUsd?: number | null;
    pctMarketCapAtPricing?: number | null;
    latestOfferPrice?: number | null;
    latestOfferPriceUsd?: number | null;
    netPrice?: number | null;
    splitAdjustedOfferPrice?: number | null;
    lastTradeBeforeOffer?: number | null;
    lastTradeBeforeOfferUsd?: number | null;
    pctToLastTrade?: number | null;
    openingSharePrice?: number | null;
    pctOfferToOpen?: number | null;
    day1SharePrice?: number | null;
    pctOfferTo1Day?: number | null;
    currentSharePrice?: number | null;
    pctOfferToCurrent?: number | null;
    marketCapPreOffering?: number | null;
    pctMarketCapPreOffering?: number | null;
    isSupersededBy?: string | null;
  } | null;
  lastAllocation?: {
    __typename?: 'Allocation';
    id: string;
    ioiType?: Types.IoiType | null;
    shares?: number | null;
    fillPercentage?: number | null;
  } | null;
  fundAllocations?: Array<{
    __typename?: 'FundAllocation';
    id: string;
    shares?: number | null;
    percentOfFirmAllocation?: number | null;
    fund?: { __typename?: 'Fund'; id: string; name?: string | null } | null;
  }> | null;
  managers: Array<{
    __typename?: 'OfferingManager';
    name?: string | null;
    managerId?: string | null;
    pctUnderwritingBaseShares?: number | null;
    roleDisplayName?: string | null;
  }>;
  atmAttributes?: {
    __typename?: 'AtmAttributes';
    pctGrossSpread?: number | null;
    lastTradeBeforeFilingSplitAdjusted?: number | null;
    latestAsOfDate?: string | null;
    latestProgramSize?: number | null;
    latestProgramSizeInSecurities?: number | null;
    announcedProgramSize?: number | null;
    announcedProgramSizeInSecurities?: number | null;
    totalSales?: number | null;
    totalSalesInSecurities?: number | null;
    totalProgramRemaining?: number | null;
    totalProgramRemainingInSecurities?: number | null;
    structure: Array<{
      __typename?: 'AtmStructure';
      selldownId?: string | null;
      programRemainingInSecurities?: number | null;
      type: Types.AtmStructureType;
      typeDisplayName?: string | null;
      announcementDate?: string | null;
      asOfDate?: string | null;
      sales?: number | null;
      salesInSecurities?: number | null;
      averagePrice?: number | null;
      programSize?: number | null;
      programSizeInSecurities?: number | null;
      programRemaining?: number | null;
    }>;
  } | null;
  terms: Array<{
    __typename?: 'Term';
    id?: string | null;
    type?: Types.TermType | null;
    revisedDate?: string | null;
    atmProgramSize?: number | null;
    atmProgramSizeInSecurities?: number | null;
  }>;
};

export type CompanyProfile_StockProfile_FieldsFragment = {
  __typename?: 'StockProfile';
  id: string;
  lastClose?: number | null;
  fiftyTwoWeekHigh?: number | null;
  fiftyTwoWeekLow?: number | null;
  pctTo52WeekHigh?: number | null;
  pctTo52WeekLow?: number | null;
  marketCap?: number | null;
  enterpriseValue?: number | null;
  averageDailyVolume4Weeks?: number | null;
  sharesOutstanding?: number | null;
  pctPriceChange1Week?: number | null;
  pctPriceChangeMtd?: number | null;
  pctPriceChangeYtd?: number | null;
  pctPriceChange52Weeks?: number | null;
  currency: Types.Currency;
};

export type CompanyProfile_Issuer_FieldsFragment = {
  __typename?: 'Issuer';
  id?: string | null;
  name?: string | null;
  primarySymbol?: string | null;
  primaryCusip?: string | null;
  primaryIsin?: string | null;
  hasSplits?: boolean | null;
  sectorDisplayName?: string | null;
  subSectorDisplayName?: string | null;
  offeringsCount?: number | null;
  capitalRaised?: number | null;
  capitalRaisedUsd?: number | null;
  isDelisted?: boolean | null;
  customSectors?: {
    __typename?: 'CustomSectorIssuer';
    id: string;
    customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
    customSubSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
  } | null;
  profile?: {
    __typename?: 'IssuerProfile';
    id: string;
    cik?: string | null;
    contactEmail?: string | null;
    description?: string | null;
    industryGroupName?: string | null;
    industryName?: string | null;
    headquarters?: string | null;
    hqHomepage?: string | null;
    hqPhone?: string | null;
    hqCity?: string | null;
    hqProvince?: string | null;
    sectorName?: string | null;
    standardName?: string | null;
    yearOfEstablishment?: number | null;
    fiscalYearEnd?: Types.Month | null;
    auditor?: string | null;
    exchangeCode?: Types.ExchangeCode | null;
    sicCode?: { __typename?: 'SicCode'; code?: number | null; name?: string | null } | null;
    naicsCode?: { __typename?: 'NaicsCode'; code?: number | null; name?: string | null } | null;
  } | null;
  offerings: Array<{
    __typename?: 'Offering';
    id: string;
    type: Types.OfferingType;
    typeDisplayName?: string | null;
    status: Types.OfferingStatus;
    statusDisplayName?: string | null;
    publicFilingDate?: string | null;
    firstTradeDate?: string | null;
    marketTimingDisplayName?: string | null;
    terminatedDate?: string | null;
    statusRelevantDate?: string | null;
    pctOfDeal?: number | null;
    attributes?: {
      __typename?: 'OfferingAttributes';
      offeringId: string;
      leftLeadId?: string | null;
      leftLeadName?: string | null;
      exchangeCountry?: Types.Country | null;
      pricingCurrency?: Types.Currency | null;
      latestGrossProceedsTotalUsd?: number | null;
      latestGrossProceedsTotal?: number | null;
      latestSizeInSecuritiesTotal?: number | null;
      marketCapAtPricing?: number | null;
      marketCapAtPricingUsd?: number | null;
      pctMarketCapAtPricing?: number | null;
      latestOfferPrice?: number | null;
      latestOfferPriceUsd?: number | null;
      netPrice?: number | null;
      splitAdjustedOfferPrice?: number | null;
      lastTradeBeforeOffer?: number | null;
      lastTradeBeforeOfferUsd?: number | null;
      pctToLastTrade?: number | null;
      openingSharePrice?: number | null;
      pctOfferToOpen?: number | null;
      day1SharePrice?: number | null;
      pctOfferTo1Day?: number | null;
      currentSharePrice?: number | null;
      pctOfferToCurrent?: number | null;
      marketCapPreOffering?: number | null;
      pctMarketCapPreOffering?: number | null;
      isSupersededBy?: string | null;
    } | null;
    lastAllocation?: {
      __typename?: 'Allocation';
      id: string;
      ioiType?: Types.IoiType | null;
      shares?: number | null;
      fillPercentage?: number | null;
    } | null;
    fundAllocations?: Array<{
      __typename?: 'FundAllocation';
      id: string;
      shares?: number | null;
      percentOfFirmAllocation?: number | null;
      fund?: { __typename?: 'Fund'; id: string; name?: string | null } | null;
    }> | null;
    managers: Array<{
      __typename?: 'OfferingManager';
      name?: string | null;
      managerId?: string | null;
      pctUnderwritingBaseShares?: number | null;
      roleDisplayName?: string | null;
    }>;
    atmAttributes?: {
      __typename?: 'AtmAttributes';
      pctGrossSpread?: number | null;
      lastTradeBeforeFilingSplitAdjusted?: number | null;
      latestAsOfDate?: string | null;
      latestProgramSize?: number | null;
      latestProgramSizeInSecurities?: number | null;
      announcedProgramSize?: number | null;
      announcedProgramSizeInSecurities?: number | null;
      totalSales?: number | null;
      totalSalesInSecurities?: number | null;
      totalProgramRemaining?: number | null;
      totalProgramRemainingInSecurities?: number | null;
      structure: Array<{
        __typename?: 'AtmStructure';
        selldownId?: string | null;
        programRemainingInSecurities?: number | null;
        type: Types.AtmStructureType;
        typeDisplayName?: string | null;
        announcementDate?: string | null;
        asOfDate?: string | null;
        sales?: number | null;
        salesInSecurities?: number | null;
        averagePrice?: number | null;
        programSize?: number | null;
        programSizeInSecurities?: number | null;
        programRemaining?: number | null;
      }>;
    } | null;
    terms: Array<{
      __typename?: 'Term';
      id?: string | null;
      type?: Types.TermType | null;
      revisedDate?: string | null;
      atmProgramSize?: number | null;
      atmProgramSizeInSecurities?: number | null;
    }>;
  }>;
  stockProfile?: {
    __typename?: 'StockProfile';
    id: string;
    lastClose?: number | null;
    fiftyTwoWeekHigh?: number | null;
    fiftyTwoWeekLow?: number | null;
    pctTo52WeekHigh?: number | null;
    pctTo52WeekLow?: number | null;
    marketCap?: number | null;
    enterpriseValue?: number | null;
    averageDailyVolume4Weeks?: number | null;
    sharesOutstanding?: number | null;
    pctPriceChange1Week?: number | null;
    pctPriceChangeMtd?: number | null;
    pctPriceChangeYtd?: number | null;
    pctPriceChange52Weeks?: number | null;
    currency: Types.Currency;
  } | null;
};

export type CompanyProfileQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  hasIoiPermission: Types.Scalars['Boolean'];
  hasFundIoiPermission: Types.Scalars['Boolean'];
  hasSellDownPermission: Types.Scalars['Boolean'];
  hasCustomSectorsPermission: Types.Scalars['Boolean'];
  hasAtmPermission: Types.Scalars['Boolean'];
}>;

export type CompanyProfileQuery = {
  __typename?: 'Query';
  issuerById?: {
    __typename?: 'Issuer';
    id?: string | null;
    name?: string | null;
    primarySymbol?: string | null;
    primaryCusip?: string | null;
    primaryIsin?: string | null;
    hasSplits?: boolean | null;
    sectorDisplayName?: string | null;
    subSectorDisplayName?: string | null;
    offeringsCount?: number | null;
    capitalRaised?: number | null;
    capitalRaisedUsd?: number | null;
    isDelisted?: boolean | null;
    customSectors?: {
      __typename?: 'CustomSectorIssuer';
      id: string;
      customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
      customSubSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
    } | null;
    profile?: {
      __typename?: 'IssuerProfile';
      id: string;
      cik?: string | null;
      contactEmail?: string | null;
      description?: string | null;
      industryGroupName?: string | null;
      industryName?: string | null;
      headquarters?: string | null;
      hqHomepage?: string | null;
      hqPhone?: string | null;
      hqCity?: string | null;
      hqProvince?: string | null;
      sectorName?: string | null;
      standardName?: string | null;
      yearOfEstablishment?: number | null;
      fiscalYearEnd?: Types.Month | null;
      auditor?: string | null;
      exchangeCode?: Types.ExchangeCode | null;
      sicCode?: { __typename?: 'SicCode'; code?: number | null; name?: string | null } | null;
      naicsCode?: { __typename?: 'NaicsCode'; code?: number | null; name?: string | null } | null;
    } | null;
    offerings: Array<{
      __typename?: 'Offering';
      id: string;
      type: Types.OfferingType;
      typeDisplayName?: string | null;
      status: Types.OfferingStatus;
      statusDisplayName?: string | null;
      publicFilingDate?: string | null;
      firstTradeDate?: string | null;
      marketTimingDisplayName?: string | null;
      terminatedDate?: string | null;
      statusRelevantDate?: string | null;
      pctOfDeal?: number | null;
      attributes?: {
        __typename?: 'OfferingAttributes';
        offeringId: string;
        leftLeadId?: string | null;
        leftLeadName?: string | null;
        exchangeCountry?: Types.Country | null;
        pricingCurrency?: Types.Currency | null;
        latestGrossProceedsTotalUsd?: number | null;
        latestGrossProceedsTotal?: number | null;
        latestSizeInSecuritiesTotal?: number | null;
        marketCapAtPricing?: number | null;
        marketCapAtPricingUsd?: number | null;
        pctMarketCapAtPricing?: number | null;
        latestOfferPrice?: number | null;
        latestOfferPriceUsd?: number | null;
        netPrice?: number | null;
        splitAdjustedOfferPrice?: number | null;
        lastTradeBeforeOffer?: number | null;
        lastTradeBeforeOfferUsd?: number | null;
        pctToLastTrade?: number | null;
        openingSharePrice?: number | null;
        pctOfferToOpen?: number | null;
        day1SharePrice?: number | null;
        pctOfferTo1Day?: number | null;
        currentSharePrice?: number | null;
        pctOfferToCurrent?: number | null;
        marketCapPreOffering?: number | null;
        pctMarketCapPreOffering?: number | null;
        isSupersededBy?: string | null;
      } | null;
      lastAllocation?: {
        __typename?: 'Allocation';
        id: string;
        ioiType?: Types.IoiType | null;
        shares?: number | null;
        fillPercentage?: number | null;
      } | null;
      fundAllocations?: Array<{
        __typename?: 'FundAllocation';
        id: string;
        shares?: number | null;
        percentOfFirmAllocation?: number | null;
        fund?: { __typename?: 'Fund'; id: string; name?: string | null } | null;
      }> | null;
      managers: Array<{
        __typename?: 'OfferingManager';
        name?: string | null;
        managerId?: string | null;
        pctUnderwritingBaseShares?: number | null;
        roleDisplayName?: string | null;
      }>;
      atmAttributes?: {
        __typename?: 'AtmAttributes';
        pctGrossSpread?: number | null;
        lastTradeBeforeFilingSplitAdjusted?: number | null;
        latestAsOfDate?: string | null;
        latestProgramSize?: number | null;
        latestProgramSizeInSecurities?: number | null;
        announcedProgramSize?: number | null;
        announcedProgramSizeInSecurities?: number | null;
        totalSales?: number | null;
        totalSalesInSecurities?: number | null;
        totalProgramRemaining?: number | null;
        totalProgramRemainingInSecurities?: number | null;
        structure: Array<{
          __typename?: 'AtmStructure';
          selldownId?: string | null;
          programRemainingInSecurities?: number | null;
          type: Types.AtmStructureType;
          typeDisplayName?: string | null;
          announcementDate?: string | null;
          asOfDate?: string | null;
          sales?: number | null;
          salesInSecurities?: number | null;
          averagePrice?: number | null;
          programSize?: number | null;
          programSizeInSecurities?: number | null;
          programRemaining?: number | null;
        }>;
      } | null;
      terms: Array<{
        __typename?: 'Term';
        id?: string | null;
        type?: Types.TermType | null;
        revisedDate?: string | null;
        atmProgramSize?: number | null;
        atmProgramSizeInSecurities?: number | null;
      }>;
    }>;
    stockProfile?: {
      __typename?: 'StockProfile';
      id: string;
      lastClose?: number | null;
      fiftyTwoWeekHigh?: number | null;
      fiftyTwoWeekLow?: number | null;
      pctTo52WeekHigh?: number | null;
      pctTo52WeekLow?: number | null;
      marketCap?: number | null;
      enterpriseValue?: number | null;
      averageDailyVolume4Weeks?: number | null;
      sharesOutstanding?: number | null;
      pctPriceChange1Week?: number | null;
      pctPriceChangeMtd?: number | null;
      pctPriceChangeYtd?: number | null;
      pctPriceChange52Weeks?: number | null;
      currency: Types.Currency;
    } | null;
  } | null;
};

export const CompanyProfile_IssuerProfile_FieldsFragmentDoc = gql`
  fragment CompanyProfile_IssuerProfile_Fields on IssuerProfile {
    id
    cik
    contactEmail
    description
    industryGroupName
    industryName
    headquarters
    hqHomepage
    hqPhone
    hqCity
    hqProvince
    sectorName
    standardName
    yearOfEstablishment
    fiscalYearEnd
    auditor
    exchangeCode
    sicCode {
      code
      name
    }
    naicsCode {
      code
      name
    }
  }
`;
export const CompanyProfile_OfferingAttributes_FieldsFragmentDoc = gql`
  fragment CompanyProfile_OfferingAttributes_Fields on OfferingAttributes {
    offeringId
    leftLeadId
    leftLeadName
    exchangeCountry
    pricingCurrency
    latestGrossProceedsTotalUsd
    latestGrossProceedsTotal
    latestSizeInSecuritiesTotal
    marketCapAtPricing
    marketCapAtPricingUsd
    pctMarketCapAtPricing
    latestOfferPrice
    latestOfferPriceUsd
    netPrice
    splitAdjustedOfferPrice
    lastTradeBeforeOffer
    lastTradeBeforeOfferUsd
    pctToLastTrade
    openingSharePrice
    pctOfferToOpen
    day1SharePrice
    pctOfferTo1Day
    currentSharePrice
    pctOfferToCurrent
    marketCapPreOffering
    pctMarketCapPreOffering
    isSupersededBy
  }
`;
export const CompanyProfile_LastAllocation_FieldsFragmentDoc = gql`
  fragment CompanyProfile_LastAllocation_Fields on Allocation {
    id
    ioiType
    shares
    fillPercentage
  }
`;
export const CompanyProfile_FundAllocation_FieldsFragmentDoc = gql`
  fragment CompanyProfile_FundAllocation_Fields on FundAllocation {
    id
    shares
    percentOfFirmAllocation
    fund {
      id
      name
    }
  }
`;
export const CompanyProfile_Manager_FieldsFragmentDoc = gql`
  fragment CompanyProfile_Manager_Fields on OfferingManager {
    name
    managerId
    pctUnderwritingBaseShares
    roleDisplayName
  }
`;
export const CompanyProfile_Offering_FieldsFragmentDoc = gql`
  fragment CompanyProfile_Offering_Fields on Offering {
    id
    type
    typeDisplayName
    status
    statusDisplayName
    publicFilingDate
    firstTradeDate
    marketTimingDisplayName
    terminatedDate
    statusRelevantDate
    pctOfDeal @include(if: $hasIoiPermission)
    attributes {
      ...CompanyProfile_OfferingAttributes_Fields
    }
    lastAllocation @include(if: $hasIoiPermission) {
      ...CompanyProfile_LastAllocation_Fields
    }
    fundAllocations @include(if: $hasFundIoiPermission) {
      ...CompanyProfile_FundAllocation_Fields
    }
    managers {
      ...CompanyProfile_Manager_Fields
    }
    atmAttributes @include(if: $hasAtmPermission) {
      ...Atm_Attributes_Fields
      ...Atm_Selldown_Fields @include(if: $hasSellDownPermission)
    }
    terms {
      id
      type
      ...Atm_Selldown_Term_Fields @include(if: $hasAtmPermission)
    }
  }
  ${CompanyProfile_OfferingAttributes_FieldsFragmentDoc}
  ${CompanyProfile_LastAllocation_FieldsFragmentDoc}
  ${CompanyProfile_FundAllocation_FieldsFragmentDoc}
  ${CompanyProfile_Manager_FieldsFragmentDoc}
  ${Atm_Attributes_FieldsFragmentDoc}
  ${Atm_Selldown_FieldsFragmentDoc}
  ${Atm_Selldown_Term_FieldsFragmentDoc}
`;
export const CompanyProfile_StockProfile_FieldsFragmentDoc = gql`
  fragment CompanyProfile_StockProfile_Fields on StockProfile {
    id
    lastClose
    fiftyTwoWeekHigh
    fiftyTwoWeekLow
    pctTo52WeekHigh
    pctTo52WeekLow
    marketCap
    enterpriseValue
    averageDailyVolume4Weeks
    sharesOutstanding
    pctPriceChange1Week
    pctPriceChangeMtd
    pctPriceChangeYtd
    pctPriceChange52Weeks
    currency
  }
`;
export const CompanyProfile_Issuer_FieldsFragmentDoc = gql`
  fragment CompanyProfile_Issuer_Fields on Issuer {
    id
    name
    primarySymbol
    primaryCusip
    primaryIsin
    hasSplits
    sectorDisplayName
    subSectorDisplayName
    customSectors @include(if: $hasCustomSectorsPermission) {
      id
      customSector {
        id
        name
      }
      customSubSector {
        id
        name
      }
    }
    profile {
      ...CompanyProfile_IssuerProfile_Fields
    }
    offerings {
      ...CompanyProfile_Offering_Fields
    }
    offeringsCount
    capitalRaised
    capitalRaisedUsd
    isDelisted
    stockProfile {
      ...CompanyProfile_StockProfile_Fields
    }
  }
  ${CompanyProfile_IssuerProfile_FieldsFragmentDoc}
  ${CompanyProfile_Offering_FieldsFragmentDoc}
  ${CompanyProfile_StockProfile_FieldsFragmentDoc}
`;
export const CompanyProfileDocument = gql`
  query CompanyProfile(
    $id: ID!
    $hasIoiPermission: Boolean!
    $hasFundIoiPermission: Boolean!
    $hasSellDownPermission: Boolean!
    $hasCustomSectorsPermission: Boolean!
    $hasAtmPermission: Boolean!
  ) {
    issuerById(id: $id) {
      ...CompanyProfile_Issuer_Fields
    }
  }
  ${CompanyProfile_Issuer_FieldsFragmentDoc}
`;

/**
 * __useCompanyProfileQuery__
 *
 * To run a query within a React component, call `useCompanyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hasIoiPermission: // value for 'hasIoiPermission'
 *      hasFundIoiPermission: // value for 'hasFundIoiPermission'
 *      hasSellDownPermission: // value for 'hasSellDownPermission'
 *      hasCustomSectorsPermission: // value for 'hasCustomSectorsPermission'
 *      hasAtmPermission: // value for 'hasAtmPermission'
 *   },
 * });
 */
export function useCompanyProfileQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyProfileQuery, CompanyProfileQueryVariables> &
    ({ variables: CompanyProfileQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyProfileQuery, CompanyProfileQueryVariables>(
    CompanyProfileDocument,
    options
  );
}
export function useCompanyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyProfileQuery, CompanyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyProfileQuery, CompanyProfileQueryVariables>(
    CompanyProfileDocument,
    options
  );
}
export function useCompanyProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyProfileQuery, CompanyProfileQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyProfileQuery, CompanyProfileQueryVariables>(
    CompanyProfileDocument,
    options
  );
}
export type CompanyProfileQueryHookResult = ReturnType<typeof useCompanyProfileQuery>;
export type CompanyProfileLazyQueryHookResult = ReturnType<typeof useCompanyProfileLazyQuery>;
export type CompanyProfileSuspenseQueryHookResult = ReturnType<
  typeof useCompanyProfileSuspenseQuery
>;
export type CompanyProfileQueryResult = Apollo.QueryResult<
  CompanyProfileQuery,
  CompanyProfileQueryVariables
>;
