import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { BarChart, BarChartProps, CustomLegend } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      mean: number;
      median: number;
      count: number;
    };
  }[];
};

export function transformData(chartData: Props['chartData'] = []): BarChartProps['data'] {
  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        label: 'Mean',
        data: chartData.map(item => item.values.mean),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Median',
        data: chartData.map(item => item.values.median),
        backgroundColor: barChartColors.light,
      },
    ],
  };
}

export function getOptions(): BarChartProps['options'] {
  return {
    scales: {
      y: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatNumber(value, 1) : value,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;
            const { label } = dataset;
            return `${label ? `${label}: ` : ''}${parsed.y} Days`;
          },
        },
      },
    },
  };
}

export default function WidgetHistoricalDaysConfidentialToPublicDate({ chartData = [] }: Props) {
  const transformedData = React.useMemo(() => transformData(chartData), [chartData]);
  const options = React.useMemo(() => getOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title="Days Between Confidential and Public Filing" />

      <Box.Content>
        <SChart fixedHeight>
          <BarChart barSizingMode="CMGCenteredBarPlugin" data={transformedData} options={options} />
        </SChart>

        <CustomLegend chartData={transformedData} chartType="bar" />
      </Box.Content>
    </SDatalabChart>
  );
}
