import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useShared_CustomSectorListQuery } from '../../../shared/sectors/graphql/__generated__/Shared_CustomSectors';
import { mapSectorsToOptions } from '../../../shared/sectors/sectors.model';
import { resetMyDashboard } from '../../ducks';
import ControlsForm, { CustomSectorTreeOption } from '../controls/ControlsForm';
import ComparisonPeriod from './components/ComparisonPeriod';
import CurrentPeriod from './components/CurrentPeriod';
import { SDashboard, SRow } from './Dashboard.styles';
import { initMyDashboard } from './ducks';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onEnter: initMyDashboard,
      onLeave: resetMyDashboard,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapDispatchToProps>;

/**
 * Buy-Side Dashboard displays the building blocks for a comparison between two periods
 * of account performance and aggregated market data
 *
 * triggers the initMyDashboard action on load to fetch dashboard offerings and customSectors
 */
export const Dashboard: React.FC<Props> = ({ actions }) => {
  React.useEffect(() => {
    actions.onEnter();

    return () => {
      actions.onLeave();
    };
  }, [actions]);

  const canReadCustomSectors = useCheckPermissions([permissionsByEntity.CustomSectors.READ]);
  const { data: sectorListData } = useShared_CustomSectorListQuery({ skip: !canReadCustomSectors });
  const sectors = sectorListData?.customSectors?.items ?? [];
  const options = mapSectorsToOptions(sectors) as CustomSectorTreeOption[];

  return (
    <SDashboard data-test-id={myDashboard.buySideDashboard.testId}>
      <ControlsForm customSectorOptions={options} />
      <SRow>
        <CurrentPeriod />
        <ComparisonPeriod />
      </SRow>
    </SDashboard>
  );
};

export default connect(null, mapDispatchToProps)(Dashboard);
