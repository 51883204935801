import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { ConfirmModal, TextInputField } from '@cmg/common';
import { saveReportModalSelector } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik, yupToFormErrors } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, InjectedProps as InjectedModalProps } from 'redux-modal';
import * as yup from 'yup';

import { UserReport } from '../../../../types/domain/report/userReport';
import { User } from '../../../../types/domain/user/user';
import {
  fetchOrganizationUsersRequest,
  fetchSharedReportOrganizationUsersRequest,
  selectOrganizationUsers,
  selectSharedReportOrganizationUsers,
} from '../../../shared/ducks';
import { DatalabScreens, DatalabScreenViewTypes, ReportDetailsModalMode } from '../../constants';
import {
  createUserReportCancelled,
  createUserReportConfirmed,
  selectFilters,
  selectReport,
  selectReportDetailsModal,
} from '../../ducks';
import UserButtonList from '../user-button-list/UserButtonList';
import UserSelectField from '../user-select-field/UserSelectField';
import {
  SCreateUserModalWrapper,
  SCurrentShareUsersWrapper,
  SFormGroup,
  SLabel,
  SRequired,
  SSubtitle,
} from './ReportDetailsModal.styles';

export const REPORT_DETAILS_MODAL_ID = 'REPORT_DETAILS_MODAL_ID';

export const serverValidationTest = (yup, value: string | undefined) => {
  if (yup.options.context.error) {
    const doesFieldHaveInvalidValue =
      yup.options.context.error.details.some(errorDetail => errorDetail.target === yup.path) &&
      value === yup.options.context.error.payload[yup.path];

    if (doesFieldHaveInvalidValue) {
      const invalidFieldErrorMessages = yup.options.context.error.details
        .filter(errorDetail => errorDetail.target === yup.path)
        .map(detail => {
          return detail && detail.message ? detail.message : [];
        })
        .reduce((acc, currentValue) => acc.concat(currentValue));

      return invalidFieldErrorMessages.length > 0
        ? yup.createError({
            path: yup.path,
            message: invalidFieldErrorMessages.join(', '),
          })
        : false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const getFormSchema = (reportDetailsModalMode: ReportDetailsModalMode) =>
  yup.object().shape({
    name: yup
      .string()
      .test('server-validation', 'An error occurred', function (this: any, value) {
        return serverValidationTest(this, value);
      })
      .required('A report name is required.'),
    ...(reportDetailsModalMode === ReportDetailsModalMode.EDIT
      ? {
          shareUserIds: yup
            .array()
            .min(1, 'Please choose users to share the report with.')
            .required('Please choose users to share the report with.'),
        }
      : {}),
  });

const mapStateToProps = state => {
  return {
    organizationUsers: selectOrganizationUsers(state),
    sharedReportOrganizationUsers: selectSharedReportOrganizationUsers(state),
    report: selectReport(state),
    reportDetailsModal: selectReportDetailsModal(state),
    filters: selectFilters(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createUserReportConfirmed,
      createUserReportCancelled,
      fetchSharedReportOrganizationUsersRequest,
      fetchOrganizationUsersRequest,
    },
    dispatch
  ),
});

type ModalProps = {
  reportDetailsModalMode: ReportDetailsModalMode;
  section: DatalabScreens;
  sectionType: DatalabScreenViewTypes;
} & InjectedModalProps;

type FormValues = {
  name: string;
  shareUserIds?: string[];
};

export type OwnProps = ModalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export type Props = OwnProps & FormikProps<FormValues>;

export const ReportDetailsModal: React.FC<Props> = ({
  actions,
  report,
  organizationUsers,
  sharedReportOrganizationUsers,
  reportDetailsModalMode,
  section,
  sectionType,
  reportDetailsModal: { error },
  setFieldValue,
  values,
  isValid,
  validateForm,
  filters,
  show,
}) => {
  const { userPermissions } = useAuth();
  React.useEffect(() => {
    actions.fetchOrganizationUsersRequest();

    actions.fetchSharedReportOrganizationUsersRequest({
      payload: { useCustomSectors: filters.useCustomSectors },
    });

    validateForm();
  }, [actions, validateForm, filters]);

  React.useEffect(() => {
    validateForm();
  }, [validateForm, error]);

  const handleConfirm = () => {
    actions.createUserReportConfirmed({
      values: {
        ...values,
        reportId: report ? report.id : null,
      },
      section,
      sectionType,
      reportDetailsModalMode,
    });
  };

  const handleHide = () => {
    actions.createUserReportCancelled();
  };

  const canUserShareReports = checkPermissions(userPermissions, [
    permissionsByEntity.SharedReports.FULL,
  ]);

  // Use organizationUsers state object to gather user details.
  // It's possible that the report is shared with users who may no
  // longer exist in the sharedReportsOrganizationUsers state object
  // For example, if the current user shares a report with a user who does not
  // have custom sectors and then the current user enables custom sectors,
  // the person whom the report was previously shared with would not
  // exist in state.shared.organization.sharedReportUsers.
  const usersWhomReportIsSharedWith: User[] =
    reportDetailsModalMode === ReportDetailsModalMode.EDIT &&
    report &&
    report.share &&
    report.share.sharedWith.length > 0
      ? (report.share.sharedWith
          .map((userId: UserReport['id']) => organizationUsers.find(user => user.id === userId))
          .filter(user => user !== undefined) as User[])
      : [];

  const excludedUserIds =
    reportDetailsModalMode === ReportDetailsModalMode.EDIT && report ? report.share.sharedWith : [];

  const usersWhoReportCanBeSharedWith = sharedReportOrganizationUsers.filter(
    member => !excludedUserIds.includes(member.id)
  );

  const suggestedUsers = usersWhoReportCanBeSharedWith.filter(
    user => !values.shareUserIds || !values.shareUserIds.includes(user.id)
  );

  return (
    <ConfirmModal
      testId={saveReportModalSelector.testId}
      show={show}
      confirmButtonEnabled={isValid}
      confirmButtonCaption="Save"
      cancelButtonCaption="Cancel"
      onHide={handleHide}
      onConfirm={handleConfirm}
      title={
        <React.Fragment>
          Save Report<SSubtitle>This would save the table configuration with the report</SSubtitle>
        </React.Fragment>
      }
    >
      <SCreateUserModalWrapper>
        <Form>
          <SFormGroup>
            <TextInputField
              name="name"
              label={
                <React.Fragment>
                  <SRequired>*</SRequired>
                  <span> Report Name</span>
                </React.Fragment>
              }
              disabled={reportDetailsModalMode === ReportDetailsModalMode.EDIT}
              fullWidth
              withMargin
            />
          </SFormGroup>
          {canUserShareReports && (
            <React.Fragment>
              {usersWhomReportIsSharedWith.length > 0 && (
                <SCurrentShareUsersWrapper>
                  <SLabel>Currently Shared With</SLabel>
                  {usersWhomReportIsSharedWith.map(user => user.username).join(', ')}
                </SCurrentShareUsersWrapper>
              )}
              <UserSelectField
                name="shareUserIds"
                label="Share report with"
                users={usersWhoReportCanBeSharedWith}
              />
              <UserButtonList
                label="Share report with"
                users={suggestedUsers.slice(0, 5)}
                onClickUserButton={(user: User) => {
                  setFieldValue('shareUserIds', [
                    ...(values.shareUserIds ? values.shareUserIds : []),
                    user.id,
                  ]);
                }}
              />
            </React.Fragment>
          )}
        </Form>
      </SCreateUserModalWrapper>
    </ConfirmModal>
  );
};

const getReportName = ({ report, reportDetailsModalMode }) => {
  if (report) {
    return reportDetailsModalMode === ReportDetailsModalMode.EDIT
      ? report.name
      : `Copy of ${report.name}`;
  } else {
    return '';
  }
};

export const FormikReportDetailsModal = withFormik<OwnProps, FormValues>({
  enableReinitialize: true,
  validate: async (values, props) => {
    try {
      await getFormSchema(props.reportDetailsModalMode).validate(values, {
        context: {
          error: props.reportDetailsModal.error,
        },
      });
      return {};
    } catch (err) {
      return yupToFormErrors(err);
    }
  },
  handleSubmit: () => {},
  mapPropsToValues: (props: OwnProps) => ({
    name: getReportName(props),
  }),
  isInitialValid: props =>
    props.reportDetailsModalMode === ReportDetailsModalMode.EDIT ? false : !!getReportName(props),
})(React.memo(ReportDetailsModal));

const ConnectedReportDetailsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormikReportDetailsModal);

export default connectModal({ name: REPORT_DETAILS_MODAL_ID, destroyOnHide: false })(
  ConnectedReportDetailsModal
);
