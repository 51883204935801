import styled from 'styled-components/macro';

export const SFirstTradedDateGroupHeader = styled.div`
  background-color: ${({ theme }) => theme.background.color.dark};
  color: ${({ theme }) => theme.text.color.white};
  padding: 5px 15px;
  margin-bottom: 5px;
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.medium};
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    margin-right: 5px;
  }
`;
