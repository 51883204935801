import { Grid, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = {
  label: string;
  value: string | React.ReactNode;
  value2?: string | React.ReactNode;
};
const labelTypography = theme => ({ color: theme.palette.text.secondary });

const OfferingDetailRowItem: React.FC<Props> = ({ label, value, value2 }) => {
  return (
    <Grid
      container
      item
      alignItems="flex-start"
      columnSpacing={2}
      role="row"
      xs={12}
      paddingY={theme => theme.spacing(0.5)}
    >
      <Grid item xs={5} role="rowheader" typography={labelTypography}>
        {label}
      </Grid>
      <Grid container item xs={value2 ? 3 : 7} role="cell">
        {typeof value === 'string' ? <Typography>{value}</Typography> : value}
      </Grid>
      {value2 && (
        <Grid container item xs={4} role="cell">
          {typeof value2 === 'string' ? <Typography>{value2}</Typography> : value2}
        </Grid>
      )}
    </Grid>
  );
};

export default OfferingDetailRowItem;
