import { checkPermissions, permissionsByEntity } from '@cmg/auth';
import { Icon, IconButton } from '@cmg/common';
import React from 'react';
import styled, { withTheme } from 'styled-components/macro';

import { CMGTheme } from '../../../types/vendor/styled-components/styled-components';

export const StyledPlusButton = styled(IconButton)`
  padding: 0px 2px;
  margin-bottom: 1px;
  border: none;
  vertical-align: middle;
`;

const SEditableCustomSector = styled.span`
  cursor: pointer;
`;

export type Props = {
  sector?: string | null;
  customSector?: string | null;
  useCustomSectors: boolean;
  onPick: () => void;
  userPermissions: string[];
  theme: CMGTheme;
};

const SectorPicker: React.FC<Props> = ({
  sector,
  customSector,
  useCustomSectors,
  onPick,
  userPermissions,
  theme,
}) => {
  const canUpdateCustomSectors = checkPermissions(userPermissions, [
    permissionsByEntity.CustomSectors.FULL,
  ]);

  return !useCustomSectors || !canUpdateCustomSectors ? (
    <React.Fragment>{(useCustomSectors ? customSector : sector) || '---'}</React.Fragment>
  ) : (
    <SEditableCustomSector onClick={onPick}>
      {customSector ? (
        <React.Fragment>
          {customSector} <Icon name="edit" color={theme.color.blue2} />
        </React.Fragment>
      ) : (
        <StyledPlusButton
          size="small"
          onClick={onPick}
          icon={{ name: 'plus-square', size: 'lg', color: theme.color.blue2 }}
        />
      )}
    </SEditableCustomSector>
  );
};

export default withTheme(SectorPicker);
