import styled, { keyframes } from 'styled-components/macro';

export const SAnimation = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }`;

export const SSpinElement = styled.div<{ size?: string }>`
  margin: 0 auto;
  position: relative;
  width: ${({ size }) => (size === 'small' ? '20px' : '60px')};
  height: ${({ size }) => (size === 'small' ? '20px' : '60px')};

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #333;
      border-radius: 100%;
      animation: ${SAnimation} 0.96s infinite ease-in-out both;
    }
  }

  .sk-circle2 {
    transform: rotate(30deg);
  }
  .sk-circle3 {
    transform: rotate(60deg);
  }
  .sk-circle4 {
    transform: rotate(90deg);
  }
  .sk-circle5 {
    transform: rotate(120deg);
  }
  .sk-circle6 {
    transform: rotate(150deg);
  }
  .sk-circle7 {
    transform: rotate(180deg);
  }
  .sk-circle8 {
    transform: rotate(210deg);
  }
  .sk-circle9 {
    transform: rotate(240deg);
  }
  .sk-circle10 {
    transform: rotate(270deg);
  }
  .sk-circle11 {
    transform: rotate(300deg);
  }
  .sk-circle12 {
    transform: rotate(330deg);
  }
  .sk-circle2:before {
    animation-delay: -0.88s;
  }
  .sk-circle3:before {
    animation-delay: -0.8s;
  }
  .sk-circle4:before {
    animation-delay: -0.72s;
  }
  .sk-circle5:before {
    animation-delay: -0.64s;
  }
  .sk-circle6:before {
    animation-delay: -0.56s;
  }
  .sk-circle7:before {
    animation-delay: -0.48s;
  }
  .sk-circle8:before {
    animation-delay: -0.4s;
  }
  .sk-circle9:before {
    animation-delay: -0.32s;
  }
  .sk-circle10:before {
    animation-delay: -0.24s;
  }
  .sk-circle11:before {
    animation-delay: -0.16s;
  }
  .sk-circle12:before {
    animation-delay: -0.08s;
  }
`;
