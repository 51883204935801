import styled, { css } from 'styled-components/macro';

export const STableHeader = styled.th<{ textAlign?: 'left' | 'right' | 'center' }>`
  &&& {
    text-align: ${({ textAlign = 'left' }) => textAlign};
  }
`;

export const SSummaryHeaders = styled.tr`
  background-color: ${({ theme }) => theme.background.color.dark};

  &&& th {
    padding-top: 0;
    padding-bottom: 0;
  }

  button {
    color: ${({ theme }) => theme.text.color.white};
    text-align: left;
  }
`;

export const SSubHeaders = styled.tr`
  th {
    border-right: 0;
    border-left: 0;
    padding-top: 0;
  }
`;

export const SCompositeLabel = styled.th`
  color: ${({ theme }) => theme.color.gray6};
  cursor: default;
  text-align: center;
  border-bottom: 0;
`;

const Title = css`
  border: 0;
  background-color: transparent;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
`;

export const SColLabel = styled.span`
  ${Title}
`;

export const SOrderingColLabel = styled.button`
  ${Title}
`;

export const SOrderArrow = styled.span`
  margin-left: 2px;
`;
