import React from 'react';
import ContentLoader from 'react-content-loader';

import HorizontalLine from '../../../../common/horizontal-line/HorizontalLine';
import { PeriodColumn, Section } from '../../../common/DashboardPeriod';

export const DateRangeControlLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 30" uniqueKey="dashboard-date-control">
      <rect x="0" y="10" rx="5" ry="5" width="100" height="15" />

      <rect x="120" y="0" rx="5" ry="5" width="300" height="30" />
    </ContentLoader>
  </Section>
);

export const UsEcmLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 50" uniqueKey="dashboard-usecm">
      <rect x="0" y="10" rx="5" ry="5" width="100" height="15" />

      <rect x="200" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="200" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="380" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="380" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="560" y="40" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
  </Section>
);

export const PricingSummaryLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 90" uniqueKey="dashboard-pricing-summary">
      <rect x="0" y="5" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="20" rx="5" ry="5" width="70" height="10" />

      <rect x="0" y="55" rx="5" ry="5" width="50" height="20" />
      <rect x="0" y="85" rx="5" ry="5" width="50" height="10" />

      <rect x="110" y="55" rx="5" ry="5" width="50" height="20" />
      <rect x="110" y="85" rx="5" ry="5" width="50" height="10" />

      <rect x="210" y="55" rx="5" ry="5" width="5" height="40" />
      <rect x="220" y="55" rx="5" ry="5" width="50" height="20" />
      <rect x="220" y="85" rx="5" ry="5" width="50" height="10" />

      <rect x="320" y="55" rx="5" ry="5" width="5" height="40" />
      <rect x="330" y="55" rx="5" ry="5" width="50" height="20" />
      <rect x="330" y="85" rx="5" ry="5" width="50" height="10" />

      <rect x="430" y="55" rx="5" ry="5" width="5" height="40" />
      <rect x="440" y="55" rx="5" ry="5" width="50" height="20" />
      <rect x="440" y="85" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="55" rx="5" ry="5" width="50" height="20" />
      <rect x="560" y="85" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
  </Section>
);

export const FollowOnsLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 60" uniqueKey="dashboard-followons">
      <rect x="0" y="10" rx="5" ry="5" width="100" height="15" />

      <rect x="200" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="200" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="320" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="320" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="440" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="440" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="560" y="40" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
    <HorizontalLine size="medium" />
  </Section>
);

export const FollowOnDetailsLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 100" uniqueKey="dashboard-followon-detail">
      <rect x="0" y="0" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="20" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="40" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="60" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="80" rx="5" ry="5" width="100" height="10" />

      <rect x="200" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="80" rx="5" ry="5" width="50" height="10" />

      <rect x="320" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="320" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="320" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="320" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="320" y="80" rx="5" ry="5" width="50" height="10" />

      <rect x="440" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="440" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="440" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="440" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="440" y="80" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="80" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
  </Section>
);

export const MyParticipationSummaryLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 60" uniqueKey="dashboard-my-participation-summary">
      <rect x="0" y="10" rx="5" ry="5" width="100" height="15" />

      <rect x="200" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="200" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="380" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="380" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="560" y="40" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
    <HorizontalLine size="medium" />
  </Section>
);

export const ParticipationDetailLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 100" uniqueKey="dashboard-participation-detail">
      <rect x="0" y="0" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="20" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="40" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="60" rx="5" ry="5" width="100" height="10" />
      <rect x="0" y="80" rx="5" ry="5" width="100" height="10" />

      <rect x="200" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="200" y="80" rx="5" ry="5" width="50" height="10" />

      <rect x="380" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="380" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="380" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="380" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="380" y="80" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="0" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="20" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="40" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="60" rx="5" ry="5" width="50" height="10" />
      <rect x="560" y="80" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
  </Section>
);

export const PassedLoading = () => (
  <Section>
    <ContentLoader viewBox="0 0 640 50" uniqueKey="dashboard-passed">
      <rect x="0" y="10" rx="5" ry="5" width="100" height="15" />

      <rect x="200" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="200" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="380" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="380" y="40" rx="5" ry="5" width="50" height="10" />

      <rect x="560" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="560" y="40" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
  </Section>
);

/**
 * LoadingSkeleton is used as a placeholder loading state
 * for the DashboardPeriod component
 */
const LoadingSkeleton: React.FC = () => (
  <PeriodColumn>
    <DateRangeControlLoading />
    <HorizontalLine />
    <UsEcmLoading />
    <HorizontalLine />
    <PricingSummaryLoading />
    <HorizontalLine />
    <FollowOnsLoading />
    <FollowOnDetailsLoading />
    <HorizontalLine />
    <MyParticipationSummaryLoading />
    <ParticipationDetailLoading />
    <HorizontalLine />
    <PassedLoading />
  </PeriodColumn>
);

export default LoadingSkeleton;
