import styled from 'styled-components/macro';

export const SStickyMenuListButton = styled.button`
  display: block;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid ${({ theme }) => theme.border.color.light};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
  padding: 10px 12px;
  background-color: ${({ theme }) => theme.background.color.white};
  color: ${({ theme }) => theme.color.blue1};
  width: 100%;
  bottom: 0;
  position: sticky;

  &:hover {
    text-decoration: underline;
  }
`;

export const SOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.color.blue14 : 'none')};
`;

export const SOptionReportInfo = styled.div`
  flex: 0 1 auto;
`;

export const SOptionReportName = styled.div<{ isSelected: boolean }>`
  color: ${({ theme, isSelected }) => (isSelected ? theme.text.color.white : 'inherit')};
`;

export const SOptionReportMeta = styled.div<{ isSelected: boolean }>`
  font-size: 12px;
  color: ${({ isSelected, theme }) => (isSelected ? theme.text.color.white : theme.color.gray8)};
`;

export const SOptionReportIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.gray3};
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.color.gray2};
`;

export const SOptionReportIcons = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;

  > ${SOptionReportIcon} {
    margin: 0 3px;
  }

  ${SOptionReportIcon}:last-child {
    margin: 0;
  }
`;

export const SGroupLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text.color.dark};
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 8px 12px;
`;
