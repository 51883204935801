import { checkPermissions, permissionsByEntity, PublicAuthStateProps, withAuth } from '@cmg/auth';
import { ISODate, Popover, Tag, timeUtil } from '@cmg/common';
import { useTheme } from '@cmg/design-system';
import { calendarOfferingIssuerNameSelector } from '@cmg/e2e-selectors';
import { isValid } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import {
  type Calendar_OfferingFieldsFragment,
  IoiType,
  OfferingStatus,
  PricingType,
} from '../graphql';
import FollowOfferingButton from './FollowOfferingButton';
import IoiSummary from './IoiSummary';
import { SIssuerButtons, SIssuerTags } from './IssuerFirmNameRenderer.styles';
import OfferingProfileLinkRenderer from './OfferingProfileLinkRenderer';

function isIoiTagVisible(offering: Calendar_OfferingFieldsFragment, userPermissions?: string[]) {
  return userPermissions && checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]);
}
function isPassTagVisible(offering: Calendar_OfferingFieldsFragment, userPermissions?: string[]) {
  return (
    isIoiTagVisible(offering, userPermissions) && offering.lastAllocation?.ioiType === IoiType.Pass
  );
}

function isRegMTagVisible(offering: Calendar_OfferingFieldsFragment, userPermissions?: string[]) {
  return (
    isIoiTagVisible(offering, userPermissions) && offering.lastAllocation?.ioiType === IoiType.RegM
  );
}

function isScalarIoiTagVisible(
  offering: Calendar_OfferingFieldsFragment,
  userPermissions?: string[]
) {
  return (
    isIoiTagVisible(offering, userPermissions) &&
    offering.indicationsOfInterest &&
    offering.indicationsOfInterest.length >= 2
  );
}

function isMarketIoiTagVisible(
  offering: Calendar_OfferingFieldsFragment,
  userPermissions?: string[]
) {
  return (
    isIoiTagVisible(offering, userPermissions) &&
    offering.indicationsOfInterest &&
    offering.indicationsOfInterest.length === 1 &&
    offering.indicationsOfInterest[0].pricingType === PricingType.Market
  );
}

function isLimitIoiTagVisible(
  offering: Calendar_OfferingFieldsFragment,
  userPermissions?: string[]
) {
  return (
    isIoiTagVisible(offering, userPermissions) &&
    offering.indicationsOfInterest &&
    offering.indicationsOfInterest.length === 1 &&
    offering.indicationsOfInterest[0].pricingType === PricingType.Limit
  );
}

function isLinkGrayedOut(offering: Calendar_OfferingFieldsFragment) {
  const { exchangeCountry, status } = offering.attributes || {};

  return exchangeCountry === 'US' && status === OfferingStatus.Filed;
}

const isToday = (date: ISODate) => {
  const comparedDate = timeUtil.getDate(date);
  const today = new Date();
  if (!isValid(comparedDate)) {
    return false;
  }
  return (
    comparedDate.getFullYear() === today.getFullYear() &&
    comparedDate.getMonth() === today.getMonth() &&
    comparedDate.getDate() === today.getDate()
  );
};

type OwnProps = {
  offering: Calendar_OfferingFieldsFragment;
  showIoiBadge?: boolean;
  showLive?: boolean;
  onToggleFollowOffering?: React.ComponentProps<typeof FollowOfferingButton>['onClick'];
};

export type Props = OwnProps & PublicAuthStateProps;

export const IssuerFirmNameRendererComponent: React.FC<Props> = ({
  offering,
  showIoiBadge,
  showLive,
  onToggleFollowOffering,
  userPermissions,
}) => {
  const themes = useTheme();

  return (
    <div style={{ display: 'flex' }} data-test-id={calendarOfferingIssuerNameSelector.testId}>
      {onToggleFollowOffering && (
        <SIssuerButtons>
          {onToggleFollowOffering && (
            <FollowOfferingButton offering={offering} onClick={onToggleFollowOffering} />
          )}
        </SIssuerButtons>
      )}
      {isLinkGrayedOut(offering) ? (
        <span style={{ flex: '1 1 auto', paddingRight: 10 }}>{offering.issuer?.name}</span>
      ) : (
        <OfferingProfileLinkRenderer label={offering.issuer?.name || ''} offering={offering} />
      )}
      <SIssuerTags>
        {showIoiBadge &&
          isPassTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color={themes.palette.text.secondary} outline transparent turnOffCaps>
              Pass
            </Tag>
          ) : (
            <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
              <Tag color={themes.palette.text.secondary} outline transparent turnOffCaps>
                Pass
              </Tag>
            </Link>
          ))}

        {showIoiBadge &&
          isRegMTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color={themes.palette.error.main} outline transparent turnOffCaps>
              Reg-M
            </Tag>
          ) : (
            <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
              <Tag color={themes.palette.error.main} outline transparent turnOffCaps>
                Reg-M
              </Tag>
            </Link>
          ))}

        {showIoiBadge &&
          isScalarIoiTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color={themes.palette.primary.main} outline transparent turnOffCaps>
              Scaled
            </Tag>
          ) : (
            <Popover
              content={<IoiSummary iois={offering.indicationsOfInterest} />}
              variant="TOOLTIP"
              trigger="hover"
            >
              <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
                <Tag color={themes.palette.primary.main} outline transparent turnOffCaps>
                  Scaled
                </Tag>
              </Link>
            </Popover>
          ))}

        {showIoiBadge &&
          isMarketIoiTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color={themes.palette.primary.main} outline transparent turnOffCaps>
              Market
            </Tag>
          ) : (
            <Popover
              content={<IoiSummary iois={offering.indicationsOfInterest} />}
              variant="TOOLTIP"
              trigger="hover"
            >
              <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
                <Tag color={themes.palette.primary.main} outline transparent turnOffCaps>
                  Market
                </Tag>
              </Link>
            </Popover>
          ))}

        {showIoiBadge &&
          isLimitIoiTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color={themes.palette.primary.main} outline transparent turnOffCaps>
              Limit
            </Tag>
          ) : (
            <Popover
              content={<IoiSummary iois={offering.indicationsOfInterest} />}
              variant="TOOLTIP"
              trigger="hover"
            >
              <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
                <Tag color={themes.palette.primary.main} outline transparent turnOffCaps>
                  Limit
                </Tag>
              </Link>
            </Popover>
          ))}

        {offering.attributes?.isAccelerated && (
          <Tag color={themes.palette.warning.main} turnOffCaps>
            Accelerated
          </Tag>
        )}

        {showLive && offering.attributes?.status === OfferingStatus.Live && (
          <Tag color={themes.palette.success.main} turnOffCaps>
            Live
          </Tag>
        )}

        <React.Fragment>
          {/*
           TODO - offering.launchDate is a YYYY-MM-DD date string. Given this data, we
           can't reliably calculate whether the launch date is the current date based on
           user/client timezones.
         */}
          {offering.attributes?.launchDate && isToday(offering.attributes?.launchDate) && (
            <Tag color={themes.palette.primary.main} turnOffCaps>
              Today
            </Tag>
          )}
        </React.Fragment>
      </SIssuerTags>
    </div>
  );
};

export default withAuth(IssuerFirmNameRendererComponent);
