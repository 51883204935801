import { MultiSelect } from '@cmg/common';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchUnderwriterOptionsActions, selectUnderwriterOptions } from '../../ducks';

const mapStateToProps = state => ({
  underwriterOptions: selectUnderwriterOptions(state),
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUnderwriterOptions: fetchUnderwriterOptionsActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  value?: string[];
  onChange: (value?: string[]) => void;
};
export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const UnderwriterControlComponent: React.FC<Props> = ({
  value,
  onChange,
  actions,
  underwriterOptions,
}) => {
  useEffect(() => {
    !underwriterOptions.length && actions.fetchUnderwriterOptions();
  }, [underwriterOptions.length, actions]);

  const handleOnChange = newValue => {
    newValue !== value && onChange(!newValue.length ? null : newValue);
  };

  return (
    <MultiSelect
      className="select-underwriter"
      isSearchable
      options={underwriterOptions}
      onChange={handleOnChange}
      placeholder="Select Underwriter"
      value={value}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnderwriterControlComponent);
