/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_AdviserPartsFragment = {
  __typename?: 'Adviser';
  id: string;
  name?: string | null;
};

export type OfferingsFilterForm_AdvisersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AdviserFilterInput>;
}>;

export type OfferingsFilterForm_AdvisersQuery = {
  __typename?: 'Query';
  advisers?: {
    __typename?: 'AdviserCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Adviser'; id: string; name?: string | null }> | null;
  } | null;
};

export const OfferingsFilterForm_AdviserPartsFragmentDoc = gql`
  fragment OfferingsFilterForm_AdviserParts on Adviser {
    id
    name
  }
`;
export const OfferingsFilterForm_AdvisersDocument = gql`
  query OfferingsFilterForm_Advisers($filter: AdviserFilterInput) {
    advisers(where: $filter) {
      items {
        ...OfferingsFilterForm_AdviserParts
      }
      totalCount
    }
  }
  ${OfferingsFilterForm_AdviserPartsFragmentDoc}
`;

/**
 * __useOfferingsFilterForm_AdvisersQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_AdvisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_AdvisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_AdvisersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOfferingsFilterForm_AdvisersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_AdvisersQuery,
    OfferingsFilterForm_AdvisersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_AdvisersQuery,
    OfferingsFilterForm_AdvisersQueryVariables
  >(OfferingsFilterForm_AdvisersDocument, options);
}
export function useOfferingsFilterForm_AdvisersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_AdvisersQuery,
    OfferingsFilterForm_AdvisersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_AdvisersQuery,
    OfferingsFilterForm_AdvisersQueryVariables
  >(OfferingsFilterForm_AdvisersDocument, options);
}
export function useOfferingsFilterForm_AdvisersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingsFilterForm_AdvisersQuery,
        OfferingsFilterForm_AdvisersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingsFilterForm_AdvisersQuery,
    OfferingsFilterForm_AdvisersQueryVariables
  >(OfferingsFilterForm_AdvisersDocument, options);
}
export type OfferingsFilterForm_AdvisersQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_AdvisersQuery
>;
export type OfferingsFilterForm_AdvisersLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_AdvisersLazyQuery
>;
export type OfferingsFilterForm_AdvisersSuspenseQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_AdvisersSuspenseQuery
>;
export type OfferingsFilterForm_AdvisersQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_AdvisersQuery,
  OfferingsFilterForm_AdvisersQueryVariables
>;
