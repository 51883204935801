import { Panel } from '@cmg/common';
import styled from 'styled-components/macro';

// it is expected to have a generic set of screen components in this file, rather than this specific screen
// we'll need to move these styled components in a common location when we add any other screen

export const SScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const SScreenContent = styled.div`
  background-color: ${({ theme }) => theme.background.color.light};
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const StyledPanel = styled(Panel)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.background.color.info};
`;
export const STableWrapper = styled.div`
  margin: 14px;
  flex: 1;
`;
