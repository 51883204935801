import { Box, Column, Row } from '@cmg/common';
import { followOnStructureSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { FollowOnStructure } from '../../../../types/domain/datalab/follow-on-structure';
import { selectFollowOnStructureCharts } from '../../ducks';
import WidgetHistoricalOfferingVolume from '../widgets/WidgetHistoricalOfferingVolume/WidgetHistoricalOfferingVolume';
import WidgetOfferingVolumeBySector from '../widgets/WidgetOfferingVolumeBySector/WidgetOfferingVolumeBySector';
import WidgetSizeAsMultOfADTV from '../widgets/WidgetSizeAsMultOfADTV/WidgetSizeAsMultOfADTV';
import WidgetSizePrePostOfferingMarketCap from '../widgets/WidgetSizePrePostOfferingMarketCap/WidgetSizePrePostOfferingMarketCap';

type Props = {
  datalab: FollowOnStructure;
};

export class FollowOnStructureScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;

    return (
      <div data-test-id={followOnStructureSelector.testId}>
        <Row>
          <Column xs={24}>
            <Box>
              <WidgetHistoricalOfferingVolume chartData={datalab.historical_offering_volume} />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column xs={24}>
            <Box>
              <WidgetOfferingVolumeBySector
                chartData={datalab.offering_volume_by_sector}
                title="Offering Volume by Sector"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetSizePrePostOfferingMarketCap
                chartData={datalab.size_pre_post_offering_market_cap}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetSizeAsMultOfADTV chartData={datalab.size_as_a_mult_of_adtv} />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectFollowOnStructureCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(FollowOnStructureScreenComponent);
