import styled from 'styled-components/macro';

/**
 * This file contains reusable styled building blocks that will be used to
 * create a dashboard
 */

export const PeriodColumn = styled.div`
  margin: 10px 0;
  display: flex;
  flex: 1;
  padding: 15px;
  background-color: ${({ theme }) => theme.background.color.white};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
  flex-direction: column;
  height: auto;
  width: 100%;
`;

export const Section = styled.div`
  padding: 12px 0;
`;

export const SectionHeader = styled.h4`
  margin: 0 0 5px 0;
`;

export const DetailTitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
`;
