import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import {
  CustomLegend,
  DoughnutChart,
  DoughnutChartProps,
} from '../../../../../common/components/charts';
import { orderAscBySelector } from '../../../../../obsolete/utils/helpers';
import { getSectorColor, noSectorAsLast } from '../../../colors';
import { SChart, SDatalabChart, SDoughnut } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    color_code: string | null;
    values: {
      amount: number;
    };
  }[];
};

const transformData = (chartData: Props['chartData'] = []): DoughnutChartProps['data'] => {
  const field = 'amount';
  const sorted = noSectorAsLast(chartData.sort(orderAscBySelector.bind(null, item => item.label)));

  return {
    labels: sorted.map(item => item.label),
    datasets: [
      {
        data: sorted.map(item => item.values[field]),
        backgroundColor: sorted.map(item => getSectorColor(item)),
      },
    ],
  };
};

const getOptions = (): DoughnutChartProps['options'] => {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.parsed;
            const total = tooltipItem.dataset.data.reduce(
              (prevTotal: number, curValue: number) => prevTotal + curValue
            );
            return `${numericUtil.formatCurrencyInBillions(value)} (${numericUtil.formatPercents(
              value / total,
              1
            )})`;
          },
        },
      },
    },
  };
};

const WidgetFeesBySector: React.FC<Props> = ({ chartData = [] }) => {
  const chartDataTransformed = React.useMemo(() => transformData(chartData), [chartData]);
  const options = React.useMemo(() => getOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title="Fees by Sector" />

      <Box.Content>
        <SDoughnut>
          <SChart>
            <DoughnutChart data={chartDataTransformed} options={options} />
          </SChart>

          <CustomLegend
            chartData={chartDataTransformed}
            chartType="doughnut"
            valueFormatter={numericUtil.formatCurrencyInBillions}
          />
        </SDoughnut>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetFeesBySector;
