import { SSelectWrapper } from '@cmg/common';
import styled from 'styled-components/macro';

export const SFormControl = styled.div`
  position: relative;

  ${SSelectWrapper} {
    min-width: auto;
  }
`;

export const SInput = styled.div`
  > * {
    display: block;
  }
`;

export const SError = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text.color.negative};
`;
