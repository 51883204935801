import { Box, numericUtil } from '@cmg/common';
import React, { useMemo } from 'react';

import { MixedChart, MixedChartProps } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors, transparent } from '../../../colors';
import afterMarketPerfOfferButtons from '../../../model/buttons/after-market-perf-offer-buttons';
import ipoMarketPerformanceButtons, {
  IpoMarketPerformanceButtons,
} from '../../../model/buttons/ipo-market-performance-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type Datapoints = {
  points: {
    x: number;
    y: number;
    ticker: string;
  }[];
  line: {
    x: number;
    y: number;
  }[];
};

type Props = {
  chartData?: {
    deal_pct_at_pricing_market_cap: {
      offer_to_current: Datapoints;
      offer_to_day: Datapoints;
      offer_to_month: Datapoints;
      offer_to_three_months: Datapoints;
      offer_to_six_months: Datapoints;
      offer_to_open: Datapoints;
    };
    price_vs_midpoint: {
      offer_to_current: Datapoints;
      offer_to_day: Datapoints;
      offer_to_month: Datapoints;
      offer_to_three_months: Datapoints;
      offer_to_six_months: Datapoints;
      offer_to_open: Datapoints;
    };
  };
};

type RawItem = {
  x: number;
  y: number;
  ticker?: string;
};

const transformData = (
  chartData: Props['chartData'],
  metricCategoryValue: string,
  offerPeriodValue: string
) => {
  if (!chartData) {
    return null;
  }

  const type =
    metricCategoryValue === IpoMarketPerformanceButtons.OFFER_TO_MIDPOINT
      ? 'price_vs_midpoint'
      : 'deal_pct_at_pricing_market_cap';
  const selectedChartData = chartData[type][offerPeriodValue];

  const data: MixedChartProps['data'] = {
    // TODO - move to data?
    labels: selectedChartData.points.map(item => item.label),
    datasets: [
      {
        borderColor: transparent,
        data: selectedChartData.points.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
            ticker: item.ticker,
          })
        ),
        backgroundColor: barChartColors.lighter,
        pointBorderColor: barChartColors.lighter,
        pointBorderWidth: 0,
        pointRadius: 3,
        pointHoverRadius: 6,
        type: 'scatter',
      },
      {
        data: selectedChartData.line.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
          })
        ),
        pointRadius: 0,
        backgroundColor: barChartColors.light,
        borderColor: barChartColors.light,
        type: 'line',
      },
    ],
  };

  return data;
};

const getOptions = (metricCategoryLabel: string, offerPeriodLabel: string) => {
  return {
    scales: {
      x: {
        ticks: {
          callback: value => {
            if (typeof value === 'number') {
              return numericUtil.formatPercents(value, 1);
            }

            return value;
          },
        },
      },
      y: {
        ticks: {
          display: true,
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
        },
        title: {
          display: true,
          text: '(Aftermarket Performance)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title(tooltipItems) {
            const raw = tooltipItems[0].raw as RawItem;

            return raw.ticker ?? '';
          },
          label: tooltipItem => {
            return [
              `${offerPeriodLabel}: ${numericUtil.formatPercents(tooltipItem.parsed.y, 1)}`,
              `${metricCategoryLabel}: ${numericUtil.formatPercents(tooltipItem.parsed.x, 1)}`,
            ];
          },
        },
      },
    },
  };
};

const WidgetAftermarketPerformanceRelativeToSizeAndPricing: React.FC<Props> = ({ chartData }) => {
  const [activeBtn, setActiveBtn] = React.useState(ipoMarketPerformanceButtons[0]);
  const [offerActiveBtn, setOfferActiveBtn] = React.useState(afterMarketPerfOfferButtons[1]);

  const transformedData = useMemo(
    () => transformData(chartData, activeBtn.value, offerActiveBtn.value),
    [chartData, activeBtn, offerActiveBtn]
  );
  const options = useMemo(
    () => getOptions(activeBtn.label, offerActiveBtn.label),
    [activeBtn.label, offerActiveBtn.label]
  );

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  const handleOfferBtnChange = (btn: any) => {
    setOfferActiveBtn(btn);
  };

  if (!transformedData) {
    return null;
  }

  return (
    <SDatalabChart>
      <Box.Header title="Aftermarket Performance Relative to Size and Pricing" />

      <SButtonBox>
        <Toggler
          buttons={ipoMarketPerformanceButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />

        <Toggler
          buttons={afterMarketPerfOfferButtons}
          activeButton={offerActiveBtn}
          onChange={handleOfferBtnChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart>
          <MixedChart data={transformedData} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetAftermarketPerformanceRelativeToSizeAndPricing;
