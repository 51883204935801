import { useAuth } from '@cmg/auth';
import { apiTypes, ApplicationError } from '@cmg/common';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import routeFactory from '../../../common/util/routeFactory';
import ScreenHeader from '../../shared/layout/ScreenHeader';

export const SApplicationErrorWrapper = styled.div<{ isLoggedIn: boolean }>`
  > * {
    /**
    * Bumping up the positioning of the error screen a bit
    * to account for the horizontal nav bar.
    */
    ${({ isLoggedIn }) =>
      isLoggedIn &&
      css`
        height: calc(100vh - 88px);
      `}
  }
`;

/**
 * Default ECM route - displayed if a matching route
 * cannot be found.
 */
const NotFoundRoute: React.FC = () => {
  const { isLoggedIn } = useAuth();

  return (
    <React.Fragment>
      {isLoggedIn && <ScreenHeader />}
      <SApplicationErrorWrapper isLoggedIn={isLoggedIn}>
        <ApplicationError
          errorCode={apiTypes.ServiceErrorCode.NOT_FOUND}
          returnUrl={routeFactory.root.routePath}
          variant={isLoggedIn ? 'LIGHT' : 'DARK'}
        />
      </SApplicationErrorWrapper>
    </React.Fragment>
  );
};

export default NotFoundRoute;
