import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { BarChart, BarChartProps, CustomLegend } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData: {
    label: string;
    values: {
      count: number;
      mean: number;
      median: number;
    };
  }[];
};

type RawItem = {
  y: number;
  x: string;
  count: number;
};

type ChartProps = BarChartProps<RawItem[]>;

const transformData = (chartData: Props['chartData']): ChartProps['data'] => {
  return {
    datasets: [
      {
        label: 'MULT. OF ADTV (MEAN)',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.mean,
            x: item.label,
            count: item.values.count,
          })
        ),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'MULT. OF ADTV (MEDIAN)',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.median,
            x: item.label,
            count: item.values.count,
          })
        ),
        backgroundColor: barChartColors.light,
      },
    ],
  };
};

const getOptions = (): ChartProps['options'] => ({
  scales: {
    x: {
      title: {
        text: 'Offering Size',
        display: true,
      },
    },
    y: {
      ticks: {
        callback: value =>
          typeof value === 'number' ? numericUtil.formatMultipleFactor(value) : value,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => {
          const { dataset, parsed } = tooltipItem;
          const raw = tooltipItem.raw as RawItem;

          const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;
          return `${dataset.label ? `${dataset.label}: ` : ''}${numericUtil.formatMultipleFactor(
            parsed.y
          )}${countString ?? ''}`;
        },
      },
    },
  },
});

const WidgetSizeAsMultOfADTV: React.FC<Props> = ({ chartData = [] }) => {
  const data = React.useMemo(() => transformData(chartData), [chartData]);
  const options = React.useMemo(() => getOptions(), []);

  return (
    <SDatalabChart horizontal>
      <Box.Header title="Size as a Mult. Of ADTV" />

      <Box.Content>
        <SChart>
          <BarChart data={data} barSizingMode="CMGCenteredBarPlugin" options={options} />
        </SChart>

        <CustomLegend chartData={data} chartType="bar" />
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetSizeAsMultOfADTV;
