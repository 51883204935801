import styled from 'styled-components/macro';

export const SNotifications = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background.color.white};
`;

export const SNotificationList = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;

export const SNotificationListHeader = styled.div`
  width: 100%;
  border-bottom: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.border.color.dark};
  display: inline-flex;
  justify-content: space-between;
`;

export const SNotificationListHeaderLeft = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

export const SNotificationListFooter = styled.div`
  width: 100%;
  text-align: right;
  border-top: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.border.color.dark};
`;

export const SNotificationsLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
`;

export const SNotificationListTitle = styled.span`
  font-size: ${({ theme }) => theme.text.size.medium};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  padding: 15px 20px;
`;

export const SNotificationUnreadOnly = styled.div`
  align-self: center;
  flex: 1;
`;

export const SNotificationListLoading = styled.div`
  width: 100%;
  margin: 50px 0;
  text-align: center;
`;

export const SNotificationsEmpty = styled.div`
  width: 100%;
  margin: 100px 0;
  text-align: center;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SNotificationsEmptyTitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
`;

export const SNotificationsEmptyIcon = styled.div`
  width: 5em;
  height: 5em;
  border-radius: 5em;
  line-height: 5.4em;
  margin: 15px auto;
  background: ${({ theme }) => theme.background.color.highlighted};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
