import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum HeatMapDropOffButtons {
  MEAN = 'mean',
  MEDIAN = 'median',
}

export const heatMapDropOffButtonsConfig: ButtonsConfig = {
  [HeatMapDropOffButtons.MEAN]: { label: 'MEAN' },
  [HeatMapDropOffButtons.MEDIAN]: { label: 'MEDIAN' },
};

export default getButtonConfigArray(heatMapDropOffButtonsConfig);
