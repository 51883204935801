import React from 'react';

import {
  getFormattedDateValue,
  getFormattedPercentageValue,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { CardRowItem, emptyValue, OfferingProfileFieldName } from '../../shared/types';

export const useGetAtmUnderwritingTermsSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const { atmAttributes, attributes } = offeringProfile.offeringById ?? {};
    const { leftLeadName } = attributes ?? {};

    return [
      {
        name: OfferingProfileFieldName.GrossSpread,
        value: getFormattedPercentageValue(atmAttributes?.pctGrossSpread) ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.LeftLead,
        value: leftLeadName ?? emptyValue,
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetAtmTimingSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const { publicFilingDate, terminatedDate } = offeringProfile.offeringById ?? {};

    return [
      {
        name: OfferingProfileFieldName.AnnouncementDate,
        value: getFormattedDateValue(publicFilingDate),
      },
      {
        name: OfferingProfileFieldName.TerminationDate,
        value: getFormattedDateValue(terminatedDate),
      },
    ];
  }, [offeringProfile.offeringById]);
