import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { Box } from '@cmg/common';
import { manageSectorsSelector } from '@cmg/e2e-selectors';

import ScreenContent from '../../shared/layout/ScreenContent';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import SectorsGrid from './sectors-grid/SectorsGrid';

const SectorsRoute = () => {
  const { userPermissions } = useAuth();

  const canUpdateCustomSectors = checkPermissions(userPermissions, [
    permissionsByEntity.CustomSectors.FULL,
  ]);

  return (
    <div data-test-id={manageSectorsSelector.testId}>
      <ScreenHeader />
      <ScreenContent>
        <Box withMargin>
          <Box.Header title="Manage Sectors" />
          <SectorsGrid canUpdate={canUpdateCustomSectors} />
        </Box>
      </ScreenContent>
    </div>
  );
};

export default SectorsRoute;
