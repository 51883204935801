import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import {
  CustomLegend,
  DoughnutChart,
  DoughnutChartProps,
} from '../../../../../../common/components/charts';
import Toggler from '../../../../../shared/layout/components/Toggler';
import { getTypeColor } from '../../../../colors';
import underwrittenValueAmountButtons from '../../../../model/buttons/underwritten-value-amount-buttons';
import { ValueAmountButtons } from '../../../../model/buttons/value-amount-buttons';
import { SButtonBox, SChart, SDatalabChart, SDoughnut } from '../../shared/styled';

type Props = {
  chartData?: {
    label: string;
    color_code: string | null;
    values: {
      value: number;
      underwritten_value: number;
      amount: number;
    };
  }[];
  title?: string;
};

const transformData = (
  chartData: Props['chartData'] = [],
  valueType: string
): DoughnutChartProps['data'] => {
  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        data: chartData.map(item => item.values[valueType]),
        backgroundColor: chartData.map(item => getTypeColor(item.label)),
      },
    ],
  };
};

const getOptions = (valueType: string): DoughnutChartProps['options'] => {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.parsed;
            const total = tooltipItem.dataset.data.reduce(
              (prevTotal: number, curValue: number) => prevTotal + curValue
            );
            const formattedValue =
              valueType === ValueAmountButtons.AMOUNT
                ? tooltipItem.parsed
                : numericUtil.formatCurrencyInBillions(tooltipItem.parsed);
            return `${formattedValue} (${numericUtil.formatPercents(value / total, 1)})`;
          },
        },
      },
    },
  };
};

const WidgetHistoricalOfferingVolumeByType: React.FC<Props> = ({
  chartData = [],
  title = 'Offering Volume by Type',
}) => {
  const [activeBtn, setActiveBtn] = React.useState(underwrittenValueAmountButtons[0]);
  const transformedData = React.useMemo(
    () => transformData(chartData, activeBtn.value),
    [chartData, activeBtn]
  );

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  const options = React.useMemo(() => getOptions(activeBtn.value), [activeBtn.value]);

  return (
    <SDatalabChart>
      <Box.Header title={title} />

      <SButtonBox>
        <Toggler
          buttons={underwrittenValueAmountButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SDoughnut>
          <SChart>
            <DoughnutChart data={transformedData} options={options} />
          </SChart>

          <CustomLegend
            chartData={transformedData}
            chartType="doughnut"
            valueFormatter={
              activeBtn.value === ValueAmountButtons.AMOUNT
                ? undefined
                : numericUtil.formatCurrencyInBillions
            }
          />
        </SDoughnut>
      </Box.Content>
    </SDatalabChart>
  );
};
export default WidgetHistoricalOfferingVolumeByType;
