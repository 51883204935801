import { Grid, GridProps, InfoOutlinedIcon, Tooltip, Typography } from '@cmg/design-system';

const nowrapStyle = { whiteSpace: 'nowrap', flexWrap: 'nowrap' };

type Props = {
  label: string;
  tooltipText?: string | null;
  justifyContent?: GridProps['justifyContent'];
};

const LabelWithTooltip = ({ label, tooltipText, justifyContent = 'flex-start' }: Props) => {
  return (
    <Grid container justifyContent={justifyContent} alignItems="center" gap={0.5} sx={nowrapStyle}>
      {label}
      {tooltipText && (
        <Tooltip
          title={
            <Grid maxWidth={theme => theme.spacing(22)}>
              <Typography variant="tooltip">{tooltipText}</Typography>
            </Grid>
          }
          placement="top"
          variant="info"
        >
          <InfoOutlinedIcon fontSize="inherit" />
        </Tooltip>
      )}
    </Grid>
  );
};

export default LabelWithTooltip;
