import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Typography,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../../shared/OfferingProfileCardItem';
import { useGetAtmTimingSectionData } from '../model/Atm.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

export const AtmTimingWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const timing = useGetAtmTimingSectionData(offeringProfile);
  const title = 'Timing';
  const dataDescription = "All dates are presented in exchange country's local time zone.";
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {dataDescription && (
            <Typography paddingBottom={1} variant="body1">
              {dataDescription}
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
            {timing.map(({ name, value }) => {
              return (
                <Box
                  key={name}
                  alignContent="flex-start"
                  role="row"
                  minWidth={theme => theme.spacing(23.5)}
                  paddingY={theme => theme.spacing(1)}
                >
                  <Grid item role="rowheader" paddingBottom={theme => theme.spacing(0.5)}>
                    <Typography variant="highlight1" color="text.secondary" noWrap>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid container role="cell">
                    {typeof value === 'string' ? <Typography noWrap>{value}</Typography> : value}
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
