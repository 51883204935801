import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  dtc.sponsor_name,
  { ...dtc.offerings, label: 'Total Offerings' },
  dtc.offerings_volume,
  dtc.total_fees,
  dtc.follow_on_discount_to_last_trade,
  dtc.gross_spread,
  dtc.pct_of_market_cap,
  dtc.first_day_turnover,
  dtc.offer_to_open,
  dtc.offer_to_1day,
  dtc.offer_to_3day,
  dtc.offer_to_7day,
  dtc.offer_to_14day,
  dtc.offer_to_30day,
  dtc.offer_to_current,
];

export const visibleFields: string[] = [
  dtc.sponsor_name.field,
  dtc.offerings.field,
  dtc.offerings_volume.field,
  dtc.total_fees.field,
  dtc.follow_on_discount_to_last_trade.field,
  dtc.gross_spread.field,
  dtc.pct_of_market_cap.field,
  dtc.first_day_turnover.field,
  dtc.offer_to_open.field,
  dtc.offer_to_1day.field,
  dtc.offer_to_7day.field,
  dtc.offer_to_30day.field,
  dtc.offer_to_current.field,
];

export const staticFields: string[] = [dtc.sponsor_name.field, dtc.offerings.field];
