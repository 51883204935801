import { Select } from '@cmg/common';
import React from 'react';

type EmptyBoolean = boolean | string;
type Props = {
  value?: EmptyBoolean;
  onChange?: (value: boolean | null) => void;
};

const options = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
  // React select does not handle null on options so we can use a empty string as a workaround
  { value: '', label: 'Null' },
];

const NullableBooleanControl: React.FC<Props> = ({ onChange, value }) => {
  const handleOnChange = (newValue: EmptyBoolean | null) => {
    // Outside of the component we should only have boolean | null
    onChange && onChange(typeof newValue === 'boolean' ? newValue : null);
  };

  // The value from the props is boolean | null, for showing the correct option in the ui we need to
  // translate null to '' and also handle the initial undefined state
  let selectValue: EmptyBoolean | undefined = undefined;
  if (value !== undefined) {
    selectValue = typeof value === 'boolean' ? value : '';
  }
  return (
    <Select<EmptyBoolean>
      onChange={handleOnChange}
      value={selectValue}
      options={options}
      isClearable={false}
    />
  );
};

export default NullableBooleanControl;
