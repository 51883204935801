import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import KeyValueItem from '../components/KeyValueItem';
import { useGetCornerstoneSectionData } from '../hooks/useGetCornerstoneSectionData';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';
import { CornerstoneInvestmentSection } from '../types';
import {
  combinedInvestmentsRowStyle,
  combinedRowStyle,
  StyledTableCell,
  StyledTableHeader,
  totalRowStyle,
} from './CornerstoneInvestorsWidget.styles';

export type CornerstoneTableProps = {
  investments: CornerstoneInvestmentSection;
  investmentType: string;
};
export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const CornerstoneTable: React.FC<CornerstoneTableProps> = ({ investments, investmentType }) => {
  const {
    investorsCount,
    investmentAmount,
    individualInvestments,
    combinedAmount,
    combinedInvestments,
  } = investments;
  return (
    <Grid item minWidth={theme => theme.spacing(64)} flex={1}>
      <Typography variant="highlight1">{`Cornerstone\u2013${investmentType}`}</Typography>
      <Table size="medium">
        <TableHead>
          <TableRow color="text.secondary">
            <TableCell>Investor</TableCell>
            <TableCell>Type</TableCell>
            <StyledTableHeader align="center">Existing Investor</StyledTableHeader>
            <StyledTableHeader align="right">Amount</StyledTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={`Total ${investmentType}`} sx={totalRowStyle}>
            <StyledTableCell width="49%" colSpan={3}>
              <Typography variant="highlight1">{`Total ${investorsCount}`}</Typography>
            </StyledTableCell>
            <StyledTableCell align="right" width="17%">
              <Typography variant="highlight1">{investmentAmount}</Typography>
            </StyledTableCell>
          </TableRow>
          {individualInvestments.map(({ name, type, isNewInvestor, amount, isUndisclosed }) => {
            return (
              <TableRow key={name}>
                <StyledTableCell width="49%">
                  <Typography color={isUndisclosed ? 'text.secondary' : 'text.primary'}>
                    {isUndisclosed ? 'Not Disclosed' : name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell width="20%">{type}</StyledTableCell>
                <StyledTableCell align="center" width="14%">
                  {isNewInvestor}
                </StyledTableCell>
                <StyledTableCell align="right" width="17%">
                  {amount}
                </StyledTableCell>
              </TableRow>
            );
          })}
          {combinedInvestments.length > 0 && (
            <TableRow key={`Combined ${investmentType}`} sx={combinedRowStyle}>
              <StyledTableCell width="49%" colSpan={3}>
                <Typography variant="highlight2" paddingLeft={1}>
                  Combined Amount
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right" width="17%">
                <Typography variant="highlight2" paddingRight={1}>
                  {combinedAmount}
                </Typography>
              </StyledTableCell>
            </TableRow>
          )}
          {combinedInvestments.map(({ name, type, isNewInvestor }) => {
            return (
              <TableRow key={name} sx={combinedInvestmentsRowStyle}>
                <StyledTableCell width="49%">{name}</StyledTableCell>
                <StyledTableCell width="20%">{type}</StyledTableCell>
                <StyledTableCell align="center" width="14%">
                  {isNewInvestor}
                </StyledTableCell>
                <StyledTableCell align="right" width="17%"></StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

const CornerstoneInvestorsWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { offeringParticipants, privatePlacement, totalInvestorsCount, totalInvestmentAmount } =
    useGetCornerstoneSectionData(offeringProfile);
  const title = React.useMemo(
    () => `Cornerstone Investors (${totalInvestorsCount ?? 0})`,
    [totalInvestorsCount]
  );
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {!totalInvestorsCount || totalInvestorsCount === 0 ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <Stack gap={2}>
              <KeyValueItem
                size="large"
                label="Cornerstone Investment Total"
                value={totalInvestmentAmount}
              />
              <TableContainer>
                <Grid container spacing={3} display="flex">
                  {offeringParticipants.investorsCount > 0 && (
                    <CornerstoneTable
                      investments={offeringParticipants}
                      investmentType="Offering Participants"
                    />
                  )}
                  {privatePlacement.investorsCount > 0 && (
                    <CornerstoneTable
                      investments={privatePlacement}
                      investmentType="Private Placement"
                    />
                  )}
                </Grid>
              </TableContainer>
            </Stack>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CornerstoneInvestorsWidget;
