import React from 'react';

import { MenuItem, SMenuList } from './MenuList';

type Props = {
  onCreateReport: () => void;
};

const SaveDropdownMenu: React.FC<Props> = ({ onCreateReport }) => (
  <SMenuList>
    <MenuItem label="Save as New Report" onClick={onCreateReport} />
  </SMenuList>
);

export default SaveDropdownMenu;
