import styled from 'styled-components/macro';

export const SWidgetOfferingTotal = styled.div`
  color: ${({ theme }) => theme.text.color.link};
  font-weight: ${({ theme }) => theme.text.weight.thin};
  height: 260px;

  > div {
    padding: 15px 10px 20px 20px;

    > div {
      padding-bottom: 15px;
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: center;
      margin: 15px;
    }
  }
`;

export const SOfferings = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border.color.light};
`;

export const SLabel = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: 15px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text.color.dark};

  ${({ theme }) => theme.mediaQuery.largeUp} {
    font-size: 17px;
  }
`;

export const SValue = styled.div`
  font-size: 30px;
  line-height: 1em;

  ${({ theme }) => theme.mediaQuery.largeUp} {
    font-size: 40px;
  }

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    font-size: 52px;
  }
`;

export const SDollar = styled.span`
  vertical-align: text-top;
  font-size: 70%;
`;
