import styled, { css } from 'styled-components/macro';

import { isIE } from '../../common/helpers/helpers';

export const SDashboardLayout = styled.div`
  /** on smaller screens display recent priced deals column on top */
  flex-direction: column-reverse;
  height: 100%;
  display: flex;

  ${({ theme }) => theme.mediaQuery.xxlargeUp} {
    flex-direction: row;
  }
`;

export const SLeftContent = styled.div`
  padding: 0 15px;
  background-color: ${({ theme }) => theme.background.color.light};
  overflow-y: auto;

  /** Fixes height issues on safari and mobile safari */
  ${() =>
    !isIE() &&
    css`
      display: flex;
      flex: 1;
    `}

  ${({ theme }) => theme.mediaQuery.xxlargeUp} {
    display: flex;
    flex: 1 1 75%;
  }
`;

export const SRightContent = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background.color.white};

  /** displays as a column */
  ${({ theme }) => theme.mediaQuery.xxlargeUp} {
    flex: 1 1 0%;
  }

  /** IE needs min-width defined to render correctly */
  ${() =>
    isIE() &&
    css`
      min-width: 290px;
    `}
`;
