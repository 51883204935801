import { urlUtil } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import routeFactory from '../../../../common/util/routeFactory';
import { getFeatureToggles } from '../../../../config/appSettings';
import { OfferingType } from '../../../../types/domain/offering/constants';
import { isInternationalOffering as isInternationalOfferingFn } from '../../../datalab/model/utils';
import { fetchOfferingActions, selectOffering } from '../../../offering-dl/shared/ducks';
import Sidebar from './Sidebar';
import { getActiveParent, getConfigData, getMenuItems } from './sidebar.model';
import { SidebarConfigArgs } from './SidebarConfig';
import SidebarContext from './SidebarContext';

const mapStateToProps = state => ({
  featureToggles: getFeatureToggles(),
  offering: selectOffering(state),
});

const mapStateToDispatch = dispatch => ({
  actions: bindActionCreators(
    {
      fetchOffering: fetchOfferingActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  userPermissions: string[];
  showLiveAndFiled: boolean;
  onCollapseChange: (event: React.MouseEvent) => void;
} & RouteComponentProps;

export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapStateToDispatch>;

type State = {
  configData: SidebarConfigArgs | null;
  locationPathname: string;
  locationActiveKey: string | null;
  manualActivatedKeys: any[];
  filter: string;
};

export class SidebarContainerComponent extends React.Component<Props, State> {
  componentDidMount() {
    this.fetchOfferingIfNeeded();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.fetchOfferingIfNeeded();
    }
  }

  fetchOfferingIfNeeded() {
    const { actions, location, offering } = this.props;

    // Since OfferingProfile has been converted to GQL we no longer have the offering context fetched in redux
    // We see if we are on the OfferingProfile route and fetch the offering from the sidebar to compensate
    const match = matchPath<{ offeringId?: string }>(location.pathname, {
      path: routeFactory.offerings.routePath,
      exact: true,
      strict: false,
    });

    const offeringId = match?.params?.offeringId;
    if (offeringId && offeringId !== offering?.id) {
      actions.fetchOffering({ offeringId });
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let newState = state;

    const { location, userPermissions, featureToggles, showLiveAndFiled, offering } = props;

    const isInternationalOffering = isInternationalOfferingFn(offering);
    const offeringType: OfferingType = offering?.type;

    const configData: SidebarConfigArgs = getConfigData(
      JSON.stringify({
        pathname: location.pathname,
        query: urlUtil.queryParse(location.search),
      }),
      userPermissions,
      featureToggles,
      showLiveAndFiled,
      isInternationalOffering,
      offeringType
    );

    if (configData !== state.configData) {
      newState = { ...newState, configData };
    }

    const activeParent = getActiveParent(configData);
    if (props.location.pathname !== state.locationPathname) {
      newState = {
        ...newState,
        locationPathname: props.location.pathname,
      };

      if (activeParent && activeParent.key) {
        newState = {
          ...newState,
          locationActiveKey: activeParent.key,
          manualActivatedKeys: [activeParent.key],
        };
      }
    } else if (state.locationActiveKey && !activeParent) {
      newState = { ...newState, locationActiveKey: null, manualActivatedKeys: [] };
    } else if (activeParent && activeParent.key && state.locationActiveKey !== activeParent.key) {
      newState = {
        ...newState,
        locationActiveKey: activeParent.key,
        manualActivatedKeys: [activeParent.key],
      };
    }

    return newState !== state ? newState : null;
  }

  state: State = {
    configData: null,
    locationPathname: this.props.location.pathname,
    locationActiveKey: null,
    manualActivatedKeys: [],
    filter: '',
  };

  handleOnFilterChange = filter => {
    this.setState({ filter });
  };

  handleOnClearFilter = () => {
    this.setState({ filter: '' });
  };

  handleOnSubmenuClicked = key => {
    const { manualActivatedKeys } = this.state;

    const newKeys = manualActivatedKeys.includes(key) ? [] : [key];
    this.setState({ manualActivatedKeys: newKeys });
  };

  render() {
    const { onCollapseChange } = this.props;
    const { configData, manualActivatedKeys, filter } = this.state;

    const items = getMenuItems(filter, configData);

    return (
      <SidebarContext.Consumer>
        {({ collapsed }) => (
          <Sidebar
            collapsed={collapsed}
            configData={configData}
            filter={filter}
            items={items}
            manualActivatedKeys={manualActivatedKeys}
            onSubmenuClicked={this.handleOnSubmenuClicked}
            onFilterChange={this.handleOnFilterChange}
            onClearFilter={this.handleOnClearFilter}
            onCollapseChange={onCollapseChange}
          />
        )}
      </SidebarContext.Consumer>
    );
  }
}

const ConnectedSidebarContainerComponent = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapStateToDispatch>
>(
  mapStateToProps,
  mapStateToDispatch
)(SidebarContainerComponent);

export default withRouter(ConnectedSidebarContainerComponent);
