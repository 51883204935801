import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';

import DashboardValueBase, { Size } from '../dashboard-value-base/DashboardValueBase';

export type Props = {
  label?: string;
  value?: number | null;
  size?: Size;
  performance?: number | null;
  className?: string;
  precision?: number;
};

/**
 * PercentsValue displays a neutral percents value
 * with optional label, size and performance
 */
const PercentsValue: React.FC<Props> = ({
  label,
  value,
  size,
  performance,
  className,
  precision = 2,
}) => {
  const formattedValue = isNil(value) ? '-' : numericUtil.formatPercents(value, precision);

  return (
    <DashboardValueBase
      className={className}
      renderValue={() => formattedValue}
      label={label}
      size={size}
      performance={performance}
    />
  );
};

export default PercentsValue;
