import { PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const STableSettingsDropdown = styled.div`
  display: flex;
`;

export const SDownloadHeader = styled.div`
  color: ${({ theme }) => theme.text.color.white};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  justify-content: center;
  margin-bottom: 3px;
`;

export const SDownloadSubHeader = styled.div`
  color: ${({ theme }) => theme.text.color.white};
  font-size: ${({ theme }) => theme.text.size.small};
  justify-content: flex-start;
`;

export const SDownloadItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text.color.white};
  padding: 5px 10px;
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SDownloadItemLabel = styled.label`
  margin: 0 0 0 4px;
`;

export const SDownloadMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDownloadButton = styled(PrimaryButton)`
  margin-top: 8px;
`;
