import 'antd/lib/input/style/index.css';
import 'antd/lib/input-number/style/index.css';

import styled from 'styled-components/macro';

export const SNumericInput = styled.span`
  .ant-input-number {
    height: 34px;
    width: 100%;

    .ant-input-number-input {
      height: 32px;
    }
  }

  .ant-input-wrapper.ant-input-group {
    display: flex;

    .ant-input-group-addon {
      height: 32px;
    }
  }

  /* Only inline-cell specific styles, for some specific sections */
  /* Legacy parent selector */
  .inline-cell & {
    .ant-input-group {
      .ant-input-number {
        border: 1px solid ${({ theme }) => theme.border.color.dark};
        border-radius: 0;
      }

      & > *:first-child,
      *:first-child.ant-input-number {
        border-top-left-radius: ${({ theme }) => theme.border.radius.medium};
        border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
      }

      & > *:last-child,
      *:last-child.ant-input-number {
        border-top-right-radius: ${({ theme }) => theme.border.radius.medium};
        border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
        border-right-width: 1px;
      }

      &.is-focused > * {
        border-color: ${({ theme }) => theme.interactive.primary};
      }
    }
  }

  .ant-input {
    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`;
