import { DropdownButton, Icon } from '@cmg/common';
import React, { Component } from 'react';

import {
  SDescription,
  SDropdownIconWrapper,
  SHeaderItem,
  SLabel,
  SSummaryHeaderItem,
  SValue,
} from './SummaryHeaderItem.styles';

type Props = {
  style?: any;
  className?: string;
  columnConfig: {
    summaryConfig: Array<{ field: string; label: string; renderer?: any }>;
    renderer?: any;
  };
  summaryValues?: Object;
};

type State = {
  summaryKey?: string;
};

/**
 * The pinned row in the legacy VirtualizedTable uses this to display a dropdown with a set of options
 */
export default class SummaryHeaderItem extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { summaryConfig, defaultSummaryOption } = props.columnConfig;

    this.state = {
      summaryKey:
        summaryConfig?.length > 0 ? defaultSummaryOption || summaryConfig[0].field : undefined,
    };
  }

  onChange = summaryKey => {
    this.setState({ summaryKey });
  };

  summaryConfig = key => this.props.columnConfig.summaryConfig.find(s => s.field === key);

  renderSummaryValue(key) {
    const { columnConfig, summaryValues } = this.props;
    const summary = this.summaryConfig(key);

    if (!summaryValues || !summary) {
      return null;
    }

    const summaryRenderer = summary.renderer;
    const columnRenderer = columnConfig.renderer;
    const defaultRenderer = v => v;

    return (summaryRenderer || columnRenderer || defaultRenderer)(summaryValues[key]);
  }

  renderSummaryLabel(key) {
    const summary = this.summaryConfig(key);
    return summary ? summary.label : null;
  }

  renderContent() {
    const { columnConfig } = this.props;
    const { summaryKey } = this.state;

    if (!columnConfig.summaryConfig) {
      return null;
    }

    const content = (
      <React.Fragment>
        <div>
          <div>{this.renderSummaryLabel(summaryKey)}</div>
          <div>{this.renderSummaryValue(summaryKey)}</div>
        </div>
        {columnConfig.summaryConfig.length > 1 && (
          <SDropdownIconWrapper>
            <Icon name="angle-down" size="lg" />
          </SDropdownIconWrapper>
        )}
      </React.Fragment>
    );

    return columnConfig.summaryConfig.length === 1 ? (
      <SHeaderItem>{content}</SHeaderItem>
    ) : (
      <DropdownButton
        // @ts-ignore problem with DropdownButton props
        buttonComponent={SHeaderItem}
        options={columnConfig.summaryConfig.map(summaryItem => ({
          onClick: () => this.onChange(summaryItem.field),
          label: () => (
            <SDescription>
              <SLabel>{this.renderSummaryLabel(summaryItem.field)}</SLabel>
              <SValue>{this.renderSummaryValue(summaryItem.field)}</SValue>
            </SDescription>
          ),
        }))}
      >
        {content}
      </DropdownButton>
    );
  }

  render() {
    const { style, className } = this.props;

    return (
      <SSummaryHeaderItem style={style} className={className}>
        {this.renderContent()}
      </SSummaryHeaderItem>
    );
  }
}
