import { ConfirmModal } from '@cmg/common';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, InjectedProps } from 'redux-modal';

import { createModalActionCreators } from '../../../common/redux/reduxHelpers';

export const SESSION_IS_ABOUT_TO_EXPIRE_MODAL_ID = 'SESSION_IS_ABOUT_TO_EXPIRE_MODAL_ID';
const modalActions = createModalActionCreators(SESSION_IS_ABOUT_TO_EXPIRE_MODAL_ID);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      confirm: modalActions.confirm,
      cancel: modalActions.cancel,
    },
    dispatch
  ),
});

type OwnProps = {
  handleHide?: () => void;
  timeoutInMin: number;
};
type Props = OwnProps & ReturnType<typeof mapDispatchToProps> & InjectedProps;

export class SessionAboutToExpireModalComponent extends Component<Props> {
  handleSubmit = () => {
    const { handleHide, actions } = this.props;
    handleHide && handleHide();
    actions.confirm();
  };

  handleClose = () => {
    const { handleHide, actions } = this.props;
    handleHide && handleHide();
    actions.cancel();
  };

  render() {
    const { timeoutInMin } = this.props;
    const unit = timeoutInMin > 1 ? 'minutes' : 'minute';
    return (
      <ConfirmModal
        show
        title="Are You Still There?"
        onHide={this.handleClose}
        onConfirm={this.handleSubmit}
        cancelButtonCaption="Log Me Out"
        confirmButtonCaption="Keep Me Logged In"
      >
        <p>
          You will be automatically logged off in {timeoutInMin} {unit}. Any unsaved changes will be
          lost if your session is lost. Would you like to keep using the system?
        </p>
      </ConfirmModal>
    );
  }
}

const connectedModal = connect(null, mapDispatchToProps)(SessionAboutToExpireModalComponent);

export default connectModal({ name: SESSION_IS_ABOUT_TO_EXPIRE_MODAL_ID })(connectedModal);
