import { useAuth } from '@cmg/auth';
import { apiTypes, ApplicationError, urlUtil } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled, { css } from 'styled-components/macro';

import routeFactory from '../../../common/util/routeFactory';
import ScreenHeader from '../../shared/layout/ScreenHeader';

export const SApplicationErrorWrapper = styled.div<{ isLoggedIn: boolean }>`
  > * {
    /**
    * Bumping up the positioning of the error screen a bit
    * to account for the horizontal nav bar.
    */
    ${({ isLoggedIn }) =>
      isLoggedIn &&
      css`
        height: calc(100vh - 88px);
      `}
  }
`;

type Props = RouteComponentProps<{
  /**
   * The error code associated with the reason error
   * the user encountered.
   */
  errorCode: apiTypes.ServiceErrorCode;
}>;

/**
 * Route redirected to when:
 *  1) An OIDC authorization issue occurs after logging in
 *      but before the OIDC redirect occurs.
 *  2) A 403 response is received from an API.
 */
const ErrorRoute: React.FC<Props> = props => {
  const query = urlUtil.queryParse<{
    errorCode?: apiTypes.ServiceErrorCode;
  }>(props.location.search);
  const { isLoggedIn } = useAuth();

  return (
    <React.Fragment>
      {isLoggedIn && <ScreenHeader />}
      <SApplicationErrorWrapper isLoggedIn={isLoggedIn}>
        <ApplicationError
          errorCode={query.errorCode}
          returnUrl={routeFactory.root.routePath}
          variant={isLoggedIn ? 'LIGHT' : 'DARK'}
        />
      </SApplicationErrorWrapper>
    </React.Fragment>
  );
};

export default ErrorRoute;
