import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetStructureData, useGetTermsData, useHasRevisedTerm } from '../model/Shared.model';
import OfferingDetailRowItem from '../OfferingDetailRowItem';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};
const tableRowStyle: SxProps<Theme> = {
  '& th': {
    color: 'text.secondary',
  },
};

const tableSectionSizes: RegularBreakpoints = { xl: 8, lg: 8, md: 12, sm: 12 };
const listGroupSizes: RegularBreakpoints = { xl: 4, lg: 4, md: 12, sm: 12 };
const listSectionSizes: RegularBreakpoints = { xl: 12, lg: 12, md: 6, sm: 12 };

const StructureWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const hasRevisedTerm = useHasRevisedTerm(offeringProfile);
  const termsDetails = useGetTermsData(offeringProfile);
  const structure = useGetStructureData(offeringProfile);
  const title = 'Terms';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container alignItems="baseline" spacing={4}>
            <Grid item {...tableSectionSizes}>
              <TableContainer>
                <Table size="medium">
                  <TableHead>
                    <TableRow sx={tableRowStyle}>
                      <TableCell>Terms</TableCell>
                      <TableCell align="right">Initial</TableCell>
                      {hasRevisedTerm && <TableCell align="right">Revised</TableCell>}
                      <TableCell align="right">Final</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {termsDetails.map(({ name, initial, revised, final }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell height={50}>{name}</TableCell>
                          <TableCell align="right">{initial}</TableCell>
                          {hasRevisedTerm && <TableCell align="right">{revised}</TableCell>}
                          <TableCell align="right">{final}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container item {...listGroupSizes}>
              <Grid container item {...listSectionSizes}>
                {structure.slice(0, 8).map(({ name, value }) => {
                  return <OfferingDetailRowItem key={name} label={name} value={value} />;
                })}
              </Grid>
              <Grid container item {...listSectionSizes}>
                {structure.slice(8).map(({ name, value }) => {
                  return <OfferingDetailRowItem key={name} label={name} value={value} />;
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StructureWidget;
