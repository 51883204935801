import { DatePicker } from '@cmg/common';
import React from 'react';

import { ISODate } from '../../../../../types/common';

type Props = {
  value?: ISODate;
  onChange?: (value: ISODate | null) => void;
};

export const DateInputControl: React.FC<Props> = ({ onChange, value }) => {
  const handleOnChange = (newValue: ISODate | null) => {
    if (onChange && newValue !== value) {
      onChange(newValue);
    }
  };

  return <DatePicker onChange={handleOnChange} value={value} />;
};

export default DateInputControl;
