import { Box, numericUtil } from '@cmg/common';
import React, { useMemo } from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      above: number;
      aboveCount: number;
      below: number;
      belowCount: number;
      inRange: number;
      inRangeCount: number;
    };
  }[];
};

type RawItem = {
  y: number;
  x: string;
  count: number;
};

type ChartProps = BarChartProps<RawItem[]>;

const transformData = (chartData: Props['chartData'] = []): ChartProps['data'] => {
  return {
    datasets: [
      {
        label: 'Below',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.below,
            x: item.label,
            count: item.values.belowCount,
          })
        ),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'In Range',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.inRange,
            x: item.label,
            count: item.values.inRangeCount,
          })
        ),
        backgroundColor: barChartColors.light,
      },
      {
        label: 'Above',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.above,
            x: item.label,
            count: item.values.aboveCount,
          })
        ),
        backgroundColor: barChartColors.dark,
      },
    ],
  };
};

const getOptions = (): BarChartProps['options'] => {
  return {
    datasets: {
      bar: {
        borderRadius: 0,
        categoryPercentage: 0.3,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
          stepSize: 0.1,
          autoSkip: false,
        },
        max: 1,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;
            const raw = tooltipItem.raw as RawItem;

            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;
            return `${dataset.label ? `${dataset.label}: ` : ''}${numericUtil.formatPercents(
              parsed.y
            )}${countString ?? ''}`;
          },
        },
      },
    },
  };
};

const WidgetPricingVsInitialRange: React.FC<Props> = ({ chartData = [] }) => {
  const transformedData = useMemo(() => transformData(chartData), [chartData]);
  const options = useMemo(() => getOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title="Pricing vs. Initial Range" />

      <Box.Content>
        <SChart>
          <BarChart data={transformedData} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetPricingVsInitialRange;
