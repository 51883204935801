import { orderAscBySelector, orderByGroupBy, orderDescBySelector } from '../../../../utils/helpers';

export const orderByTypes = ['asc', 'desc'];

export const isComposite = column => column.type === 'COMPOSITE';

export const normalizeColumns = (columnsConfig, parentColumnField) =>
  columnsConfig.map(c => ({
    ...c,
    subColumns: isComposite(c) ? normalizeColumns(c.subColumns, c.field) : undefined,
    parentColumnField,
    renderer: c.renderer || (v => v),
    textAlign: c.textAlign || 'left',
    visible: true,
  }));

export const hasSummaryHeader = columns => columns.some(c => c.summaryConfig);

export const hasCompositeHeader = columns => !!columns.filter(c => isComposite(c)).length;

export const getVisibleColumns = columns => columns.filter(c => c.visible);

export const getVisibleSubColumns = compositeColumn =>
  getVisibleColumns(compositeColumn.subColumns);

export const getFlatVisibleColumns = columns =>
  getVisibleColumns(columns).reduce((flatColumns, column) => {
    if (isComposite(column)) {
      return flatColumns.concat(getVisibleSubColumns(column));
    }

    return flatColumns.concat(column);
  }, []);

export const getGroupedRows = (orderedRows, groupBy, emptyRow = { isEmptyRow: true }) => {
  if (groupBy === '') {
    return orderedRows;
  }

  const groupCompareFunction = orderAscBySelector.bind(this, item => {
    if (groupBy.includes('.')) {
      return groupBy
        .split('.')
        .reduce((value, key) => (value && value[key] ? value[key] : value), item);
    }
    return item[groupBy];
  });

  return orderedRows.reduce(
    ({ rows, lastRow }, row, index) => {
      const isSameGroup = groupCompareFunction(lastRow ?? row, row) === 0;

      if (!isSameGroup) {
        rows.push(emptyRow);
      }

      if (index === 0 || !isSameGroup) {
        rows.push({
          ...row,
          isGroupHeaderRow: true,
          groupBy,
        });
      }

      rows.push(row);
      return { rows, lastRow: row };
    },
    {
      rows: [],
      lastRow: null,
    }
  ).rows;
};

export const getNumberOfHeaderRows = (columns, rows) =>
  rows.length === 0 ? 0 : 1 + hasCompositeHeader(columns) + hasSummaryHeader(columns);

export const getRowsWithHeaderRows = (sourceRows, columns, headerRow = { isHeaderRow: true }) => {
  if (sourceRows.length === 0) {
    return sourceRows;
  }

  const hasSummary = hasSummaryHeader(columns);
  const hasComposite = hasCompositeHeader(columns);

  let rows = [];

  if (hasComposite) {
    rows = rows.concat({
      ...headerRow,
      isCompositeHeaderRow: true,
    });
  }

  rows = rows.concat(headerRow);

  if (hasSummary) {
    rows = rows.concat({
      ...headerRow,
      isSummaryHeaderRow: true,
    });
  }

  return rows.concat(...sourceRows);
};

export const filterCompositeColumnsOut = columns => {
  const simpleColumns = [];

  for (const c of columns) {
    if (isComposite(c)) {
      simpleColumns.push(...c.subColumns);
    } else {
      simpleColumns.push(c);
    }
  }

  return simpleColumns;
};

/**
 * Ordering
 */
export const applyOrdering = (rows, orderBy, orderByType, groupBy = '', groupByOrderType) => {
  if (groupBy === '' && orderByType === 'none') {
    return rows;
  }

  const compareFunction = {
    asc: orderAscBySelector.bind(this, item => item[orderBy]),
    desc: orderDescBySelector.bind(this, item => item[orderBy]),
    none: undefined,
  }[orderByType];

  if (groupBy !== '') {
    return orderByGroupBy(rows, groupBy, compareFunction, groupByOrderType);
  }

  return [...rows].sort(compareFunction);
};

export const getOrderByType = ({
  orderBy,
  currentOrderBy,
  currentOrderByType,
  groupBy,
  currentGroupByOrderType,
}) => {
  const defaultOrderByType = orderByTypes[0];

  const getNextOrderType = orderByType => {
    const index = orderByTypes.indexOf(orderByType);
    const nextIndex = (index + 1) % orderByTypes.length;

    return orderByTypes[nextIndex];
  };

  const orderByType =
    orderBy !== currentOrderBy ? defaultOrderByType : getNextOrderType(currentOrderByType);

  return {
    orderByType,
    groupByOrderType: orderBy === groupBy ? orderByType : currentGroupByOrderType,
  };
};

export function resolveOrderByProps(orderBy, orderByType) {
  return {
    orderBy: orderBy == null ? '' : orderBy,
    orderByType: orderByType == null ? 'none' : orderByType,
  };
}

export function resolveGroupByProps(groupBy, groupByOrderType, visibleColumns) {
  return {
    groupBy: groupBy && visibleColumns.find(col => col.field === groupBy) ? groupBy : '',
    groupByOrderType: groupByOrderType || orderByTypes[0],
  };
}

/**
 * Pagination Helpers
 */
export const getTotalPages = (itemsTotal, itemsPerPage) => Math.ceil(itemsTotal / itemsPerPage);

export const getItemsForPage = (items, page, itemsPerPage) => {
  const startIdx = page * itemsPerPage - itemsPerPage;
  return items.slice(startIdx, startIdx + itemsPerPage);
};
