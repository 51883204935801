import { SecondaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

// eslint-disable-next-line no-unexpected-multiline
export const StyledFilterPanelToggleButton = styled(SecondaryButton)<{
  isFilterPanelExpanded: boolean;
}>`
  height: 34px;
  color: ${({ theme }) => theme.text.color.dark};
  border: 1px solid ${({ theme }) => theme.color.gray1};
  background-color: ${({ theme, isFilterPanelExpanded }) =>
    isFilterPanelExpanded ? theme.background.color.light : 'transparent'};

  &:hover {
    background-color: ${({ theme, isFilterPanelExpanded }) =>
      isFilterPanelExpanded ? theme.background.color.light : 'inherit'};
    color: ${({ theme }) => theme.text.color.dark};
  }

  &:active,
  &:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.interactive.primary};
  }
`;

export const SFilterButtonWrapper = styled.div<{ hasFilters: boolean }>`
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 17px;
  width: ${({ hasFilters }) => (hasFilters ? '105px' : '80px')};
`;

export const SDropdownFilterCountBadge = styled.div`
  margin: 0 0 0 3px;
  border-radius: 10px;
  line-height: 11px;
  padding: 3px 5px;
  background-color: ${({ theme }) => theme.interactive.primary};
  color: ${({ theme }) => theme.interactive.primaryContrast};
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SDropdownText = styled.span`
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SDropdownIconWrapper = styled.span`
  margin: 0 5px;
  color: ${({ theme }) => theme.color.gray7};

  :hover {
    color: ${({ theme }) => theme.color.gray8};
  }

  ${StyledFilterPanelToggleButton}:selected & {
    color: ${({ theme }) => theme.color.gray9};
  }
  ${StyledFilterPanelToggleButton}:focus & {
    color: ${({ theme }) => theme.color.gray9};
  }
`;
