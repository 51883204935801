import { numericUtil, Popover } from '@cmg/common';
import React from 'react';

import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { Offering } from '../offerings-report-table/types';
import { SShareholderName } from './ShareholderRenderer.styles';

export type Props = Readonly<{
  data?: Offering;
}>;

const ShareholderRenderer: React.FC<Props> = ({ data }) => {
  const { shareholders, attributes } = data ?? {};

  return (
    <Popover
      variant="TOOLTIP"
      placement="left"
      transparentBackground
      content={
        <PopoverContentTable alignAll="right" alignFirstColumn="left">
          <thead>
            <tr>
              <th>Shareholder</th>
              <th>Sponsor type</th>
              <th colSpan={2}>Pre-offering shares</th>
              <th colSpan={2}>Post-offering shares</th>
            </tr>
          </thead>
          <tbody>
            {shareholders?.map(s => (
              <tr key={s.shareholderId}>
                <td>{s.name}</td>
                <td>{s.shareholderTypeDisplayName}</td>
                <td>{numericUtil.formatInteger(s.preOfferingOwnership)}</td>
                <td>{numericUtil.formatPercents(s.pctPreOfferingOwnership, 1)}</td>
                <td>{numericUtil.formatInteger(s.postOfferingOwnership)}</td>
                <td>{numericUtil.formatPercents(s.pctPostOfferingOwnership, 1)}</td>
              </tr>
            ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      <SShareholderName>{attributes?.primaryShareholderName}</SShareholderName>
    </Popover>
  );
};

export default ShareholderRenderer;
