import { Column, Row } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { ParticipationDetail as ParticipationDetailType } from '../../../../../../types/domain/my-dashboard/myParticipationSummary';
import CurrencyValue from '../../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../../common/dashboard-value/numeric-value/NumericValue';
import PerformancePercentsValue from '../../../../common/dashboard-value/performance-percents-value/PerformancePercentsValue';
import { DetailTitle } from '../../../common/DashboardPeriod';

const StyledColumn = styled(Column)`
  margin: 3px 0;
`;

const SParticipationDetail = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
`;

type Props = {
  data?: ParticipationDetailType;
  label: string;
};

const ParticipationDetail: React.FC<Props> = ({ label, data }) => {
  return (
    <SParticipationDetail>
      <StyledColumn sm={6}>
        <DetailTitle>{label}</DetailTitle>
      </StyledColumn>
      <StyledColumn sm={5}>
        <NumericValue value={data?.numberOfOfferings} />
      </StyledColumn>
      <StyledColumn sm={5}>
        <CurrencyValue value={data?.invested} />
      </StyledColumn>
      <StyledColumn sm={5}>
        <PerformancePercentsValue value={data?.performance} />
      </StyledColumn>
    </SParticipationDetail>
  );
};

export default ParticipationDetail;
