import { getCurrencySymbol, numericUtil, timeUtil } from '@cmg/common';

export const millionLocalCurrencyTableColumn = (value, currencyCode?) =>
  value === null || value === undefined
    ? ''
    : `${getCurrencySymbol(currencyCode)}${numericUtil.formatInteger(value)}`;

export const decimalLocalCurrencyTableColumn = (value, currencyCode?) =>
  value === null || value === undefined
    ? ''
    : `${getCurrencySymbol(currencyCode)}${numericUtil.formatNumericValue(value, {
        precision: 2,
      })}`;

export const dateTableColumn = (v?: string | Date | null): string => {
  return timeUtil.formatAsMonthDateYear(v ?? '') ?? '';
};
