import { Box, numericUtil } from '@cmg/common';
import React, { useMemo, useState } from 'react';

import {
  CustomLegend,
  DoughnutChart,
  DoughnutChartProps,
} from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { getTypeColor } from '../../../colors';
import valueAmountButtons, {
  ValueAmountButtons,
} from '../../../model/buttons/value-amount-buttons';
import { SButtonBox, SChart, SDatalabChart, SDoughnut } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      value: number;
      underwritten_value: number;
      amount: number;
    };
  }[];
  title: string;
};

const transformData = (
  chartData: Props['chartData'] = [],
  activeBtn: any
): DoughnutChartProps['data'] => {
  const field = activeBtn;

  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        data: chartData.map(item => item.values[field]),
        backgroundColor: chartData.map(item => getTypeColor(item.label)),
      },
    ],
  };
};

const getOptions = (activeBtn: any): DoughnutChartProps['options'] => {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
            let total = 0;
            tooltipItem.dataset.data.forEach((dataValue: any) => {
              total += dataValue;
            });
            const formattedValue =
              activeBtn === ValueAmountButtons.VALUE
                ? numericUtil.formatCurrencyInBillions(value)
                : value;
            return `${formattedValue} (${numericUtil.formatPercents(value / total, 1)})`;
          },
        },
      },
    },
  };
};

const WidgetHistoricalOfferingVolumeByType: React.FC<Props> = ({
  chartData = [],
  title = 'Offering Volume by Type',
}) => {
  const [activeBtn, setActiveBtn] = useState(valueAmountButtons[0]);

  const transformedData = useMemo(
    () => transformData(chartData, activeBtn.value),
    [chartData, activeBtn.value]
  );
  const options = useMemo(() => getOptions(activeBtn.value), [activeBtn.value]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart>
      <Box.Header title={title} />

      <SButtonBox>
        <Toggler
          buttons={valueAmountButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SDoughnut>
          <SChart>
            <DoughnutChart data={transformedData} options={options} />
          </SChart>

          <CustomLegend
            chartData={transformedData}
            chartType="doughnut"
            valueFormatter={
              activeBtn.value === ValueAmountButtons.VALUE
                ? numericUtil.formatCurrencyInBillions
                : undefined
            }
          />
        </SDoughnut>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetHistoricalOfferingVolumeByType;
