import { Option, ToastManager } from '@cmg/common';
import React, { useCallback } from 'react';

import { SortEnumType } from '../../../../../graphql/__generated__/index';
import { SearchFilterHookResult } from '../../../../../graphql/types';
import {
  useOfferingsFilterForm_LeftLeadsLazyQuery,
  useOfferingsFilterForm_LeftLeadsQuery,
} from '../graphql/__generated__/OfferingsFilterForm_LeftLeads';
import { createLeftLeadOptions, useCreateLeftleadOptions } from '../OfferingsFilterForm.model';

export const useLoadMissingLeftLeads = () => {
  const [loadLeftLeads] = useOfferingsFilterForm_LeftLeadsLazyQuery();
  const loadMissingUnderwriters = useCallback(
    async (ids: string[]) => {
      const { data } = await loadLeftLeads({
        variables: {
          where: {
            id: { in: ids },
          },
        },
        fetchPolicy: 'cache-first',
      });
      return createLeftLeadOptions(data?.managers?.items ?? []);
    },
    [loadLeftLeads]
  );
  return loadMissingUnderwriters;
};

export const useSearchLeftleads = (): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useOfferingsFilterForm_LeftLeadsQuery({
    variables: {
      order: { name: SortEnumType.Asc },
      where: {
        offeringManagers: { some: { isLeftLead: { eq: true } } },
        name: searchText ? { like: searchText } : undefined,
      },
    },
    fetchPolicy: 'cache-first',
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const leftleadOptions = useCreateLeftleadOptions(data?.managers?.items ?? []);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching leftleads');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(leftleadOptions);
    }
  }, [loading, data, error, leftleadOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: leftleadOptions,
    loadOptions,
  };
};
