import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InfoOutlinedIcon,
  RegularBreakpoints,
  TextField,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  onSaveUserNote?: (userNote: string | null) => void;
  userNote?: string | null;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const UserNotesWidget: React.FC<Props> = ({ userNote, onSaveUserNote, cardSizes, id }) => {
  const title = 'Private Notes';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: userNote ?? '',
    },
    onSubmit: value => {
      onSaveUserNote && onSaveUserNote(value?.note);
    },
  });
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Grid container alignItems="baseline" justifyContent="space-between" paddingRight={2}>
              <Grid container item maxWidth={theme => theme.spacing(20)}>
                <Typography variant="h3" textTransform="none" sx={cardTitleStyle}>
                  {title}
                </Typography>
                <Tooltip
                  title={
                    <Grid maxWidth={theme => theme.spacing(19.75)}>
                      <Typography variant="tooltip">
                        Notes are private to you and not visible to anyone else
                      </Typography>
                    </Grid>
                  }
                  placement="top-start"
                  variant="info"
                >
                  <Typography color="text.secondary" paddingLeft={1}>
                    <InfoOutlinedIcon fontSize="small" />
                  </Typography>
                </Tooltip>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  formik.submitForm();
                }}
              >
                <Typography variant="highlight1">Save</Typography>
              </Button>
            </Grid>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <FormikProvider value={formik}>
            <Form>
              <TextField
                label="Notes"
                name="note"
                multiline
                rows={6}
                value={formik.values.note}
                onChange={formik.handleChange}
              />
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserNotesWidget;
