import { permissionsByEntity, useAuth, useCheckPermissions } from '@cmg/auth';
import React from 'react';

import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { type Calendar_OfferingFieldsFragment, useCalendar_OfferingListQuery } from '../graphql';
import { FilterValues } from '../model/calendar-filters';
import { getGraphqlOrder, getGraphqlWhere, OrderProps } from './graphql-query';

export type { Calendar_OfferingFieldsFragment, OrderProps };

export type ReloadProps = {
  pagination: { activePage: number; itemsPerPage: number };
  order: OrderProps;
};

type Params = {
  calendarCategory: CalendarCategory;
  filters: FilterValues;
  defaultOrder: OrderProps;
  defaultGroupOrder: OrderProps;
};

export default function useCalendarQuery({
  calendarCategory,
  filters,
  defaultOrder,
  defaultGroupOrder,
}: Params) {
  const { oidcUserId, userPermissions } = useAuth();

  const [pagination, setPagination] = React.useState<ReloadProps['pagination']>({
    activePage: 1,
    itemsPerPage: 100,
  });
  const resetPagination = () =>
    setPagination(currentPagination => ({ ...currentPagination, activePage: 1 }));

  const [order, setOrder] = React.useState<ReloadProps['order']>({
    orderBy: defaultOrder.orderBy,
    orderByType: defaultOrder.orderByType,
  });

  const handleOnReload = (reload: ReloadProps) => {
    setPagination(reload.pagination);
    setOrder(reload.order);
  };

  const queryResult = useCalendar_OfferingListQuery({
    variables: {
      skip: (pagination.activePage - 1) * pagination.itemsPerPage,
      take: pagination.itemsPerPage,
      where: getGraphqlWhere({ userId: oidcUserId!, calendarCategory, userPermissions, filters }),
      order: getGraphqlOrder({ secondaryOrder: order, primaryOrder: defaultGroupOrder }),
      // DLGW GQL implementation uses standard pattern for authorization by populating
      // the result error collection with any/all authorization failures. This is a temporary
      // solution as currently FE will show error UI when error collection is populated.
      hasIoiPermission: useCheckPermissions([permissionsByEntity.Ioi.READ]),
      hasCustomSectorsPermission: useCheckPermissions([permissionsByEntity.CustomSectors.READ]),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return {
    queryResult,
    pagination,
    resetPagination,
    order,
    handleOnReload,
  };
}
