/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanySearch_IssuerFragment = {
  __typename?: 'Issuer';
  id?: string | null;
  name?: string | null;
  primarySymbol?: string | null;
};

export type CompanySearch_IssuerListQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']>;
  searchTermFirstCharUpper?: Types.InputMaybe<Types.Scalars['String']>;
  searchTermFirstCharLower?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type CompanySearch_IssuerListQuery = {
  __typename?: 'Query';
  issuers?: {
    __typename?: 'IssuerCollectionSegment';
    items?: Array<{
      __typename?: 'Issuer';
      id?: string | null;
      name?: string | null;
      primarySymbol?: string | null;
    }> | null;
  } | null;
};

export const CompanySearch_IssuerFragmentDoc = gql`
  fragment CompanySearch_Issuer on Issuer {
    id
    name
    primarySymbol
  }
`;
export const CompanySearch_IssuerListDocument = gql`
  query CompanySearch_IssuerList(
    $searchTerm: String
    $searchTermFirstCharUpper: String
    $searchTermFirstCharLower: String
  ) {
    issuers(
      where: {
        or: [
          { primarySymbol: { startsWith: $searchTerm } }
          {
            and: [
              { name: { like: $searchTerm } }
              {
                or: [
                  { name: { startsWith: $searchTermFirstCharUpper } }
                  { name: { startsWith: $searchTermFirstCharLower } }
                ]
              }
            ]
          }
        ]
      }
      order: { primarySymbol: ASC, name: ASC }
      take: 100
    ) {
      items {
        ...CompanySearch_Issuer
      }
    }
  }
  ${CompanySearch_IssuerFragmentDoc}
`;

/**
 * __useCompanySearch_IssuerListQuery__
 *
 * To run a query within a React component, call `useCompanySearch_IssuerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearch_IssuerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearch_IssuerListQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      searchTermFirstCharUpper: // value for 'searchTermFirstCharUpper'
 *      searchTermFirstCharLower: // value for 'searchTermFirstCharLower'
 *   },
 * });
 */
export function useCompanySearch_IssuerListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanySearch_IssuerListQuery,
    CompanySearch_IssuerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySearch_IssuerListQuery, CompanySearch_IssuerListQueryVariables>(
    CompanySearch_IssuerListDocument,
    options
  );
}
export function useCompanySearch_IssuerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySearch_IssuerListQuery,
    CompanySearch_IssuerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySearch_IssuerListQuery, CompanySearch_IssuerListQueryVariables>(
    CompanySearch_IssuerListDocument,
    options
  );
}
export function useCompanySearch_IssuerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanySearch_IssuerListQuery,
        CompanySearch_IssuerListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanySearch_IssuerListQuery,
    CompanySearch_IssuerListQueryVariables
  >(CompanySearch_IssuerListDocument, options);
}
export type CompanySearch_IssuerListQueryHookResult = ReturnType<
  typeof useCompanySearch_IssuerListQuery
>;
export type CompanySearch_IssuerListLazyQueryHookResult = ReturnType<
  typeof useCompanySearch_IssuerListLazyQuery
>;
export type CompanySearch_IssuerListSuspenseQueryHookResult = ReturnType<
  typeof useCompanySearch_IssuerListSuspenseQuery
>;
export type CompanySearch_IssuerListQueryResult = Apollo.QueryResult<
  CompanySearch_IssuerListQuery,
  CompanySearch_IssuerListQueryVariables
>;
