import { Table, TableBody, TableHead, TableRow } from '@cmg/design-system';
import React from 'react';

import { NoDataAvailable } from '../../../../../common/components/table/table';
import {
  StyledGrid,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from '../../../../../common/components/table/table.styles';
import { CompanyProfile_Offering_FieldsFragment } from '../../../graphql/__generated__/CompanyProfile';
import {
  hideOfferingRestrictedFields,
  useGetOfferingsTableModel,
} from '../table-model/OfferingsTable.model';
import { TableColumnProps } from '../table-model/types';

type Props = {
  offerings: CompanyProfile_Offering_FieldsFragment[];
  permissions: TableColumnProps;
};

const OfferingsTable: React.FC<Props> = ({ offerings, permissions }) => {
  const noAtmTableModel = useGetOfferingsTableModel(permissions);

  return (
    <StyledGrid>
      <Table data-testid="offerings-table">
        <TableHead>
          {(offerings?.length ?? 0) > 0 && (
            <TableRow color="text.secondary">
              {noAtmTableModel.map((column, index) => (
                <StyledTableHeader key={index} {...column.headerStyles}>
                  {column.label}
                </StyledTableHeader>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {offerings.map(hideOfferingRestrictedFields).map(offering => {
            if (!offering.attributes) {
              return null;
            }
            return (
              <StyledTableRow key={offering.id}>
                {noAtmTableModel.map((column, index) => (
                  <StyledTableCell key={`${offering.id}_${index}`} {...column.contentStyles}>
                    {column.render(offering, permissions)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            );
          })}
          {!offerings?.length && <NoDataAvailable />}
        </TableBody>
      </Table>
    </StyledGrid>
  );
};

export default OfferingsTable;
