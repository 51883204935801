import { FormField, MultiSelect, SecondaryButton, Select, TextInput } from '@cmg/common';
import React from 'react';

import { Filter } from '../../../../../common/helpers/helpers';
import {
  SFieldWrapper,
  SFilters,
  SInputGroup,
  SSearch,
  StyledSearchButton,
} from './Filters.styles';

export type Props = {
  filters: Filter[];
  handlePagination?: (page: number) => void;
  onResetFilters?: () => void;
  hideReset?: boolean;
  justifyContent?: boolean; // stretch filters inside to fit wrapper width
};

export default class Filters extends React.Component<Props> {
  renderFilter(filterData: Filter) {
    const {
      field,
      elType,
      multi,
      searchable,
      label,
      placeholder,
      value,
      options,
      onChange,
      className,
      hideIcon,
    } = filterData;

    if (!['select', 'text'].includes(elType)) {
      return null;
    }

    const onChangeFilter = this.getOnChange(onChange);

    if (elType === 'select') {
      const selectProps = {
        label,
        options,
        onChange: onChangeFilter,
        isClearable: false,
        isSearchable: searchable || false,
        key: field,
      };

      return (
        <FormField label={label}>
          {multi ? (
            <MultiSelect {...selectProps} value={value as string[]} />
          ) : (
            <Select {...selectProps} value={value as string} />
          )}
        </FormField>
      );
    }

    const input = (
      <TextInput
        placeholder={placeholder || label}
        value={value as string}
        onChange={onChangeFilter}
      />
    );

    return (
      <SSearch>
        <FormField label={label || 'Filter'} className={`${className || ''}`}>
          {hideIcon ? (
            input
          ) : (
            <SInputGroup>
              {input}
              <StyledSearchButton iconLeft={{ name: 'search', size: 'lg', fixedWidth: true }} />
            </SInputGroup>
          )}
        </FormField>
      </SSearch>
    );
  }

  getOnChange =
    onChange =>
    (...params) => {
      this.props.handlePagination && this.props.handlePagination(1);
      onChange(...params);
    };

  onResetFilters = () => {
    this.props.handlePagination && this.props.handlePagination(1);
    this.props.onResetFilters && this.props.onResetFilters();
  };

  render() {
    const { filters, hideReset, justifyContent } = this.props;

    if (!filters || !filters.length) {
      return null;
    }

    return (
      <SFilters>
        {filters.map(filter => (
          <SFieldWrapper key={filter.field} justifyContent={justifyContent}>
            {this.renderFilter(filter)}
          </SFieldWrapper>
        ))}

        {hideReset !== true && (
          <div>
            <SecondaryButton onClick={this.onResetFilters}>Reset All</SecondaryButton>
          </div>
        )}
      </SFilters>
    );
  }
}
