import { numericUtil } from '@cmg/common';
import { Tooltip, Typography } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';

export type Props = {
  value?: number | null;
  nullValue?: string;
  precision?: number;
  tooltip?: string;
  tooltipVariant?: 'error' | 'success' | 'info' | 'warning';
};

const tooltipOffset = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10],
      },
    },
  ],
};

/**
 * Formats a percent value with tooltip hint
 */
const PerformancePercentsWithTooltip: React.FC<Props> = ({
  precision = 1,
  value,
  nullValue = '-',
  tooltip,
  tooltipVariant,
}) => {
  const getVariant = React.useMemo(() => {
    if (isNil(value) || value === 0) {
      return { color: theme => theme.palette.text.primary };
    } else if (value && value < 0) {
      return { color: theme => theme.palette.error.main };
    } else {
      return { color: theme => theme.palette.success.main };
    }
  }, [value]);

  const formattedValue = React.useMemo(() => {
    return numericUtil.formatPercents(value, precision);
  }, [precision, value]);

  if (isNil(value)) {
    return <React.Fragment>{nullValue}</React.Fragment>;
  }
  return tooltip ? (
    <Tooltip
      title={tooltip}
      placement="top-end"
      variant={tooltipVariant}
      PopperProps={tooltipOffset}
    >
      <Typography variant="hint" sx={getVariant}>
        {formattedValue}
      </Typography>
    </Tooltip>
  ) : (
    <Typography sx={getVariant}>{formattedValue}</Typography>
  );
};

export default PerformancePercentsWithTooltip;
