import { ReportTableFieldName, SortOrder } from './ReportTableConfig';

export type SortState = {
  orderDirection: SortOrder;
  orderField: ReportTableFieldName;
};

export const getNextOrderDirection = (nextOrderField: ReportTableFieldName, sort: SortState) => {
  if (sort.orderField !== nextOrderField) {
    return SortOrder.ASC;
  }

  return sort.orderDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
};
