import { Box, numericUtil } from '@cmg/common';
import React, { useMemo, useState } from 'react';

import { BarChart, BarChartProps, CustomLegend } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors } from '../../../colors';
import sizeCapButtons, { SizeCapButtons } from '../../../model/buttons/size-cap-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      dealSizeMeanBg: number;
      dealSizeMedianBg: number;
      marketCapMeanBg: number;
      marketCapMedianBg: number;
    };
  }[];
};

const transformData = (chartData: Props['chartData'] = [], activeBtn): BarChartProps['data'] => {
  if (activeBtn.value === SizeCapButtons.OFFERING_SIZE) {
    return {
      labels: chartData.map(item => item.label),
      datasets: [
        {
          label: 'Deal Size (MEAN)',
          data: chartData.map(item => item.values.dealSizeMeanBg),
          backgroundColor: barChartColors.lighter,
        },
        {
          label: 'Deal Size (MEDIAN)',
          data: chartData.map(item => item.values.dealSizeMedianBg),
          backgroundColor: barChartColors.light,
        },
      ],
    };
  }

  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        label: 'Market Cap (MEAN)',
        data: chartData.map(item => item.values.marketCapMeanBg),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Market Cap (MEDIAN)',
        data: chartData.map(item => item.values.marketCapMedianBg),
        backgroundColor: barChartColors.light,
      },
    ],
  };
};

const getOptions = (): BarChartProps['options'] => ({
  scales: {
    y: {
      ticks: {
        callback: value =>
          typeof value === 'number' ? numericUtil.formatCurrencyInBillions(value) : value,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: tooltipItem => {
          const { dataset, parsed } = tooltipItem;
          return `${
            dataset.label ? `${dataset.label}: ` : ''
          }${numericUtil.formatCurrencyInBillions(parsed.y)}`;
        },
      },
    },
  },
});

const WidgetHistoricalDealSizeMarketCapTrends = ({ chartData = [] }: Props) => {
  const [activeBtn, setActiveBtn] = useState(sizeCapButtons[0]);

  const transformedData = useMemo(
    () => transformData(chartData, activeBtn),
    [chartData, activeBtn]
  );

  const options = useMemo(() => getOptions(), []);

  const handleButtonChange = activeBtn => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart horizontal>
      <Box.Header title="Historical Deal Size and Market Cap Trends" />

      <SButtonBox>
        <Toggler
          buttons={sizeCapButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <BarChart barSizingMode="CMGCenteredBarPlugin" data={transformedData} options={options} />
        </SChart>

        <CustomLegend chartData={transformedData} chartType="bar" />
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetHistoricalDealSizeMarketCapTrends;
