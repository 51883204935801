import styled from 'styled-components/macro';

export const SAllReportsPanelWrapper = styled.div`
  padding: 10px 20px;
`;

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SSavedReportsHeader = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin: 10px 0;
`;

export const SSavedReportTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SPaginationWrapper = styled.div`
  margin: 10px 0;
`;
