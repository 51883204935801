import { getOperationName } from '@apollo/client/utilities';
import { Icon, Popover } from '@cmg/common';
import { companyScreenSubHeaderTestId } from '@cmg/e2e-selectors';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import React from 'react';

import { Currency } from '../../../../graphql/__generated__';
import { Pill } from '../../../../obsolete/components/ui';
import { useCompaniesGrid_UpsertCustomSectorIssuersMutation } from '../../../settings/companies/companies-grid/graphql/__generated__/CompaniesGrid_Issuers';
import ScreenSubHeader from '../../../shared/layout/ScreenSubHeader';
import { getCurrencyFormatInBillions } from '../../../shared/model/utils';
import SectorProvider from '../../../shared/sectors/SectorProvider';
import { onAssignSectorError } from '../../../shared/sectors/sectors.model';
import {
  CompanyProfile_Issuer_FieldsFragment,
  CompanyProfileDocument,
} from '../../graphql/__generated__/CompanyProfile';
import { InfoIconWrapper, PopoverContent } from './CompanySubHeader.styles';

export type Props = {
  company: CompanyProfile_Issuer_FieldsFragment;
  showInternational: boolean;
  showAtm: boolean;
};

export const CompanySubHeader: React.FC<Props> = ({ company, showInternational, showAtm }) => {
  const [assignCustomSectorToIssuer, { loading: upsertLoading }] =
    useCompaniesGrid_UpsertCustomSectorIssuersMutation({
      refetchQueries: [getOperationName(CompanyProfileDocument)!],
      onError: onAssignSectorError,
    });

  const handleSubmit = async (sectorId: string) => {
    if (!company.id) {
      return;
    }

    await assignCustomSectorToIssuer({
      variables: {
        input: {
          customSectorId: sectorId,
          issuerIds: [company.id],
        },
      },
    });
  };

  const capitalRaised = {
    value: company.capitalRaisedUsd,
    currency: Currency.Usd,
  };
  if (showInternational && !isNil(company.capitalRaised)) {
    capitalRaised.value = company.capitalRaised;

    const occurringCurrencies = uniq(
      company.offerings.map(({ attributes }) => attributes?.pricingCurrency)
    );
    if (occurringCurrencies.length === 1) {
      capitalRaised.currency = occurringCurrencies[0] as Currency;
    }
  }

  return (
    <SectorProvider onSubmit={handleSubmit} isSubmitting={upsertLoading}>
      {({ SectorToggle, SectorPicker }) => (
        <ScreenSubHeader
          title={
            <div data-test-id={companyScreenSubHeaderTestId.testId}>
              {company.name} <span className="ticker">({company.primarySymbol})</span>
              {company.isDelisted &&
                company.offerings.some(({ firstTradeDate }) => !!firstTradeDate) && (
                  <Pill type="danger" uppercase>
                    Delisted
                  </Pill>
                )}
            </div>
          }
          bottomData={[
            {
              label: <SectorToggle />,
              value: (
                <SectorPicker
                  sector={company.sectorDisplayName}
                  customSector={company.customSectors?.customSector?.name}
                />
              ),
            },
            {
              label: 'Sub Sector',
              value: (
                <SectorPicker
                  sector={company.subSectorDisplayName}
                  customSector={company.customSectors?.customSubSector?.name}
                />
              ),
            },
          ]}
          rightData={[
            {
              label: 'Offerings',
              value: company.offeringsCount,
            },
            {
              label: (
                <React.Fragment>
                  Capital Raised
                  {showAtm && (
                    <Popover
                      variant="DARK"
                      placement="topLeft"
                      hideArrow
                      content={<PopoverContent>Excludes ATM Offerings</PopoverContent>}
                    >
                      <InfoIconWrapper>
                        <Icon name="info-circle" size="lg" className="icon" />
                      </InfoIconWrapper>
                    </Popover>
                  )}
                </React.Fragment>
              ),
              value: capitalRaised.value
                ? getCurrencyFormatInBillions({
                    value: capitalRaised.value,
                    pricingCurrencyCode: capitalRaised.currency,
                    showInternational,
                  })
                : '-',
            },
          ]}
        />
      )}
    </SectorProvider>
  );
};

export default CompanySubHeader;
