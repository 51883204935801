import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OfferingNotes from '../components/OfferingNotes';
import {
  createOfferingNoteActions,
  deleteOfferingNoteActions,
  fetchOfferingNotesActions,
  selectNotes,
  updateOfferingNoteActions,
} from '../ducks';

const mapStateToProps = state => ({
  offeringNotes: selectNotes(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchOfferingNotes: fetchOfferingNotesActions.request,
      createOfferingNote: createOfferingNoteActions.request,
      updateOfferingNote: updateOfferingNoteActions.request,
      deleteOfferingNote: deleteOfferingNoteActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  offeringId: string;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const OfferingNotesContainer: React.FC<Props> = ({ offeringNotes, offeringId, actions }) => {
  React.useEffect(() => {
    actions.fetchOfferingNotes(offeringId);
  }, [actions, offeringId]);

  return (
    <OfferingNotes
      offeringId={offeringId}
      offeringNotes={offeringNotes}
      createOfferingNote={actions.createOfferingNote}
      updateOfferingNote={actions.updateOfferingNote}
      deleteOfferingNote={actions.deleteOfferingNote}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingNotesContainer);
