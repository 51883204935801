import { Checkbox } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledCheckbox = styled(Checkbox)`
  margin: 0 5px 0 0;
`;

export const SGroupLabelWrapper = styled.div`
  display: flex;
  margin: 5px 0 0 0;
  cursor: default;
`;
