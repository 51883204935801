import { allOption } from '../../features/shared/constants/constants';

/**
 * Select input helpers
 * First param must be bigger than second. If you want a solution,
 * which works for all lengths use arrSymmetricDifference.
 * @param a1 {Array}
 * @param a2 {Array}
 */
// todo remove
// see getNewSelectedValues
const arrDifference = (a1, a2) => {
  const a2Set = new Set(a2);

  return a1.filter(x => !a2Set.has(x));
};

// todo remove
// depends on removing old Filters
export const getNewSelectedValues = (oldValues, currentValues) => {
  const newValue = arrDifference(currentValues, oldValues);
  let newValues = [...currentValues];

  // If no option is selected or ALL option is selected, select only ALL option
  if (
    (!newValue.length && oldValues.length <= 1) ||
    (newValue.length > 0 && newValue[0] === allOption.value)
  ) {
    newValues = [allOption.value];
  } else {
    // If non-ALL option is selected, unselect the ALL option
    for (const [idx, v] of newValues.entries()) {
      if (v === allOption.value) {
        newValues.splice(idx, 1);
        break;
      }
    }
  }

  return newValues;
};

/**
 * Ordering helpers
 */
// todo remove
const orderFunction = (a, b, asc = true, nullFirst = false) => {
  if (a === b) {
    return 0;
  }
  // Assume blank string and null to be smallest value - no value
  // unless overriden
  if (a === '' || a === null || typeof a === 'undefined') {
    return asc || nullFirst ? -1 : 1;
  }

  if (b === '' || b === null || typeof b === 'undefined') {
    return asc || nullFirst ? 1 : -1;
  }

  if (a < b) {
    return asc ? -1 : 1;
  }

  if (a > b) {
    return asc ? 1 : -1;
  }

  return 0;
};

// todo remove
const stringToLowerCase = val => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
};

export const orderAscBySelector = (selector, a, b) =>
  orderFunction(stringToLowerCase(selector(a)), stringToLowerCase(selector(b)), true);

export const orderDescBySelector = (selector, a, b) =>
  orderFunction(stringToLowerCase(selector(a)), stringToLowerCase(selector(b)), false);

export const orderDescWithNullFirstBySelector = (selector, a, b) =>
  orderFunction(stringToLowerCase(selector(a)), stringToLowerCase(selector(b)), false, true);

export const orderByGroupBy = (items, groupBy, compareFunction, groupByOrder = 'asc') => {
  const orderFunctions = {
    asc: orderAscBySelector.bind(this, item => item[groupBy]),
    desc: orderDescBySelector.bind(this, item => item[groupBy]),
    descWithNullFirst: orderDescWithNullFirstBySelector.bind(this, item => item[groupBy]),
  };

  const groupCompareFunction = orderFunctions[groupByOrder];

  if (compareFunction === undefined) {
    return [...items].sort(groupCompareFunction);
  }

  const sortedGroups = [...items]
    .sort(groupCompareFunction)
    .reduce(
      ({ groups, lastGroup, lastRow }, row, index, arr) => {
        if (lastRow === null) {
          // An obsolete code with missing tests, not worth the effort
          // eslint-disable-next-line no-param-reassign
          lastRow = row;
        }

        const isSameGroup = groupCompareFunction(lastRow, row) === 0;

        if (isSameGroup) {
          lastGroup.push(row);
        }

        if (!isSameGroup) {
          groups.push(lastGroup);
          // An obsolete code with missing tests, not worth the effort
          // eslint-disable-next-line no-param-reassign
          lastGroup = [row];
        }

        if (index === arr.length - 1) {
          groups.push(lastGroup);
        }

        // An obsolete code with missing tests, not worth the effort
        // eslint-disable-next-line no-param-reassign
        lastRow = row;
        return { groups, lastGroup, lastRow };
      },
      { groups: [], lastGroup: [], lastRow: null }
    )
    .groups.map(group => group.sort(compareFunction));

  return [].concat(...sortedGroups);
};

/**
 * Chart helpers
 */
export const getBarChartOptions = ({
  hideLegend = false,
  legendPosition = 'right',
  tooltipLabelCb,
  ticksX1Cb,
  ticksY1Cb,
  xCategoryPercentage = 1,
  xStacked,
  yStacked,
  xScaleLabel = '',
}) => ({
  legend: {
    onClick: false,
    display: !hideLegend,
    position: legendPosition,
  },
  tooltips: {
    callbacks: {
      title: tooltipItem => tooltipItem[0].xLabel,
      label:
        tooltipLabelCb ||
        ((tooltipItem, data) => {
          const dataSetLabel = data.datasets[tooltipItem.datasetIndex].label;
          return `${dataSetLabel ? `${dataSetLabel}: ` : ''}${tooltipItem.yLabel}`;
        }),
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
        categoryPercentage: xCategoryPercentage,
        barPercentage: 1,
        ticks: {
          beginAtZero: true,
          callback: ticksX1Cb || (value => value),
          autoSkip: false,
        },
        stacked: xStacked,
        scaleLabel: {
          display: !!xScaleLabel,
          labelString: xScaleLabel,
        },
      },
      {
        position: 'top',
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          callback: ticksY1Cb || (value => value),
        },
        scaleLabel: {
          display: false,
        },
        stacked: yStacked,
      },
      {
        position: 'right',
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
  },
});

export const getLineChartOptions = ({
  hideLegend = false,
  legendPosition = 'right',
  tooltipLabelCb,
}) => ({
  legend: {
    onClick: false,
    display: !hideLegend,
    position: legendPosition,
  },
  tooltips: {
    mode: 'nearest',
    callbacks: {
      title: tooltipItem => tooltipItem[0].xLabel,
      label:
        tooltipLabelCb ||
        ((tooltipItem, data) => {
          const dataSetLabel = data.datasets[tooltipItem.datasetIndex].label;
          return `${dataSetLabel ? `${dataSetLabel}: ` : ''}${tooltipItem.yLabel}`;
        }),
    },
  },
  elements: {
    line: {
      tension: 0,
      borderWidth: 2,
    },
    point: {
      radius: 1,
    },
  },
  hover: {
    mode: 'nearest',
  },
});

/**
 * Other Helpers
 */

const cloneObjectWithStringFormatter = (obj, strFormat) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const clone = {};
  Object.keys(obj).forEach(key => {
    const convertedKey = strFormat(key);
    const value = obj[key];
    if (value === null || typeof value !== 'object') {
      clone[convertedKey] = value;
    } else if (value instanceof Array) {
      clone[convertedKey] = Array.from(value, el => cloneObjectWithStringFormatter(el, strFormat));
    } else if (value instanceof Object) {
      clone[convertedKey] = cloneObjectWithStringFormatter(value, strFormat);
    }
  });
  return clone;
};

/**
 * Formats a string from camelCase to snake_case.
 */
// TODO Remove
export const camelToSnake = s =>
  s.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');

/**
 * Clones an object with snake cased prop names from an object with camel cased prop names.
 */
// TODO Remove
export const cloneObjectCamelToSnake = obj => cloneObjectWithStringFormatter(obj, camelToSnake);
