import { DatalabScreenViewTypes } from '../../constants';

export const getInitialSectionType = (
  defaultSection: DatalabScreenViewTypes,
  report: { isTableView: boolean } | undefined | null
) => {
  if (report) {
    return report.isTableView ? DatalabScreenViewTypes.TABLE : DatalabScreenViewTypes.CHART;
  }

  return defaultSection;
};
