import { cmgSectorScheme, ToastManager } from '@cmg/common';
import React from 'react';

import { useOfferingsFilterForm_SectorsQuery } from '../graphql/__generated__/OfferingsFilterForm_Sectors';
import { SectorOption, useCreateSectorOptions } from '../OfferingsFilterForm.model';

// add relevant SubSector children to Sector parents since this relation is currently
// not implemented in graphql (returns expected future graphql operation result)
// TBD: remove once the sector-subSector relation is available via graphql
const attachSubSectors = (data: ReturnType<typeof useOfferingsFilterForm_SectorsQuery>['data']) => {
  if (!data?.sectors?.items || !data?.subSectors?.items) {
    return data;
  }

  const subSectorValueToIdMap = data.subSectors.items.reduce((map, subSector) => {
    map[subSector.value] = subSector.id;
    return map;
  }, {});

  return {
    sectors: {
      items: data.sectors.items.map(item => ({
        ...item,
        children: (
          cmgSectorScheme.find(sector => sector.value === `${item.value}`)?.children ?? []
        ).map(subSector => ({
          id: subSectorValueToIdMap[subSector.value],
          value: subSector.value,
          displayName: subSector.title,
        })),
      })),
      totalCount: data.sectors?.totalCount,
    },
  };
};

export const useFetchSectors = ({ skip }: { skip?: boolean } = {}): SectorOption[] => {
  const {
    data: rawData,
    error,
    loading,
  } = useOfferingsFilterForm_SectorsQuery({ skip, fetchPolicy: 'cache-first' });
  const data = React.useMemo(() => attachSubSectors(rawData), [rawData]);
  const sectors = useCreateSectorOptions(data);
  const sectorOptions = React.useMemo(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching sectors');
    }
    if (!loading && data) {
      return sectors;
    }
    return [];
  }, [data, error, loading, sectors]);

  return sectorOptions;
};
