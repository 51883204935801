import { mixpanelUtil, ToastManager } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../../api/datalab-api';
import {
  createActionType,
  createApiActionCreators,
  createReducer,
  REQUEST,
  SUCCESS,
} from '../../../../common/redux/reduxHelpers';
import { toastMessages } from '../../../shared/constants/messages';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
const FETCH_OFFERING_NOTES = 'offering-notes/FETCH_OFFERING_NOTES';
const CREATE_OFFERING_NOTE = 'offering-notes/CREATE_OFFERING_NOTE';
const UPDATE_OFFERING_NOTE = 'offering-notes/UPDATE_OFFERING_NOTE';
const DELETE_OFFERING_NOTE = 'offering-notes/DELETE_OFFERING_NOTE';

/**
 * ACTIONS
 */
export const fetchOfferingNotesActions = createApiActionCreators<string, {}, {}>(
  FETCH_OFFERING_NOTES
);
export const createOfferingNoteActions = createApiActionCreators(CREATE_OFFERING_NOTE);
export const updateOfferingNoteActions = createApiActionCreators(UPDATE_OFFERING_NOTE);
export const deleteOfferingNoteActions = createApiActionCreators(DELETE_OFFERING_NOTE);

/**
 * REDUCERS
 */
const initialState = {
  notes: [],
};

const notes = createReducer(initialState.notes, {
  [FETCH_OFFERING_NOTES]: {
    [SUCCESS]: (state, payload) => payload.offeringNotes,
  },
  [CREATE_OFFERING_NOTE]: {
    [SUCCESS]: (state, payload) => payload.offeringNotes,
  },
  [UPDATE_OFFERING_NOTE]: {
    [SUCCESS]: (state, payload) => payload.offeringNotes,
  },
  [DELETE_OFFERING_NOTE]: {
    [SUCCESS]: (state, payload) => payload.offeringNotes,
  },
});

export default combineReducers({
  notes,
});

/**
 * SELECTORS
 */
export const selectOfferingNotes = state => state.offeringNotes;

export const selectNotes = state => selectOfferingNotes(state).notes;

/**
 * SAGAS
 */
function* fetchOfferingNotesSaga({
  payload,
}: ReturnType<typeof fetchOfferingNotesActions.request>): SagaIterator {
  yield put(createMixPanelAction(FETCH_OFFERING_NOTES, 'Offering Fetch Notes'));

  const resp = yield call(api.fetchOfferingNotes, payload);

  if (resp.ok) {
    yield put(fetchOfferingNotesActions.success(resp.data));
  } else {
    yield put(fetchOfferingNotesActions.failure(resp.error));
  }
}

function* createOfferingNoteSaga({ payload }: any): SagaIterator {
  const { offeringId, offeringNote } = payload;
  yield put(createMixPanelAction(CREATE_OFFERING_NOTE, 'Offering Create Note'));

  const resp = yield call(api.createOfferingNote, offeringId, offeringNote);

  if (resp.ok) {
    yield put(createOfferingNoteActions.success(resp.data));
    ToastManager.success(toastMessages.success.saved);
  } else {
    yield put(createOfferingNoteActions.failure(resp.error));
  }
}

function* updateOfferingNoteSaga({ payload }: any): SagaIterator {
  const { offeringId, noteId, offeringNote } = payload;
  yield put(createMixPanelAction(UPDATE_OFFERING_NOTE, 'Offering Update Note'));

  const resp = yield call(api.updateOfferingNote, offeringId, noteId, offeringNote);

  if (resp.ok) {
    yield put(updateOfferingNoteActions.success(resp.data));
    ToastManager.success(toastMessages.success.saved);
  } else {
    yield put(updateOfferingNoteActions.failure(resp.error));
  }
}

function* deleteOfferingNoteSaga({ payload }: any): SagaIterator {
  yield put(createMixPanelAction(DELETE_OFFERING_NOTE, 'Offering Delete Note'));

  const resp = yield call(api.deleteOfferingNote, payload.offeringId, payload.noteId);

  if (resp.ok) {
    yield put(deleteOfferingNoteActions.success(resp.data));
  } else {
    yield put(deleteOfferingNoteActions.failure(resp.error));
  }
}

export function* offeringNotesSaga() {
  yield takeLatest(createActionType(FETCH_OFFERING_NOTES, REQUEST), fetchOfferingNotesSaga);
  yield takeLatest(createActionType(CREATE_OFFERING_NOTE, REQUEST), createOfferingNoteSaga);
  yield takeLatest(createActionType(UPDATE_OFFERING_NOTE, REQUEST), updateOfferingNoteSaga);
  yield takeLatest(createActionType(DELETE_OFFERING_NOTE, REQUEST), deleteOfferingNoteSaga);
}
