import { numericUtil, Table } from '@cmg/common';
import React from 'react';

import {
  STableDataCategoryCell,
  STableDataCell,
  STableHeaderCategoryCell,
  STableHeaderCell,
  STableRow,
  StyledPopover,
} from './Heatmap.styles';

type OwnProps = {
  data: {
    items: any[];
    min_value: number;
    max_value: number;
  };
  columns: { name: string; label: string }[];
  valueKey: string;
  breakDownBy: {
    name: string;
    label: string;
  };
};

/**
 * Data visualization component that displays a matrix of
 * values using colors to highlight variances.
 * @param data - data representation of table rows and the min and max values within that dataset.
 * @param columns - data representation of table columns
 * @param valueKey - key within cell objects (data.items[x]) to reference for the value that is displayed and visualized within the table.
 * @param breakDownBy - an object that contains the label for the table (breakDown.label) and a key that serves as a reference to the row labels (breakdownBy.name).
 */
const HeatMap: React.FC<OwnProps> = ({ data, columns, valueKey, breakDownBy }) => (
  <Table>
    <thead>
      <tr>
        <STableHeaderCategoryCell>{breakDownBy.label}</STableHeaderCategoryCell>
        {columns.map(column => (
          <STableHeaderCell key={column.name}>{column.label}</STableHeaderCell>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.items.map((item, i) => (
        <STableRow key={`row-${i}`} bold={item[breakDownBy.name] === 'Total'}>
          <STableDataCategoryCell>{item[breakDownBy.name]}</STableDataCategoryCell>
          {columns.map(column => {
            const value = item[`${column.name}`];
            const count = value && value.count;
            const selectedValue = value && value[valueKey];

            return (
              <STableDataCell
                key={`col-${column.name}-${i}`}
                minValue={data.min_value}
                maxValue={data.max_value}
                selectedValue={selectedValue}
              >
                <StyledPopover
                  trigger="hover"
                  placement="left"
                  variant="TOOLTIP"
                  content={<span>Count: {count}</span>}
                >
                  <span>{count ? numericUtil.formatPercents(value[valueKey]) : '-'}</span>
                </StyledPopover>
              </STableDataCell>
            );
          })}
        </STableRow>
      ))}
    </tbody>
  </Table>
);

export default React.memo(HeatMap);
