import { Grid, Theme, Typography } from '@cmg/design-system';
import isNumber from 'lodash/isNumber';
import React from 'react';

export type SizeVariant = 'base' | 'small' | 'medium' | 'large' | 'xl' | number;
export type Props = {
  label: string | React.ReactNode;
  value: Readonly<string> | Readonly<React.ReactNode>;
  size?: Readonly<SizeVariant>;
  height?: Readonly<string> | ((theme: Theme) => string);
};
const labelWidth: { [size in SizeVariant]: (theme: Theme) => string } = {
  small: theme => theme.spacing(16.25),
  medium: theme => theme.spacing(21),
  base: theme => theme.spacing(23.125),
  large: theme => theme.spacing(27.5),
  xl: theme => theme.spacing(32.5),
};

const KeyValueItem: React.FC<Props> = ({ label, value, size = 'base', height, ...props }) => {
  return (
    <Grid
      container
      item
      alignItems="flex-start"
      flexWrap="nowrap"
      textTransform="none"
      paddingY={theme => theme.spacing(0.625)}
      gap={1}
      role="row"
      height={height}
      {...props}
    >
      <Typography
        variant="highlight1"
        color="text.secondary"
        minWidth={isNumber(size) ? size : labelWidth[size]}
        style={{ whiteSpace: 'pre-line' }}
      >
        {label}
      </Typography>
      {typeof value === 'string' ? <Typography>{value}</Typography> : value}
    </Grid>
  );
};

export default KeyValueItem;
