import { Icon, LinkButton } from '@cmg/common';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import logoImage from '../../../../assets/logo-dl.svg';
import logoSmallImage from '../../../../assets/logo-dl-small.svg';

// This could be moved to an ECM specific theme since it's used elsewhere in the app.
// We don't currently have the capability to supplement our common theme with SPA specific
// variables.
const appHeaderHeight = '88px';
export const sidebarExpandedWidth = '240px';
export const sidebarCollapsedWidth = '61px';

export const StyledLinkButton = styled(LinkButton)`
  border-radius: 0;

  div {
    flex: 1;
  }
`;

export const SItemIconWrapper = styled.span`
  width: 20px;
  text-align: center;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SItemLabel = styled.span`
  display: flex;
  flex: 1;
  padding-left: 5px;
  text-align: left;
`;

export const SWrapper = styled.div<{ collapsed: boolean }>`
  position: relative;
  width: ${sidebarExpandedWidth};
  min-width: ${sidebarExpandedWidth}; /* prevents being affected by a flexbox grid */
  overflow: visible;
  background-color: ${({ theme }) => theme.brand.color.dark};
  transition: all 0.15s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  ${({ collapsed }) =>
    collapsed &&
    css`
      width: ${sidebarCollapsedWidth};
      min-width: ${sidebarCollapsedWidth};
    `}
`;

export const StyledLinkLogo = styled(Link)`
  position: relative;
  background: ${({ theme }) => theme.brand.color.darkAlternate} url(${logoImage}) no-repeat center
    center;
  width: 100%;
  height: ${appHeaderHeight};
  display: block;
`;

export const StyledLinkLogoCollapsed = styled(StyledLinkLogo)`
  background-image: url(${logoSmallImage});
`;

export const SPullTabButton = styled.button`
  position: absolute;
  top: 22px;
  right: -21px;
  line-height: 44px;
  width: 21px;
  z-index: 6;
  background-color: ${({ theme }) => theme.brand.color.darkAlternate};
  cursor: pointer;
  color: ${({ theme }) => theme.text.color.light};
  border: 0;
  border-top-right-radius: ${({ theme }) => theme.border.radius.medium};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
  padding: 0 5px 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
`;

export const SMenuFilter = styled.div`
  padding: 10px 10px 15px 15px;
`;

export const SidebarMenu = styled.ul<{ collapsed?: boolean }>`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  height: calc(100vh - ${appHeaderHeight} - 90px);

  li {
    list-style: none;
    a {
      height: 30px;
    }

    button {
      height: 32px;
    }

    a,
    button {
      display: flex;
      align-items: baseline;
      padding: 5px 10px 5px 5px;
      color: ${({ theme }) => theme.color.gray1};
      border-left: 5px solid transparent;
      font-size: 14px;
      font-weight: ${({ theme }) => theme.text.weight.regular};
      width: 100%;
      text-align: left;

      .submenu-indicator {
        color: ${({ theme }) => theme.text.color.light};
      }

      &:focus,
      &:hover,
      &.active {
        background-color: inherit;
        color: ${({ theme }) => theme.color.gray1};
        text-decoration: none;
      }

      &:hover,
      &.active {
        color: ${({ theme }) => theme.interactive.primaryLight};
      }

      &:hover {
        ${SItemIconWrapper},
        .submenu-indicator {
          color: ${({ theme }) => theme.color.blue12};
        }
      }

      &.active {
        border-left-color: ${({ theme }) => theme.interactive.primaryLight};

        ${SItemIconWrapper} {
          color: ${({ theme }) => theme.interactive.primaryLight};
        }
      }
    }
  }

  .submenu {
    background: ${({ theme }) => theme.brand.color.darkAlternate};

    li {
      a,
      button {
        padding-left: 25px;
      }
    }
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      height: calc(100vh - ${appHeaderHeight} - 40px);

      li {
        &,
        .submenu {
          a,
          button {
            padding: 0;

            ${SItemIconWrapper} {
              flex: 1 0 100%;
              padding: 5px 0;
              align-self: center;
            }

            ${SItemLabel} {
              padding: 5px 0;
              text-align: center;
              white-space: nowrap;
            }
          }
        }
      }
    `}
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  color: ${({ theme }) => theme.color.gray3};

  ${props =>
    props.name === 'filter' &&
    css`
      top: 5px;
      left: 0;
    `}

  ${props =>
    props.name === 'times-circle' &&
    css`
      top: 7px;
      right: 0;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: ${({ theme }) => theme.color.blue12};
      }
    `}
`;

export const SidebarDisclaimer = styled.div<{ collapsed?: boolean }>`
  padding: 10px 10px;
  color: ${({ theme }) => theme.color.gray1};
  border-top: 1px solid;
  font-size: 14px;

  ${SItemLabel} {
    float: left;
    padding-left: 25px;
  }

  ${StyledIcon} {
    padding: 3px 5px;
    color: ${({ theme }) => theme.color.gray1};
  }

  &:hover {
    ${SItemLabel} {
      text-decoration: underline;
    }
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      ${StyledIcon} {
        padding: 0 12px;
      }
    `}
`;
