import { Column, Row } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { FollowOnPricingSummary as FollowOnPricingSummaryType } from '../../../../../types/domain/my-dashboard/followOnPricingSummary';
import CurrencyValue from '../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../common/dashboard-value/numeric-value/NumericValue';
import PerformanceMetricValue from '../../../common/dashboard-value/performance-metric-value/PerformanceMetricValue';
import PerformancePercentsValue from '../../../common/dashboard-value/performance-percents-value/PerformancePercentsValue';
import HorizontalLine from '../../../common/horizontal-line/HorizontalLine';
import { SectionHeader } from '../../common/DashboardPeriod';
import FollowOnPricingDetail from './FollowOnPricingDetail';

const StyledFollowOns = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledColumn = styled(Column)`
  margin: 5px 0;
`;

export type Props = {
  data?: FollowOnPricingSummaryType;
};

const FollowOnPricingSummary: React.FC<Props> = ({ data }) => {
  return (
    <div data-test-id={myDashboard.followOnSummarySection.testId}>
      <StyledFollowOns>
        <StyledColumn sm={6}>
          <SectionHeader>Follow-Ons</SectionHeader>
        </StyledColumn>
        <StyledColumn sm={4}>
          <NumericValue value={data?.numberOfOfferings} label="# Offerings" size="mediumLarge" />
        </StyledColumn>
        <StyledColumn sm={4}>
          <CurrencyValue value={data?.capitalRaised} label="Capital Raised" size="mediumLarge" />
        </StyledColumn>
        <StyledColumn sm={4}>
          <PerformancePercentsValue
            value={data?.fileToOffer}
            label="File To Offer"
            size="mediumLarge"
          />
        </StyledColumn>
        <StyledColumn sm={4}>
          <PerformanceMetricValue value={data?.performance} size="mediumLarge" />
        </StyledColumn>
      </StyledFollowOns>
      <HorizontalLine size="medium" />
      <FollowOnPricingDetail label="Marketed FOs" data={data?.marketedFos} />
      <FollowOnPricingDetail label="Overnight FOs" data={data?.overnightFos} />
      <FollowOnPricingDetail label="Registered Blocks" data={data?.registeredBlocks} />
      <FollowOnPricingDetail label="Unregistered Blocks" data={data?.unregisteredBlocks} />
    </div>
  );
};

export default FollowOnPricingSummary;
