import Chart, { Plugin } from 'chart.js/auto';

/**
 * Use this when you want to display a bar chart with a fixed width for each bar and centered within the category.
 * The default behavior for chartjs with fixed width bars is to evenly space them across the category, which is not desirable.
 */
export const CMGCenteredBarPlugin: Plugin = {
  id: 'CMGCenteredBarPlugin',
  beforeUpdate: function (chartArg) {
    // this plugin only operates on pure bar charts (does not support other chart types, does not support bars in mixed charts)
    if ('type' in chartArg.config && chartArg.config.type === 'bar') {
      const chart = chartArg as Chart<'bar'>;
      const chartWidth = chart.width;
      const maxPixelWidth = 12;

      const categoryCount = chart.data.labels?.length || chart.data.datasets[0]?.data.length || 1;
      const categoryWidth = chartWidth / categoryCount;
      const barCountPerCategory = chart.data.datasets.length;

      // calculate bar width based on number of bars, not exceeding maxPixelWidth
      const barWidthProposed = categoryWidth / barCountPerCategory;
      const barWidth = Math.min(barWidthProposed, maxPixelWidth);
      const barTotalWidthPerCategory = barCountPerCategory * (barWidth + 5); // 5 is the space between bars

      // category percentage should be just enough to fit all bars
      const categoryPercentage = Math.min(barTotalWidthPerCategory, categoryWidth) / categoryWidth;

      // bars try to take up as much space as possible within the constrained category width
      chart.data.datasets.forEach(dataset => {
        dataset.maxBarThickness = barWidth;
        dataset.barPercentage = 1;
        dataset.categoryPercentage = categoryPercentage;
      });
    }
  },
};
