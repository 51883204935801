import { Checkbox } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

const SWrapper = styled.div`
  text-align: center;
  margin-top: 5px;
  line-height: 20px;
`;

type Props = {
  value?: boolean;
  setValue?: (value: boolean) => void;
};

const CheckboxRenderer: React.FC<Props> = ({ value, setValue }) => (
  <SWrapper>
    <Checkbox onChange={setValue} value={value} />
  </SWrapper>
);

export default CheckboxRenderer;
