import { NotificationDetail, NotificationGroupDetail } from './notification';

export enum NotificationActionType {
  NEW_REPORT_SHARED = 'NEW_REPORT_SHARED',
  NEW_IOI_ADDED = 'NEW_IOI_ADDED',
  NEW_OFFERING_NOTE_SHARED = 'NEW_OFFERING_NOTE_SHARED',
  FINAL_ALLOCATION_ENTERED = 'FINAL_ALLOCATION_ENTERED',
  GENERIC = 'GENERIC',
}

export enum NotificationGroup {
  REPORT = 'REPORT',
  NOTE = 'NOTE',
  IOI = 'IOI',
  ALLOCATION = 'ALLOCATION',
}

export enum NotificationResourceType {
  OFFERING = 'OFFERING',
  IOI = 'IOI',
  SHARED_REPORT = 'SHARED_REPORT',
}

/*
 * groups have name + icon and actionTypes
 */
export const notificationGroups: { [key in NotificationGroup]: NotificationGroupDetail } = {
  [NotificationGroup.REPORT]: {
    name: 'REPORT',
    icon: {
      name: 'file-chart-line',
    },
  },
  [NotificationGroup.NOTE]: {
    name: 'NOTE',
    icon: {
      name: 'sticky-note',
    },
  },
  [NotificationGroup.IOI]: {
    name: 'ORDER',
    icon: {
      name: 'file-invoice-dollar',
    },
  },
  [NotificationGroup.ALLOCATION]: {
    name: 'ALLOCATION',
    icon: {
      name: 'chart-network',
    },
  },
};

/*
 * mapping for filter tree as well as messaging
 */
export const notificationDisplay: { [key in NotificationActionType]: NotificationDetail } = {
  [NotificationActionType.NEW_REPORT_SHARED]: {
    group: notificationGroups[NotificationGroup.REPORT],
    message: 'New Report Shared',
  },
  [NotificationActionType.NEW_IOI_ADDED]: {
    group: notificationGroups[NotificationGroup.IOI],
    message: 'IOI Entered',
  },
  [NotificationActionType.NEW_OFFERING_NOTE_SHARED]: {
    group: notificationGroups[NotificationGroup.NOTE],
    message: 'Offering Note Submitted',
  },
  [NotificationActionType.FINAL_ALLOCATION_ENTERED]: {
    group: notificationGroups[NotificationGroup.ALLOCATION],
    message: 'Final Allocation Entered',
  },
  [NotificationActionType.GENERIC]: {
    group: notificationGroups[NotificationGroup.REPORT],
    message: '',
  },
};
