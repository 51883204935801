import { AccessControl, permissionsByEntity } from '@cmg/auth';
import React from 'react';
import styled from 'styled-components/macro';

import { SectorToggleField } from './SectorToggle';

const StyledSectorToggleField = styled(SectorToggleField)`
  color: ${({ theme }) => theme.interactive.primary};
  margin-bottom: 0;
  font-size: 110%;
`;

type Props = {
  onChange?: () => void;
  testId?: string;
};

/**
 * styled privileged toggler field is used in forms as a label above treeSelects for sectors
 */
export const SectorToggleLabel: React.FC<Props> = ({ testId, onChange }) => (
  <AccessControl
    requiredPermissions={[permissionsByEntity.CustomSectors.READ]}
    renderNoAccess={() => <React.Fragment>Sectors</React.Fragment>}
  >
    <StyledSectorToggleField
      name="useCustomSectors"
      testId={testId}
      onChange={() => onChange && onChange()}
    />
  </AccessControl>
);

export default SectorToggleLabel;
