// As we move towards either GraphQL or .NET REST, the types/enums in this file should be pulled in from
//  those auto generated sources.

import {
  DLGWOfferingType as OfferingType,
  DLGWPricingType as PricingType,
  DLGWUnitType as UnitType,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import { currencyCodes, exchangeMicLabels } from '@cmg/common';

import { OfferingType as OfferingTypeGraphql } from '../../../graphql/__generated__';

export { OfferingType, PricingType, UnitType };

// Not coming directly from DLGW because DLGW includes 'Draft'. Need to test ramifications of including draft here.
export enum OfferingStatus {
  FILED = 'FILED',
  LIVE = 'LIVE',
  PRICED = 'PRICED',
  POSTPONED = 'POSTPONED',
  WITHDRAWN = 'WITHDRAWN',
  TERMINATED = 'TERMINATED',
}

type OfferingTypeDisplay =
  | 'IPO'
  | 'Follow On'
  | 'Marketed FO'
  | 'Overnight FO'
  | 'Registered Block'
  | 'Unregistered Block'
  | 'IPO (SPACs)'
  | 'Rights'
  | 'Convertible'
  | 'Registered Direct'
  | 'Direct Listing'
  | 'ATM'
  | 'ABB/ABO'
  | 'Block'
  | 'Fully Marketed Offering';

export const offeringTypeLabels: Record<OfferingType | OfferingTypeGraphql, OfferingTypeDisplay> = {
  [OfferingType.IPO]: 'IPO',
  [OfferingType.FO]: 'Follow On',
  [OfferingTypeGraphql.FollowOn]: 'Follow On',
  [OfferingType.MARKETED_FO]: 'Marketed FO',
  [OfferingType.OVERNIGHT_FO]: 'Overnight FO',
  [OfferingType.REGISTERED_BLOCK]: 'Registered Block',
  [OfferingType.UNREGISTERED_BLOCK]: 'Unregistered Block',
  [OfferingType.RIGHTS]: 'Rights',
  [OfferingType.CONVERTIBLE]: 'Convertible',
  [OfferingType.REGISTERED_DIRECT]: 'Registered Direct',
  [OfferingType.DIRECT_LISTING]: 'Direct Listing',
  [OfferingType.ATM]: 'ATM',
};

export const InternationalOfferingTypeLabels: Record<
  OfferingType | OfferingTypeGraphql,
  OfferingTypeDisplay
> = {
  ...offeringTypeLabels,
  [OfferingType.MARKETED_FO]: 'Fully Marketed Offering',
  [OfferingType.OVERNIGHT_FO]: 'ABB/ABO',
  [OfferingType.REGISTERED_BLOCK]: 'Block',
  [OfferingType.UNREGISTERED_BLOCK]: 'Block',
};

export enum OfferingExchange {
  BATS = 'BATS',
  NASDAQ = 'NASDAQ',
  NASDAQ_CM = 'NASDAQ_CM',
  NASDAQ_GM = 'NASDAQ_GM',
  NASDAQ_GS = 'NASDAQ_GS',
  NYSE = 'NYSE',
  NYSE_INC = 'NYSE_INC',
  NYSE_MKT = 'NYSE_MKT',
  TORONTO_SE = 'TORONTO_SE',
  TSX = 'TSX',
}

export enum OfferingEgc {
  NOT_INDICATED = 'NOT_INDICATED',
  NO = 'NO',
  YES = 'YES',
}

export enum Sector {
  BIZ_SVCS = 'BIZ_SVCS',
  CONSUMER = 'CONSUMER',
  ENERGY = 'ENERGY',
  ENERGY_TECH = 'ENERGY_TECH',
  FIN_SVCS = 'FIN_SVCS',
  HEALTH = 'HEALTH',
  INDUSTRIAL = 'INDUSTRIAL',
  REAL_ESTATE = 'REAL_ESTATE',
  TECHNOLOGY = 'TECHNOLOGY',
  OTHER = 'OTHER',
}

export enum OfferingTiming {
  PRE_OPEN = 'PRE_OPEN',
  MID_DAY = 'MID_DAY',
  POST_CLOSE = 'POST_CLOSE',
}

export enum FirmManagementStatus {
  NONE = 'NONE',
  MANAGED = 'MANAGED',
  UNMANAGED = 'UNMANAGED',
  UNCONFIRMED = 'UNCONFIRMED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ManagerRole {
  ACTIVE_BOOKRUNNER = 'ACTIVE_BOOKRUNNER',
  BOOKRUNNER = 'BOOKRUNNER', // only in DL
  CO_LEAD = 'CO_LEAD',
  CO_MANAGER = 'CO_MANAGER',
  UNDERWRITER = 'UNDERWRITER',
  SELLING_GROUP_MEMBER = 'SELLING_GROUP_MEMBER',
  GLOBAL_COORDINATOR = 'GLOBAL_COORDINATOR',
}

export const managerRoleLabels = {
  [ManagerRole.ACTIVE_BOOKRUNNER]: 'Active Bookrunner',
  [ManagerRole.BOOKRUNNER]: 'Bookrunner',
  [ManagerRole.CO_LEAD]: 'Co-Lead Manager',
  [ManagerRole.CO_MANAGER]: 'Co-Manager',
  [ManagerRole.GLOBAL_COORDINATOR]: 'Global Coordinator',
  [ManagerRole.SELLING_GROUP_MEMBER]: 'Selling Group Member',
  [ManagerRole.UNDERWRITER]: 'Underwriter',
};

export enum BookAccess {
  NO_ACCESS = 'NO_ACCESS',
  READ_ONLY = 'READ_ONLY',
  FULL_ACCESS = 'FULL_ACCESS',
}

export enum InviteStatus {
  NOT_INVITED = 'NOT_INVITED',
  UNSENT = 'UNSENT',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export enum AdvisorRole {
  ISSUER_COUNSEL = 'ISSUER_COUNSEL',
  ISSUER_AUDITOR = 'ISSUER_AUDITOR',
  UNDERWRITER_COUNSEL = 'UNDERWRITER_COUNSEL',
  ADVISORY = 'ADVISORY',
}

export const advisorRoleLabels = {
  [AdvisorRole.ISSUER_COUNSEL]: 'Issuer Counsel',
  [AdvisorRole.ISSUER_AUDITOR]: 'Issuer Auditor',
  [AdvisorRole.UNDERWRITER_COUNSEL]: 'Underwriter Counsel',
  [AdvisorRole.ADVISORY]: 'Advisory',
};

export enum FilingType {
  FILED = 'FILED',
  REVISED = 'REVISED',
  PRICED = 'PRICED',
}

export enum UseOfProceeds {
  ACQUISITIONS = 'ACQUISITIONS',
  CAPEX = 'CAPEX',
  DEBT_REPAYMENT = 'DEBT_REPAYMENT',
  GENERAL_CORPORATE_PURPOSES = 'GENERAL_CORPORATE_PURPOSES',
  RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  GROWTH_CAPITAL = 'GROWTH_CAPITAL',
  SHAREHOLDER_DIVIDENDS = 'SHAREHOLDER_DIVIDENDS',
  NO_PROCEEDS_TO_ISSUER = 'NO_PROCEEDS_TO_ISSUER',
  FUTURE_ACQUISITIONS = 'FUTURE_ACQUISITIONS',
  INVESTMENTS = 'INVESTMENTS',
  REPURCHASE_SHARES = 'REPURCHASE_SHARES',
}

export enum OfferingSecurityType {
  COMMON_ORDINARY = 'COMMON_ORDINARY',
  UNIT = 'UNIT',
  COMMON_WARRANTS = 'COMMON_WARRANTS',
  GDR_ADR_ADS = 'GDR_ADR_ADS',
}

export const offeringSecurityTypeLabels = {
  [OfferingSecurityType.COMMON_ORDINARY]: 'Common/Ordinary',
  [OfferingSecurityType.UNIT]: 'LP Units',
  [OfferingSecurityType.COMMON_WARRANTS]: 'Common+Warrants',
  [OfferingSecurityType.GDR_ADR_ADS]: 'ADR/GDR',
};

export enum OfferingPriceVsRange {
  BELOW = 'BELOW',
  ABOVE = 'ABOVE',
  WITHIN = 'WITHIN',
}

export enum SpacFilterOptions {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
  ONLY = 'only',
}

export const offeringPriceVsRangeLabels = {
  [OfferingPriceVsRange.BELOW]: 'Below',
  [OfferingPriceVsRange.ABOVE]: 'Above',
  [OfferingPriceVsRange.WITHIN]: 'In Range',
};

export enum TimingOfLaunch {
  PRE_OPEN = 'PRE_OPEN',
  POST_CLOSE = 'POST_CLOSE',
  INTRA_DAY = 'INTRA_DAY',
}

export const timingOfLaunchLabels = {
  [TimingOfLaunch.PRE_OPEN]: 'Pre Market',
  [TimingOfLaunch.POST_CLOSE]: 'Post Market',
  [TimingOfLaunch.INTRA_DAY]: 'Intra-Day',
};

export enum IpoRangeRevisionType {
  NO_CHANGE = 'NO_CHANGE',
  INCREASED = 'INCREASED',
  DECREASED = 'DECREASED',
}

export const ipoRangeRevisionTypeLabels = {
  [IpoRangeRevisionType.NO_CHANGE]: 'No Change',
  [IpoRangeRevisionType.INCREASED]: 'Increased',
  [IpoRangeRevisionType.DECREASED]: 'Decreased',
};

export const filingTypeLabels = {
  [FilingType.FILED]: 'Filed',
  [FilingType.REVISED]: 'Revised',
  [FilingType.PRICED]: 'Priced',
};

export enum IoiLimitType {
  PRICE = 'price',
  DISCOUNT = 'discount',
  PREMIUM = 'premium',
}

export const exchangeMicEnumFilterOptions = Object.keys(exchangeMicLabels).map(key => ({
  label: exchangeMicLabels[key].displayName,
  value: key,
}));

export const currencyEnumFilterOptions = Object.keys(currencyCodes).map(key => ({
  label: currencyCodes[key].currencyName,
  value: currencyCodes[key].alphabeticCode,
}));

export enum CornerstoneInvestorType {
  OFFERING_PARTICIPANT = 'OFFERING_PARTICIPANT',
  PRIVATE_PLACEMENT = 'PRIVATE_PLACEMENT',
}

export const CornerstoneInvestorTypeLabels = {
  [CornerstoneInvestorType.OFFERING_PARTICIPANT]: 'Offering Participants',
  [CornerstoneInvestorType.PRIVATE_PLACEMENT]: 'Private Placement',
};

export enum ShareholderType {
  SPONSOR_TYPE_CORPORATE = 'SPONSOR_TYPE_CORPORATE',
  SPONSOR_TYPE_INSIDER = 'SPONSOR_TYPE_INSIDER',
  SPONSOR_TYPE_NON_INSIDER = 'SPONSOR_TYPE_NON_INSIDER',
  SPONSOR_TYPE_PRIVATE_EQUITY = 'SPONSOR_TYPE_PRIVATE_EQUITY',
  SPONSOR_TYPE_VENTURE_CAPITAL = 'SPONSOR_TYPE_VENTURE_CAPITAL',
  SPONSOR_TYPE_OTHER_INSTITUTIONAL = 'SPONSOR_TYPE_OTHER_INSTITUTIONAL',
}

export const CornerstoneShareholderTypeLabels = {
  [ShareholderType.SPONSOR_TYPE_CORPORATE]: 'Corporate',
  [ShareholderType.SPONSOR_TYPE_INSIDER]: 'Insider',
  [ShareholderType.SPONSOR_TYPE_NON_INSIDER]: 'Non Insider',
  [ShareholderType.SPONSOR_TYPE_PRIVATE_EQUITY]: 'PE',
  [ShareholderType.SPONSOR_TYPE_VENTURE_CAPITAL]: 'VC',
  [ShareholderType.SPONSOR_TYPE_OTHER_INSTITUTIONAL]: 'Other Inst.',
};
