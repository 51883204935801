import { Box } from '@cmg/common';
import React, { useState } from 'react';

import Toggler from '../../../../shared/layout/components/Toggler';
import { heatmapPerformanceColumns } from '../../../constants-heatmap-columns';
import heatMapPerformanceButtons from '../../../model/buttons/heat-map-performance-buttons';
import HeatMap from '../../HeatMap';
import { SButtonBox } from '../shared/styled';

type Props = {
  // TODO - find out what this data structure is by looking at requests in the network tab
  chartData: any;
  breakDownBy: string;
  breakDownLabel: string;
  testId?: string;
  title?: string;
};

const WidgetPerformanceHeatMap: React.FC<Props> = ({
  chartData,
  breakDownBy,
  breakDownLabel,
  testId,
  title,
}) => {
  const [heatMapPerformanceBtn, setHeatMapPerformanceBtn] = useState(heatMapPerformanceButtons[0]);

  const handlePerformanceBtnChange = (btn: any) => {
    setHeatMapPerformanceBtn(btn);
  };

  return (
    <div data-test-id={testId}>
      <Box.Header title={title || 'Aftermarket Performance Heatmap'} />

      <SButtonBox>
        <Toggler
          buttons={heatMapPerformanceButtons}
          activeButton={heatMapPerformanceBtn}
          onChange={handlePerformanceBtnChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <HeatMap
          columns={heatmapPerformanceColumns}
          data={chartData}
          valueKey={heatMapPerformanceBtn.value}
          breakDownBy={{
            label: breakDownLabel,
            name: breakDownBy,
          }}
        />
      </Box.Content>
    </div>
  );
};

export default WidgetPerformanceHeatMap;
