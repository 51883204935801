import { Box, Column, numericUtil } from '@cmg/common';
import React from 'react';

import PerformancePercents from '../../../../../common/components/format/performance-percents/PerformancePercents';
import ValuesGrid from '../../../../shared/components/ValuesGrid';
import {
  SLabel,
  SLabels,
  SPerformanceCol,
  SSlash,
  STitle,
  StyledValuesGrid,
  SWidgetOfferingPerformance,
} from './WidgetOfferingPerformance.styles';

const { Label, Value } = ValuesGrid;

type Props = {
  ipo: {
    below: number;
    within: number;
    above: number;
    day1: number;
    day30: number;
    current: number;
  };
  followOn: {
    file_to_offer: number;
    to_last_trade: number;
    day1: number;
    day30: number;
    current: number;
  };
  info: {
    ipo: {
      quantity: number;
      price: number;
    };
    followon: {
      quantity: number;
      price: number;
    };
  };
};

const WidgetOfferingPerformance: React.FC<Props> = ({ ipo, followOn, info }) => {
  return (
    <SWidgetOfferingPerformance>
      <SPerformanceCol>
        <STitle>IPOs</STitle>
        <SLabels>
          <div>
            {numericUtil.formatInteger(info.ipo.quantity)}
            <SLabel>QTY.</SLabel>
          </div>
          <SSlash>/</SSlash>
          <div>
            {numericUtil.formatCurrencyInBillions(info.ipo.price)}
            <SLabel>Dollars</SLabel>
          </div>
        </SLabels>
        <Box.Content>
          <StyledValuesGrid>
            <ValuesGrid.Row>
              <Column className="item-cell" xs={8}>
                <Value>{numericUtil.formatPercents(ipo.below, 1)}</Value>
                <Label>Below</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>{numericUtil.formatPercents(ipo.within, 1)}</Value>
                <Label>Within</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>{numericUtil.formatPercents(ipo.above, 1)}</Value>
                <Label>Above</Label>
              </Column>
            </ValuesGrid.Row>

            <ValuesGrid.Row>
              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={ipo.day1} />
                </Value>
                <Label>1 Day</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={ipo.day30} />
                </Value>
                <Label>30 Day</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={ipo.current} />
                </Value>
                <Label>Current</Label>
              </Column>
            </ValuesGrid.Row>
          </StyledValuesGrid>
        </Box.Content>
      </SPerformanceCol>

      <SPerformanceCol>
        <STitle>Follow-ons</STitle>

        <SLabels>
          <div>
            {numericUtil.formatInteger(info.followon.quantity)}
            <SLabel>QTY.</SLabel>
          </div>
          <SSlash>/</SSlash>
          <div>
            {numericUtil.formatCurrencyInBillions(info.followon.price)}
            <SLabel>Dollars</SLabel>
          </div>
        </SLabels>
        <Box.Content>
          <StyledValuesGrid>
            <ValuesGrid.Row>
              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={followOn.file_to_offer} />
                </Value>
                <Label>File to Offer</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={followOn.to_last_trade} />
                </Value>
                <Label>To Last Trade</Label>
              </Column>
            </ValuesGrid.Row>

            <ValuesGrid.Row>
              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={followOn.day1} />
                </Value>
                <Label>1 Day</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={followOn.day30} />
                </Value>
                <Label>30 Day</Label>
              </Column>

              <Column className="item-cell" xs={8}>
                <Value>
                  <PerformancePercents value={followOn.current} />
                </Value>
                <Label>Current</Label>
              </Column>
            </ValuesGrid.Row>
          </StyledValuesGrid>
        </Box.Content>
      </SPerformanceCol>
    </SWidgetOfferingPerformance>
  );
};

export default WidgetOfferingPerformance;
