import { saveAs } from 'file-saver';

export default function downloadIoi(ioiOfferingId: string, accessToken: string | null) {
  const uri = `/dl/api/v1/iois/${ioiOfferingId}.jpg`;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return fetch(uri, {
    headers: accessToken ? headers : {},
  })
    .then(response => response.blob())
    .then(blob => {
      saveAs(blob, `${ioiOfferingId}.jpg`);
    });
}
