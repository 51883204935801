import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum UnderwrittenValueAmountButtons {
  AMOUNT = 'amount',
  UNDERWRITTEN_VALUE = 'underwritten_value',
  VALUE = 'value',
}

export const underwrittenValueAmountButtonsConfig: ButtonsConfig = {
  [UnderwrittenValueAmountButtons.VALUE]: { label: 'VOLUME ($)' },
  [UnderwrittenValueAmountButtons.UNDERWRITTEN_VALUE]: { label: 'Underwritten ($)' },
  [UnderwrittenValueAmountButtons.AMOUNT]: { label: 'VOLUME (#)' },
};

export default getButtonConfigArray(underwrittenValueAmountButtonsConfig);
