import { ColDef, Icon, selectColumn } from '@cmg/common';
import { Column } from 'ag-grid-community';
import merge from 'lodash/merge';
import { useState } from 'react';

import type {
  CustomSectorIssuer,
  IssuerFilterInput,
  IssuerSortInput,
} from '../../../../graphql/__generated__';
import type { PaginationQueryParams } from '../../../../types/api/pagination';
import useSectorsLookup from '../../../shared/sectors/useSectorsLookup';
import { ActionCell } from './CompaniesGrid.styles';
import type { FilterValues } from './components/CompaniesFilters';
import { CompaniesGrid_IssuerPartsFragment } from './graphql/__generated__/CompaniesGrid_Issuers';

export type customSectorNameField = `${keyof Pick<
  CompaniesGrid_IssuerPartsFragment,
  'customSectors'
>}.${keyof Pick<CustomSectorIssuer, 'customSector'>}.${keyof Pick<
  NonNullable<CustomSectorIssuer['customSector']>,
  'name'
>}`;

export type customSubSectorNameField = `${keyof Pick<
  CompaniesGrid_IssuerPartsFragment,
  'customSectors'
>}.${keyof Pick<CustomSectorIssuer, 'customSubSector'>}.${keyof Pick<
  NonNullable<CustomSectorIssuer['customSubSector']>,
  'name'
>}`;

export type ColumnKey =
  | keyof Omit<CompaniesGrid_IssuerPartsFragment, '__typename' | 'id' | 'customSectors'>
  | customSectorNameField
  | customSubSectorNameField;

export type ColumnDef = Omit<ColDef, 'field'> & { field: ColumnKey | 'checkbox' };

export const getColumns = ({
  canWriteCustomSectors,
  handleUpdateIds,
}: {
  canWriteCustomSectors: boolean;
  handleUpdateIds: (issuers: CompaniesGrid_IssuerPartsFragment[]) => void;
}): ColumnDef[] => [
  { ...selectColumn, field: 'checkbox', hide: !canWriteCustomSectors },
  { field: 'name', headerName: 'Issuer' },
  { field: 'primarySymbol', headerName: 'Ticker' },
  {
    field: 'sectorDisplayName',
    headerName: 'Sector',
  },
  {
    field: 'subSectorDisplayName',
    headerName: 'Sub Sector',
  },
  {
    field: 'customSectors.customSector.name',
    headerName: 'My Sector',
    hide: !canWriteCustomSectors,
    cellRendererFramework: ({ data: issuer }) => (
      <ActionCell onClick={() => handleUpdateIds([issuer])}>
        {issuer.customSectors?.customSector ? (
          issuer.customSectors.customSector.name
        ) : (
          <Icon name="plus" size="sm" />
        )}
      </ActionCell>
    ),
  },
  {
    field: 'customSectors.customSubSector.name',
    headerName: 'My Sub Sector',
    hide: !canWriteCustomSectors,
    cellRendererFramework: ({ data: issuer }) => (
      <ActionCell onClick={() => handleUpdateIds([issuer])}>
        {issuer.customSectors?.customSubSector ? (
          issuer.customSectors.customSubSector.name
        ) : (
          <Icon name="plus" size="sm" />
        )}
      </ActionCell>
    ),
  },
];

export function useIssuerListQueryFilter() {
  const [issuerListQueryFilter, setIssuerListQueryFilter] = useState<IssuerFilterInput>();

  const { sectors, subSectors } = useSectorsLookup();

  return {
    issuerListQueryFilter,
    setFilterValues: (filterValues: FilterValues) => {
      setIssuerListQueryFilter(getIssuerListQueryFilter(filterValues, { sectors, subSectors }));
    },
  };
}

export function getIssuerListQueryFilter(
  filterValues: FilterValues,
  { sectors, subSectors }: ReturnType<typeof useSectorsLookup>
): IssuerFilterInput | undefined {
  const filters: IssuerFilterInput[] = [];

  if (filterValues.searchTerm) {
    filters.push({
      or: [
        { name: { like: filterValues.searchTerm } },
        { primarySymbol: { startsWith: filterValues.searchTerm.toLocaleUpperCase() } },
      ],
    });
  }

  if (filterValues.sectorValue) {
    const sectorId = sectors.find(({ value }) => `${value}` === filterValues.sectorValue)?.id;
    if (sectorId) {
      filters.push({ sector: { eq: sectorId } });
    }
  }
  if (filterValues.subSectorValue) {
    const subSectorId = subSectors.find(
      ({ value }) => `${value}` === filterValues.subSectorValue
    )?.id;
    if (subSectorId) {
      filters.push({ subSector: { eq: subSectorId } });
    }
  }

  if (filterValues.customSectorId) {
    filters.push({ customSectors: { customSectorId: { eq: filterValues.customSectorId } } });
  }
  if (filterValues.customSubSectorId) {
    // note camelCase inconsistency of "customSubsectorId" comes from the backend
    filters.push({ customSectors: { customSubsectorId: { eq: filterValues.customSubSectorId } } });
  }

  return filters.length ? { and: filters } : undefined;
}

const stringOrderFields: ColumnKey[] = ['name', 'primarySymbol'];

export function getIssuerListQuerySort({
  orderField,
  orderDirection,
}: Pick<PaginationQueryParams, 'orderField' | 'orderDirection'>): IssuerSortInput | undefined {
  if (!orderField || !orderDirection) {
    return undefined;
  }

  const direction = orderDirection.toUpperCase();

  if (stringOrderFields.includes(orderField as ColumnKey)) {
    return { [orderField]: direction };
  }

  if (orderField.endsWith('DisplayName')) {
    return { [orderField.replace('DisplayName', '')]: { displayName: direction } };
  }

  if (orderField.startsWith('customSectors')) {
    return orderField
      .split('.')
      .reverse()
      .reduce((sort, key, index) => ({ [key]: index === 0 ? direction : sort }), {});
  }

  return undefined;
}

export const defaultDownloadColumnOptions = {
  name: {
    columnName: 'Company',
    numberPrecision: null,
    displayOrder: 0,
  },
  primarySymbol: {
    columnName: 'Ticker',
    numberPrecision: null,
    displayOrder: 1,
  },
  sectorDisplayName: {
    columnName: 'Sector',
    numberPrecision: null,
    displayOrder: 2,
  },
  subSectorDisplayName: {
    columnName: 'Sub sector',
    numberPrecision: null,
    displayOrder: 3,
  },
  'customSectors.customSector.name': {
    columnName: 'Custom sector',
    numberPrecision: null,
    displayOrder: 4,
  },
  'customSectors.customSubSector.name': {
    columnName: 'Custom subsector',
    numberPrecision: null,
    displayOrder: 5,
  },
};

type DownloadColumnOptions = {
  [key: string]: { columnName: string; numberPrecision?: number | null; displayOrder: number };
};

export const defaultDownloadFieldSelection =
  'name primarySymbol sectorDisplayName subSectorDisplayName customSectors { customSector { name } customSubSector { name } }';

type SelectionFields = { [key: string]: SelectionFields | null };

function flattenSelectionFields(selectionFields: SelectionFields): SelectionFields | string {
  return Object.entries(selectionFields)
    .map(([key, children]) => {
      return key + (!children ? '' : ` { ${flattenSelectionFields(children)} }`);
    })
    .join(' ');
}

export function getDownloadArgs({
  displayedColumns,
  filter,
  sort,
}: {
  displayedColumns: Column[] | undefined;
  filter: IssuerFilterInput | undefined;
  sort: IssuerSortInput | undefined;
}) {
  const columnOptions: DownloadColumnOptions = {};
  let selectionFields: SelectionFields = {};

  displayedColumns?.forEach(column => {
    const colId = column.getId();
    if (defaultDownloadColumnOptions[colId]) {
      columnOptions[colId] = {
        ...defaultDownloadColumnOptions[colId],
        displayOrder: Object.keys(columnOptions).length,
      };

      const fieldTree = colId
        .split('.')
        .reverse()
        .reduce((tree, key, index) => ({ [key]: index === 0 ? null : tree }), {});

      selectionFields = merge(selectionFields, fieldTree);
    }
  });

  return {
    selection: flattenSelectionFields(selectionFields) as string,
    columnOptions,
    arguments: {
      where: filter || {},
      order: sort ? [sort] : [],
    },
  };
}
