export const NO_SECTOR_LABEL = 'No Sector Assigned';

const colors = {
  blue: {
    lighter: '#91daff',
    light: '#3ab1ef',
    dark: '#1978a9',
    darker: '#0c5074',
  },
  green: {
    light: '#91dcac',
    dark: '#4ce687',
    darker: '#1bbb58',
  },
  alpha50: {
    blue: 'rgba(58, 177, 239, 0.5)',
    green: 'rgba(145, 220, 172, 0.5)',
  },
  alpha30: {
    blue: 'rgba(58, 177, 239, 0.3)',
    green: 'rgba(145, 220, 172, 0.3)',
  },
  black: '#000000',
  white: '#ffffff',
  gray: {
    light: '#E2E2E2',
    dark: '#88939a',
  },
};

export const barChartColors = {
  lighter: colors.blue.lighter,
  light: colors.blue.light,
  dark: colors.blue.dark,
  darker: colors.blue.darker,

  ipoBg: colors.green.light,

  lightAlpha: colors.alpha50.blue,
  ipoBgAlpha: colors.alpha50.green,

  lightAlpha30: colors.alpha30.blue,
  ipoBgAlpha30: colors.alpha30.green,
  grayer: colors.gray.dark,
};

// sorted alphabetically
export const sectorColors = [
  { value: 'Basic Materials', color: colors.blue.lighter },
  { value: 'Consumer Cyclical', color: '#ff8f83' },
  { value: 'Communication Services', color: '#e5534c' },
  { value: 'Consumer Defensive', color: '#f0efb7' },
  { value: 'Energy', color: colors.green.darker },
  { value: 'Financial Services', color: colors.green.dark },
  { value: 'Healthcare', color: colors.green.light },
  { value: 'Industrials', color: '#4c5053' },
  { value: 'Real Estate', color: '#a9aeb8' },
  { value: 'Technology', color: colors.blue.dark },
  { value: 'Utilities', color: colors.blue.light },
];

export const moreSectorColors = [
  { color: '#F5B041' },
  { color: '#9C640C' },
  { color: '#48C9B0' },
  { color: '#C39BD3' },
  { color: '#9B59B6' },
];

export const typeColors = [
  { value: 'Registered Block', color: barChartColors.dark },
  { value: 'Marketed FO', color: barChartColors.lighter },
  { value: 'Overnight FO', color: barChartColors.light },
  { value: 'Unregistered Block', color: barChartColors.darker },
  { value: 'IPO', color: barChartColors.ipoBg },
];

export const defaultRadarColors = {
  pointBg: colors.white,
  pointLabel: colors.black,
  pointBorder: colors.green.light,
};

export const transparent = 'transparent';

// Color helpers

export const getConstantByValue = (
  constantsArr: { value: string; color: string }[],
  value: string
) => constantsArr.filter(constant => constant.value === value)[0];

// TODO fix snake_case (who the hell designed it like this?!?)
export const getSectorColor = (sector: { label: string; color_code?: number }): string => {
  if (sector.label === NO_SECTOR_LABEL) {
    return colors.black;
  }

  const allSectorColors = [...sectorColors, ...moreSectorColors];
  const ColorByColorCode = sector.color_code && allSectorColors[sector.color_code];
  if (ColorByColorCode) {
    return ColorByColorCode.color;
  }

  const colorByLabel = getConstantByValue(sectorColors, sector.label);
  if (colorByLabel) {
    return colorByLabel.color;
  }

  return getColorFromString(sector.label);
};

export const getTypeColor = type => {
  const color = getConstantByValue(typeColors, type);

  return (color && color.color) || '#000';
};

/**
 * Put noAssigned row to the end of the array. Usage is in color-legends of our charts.
 */
export const noSectorAsLast = arr => {
  const noSector = arr.find(row => row.label === NO_SECTOR_LABEL);
  if (noSector) {
    return [...arr.filter(row => row.label !== NO_SECTOR_LABEL), noSector];
  }
  return arr;
};

/**
 * Gets random, but same color for same string
 */
export function getColorFromString(val: string) {
  if (!val) {
    return '#000';
  }
  const x = val.split('').reduce((i, s) => s.charCodeAt(0) + i, 0);
  return (
    '#' +
    (
      '00' + // prefix which will be trimmed in most cases
      Math.floor(
        Number(
          '0.' +
            Math.sin(x)
              .toString() // It converts string to long number (by math.sin)
              .substr(6) // than takes only some last digits and creates number between 0-1
        ) *
          (4095 - 100) + // multiplied by 4095 would be full color range
          50 / 4095 // but we remove first and last shades to get rid of black and white
      ).toString(16)
    ) // convert to hex
      .slice(-3) // remove the placeholding zeros
  );
}
