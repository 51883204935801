import React from 'react';

import { OfferingProfile_Common_CornerstoneInvestmentFragment } from '../../../../common/graphql/__generated__/commonFragments';
import { CornerstoneInvestmentType } from '../../../../graphql/__generated__';
import { getFormattedCurrencyInMillion } from '../../../shared/model/utils';
import { Offering } from '../offerings-report-table/types';
import {
  HeaderLabel,
  SLabel,
  STooltipTable,
  StyledPopover,
} from './CornerstoneInvestorsRenderer.styles';
import CornerstoneInvestorsTableContent from './CornerstoneInvestorsTableContent';

export type Props = {
  offering?: Offering;
};

const mapToFirmName = (
  investment: OfferingProfile_Common_CornerstoneInvestmentFragment
): string[] =>
  (investment.investors ?? [])
    .flatMap(investor => investor.shareholder?.name ?? '')
    .filter(name => name.length > 0);

export const formatInvestorNames = (offering?: Offering): string => {
  const { cornerstoneInvestments } = offering ?? {};
  const offeringParticipants =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.OfferingParticipant) ??
    [];
  const privatePlacement =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.PrivatePlacement) ??
    [];
  const offeringParticipantsInvestors = offeringParticipants.flatMap(mapToFirmName);
  const privatePlacementInvestors = privatePlacement.flatMap(mapToFirmName);

  if (offeringParticipantsInvestors.length === 0) {
    return privatePlacementInvestors.join(', ');
  }
  if (privatePlacementInvestors.length === 0) {
    return offeringParticipantsInvestors.join(', ');
  }
  return `${offeringParticipantsInvestors.join(', ')} / ${privatePlacementInvestors.join(', ')}`;
};

const CornerstoneInvestorsRenderer: React.FC<Props> = ({ offering }) => {
  const { cornerstoneInvestments, attributes } = offering ?? {};
  const {
    pricingCurrency,
    cornerstoneOfferingParticipantsAmount,
    cornerstonePrivatePlacementAmount,
    cornerstoneTotalAmount,
    cornerstoneInvestorsTotalCount,
    cornerstoneInvestorsOfferingParticipantsCount,
    cornerstoneInvestorsPrivatePlacementCount,
  } = attributes ?? {};

  const offeringParticipants =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.OfferingParticipant) ??
    [];

  const privatePlacement =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.PrivatePlacement) ??
    [];

  const investmentNames = formatInvestorNames(offering);
  if (investmentNames.length === 0) {
    return <span>-</span>;
  }

  return (
    <StyledPopover
      content={
        <STooltipTable>
          <thead>
            <tr>
              <HeaderLabel className="cornerstone-header">
                {`Cornerstone Investors (${cornerstoneInvestorsTotalCount})`}
                <div className="subtitle">
                  Cornerstone Investment Total
                  <span>
                    {getFormattedCurrencyInMillion(pricingCurrency, cornerstoneTotalAmount)}
                  </span>
                </div>
              </HeaderLabel>
            </tr>
          </thead>
          <CornerstoneInvestorsTableContent
            cornerstoneInvestments={offeringParticipants}
            cornerstoneInvestorsCount={cornerstoneInvestorsOfferingParticipantsCount}
            cornerstoneOfferingAmount={cornerstoneOfferingParticipantsAmount}
            investmentType={CornerstoneInvestmentType.OfferingParticipant}
            pricingCurrency={pricingCurrency}
          />
          <CornerstoneInvestorsTableContent
            cornerstoneInvestments={privatePlacement}
            cornerstoneInvestorsCount={cornerstoneInvestorsPrivatePlacementCount}
            cornerstoneOfferingAmount={cornerstonePrivatePlacementAmount}
            investmentType={CornerstoneInvestmentType.PrivatePlacement}
            pricingCurrency={pricingCurrency}
          />
        </STooltipTable>
      }
    >
      <SLabel>{investmentNames}</SLabel>
    </StyledPopover>
  );
};

export default CornerstoneInvestorsRenderer;
