import styled from 'styled-components/macro';

import { isIE } from '../../../../common/helpers/helpers';
import { PeriodColumn } from '../common/DashboardPeriod';

export const SRow = styled.div`
  /* side by side comparison view, below this breakpoint they will be stacked up */
  ${({ theme }) => (isIE() ? theme.mediaQuery.xlargeUp : theme.mediaQuery.largeUp)} {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    & > ${PeriodColumn}:first-of-type {
      margin-right: 15px;
    }
  }
`;

export const SDashboard = styled.div`
  /** cannot be flex column because Safari
  does not calculate children dashboard periods height: auto correctly which causes
  the content to bleed out of the container when resizing */
  width: 100%;
`;
