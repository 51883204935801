import { datalabApi } from '@cmg/api';
import React from 'react';
import styled from 'styled-components/macro';

import { SponsorOption } from '../../../../types/domain/firm/sponsorOptions';
import { firmSubTypeLabels } from '../../../shared/constants/constants';

const SSponsorOption = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SContent = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.color.gray7};
  text-align: right;
`;

function formatFirmSubType(firmSubType: datalabApi.FirmSubType | null) {
  if (!firmSubType || !firmSubTypeLabels[firmSubType]) {
    return null;
  }

  return firmSubTypeLabels[firmSubType];
}

export type Props = SponsorOption;

const SponsorOptionRenderer: React.FC<Props> = ({ label, sponsorType }) => (
  <SSponsorOption>
    <div>{label}</div>
    <SContent>{formatFirmSubType(sponsorType)}</SContent>
  </SSponsorOption>
);

export default SponsorOptionRenderer;
