import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { RadarChart, RadarChartProps } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    value: number;
    count: number;
  }[];
  title: string;
  label: string;
};

type RawItem = {
  r: number;
  count: number;
};

type ChartProps = RadarChartProps<RawItem[]>;

export const transformData = (
  chartData: Props['chartData'] = [],
  label: string
): ChartProps['data'] => {
  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        label,
        data: chartData.map((item): RawItem => ({ r: item.value, count: item.count })),
        borderColor: barChartColors.ipoBgAlpha,
        backgroundColor: barChartColors.ipoBgAlpha,
      },
    ],
  };
};

function getChartOptions(): ChartProps['options'] {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const raw = tooltipItem.raw as RawItem;
            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;

            return ` ${numericUtil.formatPercents(tooltipItem.parsed.r)}${countString ?? ''}`;
          },
        },
      },
    },

    scales: {
      r: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
        },
      },
    },
  };
}

const WidgetSpiderChart: React.FC<Props> = ({ title, chartData = [], label }) => {
  const transformedData = React.useMemo(() => transformData(chartData, label), [chartData, label]);
  const options = React.useMemo(() => getChartOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title={title} />

      <Box.Content>
        <SChart>
          <RadarChart data={transformedData} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetSpiderChart;
