import { createFormikField, FieldControlProps } from '@cmg/common';
import React from 'react';

import { SValueInput } from './ValueInputControl.styles';

interface Props extends FieldControlProps<any, any> {
  renderer: Function;
  value: any;
}

export const ValueInputControlComponent: React.FC<Props> = ({ renderer, value, onChange }) => {
  const Component = renderer;
  return (
    <SValueInput>
      <Component value={value} onChange={onChange} />
    </SValueInput>
  );
};

export default createFormikField<any, any, HTMLInputElement, Props>(ValueInputControlComponent);
