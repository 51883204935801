import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { ChartProps, Radar } from 'react-chartjs-2';

// TODO - refactor for chart default colors not to be coupled to datalab
import { defaultRadarColors } from '../../../../features/datalab/colors';

export type Props<TData = void> = Omit<ChartProps<'radar', TData>, 'type'>;

const defaultRadarChartOptions: Props['options'] = {
  plugins: {
    legend: {
      display: false,
    },
  },
  datasets: {
    line: {
      tension: 0.9,
    },
    radar: {
      fill: true,
      pointHitRadius: 10,
      pointBackgroundColor: defaultRadarColors.pointBg,
      pointHoverBackgroundColor: defaultRadarColors.pointBg,
      pointBorderColor: defaultRadarColors.pointBorder,
      pointHoverBorderColor: defaultRadarColors.pointBorder,
      pointBorderWidth: 3,
      pointHoverBorderWidth: 3,
    },
  },
  scales: {
    r: {
      beginAtZero: true,
      pointLabels: {
        color: defaultRadarColors.pointLabel,
        font: {
          size: 13,
        },
      },
    },
  },
};

const getRadarChartOptions = <TData extends unknown>(options: Props<TData>['options']) => {
  return merge(cloneDeep(defaultRadarChartOptions), options);
};

export const RadarChart = <TData extends unknown>({ data, options }: Props<TData>) => {
  const mergedOptions = getRadarChartOptions(options);

  return <Radar data={data} options={mergedOptions} />;
};
