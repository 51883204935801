import { DangerButton, PrimaryButton, SecondaryButton, TextInput } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SSectorRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SSectorName = styled.span<{ crossed?: boolean }>`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  text-decoration: ${({ crossed }) => (crossed ? `line-through` : `none`)};
`;

export const StyledButton = styled(SecondaryButton)`
  border: none;
  cursor: pointer;
  padding: 2px;
  font-size: 12px;
  &:not(:hover):not(:active) {
    color: ${({ theme }) => theme.text.color.light};
  }
  &:hover {
    background: none;
    color: ${({ theme }) => theme.text.color.link};
  }
`;

export const StyledSectorDeleteIconButton = styled(StyledButton)`
  &:hover {
    color: ${({ theme }) => theme.color.red1};
  }
`;

export const primaryStyles = css`
  color: ${({ theme }) => `${theme.color.white} !important`};
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
`;

export const StyledSectorDeleteButton = styled(DangerButton)`
  ${primaryStyles}
`;

export const StyledSectorPrimaryButton = styled(PrimaryButton)`
  ${primaryStyles}
`;

export const SErrorNote = styled.span`
  color: ${({ theme }) => theme.text.color.error};
  margin: 0 10px;
`;

export const SSectorInputWrapper = styled.div`
  display: inline-block;
`;

export const StyledSectorInput = styled(TextInput)`
  width: 250px;
  display: inline-block;
`;
