import { LinkButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SWrapper = styled.div<{ isEditing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.isEditing &&
    css`
      margin-right: 10px;
    `}
`;

export const SLinkButton = styled(LinkButton)`
  &:disabled {
    color: ${({ theme }) => theme.text.color.light};
  }
`;
