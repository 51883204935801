import styled from 'styled-components/macro';

export const SButtonWrapper = styled.div`
  margin-left: 5px;
`;

export const SRankingTag = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;

  .stationary {
    margin-left: -15px;
  }

  button:disabled {
    opacity: 1;
    cursor: default;
    pointer-events: none;
  }
`;
