import styled from 'styled-components/macro';

export const STitle = styled.h4`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin: 0;
`;

export const SNoRecentDeals = styled.div.attrs(() => ({
  'data-testid': 'sno-recent-deals',
}))`
  color: ${({ theme }) => theme.background.color.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 15px;
`;
