import { PrivateRouteContent } from '@cmg/auth';
import React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

type OwnProps = {
  accessCheck?: (userPermissions: string[]) => boolean;
  /** requiredPermissions required permissions for the content to display */
  requiredPermissions?: string[];
  /** requireAllPermissions when true, requires the user to have all permissions in 'requiredPermissions' */
  requireAllPermissions?: boolean;
  /** renderNoAccess function that will be invoked when requiredPermissions fails or if the use is not logged in */
  renderNoAccess: (props: { isLoggedIn: boolean } & RouteComponentProps) => React.ReactNode;
};

export type Props = OwnProps & RouteProps;

export const PrivateRoute: React.FC<Props> = ({
  render,
  renderNoAccess,
  component: Component,
  children,
  accessCheck,
  requireAllPermissions,
  requiredPermissions,
  ...rest
}) => {
  const renderContent = (routeProps: RouteComponentProps<any>) => {
    if (render) {
      return render(routeProps);
    }

    if (Component) {
      return <Component {...routeProps} />;
    }

    return children;
  };

  return (
    <Route
      {...rest}
      render={routeProps => (
        <PrivateRouteContent
          accessCheck={accessCheck}
          requireAllPermissions={requireAllPermissions}
          requiredPermissions={requiredPermissions}
          renderNoAccess={isLoggedIn => renderNoAccess({ isLoggedIn, ...routeProps })}
        >
          {renderContent(routeProps)}
        </PrivateRouteContent>
      )}
    />
  );
};
