import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidV4 } from 'uuid';

import { UUID } from '../../../../types/common';
import { FundIoi, Ioi } from '../../../../types/domain/offering/ioi/ioi';

const ioiTemplate = {
  id: null,
  unitType: null,
  dollars: null,
  percentage: null,
  shares: null,
  pricingType: null,
  limitPrice: null,
  calculateShares: null,
  price: null,
  realDemandDollars: null,
  realDemandPercentage: null,
  realDemandShares: null,
  isEditing: true,
  uuid: null,
  formErrors: {
    unitType: null,
    dollars: null,
    percentage: null,
    shares: null,
    pricingType: null,
    limitPrice: null,
    realDemandDollars: null,
    realDemandPercentage: null,
    realDemandShares: null,
    limitType: null,
    limitPercentage: null,
  },
};

export const buildIOIs = (iois: Ioi[]) => {
  return iois.map(ioi => ({
    ...cloneDeep(ioiTemplate),
    id: ioi.id,
    unitType: ioi.unitType,
    dollars: ioi.dollars,
    percentage: ioi.percentage,
    shares: ioi.shares,
    pricingType: ioi.pricingType,
    limitPrice: ioi.limitPrice,
    calculateShares: ioi.calculateShares,
    // @ts-ignore price does not exist on Ioi model
    price: ioi.price,
    realDemandDollars: ioi.realDemandDollars,
    realDemandPercentage: ioi.realDemandPercentage,
    realDemandShares: ioi.realDemandShares,
    limitType: ioi.limitType,
    limitPercentage: ioi.limitPercentage,
    uuid: uuidV4(),
  }));
};

export const getNewIoI = () => ({
  ...cloneDeep(ioiTemplate),
  uuid: uuidV4(),
  unitType: '',
  dollars: undefined,
  percentage: undefined,
  shares: undefined,
  pricingType: '',
  limitPrice: undefined,
  calculateShares: undefined,
  price: undefined,
  realDemandDollars: undefined,
  realDemandPercentage: undefined,
  realDemandShares: undefined,
  limitType: '',
  limitPercentage: undefined,
});

export const buildFundIOIs = (fundIois: FundIoi[]) => {
  return fundIois.map(fundIoi => ({
    ...cloneDeep(ioiTemplate),
    id: fundIoi.id,
    uuid: uuidV4(),
    fundId: fundIoi.fundId,
    unitType: fundIoi.unitType,
    dollars: fundIoi.dollars,
    percentage: fundIoi.percentage,
    shares: fundIoi.shares,
    pricingType: fundIoi.pricingType,
    limitPrice: fundIoi.limitPrice,
    calculateShares: fundIoi.calculateShares,
    // @ts-ignore price does not exist on Ioi model
    price: fundIoi.price,
  }));
};

export const getNewFundIoI = (fundId: UUID) => ({
  ...cloneDeep(ioiTemplate),
  uuid: uuidV4(),
  fundId: fundId,
  unitType: '',
  dollars: undefined,
  percentage: undefined,
  shares: undefined,
  pricingType: '',
  limitPrice: undefined,
  calculateShares: undefined,
  price: undefined,
});
