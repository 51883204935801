import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum MeanMedianButtons {
  MEAN = 'MEAN',
  MEDIAN = 'MEDIAN',
}

export const meanMedianButtonsConfig: ButtonsConfig = {
  [MeanMedianButtons.MEAN]: { label: 'MEAN' },
  [MeanMedianButtons.MEDIAN]: { label: 'MEDIAN' },
};

export default getButtonConfigArray(meanMedianButtonsConfig);
