import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { BarChart } from '../../../../../common/components/charts';
import { orderAscBySelector } from '../../../../../obsolete/utils/helpers';
import { getSectorColor, noSectorAsLast } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

export type Row = {
  count: number;
  label: string;
  value: number;
};

type RawItem = {
  x: string;
  y: number;
  count: number;
};

export const transformData = (rawData: Row[] = []) => {
  const sorted = noSectorAsLast(
    rawData.sort(orderAscBySelector.bind(null, item => item.label))
  ) as Row[];

  return {
    datasets: [
      {
        backgroundColor: sorted.map(item => getSectorColor(item)),
        data: sorted.map(
          (item): RawItem => ({
            x: item.label,
            y: item.value,
            count: item.count,
          })
        ),
      },
    ],
  };
};

type Props = {
  rawData?: Row[];
  title: string;
  yLabelFormatter: (value: number) => string;
  valueFormatter: (value: number) => string;
};

const SingleVerticalBarChart = ({
  rawData = [],
  title,
  yLabelFormatter,
  valueFormatter,
}: Props) => {
  const transformedData = React.useMemo(() => transformData(rawData), [rawData]);

  return (
    <SDatalabChart>
      <Box.Header title={title} />
      <Box.Content>
        <SChart>
          <BarChart
            data={transformedData}
            barSizingMode="CMGCenteredBarPlugin"
            options={{
              scales: {
                y: {
                  ticks: {
                    callback: value => (typeof value === 'number' ? yLabelFormatter(value) : value),
                  },
                },
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: tooltipItem => {
                      const { parsed } = tooltipItem;
                      const raw = tooltipItem.raw as RawItem;

                      const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;
                      const value = valueFormatter(parsed.y);
                      return `${value}${countString}`;
                    },
                  },
                },
              },
            }}
          />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default SingleVerticalBarChart;
