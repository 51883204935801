import styled, { css } from 'styled-components/macro';

import { sidebarCollapsedWidth, sidebarExpandedWidth } from './sidebar/Sidebar.styles';

export const SWrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  .is-mobile-device & {
    -webkit-overflow-scrolling: touch;
  }
`;

export type SidebarState = 'collapsed' | 'expanded' | 'hidden';
export const SScreenWrapper = styled.div<{ sidebarState: SidebarState }>`
  /* Takes up all the width in the viewport that the sidebar is not */
  flex: 1 1 100%;
  /*
    If we don't lock in a max-width in, this column will keep extending to the right based on the content size within.
    Setting a max-width sets a bound on the width and the responsibility for handling wide content is delegated to components
    further down the tree. (to prevent issues like this https://capitalmarketsgateway.atlassian.net/browse/ECM-4305)
  */
  ${({ sidebarState }) =>
    sidebarState === 'collapsed' &&
    css`
      max-width: ${`calc(100% - ${sidebarCollapsedWidth})`};
    `}

  ${({ sidebarState }) =>
    sidebarState === 'expanded' &&
    css`
      max-width: ${`calc(100% - ${sidebarExpandedWidth})`};
    `}

  transition: width 0.15s ease;

  .is-mobile-device & {
    min-width: ${`calc(100% - ${sidebarCollapsedWidth})`};
  }
`;
