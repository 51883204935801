import { Icon, Popover, PrimaryButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import SaveDropdownMenu from './SaveDropdownMenu';

const SFlex = styled.div`
  display: flex;
`;

const SSaveButtonWrapper = styled.div`
  display: flex;
  margin: 0 15px;
`;

const SSavePopoverContent = styled.div`
  max-width: 280px;
`;

const SSaveButtonLabel = styled.span`
  padding-right: 9px;
`;

// TODO: ECM-1536 - A number of styles in StyledSaveToolbarPrimaryButton
// and StyledSaveDropdownToolbarPrimaryButton (specifically, border-related
// styles) are there to achieve a button group-like UI.
// Once we have a common button group component, we should be able to
// utilize that and remove many of the styles in both of these
// styled components.
export const StyledSaveToolbarPrimaryButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 0;
  margin-right: 0;
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right: ${({ theme }) => `1px solid ${theme.border.color.light}`};

  &:active,
  &:focus {
    box-shadow: none;
  }
`;

export const StyledSaveDropdownToolbarPrimaryButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  padding: 6px 8px 6px 8px;
  margin: 0 2px 0 0;
  border-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;

  &:active,
  &:focus {
    box-shadow: none;
  }
`;

type Props = {
  isReportShared: boolean;
  isSaveButtonEnabled: boolean;
  onSaveReport: () => void;
  onCreateReport: () => void;
};

const SaveButtonGroup: React.FC<Props> = ({
  onSaveReport,
  onCreateReport,
  isReportShared,
  isSaveButtonEnabled,
}) => (
  <SSaveButtonWrapper>
    <Popover
      trigger="hover"
      disabled={!(isReportShared && isSaveButtonEnabled)}
      content={
        <SSavePopoverContent>
          Saving the report will update the report for all users the report is shared with.
        </SSavePopoverContent>
      }
      placement="topRight"
      variant="DARK"
    >
      <StyledSaveToolbarPrimaryButton
        type="button"
        disabled={!isSaveButtonEnabled}
        onClick={onSaveReport}
      >
        <SSaveButtonLabel>Save</SSaveButtonLabel>
      </StyledSaveToolbarPrimaryButton>
    </Popover>
    <Popover
      trigger="focus"
      content={<SaveDropdownMenu onCreateReport={onCreateReport} />}
      placement="bottomRight"
      hideArrow
    >
      <SFlex>
        <StyledSaveDropdownToolbarPrimaryButton
          type="button"
          onClick={event => {
            // In Firefox and Safari, buttons do not receive focus
            // when they're clicked.  As we're using the "focus"
            // trigger in the Popover containing this node, we
            // need to manually trigger focus so that the Popover appears.
            event.currentTarget.focus();
          }}
        >
          <Icon size="lg" name="angle-down" />
        </StyledSaveDropdownToolbarPrimaryButton>
      </SFlex>
    </Popover>
  </SSaveButtonWrapper>
);

export default SaveButtonGroup;
