import { Box, numericUtil } from '@cmg/common';
import React, { useMemo, useState } from 'react';

import { MixedChart, MixedChartProps } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors, transparent } from '../../../colors';
import afterMarketPerfOfferButtons from '../../../model/buttons/after-market-perf-offer-buttons';
import ioiMarketPerformanceButtons from '../../../model/buttons/ioi-market-performance-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type PointItem = {
  x: number;
  y: number;
  ticker: string;
};

type LineItem = {
  x: number;
  y: number;
};

type Props = {
  chartData?: {
    pct_of_deal: {
      offer_to_current: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_day: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_month: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_three_months: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_six_months: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_open: {
        points: PointItem[];
        line: LineItem[];
      };
    };
    pct_fill: {
      offer_to_current: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_day: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_month: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_three_months: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_six_months: {
        points: PointItem[];
        line: LineItem[];
      };
      offer_to_open: {
        points: PointItem[];
        line: LineItem[];
      };
    };
  };
};

type RawItem = {
  x: number;
  y: number;
  ticker?: string;
};

const transformData = (
  chartData: Props['chartData'],
  dataKeyValue: string,
  timePeriodValue: string
): MixedChartProps['data'] | null => {
  if (!chartData) {
    return null;
  }

  const selectedChartData: { points: PointItem[]; line: LineItem[] } =
    chartData[dataKeyValue][timePeriodValue];

  const data: MixedChartProps['data'] = {
    datasets: [
      {
        borderColor: transparent,
        data: selectedChartData.points.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
            ticker: item.ticker,
          })
        ),
        backgroundColor: barChartColors.lighter,
        pointBorderColor: barChartColors.lighter,
        pointBorderWidth: 0,
        pointRadius: 3,
        pointHoverRadius: 6,
        type: 'scatter',
      },
      {
        data: selectedChartData.line.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
          })
        ),
        pointRadius: 0,
        backgroundColor: barChartColors.light,
        borderColor: barChartColors.light,
        type: 'line',
      },
    ],
  };
  return data;
};

const getOptions = (dataKeyLabel: string): MixedChartProps['options'] => {
  return {
    scales: {
      x: {
        position: 'bottom',
        ticks: {
          callback: val => (typeof val === 'number' ? numericUtil.formatPercents(val, 1) : val),
        },
      },
      y: {
        ticks: {
          display: true,
          callback: val => (typeof val === 'number' ? numericUtil.formatPercents(val, 1) : val),
        },
        title: {
          display: true,
          text: '(Aftermarket Performance)',
        },
        min: -1,
        max: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: tooltipItems => {
            const raw = tooltipItems[0].raw as RawItem;

            return raw.ticker ?? '';
          },
          label: tooltipItem => [
            `Aftermarket Performance: ${numericUtil.formatPercents(tooltipItem.parsed.y, 1)}`,
            `${dataKeyLabel}: ${numericUtil.formatPercents(tooltipItem.parsed.x, 1)}`,
          ],
        },
      },
    },
  };
};

const WidgetAftermarketPerformanceRelativeToIoI: React.FC<Props> = ({ chartData }) => {
  const [activeBtn, setActiveBtn] = useState(ioiMarketPerformanceButtons[0]);
  const [offerActiveBtn, setOfferActiveBtn] = useState(afterMarketPerfOfferButtons[3]);

  const transformedData = useMemo(
    () => transformData(chartData, activeBtn.value, offerActiveBtn.value),
    [chartData, activeBtn.value, offerActiveBtn.value]
  );
  const optionsMemo = useMemo(() => getOptions(activeBtn.label), [activeBtn.label]);

  const handleOfferBtnChange = (btn: any) => {
    setOfferActiveBtn(btn);
  };

  const handleButtonChange = (btn: any) => {
    setActiveBtn(btn);
  };

  if (!transformedData) {
    return null;
  }

  return (
    <SDatalabChart>
      <Box.Header title="Aftermarket Performance Relative to Participation" />

      <SButtonBox>
        <Toggler
          buttons={ioiMarketPerformanceButtons}
          onChange={handleButtonChange}
          activeButton={activeBtn}
          size="small"
        />

        <Toggler
          buttons={afterMarketPerfOfferButtons}
          onChange={handleOfferBtnChange}
          activeButton={offerActiveBtn}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart>
          <MixedChart data={transformedData} options={optionsMemo} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetAftermarketPerformanceRelativeToIoI;
