import isEqual from 'lodash/isEqual';
import { combineReducers } from 'redux';

import { createActionCreator, createReducer } from '../../../common/redux/reduxHelpers';

/**
 * ACTION TYPES
 */
export const START_API_CALL = 'spinner/START_API_CALL';
export const FINISH_API_CALL = 'spinner/FINISH_API_CALL';

/**
 * ACTIONS
 */
export const startApiCall = createActionCreator(START_API_CALL);
export const finishApiCall = createActionCreator(FINISH_API_CALL);

/**
 * REDUCERS
 */
export const initialState = {
  globalCounter: 0,
  apiCalls: {},
};

const globalCounter = createReducer(initialState.globalCounter, {
  [START_API_CALL]: (state, payload) =>
    payload.apiCallId || payload.apiCallId === false ? state : state + 1,
  [FINISH_API_CALL]: (state, payload) =>
    payload.apiCallId || payload.apiCallId === false ? state : state - 1,
});

const apiCalls = createReducer(initialState.apiCalls, {
  [START_API_CALL]: (state, { apiCallId, apiCallIdParams }) => {
    if (apiCallId) {
      const apiCall = state[apiCallId] || { count: 0, requests: [] };
      const count = apiCall.count + 1;
      const requests = apiCallIdParams ? [...apiCall.requests, apiCallIdParams] : apiCall.requests;

      return {
        ...state,
        [apiCallId]: { count, requests },
      };
    }

    return state;
  },
  [FINISH_API_CALL]: (state, { apiCallId, apiCallIdParams }) => {
    if (apiCallId) {
      const apiCall = state[apiCallId] || { count: 1, requests: [] };
      const count = apiCall.count - 1;
      const requests = apiCallIdParams
        ? apiCall.requests.filter(r => r !== apiCallIdParams)
        : apiCall.requests;

      return {
        ...state,
        [apiCallId]: { count, requests },
      };
    }

    return state;
  },
});

export default combineReducers({
  globalCounter,
  apiCalls,
});

/**
 * SELECTORS
 */
export const selectSpinner = state => state.spinner;
export const selectShowSpinner = state => !!selectSpinner(state).globalCounter;
export const selectApiCalls = state => selectSpinner(state).apiCalls;
export const selectApiCallById = (state, apiCallId) => selectApiCalls(state)[apiCallId];
export const selectApiCallCountById = (state, apiCallId) =>
  selectApiCallById(state, apiCallId) && selectApiCallById(state, apiCallId).count;
export const selectApiCallByParams = (state, apiCallId, params) =>
  selectApiCallById(state, apiCallId) &&
  selectApiCallById(state, apiCallId).requests.filter(r => isEqual(r, params));
export const selectApiCallCountByParams = (state, apiCallId, params) =>
  selectApiCallById(state, apiCallId) && selectApiCallByParams(state, apiCallId, params).length;
export const selectIsInProgress = (state, apiCallId, params) =>
  params
    ? !!selectApiCallCountByParams(state, apiCallId, params)
    : !!selectApiCallCountById(state, apiCallId);
