import React from 'react';

import { SectionHeaderProps } from '../../features/offering-dl/offering-profile/profiles/shared/types';

const nearestElementIndex = (
  currentPosition: number,
  sections: SectionHeaderProps[]
): string | null => {
  let activeSection: string | null = null;
  sections.forEach(section => {
    const sectionElement = document.getElementById(section.id);
    if (!sectionElement) {
      return;
    }
    const { offsetTop, offsetHeight } = sectionElement;
    const offsetBottom = offsetTop + offsetHeight;

    if (currentPosition >= offsetTop && currentPosition < offsetBottom) {
      activeSection = section.id;
    }
  });
  return activeSection;
};

export type UseAciveSectionOnScrollParams = {
  sectionsConfig: SectionHeaderProps[];
  mainSectionId: string;
};
export type UseAciveSectionOnScrollResult = {
  activeSection: string | null;
  handleClick: (section: SectionHeaderProps) => void;
};
export const useActiveSectionOnScroll = ({
  sectionsConfig,
  mainSectionId,
}: UseAciveSectionOnScrollParams): UseAciveSectionOnScrollResult => {
  const [activeSection, setActiveSection] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (sectionsConfig.length > 0) {
      const mainSection = document.getElementById(mainSectionId);
      const handleScroll = e => {
        const sectionId = nearestElementIndex(
          e.currentTarget.offsetTop + e.currentTarget.scrollTop,
          sectionsConfig
        );
        sectionId && setActiveSection(sectionId);
      };
      setActiveSection(sectionsConfig[0].id);
      mainSection?.addEventListener('scroll', handleScroll);
      return () => {
        mainSection?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [mainSectionId, sectionsConfig]);

  const handleClick = React.useCallback((section: SectionHeaderProps) => {
    const targetSection = document.getElementById(section.id);
    targetSection?.scrollIntoView({ behavior: 'smooth' });
    setActiveSection(section.id);
  }, []);
  return {
    activeSection,
    handleClick,
  };
};
