import { Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UserReport } from '../../../../types/domain/report/userReport';
import { selectUserSettings } from '../../../shared/ducks';
import { DatalabScreens, DatalabScreenViewTypes, ReportDetailsModalMode } from '../../constants';
import {
  favoriteReportActions,
  initDatalabAction,
  initReportDetailsModalActions,
  loadReportActions,
  selectFilters,
  selectReport,
  selectVisibleColumns,
  updateReportActions,
} from '../../ducks';
import { getFilterDefaultValues } from '../../model/filters.model';
import { getReportFilterValues } from '../../model/reports.model';
import { TableConfigType } from '../../model/table.model';
import { DatalabFilterValues } from '../../types';
import { getInitialSectionType } from './DatalabToolbar.model';
import DatalabToolbarForm from './DatalabToolbarForm';

// TODO - why are filter values being set to undefined in some
// places and set to null in others?  If this weren't the case,
// we could remove this custom equality check function in favor
// of doing a standard deep equality check.
export const areFiltersEqual = memoize(
  (currentFilters: DatalabFilterValues, existingFilters: DatalabFilterValues) => {
    const doesFilterValueDiffer = (key: string) => {
      const currentFilterValue = currentFilters[key];
      const existingFilterValue = existingFilters[key];

      if (Array.isArray(currentFilterValue) || Array.isArray(existingFilterValue)) {
        // If arrays are empty in one FilterValue set and are undefined
        // in the other, we can treat them as being equal.
        if (
          (currentFilterValue.length === 0 && existingFilterValue === undefined) ||
          (currentFilterValue === undefined && existingFilterValue.length === 0)
        ) {
          return false;
        } else {
          return !isEqual(currentFilterValue, existingFilterValue);
        }
      } else {
        // If scalar values are null and undefined, we can treat
        // them as being equal.
        if (
          (currentFilterValue === null && existingFilterValue === undefined) ||
          (currentFilterValue === undefined && existingFilterValue === null)
        ) {
          return false;
        } else {
          return !isEqual(currentFilterValue, existingFilterValue);
        }
      }
    };

    // These properties exist in the filters object, but should not be
    // included when determining if filters have changed.
    const ignoredFilterFields = ['useCustomSectors'];

    const areFiltersEqual = !Object.keys(currentFilters)
      .filter(key => !ignoredFilterFields.includes(key))
      .some(doesFilterValueDiffer);

    return areFiltersEqual;
  }
);

export const haveFiltersChanged = memoize(
  (currentFilters: DatalabFilterValues, initialFilters: DatalabFilterValues): boolean =>
    !areFiltersEqual(currentFilters, initialFilters)
);

export const haveVisibleTableColumnsChanged = memoize(
  (currentVisibleColumns: any[], initialVisibleColumns: any[]): boolean =>
    xor(initialVisibleColumns, currentVisibleColumns).length > 0
);

export const hasSectionTypeChanged = memoize(
  (
    currentSectionType: DatalabScreenViewTypes,
    initialSectionType: DatalabScreenViewTypes
  ): boolean => currentSectionType !== initialSectionType
);

const mapStateToProps = (state, props: OwnProps) => {
  const report = selectReport(state);
  const userSettings = selectUserSettings(state);
  const filters = selectFilters(state);
  const visibleColumns = selectVisibleColumns(state);
  const filterDefaultValues = getFilterDefaultValues({}, props.screen, userSettings);
  const visibleColumnsDefaultValues = props.tableConfig.builtVisibleColumns;

  // If a saved report is being viewed, the initial filters, column settings,
  // and section types are the saved report's filters and column settings.
  // Otherwise, the default filter and column settings values are the default
  // filter and column settings.
  const initialFilters = report ? getReportFilterValues(report) : filterDefaultValues;
  const initialVisibleColumns = report ? report.fields : visibleColumnsDefaultValues;
  const initialSectionType = getInitialSectionType(props.screenType, report);
  const hasReportChanged =
    haveFiltersChanged(filters, initialFilters) ||
    haveVisibleTableColumnsChanged(visibleColumns, initialVisibleColumns) ||
    hasSectionTypeChanged(props.screenType, initialSectionType);

  return {
    report,
    canSaveReport: hasReportChanged,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initReportDetailsModal: initReportDetailsModalActions.request,
      saveReport: updateReportActions.request,
      loadReport: loadReportActions.request,
      reset: initDatalabAction,
      favoriteReport: favoriteReportActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  isFilterPanelExpanded: boolean;
  toggleFilters: () => void;
  screen: DatalabScreens;
  screenType: DatalabScreenViewTypes;
  filterCount: number;
  tableConfig: TableConfigType;
};

export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class DatalabToolbar extends React.PureComponent<Props> {
  handleLoadReport = (values: { reportId: UserReport['id'] }) => {
    const { actions, report, isFilterPanelExpanded, toggleFilters } = this.props;

    if (report && values.reportId === report.id) {
      // The current report has been reselected - don't refresh.
      return;
    }

    actions.loadReport({ reportId: values.reportId });

    if (isFilterPanelExpanded) {
      toggleFilters();
    }
  };

  handleCreateReport = () => {
    const { actions, screen, screenType } = this.props;

    actions.initReportDetailsModal({
      section: screen,
      sectionType: screenType,
      reportDetailsModalMode: ReportDetailsModalMode.CREATE,
    });
  };

  handleSaveReport = () => {
    const { actions, report, screen, screenType } = this.props;

    actions.saveReport({ report, section: screen, sectionType: screenType });
  };

  handleShareReport = () => {
    const { actions, report } = this.props;

    actions.initReportDetailsModal({
      reportId: report.id,
      reportDetailsModalMode: ReportDetailsModalMode.EDIT,
    });
  };

  handleFavoriteReport = () => {
    const { actions, report } = this.props;

    actions.favoriteReport({
      reportId: report.id,
      isFavorite: !report.isFavorite,
      showToast: true,
    });
  };

  handleReset = () => {
    const { actions, screen, screenType } = this.props;

    actions.reset({
      section: screen,
      sectionType: screenType,
      query: {},
    });
  };

  render() {
    const { report, isFilterPanelExpanded, toggleFilters, filterCount, canSaveReport } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{ reportId: report ? report.id : null }}
        isInitialValid={false}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={this.handleLoadReport}
        render={({
          handleReset: handleFormikReset,
        }: FormikProps<{ reportId: UserReport['id'] }>) => (
          <DatalabToolbarForm
            report={report}
            onReset={() => {
              handleFormikReset();
              this.handleReset();
            }}
            toggleFilters={toggleFilters}
            isFilterPanelExpanded={isFilterPanelExpanded}
            filterCount={filterCount}
            canSaveReport={canSaveReport}
            onLoadReport={this.handleLoadReport}
            onCreateReport={this.handleCreateReport}
            onSaveReport={this.handleSaveReport}
            onShareReport={this.handleShareReport}
            onFavoriteReport={this.handleFavoriteReport}
          />
        )}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatalabToolbar);
