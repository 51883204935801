import styled from 'styled-components/macro';

export const SNotificationsIconWrapper = styled.div`
  padding-right: 25px;
  font-size: 24px;
`;

export const SBadgeIconWrapper = styled.div`
  position: relative;
  vertical-align: middle;
`;

export const SBadge = styled.div`
  background-color: ${({ theme }) => theme.color.red1};
  color: ${({ theme }) => theme.text.color.white};
  font-size: ${({ theme }) => theme.text.size.small};
  position: absolute;
  left: 50%;
  top: -3px;
  padding: 0 6px;
  border-radius: 10px;
  line-height: 16px;
  height: 16px;
`;
