export enum ReportTableFieldName {
  REPORT_NAME = 'name',
  LAST_MODIFIED_BY = 'last_modified_by_user',
  LAST_MODIFIED_ON = 'last_modified',
  MEMBERS = 'members',
  REPORT_CONTROLS = 'report_controls',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

const ColumnMap = {
  REPORT_NAME: {
    label: 'Report',
    fieldName: ReportTableFieldName.REPORT_NAME,
    isSortable: true,
  },
  LAST_MODIFIED_BY: {
    label: 'Last Modified By',
    fieldName: ReportTableFieldName.LAST_MODIFIED_BY,
    isSortable: false,
  },
  LAST_MODIFIED_ON: {
    label: 'Last Modified on',
    fieldName: ReportTableFieldName.LAST_MODIFIED_ON,
    isSortable: true,
  },
  MEMBERS: {
    label: 'Members',
    fieldName: ReportTableFieldName.MEMBERS,
    isSortable: false,
  },
  REPORT_CONTROLS: {
    label: null,
    fieldName: ReportTableFieldName.REPORT_CONTROLS,
    isSortable: false,
  },
};

export const columns = [
  ColumnMap.REPORT_NAME,
  ColumnMap.LAST_MODIFIED_BY,
  ColumnMap.LAST_MODIFIED_ON,
  ColumnMap.MEMBERS,
  ColumnMap.REPORT_CONTROLS,
];
