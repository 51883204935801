import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  SxProps,
  Theme,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import OfferingDetailRowItem from './OfferingDetailRowItem';

export const cardHeight: SxProps<Theme> = { height: '100%', paddingTop: 0 };
export const cardTitleStyle: SxProps<Theme> = { fontWeight: 500 };
export const cardHeaderStyle: SxProps<Theme> = {
  padding: '10px 0 0 16px',
};
export const cardContentStyle: SxProps<Theme> = {
  marginBottom: '5px',
  '&:last-child': { paddingBottom: 0 },
};
export const firstDividerStyle: SxProps<Theme> = {
  border: 1,
  marginTop: 0.5,
  paddingX: 0,
  color: theme => theme.palette.divider,
};
export const dividerStyle: SxProps<Theme> = {
  border: 1,
  marginTop: 3,
  paddingX: 0,
  color: theme => theme.palette.divider,
};
export const dividerBottomStyle: SxProps<Theme> = {
  borderBottom: 1,
  marginTop: '5px',
  color: theme => theme.palette.divider,
};
export type Props = {
  title: string;
  dataDescription?: string;
  rowData: {
    name: string;
    value: string | React.ReactNode;
  }[];
  cardSizes?: RegularBreakpoints;
};

const OfferingProfileCardItem: React.FC<Props> = ({
  title,
  dataDescription,
  rowData,
  cardSizes,
}) => {
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {dataDescription && (
            <Typography paddingY={0.5} variant="body1">
              {dataDescription}
            </Typography>
          )}
          {rowData.map(({ name, value }) => {
            return <OfferingDetailRowItem key={name} label={name} value={value} />;
          })}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OfferingProfileCardItem;
