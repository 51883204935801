import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { DatalabScreens } from '../../constants';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  {
    ...dtc.underwriter_name,
    label: 'Manager',
    renderer: (v, row) => (
      <Link
        to={routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.BROKER_PL_OFFERINGS,
          type: 'table',
          query: {
            underwriter: row.id,
          },
        })}
      >
        {v}
      </Link>
    ),
  },
  dtc.offerings,
  dtc.underwritten_volume,
  {
    ...dtc.allocation_investment,
    label: 'Total Investment',
  },
  dtc.estimated_commission,
  dtc.return_open_volume,
  dtc.return_open,
  dtc.return_vwap_1day_volume,
  dtc.return_vwap_1day,
  dtc.return_1day_volume,
  dtc.return_1day,
  dtc.return_3day_volume,
  dtc.return_3day,
  dtc.return_7day_volume,
  dtc.return_7day,
  dtc.return_prior_quarter_volume,
  dtc.return_prior_quarter,
  dtc.return_current_volume,
  dtc.return_current,
];

export const visibleFields: string[] = [
  dtc.underwriter_name.field,
  dtc.offerings.field,
  dtc.underwritten_volume.field,
  dtc.allocation_investment.field,
  dtc.estimated_commission.field,
  dtc.return_open_volume.field,
  dtc.return_open.field,
  dtc.return_vwap_1day_volume.field,
  dtc.return_vwap_1day.field,
  dtc.return_1day_volume.field,
  dtc.return_1day.field,
  dtc.return_3day_volume.field,
  dtc.return_3day.field,
  dtc.return_7day_volume.field,
  dtc.return_7day.field,
  dtc.return_prior_quarter_volume.field,
  dtc.return_prior_quarter.field,
  dtc.return_current_volume.field,
  dtc.return_current.field,
];

export const staticFields: string[] = [dtc.underwriter_name.field];
