import { TraitCode } from '@capital-markets-gateway/api-client-identity';
import { checkPermissions, getUserPermissions, permissionsByEntity, useAuth } from '@cmg/auth';

export function useCustomSectorsVisibility() {
  const canReadCustomSectors = checkPermissions(getUserPermissions(), [
    permissionsByEntity.CustomSectors.READ,
  ]);

  return { canReadCustomSectors };
}
export function useSellingRestrictionVisibility() {
  const { oidcUserAccountTraits } = useAuth();

  const hasConvertsPerm = checkPermissions(getUserPermissions(), [
    permissionsByEntity.ConvertsOffering.READ,
  ]);
  const hasConvertsTrait = oidcUserAccountTraits.includes(TraitCode.CONVERTS);

  const hasIntlPerm = checkPermissions(getUserPermissions(), [
    permissionsByEntity.InternationalOffering.READ,
  ]);
  const hasIntlTrait = oidcUserAccountTraits.includes(TraitCode.INTERNATIONAL);

  return {
    canReadConverts: hasConvertsPerm && hasConvertsTrait,
    canReadIntl: hasIntlPerm && hasIntlTrait,
  };
}
