import { PrimaryButton, SecondaryButton } from '@cmg/common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SRadioButton } from './RadioButton.styles';

export default class RadioButton extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    testId: PropTypes.string,
  };

  flip = () => {
    this.props.onChange(this.props.id);
  };

  render() {
    const { label, active, className, testId } = this.props;

    const Button = active ? PrimaryButton : SecondaryButton;

    return (
      <SRadioButton className={className}>
        <Button onClick={this.flip} testId={testId}>
          {label}
        </Button>
      </SRadioButton>
    );
  }
}
