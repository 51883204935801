import { Box, numericUtil } from '@cmg/common';
import { widgetOfferingVolumeBySector } from '@cmg/e2e-selectors';
import React, { useMemo, useState } from 'react';

import {
  CustomLegend,
  DoughnutChart,
  DoughnutChartProps,
} from '../../../../../../common/components/charts';
import { orderAscBySelector } from '../../../../../../obsolete/utils/helpers';
import Toggler from '../../../../../shared/layout/components/Toggler';
import { getSectorColor, noSectorAsLast } from '../../../../colors';
import underwrittenValueAmountButtons from '../../../../model/buttons/underwritten-value-amount-buttons';
import { ValueAmountButtons } from '../../../../model/buttons/value-amount-buttons';
import { SButtonBox, SChart, SDatalabChart, SDoughnut } from '../../shared/styled';

type Props = {
  chartData?: {
    label: string;
    color_code: string | null;
    values: {
      value: number;
      underwritten_value: number;
      amount: number;
    };
  }[];
  title: string;
};

function transformData(
  chartData: Props['chartData'] = [],
  valueType: string
): DoughnutChartProps['data'] {
  const sorted = noSectorAsLast(chartData.sort(orderAscBySelector.bind(null, item => item.label)));

  return {
    labels: sorted.map(item => item.label),
    datasets: [
      {
        data: sorted.map(item => item.values[valueType]),
        backgroundColor: sorted.map(item => getSectorColor(item)),
      },
    ],
  };
}

function getOptions(valueType: string): DoughnutChartProps['options'] {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.parsed;
            const total = tooltipItem.dataset.data.reduce(
              (prevTotal: number, curValue: number) => prevTotal + curValue
            );
            const formattedValue =
              valueType === ValueAmountButtons.AMOUNT
                ? tooltipItem.parsed
                : numericUtil.formatCurrencyInBillions(tooltipItem.parsed);
            return `${formattedValue} (${numericUtil.formatPercents(value / total, 1)})`;
          },
        },
      },
    },
  };
}

export default function WidgetOfferingVolumeBySector({ chartData = [], title }: Props) {
  const [activeBtn, setActiveBtn] = useState(underwrittenValueAmountButtons[0]);

  const transformedData = useMemo(
    () => transformData(chartData, activeBtn.value),
    [chartData, activeBtn.value]
  );

  const options = useMemo(() => getOptions(activeBtn.value), [activeBtn.value]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart data-test-id={widgetOfferingVolumeBySector.testId}>
      <Box.Header title={title} />

      <SButtonBox>
        <Toggler
          activeButton={activeBtn}
          onChange={handleButtonChange}
          buttons={underwrittenValueAmountButtons}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SDoughnut>
          <SChart>
            <DoughnutChart data={transformedData} options={options} />
          </SChart>

          <CustomLegend
            chartData={transformedData}
            chartType="doughnut"
            valueFormatter={
              activeBtn.value === ValueAmountButtons.AMOUNT
                ? undefined
                : numericUtil.formatCurrencyInBillions
            }
          />
        </SDoughnut>
      </Box.Content>
    </SDatalabChart>
  );
}
