import { Icon } from '@cmg/common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SummaryHeaderItem from '../shared/SummaryHeaderItem';
import {
  getVisibleSubColumns,
  hasCompositeHeader,
  hasSummaryHeader,
  isComposite,
} from '../shared/table-helpers';
import {
  SColLabel,
  SCompositeLabel,
  SOrderArrow,
  SOrderingColLabel,
  SSubHeaders,
  SSummaryHeaders,
  STableHeader,
} from './Header.styles';

export default class Header extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderByType: PropTypes.string.isRequired,
    handleOrderBy: PropTypes.func.isRequired,
    showRanking: PropTypes.bool,
    summaries: PropTypes.object,
  };

  renderHeader(column, rowSpan) {
    const { orderBy, handleOrderBy, orderByType } = this.props;

    return (
      <STableHeader
        key={column.field}
        textAlign={column.textAlign}
        className={column.headerClass}
        rowSpan={rowSpan}
      >
        {!column.disableSort && (
          <SOrderingColLabel onClick={() => handleOrderBy(column.field)}>
            {column.label}
            {orderBy === column.field && (
              <SOrderArrow>
                <Icon name={`sort-amount-${orderByType === 'asc' ? 'down' : 'up'}`} />
              </SOrderArrow>
            )}
          </SOrderingColLabel>
        )}
        {column.headerRenderer != null && column.headerRenderer()}
        {column.disableSort && !column.headerRenderer && <SColLabel> {column.label} </SColLabel>}
      </STableHeader>
    );
  }

  renderCompositeHeader(compositeColumn) {
    const visibleSubColumns = getVisibleSubColumns(compositeColumn);

    if (!visibleSubColumns.length) {
      return null;
    }

    return (
      <SCompositeLabel key={compositeColumn.field} colSpan={visibleSubColumns.length}>
        {compositeColumn.label}
      </SCompositeLabel>
    );
  }

  renderColumnHeader(column) {
    const { columns } = this.props;

    if (isComposite(column)) {
      return this.renderCompositeHeader(column);
    }

    if (!column.visible) {
      return null;
    }

    return this.renderHeader(column, hasCompositeHeader(columns) ? 2 : undefined);
  }

  renderSummaryHeader = column => {
    if (!column.visible) {
      return null;
    }

    if (!column.summaryConfig) {
      return <th key={`summary-${column.field}`} />;
    }

    const { summaries } = this.props;

    return (
      <STableHeader key={`summary-${column.field}`} textAlign={column.textAlign}>
        <SummaryHeaderItem
          columnConfig={column}
          summaryValues={summaries ? summaries[column.field] : null}
        />
      </STableHeader>
    );
  };

  render() {
    const { columns, showRanking } = this.props;

    return (
      <thead>
        <tr>
          {showRanking && <th />}
          {columns.map(column => this.renderColumnHeader(column))}
        </tr>
        {hasCompositeHeader(columns) && (
          <SSubHeaders>
            {showRanking && <th>Rank</th>}
            {columns.map(column => {
              if (!isComposite(column)) {
                return null;
              }

              return column.subColumns.map(subColumn => {
                if (!subColumn.visible) {
                  return null;
                }

                return this.renderHeader(subColumn);
              });
            })}
          </SSubHeaders>
        )}
        {hasSummaryHeader(columns) && (
          <SSummaryHeaders>
            {showRanking && <th />}
            {columns.map(column => this.renderSummaryHeader(column))}
          </SSummaryHeaders>
        )}
      </thead>
    );
  }
}
