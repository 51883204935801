import styled, { css } from 'styled-components/macro';

type PillType =
  | 'default'
  | 'primary'
  | 'medium-blue'
  | 'dark-blue'
  | 'danger'
  | 'warning'
  | 'success'
  | 'info';

export const SPill = styled.span<{ withUppercase?: boolean; type?: PillType }>`
  ${({ theme, type, withUppercase }) => css`
    color: ${theme.text.color.white};
    padding: 4px 14px;
    border-radius: 12px;
    white-space: nowrap;

    ${withUppercase &&
    css`
      text-transform: uppercase;
    `}

    ${type === 'default' &&
    css`
      background-color: ${theme.background.color.menu};
    `}

  ${type === 'primary' &&
    css`
      background: ${theme.brand.color.light};
    `}

  ${type === 'medium-blue' &&
    css`
      background: ${theme.color.blue13};
    `};

    ${type === 'dark-blue' &&
    css`
      /* Used to be #0277bd */
      background: ${theme.color.blue14};
    `};

    ${type === 'danger' &&
    css`
      background-color: ${theme.background.color.negative};
    `}

    ${type === 'warning' &&
    css`
      background-color: ${theme.brand.color.warning};
    `}

    ${type === 'success' &&
    css`
      background-color: ${theme.brand.color.success};
    `}

    ${type === 'info' &&
    css`
      background-color: ${theme.brand.color.light};
    `}
  `};
`;
