import { UUID } from '@cmg/api';
import { apiTypes, DateRangePresetTypes } from '@cmg/common';

import { AverageMethod } from './averageMethod';

export type DashboardParametersBase = {
  /** Current time period */
  timePeriod: DateRangeControl;
  /** Comparison time period will be previous to timePeriod  */
  comparisonPeriod: DateRangeControl;
  /** Predefined CMG sector ids */
  sectorCodes: string[];
  /** Client defined custom sector ids */
  customSectorIds: string[];
  /** Offering size minimum */
  offeringSizeMin?: number;
  /** Offering size maximum */
  offeringSizeMax?: number;
  /** Performance Metric calculated value */
  performanceMetric: PerformanceMetric;
  /** Average Method for performance metric calculation */
  averageMethod: AverageMethod;
};

export enum LeagueTableOrderField {
  UNDERWRITER_NAME = 'UNDERWRITER_NAME',
  NUMBER_OF_OFFERINGS = 'NUMBER_OF_OFFERINGS',
  TOTAL_FEES = 'TOTAL_FEES',
  WALLET_SHARE = 'WALLET_SHARE',
}

export type LeagueTableParams = {
  underwritersIds: UUID[];
  orderField: LeagueTableOrderField;
  sortOrderDirection: apiTypes.SortDirection;
};

/**
 * my dashboard buy-side request parameters
 */
export type BuySideDashboardParameters = DashboardParametersBase;
/**
 * my dashboard sell-side request parameters
 */
export type SellSideDashboardParameters = DashboardParametersBase & {
  leagueTableParams: LeagueTableParams;
};

/**
 * top controls includes all the controls except for time and comparison periods
 */
export type MyDashboardTopControls = Omit<
  BuySideDashboardParameters,
  'timePeriod' | 'comparisonPeriod'
>;

export type DateRangeControl = {
  startDate?: string;
  endDate?: string;
  dateType: DateRangePresetTypes;
};

export enum PerformanceMetric {
  OFFER_TO_OPEN = 'offerToOpen',
  OFFER_TO_1_DAY = 'offerTo1Day',
  OFFER_TO_7_DAY = 'offerTo7Day',
  OFFER_TO_14_DAY = 'offerTo14Day',
  OFFER_TO_30_DAY = 'offerTo30Day',
  OFFER_TO_90_DAY = 'offerTo90Day',
  OFFER_TO_180_DAY = 'offerTo180Day',
  OFFER_TO_ONE_YEAR = 'offerToOneYear',
  OFFER_TO_CURRENT = 'offerToCurrent',
}
