import { Modal, ModalContent } from '@cmg/common';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import HubspotForm from './components/hubspot-form/HubspotForm';

type OwnProps = {
  title?: string;
};

export type Props = InjectedProps & OwnProps;

/**
 * Modal window component to send feedback message to CMG support.
 */
export const SupportRequestModalComponent: React.FC<Props> = ({
  show,
  handleHide,
  title = 'Please let us know of any questions or comments',
}) => (
  <Modal show={show} title={title} closeButton onHide={handleHide}>
    <ModalContent>
      <HubspotForm />
    </ModalContent>
  </Modal>
);

const name = 'SUPPORT/SUPPORT_REQUEST_MODAL';
export const openSupportRequestModal = (props: OwnProps) => show(name, props);
export const closeSupportRequestModal = () => hide(name);

export default connectModal({ name })(SupportRequestModalComponent);
