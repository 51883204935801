import { numericUtil } from '@cmg/common';
import React from 'react';

import PerformanceValue from '../performance-value/PerformanceValue';

export type Props = {
  value?: number | null;
  nullValue?: string;
};

/**
 * Formats a currency value and add css classes to colorize the result
 */
const PerformanceCurrency: React.FC<Props> = ({ value, nullValue }) => (
  <PerformanceValue
    value={value}
    nullValue={nullValue}
    formatter={value => numericUtil.formatCurrency(value)}
  />
);

export default PerformanceCurrency;
