import { createFormikField, FieldControlProps } from '@cmg/common';
import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

import { AverageMethod } from '../../../../../../types/domain/my-dashboard/averageMethod';
import Toggler from '../../../../../shared/layout/components/Toggler';
import { TogglerButton } from '../../../../../shared/layout/components/Toggler.types';

/** export for testing */
export const StyledToggler = styled(Toggler)`
  display: flex;
`;
const buttonOptions: TogglerButton<AverageMethod>[] = [
  { value: AverageMethod.MEAN, label: 'MEAN' },
  { value: AverageMethod.MEDIAN, label: 'MEDIAN' },
  { value: AverageMethod.WEIGHTED, label: '$ WEIGHTED' },
];

type OwnProps = {
  onChange?: (value: AverageMethod) => void;
  value: AverageMethod;
};

type Props = OwnProps & FieldControlProps<AverageMethod, AverageMethod>;

/**
 * Displays a toggler allowing for selection of a single 'Average Method'.
 *
 */
const AverageMethodControl: React.FC<Props> = ({ onChange, value }) => {
  const handleChange = useCallback(
    (selectedButton: TogglerButton) => {
      onChange && onChange(selectedButton.value as AverageMethod);
    },
    [onChange]
  );

  return (
    <StyledToggler
      buttons={buttonOptions}
      activeButton={buttonOptions.find(bo => bo.value === value)}
      onChange={handleChange}
      size="medium"
    />
  );
};

export const AverageMethodControlField = createFormikField<
  AverageMethod,
  AverageMethod,
  HTMLInputElement,
  Props
>(AverageMethodControl);

export default AverageMethodControl;
