import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum HeatMapPerformanceButtons {
  MEAN = 'mean',
  MEDIAN = 'median',
  WEIGHTED = 'weighted',
}

export const heatMapPerformanceButtonsConfig: ButtonsConfig = {
  [HeatMapPerformanceButtons.MEAN]: { label: 'MEAN' },
  [HeatMapPerformanceButtons.MEDIAN]: { label: 'MEDIAN' },
  [HeatMapPerformanceButtons.WEIGHTED]: { label: '$ WEIGHTED' },
};

export default getButtonConfigArray(heatMapPerformanceButtonsConfig);
