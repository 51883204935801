import { Box } from '@cmg/common';
import { useEffect, useState } from 'react';

import type { CompanyProfile_Issuer_FieldsFragment } from '../../graphql/__generated__/CompanyProfile';
import CompanyProfileTab from './components/CompanyProfileTab';
import MorningstarLogo from './components/MorningstarLogo';
import PricePerformanceTab from './components/PricePerformanceTab';

type CompanyTabs = {
  value: string;
  label: string;
};

export const companyTabs: CompanyTabs[] = [
  {
    value: 'PRICE_PERFORMANCE',
    label: 'Price & Performance',
  },
  { value: 'COMPANY_PROFILE', label: 'Company Profile' },
];

const getAvailableTabs = (isDelisted: boolean | null | undefined) =>
  isDelisted ? [companyTabs[1]] : companyTabs;

export type Props = {
  company: CompanyProfile_Issuer_FieldsFragment | null | undefined;
};

const CompanyInfo: React.FC<Props> = ({ company }) => {
  const availableTabs = getAvailableTabs(company?.isDelisted);
  const [activeTab, setActiveTab] = useState<CompanyTabs>(availableTabs[0]);

  useEffect(() => {
    setActiveTab(getAvailableTabs(company?.isDelisted)[0]);
  }, [company?.isDelisted]);

  return (
    <Box>
      <Box.Header tabs={availableTabs} activeTab={activeTab} onChangeTab={setActiveTab}>
        {company?.profile?.cik && <MorningstarLogo />}
      </Box.Header>

      <Box.Content>
        {company?.stockProfile && activeTab.value === companyTabs[0].value && (
          <PricePerformanceTab stockProfile={company.stockProfile} />
        )}
        {company?.profile && activeTab.value === companyTabs[1].value && (
          <CompanyProfileTab company={company} />
        )}
      </Box.Content>
    </Box>
  );
};

export default CompanyInfo;
