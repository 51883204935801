import { Column, Row } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { FollowOnPricingDetail as FollowOnPricingDetailType } from '../../../../../types/domain/my-dashboard/followOnPricingSummary';
import CurrencyValue from '../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../common/dashboard-value/numeric-value/NumericValue';
import PerformancePercentsValue from '../../../common/dashboard-value/performance-percents-value/PerformancePercentsValue';
import { DetailTitle } from '../../common/DashboardPeriod';

const SFollowOnPricingDetail = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
`;
const StyledColumn = styled(Column)`
  margin: 3px 0;
`;

type Props = {
  data?: FollowOnPricingDetailType;
  label: string;
};

const FollowOnPricingDetail: React.FC<Props> = ({ label, data }) => {
  return (
    <SFollowOnPricingDetail>
      <StyledColumn sm={6}>
        <DetailTitle>{label}</DetailTitle>
      </StyledColumn>
      <StyledColumn sm={4}>
        <NumericValue value={data?.numberOfOfferings} />
      </StyledColumn>
      <StyledColumn sm={4}>
        <CurrencyValue value={data?.capitalRaised} />
      </StyledColumn>
      <StyledColumn sm={4}>
        <PerformancePercentsValue value={data?.fileToOffer} />
      </StyledColumn>
      <StyledColumn sm={4}>
        <PerformancePercentsValue value={data?.performance} />
      </StyledColumn>
    </SFollowOnPricingDetail>
  );
};

export default FollowOnPricingDetail;
