import { timeUtil } from '@cmg/common';
import isUndefined from 'lodash/isUndefined';
import React from 'react';

import { LockUpUnit } from '../../../../../../graphql/__generated__';
import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { CardRowItem, emptyValue, OfferingLockupsFieldName } from '../types';

export const useGetLockupsData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { attributes, lockUps } = offeringProfile.offeringById ?? {};
    const lockUp = lockUps && lockUps.length > 0 ? lockUps[0] : null;
    const { lockUpPeriod, isConditionalLockUp, hasMultipleLockUps } = attributes ?? {};

    const { earlyReleaseDate, expirationDate, period, unit, unitDisplayName } = lockUp ?? {};
    const periodInMonths =
      unit === LockUpUnit.Months && !isUndefined(period) ? `${period} ${unitDisplayName}/` : '';

    return [
      {
        name: OfferingLockupsFieldName.Period,
        value: lockUpPeriod ? `${periodInMonths}${lockUpPeriod} Days` : emptyValue,
      },
      {
        name: OfferingLockupsFieldName.ExpDate,
        value: timeUtil.formatAsMonthDateYear(expirationDate ?? '') ?? emptyValue,
      },
      {
        name: OfferingLockupsFieldName.EarlyRelease,
        value: timeUtil.formatAsMonthDateYear(earlyReleaseDate ?? '') ?? emptyValue,
      },
      {
        name: OfferingLockupsFieldName.ConditionalLockups,
        value: <BooleanIcon value={isConditionalLockUp} />,
      },
      {
        name: OfferingLockupsFieldName.MultipleLockups,
        value: <BooleanIcon value={hasMultipleLockUps} />,
      },
    ];
  }, [offeringProfile.offeringById]);
