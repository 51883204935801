/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_LeftLeads_ManagerPartsFragment = {
  __typename?: 'Manager';
  id: string;
  name?: string | null;
};

export type OfferingsFilterForm_LeftLeadsQueryVariables = Types.Exact<{
  order?: Types.InputMaybe<Array<Types.ManagerSortInput> | Types.ManagerSortInput>;
  where?: Types.InputMaybe<Types.ManagerFilterInput>;
}>;

export type OfferingsFilterForm_LeftLeadsQuery = {
  __typename?: 'Query';
  managers?: {
    __typename?: 'ManagerCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Manager'; id: string; name?: string | null }> | null;
  } | null;
};

export const OfferingsFilterForm_LeftLeads_ManagerPartsFragmentDoc = gql`
  fragment OfferingsFilterForm_LeftLeads_ManagerParts on Manager {
    id
    name
  }
`;
export const OfferingsFilterForm_LeftLeadsDocument = gql`
  query OfferingsFilterForm_LeftLeads($order: [ManagerSortInput!], $where: ManagerFilterInput) {
    managers(order: $order, where: $where) {
      items {
        ...OfferingsFilterForm_LeftLeads_ManagerParts
      }
      totalCount
    }
  }
  ${OfferingsFilterForm_LeftLeads_ManagerPartsFragmentDoc}
`;

/**
 * __useOfferingsFilterForm_LeftLeadsQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_LeftLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_LeftLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_LeftLeadsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOfferingsFilterForm_LeftLeadsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_LeftLeadsQuery,
    OfferingsFilterForm_LeftLeadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_LeftLeadsQuery,
    OfferingsFilterForm_LeftLeadsQueryVariables
  >(OfferingsFilterForm_LeftLeadsDocument, options);
}
export function useOfferingsFilterForm_LeftLeadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_LeftLeadsQuery,
    OfferingsFilterForm_LeftLeadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_LeftLeadsQuery,
    OfferingsFilterForm_LeftLeadsQueryVariables
  >(OfferingsFilterForm_LeftLeadsDocument, options);
}
export function useOfferingsFilterForm_LeftLeadsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingsFilterForm_LeftLeadsQuery,
        OfferingsFilterForm_LeftLeadsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingsFilterForm_LeftLeadsQuery,
    OfferingsFilterForm_LeftLeadsQueryVariables
  >(OfferingsFilterForm_LeftLeadsDocument, options);
}
export type OfferingsFilterForm_LeftLeadsQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_LeftLeadsQuery
>;
export type OfferingsFilterForm_LeftLeadsLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_LeftLeadsLazyQuery
>;
export type OfferingsFilterForm_LeftLeadsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_LeftLeadsSuspenseQuery
>;
export type OfferingsFilterForm_LeftLeadsQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_LeftLeadsQuery,
  OfferingsFilterForm_LeftLeadsQueryVariables
>;
