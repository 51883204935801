import { AccountType, getUserAccountType } from '@cmg/auth';
import { apiTypes, duckPartFactory, mixpanelUtil, reduxUtil } from '@cmg/common';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import * as dlgwApi from '../../../../api/dlgw/datalabGatewayApi';
import { RootState } from '../../../../common/redux/rootReducer';
import {
  LeagueTableOrderField,
  LeagueTableParams,
  SellSideDashboardParameters,
} from '../../../../types/domain/my-dashboard/myDashboardParameters';
import { SellSideDashboardDto } from '../../../../types/domain/my-dashboard/sellSideDashboard';
import { selectUserSettingUseCustomSectors } from '../../../shared/ducks';
import * as DashboardControls from '../../dashboard/controls/ducks';

const { createMixPanelAction } = mixpanelUtil;

/**
 * processMixPanelControlsPayload flattens the date periods of the dashboard parameters
 * and adds flags for whether or not sectors/custom sectors were entered.
 *
 * This will allow us to filter by individual fields in mixpanel
 */
export const processMixPanelControlsPayload = (payload: SellSideDashboardParameters) => {
  const { timePeriod, comparisonPeriod, leagueTableParams, ...rest } = payload;
  const flatObj = (obj, parentKey) =>
    Object.keys(obj).reduce(
      (total, current) => ({
        ...total,
        [camelCase(`${parentKey} ${current}`)]: obj[current],
      }),
      {}
    );
  return {
    ...rest,
    ...flatObj(payload.timePeriod, 'timePeriod'),
    ...flatObj(payload.comparisonPeriod, 'comparisonPeriod'),
    ...flatObj(payload.leagueTableParams, 'leagueTableParams'),
    hasLeagueTableUnderwritersIds: payload.leagueTableParams.underwritersIds.length > 0,
    hasSectors: payload.sectorCodes.length > 0,
    hasCustomSectors: payload.customSectorIds.length > 0,
  };
};

export const initialLeagueTableParams: LeagueTableParams = {
  orderField: LeagueTableOrderField.WALLET_SHARE,
  sortOrderDirection: apiTypes.SortDirection.DESC,
  underwritersIds: [],
};

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  INIT_MY_DASHBOARD = 'MY_DASHBOARD/INIT_SELL_SIDE_DASHBOARD',
  LEAGUE_TABLE_UPDATED = 'MY_DASHBOARD/SELL_SIDE_LEAGUE_TABLE_UPDATED',
}

/**
 * DUCK PARTS DEFINITIONS
 */
export const {
  actionCreators: {
    request: fetchSellSideDashboardRequest,
    success: fetchSellSideDashboardSucceeded,
    failure: fetchSellSideDashboardFailed,
  },
  initialState: sellSideDashboardInitialState,
  reducer: sellSideDashboardReducer,
  actionTypes: {
    REQUEST: fetchSellSideDashboardRequestType,
    SUCCESS: fetchSellSideDashboardSuccessType,
    FAILURE: fetchSellSideDashboardFailureType,
  },
  makeSelectors: makeSellSideDashboardSelectors,
} = duckPartFactory.makeAPIDuckParts<undefined, SellSideDashboardDto>({
  prefix: 'MY_DASHBOARD/FETCH_SELL_SIDE_DASHBOARD',
});

/**
 * ACTIONS
 */
export type FetchSellSideDashboardAction = ReturnType<typeof fetchSellSideDashboardRequest>;

export const leagueTableUpdated = (payload: LeagueTableParams) => ({
  type: ActionTypes.LEAGUE_TABLE_UPDATED,
  payload,
});
export type LeagueTableUpdatedAction = ReturnType<typeof leagueTableUpdated>;

/**
 * this action is triggered upon entering individual datalab screens
 * corresponding saga checks if filter values should be persisted or reset
 */
export const initMyDashboard = () => ({
  type: ActionTypes.INIT_MY_DASHBOARD,
});
export type InitMyDashboardAction = ReturnType<typeof initMyDashboard>;

type Actions = {
  [ActionTypes.INIT_MY_DASHBOARD]: InitMyDashboardAction;
  [ActionTypes.LEAGUE_TABLE_UPDATED]: LeagueTableUpdatedAction;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  /** sell side dashboard offerings */
  offerings: typeof sellSideDashboardInitialState;

  /** league table params only used for sell-side dashboard */
  leagueTableParams: LeagueTableParams;

  /** league table params changed loading */
  leagueTableLoading: boolean;
};

export const initialState: ReducerState = {
  offerings: sellSideDashboardInitialState,
  leagueTableParams: initialLeagueTableParams,
  leagueTableLoading: false,
};

const updateLeagueTableReducer = createReducer<ReducerState['leagueTableParams'], Actions>(
  initialState.leagueTableParams,
  {
    [ActionTypes.LEAGUE_TABLE_UPDATED]: (curState, { payload }) => payload,
  }
);

/**
 * leagueTableLoadingReducer changes the loading state based on whether the league table params changes
 * and sets back to false when the request was either successful or failure.
 * This allows us to only re-render the league instead of the entire dashboard upon changing any related league table
 * parameters
 */
export const leagueTableLoadingReducer = createReducer<ReducerState['leagueTableLoading'], Actions>(
  initialState.leagueTableLoading,
  {
    [ActionTypes.LEAGUE_TABLE_UPDATED]: () => true,
    [fetchSellSideDashboardSuccessType]: () => false,
    [fetchSellSideDashboardFailureType]: () => false,
  }
);

export const reducer = combineReducers<ReducerState>({
  offerings: sellSideDashboardReducer,
  leagueTableParams: updateLeagueTableReducer,
  leagueTableLoading: leagueTableLoadingReducer,
});

export default reducer;

/**
 * Selectors
 */
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, (a, b) => isEqual(a, b));
export const selectMyDashboard = (state: RootState) => state.myDashboard;
export const selectSellSideDashboard = (state: RootState) =>
  selectMyDashboard(state).sellSideDashboard;

/** SellSide Dashboard Offerings */

export const selectAllControls = createDeepEqualSelector(
  (state: RootState) => ({
    ...DashboardControls.selectTopControls(state),
    timePeriod: DashboardControls.selectTimePeriodControl(state),
    comparisonPeriod: DashboardControls.selectComparisonPeriodControl(state),
    leagueTableParams: selectSellSideDashboard(state).leagueTableParams,
  }),
  allControls => allControls as SellSideDashboardParameters
);

export const selectLeagueTableParams = (state: RootState) =>
  selectSellSideDashboard(state).leagueTableParams;
export const selectLeagueTableLoading = (state: RootState) =>
  selectSellSideDashboard(state).leagueTableLoading;

export const selectSellSideOfferings = (state: RootState) =>
  selectSellSideDashboard(state).offerings;

const sellSideSelectors = makeSellSideDashboardSelectors(selectSellSideOfferings);
export const selectSellSideData = sellSideSelectors.selectData;
export const selectSellSideLoading = sellSideSelectors.selectLoading;
export const selectSellSideError = sellSideSelectors.selectError;

/** Periods */
export const selectSellSideCurrentPeriod = (state: RootState) => selectSellSideData(state)?.current;

export const selectSellSideComparisonPeriod = (state: RootState) =>
  selectSellSideData(state)?.comparison;

/**  US Ecm */
export const selectSellSideCurrentUsEcm = state => selectSellSideCurrentPeriod(state)?.usEcm;

export const selectSellSideComparisonUsEcm = state => selectSellSideComparisonPeriod(state)?.usEcm;

/** League Table */
export const selectSellSideCurrentLeagueTable = state =>
  selectSellSideCurrentPeriod(state)?.leagueTable.rows;

export const selectSellSideComparisonLeagueTable = state =>
  selectSellSideComparisonPeriod(state)?.leagueTable.rows;

/** IPO Pricing  Summary */
export const selectSellSideCurrentIpoPricingSummary = state =>
  selectSellSideCurrentPeriod(state)?.ipoPricingSummary;

export const selectSellSideComparisonIpoPricingSummary = state =>
  selectSellSideComparisonPeriod(state)?.ipoPricingSummary;

/** Follow  On PricingSummary */
export const selectSellSideCurrentFollowOnPricingSummary = state =>
  selectSellSideCurrentPeriod(state)?.followOnPricingSummary;

export const selectSellSideComparisonFollowOnPricingSummary = state =>
  selectSellSideComparisonPeriod(state)?.followOnPricingSummary;

/**
 * SAGAS
 */
/** Fetch sell-side dashboard saga */
export function* fetchSellSideDashboardSaga(): SagaIterator {
  const accountType = getUserAccountType();
  if (accountType !== AccountType.SELL_SIDE) {
    return;
  }

  const allControls = yield select(selectAllControls);

  yield put(
    createMixPanelAction(
      fetchSellSideDashboardRequestType,
      'Fetch Sell-Side Dashboard Offerings',
      processMixPanelControlsPayload(allControls)
    )
  );

  const resp: dlgwApi.FetchSellSideDashboardResponse = yield call(
    dlgwApi.fetchSellSideDashboard,
    allControls
  );

  if (resp.ok) {
    yield put(fetchSellSideDashboardSucceeded(resp.data));
  } else {
    yield put(fetchSellSideDashboardFailed(resp.data.error));
  }
}

export function* controlsUpdatedSaga(): SagaIterator {
  yield put(fetchSellSideDashboardRequest(undefined));
}

/**
 * initMyDashboardSaga handles the initial requests that needs to be made
 * for the dashboard column of My Dashboard.
 */
export function* initMyDashboardSaga(action: InitMyDashboardAction): SagaIterator {
  const userSettingsUseCustomSectors = yield select(selectUserSettingUseCustomSectors);
  const useCustomSectors = yield select(DashboardControls.selectUseCustomSectors);
  // initialize the local useCustomSectors value to the global setting
  if (useCustomSectors !== userSettingsUseCustomSectors) {
    yield put(DashboardControls.setUseCustomSectors(userSettingsUseCustomSectors));
  }

  yield put(fetchSellSideDashboardRequest(undefined));
}

export function* sellSideDashboardSaga() {
  yield takeLatest(
    [ActionTypes.LEAGUE_TABLE_UPDATED, fetchSellSideDashboardRequestType],
    fetchSellSideDashboardSaga
  );
  yield takeLatest(
    [
      DashboardControls.ActionTypes.TOP_CONTROLS_UPDATED,
      /**
       * The reason we fetch only when comparison time period is changed and not when
       * current time period is changed, is because updating the current time period
       * auto maps the comparison time period value which would cause 2 actions to trigger.
       *
       * triggering the fetchBuySideDashboardSaga when comparison changes
       * ensures we fetch data once.
       */
      DashboardControls.ActionTypes.COMPARISON_PERIOD_CONTROL_UPDATED,
    ],
    controlsUpdatedSaga
  );

  yield takeEvery<InitMyDashboardAction>(ActionTypes.INIT_MY_DASHBOARD, initMyDashboardSaga);
}
