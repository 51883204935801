import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { ChartProps, Doughnut } from 'react-chartjs-2';

export type Props = Omit<ChartProps<'doughnut'>, 'type'>;

const defaultDoughnutChartOptions: Props['options'] = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const getDoughnutChartOptions = (options: Props['options']) => {
  return merge(cloneDeep(defaultDoughnutChartOptions), options);
};

export const DoughnutChart = ({ data, options }: Props) => {
  const mergedOptions = getDoughnutChartOptions(options);

  return <Doughnut data={data} options={mergedOptions} />;
};
