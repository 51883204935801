import {
  DatalabTableColumn,
  dtc,
  masterColumns,
  visibleLocalCurrFields,
} from '../datalabTableColumns';
import { categorizedColumns, groupedColumns } from './master-columns';

export const columns: DatalabTableColumn[] = masterColumns;

export const visibleFields: string[] = [
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.filing_details_offering_price.field,
  dtc.offering_size.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.filing_details_deal_pct_at_pricing_market_cap.field,
  dtc.filing_details_pct_secondary_shares.field,
  dtc.offering_total_managers.field,
  dtc.offering_total_bookrunners.field,
  dtc.offering_total_non_bookrunners.field,
  dtc.offering_total_pct_to_bookrunners.field,
  dtc.offering_total_pct_to_non_bookrunners.field,
  dtc.offering_gross_spread_pct.field,
  dtc.left_lead_firm_name.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export { categorizedColumns, groupedColumns, visibleLocalCurrFields };
