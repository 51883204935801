import { ColDef } from '@cmg/common';

import {
  CustomSectorIssuerFilterInput,
  IssuerFilterInput,
  OfferingFilterInput,
  OfferingStatus,
  OfferingType,
  Sector,
  SortEnumType,
  SubSector,
} from '../../../graphql/__generated__/index';
import { NestedSortInput } from '../../../graphql/types';
import {
  InternalOfferingType,
  OfferingsFilterFormType,
} from '../components/offerings-filter-form/OfferingsFilterForm.model';
import {
  dataGridColumns,
  type GlobalLeagueItem,
} from './components/global-league-table/GlobalLeagueTable.model';

const ONE_MILLION = 1000000;

export const alwaysVisibleColumns = ['ranking', 'managerId'];

export const computedColumns = ['ranking'];

export const orderedGlobalLeagueColumns: ColDef<GlobalLeagueItem, any>[] = [
  dataGridColumns.ranking,
  dataGridColumns.manager,
  dataGridColumns.numberOfBook,
  dataGridColumns.numberOfNonBook,
  dataGridColumns.offeringCount,
  dataGridColumns.dollarVolume,
];

export const defaultSortModel: NestedSortInput = {
  dollarVolumeUsd: SortEnumType.Desc,
};

export const excludedOfferingTypes = [OfferingType.FollowOn];

export const mapFormTypeToGlobalLeagueFilterInput = (
  fields: OfferingsFilterFormType
): OfferingFilterInput => {
  const {
    date,
    offeringTypes,
    marketCap,
    grossProceedsBase,
    sellingShareholderPct,
    leftleads,
    sectors: sectorsField,
    customSectors: customSectorsField,
    countries,
    underwriters,
    shareholders,
    advisers,
  } = fields;

  const type = offeringTypes?.filter(
    offeringType =>
      !Object.values(InternalOfferingType).includes(offeringType as InternalOfferingType) &&
      !excludedOfferingTypes.includes(offeringType as OfferingType)
  ) as OfferingType[] | undefined;

  const hasSpac = offeringTypes?.includes(InternalOfferingType.IPO_SPACS);

  let isSpac = !hasSpac && type?.includes(OfferingType.Ipo) ? { eq: false } : undefined;

  if (hasSpac && type && !type.includes(OfferingType.Ipo)) {
    type.push(OfferingType.Ipo);
    isSpac = { eq: true };
  }

  const marketCapAtPricingUsd =
    marketCap?.min || marketCap?.max
      ? {
          gte: marketCap?.min ? marketCap?.min * ONE_MILLION : undefined,
          lte: marketCap?.max ? marketCap?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestGrossProceedsTotal =
    grossProceedsBase?.min || grossProceedsBase?.max
      ? {
          gte: grossProceedsBase?.min ? grossProceedsBase?.min * ONE_MILLION : undefined,
          lte: grossProceedsBase?.max ? grossProceedsBase?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestPctSecondaryShares =
    sellingShareholderPct?.min || sellingShareholderPct?.max
      ? {
          gte: sellingShareholderPct?.min ? sellingShareholderPct?.min : undefined,
          lte: sellingShareholderPct?.max ? sellingShareholderPct?.max : undefined,
        }
      : undefined;
  const leftLeadId = (leftleads ?? []).length > 0 ? { in: leftleads } : undefined;
  const exchangeCountry = (countries ?? []).length > 0 ? { in: countries } : undefined;

  const sectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SECTOR'))
    .map(s => s.split(':')[1] as Sector);
  const sector = sectors.length > 0 ? { sector: { in: sectors } } : undefined;
  const subSectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SUB_SECTOR'))
    .map(s => s.split(':')[1] as SubSector);
  const subSector = subSectors.length > 0 ? { subSector: { in: subSectors } } : undefined;
  const sectorOrSubSector =
    sector || subSector
      ? { or: [sector, subSector].filter(s => !!s) as IssuerFilterInput[] }
      : undefined;

  const customSectors =
    (customSectorsField ?? []).length > 0
      ? {
          or: Object.entries(
            customSectorsField!.reduce((result, value) => {
              const [type, id] = value.split(':');
              const key = type === 'SUB_SECTOR' ? 'customSubsectorId' : 'customSectorId';
              result[key] = result[key] ?? { in: [] };
              result[key]!.in!.push(id); // `!` required because of how CustomSectorIssuerFilterInput is typed
              return result;
            }, {} as CustomSectorIssuerFilterInput)
          ).map(([key, filter]) => ({ [key]: filter })),
        }
      : undefined;

  return {
    status: { eq: OfferingStatus.Priced },
    type: type && type.length > 0 ? { in: type } : { nin: excludedOfferingTypes },
    pricingDate:
      date?.start || date?.end
        ? {
            gte: date?.start ? date?.start : undefined,
            lte: date?.end ? date?.end : undefined,
          }
        : undefined,
    attributes: {
      ...(marketCapAtPricingUsd ||
      latestGrossProceedsTotal ||
      latestPctSecondaryShares ||
      leftLeadId ||
      exchangeCountry ||
      isSpac
        ? {
            marketCapAtPricingUsd,
            latestGrossProceedsTotal,
            latestPctSecondaryShares,
            leftLeadId,
            exchangeCountry,
            isSpac,
          }
        : undefined),
      isSupersededBy: { eq: null },
    },
    issuer:
      sectorOrSubSector || customSectors
        ? {
            ...sectorOrSubSector,
            customSectors,
          }
        : undefined,
    managers:
      (underwriters ?? []).length > 0
        ? { some: { manager: { id: { in: underwriters } } } }
        : undefined,
    shareholders:
      (shareholders ?? []).length > 0
        ? { some: { shareholderId: { in: shareholders } } }
        : undefined,
    advisers: (advisers ?? []).length > 0 ? { some: { adviserId: { in: advisers } } } : undefined,
  };
};
