import styled from 'styled-components/macro';

export const SValueWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

export const SIconWrapper = styled.div`
  align-items: center;
  margin-left: 5px;
`;

export const SHeaderItem = styled.div.attrs({ tabIndex: 0 })<{ onClick?: () => void }>`
  display: flex;
  color: ${({ theme }) => theme.text.color.white};
  height: 30px;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  width: 100%;
  justify-content: flex-end;
`;

export const SDescription = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const SLabel = styled.span`
  padding-right: 5px;
`;

export const SValue = styled.span`
  padding-left: 5px;
`;
