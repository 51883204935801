import { checkPermissions, permissionsByEntity, PublicAuthStateProps, withAuth } from '@cmg/auth';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  fetchDatalabOptionsActions,
  fetchOfferingActions,
  selectAdvisoryOptions,
  selectError as selectOfferingError,
  selectFundOptions,
  selectOffering,
} from '../../shared/ducks';
import UnderwriterCreditsScreen from '../components/UnderwriterCreditsScreen';
import {
  fetchUnderwriterCredits,
  resetState,
  saveUnderwriterCreditsRequest,
  selectAllocation,
  selectError,
  selectManagers,
  selectUnderwriterCreditsOffering,
} from '../ducks';

const mapStateToProps = (state, ownProps) => ({
  underwriterCreditsError: selectError(state),
  offeringError: selectOfferingError(state),
  underwriterCreditOffering: selectUnderwriterCreditsOffering(state),
  allocation: selectAllocation(state),
  offering: selectOffering(state),
  managers: selectManagers(state),
  advisoryOptions: selectAdvisoryOptions(state),
  fundOptions: selectFundOptions(state),
  offeringId: ownProps.match.params.offeringId,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      saveUnderwriterCredits: saveUnderwriterCreditsRequest,
      fetchUnderwriterCredits: fetchUnderwriterCredits,
      fetchOffering: fetchOfferingActions.request,
      fetchDatalabOptions: fetchDatalabOptionsActions.request,
      resetState: resetState,
    },
    dispatch
  ),
});

type RouteProps = RouteComponentProps<{ offeringId: string }>;
type Props = RouteProps &
  PublicAuthStateProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export class UnderwriterCreditsContainerComponent extends Component<Props> {
  componentDidMount() {
    const { actions, offeringId } = this.props;

    this.fetchData(offeringId);
    actions.fetchDatalabOptions();
  }

  componentDidUpdate(prevProps: Props) {
    const { offeringId } = this.props;

    if (offeringId !== prevProps.offeringId) {
      this.fetchData(offeringId);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  fetchData(offeringId) {
    const { actions } = this.props;

    actions.fetchOffering({ offeringId });
    actions.fetchUnderwriterCredits(offeringId);
  }

  render() {
    const {
      underwriterCreditOffering,
      allocation,
      managers,
      userPermissions,
      actions,
      offering,
      offeringError,
      underwriterCreditsError,
    } = this.props;
    const error = offeringError || underwriterCreditsError;

    if (!error && (!underwriterCreditOffering || !allocation || !offering)) {
      return null;
    }

    const canManageIOI = checkPermissions(userPermissions, [permissionsByEntity.Ioi.FULL]);

    return (
      <UnderwriterCreditsScreen
        error={error}
        offering={offering}
        underwriterCreditOffering={underwriterCreditOffering}
        allocation={allocation}
        managers={managers}
        canEdit={canManageIOI}
        saveUnderwriterCredits={actions.saveUnderwriterCredits}
      />
    );
  }
}

const UnderwriterCreditsContainerWithAuth = withAuth(UnderwriterCreditsContainerComponent);

export default connect(mapStateToProps, mapDispatchToProps)(UnderwriterCreditsContainerWithAuth);
