import { DatalabTableColumnCategory } from '../../constants';
import { ColumnCategoryConfig } from '../advanced-filters-config';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';
import { setColumnsCategory } from '../utils';
import { categorizedColumns as categorizedMasterColumns } from './master-columns';

export const columns: DatalabTableColumn[] = [
  dtc.offerings_pricing_date,
  dtc.companies_issuer,
  dtc.offering_entity_structure,
  dtc.allocation,
  dtc.ioi_shares,
  dtc.fill_rate,
  dtc.allocation_pct,
  dtc.allocation_investment,
  { ...dtc.underwriter_name, textAlign: 'right' },
  dtc.underwriter_role,
  { ...dtc.left_lead, textAlign: 'right' },
  dtc.economics_pct,
  dtc.estimated_fee,
  dtc.sales_concession,
  dtc.companies_ticker,
  dtc.companies_cik,
  dtc.companies_cusip,
  dtc.issuer_isin,
  dtc.offerings_sector,
  dtc.companies_sub_sector,
  { ...dtc.offerings_type, label: 'Type' },
  dtc.filing_details_offering_price,
  dtc.underwriting_table_shares,
  dtc.return_open_volume,
  dtc.return_open,
  dtc.return_vwap_1day_volume,
  dtc.return_vwap_1day,
  dtc.return_1day_volume,
  dtc.return_1day,
  dtc.return_3day_volume,
  dtc.return_3day,
  dtc.return_7day_volume,
  dtc.return_7day,
  dtc.return_prior_quarter_volume,
  dtc.return_prior_quarter,
  dtc.return_current_volume,
  dtc.return_current,
];

export const visibleFields: string[] = [
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.allocation.field,
  dtc.ioi_shares.field,
  dtc.fill_rate.field,
  dtc.allocation_pct.field,
  dtc.allocation_investment.field,
  dtc.underwriter_name.field,
  dtc.underwriter_role.field,
  dtc.left_lead.field,
  dtc.economics_pct.field,
  dtc.sales_concession.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.offerings_type.field,
  dtc.filing_details_offering_price.field,
  dtc.underwriting_table_shares.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.return_open_volume.field,
  dtc.return_open.field,
  dtc.return_vwap_1day_volume.field,
  dtc.return_vwap_1day.field,
  dtc.return_1day_volume.field,
  dtc.return_1day.field,
  dtc.return_3day_volume.field,
  dtc.return_3day.field,
  dtc.return_7day_volume.field,
  dtc.return_7day.field,
  dtc.return_prior_quarter_volume.field,
  dtc.return_prior_quarter.field,
  dtc.return_current_volume.field,
  dtc.return_current.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export const categorizedColumns: ColumnCategoryConfig = {
  [DatalabTableColumnCategory.MY_PARTICIPATION]: setColumnsCategory(
    [
      dtc.allocation,
      dtc.ioi_shares,
      dtc.fill_rate,
      dtc.allocation_pct,
      dtc.allocation_investment,
      dtc.sales_concession,
      dtc.return_open_volume,
      dtc.return_open,
      dtc.return_vwap_1day_volume,
      dtc.return_vwap_1day,
      dtc.return_1day_volume,
      dtc.return_1day,
      dtc.return_3day_volume,
      dtc.return_3day,
      dtc.return_7day_volume,
      dtc.return_7day,
      dtc.return_prior_quarter_volume,
      dtc.return_prior_quarter,
      dtc.return_current_volume,
      dtc.return_current,
    ],
    DatalabTableColumnCategory.MY_PARTICIPATION
  ),
  [DatalabTableColumnCategory.ISSUER]: categorizedMasterColumns.ISSUER,
  [DatalabTableColumnCategory.OFFERING_TERMS]: setColumnsCategory(
    [
      { ...dtc.offerings_type, label: 'Type' },
      dtc.filing_details_offering_price,
      dtc.underwriting_table_shares,
    ],
    DatalabTableColumnCategory.OFFERING_TERMS
  ),
  [DatalabTableColumnCategory.TIMING]: setColumnsCategory(
    [dtc.offerings_pricing_date],
    DatalabTableColumnCategory.TIMING
  ),
  [DatalabTableColumnCategory.UNDERWRITING]: setColumnsCategory(
    [
      dtc.estimated_fee,
      { ...dtc.underwriter_name, textAlign: 'right' },
      dtc.underwriter_role,
      { ...dtc.left_lead, textAlign: 'right' },
      dtc.economics_pct,
    ],
    DatalabTableColumnCategory.UNDERWRITING
  ),
};
