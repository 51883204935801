import { IconButton } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeConsumer } from 'styled-components/macro';

import { selectTotalUnreadCount, showNotifications } from '../ducks';
import { SBadge, SBadgeIconWrapper, SNotificationsIconWrapper } from './NotificationsIcon.styles';

const mapStateToProps = state => ({
  totalUnread: selectTotalUnreadCount(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showNotifications,
    },
    dispatch
  ),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const NotificationsIcon: React.FC<Props> = ({ actions, totalUnread }) => {
  return (
    <ThemeConsumer>
      {theme => (
        <SNotificationsIconWrapper onClick={actions.showNotifications}>
          <SBadgeIconWrapper>
            <IconButton
              inline
              size="large"
              icon={{ name: 'bell' }}
              color={theme.text.color.light}
            />
            {totalUnread > 0 && <SBadge>{totalUnread}</SBadge>}
          </SBadgeIconWrapper>
        </SNotificationsIconWrapper>
      )}
    </ThemeConsumer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsIcon);
