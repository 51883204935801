import React from 'react';
import { FormattedNumber } from 'react-intl';

export type DisplayVariantType = 'symbol' | 'code' | 'name' | 'narrowSymbol';
export type NotationType = 'standard' | 'scientific' | 'engineering' | 'compact';

type Props = {
  value: number;
  currency: string;
  displayVariant?: DisplayVariantType;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  notation?: NotationType;
};

const CurrencyFormatter: React.FC<Props> = ({
  value,
  currency,
  displayVariant = 'narrowSymbol',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  notation = 'standard',
}) => {
  return (
    <FormattedNumber
      // we must disable this rule because we are using a custom currency formatter
      // that requires the currency to be passed in as string
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
      value={value}
      currency={currency}
      currencyDisplay={displayVariant}
      notation={notation}
    />
  );
};

export default CurrencyFormatter;
