import React from 'react';

import { formatNullableBoolean } from '../../../common/helpers/helpers';
import { CornerstoneInvestmentType } from '../../../graphql/__generated__';
import { getFormattedCurrencyInMillion } from '../../shared/model/utils';
import { type Calendar_OfferingFieldsFragment } from '../graphql';
import { HeaderLabel, STooltipTable, StyledPopover } from './CornerstoneInvestorsRenderer.styles';
import CornerstoneInvestorsTableContent from './CornerstoneInvestorsTableContent';

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const CornerstoneInvestorsRenderer: React.FC<Props> = ({ offering }) => {
  const {
    pricingCurrency,
    hasCornerstoneInvestors,
    cornerstoneOfferingParticipantsAmount,
    cornerstonePrivatePlacementAmount,
    cornerstoneTotalAmount,
    cornerstoneInvestorsTotalCount,
    cornerstoneInvestorsOfferingParticipantsCount,
    cornerstoneInvestorsPrivatePlacementCount,
  } = offering.attributes || {};
  const { cornerstoneInvestments } = offering;

  const offeringParticipants =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.OfferingParticipant) ??
    [];

  const privatePlacement =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.PrivatePlacement) ??
    [];

  if (!hasCornerstoneInvestors) {
    return <span>{formatNullableBoolean(hasCornerstoneInvestors)}</span>;
  }

  return (
    <StyledPopover
      content={
        <STooltipTable>
          <thead>
            <tr>
              <HeaderLabel className="cornerstone-header">
                {`Cornerstone Investors (${cornerstoneInvestorsTotalCount})`}
                <div className="subtitle">
                  Cornerstone Investment Total
                  <span>
                    {getFormattedCurrencyInMillion(pricingCurrency, cornerstoneTotalAmount)}
                  </span>
                </div>
              </HeaderLabel>
            </tr>
          </thead>
          <CornerstoneInvestorsTableContent
            cornerstoneInvestments={offeringParticipants}
            cornerstoneInvestorsCount={cornerstoneInvestorsOfferingParticipantsCount}
            cornerstoneOfferingAmount={cornerstoneOfferingParticipantsAmount}
            investmentType={CornerstoneInvestmentType.OfferingParticipant}
            pricingCurrency={pricingCurrency}
          />
          <CornerstoneInvestorsTableContent
            cornerstoneInvestments={privatePlacement}
            cornerstoneInvestorsCount={cornerstoneInvestorsPrivatePlacementCount}
            cornerstoneOfferingAmount={cornerstonePrivatePlacementAmount}
            investmentType={CornerstoneInvestmentType.PrivatePlacement}
            pricingCurrency={pricingCurrency}
          />
        </STooltipTable>
      }
    >
      {formatNullableBoolean(hasCornerstoneInvestors)}
    </StyledPopover>
  );
};

export default CornerstoneInvestorsRenderer;
