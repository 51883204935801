import React from 'react';

import { DatalabScreens } from '../../../../datalab/constants';
import { mapFormTypeToATMFilterInput } from '../../../atm-offerings-report/ATMOfferingsScreen.model';
import { mapFormTypeToConvertibleFilterInput } from '../../../convert-offerings-report/ConvertOfferingsScreen.model';
import { mapFormTypeToGlobalEcmFilterInput } from '../../../global-ecm-v2/GlobalEcmScreen.model';
import { mapFormTypeToGlobalLeagueFilterInput } from '../../../global-league/GlobalLeagueScreen.model';
import {
  OfferingsFilterFormType,
  useGetInitialFilterValues,
  useGetUpdatedFilterCount,
} from '../OfferingsFilterForm.model';

export const useOfferingFilters = (screen?: DatalabScreens) => {
  const initialFilterValues = useGetInitialFilterValues({ screen });
  const [isFiltersExpanded, setIsFiltersExpanded] = React.useState(false);
  const [filters, setFilters] = React.useState<OfferingsFilterFormType>(initialFilterValues);
  const filterCount = useGetUpdatedFilterCount(filters);

  const gqlFilterInput = React.useMemo(() => {
    if (screen === DatalabScreens.CONVERTS) {
      return mapFormTypeToConvertibleFilterInput(filters);
    }
    if (screen === DatalabScreens.GLOBAL_ECM) {
      return mapFormTypeToGlobalEcmFilterInput(filters);
    }
    if (screen === DatalabScreens.GLOBAL_LEAGUE) {
      return mapFormTypeToGlobalLeagueFilterInput(filters);
    }
    return mapFormTypeToATMFilterInput(filters);
  }, [filters, screen]);

  return {
    filters,
    setFilters,
    isFiltersExpanded,
    setIsFiltersExpanded,
    gqlFilterInput,
    filterCount,
  };
};
