import { createFormikField, FieldControlProps, Icon } from '@cmg/common';
import React from 'react';
import styled, { withTheme } from 'styled-components/macro';

import { CMGTheme } from '../../../types/vendor/styled-components/styled-components';

export interface Props extends FieldControlProps<boolean, boolean, HTMLSpanElement> {
  color?: string;
  testId?: string;
}

type OwnProps = {
  theme: CMGTheme;
} & Props;

export const SSectorToggleContainer = styled.span`
  cursor: pointer;
`;

export const SectorToggleComponent: React.FC<OwnProps> = ({
  value,
  onChange,
  color,
  theme,
  testId,
}) => {
  const iconColor = color || theme.color.gray5;

  return (
    <SSectorToggleContainer
      onClick={() => onChange && onChange(!value)}
      role="switch"
      aria-checked={!!value}
      data-test-id={testId}
    >
      {value ? (
        <React.Fragment>
          <Icon name="toggle-on" fixedWidth color={iconColor} size="lg" /> My Sectors
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Icon name="toggle-off" fixedWidth color={iconColor} size="lg" /> Sectors
        </React.Fragment>
      )}
    </SSectorToggleContainer>
  );
};

const SectorToggleWithTheme = withTheme(SectorToggleComponent);

export default SectorToggleWithTheme; // used in SectorProvider

export const SectorToggleField = createFormikField<boolean, boolean, HTMLSpanElement, Props>(
  SectorToggleWithTheme
);
