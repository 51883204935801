import { IoiLimitType } from '../../../types/domain/offering/constants';

export const ioiUnitTypeOptions = [
  { value: 'dollars', label: 'Dollars' },
  { value: 'percentage', label: '% of Deal' },
  { value: 'shares', label: 'Shares' },
];

export const ioiPricingTypeOptions = [
  { value: 'limit', label: 'Limit' },
  { value: 'market', label: 'Market' },
];

export const ioiLimitTypeOptions = [
  { value: IoiLimitType.PRICE, label: 'Price' },
  { value: IoiLimitType.DISCOUNT, label: 'Discount' },
  { value: IoiLimitType.PREMIUM, label: 'Premium' },
];
