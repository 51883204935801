import isNil from 'lodash/isNil';
import React from 'react';

import { CornerstoneInvestmentType, Currency } from '../../../../../../graphql/__generated__/index';
import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import { getFormattedCurrencyInMillion } from '../../../../../shared/model/utils';
import {
  OfferingProfile_CornerstoneInvestment_PartFragment,
  OfferingProfile_ListQuery,
} from '../../../graphql/__generated__/OfferingProfile';
import { CornerstoneRowItem, CornerstoneSectionData, emptyValue } from '../types';

type CornerstoneInvestor = OfferingProfile_CornerstoneInvestment_PartFragment['investors'][number];

export const getUndisclosedInvestorsCount = (
  investments: OfferingProfile_CornerstoneInvestment_PartFragment[] | null | undefined
) =>
  (investments || []).filter(
    investment => !investment.investors.length && !!investment.individualAmount
  ).length;

export const getTotalCornerstoneInvestorsCount = (
  offering: OfferingProfile_ListQuery['offeringById']
) =>
  (offering?.attributes?.cornerstoneInvestorsTotalCount ?? 0) +
  getUndisclosedInvestorsCount(offering?.cornerstoneInvestments);

const formatCornerstoneIndividualRowFn = (
  investment: OfferingProfile_CornerstoneInvestment_PartFragment,
  pricingCurrency?: Currency | null
): CornerstoneRowItem => {
  // there's only 1 or zero investor for each individual investment
  const investor = investment.investors?.[0] as CornerstoneInvestor | undefined;

  if (!investor) {
    return {
      name: emptyValue,
      type: emptyValue,
      isNewInvestor: emptyValue,
      amount: getFormattedCurrencyInMillion(pricingCurrency, investment.individualAmount),
      isUndisclosed: true,
    };
  }

  const { shareholder, isExistingCornerstoneInvestor, cornerstoneInvestment } = investor;
  const { name, shareholderTypeDisplayName } = shareholder ?? {};
  return {
    name: name ?? emptyValue,
    type: shareholderTypeDisplayName ?? emptyValue,
    isNewInvestor: <BooleanIcon value={isExistingCornerstoneInvestor} />,
    amount: getFormattedCurrencyInMillion(pricingCurrency, cornerstoneInvestment.individualAmount),
  };
};

export const investorSortFn = (
  investorA: OfferingProfile_CornerstoneInvestment_PartFragment,
  investorB: OfferingProfile_CornerstoneInvestment_PartFragment
): number => {
  if (isNil(investorA.individualAmount) || isNil(investorB.individualAmount)) {
    return -1;
  }
  return investorB.individualAmount - investorA.individualAmount;
};

const getCornerstoneCombinedRows = (
  investments: OfferingProfile_CornerstoneInvestment_PartFragment[]
): CornerstoneRowItem[] => {
  // there should only be maximum 1 combined investment
  const investors = investments.length > 0 ? investments[0].investors : null;
  if (!investors) {
    return [];
  }
  return investors.map(investor => {
    const { shareholder, isExistingCornerstoneInvestor } = investor;
    const { name, shareholderTypeDisplayName } = shareholder ?? {};
    return {
      name: name ?? emptyValue,
      type: shareholderTypeDisplayName ?? emptyValue,
      isNewInvestor: <BooleanIcon value={isExistingCornerstoneInvestor} />,
      amount: null, // no individual amount for combined investment
    };
  });
};
const getCornerstoneCombinedAmount = (
  investments: OfferingProfile_CornerstoneInvestment_PartFragment[],
  pricingCurrency?: Currency | null
): string => {
  // there should only be maximum 1 combined investment
  const combinedInvestment = investments.length > 0 ? investments[0] : null;
  if (!combinedInvestment) {
    return emptyValue;
  }
  return getFormattedCurrencyInMillion(pricingCurrency, combinedInvestment.individualAmount);
};

export const useGetCornerstoneSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): CornerstoneSectionData =>
  React.useMemo(() => {
    const {
      cornerstoneInvestments = [],
      attributes,
      pricingCurrency,
    } = offeringProfile.offeringById ?? {};
    const {
      cornerstoneInvestorsOfferingParticipantsCount,
      cornerstoneInvestorsPrivatePlacementCount,
      cornerstoneOfferingParticipantsAmount,
      cornerstonePrivatePlacementAmount,
      cornerstoneTotalAmount,
    } = attributes ?? {};
    const totalInvestorsCount = getTotalCornerstoneInvestorsCount(offeringProfile.offeringById);
    const totalInvestmentAmount = getFormattedCurrencyInMillion(
      pricingCurrency,
      cornerstoneTotalAmount
    );
    const offeringParticipantsInvestments =
      cornerstoneInvestments?.filter(
        c => c.type === CornerstoneInvestmentType.OfferingParticipant
      ) ?? [];

    const offeringParticipantsIndividualInvestmentRows = offeringParticipantsInvestments
      .filter(c => (c.investors?.length ?? 0) <= 1)
      .sort(investorSortFn)
      .map(investment => formatCornerstoneIndividualRowFn(investment, pricingCurrency))
      .filter(i => i !== null);

    const offeringParticipantsCombinedInvestments = offeringParticipantsInvestments.filter(
      c => (c.investors?.length ?? 0) > 1
    );

    const offeringParticipantsCombinedInvestmentRows = getCornerstoneCombinedRows(
      offeringParticipantsCombinedInvestments
    );
    const offeringParticipantsCombinedInvestmentAmount = getCornerstoneCombinedAmount(
      offeringParticipantsCombinedInvestments,
      pricingCurrency
    );

    const privatePlacementInvestments =
      cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.PrivatePlacement) ??
      [];

    const privatePlacementIndividualInvestmentRows = privatePlacementInvestments
      .filter(c => (c.investors?.length ?? 0) <= 1)
      .sort(investorSortFn)
      .map(investment => formatCornerstoneIndividualRowFn(investment, pricingCurrency))
      .filter(i => i !== null);

    const privatePlacementCombinedInvestments = privatePlacementInvestments.filter(
      c => (c.investors?.length ?? 0) > 1
    );

    const privatePlacementCombinedInvestmentRows = getCornerstoneCombinedRows(
      privatePlacementCombinedInvestments
    );
    const privatePlacementCombinedInvestmentAmount = getCornerstoneCombinedAmount(
      privatePlacementCombinedInvestments,
      pricingCurrency
    );

    return {
      offeringParticipants: {
        investorsCount:
          (cornerstoneInvestorsOfferingParticipantsCount ?? 0) +
          getUndisclosedInvestorsCount(offeringParticipantsInvestments),
        investmentAmount: getFormattedCurrencyInMillion(
          pricingCurrency,
          cornerstoneOfferingParticipantsAmount
        ),
        individualInvestments: offeringParticipantsIndividualInvestmentRows,
        combinedAmount: offeringParticipantsCombinedInvestmentAmount,
        combinedInvestments: offeringParticipantsCombinedInvestmentRows,
      },
      privatePlacement: {
        investorsCount:
          (cornerstoneInvestorsPrivatePlacementCount ?? 0) +
          getUndisclosedInvestorsCount(privatePlacementInvestments),
        investmentAmount: getFormattedCurrencyInMillion(
          pricingCurrency,
          cornerstonePrivatePlacementAmount
        ),
        individualInvestments: privatePlacementIndividualInvestmentRows,
        combinedAmount: privatePlacementCombinedInvestmentAmount,
        combinedInvestments: privatePlacementCombinedInvestmentRows,
      },
      totalInvestorsCount,
      totalInvestmentAmount,
    };
  }, [offeringProfile.offeringById]);
