import { Select } from '@cmg/common';
import { advancedFilterRoleSelect, advancedFilterUnderwriterSelect } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { managerRolesOptions } from '../../../shared/constants/constants';
import { fetchUnderwriterOptionsActions, selectUnderwriterOptions } from '../../ducks';

const SUnderwriterRole = styled.div`
  display: flex;
`;

const StyledUnderwriterSelect = styled(Select)`
  min-width: 200px;
  margin-right: 6px;
` as typeof Select;

const StyledRoleSelect = styled(Select)`
  min-width: 200px;
` as typeof Select;

type UnderwriterAndRole = {
  underwriterId?: string | null;
  role?: string | null;
};

function getNextState(value?: UnderwriterAndRole): UnderwriterAndRole {
  return {
    underwriterId: value && value.underwriterId,
    role: value && value.role,
  };
}

const mapStateToProps = state => ({
  underwriterOptions: selectUnderwriterOptions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUnderwriterOptions: fetchUnderwriterOptionsActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  value?: UnderwriterAndRole;
  onChange: (value?: UnderwriterAndRole) => void;
};
export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = UnderwriterAndRole;

export class UnderwriterRoleControlComponent extends React.Component<Props, State> {
  state: State = getNextState(this.props.value);

  componentDidMount(): void {
    const { actions, underwriterOptions } = this.props;
    !underwriterOptions.length && actions.fetchUnderwriterOptions();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState(getNextState(this.props.value));
    }
  }

  handleOnChange = (update: State) => {
    const newState = { ...this.state, ...update };
    this.setState(newState);
    if (newState.underwriterId && newState.role) {
      this.props.onChange(newState);
    }
  };

  render() {
    const { underwriterOptions } = this.props;
    const { underwriterId, role } = this.state;

    return (
      <SUnderwriterRole>
        <StyledUnderwriterSelect
          isSearchable
          options={underwriterOptions}
          onChange={(val: string | null) => this.handleOnChange({ underwriterId: val })}
          placeholder="Select Underwriter"
          value={underwriterId || null}
          testId={advancedFilterUnderwriterSelect.testId}
        />
        <StyledRoleSelect
          isSearchable
          options={managerRolesOptions}
          onChange={(val: string | null) => this.handleOnChange({ role: val })}
          placeholder="Select Role"
          value={role || null}
          testId={advancedFilterRoleSelect.testId}
        />
      </SUnderwriterRole>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwriterRoleControlComponent);
