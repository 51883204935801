import HighlightWords from 'react-highlight-words';
import styled from 'styled-components/macro';

export const StyledHighlightWords = styled(HighlightWords)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;

  .highlight {
    color: ${({ theme }) => theme.brand.color.light};
    background: none;
  }
`;
