import { TogglerButton } from '../shared/layout/components/Toggler.types';

export enum DatalabScreenViewTypes {
  CHART = 'chart',
  TABLE = 'table',
}

export enum ReportDetailsModalMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

export const datalabSectionTypes = {
  CHART: 'CHART',
  TABLE: 'TABLE',
};

export const datalabSections: { chart: TogglerButton; table: TogglerButton } = {
  chart: { value: datalabSectionTypes.CHART, label: 'Charts', icon: 'chart-bar' },
  table: { value: datalabSectionTypes.TABLE, label: 'Table', icon: 'list-alt' },
};

export enum DatalabScreens {
  IPO_STRUCTURE = 'ipo_structure',
  IPO_PRICING = 'ipo_pricing',
  FOLLOW_ON_STRUCTURE = 'follow_on_structure',
  FOLLOW_ON_PRICING = 'follow_on_pricing',
  UNDERWRITING_TERMS = 'underwriting_structures',
  UNDERWRITER_PROFILE_SELL = 'underwriter_profile_sell',
  UNDERWRITER_PROFILE_BUY = 'underwriter_profile_buy',
  IPO_BACKLOG = 'ipo_backlog',
  LOCK_UP_EXPIRATION = 'lock_up_expiration',
  LEAGUE_TABLE = 'league_tables',
  AFTERMARKET_MATRIX = 'aftermarket_matrix',
  SPONSOR_LEAGUE_TABLE = 'sponsor_league_table',
  UNDERWRITER_OFFERINGS = 'underwriter_offerings',
  AFTERMARKET_UNDERWRITER_OFFERINGS = 'aftermarket_underwriter_offerings',
  MARKET_PULSE = 'market_pulse',
  IOI_PARTICIPATION = 'ioi_participation',
  IOI_LEAGUE_TABLE = 'ioi_league_table',
  PL_LEAGUE_TABLE = 'pl_league_table',
  BROKER_PL_OFFERINGS = 'broker_pl_offerings',
  FUND_IOI_LEAGUE_TABLE = 'fund_ioi_league_table',
  CAPITAL_RAISED_MARKET = 'capital_raised_matrix',
  FUND_IOI_PARTICIPATION = 'fund_ioi_participation',
  CONVERTS = 'converts',
  ATM = 'atm',
  GLOBAL_ECM = 'global_ecm',
  GLOBAL_LEAGUE = 'global_league',
}

export const chartViewOnlyDatalabSections = [DatalabScreens.CAPITAL_RAISED_MARKET];
export const tableViewOnlyDatalabSections = [
  DatalabScreens.LOCK_UP_EXPIRATION,
  DatalabScreens.IPO_BACKLOG,
  DatalabScreens.LEAGUE_TABLE,
  DatalabScreens.AFTERMARKET_MATRIX,
  DatalabScreens.SPONSOR_LEAGUE_TABLE,
  DatalabScreens.UNDERWRITER_OFFERINGS,
  DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS,
  DatalabScreens.IOI_LEAGUE_TABLE,
  DatalabScreens.PL_LEAGUE_TABLE,
  DatalabScreens.FUND_IOI_PARTICIPATION,
  DatalabScreens.FUND_IOI_LEAGUE_TABLE,
  DatalabScreens.BROKER_PL_OFFERINGS,
];

export enum DatalabTableColumnCategory {
  ISSUER = 'ISSUER',
  OFFERING_TERMS = 'OFFERING_TERMS',
  OWNERSHIP = 'OWNERSHIP',
  PERFORMANCE = 'PERFORMANCE',
  PRICING_DISCOUNT = 'PRICING_DISCOUNT',
  TIMING = 'TIMING',
  UNDERWRITING = 'UNDERWRITING',
  MY_PARTICIPATION = 'MY_PARTICIPATION',
  ADVISORS = 'ADVISORS',
}

export const columnCategoryLabel: { [key in DatalabTableColumnCategory]: string } = {
  [DatalabTableColumnCategory.ISSUER]: 'Issuer',
  [DatalabTableColumnCategory.OFFERING_TERMS]: 'Offering Terms',
  [DatalabTableColumnCategory.OWNERSHIP]: 'Ownership',
  [DatalabTableColumnCategory.PERFORMANCE]: 'Performance',
  [DatalabTableColumnCategory.PRICING_DISCOUNT]: 'Pricing Discount',
  [DatalabTableColumnCategory.TIMING]: 'Timing',
  [DatalabTableColumnCategory.UNDERWRITING]: 'Underwriting',
  [DatalabTableColumnCategory.MY_PARTICIPATION]: 'My Participation',
  [DatalabTableColumnCategory.ADVISORS]: 'Advisors',
};

export enum DatalabTableColumnGroup {
  LOCAL_CURRENCY = 'LOCAL_CURRENCY',
  USD = 'USD',
}

export const columnGroupLabel: { [key in DatalabTableColumnGroup]: string } = {
  [DatalabTableColumnGroup.LOCAL_CURRENCY]: 'Local Currency Fields',
  [DatalabTableColumnGroup.USD]: 'USD Fields',
};
