/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Shared_CustomSectorFieldsFragment = {
  __typename?: 'CustomSector';
  id: string;
  name?: string | null;
};

export type Shared_CustomSectorPartsFragment = {
  __typename?: 'CustomSector';
  id: string;
  name?: string | null;
  children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
};

export type Shared_CustomSectorListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.CustomSectorFilterInput>;
}>;

export type Shared_CustomSectorListQuery = {
  __typename?: 'Query';
  customSectors?: {
    __typename?: 'CustomSectorCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'CustomSector';
      id: string;
      name?: string | null;
      children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      offset: number;
      pageSize: number;
    };
  } | null;
};

export const Shared_CustomSectorFieldsFragmentDoc = gql`
  fragment Shared_CustomSectorFields on CustomSector {
    id
    name
  }
`;
export const Shared_CustomSectorPartsFragmentDoc = gql`
  fragment Shared_CustomSectorParts on CustomSector {
    ...Shared_CustomSectorFields
    children {
      ...Shared_CustomSectorFields
    }
  }
  ${Shared_CustomSectorFieldsFragmentDoc}
`;
export const Shared_CustomSectorListDocument = gql`
  query Shared_CustomSectorList(
    $skip: Int = 0
    $take: Int = 100
    $where: CustomSectorFilterInput = {}
  ) {
    customSectors(
      skip: $skip
      take: $take
      where: { and: [{ type: { eq: SECTOR } }, $where] }
      order: { name: ASC }
    ) {
      items {
        ...Shared_CustomSectorParts
      }
      totalCount
      pageInfo {
        hasNextPage
        offset
        pageSize
      }
    }
  }
  ${Shared_CustomSectorPartsFragmentDoc}
`;

/**
 * __useShared_CustomSectorListQuery__
 *
 * To run a query within a React component, call `useShared_CustomSectorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShared_CustomSectorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShared_CustomSectorListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useShared_CustomSectorListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Shared_CustomSectorListQuery,
    Shared_CustomSectorListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Shared_CustomSectorListQuery, Shared_CustomSectorListQueryVariables>(
    Shared_CustomSectorListDocument,
    options
  );
}
export function useShared_CustomSectorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Shared_CustomSectorListQuery,
    Shared_CustomSectorListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Shared_CustomSectorListQuery, Shared_CustomSectorListQueryVariables>(
    Shared_CustomSectorListDocument,
    options
  );
}
export function useShared_CustomSectorListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        Shared_CustomSectorListQuery,
        Shared_CustomSectorListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Shared_CustomSectorListQuery,
    Shared_CustomSectorListQueryVariables
  >(Shared_CustomSectorListDocument, options);
}
export type Shared_CustomSectorListQueryHookResult = ReturnType<
  typeof useShared_CustomSectorListQuery
>;
export type Shared_CustomSectorListLazyQueryHookResult = ReturnType<
  typeof useShared_CustomSectorListLazyQuery
>;
export type Shared_CustomSectorListSuspenseQueryHookResult = ReturnType<
  typeof useShared_CustomSectorListSuspenseQuery
>;
export type Shared_CustomSectorListQueryResult = Apollo.QueryResult<
  Shared_CustomSectorListQuery,
  Shared_CustomSectorListQueryVariables
>;
