import { cmgSectorScheme, MediaQuery, NumericRangeInputField } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SectorToggleLabel from '../../../shared/sectors/SectorToggleLabel';
import {
  mapDefaultTopControlValues,
  mapSubmittedTopControlValues,
  performanceMetricOptions,
  TopControlFormValues,
} from './controlsForm.model';
import {
  SColumn,
  SControls,
  SMobileControls,
  SMobileOfferingSizeControl,
  SOfferingSizeControl,
  StyledAverageMethodControl,
  StyledMobileAverageMethodControl,
  StyledMobilePerformanceMetricControl,
  StyledMobileSectorControl,
  StyledPerformanceMetricControl,
  StyledSectorControl,
} from './ControlsForm.styles';
import {
  selectTopControls,
  selectUseCustomSectors,
  setUseCustomSectors,
  topControlsUpdated,
} from './ducks';

const mapStateToProps = state => ({
  controls: selectTopControls(state),
  useCustomSectors: selectUseCustomSectors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onChange: topControlsUpdated,
      onCustomSectorToggle: setUseCustomSectors,
    },
    dispatch
  ),
});

type CustomSectorOption = {
  title: string;
  value: string;
};

export type CustomSectorTreeOption = CustomSectorOption & { children: CustomSectorOption[] };

type OwnProps = {
  customSectorOptions: CustomSectorTreeOption[];
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
export type StateProps = OwnProps & ConnectedProps;
export type Props = StateProps & FormikProps<TopControlFormValues>;

/**
 * ControlsForm is responsible for displaying top controls and updating their redux state
 * whenever they are changed by the user
 */
export const ControlsForm: React.FC<Props> = ({
  customSectorOptions,
  useCustomSectors,
  handleSubmit,
}) => (
  <Form>
    <MediaQuery.IsXXLargeUp>
      {matches =>
        matches ? (
          <SControls data-test-id={myDashboard.controlsForm.testId}>
            <SColumn>
              {useCustomSectors ? (
                <StyledSectorControl
                  name="customSectorIds"
                  treeData={customSectorOptions}
                  label={
                    <SectorToggleLabel
                      testId={myDashboard.useCustomSectors.testId}
                      onChange={handleSubmit}
                    />
                  }
                  withMargin
                  maxTagCount={1}
                  onChange={handleSubmit}
                />
              ) : (
                <StyledSectorControl
                  name="sectorCodes"
                  treeData={cmgSectorScheme}
                  label={
                    <SectorToggleLabel
                      testId={myDashboard.useCustomSectors.testId}
                      onChange={handleSubmit}
                    />
                  }
                  withMargin
                  maxTagCount={1}
                  onChange={handleSubmit}
                />
              )}
              <SOfferingSizeControl>
                <NumericRangeInputField
                  name="offeringSize"
                  label="Offering Size ($M)"
                  inputsProps={{ precision: 0 }}
                  withMargin
                  onChange={() => handleSubmit()}
                />
              </SOfferingSizeControl>
            </SColumn>
            <SColumn>
              <StyledPerformanceMetricControl
                label="Performance Metric"
                name="performanceMetric"
                withMargin
                options={performanceMetricOptions}
                isClearable={false} // at least one value must be selected
                onChange={handleSubmit}
              />
              <StyledAverageMethodControl
                name="averageMethod"
                withMargin
                onChange={() => handleSubmit()}
              />
            </SColumn>
          </SControls>
        ) : (
          <SMobileControls data-test-id={myDashboard.controlsForm.testId}>
            {useCustomSectors ? (
              <StyledMobileSectorControl
                name="customSectorIds"
                treeData={customSectorOptions}
                label={<SectorToggleLabel onChange={handleSubmit} />}
                maxTagCount={1}
                onChange={handleSubmit}
              />
            ) : (
              <StyledMobileSectorControl
                name="sectorCodes"
                treeData={cmgSectorScheme}
                label={<SectorToggleLabel onChange={handleSubmit} />}
                maxTagCount={1}
                onChange={handleSubmit}
              />
            )}
            <SMobileOfferingSizeControl>
              <NumericRangeInputField
                name="offeringSize"
                label="Offering Size ($M)"
                inputsProps={{ precision: 0 }}
                onChange={() => handleSubmit()}
              />
            </SMobileOfferingSizeControl>

            <StyledMobilePerformanceMetricControl
              label="Performance Metric"
              name="performanceMetric"
              options={performanceMetricOptions}
              isClearable={false} // at least one value must be selected
              onChange={handleSubmit}
            />
            <StyledMobileAverageMethodControl
              name="averageMethod"
              onChange={() => handleSubmit()}
            />
          </SMobileControls>
        )
      }
    </MediaQuery.IsXXLargeUp>
  </Form>
);

export const ControlsFormWithFormik = withFormik<StateProps, TopControlFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props: StateProps) =>
    mapDefaultTopControlValues(props.controls, props.useCustomSectors),
  handleSubmit: (values, { props }) => {
    if (props.useCustomSectors !== values.useCustomSectors) {
      props.actions.onCustomSectorToggle(Boolean(values.useCustomSectors));
    }
    props.actions.onChange({
      controls: mapSubmittedTopControlValues(values),
    });
  },
})(ControlsForm);

export default connect(mapStateToProps, mapDispatchToProps)(ControlsFormWithFormik);
