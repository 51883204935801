import { Column, Popover, Row } from '@cmg/common';
import capitalize from 'lodash/capitalize';

import { formatUrl } from '../../../../../common/helpers/helpers';
import { CompanyProfile_Issuer_FieldsFragment } from '../../../graphql/__generated__/CompanyProfile';
import SDlHorizontal from './SDlHorizontal';

export type Props = {
  company: CompanyProfile_Issuer_FieldsFragment;
};

const CompanyProfileTab = ({ company }: Props) => {
  if (!company.profile) {
    return null;
  }
  const companyProfile = company.profile!;

  return (
    <Row>
      <Column xs={24} sm={10}>
        <p dangerouslySetInnerHTML={{ __html: companyProfile.description || '' }} />
      </Column>
      <Column xs={24} sm={12} offset={2}>
        <Row>
          <Column xs={12} sm={24}>
            <SDlHorizontal>
              <dt>Company Name:</dt>
              <dd>{companyProfile.standardName}</dd>
              <dt>Exchange:</dt>
              <dd>{companyProfile.exchangeCode}</dd>
              <dt>CIK:</dt>
              <dd>{companyProfile.cik?.padStart(10, '0')}</dd>
              <dt>CUSIP:</dt>
              <dd>{company.primaryCusip}</dd>
              <dt>ISIN:</dt>
              <dd>{company.primaryIsin}</dd>
              <dt>MorningStar&copy; Sector:</dt>
              <dd>{company.sectorDisplayName}</dd>
              <dt>MorningStar&copy; Group:</dt>
              <dd>{companyProfile.industryGroupName}</dd>
              <dt>MorningStar&copy; Industry:</dt>
              <dd>{companyProfile.industryName}</dd>
              <dt>SIC:</dt>
              <Popover
                trigger="hover"
                variant="TOOLTIP"
                content={<span>SIC Code: {companyProfile.sicCode?.code}</span>}
                placement="top"
              >
                <dd>{companyProfile.sicCode?.name}</dd>
              </Popover>
              <dt>NAICS:</dt>
              <Popover
                trigger="hover"
                variant="TOOLTIP"
                content={<span>NAICS Code: {companyProfile.naicsCode?.code}</span>}
                placement="top"
              >
                <dd>{companyProfile.naicsCode?.name}</dd>
              </Popover>
            </SDlHorizontal>
          </Column>
          <Column xs={12} sm={24}>
            <SDlHorizontal topDelimiter>
              <dt>Company Website:</dt>
              <dd>
                {companyProfile.hqHomepage && (
                  <a
                    href={formatUrl(companyProfile.hqHomepage)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {companyProfile.hqHomepage}
                  </a>
                )}
              </dd>
              <dt>Email:</dt>
              <dd>{companyProfile.contactEmail}</dd>
              <dt>Phone Number:</dt>
              <dd>{companyProfile.hqPhone}</dd>
              <dt>Headquarters:</dt>
              <dd>{companyProfile.headquarters}</dd>
              <dt>Year Founded:</dt>
              <dd>{companyProfile.yearOfEstablishment}</dd>
              <dt>Fiscal Year End:</dt>
              <dd>{capitalize(companyProfile.fiscalYearEnd ?? undefined)}</dd>
              <dt>Auditor:</dt>
              <dd>{companyProfile.auditor}</dd>
            </SDlHorizontal>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

export default CompanyProfileTab;
