import { theme } from '@cmg/common';
import { Chart } from 'chart.js/auto';

// This file sets the default options for all Chart.js charts.
// Options passed in when creating a chart will override these defaults.
//
// Note: Ideally, our implementation of each chart would merge these defaults with the options
// passed in, but Chartjs only supports setting some of the global defaults directly on the defaults
// object here. Use this file sparingly. If an option is chart type specific, set it in BarChart.tsx/DoughnutChart.tsx/etc...

// LAYOUT
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.responsive = true;
Chart.defaults.layout = { padding: { left: 5, bottom: 5 } };
Chart.defaults.color = theme.text.color.light;

// FONT
Chart.defaults.font.size = 13;
Chart.defaults.font.family = '"Roboto", sans-serif, "Helvetica Neue", Helvetica, Arial';

// ELEMENTS - LINE
Chart.defaults.elements.line.fill = false;
Chart.defaults.elements.line.borderWidth = 2;
Chart.defaults.elements.line.backgroundColor = theme.border.color.dark;
Chart.defaults.elements.line.borderColor = theme.border.color.dark;

// ELEMENTS - POINT
Chart.defaults.elements.point.radius = 4;
Chart.defaults.elements.point.backgroundColor = theme.border.color.dark;
Chart.defaults.elements.point.borderWidth = 3;
Chart.defaults.elements.point.borderColor = theme.border.color.white;
Chart.defaults.elements.point.hoverBorderColor = theme.border.color.dark;

// SCALE
Chart.defaults.scale.ticks.padding = 10;
Chart.defaults.scale.grid.color = line =>
  line.tick?.value === 0 ? theme.text.color.light : theme.border.color.dark;

// LEGEND
Chart.defaults.plugins.legend.labels.font = { size: 14 };
Chart.defaults.plugins.legend.labels.color = theme.text.color.dark;
// boxWidth, boxHeight, borderRadius, and useBorderRadius combine to make the pill-like shape
Chart.defaults.plugins.legend.labels.boxWidth = 20;
Chart.defaults.plugins.legend.labels.boxHeight = 10;
Chart.defaults.plugins.legend.labels.borderRadius = 5;
Chart.defaults.plugins.legend.labels.useBorderRadius = true;
Chart.defaults.plugins.legend.onClick = () => null;

// TOOLTIP
Chart.defaults.plugins.tooltip.mode = 'nearest';
