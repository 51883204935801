import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SWrap = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  color: ${({ theme }) => theme.text.color.white};
`;

const StyledIcon = styled(Icon)`
  font-size: 150px;
  transform: rotate(-90deg);
`;

const OrientationOverlay = () => (
  <SWrap>
    <StyledIcon name="mobile-alt" />
    <p>Please switch to Landscape Mode</p>
  </SWrap>
);

export default OrientationOverlay;
