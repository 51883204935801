import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import KeyValueItem from '../components/KeyValueItem';
import { useGetUnderwriterTermsSectionData } from '../hooks/useGetUnderwritingTerms';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const UnderwritingTermsWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { underwriterTerms, underwriterNames, underwriterPct } =
    useGetUnderwriterTermsSectionData(offeringProfile);
  const title = 'Underwriting Terms';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container spacing={3} display="flex">
            <Grid item minWidth={theme => theme.spacing(46)} flex={1}>
              <TableContainer>
                <Table size="medium">
                  <TableHead>
                    <TableRow color="text.secondary">
                      <TableCell></TableCell>
                      <TableCell align="right">%</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {underwriterTerms.map(({ name, value, value2 }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell width="60%">{name}</TableCell>
                          <TableCell align="right" width="15%">
                            {value}
                          </TableCell>
                          <TableCell align="right" width="25%">
                            {value2}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              item
              direction="column"
              gap={2}
              minWidth={theme => theme.spacing(46)}
              flex={1}
            >
              <Grid item>
                {underwriterNames.map(({ name, value }) => {
                  return <KeyValueItem key={name} size="base" label={name} value={value} />;
                })}
              </Grid>
              <Grid item>
                {underwriterPct.map(({ name, value }) => {
                  return <KeyValueItem key={name} size="base" label={name} value={value} />;
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UnderwritingTermsWidget;
