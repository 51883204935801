import { Box, numericUtil } from '@cmg/common';
import { widgetTotalCapitalRaised, widgetTotalOfferings } from '@cmg/e2e-selectors';
import React from 'react';

import {
  SDollar,
  SLabel,
  SOfferings,
  SValue,
  SWidgetOfferingTotal,
} from './WidgetOfferingTotal.styles';

type Props = {
  offerings: number;
  detailValue: number;
  detailTitle: string;
};

const WidgetOfferingTotal: React.FC<Props> = ({ detailTitle, offerings, detailValue }) => {
  return (
    <SWidgetOfferingTotal>
      <Box.Content>
        <SOfferings>
          <SLabel>Offerings</SLabel>
          <SValue data-test-id={widgetTotalOfferings.testId}>
            {numericUtil.formatInteger(offerings)}
          </SValue>
        </SOfferings>

        <div>
          <SLabel>{detailTitle}</SLabel>
          <SValue data-test-id={widgetTotalCapitalRaised.testId}>
            <SDollar>$</SDollar>
            {numericUtil.formatValueInBillions(detailValue)}
          </SValue>
        </div>
      </Box.Content>
    </SWidgetOfferingTotal>
  );
};

export default WidgetOfferingTotal;
