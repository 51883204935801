import { styled, TableCell, TableContainer, TextField } from '@cmg/design-system';

export const StyledTextField = styled(TextField)`
  && {
    margin: 20px 0 8px 0;
  }
`;

export const StyledNewsTableContainer = styled(TableContainer)`
  max-height: ${({ theme }) => theme.spacing(24)}; /* corresponds to 4 table rows + a peek of 5th */
  overflow-y: auto;
`;

export const StyledTableCell = styled(TableCell)`
  height: ${({ theme }) => theme.spacing(5.25)};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`;
