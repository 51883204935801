import { DatalabTableColumnCategory } from '../../constants';
import { ColumnCategoryConfig } from '../advanced-filters-config';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';
import { setColumnsCategory } from '../utils';

export const columns: DatalabTableColumn[] = [
  dtc.offerings_pricing_date,
  dtc.companies_issuer,
  dtc.companies_ticker,
  dtc.companies_cik,
  dtc.companies_cusip,
  dtc.issuer_isin,
  dtc.offering_entity_structure,
  dtc.offerings_sector,
  dtc.companies_sub_sector,
  dtc.offerings_type,
  dtc.filing_details_gross_proceeds,
  dtc.filing_details_gross_proceeds_with_over_allotment,
  dtc.filing_details_market_cap_at_pricing,
  dtc.offering_post_offering_shares,
  dtc.offering_pre_offering_shares,
  { ...dtc.underwriter_name, textAlign: 'right' },
  dtc.underwriter_role,
  { ...dtc.left_lead, textAlign: 'right' },
  dtc.economics_pct,
  dtc.estimated_fee,
  dtc.offering_forward_agreement,
  dtc.offering_pre_offering_ownership,
  dtc.offering_pct_pre_offering_ownership,
  dtc.offering_post_offering_ownership,
  dtc.offering_pct_post_offering_ownership,
  dtc.first_follow_on,
];

export const visibleFields: string[] = [
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.companies_cik.field,
  dtc.companies_cusip.field,
  dtc.offering_entity_structure.field,
  dtc.offerings_sector.field,
  dtc.companies_sub_sector.field,
  dtc.offerings_type.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.underwriter_name.field,
  dtc.underwriter_role.field,
  dtc.left_lead.field,
  dtc.economics_pct.field,
  dtc.estimated_fee.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export const categorizedColumns: ColumnCategoryConfig = {
  [DatalabTableColumnCategory.ISSUER]: setColumnsCategory(
    [
      dtc.companies_issuer,
      dtc.companies_ticker,
      dtc.companies_cik,
      dtc.companies_cusip,
      dtc.issuer_isin,
      dtc.offering_entity_structure,
      dtc.offerings_sector,
      dtc.companies_sub_sector,
    ],
    DatalabTableColumnCategory.ISSUER
  ),
  [DatalabTableColumnCategory.OFFERING_TERMS]: setColumnsCategory(
    [
      { ...dtc.offerings_type, label: 'Type' },
      dtc.filing_details_gross_proceeds,
      dtc.filing_details_gross_proceeds_with_over_allotment,
      dtc.filing_details_market_cap_at_pricing,
      dtc.offering_forward_agreement,
      dtc.offering_post_offering_shares,
      dtc.offering_pre_offering_shares,
      dtc.first_follow_on,
    ],
    DatalabTableColumnCategory.OFFERING_TERMS
  ),
  [DatalabTableColumnCategory.OWNERSHIP]: setColumnsCategory(
    [
      dtc.offering_pre_offering_ownership,
      dtc.offering_pct_pre_offering_ownership,
      dtc.offering_post_offering_ownership,
      dtc.offering_pct_post_offering_ownership,
    ],
    DatalabTableColumnCategory.OWNERSHIP
  ),
  [DatalabTableColumnCategory.TIMING]: setColumnsCategory(
    [dtc.offerings_pricing_date],
    DatalabTableColumnCategory.TIMING
  ),
  [DatalabTableColumnCategory.UNDERWRITING]: setColumnsCategory(
    [
      dtc.estimated_fee,
      dtc.economics_pct,
      { ...dtc.underwriter_name, textAlign: 'right' },
      dtc.underwriter_role,
      { ...dtc.left_lead, textAlign: 'right' },
    ],
    DatalabTableColumnCategory.UNDERWRITING
  ),
};
