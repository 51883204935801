import { Option } from '@cmg/common';

import { AverageMethod } from '../../../../types/domain/my-dashboard/averageMethod';
import {
  MyDashboardTopControls,
  PerformanceMetric,
} from '../../../../types/domain/my-dashboard/myDashboardParameters';

export type TopControlFormValues = {
  /**
   * Offering size range.
   */
  offeringSize: { min?: number; max?: number };
  /**
   * List of sector codes.
   */
  sectorCodes: string[];
  /**
   * List of custom sector ids.
   */
  customSectorIds: string[];
  /**
   * Use custom sectors toggle will determine what values display in the sector filter
   */
  useCustomSectors?: boolean;
  /**
   * Performance metric will determine the metric in which performance values are calculated
   */
  performanceMetric: PerformanceMetric;
  /**
   * Average Method will determine the calculation method for performance metric
   */
  averageMethod: AverageMethod;
};

/**
 * Maps default values to controls form values
 */
export function mapDefaultTopControlValues(
  values: MyDashboardTopControls,
  useCustomSectors: boolean
): TopControlFormValues {
  const {
    offeringSizeMin,
    offeringSizeMax,
    sectorCodes,
    performanceMetric,
    averageMethod,
    customSectorIds,
  } = values;
  return {
    offeringSize: { min: offeringSizeMin, max: offeringSizeMax },
    sectorCodes: sectorCodes,
    customSectorIds: customSectorIds,
    useCustomSectors: useCustomSectors,
    performanceMetric: performanceMetric,
    averageMethod: averageMethod,
  };
}

/**
 * Maps form values of type TopControlFormValues to MyDashboardTopControls
 */
export function mapSubmittedTopControlValues(values: TopControlFormValues): MyDashboardTopControls {
  const {
    offeringSize,
    sectorCodes,
    customSectorIds,
    performanceMetric,
    useCustomSectors,
    averageMethod,
  } = values;

  return {
    offeringSizeMin: offeringSize.min,
    offeringSizeMax: offeringSize.max,
    sectorCodes: useCustomSectors ? [] : sectorCodes,
    customSectorIds: useCustomSectors ? customSectorIds : [],
    performanceMetric: performanceMetric,
    averageMethod: averageMethod,
  };
}

export const performanceMetricOptions: Option<PerformanceMetric>[] = [
  { label: 'Offer To Current', value: PerformanceMetric.OFFER_TO_CURRENT },
  { label: 'Offer To Open', value: PerformanceMetric.OFFER_TO_OPEN },
  { label: 'Offer To 1 Day', value: PerformanceMetric.OFFER_TO_1_DAY },
  { label: 'Offer To 7 Day', value: PerformanceMetric.OFFER_TO_7_DAY },
  { label: 'Offer To 14 Day', value: PerformanceMetric.OFFER_TO_14_DAY },
  { label: 'Offer To 30 Day', value: PerformanceMetric.OFFER_TO_30_DAY },
  { label: 'Offer To 90 Day', value: PerformanceMetric.OFFER_TO_90_DAY },
  { label: 'Offer To 180 Day', value: PerformanceMetric.OFFER_TO_180_DAY },
  { label: 'Offer To One Year', value: PerformanceMetric.OFFER_TO_ONE_YEAR },
];

export const performanceMetricDisplay = {
  [PerformanceMetric.OFFER_TO_CURRENT]: 'Offer To Current',
  [PerformanceMetric.OFFER_TO_OPEN]: 'Offer To Open',
  [PerformanceMetric.OFFER_TO_1_DAY]: 'Offer To 1 Day',
  [PerformanceMetric.OFFER_TO_7_DAY]: 'Offer To 7 Day',
  [PerformanceMetric.OFFER_TO_14_DAY]: 'Offer To 14 Day',
  [PerformanceMetric.OFFER_TO_30_DAY]: 'Offer To 30 Day',
  [PerformanceMetric.OFFER_TO_90_DAY]: 'Offer To 90 Day',
  [PerformanceMetric.OFFER_TO_180_DAY]: 'Offer To 180 Day',
  [PerformanceMetric.OFFER_TO_ONE_YEAR]: 'Offer To One Year',
};
