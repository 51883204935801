/* eslint-disable */
// prettier-ignore
import * as Types from '../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
export type Atm_Selldown_StructureFieldsFragment = {
  __typename?: 'AtmStructure';
  selldownId?: string | null;
  programRemainingInSecurities?: number | null;
  type: Types.AtmStructureType;
  typeDisplayName?: string | null;
  announcementDate?: string | null;
  asOfDate?: string | null;
  sales?: number | null;
  salesInSecurities?: number | null;
  averagePrice?: number | null;
  programSize?: number | null;
  programSizeInSecurities?: number | null;
  programRemaining?: number | null;
};

export type Atm_Attributes_FieldsFragment = {
  __typename?: 'AtmAttributes';
  pctGrossSpread?: number | null;
  lastTradeBeforeFilingSplitAdjusted?: number | null;
  latestAsOfDate?: string | null;
  latestProgramSize?: number | null;
  latestProgramSizeInSecurities?: number | null;
  announcedProgramSize?: number | null;
  announcedProgramSizeInSecurities?: number | null;
};

export type Atm_Selldown_FieldsFragment = {
  __typename?: 'AtmAttributes';
  totalSales?: number | null;
  totalSalesInSecurities?: number | null;
  totalProgramRemaining?: number | null;
  totalProgramRemainingInSecurities?: number | null;
  structure: Array<{
    __typename?: 'AtmStructure';
    selldownId?: string | null;
    programRemainingInSecurities?: number | null;
    type: Types.AtmStructureType;
    typeDisplayName?: string | null;
    announcementDate?: string | null;
    asOfDate?: string | null;
    sales?: number | null;
    salesInSecurities?: number | null;
    averagePrice?: number | null;
    programSize?: number | null;
    programSizeInSecurities?: number | null;
    programRemaining?: number | null;
  }>;
};

export type AtmOfferings_Selldown_FieldsFragment = {
  __typename?: 'AtmAttributes';
  totalSales?: number | null;
  totalSalesInSecurities?: number | null;
  totalProgramRemaining?: number | null;
  totalProgramRemainingInSecurities?: number | null;
};

export type Atm_Selldown_Term_FieldsFragment = {
  __typename?: 'Term';
  type?: Types.TermType | null;
  revisedDate?: string | null;
  atmProgramSize?: number | null;
  atmProgramSizeInSecurities?: number | null;
};

export type OfferingProfile_Shareholder_PartFragment = {
  __typename?: 'OfferingShareholder';
  shareholderId?: string | null;
  name?: string | null;
  shareholderTypeDisplayName?: string | null;
  preOfferingOwnership?: number | null;
  pctPreOfferingOwnership?: number | null;
  postOfferingOwnership?: number | null;
  pctPostOfferingOwnership?: number | null;
  postOfferingOwnershipIncludingShoe?: number | null;
  pctPostOfferingOwnershipIncludingShoe?: number | null;
  isCarveOutParent?: boolean | null;
};

export type OfferingProfile_Common_OfferingAttributes_PartFragment = {
  __typename?: 'OfferingAttributes';
  primaryShareholderName?: string | null;
  primaryShareholderFirmId?: string | null;
  pricingCurrency?: Types.Currency | null;
  hasCornerstoneInvestors?: boolean | null;
  cornerstoneOfferingParticipantsAmount?: number | null;
  cornerstonePrivatePlacementAmount?: number | null;
  cornerstoneTotalAmount?: number | null;
  cornerstoneInvestorsTotalCount?: number | null;
  cornerstoneInvestorsOfferingParticipantsCount?: number | null;
  cornerstoneInvestorsPrivatePlacementCount?: number | null;
};

export type OfferingProfile_Common_CornerstoneInvestorFragment = {
  __typename?: 'CornerstoneInvestor';
  id: string;
  cornerstoneInvestmentId: string;
  isExistingCornerstoneInvestor: boolean;
  shareholder: {
    __typename?: 'Shareholder';
    id: string;
    name?: string | null;
    shareholderTypeDisplayName?: string | null;
  };
  cornerstoneInvestment: {
    __typename?: 'CornerstoneInvestment';
    individualAmount?: number | null;
    totalInvestors: number;
    type: Types.CornerstoneInvestmentType;
    typeDisplayName?: string | null;
  };
};

export type OfferingProfile_Common_CornerstoneInvestmentFragment = {
  __typename?: 'CornerstoneInvestment';
  individualAmount?: number | null;
  totalInvestors: number;
  type: Types.CornerstoneInvestmentType;
  typeDisplayName?: string | null;
  investors: Array<{
    __typename?: 'CornerstoneInvestor';
    id: string;
    cornerstoneInvestmentId: string;
    isExistingCornerstoneInvestor: boolean;
    shareholder: {
      __typename?: 'Shareholder';
      id: string;
      name?: string | null;
      shareholderTypeDisplayName?: string | null;
    };
    cornerstoneInvestment: {
      __typename?: 'CornerstoneInvestment';
      individualAmount?: number | null;
      totalInvestors: number;
      type: Types.CornerstoneInvestmentType;
      typeDisplayName?: string | null;
    };
  }>;
};

export const Atm_Attributes_FieldsFragmentDoc = gql`
  fragment Atm_Attributes_Fields on AtmAttributes {
    pctGrossSpread
    lastTradeBeforeFilingSplitAdjusted
    latestAsOfDate
    latestProgramSize
    latestProgramSizeInSecurities
    announcedProgramSize
    announcedProgramSizeInSecurities
  }
`;
export const Atm_Selldown_StructureFieldsFragmentDoc = gql`
  fragment Atm_Selldown_StructureFields on AtmStructure {
    selldownId
    programRemainingInSecurities
    type
    typeDisplayName
    announcementDate
    asOfDate
    sales
    salesInSecurities
    averagePrice
    programSize
    programSizeInSecurities
    programRemaining
  }
`;
export const AtmOfferings_Selldown_FieldsFragmentDoc = gql`
  fragment AtmOfferings_Selldown_Fields on AtmAttributes {
    totalSales
    totalSalesInSecurities
    totalProgramRemaining
    totalProgramRemainingInSecurities
  }
`;
export const Atm_Selldown_FieldsFragmentDoc = gql`
  fragment Atm_Selldown_Fields on AtmAttributes {
    structure {
      ...Atm_Selldown_StructureFields
    }
    ...AtmOfferings_Selldown_Fields
  }
  ${Atm_Selldown_StructureFieldsFragmentDoc}
  ${AtmOfferings_Selldown_FieldsFragmentDoc}
`;
export const Atm_Selldown_Term_FieldsFragmentDoc = gql`
  fragment Atm_Selldown_Term_Fields on Term {
    type
    revisedDate
    atmProgramSize
    atmProgramSizeInSecurities
  }
`;
export const OfferingProfile_Shareholder_PartFragmentDoc = gql`
  fragment OfferingProfile_Shareholder_Part on OfferingShareholder {
    shareholderId
    name
    shareholderTypeDisplayName
    preOfferingOwnership
    pctPreOfferingOwnership
    postOfferingOwnership
    pctPostOfferingOwnership
    postOfferingOwnershipIncludingShoe
    pctPostOfferingOwnershipIncludingShoe
    isCarveOutParent
  }
`;
export const OfferingProfile_Common_OfferingAttributes_PartFragmentDoc = gql`
  fragment OfferingProfile_Common_OfferingAttributes_Part on OfferingAttributes {
    primaryShareholderName
    primaryShareholderFirmId
    pricingCurrency
    hasCornerstoneInvestors
    cornerstoneOfferingParticipantsAmount
    cornerstonePrivatePlacementAmount
    cornerstoneTotalAmount
    cornerstoneInvestorsTotalCount
    cornerstoneInvestorsOfferingParticipantsCount
    cornerstoneInvestorsPrivatePlacementCount
  }
`;
export const OfferingProfile_Common_CornerstoneInvestorFragmentDoc = gql`
  fragment OfferingProfile_Common_CornerstoneInvestor on CornerstoneInvestor {
    id
    cornerstoneInvestmentId
    isExistingCornerstoneInvestor
    shareholder {
      id
      name
      shareholderTypeDisplayName
    }
    cornerstoneInvestment {
      individualAmount
      totalInvestors
      type
      typeDisplayName
    }
  }
`;
export const OfferingProfile_Common_CornerstoneInvestmentFragmentDoc = gql`
  fragment OfferingProfile_Common_CornerstoneInvestment on CornerstoneInvestment {
    individualAmount
    totalInvestors
    type
    typeDisplayName
    investors {
      ...OfferingProfile_Common_CornerstoneInvestor
    }
  }
  ${OfferingProfile_Common_CornerstoneInvestorFragmentDoc}
`;
