import { Box } from '@cmg/common';
import React, { useState } from 'react';

import Toggler from '../../../../shared/layout/components/Toggler';
import meanMedianButtons from '../../../model/buttons/mean-median-buttons';
import { SButtonBox } from '../shared/styled';
import WidgetBarDiscountByType from '../WidgetBarDiscountByType/WidgetBarDiscountByType';

type Props = {
  // TODO - find out what this data structure is by looking at requests in the network tab
  mean: any[];
  median: any[];
};

const WidgetOfferingDiscountByType: React.FC<Props> = ({ mean, median }) => {
  const [activeBtn, setActiveBtn] = useState(meanMedianButtons[0]);

  const handleBtnChanged = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <div>
      <Box.Header title="Offering Discounts from Last trade by Type" />

      <SButtonBox>
        <Toggler
          buttons={meanMedianButtons}
          activeButton={activeBtn}
          onChange={handleBtnChanged}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        {activeBtn.value === 'MEAN' && <WidgetBarDiscountByType chartData={mean} />}
        {activeBtn.value === 'MEDIAN' && <WidgetBarDiscountByType chartData={median} />}
      </Box.Content>
    </div>
  );
};

export default WidgetOfferingDiscountByType;
