import { CellMeasurerCache } from 'react-virtualized';

export default class CustomCellMeasurerCache extends CellMeasurerCache {
  containerWidth;
  fullColumnCount = 5;
  _originalColumnWidthCache = {};

  clearAll() {
    super.clearAll();
    this._originalColumnWidthCache = {};
  }

  getTableWidth() {
    return this._cellWidthCache
      ? Object.values(this._originalColumnWidthCache).reduce((acc, value) => acc + value, 0)
      : this._defaultWidth * this.fullColumnCount;
  }

  getAdjustedWidth(width = this.minWidth, tableWidth) {
    if (
      !Number.isFinite(this.containerWidth) ||
      !Number.isFinite(tableWidth) ||
      this.containerWidth <= tableWidth ||
      tableWidth <= 0
    ) {
      return width;
    }

    const coefficient = this.containerWidth / tableWidth;

    return Math.floor(width * coefficient);
  }

  _updateCachedColumnAndRowSizes(rowIndex, columnIndex) {
    if (!this._hasFixedWidth) {
      let columnWidth = this._minWidth;

      for (let i = 0; i < this._rowCount; i += 1) {
        columnWidth = Math.max(columnWidth, this.getWidth(i, columnIndex));
      }

      const columnKey = this._keyMapper(0, columnIndex);
      this._originalColumnWidthCache[columnKey] = columnWidth;

      const tableWidth = this.getTableWidth();

      Object.entries(this._originalColumnWidthCache).forEach(([key, value]) => {
        this._columnWidthCache[key] = this.getAdjustedWidth(value, tableWidth);
      });
    }

    if (!this._hasFixedHeight) {
      let rowHeight = this._minHeight;

      for (let i = 0; i < this._columnCount; i += 1) {
        rowHeight = Math.max(rowHeight, this.getHeight(rowIndex, i));
      }

      const rowKey = this._keyMapper(rowIndex, 0);
      this._rowHeightCache[rowKey] = rowHeight;
    }
  }
}
