import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import sumBy from 'lodash/sumBy';

import { UnderwriterCreditsManager } from '../../../../types/domain/underwriter-credits/underwriterCreditsManager';

export function getManagers(managers: UnderwriterCreditsManager[]) {
  return managers.map(manager => ({
    ...manager,
    plCredit: manager.plCredit || 0,
  }));
}

export const geSellingConcessionSummary = (rows: UnderwriterCreditsManager[]) => {
  const pctEconomics = sumBy(rows, row => row.pctEconomics);
  const estimatedCommission = sumBy(rows, row => row.estimatedCommission);
  const estimatedDesignation = sumBy(rows, row => row.estimatedDesignation);
  const plCredit = sumBy(rows, row => (!isNil(row.plCredit) ? row.plCredit : 0));
  const return1dayEconomics = sumBy(rows, row =>
    !isNil(row.return1dayEconomics) ? row.return1dayEconomics : 0
  );
  const returnVwap1dayEconomics = sumBy(rows, row =>
    !isNil(row.returnVwap1dayEconomics) ? row.returnVwap1dayEconomics : 0
  );

  return [
    {
      pctEconomics: { title: 'SUM', value: numericUtil.formatPercents(pctEconomics) },
      estimatedCommission: {
        title: 'SUM',
        value: numericUtil.formatCurrency(estimatedCommission, 2),
      },
      estimatedDesignation: {
        title: 'SUM',
        value: numericUtil.formatNumber(estimatedDesignation, 0),
      },
      plCredit: { title: 'SUM', value: numericUtil.formatPercents(plCredit) },
      return1dayEconomics: { title: 'SUM', value: numericUtil.formatPercents(return1dayEconomics) },
      returnVwap1dayEconomics: {
        title: 'SUM',
        value: numericUtil.formatPercents(returnVwap1dayEconomics),
      },
    },
  ];
};

export const creditChanged = (
  prevRows: UnderwriterCreditsManager[],
  newRows: UnderwriterCreditsManager[]
) =>
  sumBy(prevRows, row => (!isNil(row.plCredit) ? row.plCredit : 0)) !==
  sumBy(newRows, row => (!isNil(row.plCredit) ? row.plCredit : 0));

export const isValidPlCredit = (rows: UnderwriterCreditsManager[]) => {
  const plCredit = sumBy(rows, row => (!isNil(row.plCredit) ? row.plCredit : 0));

  return rows.length === 0 || plCredit === 1 || plCredit === 0;
};
