import styled from 'styled-components/macro';

import TrashButton from '../../../../../common/components/buttons/trash-button/TrashButton';

export const SRow = styled.div`
  margin: 0 0 10px 0;
  width: 100%;
`;

export const StyledTrashButton = styled(TrashButton)`
  float: left;
`;
