import { checkPermissions, permissionsByEntity } from '@cmg/auth';

import { Offering_UserOfferingsPartFragment } from '../../offering-dl/offering-profile/graphql/__generated__/OfferingProfile';

/**
 * Note:
 * This is a BE concern that was exposed because we exposed the UserOfferings collection on GQL. Ideally how the BE
 * saves the fact that the user is following the offering is not a FE concern and should just be a boolean on the offering
 * rather than the userOfferings collection
 **/
export const checkIsFollowingOffering = (
  offering: Offering_UserOfferingsPartFragment,
  userId: string,
  userPermissions: string[]
) => {
  if (checkPermissions(userPermissions, [permissionsByEntity.SharedFollowedOfferings.READ])) {
    return !!offering.userOfferings?.some(uo => uo!.isShared);
  }
  return !!offering.userOfferings?.some(uo => uo!.userId === userId);
};
