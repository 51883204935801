import { getUserFamilyName, getUserGivenName, getUserId } from '@cmg/auth';
import { loggerUtil, mixpanelUtil } from '@cmg/common';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import mixpanelMiddleware from 'remimi';

import { getAppSettings } from '../../config/appSettings';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

const appSettings = getAppSettings();
const sagaMiddleware = createSagaMiddleware({
  ...(!appSettings.env.isDevelopment
    ? {
        onError: (error, errorInfo) => {
          loggerUtil.error(error, errorInfo);
        },
      }
    : {}),
});

const middlewares = [sagaMiddleware];

if (appSettings.logging.mixPanelToken) {
  const mixpanelOptions = mixpanelUtil.getMixPanelOptions(
    getUserId,
    getUserGivenName,
    getUserFamilyName
  );

  middlewares.push(mixpanelMiddleware(appSettings.logging.mixPanelToken, mixpanelOptions));
}

// @ts-ignore TODO add __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ to window interface
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(),
  undefined,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

// Note on HMR: We would do HMR for the rootReducer here, but since our react
// components depend on ducks (for selectors), the entire component tree must be
// re-rendered when a duck file is changed, which is already handled in index.js.

export default store;
