import isNil from 'lodash/isNil';
import React from 'react';

import { Country, DocumentType } from '../../../../../../graphql/__generated__';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';

const documentsOrder = {
  [DocumentType.SourceDocument]: 0,
  [DocumentType.RegistrationInitial]: 1,
  [DocumentType.RegistrationRevision]: 2,
  [DocumentType.RegistrationFinal]: 3,
};

const documentsNames = {
  [DocumentType.SourceDocument]: 'Source Document',
  [DocumentType.RegistrationInitial]: 'Initial/Launch Document',
  [DocumentType.RegistrationRevision]: 'Revised Document',
  [DocumentType.RegistrationFinal]: 'Final Document',
};

export const useGetDocumentationData = (offeringProfile: OfferingProfile_ListQuery) =>
  React.useMemo(() => {
    const {
      documents: documentsData,
      news: newsData,
      exchangeCountry,
    } = offeringProfile.offeringById ?? {};

    const documents = (documentsData || [])
      .filter(({ typeDisplayName, link }) => !isNil(typeDisplayName) && !isNil(link))
      .sort((a, b) => (documentsOrder[a.type] > documentsOrder[b.type] ? 1 : -1))
      .map(document => ({
        name: documentsNames[document.type] ?? document.typeDisplayName!,
        link: document.link,
      }));

    const news =
      exchangeCountry !== Country.Us
        ? []
        : (newsData || [])
            .filter(
              ({ filingDate, filingType, fileUrl }) =>
                !isNil(filingDate) && !isNil(filingType) && !isNil(fileUrl)
            )
            .sort((a, b) => (a.filingDate! > b.filingDate! ? -1 : 1))
            .map(newsItem => ({
              name: `${newsItem.filingType} on ${newsItem.filingDate}`,
              link: newsItem.fileUrl!,
            }));

    return { documents, news };
  }, [offeringProfile.offeringById]);
