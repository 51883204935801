import { Table } from '@cmg/common';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from './Header';
import { STableCell } from './StandardTable.styles';

export default class StandardTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    handleOrderBy: PropTypes.func.isRequired,
    hideRowsOnNoItems: PropTypes.bool,
    noResultsMessage: PropTypes.string,
    orderBy: PropTypes.string.isRequired,
    orderByType: PropTypes.string.isRequired,
    responsive: PropTypes.bool,
    rows: PropTypes.array.isRequired,
    showRanking: PropTypes.bool,
    simpleColumns: PropTypes.array.isRequired,
    striped: PropTypes.bool,
    summaries: PropTypes.object,
    visibleRows: PropTypes.array.isRequired,
    metaData: PropTypes.object,
  };

  static defaultProps = {
    striped: true,
    noResultsMessage: 'There were no results matching your criteria.',
  };

  renderRow(column, row) {
    const { rows, orderBy, metaData } = this.props;

    const value = row[column.field];

    const className = cn(
      column.class,
      column.classRenderer && column.classRenderer(value, row, column, rows, orderBy)
    );

    return (
      <STableCell
        textAlign={column.textAlign || 'left'}
        className={className}
        key={`${row.id || row.uuid}-${column.field}`}
      >
        {column.renderer(value, row, column, rows, metaData)}
      </STableCell>
    );
  }

  render() {
    const {
      columns,
      handleOrderBy,
      hideRowsOnNoItems,
      noResultsMessage,
      orderBy,
      orderByType,
      responsive,
      rows,
      showRanking,
      simpleColumns,
      striped,
      summaries,
      visibleRows,
    } = this.props;

    return (
      <Table striped={striped} responsive={responsive}>
        <Header
          columns={columns}
          orderBy={orderBy}
          orderByType={orderByType}
          handleOrderBy={handleOrderBy}
          showRanking={showRanking}
          summaries={summaries}
        />

        <tbody>
          {visibleRows.map((row, idx) => (
            <tr key={row.id || row.uuid || idx}>
              {showRanking && (
                <STableCell textAlign="center" key={`_col-ranking-${idx}`}>
                  {idx + 1}
                </STableCell>
              )}
              {simpleColumns
                .filter(column => column.visible)
                .map(column => this.renderRow(column, row))}
            </tr>
          ))}

          {rows.length === 0 && !hideRowsOnNoItems && (
            <tr>
              <td colSpan="100">{noResultsMessage}</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}
