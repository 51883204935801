import { numericUtil, Popover, timeUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import PerformancePercents from '../../../../../common/components/format/performance-percents/PerformancePercents';
import { TableRow } from '../../../../../common/components/table/types';
import { Country, OfferingStatus } from '../../../../../graphql/__generated__';
import { CantReadSelldownTooltip } from '../../../../shared/components/atm/blur-table/BlurTableRows';
import { SBlurText } from '../../../../shared/components/atm/sell-down/AtmSellDownReport.styles';
import PopoverContentTable from '../../../../shared/components/PopoverContentTable';
import { getCurrencyFormat, getCurrencyFormatInMillions } from '../../../../shared/model/utils';
import { CompanyProfile_Offering_FieldsFragment } from '../../../graphql/__generated__/CompanyProfile';
import { STableCell, STableHeader } from '../OfferingsTable.styles';
import FundAllocationsCell from './cells/FundAllocationsCell';
import OfferingTypeCell from './cells/OfferingTypeCell';
import { TableColumnProps, TableKeys } from './types';

const emptyValue = '-';

export type OfferingTableModel = TableRow<CompanyProfile_Offering_FieldsFragment, TableColumnProps>;
export const dtc: Record<TableKeys, OfferingTableModel> = {
  [TableKeys.type]: {
    label: 'Type',
    headerStyles: { align: 'left', minWidth: 100 },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) => (
      <OfferingTypeCell offering={offering} />
    ),
  },
  [TableKeys.status]: {
    label: 'Status',
    headerStyles: { align: 'left' },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.statusDisplayName ?? emptyValue,
  },
  [TableKeys.publicFilingDate]: {
    label: 'Public Filing Date',
    headerStyles: { align: 'left' },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.publicFilingDate
        ? timeUtil.formatAsDisplayDate(offering.publicFilingDate)
        : emptyValue,
  },
  [TableKeys.announcementDate]: {
    label: 'Announcement Date',
    headerStyles: { align: 'left' },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.publicFilingDate
        ? timeUtil.formatAsDisplayDate(offering.publicFilingDate)
        : emptyValue,
  },
  [TableKeys.marketTimingDisplayName]: {
    label: 'Public Filing\nTime',
    headerStyles: { align: 'left', minWidth: 88 },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.marketTimingDisplayName ?? emptyValue,
  },
  [TableKeys.announcementTime]: {
    label: 'Announcement Time',
    headerStyles: { align: 'left', minWidth: 88 },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.marketTimingDisplayName ?? emptyValue,
  },
  [TableKeys.terminatedDate]: {
    label: 'Terminated Date',
    headerStyles: { align: 'left' },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.terminatedDate ? timeUtil.formatAsDisplayDate(offering.terminatedDate) : emptyValue,
  },
  [TableKeys.statusRelevantDate]: {
    label: 'Pricing Date',
    headerStyles: { align: 'left' },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.statusRelevantDate
        ? timeUtil.formatAsDisplayDate(offering.statusRelevantDate)
        : emptyValue,
  },
  [TableKeys.atm_attributes_latestGrossProceedsTotal]: {
    label: 'Gross Proceeds\nTotal',
    headerStyles: {
      minWidth: 113,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      const { latestGrossProceedsTotal } = offering.attributes || {};
      return !isNil(latestGrossProceedsTotal)
        ? getCurrencyFormatInMillions({
            value: latestGrossProceedsTotal,
            pricingCurrencyCode,
            showInternational,
          })
        : emptyValue;
    },
  },
  [TableKeys.atm_attributes_latestProgramSize]: {
    label: 'ATM Program Size',
    headerStyles: {
      minWidth: 113,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      const { latestProgramSize } = offering.atmAttributes || {};
      return !isNil(latestProgramSize)
        ? getCurrencyFormatInMillions({
            value: latestProgramSize,
            pricingCurrencyCode,
            showInternational,
          })
        : emptyValue;
    },
  },
  [TableKeys.attributes_latestGrossProceedsTotal]: {
    label: 'Gross Proceeds\nTotal',
    headerStyles: {
      minWidth: 113,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      if (showInternational) {
        return !isNil(offering.attributes?.latestGrossProceedsTotal)
          ? getCurrencyFormatInMillions({
              value: offering.attributes!.latestGrossProceedsTotal,
              pricingCurrencyCode,
              showInternational,
            })
          : emptyValue;
      }
      return !isNil(offering.attributes?.latestGrossProceedsTotalUsd)
        ? getCurrencyFormatInMillions({
            value: offering.attributes!.latestGrossProceedsTotalUsd,
            pricingCurrencyCode: 'USD',
            showInternational,
          })
        : emptyValue;
    },
  },
  [TableKeys.atm_program_remaining]: {
    label: 'ATM Program\nRemaining',
    headerStyles: {
      minWidth: 113,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { canReadAtmSelldown }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      if (!canReadAtmSelldown) {
        return (
          <CantReadSelldownTooltip>
            <SBlurText />
          </CantReadSelldownTooltip>
        );
      }
      return !isNil(offering.atmAttributes?.totalProgramRemaining)
        ? getCurrencyFormatInMillions({
            value: offering.atmAttributes?.totalProgramRemaining,
            pricingCurrencyCode,
          })
        : emptyValue;
    },
  },
  [TableKeys.atm_program_remaining_in_securities]: {
    label: 'ATM Program\nRemaining Securities',
    headerStyles: {
      minWidth: 141,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { canReadAtmSelldown }) => {
      if (!canReadAtmSelldown) {
        return (
          <CantReadSelldownTooltip>
            <SBlurText />
          </CantReadSelldownTooltip>
        );
      }
      return !isNil(offering.atmAttributes?.totalProgramRemainingInSecurities)
        ? numericUtil.formatInteger(offering.atmAttributes?.totalProgramRemainingInSecurities)
        : emptyValue;
    },
  },
  [TableKeys.attributes_latestSizeInSecuritiesTotal]: {
    label: 'Size in Securities Total',
    headerStyles: {
      minWidth: 113,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      !isNil(offering.attributes?.latestSizeInSecuritiesTotal)
        ? numericUtil.formatInteger(offering.attributes?.latestSizeInSecuritiesTotal)
        : emptyValue,
  },
  [TableKeys.atm_attributes_latestSizeInSecuritiesTotal]: {
    label: 'Size in Securities Total',
    headerStyles: {
      minWidth: 118,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment) => {
      const { latestSizeInSecuritiesTotal } = offering.attributes || {};
      return !isNil(latestSizeInSecuritiesTotal)
        ? numericUtil.formatInteger(latestSizeInSecuritiesTotal)
        : emptyValue;
    },
  },
  [TableKeys.atm_attributes_latestProgramSizeInSecurities]: {
    label: 'ATM Program\nSize in Securities',
    headerStyles: {
      minWidth: 118,
    },
    render: (offering: CompanyProfile_Offering_FieldsFragment) => {
      const { latestProgramSizeInSecurities } = offering.atmAttributes || {};
      return !isNil(latestProgramSizeInSecurities)
        ? numericUtil.formatInteger(latestProgramSizeInSecurities)
        : emptyValue;
    },
  },
  [TableKeys.attributes_marketCapPreOffering]: {
    label: 'Market Cap\nPre-Offering',
    headerStyles: { minWidth: 89 },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) =>
      !isNil(offering?.attributes?.marketCapPreOffering)
        ? getCurrencyFormatInMillions({
            value: offering.attributes!.marketCapPreOffering,
            pricingCurrencyCode: offering.attributes!.pricingCurrency,
            showInternational,
          })
        : emptyValue,
  },
  [TableKeys.attributes_pctMarketCapPreOffering]: {
    label: 'Market Cap\nPre-Offering %',
    headerStyles: { minWidth: 101 },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      !isNil(offering?.attributes?.pctMarketCapPreOffering)
        ? numericUtil.getDisplayValueForPercents(offering.attributes!.pctMarketCapPreOffering, 2)
        : emptyValue,
  },
  [TableKeys.attributes_marketCapAtPricing]: {
    label: 'Market Cap\nPost-Offering',
    headerStyles: { minWidth: 107 },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      if (showInternational) {
        return !isNil(offering.attributes?.marketCapAtPricing)
          ? getCurrencyFormatInMillions({
              value: offering.attributes?.marketCapAtPricing,
              pricingCurrencyCode,
              showInternational,
            })
          : emptyValue;
      }
      return !isNil(offering.attributes?.marketCapAtPricingUsd)
        ? getCurrencyFormatInMillions({
            value: offering.attributes?.marketCapAtPricingUsd,
            pricingCurrencyCode: 'USD',
            showInternational,
          })
        : emptyValue;
    },
  },
  [TableKeys.attributes_pctMarketCapAtPricing]: {
    label: 'Market Cap\nPost-Offering %',
    headerStyles: { minWidth: 112 },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      !isNil(offering.attributes?.pctMarketCapAtPricing)
        ? numericUtil.getDisplayValueForPercents(offering.attributes!.pctMarketCapAtPricing, 2)
        : emptyValue,
  },
  [TableKeys.attributes_latestOfferPrice]: {
    label: 'Offer Price',
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      if (showInternational) {
        return !isNil(offering.attributes?.latestOfferPrice)
          ? getCurrencyFormat({
              value: offering.attributes?.latestOfferPrice,
              pricingCurrencyCode,
              showInternational,
            })
          : emptyValue;
      }
      return !isNil(offering.attributes?.latestOfferPriceUsd)
        ? getCurrencyFormat({
            value: offering.attributes?.latestOfferPriceUsd,
            pricingCurrencyCode: 'USD',
            showInternational,
          })
        : emptyValue;
    },
  },
  [TableKeys.attributes_netPrice]: {
    label: 'Net Price',
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) =>
      !isNil(offering.attributes?.netPrice)
        ? getCurrencyFormat({
            value: offering.attributes?.netPrice,
            pricingCurrencyCode: offering.attributes?.pricingCurrency,
            showInternational,
          })
        : emptyValue,
  },
  [TableKeys.attributes_splitAdjustedOfferPrice]: {
    label: 'Split Adjusted Offer Price',
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) =>
      !isNil(offering.attributes?.splitAdjustedOfferPrice)
        ? getCurrencyFormat({
            value: offering.attributes?.splitAdjustedOfferPrice,
            pricingCurrencyCode: offering.attributes?.pricingCurrency,
            showInternational,
          })
        : emptyValue,
    hide: ({ hasSplits }) => !hasSplits,
  },
  [TableKeys.lastAllocation]: {
    label: 'Allocation',
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      offering.lastAllocation ? (
        <FundAllocationsCell
          lastAllocation={offering.lastAllocation}
          fundAllocations={offering.fundAllocations ?? []}
        />
      ) : (
        emptyValue
      ),
    hide: ({ hasIoiPermission }) => !hasIoiPermission,
  },
  [TableKeys.lastAllocation_fillPercentage]: {
    label: 'Fill Rate',
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      !isNil(offering.lastAllocation?.fillPercentage)
        ? numericUtil.formatPercents(offering.lastAllocation!.fillPercentage)
        : emptyValue,
    hide: ({ hasIoiPermission }) => !hasIoiPermission,
  },
  [TableKeys.pctOfDeal]: {
    label: '% of Deal',
    headerStyles: { minWidth: 72 },
    render: (offering: CompanyProfile_Offering_FieldsFragment) =>
      !isNil(offering.pctOfDeal) ? numericUtil.formatPercents(offering.pctOfDeal) : emptyValue,
    hide: ({ hasIoiPermission }) => !hasIoiPermission,
  },
  [TableKeys.attributes_pctToLastTrade]: {
    label: 'To Last\nTrade %',
    headerStyles: { minWidth: 65 },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      return !isNil(offering.attributes?.pctToLastTrade) ? (
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          content={
            showInternational
              ? getCurrencyFormat({
                  value: offering.attributes?.lastTradeBeforeOffer,
                  pricingCurrencyCode,
                  showInternational,
                })
              : getCurrencyFormat({
                  value: offering.attributes?.lastTradeBeforeOfferUsd,
                  pricingCurrencyCode: 'USD',
                  showInternational,
                })
          }
          placement="left"
          transparentBackground
        >
          <span>
            <PerformancePercents value={offering.attributes?.pctToLastTrade} />
          </span>
        </Popover>
      ) : (
        emptyValue
      );
    },
  },
  [TableKeys.attributes_pctOfferToOpen]: {
    label: 'Open %',
    headerStyles: { minWidth: 59 },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) => {
      const pricingCurrencyCode = offering.attributes?.pricingCurrency;
      return !isNil(offering.attributes?.pctOfferToOpen) ? (
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          content={getCurrencyFormat({
            value: offering.attributes?.openingSharePrice,
            pricingCurrencyCode,
            showInternational,
          })}
          placement="left"
          transparentBackground
        >
          <span>
            <PerformancePercents value={offering.attributes?.pctOfferToOpen} />
          </span>
        </Popover>
      ) : (
        emptyValue
      );
    },
  },
  [TableKeys.attributes_pctOfferTo1Day]: {
    label: '1 Day %',
    headerStyles: { minWidth: 62 },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) =>
      !isNil(offering.attributes?.pctOfferTo1Day) ? (
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          content={getCurrencyFormat({
            value: offering.attributes?.day1SharePrice,
            pricingCurrencyCode: offering.attributes?.pricingCurrency,
            showInternational,
          })}
          placement="left"
          transparentBackground
        >
          <span>
            <PerformancePercents value={offering.attributes?.pctOfferTo1Day} />
          </span>
        </Popover>
      ) : (
        emptyValue
      ),
  },
  [TableKeys.attributes_pctOfferToCurrent]: {
    label: 'Current %',
    headerStyles: { minWidth: 79 },
    render: (offering: CompanyProfile_Offering_FieldsFragment, { showInternational }) =>
      !isNil(offering.attributes?.pctOfferToCurrent) ? (
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          content={getCurrencyFormat({
            value: offering.attributes?.currentSharePrice,
            pricingCurrencyCode: offering.attributes?.pricingCurrency,
            showInternational,
          })}
          placement="left"
          transparentBackground
        >
          <span>
            <PerformancePercents value={offering.attributes?.pctOfferToCurrent} />
          </span>
        </Popover>
      ) : (
        emptyValue
      ),
  },
  [TableKeys.attributes_leftLeadName]: {
    label: 'Left Lead',
    headerStyles: { align: 'left', minWidth: 200 },
    contentStyles: { align: 'left' },
    render: (offering: CompanyProfile_Offering_FieldsFragment) => {
      const hasShares = offering.managers?.some(mm => !!mm.pctUnderwritingBaseShares);

      return offering.attributes?.leftLeadName ? (
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          placement="left"
          transparentBackground
          content={
            <PopoverContentTable alignLastColumn="right">
              <thead>
                <tr>
                  <STableHeader textAlign="left">Underwriter</STableHeader>
                  <STableHeader textAlign="left">Role</STableHeader>
                  {hasShares && (
                    <STableHeader textAlign="left" style={{ minWidth: '100px' }}>
                      Shares
                    </STableHeader>
                  )}
                </tr>
              </thead>
              <tbody>
                {offering.managers?.map((manager, idx) => (
                  <tr key={idx}>
                    <STableCell textAlign="left">{manager.name}</STableCell>
                    <STableCell textAlign="left">{manager.roleDisplayName}</STableCell>
                    {hasShares && (
                      <STableCell textAlign="left">
                        {!isNil(manager.pctUnderwritingBaseShares)
                          ? numericUtil.formatPercents(manager.pctUnderwritingBaseShares, 1)
                          : emptyValue}
                      </STableCell>
                    )}
                  </tr>
                ))}
              </tbody>
            </PopoverContentTable>
          }
        >
          <Link
            to={`/datalab/underwriter_offerings/?underwriter=${offering.attributes?.leftLeadId}`}
          >
            {offering.attributes?.leftLeadName}
          </Link>
        </Popover>
      ) : (
        emptyValue
      );
    },
  },
};

export const useGetOfferingsTableModel = (permissions: TableColumnProps): OfferingTableModel[] =>
  useMemo(
    () =>
      [
        dtc[TableKeys.type],
        dtc[TableKeys.status],
        dtc[TableKeys.statusRelevantDate],
        dtc[TableKeys.attributes_latestGrossProceedsTotal],
        dtc[TableKeys.attributes_latestSizeInSecuritiesTotal],
        dtc[TableKeys.attributes_marketCapAtPricing],
        dtc[TableKeys.attributes_pctMarketCapAtPricing],
        dtc[TableKeys.attributes_latestOfferPrice],
        dtc[TableKeys.attributes_netPrice],
        dtc[TableKeys.attributes_splitAdjustedOfferPrice],
        dtc[TableKeys.lastAllocation],
        dtc[TableKeys.lastAllocation_fillPercentage],
        dtc[TableKeys.pctOfDeal],
        dtc[TableKeys.attributes_pctToLastTrade],
        dtc[TableKeys.attributes_pctOfferToOpen],
        dtc[TableKeys.attributes_pctOfferTo1Day],
        dtc[TableKeys.attributes_pctOfferToCurrent],
        dtc[TableKeys.attributes_leftLeadName],
      ].filter(row => (row.hide ? !row.hide(permissions) : true)),
    [permissions]
  );

export const hideOfferingRestrictedFields = (
  offering: CompanyProfile_Offering_FieldsFragment
): CompanyProfile_Offering_FieldsFragment => {
  // Due to legal constraints for US FILED offerings, hide all the fields but the listed
  if (
    offering?.attributes?.exchangeCountry === Country.Us &&
    offering.status === OfferingStatus.Filed
  ) {
    return {
      id: offering.id,
      type: offering.type,
      typeDisplayName: offering.typeDisplayName,
      status: offering.status,
      statusDisplayName: offering.statusDisplayName,
      attributes: offering?.attributes && {
        offeringId: offering.attributes.offeringId,
        exchangeCountry: offering?.attributes.exchangeCountry,
        pricingCurrency: offering.attributes.pricingCurrency,
        latestGrossProceedsTotal: offering.attributes.latestGrossProceedsTotal,
        latestGrossProceedsTotalUsd: offering.attributes.latestGrossProceedsTotalUsd,
      },
      managers: [],
      terms: [],
    };
  }

  return offering;
};

export const useGetAtmOfferingsTableModel = (
  permissions: TableColumnProps
): OfferingTableModel[] => {
  return useMemo(() => {
    return [
      dtc[TableKeys.type],
      dtc[TableKeys.status],
      dtc[TableKeys.announcementDate],
      dtc[TableKeys.announcementTime],
      dtc[TableKeys.terminatedDate],
      dtc[TableKeys.atm_attributes_latestProgramSize],
      dtc[TableKeys.atm_attributes_latestProgramSizeInSecurities],
      dtc[TableKeys.atm_program_remaining],
      dtc[TableKeys.atm_program_remaining_in_securities],
      dtc[TableKeys.attributes_marketCapPreOffering],
      dtc[TableKeys.attributes_pctMarketCapPreOffering],
      dtc[TableKeys.attributes_pctOfferToOpen],
      dtc[TableKeys.attributes_pctOfferTo1Day],
      dtc[TableKeys.attributes_pctOfferToCurrent],
      dtc[TableKeys.attributes_leftLeadName],
    ].filter(row => (row.hide ? !row.hide(permissions) : true));
  }, [permissions]);
};
