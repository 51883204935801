import { Box, Column, Row } from '@cmg/common';
import { ipoStructureScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { IpoStructure } from '../../../../types/domain/datalab/ipo-structure';
import { selectIpoStructureCharts } from '../../ducks';
import WidgetHistoricalOfferingVolume from '../widgets/ipo-structure/WidgetHistoricalOfferingVolume/WidgetHistoricalOfferingVolume';
import WidgetBarMeanMedianPercentage from '../widgets/WidgetBarMeanMedianPercentage/WidgetBarMeanMedianPercentage';
import WidgetHistoricalDaysConfidentialToPublicDate from '../widgets/WidgetHistoricalDaysConfidentialToPublicDate/WidgetHistoricalDaysConfidentialToPublicDate';
import WidgetHistoricalDealSizeMarketCapTrends from '../widgets/WidgetHistoricalDealSizeMarketCapTrends/WidgetHistoricalDealSizeMarketCapTrends';
import WidgetOfferingVolumeBySector from '../widgets/WidgetOfferingVolumeBySector/WidgetOfferingVolumeBySector';
import WidgetPercentIPOsWithSecondaryComponent from '../widgets/WidgetPercentIPOsWithSecondaryComponent/WidgetPercentIPOsWithSecondaryComponent';
import WidgetSizePrePostOfferingMarketCap from '../widgets/WidgetSizePrePostOfferingMarketCap/WidgetSizePrePostOfferingMarketCap';

type Props = {
  datalab: IpoStructure;
};

export class IpoStructureScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;

    return (
      <div data-test-id={ipoStructureScreenSelector.testId}>
        <Row>
          <Column xs={24}>
            <Box>
              <WidgetHistoricalOfferingVolume chartData={datalab.historical_offering_volume} />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetHistoricalDaysConfidentialToPublicDate
                chartData={datalab.days_from_confidential_filing_to_public_filing}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetOfferingVolumeBySector
                chartData={datalab.offering_volume_by_sector}
                title="Offering Volume by Sector"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetHistoricalDealSizeMarketCapTrends
                chartData={datalab.historical_deal_size_market_cap_trends}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetSizePrePostOfferingMarketCap
                chartData={datalab.size_pre_post_offering_market_cap}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPercentIPOsWithSecondaryComponent
                chartData={datalab.percent_ipos_with_secondary_component}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetBarMeanMedianPercentage
                chartData={datalab.percent_secondary_shares_offered}
                title="% Secondary Shares Offered"
                xScaleLabel="Offering Size"
              />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectIpoStructureCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(IpoStructureScreenComponent);
