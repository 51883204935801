import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum IoiMarketPerformanceButtons {
  PCT_FILL = 'pct_fill',
  PCT_OF_DEAL = 'pct_of_deal',
}

export const ioiMarketPerformanceButtonsConfig: ButtonsConfig = {
  [IoiMarketPerformanceButtons.PCT_FILL]: { label: '% Fill' },
  [IoiMarketPerformanceButtons.PCT_OF_DEAL]: { label: '% of Deal' },
};

export default getButtonConfigArray(ioiMarketPerformanceButtonsConfig);
