import {
  AsyncMultiSelectField,
  CheckboxSelectField,
  cmgSectorScheme,
  DateRangeField,
  Icon,
  MultiSelectField,
  NumericRangeInputField,
  Option,
  regionAndCountryTreeSelectSchema,
  YesNoSelectField,
} from '@cmg/common';
import { datalabMarketCapSelector, datalabRegionCountryFilterSelector } from '@cmg/e2e-selectors';
import { Form, Formik } from 'formik';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import { TreeSelectField } from '../../../../common/components/form/selects/TreeSelect';
import { isDefined } from '../../../../common/helpers/helpers';
import { DEBOUNCE_TIMEOUT } from '../../../../config/constants';
import { SponsorOption } from '../../../../types/domain/firm/sponsorOptions';
import SectorToggleLabel from '../../../shared/sectors/SectorToggleLabel';
import { mapDefaultValues, mapSubmittedValues } from '../../model/filters.model';
import { DatalabFilterValues, FilterSettings } from '../../types';
import { DatalabAdvancedFilterField } from '../advanced-filter/DatalabAdvancedFilter';
import { YearRangePickerField } from './DatalabYearsRangeFilter';
import SponsorOptionRenderer from './SponsorOptionRenderer';

export const SBaseFiltersRow = styled.div`
  margin: 0 15px 5px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const SFiltersRow = styled(SBaseFiltersRow)`
  > * {
    width: 250px;
    margin-right: 15px;
  }
`;

export const StyledSearchIcon = styled(Icon)`
  margin: 5px;
`;

export const ioiTypeOptions = [
  { label: 'Pass', value: 'pass' },
  { label: 'Reg-M Conflict', value: 'reg-m' },
  { label: 'No Allocation', value: 'ioi' },
  { label: 'Terminated', value: 'terminated' },
];

export type InnerFormProps = {
  advisoryOptions: any[];
  filterSettings: FilterSettings;
  fundsOptions: any[];
  sponsorOptions: SponsorOption[];
  handleSponsorSearch: (input: string) => Promise<SponsorOption[]>;
  underwriterOptions: any[];
  useCustomSectors?: boolean;
  customSectorOptions: Omit<Option, 'label'>[];
  spacOptions: Option[];
  onChange: () => void;
  offeringTypesDefaultValues?: { value: string; label: string }[];
};

export class FilterForm extends React.PureComponent<InnerFormProps> {
  debouncedOnChange = debounce(() => {
    this.props.onChange();
  }, DEBOUNCE_TIMEOUT);

  render() {
    const {
      advisoryOptions,
      filterSettings,
      fundsOptions,
      sponsorOptions,
      underwriterOptions,
      useCustomSectors,
      customSectorOptions,
      onChange,
      offeringTypesDefaultValues,
    } = this.props;

    const {
      showYearSelect,
      dateRangePresetOptions,
      filterIoiTypeEnabled,
      filterFundsEnabled,
      advancedFiltersEnabled,
      marketCapFiltersEnabled,
      offeringSizeFiltersEnabled,
      sellingShareFiltersEnabled,
      showInternational,
    } = filterSettings;

    const offeringTypeOptions = filterSettings.offeringTypeOptions;

    return (
      <Form>
        <SFiltersRow>
          {showYearSelect ? (
            <YearRangePickerField
              name="date"
              label="Date Range (Years)"
              withMargin
              onChange={onChange}
            />
          ) : (
            <DateRangeField
              name="date"
              label="Date Range"
              withMargin
              presetOptions={dateRangePresetOptions}
              onChange={onChange}
            />
          )}

          {/* Since we still have the property spac on the BE we need to keep an internal record in order to include SPACS */}
          <CheckboxSelectField
            name="offeringTypeInternal"
            label="Offering Type"
            options={offeringTypeOptions}
            withMargin
            onChange={onChange}
            resetValues={offeringTypesDefaultValues}
          />

          {useCustomSectors ? (
            <TreeSelectField
              name="customSector"
              treeData={customSectorOptions}
              label={<SectorToggleLabel onChange={onChange} />}
              withMargin
              onChange={onChange}
            />
          ) : (
            <TreeSelectField
              name="sector"
              treeData={cmgSectorScheme}
              label={<SectorToggleLabel onChange={onChange} />}
              withMargin
              onChange={onChange}
            />
          )}
          {showInternational && (
            <TreeSelectField
              data-test-id={datalabRegionCountryFilterSelector}
              name="countries"
              treeData={regionAndCountryTreeSelectSchema}
              label="Region/Country"
              withMargin
              onChange={onChange}
            />
          )}
        </SFiltersRow>

        <SFiltersRow>
          <NumericRangeInputField
            name="marketCap"
            data-test-id={datalabMarketCapSelector}
            label="Market Cap ($M)"
            inputsProps={{ precision: 0, disabled: !marketCapFiltersEnabled }}
            withMargin
            onChange={this.debouncedOnChange}
          />
          <NumericRangeInputField
            name="offeringSize"
            label="Gross Proceeds Base ($M)"
            inputsProps={{ precision: 0, disabled: !offeringSizeFiltersEnabled }}
            withMargin
            onChange={this.debouncedOnChange}
          />
          <NumericRangeInputField
            name="sellingShareholderPct"
            label="Selling ShareHl %"
            inputsProps={{ suffix: '%', disabled: !sellingShareFiltersEnabled }}
            withMargin
            onChange={this.debouncedOnChange}
          />
        </SFiltersRow>

        <SFiltersRow>
          <MultiSelectField
            name="underwriters"
            label="Underwriter"
            options={underwriterOptions}
            withMargin
            onChange={onChange}
          />
          <YesNoSelectField
            name="leftLeadOnly"
            label="Left Lead Only"
            isClearable
            withMargin
            onChange={onChange}
          />

          {filterIoiTypeEnabled && (
            <MultiSelectField
              name="ioiType"
              label="IOI Type"
              options={ioiTypeOptions}
              withMargin
              onChange={onChange}
            />
          )}

          {filterFundsEnabled && (
            <MultiSelectField
              name="funds"
              label="Fund/Strategy"
              options={fundsOptions}
              withMargin
              onChange={onChange}
            />
          )}

          <AsyncMultiSelectField
            name="sponsors"
            label="Sponsor"
            defaultOptions={sponsorOptions}
            renderOption={option => <SponsorOptionRenderer {...(option as SponsorOption)} />}
            renderDropdownIndicator={() => <StyledSearchIcon name="search" size="sm" />}
            withMargin
            loadOptions={this.props.handleSponsorSearch}
            onChange={onChange}
          />

          <MultiSelectField
            name="advisories"
            label="Advisory"
            options={advisoryOptions}
            withMargin
            onChange={onChange}
          />
        </SFiltersRow>
        {advancedFiltersEnabled && (
          <React.Fragment>
            <SBaseFiltersRow>
              <DatalabAdvancedFilterField
                name="advancedQuery"
                label="Custom Filters"
                config={filterSettings.advancedFilterFieldConfig}
                onChange={onChange}
              />
            </SBaseFiltersRow>
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export const DatalabFilterValueSchema = Yup.object().shape({
  date: Yup.object({
    end: Yup.string().nullable(),
    start: Yup.string().nullable(),
  }).test(
    'date-range-empty',
    'Start and End dates are required',
    value => isDefined(value.start) && isDefined(value.end)
  ),
});

function filterValues(values) {
  const pairs = Object.entries(values).filter(([, value]) => value !== undefined);
  return pairs.reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});
}

/**
 * https://capitalmarketsgateway.atlassian.net/browse/ECM-3906
 * prevents unnecessary requests
 */
function hasChanges(filters: Object, submitted: Object) {
  return !isEqual(filterValues(filters), filterValues(submitted));
}

export type Props = {
  filters: DatalabFilterValues;
  filterCount: number;
  advisoryOptions: any[];
  defaultValues: DatalabFilterValues;
  filterSettings: FilterSettings;
  fundsOptions: any[];
  sponsorOptions: any[];
  handleSponsorSearch: (input: string) => Promise<SponsorOption[]>;
  underwriterOptions: any[];
  customSectorOptions: Omit<Option, 'label'>[];
  spacOptions: Option[];
  onChange: Function;
  offeringTypesDefaultValues?: {
    value: string;
    label: string;
  }[];
};

class DatalabFilterForm extends React.PureComponent<Props> {
  render() {
    const {
      filterCount,
      filters,
      advisoryOptions,
      defaultValues,
      filterSettings,
      fundsOptions,
      sponsorOptions,
      handleSponsorSearch,
      underwriterOptions,
      customSectorOptions,
      spacOptions,
      onChange,
      offeringTypesDefaultValues,
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={
          filterCount > 0 ? mapDefaultValues(filters) : mapDefaultValues(defaultValues)
        }
        isInitialValid={false}
        onSubmit={values => {
          const submitted = mapSubmittedValues(values, filterSettings);

          // we only trigger onChange when something actually changes
          // for internal reasons, submitted values contains keys that are empty { name: undefined }
          // but these fields are originally not present in initial values, so {} would be present
          // so to avoid unwanted request, we explicitly filter these cases out
          if (hasChanges(filters, submitted)) {
            onChange(submitted);
          }
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={DatalabFilterValueSchema}
      >
        {formikProps => {
          return (
            <FilterForm
              filterSettings={filterSettings}
              advisoryOptions={advisoryOptions}
              fundsOptions={fundsOptions}
              underwriterOptions={underwriterOptions}
              sponsorOptions={sponsorOptions}
              handleSponsorSearch={handleSponsorSearch}
              useCustomSectors={formikProps.values.useCustomSectors}
              customSectorOptions={customSectorOptions}
              spacOptions={spacOptions}
              onChange={formikProps.handleSubmit}
              offeringTypesDefaultValues={offeringTypesDefaultValues}
            />
          );
        }}
      </Formik>
    );
  }
}

export default DatalabFilterForm;
