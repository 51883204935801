import { Table, TextInput } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledTable = styled(Table)`
  &.disabled {
    background-color: ${({ theme }) => theme.background.color.light};

    .value-input {
      color: ${({ theme }) => theme.text.color.light};
    }
  }

  &.simple {
    height: auto;
    border: 0;
    padding: 0;
  }

  .internal-demand-summary {
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }

  .select-wrapper {
    margin-bottom: 0;
  }

  tr {
    display: flex;

    td {
      padding: 4px;
    }

    th {
      padding: 6px 8px;
    }

    td + td,
    th + th {
      padding-left: 0;
    }

    .action-column {
      width: 40px;
    }

    .value-column {
      display: block;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      .inputs-group {
        display: flex;

        > * {
          flex: 1 1;
        }

        input {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 50%;
  margin-right: 8px;
`;

export const SAddFundWrapper = styled.div`
  display: flex;
`;

export const SInternalDemand = styled.div`
  button.top-gap .fa {
    padding-top: 2px;
    padding-right: 2px;
  }

  .form-control-validation {
    position: static;
  }
`;
