import styled from 'styled-components/macro';

export const SToggleHandler = styled.button`
  display: block;
  margin: 0 auto;
  padding: 7px 10px 5px 15px;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.brand.color.dark};
  background-color: ${({ theme }) => theme.background.color.light};
  text-transform: uppercase;
  border: 0;
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
`;
