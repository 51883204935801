import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Typography,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import KeyValueItem from '../components/KeyValueItem';
import { useGetAdvisersData } from '../hooks/useGetAdvisersData';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};
const AdvisersWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const advisers = useGetAdvisersData(offeringProfile);
  const title = 'Advisors';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" textTransform="none" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {!advisers.length ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <Grid container columnSpacing={2}>
              {advisers.map(({ name, value }) => {
                return (
                  <KeyValueItem size="base" key={`${name}_${value}`} label={name} value={value} />
                );
              })}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AdvisersWidget;
