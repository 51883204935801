import { numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { DatalabScreens } from '../../datalab/constants';
import PopoverContentTable from '../../shared/components/PopoverContentTable';
import { type Calendar_OfferingFieldsFragment } from '../graphql';

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const LeftLeadFirmNameRenderer: React.FC<Props> = ({ offering }) => {
  const url = routeFactory.datalab.getUrlPath({
    screen: DatalabScreens.UNDERWRITER_OFFERINGS,
    type: 'table',
    query: {
      underwriter: offering.attributes?.leftLeadId,
    },
  });

  const hasEconomics =
    offering.managers && offering.managers.some(u => u.pctUnderwritingBaseShares);

  return (
    <Popover
      variant="TOOLTIP"
      placement="left"
      content={
        <PopoverContentTable>
          <thead>
            <tr>
              <th>Underwriter</th>
              <th>Role</th>
              {hasEconomics ? <th>Economics</th> : null}
            </tr>
          </thead>
          <tbody>
            {offering.managers.map((offeringManager, idx) => (
              <tr key={idx}>
                <td>{offeringManager.name}</td>
                <td>{offeringManager.roleDisplayName}</td>
                {hasEconomics && (
                  <td>
                    {numericUtil.formatPercents(offeringManager.pctUnderwritingBaseShares, 1)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      <Link to={url}>{offering.attributes?.leftLeadName}</Link>
    </Popover>
  );
};

export default LeftLeadFirmNameRenderer;
