import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum ValueAmountButtons {
  AMOUNT = 'amount',
  VALUE = 'value',
}

export const valueAmountButtonsConfig: ButtonsConfig = {
  [ValueAmountButtons.VALUE]: { label: 'VOLUME ($)' },
  [ValueAmountButtons.AMOUNT]: { label: 'VOLUME (#)' },
};

export default getButtonConfigArray(valueAmountButtonsConfig);
