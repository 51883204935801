import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum OfferingDiscountBySizeButtons {
  FROM_FILLING = 'from_filing',
  LAST_TRADE = 'last_trade',
}

export const offeringDiscountBySizeButtonsConfig: ButtonsConfig = {
  [OfferingDiscountBySizeButtons.FROM_FILLING]: { label: 'From Filing' },
  [OfferingDiscountBySizeButtons.LAST_TRADE]: { label: 'Last Trade' },
};

export default getButtonConfigArray(offeringDiscountBySizeButtonsConfig);
