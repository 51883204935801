import * as changeCase from 'change-case';
import flattenDeep from 'lodash/flattenDeep';
import transformObjKeys from 'transform-obj-keys';

function encodePair(key, value, encodeValue = true) {
  return `${encodeURIComponent(key)}=${encodeValue ? encodeURIComponent(value) : value}`;
}

function buildQueryParts(data, prefix = null, delimiter = null) {
  const parts = Object.keys(data)
    .map(property => {
      const value = data[property];
      const name = prefix ? `${prefix}[${property}]` : property;

      if (value instanceof Array) {
        if (!delimiter) {
          return value.map(item => encodePair(`${name}[]`, item));
        }
        return encodePair(name, value.map(item => encodeURIComponent(item)).join(delimiter), false);
      } else if (value !== null && typeof value === 'object') {
        return buildQueryParts(value, name, delimiter);
      } else if (value || value === 0 || value === false) {
        return encodePair(name, value);
      }

      return null;
    })
    .filter(part => part !== null);

  return flattenDeep(parts);
}

// TODO - replace with usage of query-string library (see @cmg/common)
export function encodeQuery(data, delimiter = null) {
  return buildQueryParts(data, null, delimiter).join('&');
}

// TODO remove
export function encodeQueryCamelToSnake(data) {
  return encodeQuery(objectCamelToSnake(data));
}

// TODO remove
export function objectCamelToSnake(object) {
  if (object === null || object === undefined) {
    return object;
  }
  return transformObjKeys(object, changeCase.snakeCase, { deep: true, exclude: ['$id'] });
}

// TODO remove
export function objectSnakeToCamel(object) {
  const convertFn = str => changeCase.camelCase(str).replace(/_/g, '');
  return transformObjKeys(object, convertFn, { deep: true, exclude: ['$id'] });
}
