import { getOperationName } from '@apollo/client/utilities';
import { Icon, ToastManager } from '@cmg/common';
import React, { useState } from 'react';

import { UUID } from '../../../../../types/common';
import {
  Shared_CustomSectorFieldsFragment,
  Shared_CustomSectorListDocument,
} from '../../../../shared/sectors/graphql/__generated__/Shared_CustomSectors';
import {
  useSectorsGrid_DeleteCustomSectorMutation,
  useSectorsGrid_UpdateCustomSectorMutation,
} from '../graphql/__generated__/SectorsGrid_CustomSectors';
import SectorEdit from './SectorEdit';
import {
  SErrorNote,
  SSectorName,
  SSectorRow,
  StyledButton,
  StyledSectorDeleteButton,
  StyledSectorDeleteIconButton,
} from './SectorsGridComponents.styles';

export type Props = {
  sector: Shared_CustomSectorFieldsFragment & {
    children?: Shared_CustomSectorFieldsFragment[];
  };
  parentId?: UUID;
  canUpdate: boolean;
};

const SectorRow = ({ sector, parentId, canUpdate }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [updateCustomSector, { loading: updateLoading }] =
    useSectorsGrid_UpdateCustomSectorMutation({
      onError: error => {
        const messages = error.graphQLErrors
          .map(({ extensions, message }) =>
            extensions ? extensions.errors.map(e => e.message) : message
          )
          .flat();
        console.error('CustomSector update failed', error);
        ToastManager.error(
          `Sector update failed: ${messages.length ? messages.join('; ') : error.message}`
        );
      },
    });

  const handleEdit = async (name: string) => {
    await updateCustomSector({ variables: { id: sector.id, data: { name, parentId } } });
    setEditing(false);
  };

  const [deleteCustomSector, { loading: deleteLoading }] =
    useSectorsGrid_DeleteCustomSectorMutation({
      refetchQueries: [getOperationName(Shared_CustomSectorListDocument)!],
      onError: error => {
        const messages = error.graphQLErrors
          .map(({ extensions, message }) =>
            extensions ? extensions.errors.map(e => e.message) : message
          )
          .flat();
        console.error('CustomSector deletion failed', error);
        ToastManager.error(
          `Sector deletion failed: ${messages.length ? messages.join('; ') : error.message}`
        );
      },
    });

  const handleDelete = async () => {
    await deleteCustomSector({ variables: { id: sector.id } });
    setDeleting(false);
  };

  if (editing) {
    return (
      <SSectorRow>
        <SectorEdit
          sector={sector}
          parentId={parentId}
          loading={updateLoading}
          onEdit={handleEdit}
        />
        <StyledButton onClick={() => setEditing(false)} disabled={updateLoading}>
          Cancel
        </StyledButton>
      </SSectorRow>
    );
  }

  if (deleting) {
    let error: string | undefined;

    if (sector.children?.length) {
      error = 'Please delete sub sectors first';
    }

    return (
      <SSectorRow>
        <SSectorName crossed>{sector.name}</SSectorName>
        {error ? (
          <SErrorNote>{error}</SErrorNote>
        ) : (
          <StyledSectorDeleteButton
            onClick={handleDelete}
            loading={deleteLoading}
            loadingLabel="Deleting..."
          >
            Confirm Delete
          </StyledSectorDeleteButton>
        )}
        <StyledButton onClick={() => setDeleting(false)} disabled={deleteLoading}>
          Cancel
        </StyledButton>
      </SSectorRow>
    );
  }

  return (
    <SSectorRow>
      <SSectorName>{sector.name}</SSectorName> &nbsp;
      {canUpdate && (
        <React.Fragment>
          <StyledButton title="Edit sector button" onClick={() => setEditing(true)}>
            <Icon name="edit" />
          </StyledButton>
          <StyledSectorDeleteIconButton
            title="Delete sector button"
            onClick={() => setDeleting(true)}
          >
            <Icon name="trash-alt" />
          </StyledSectorDeleteIconButton>
        </React.Fragment>
      )}
    </SSectorRow>
  );
};

export default SectorRow;
