import React from 'react';

import {
  NotificationActionType,
  notificationDisplay,
} from '../../../../../types/domain/notifications/constants';
import NotificationFilter from './NotificationFilter';
import {
  SNotificationFilterGroup,
  SNotificationFilterGroupInner,
} from './NotificationFilterGroup.styles';

type GroupProps = {
  label?: React.ReactNode;
  actionTypes: NotificationActionType[];
  selected: NotificationActionType[];
  onAddFilterChange: (actionTypes: NotificationActionType[]) => void;
  onRemoveFilterChange: (actionTypes: NotificationActionType[]) => void;
  hideTree?: boolean;
};

export const NotificationFilterGroup: React.FC<GroupProps> = ({
  label,
  actionTypes,
  selected,
  onAddFilterChange,
  onRemoveFilterChange,
  children,
  hideTree = false,
}) => {
  if (!actionTypes.length) {
    return null;
  }

  const selectedInGroup = actionTypes.filter(a => selected.includes(a));
  const groupChecked = selectedInGroup.length > 0 && selectedInGroup.length === actionTypes.length;

  return (
    <SNotificationFilterGroup>
      <NotificationFilter
        label={label}
        checked={groupChecked}
        indeterminate={!groupChecked && selectedInGroup.length > 0}
        onChange={checked =>
          checked ? onAddFilterChange(actionTypes) : onRemoveFilterChange(actionTypes)
        }
      />
      <SNotificationFilterGroupInner hideTree={hideTree}>
        {children ||
          actionTypes.map((actionType, i) => (
            <NotificationFilter
              label={notificationDisplay[actionType].message}
              checked={selected.includes(actionType)}
              onChange={checked =>
                checked ? onAddFilterChange([actionType]) : onRemoveFilterChange([actionType])
              }
              key={actionType}
            />
          ))}
      </SNotificationFilterGroupInner>
    </SNotificationFilterGroup>
  );
};

export default NotificationFilterGroup;
