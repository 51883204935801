/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompaniesGrid_CustomSectorIssuerPartsFragment = {
  __typename?: 'CustomSectorIssuer';
  id: string;
  customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
  customSubSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
};

export type CompaniesGrid_IssuerPartsFragment = {
  __typename?: 'Issuer';
  id?: string | null;
  name?: string | null;
  primarySymbol?: string | null;
  sectorDisplayName?: string | null;
  subSectorDisplayName?: string | null;
  customSectors?: {
    __typename?: 'CustomSectorIssuer';
    id: string;
    customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
    customSubSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
  } | null;
};

export type CompaniesGrid_IssuerListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.IssuerFilterInput>;
  order?: Types.InputMaybe<Array<Types.IssuerSortInput> | Types.IssuerSortInput>;
}>;

export type CompaniesGrid_IssuerListQuery = {
  __typename?: 'Query';
  issuers?: {
    __typename?: 'IssuerCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Issuer';
      id?: string | null;
      name?: string | null;
      primarySymbol?: string | null;
      sectorDisplayName?: string | null;
      subSectorDisplayName?: string | null;
      customSectors?: {
        __typename?: 'CustomSectorIssuer';
        id: string;
        customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
        customSubSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      offset: number;
      pageSize: number;
    };
  } | null;
};

export type CompaniesGrid_UpsertCustomSectorIssuersMutationVariables = Types.Exact<{
  input: Types.UpsertCustomSectorIssuersInput;
}>;

export type CompaniesGrid_UpsertCustomSectorIssuersMutation = {
  __typename?: 'Mutation';
  upsertCustomSectorIssuers?: {
    __typename?: 'UpsertCustomSectorIssuersPayload';
    customSectorIssuer?: Array<{
      __typename?: 'CustomSectorIssuer';
      id: string;
      customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
      customSubSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
    }> | null;
  } | null;
};

export const CompaniesGrid_CustomSectorIssuerPartsFragmentDoc = gql`
  fragment CompaniesGrid_CustomSectorIssuerParts on CustomSectorIssuer {
    id
    customSector {
      id
      name
    }
    customSubSector {
      id
      name
    }
  }
`;
export const CompaniesGrid_IssuerPartsFragmentDoc = gql`
  fragment CompaniesGrid_IssuerParts on Issuer {
    id
    name
    primarySymbol
    sectorDisplayName
    subSectorDisplayName
    customSectors {
      ...CompaniesGrid_CustomSectorIssuerParts
    }
  }
  ${CompaniesGrid_CustomSectorIssuerPartsFragmentDoc}
`;
export const CompaniesGrid_IssuerListDocument = gql`
  query CompaniesGrid_IssuerList(
    $skip: Int
    $take: Int
    $where: IssuerFilterInput
    $order: [IssuerSortInput!]
  ) {
    issuers(skip: $skip, take: $take, where: $where, order: $order) {
      items {
        ...CompaniesGrid_IssuerParts
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        offset
        pageSize
      }
      totalCount
    }
  }
  ${CompaniesGrid_IssuerPartsFragmentDoc}
`;

/**
 * __useCompaniesGrid_IssuerListQuery__
 *
 * To run a query within a React component, call `useCompaniesGrid_IssuerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesGrid_IssuerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesGrid_IssuerListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCompaniesGrid_IssuerListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompaniesGrid_IssuerListQuery,
    CompaniesGrid_IssuerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompaniesGrid_IssuerListQuery, CompaniesGrid_IssuerListQueryVariables>(
    CompaniesGrid_IssuerListDocument,
    options
  );
}
export function useCompaniesGrid_IssuerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompaniesGrid_IssuerListQuery,
    CompaniesGrid_IssuerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompaniesGrid_IssuerListQuery, CompaniesGrid_IssuerListQueryVariables>(
    CompaniesGrid_IssuerListDocument,
    options
  );
}
export function useCompaniesGrid_IssuerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompaniesGrid_IssuerListQuery,
        CompaniesGrid_IssuerListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompaniesGrid_IssuerListQuery,
    CompaniesGrid_IssuerListQueryVariables
  >(CompaniesGrid_IssuerListDocument, options);
}
export type CompaniesGrid_IssuerListQueryHookResult = ReturnType<
  typeof useCompaniesGrid_IssuerListQuery
>;
export type CompaniesGrid_IssuerListLazyQueryHookResult = ReturnType<
  typeof useCompaniesGrid_IssuerListLazyQuery
>;
export type CompaniesGrid_IssuerListSuspenseQueryHookResult = ReturnType<
  typeof useCompaniesGrid_IssuerListSuspenseQuery
>;
export type CompaniesGrid_IssuerListQueryResult = Apollo.QueryResult<
  CompaniesGrid_IssuerListQuery,
  CompaniesGrid_IssuerListQueryVariables
>;
export const CompaniesGrid_UpsertCustomSectorIssuersDocument = gql`
  mutation CompaniesGrid_UpsertCustomSectorIssuers($input: UpsertCustomSectorIssuersInput!) {
    upsertCustomSectorIssuers(input: $input) {
      customSectorIssuer {
        ...CompaniesGrid_CustomSectorIssuerParts
      }
    }
  }
  ${CompaniesGrid_CustomSectorIssuerPartsFragmentDoc}
`;
export type CompaniesGrid_UpsertCustomSectorIssuersMutationFn = Apollo.MutationFunction<
  CompaniesGrid_UpsertCustomSectorIssuersMutation,
  CompaniesGrid_UpsertCustomSectorIssuersMutationVariables
>;

/**
 * __useCompaniesGrid_UpsertCustomSectorIssuersMutation__
 *
 * To run a mutation, you first call `useCompaniesGrid_UpsertCustomSectorIssuersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompaniesGrid_UpsertCustomSectorIssuersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companiesGridUpsertCustomSectorIssuersMutation, { data, loading, error }] = useCompaniesGrid_UpsertCustomSectorIssuersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompaniesGrid_UpsertCustomSectorIssuersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompaniesGrid_UpsertCustomSectorIssuersMutation,
    CompaniesGrid_UpsertCustomSectorIssuersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompaniesGrid_UpsertCustomSectorIssuersMutation,
    CompaniesGrid_UpsertCustomSectorIssuersMutationVariables
  >(CompaniesGrid_UpsertCustomSectorIssuersDocument, options);
}
export type CompaniesGrid_UpsertCustomSectorIssuersMutationHookResult = ReturnType<
  typeof useCompaniesGrid_UpsertCustomSectorIssuersMutation
>;
export type CompaniesGrid_UpsertCustomSectorIssuersMutationResult =
  Apollo.MutationResult<CompaniesGrid_UpsertCustomSectorIssuersMutation>;
export type CompaniesGrid_UpsertCustomSectorIssuersMutationOptions = Apollo.BaseMutationOptions<
  CompaniesGrid_UpsertCustomSectorIssuersMutation,
  CompaniesGrid_UpsertCustomSectorIssuersMutationVariables
>;
