import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import styled from 'styled-components/macro';

export const StyledKeyboardArrowUp = styled(KeyboardArrowUp)`
  color: #233249;
  opacity: 0.54;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;

export const StyledKeyboardArrowDown = styled(KeyboardArrowDown)`
  color: #233249;
  opacity: 0.54;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;

export const SContainer = styled.div`
  margin: 0px 5px 16px 40px;
`;

export const StyledNoSpaceRow = styled.td`
  margin: 0px;
  padding: 0px;
`;
