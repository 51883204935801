import { Icon, IconButton, Popover, timeUtil } from '@cmg/common';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import {
  notificationDisplay,
  NotificationResourceType,
} from '../../../../../types/domain/notifications/constants';
import {
  Notification,
  NotificationDetail,
  NotificationResource,
} from '../../../../../types/domain/notifications/notification';
import { OfferingType, offeringTypeLabels } from '../../../../../types/domain/offering/constants';
import {
  SNotificationContent,
  SNotificationContentInner,
  SNotificationGroup,
  SNotificationIssuer,
  SNotificationMessage,
  SNotificationMetadata,
  SNotificationOfferingType,
  SNotificationPublishedOn,
  SNotificationTicker,
  SNotificationTitle,
  SNotificationTitleGroup,
  SNotificationUnread,
  SNotificationWrapper,
  StyledLink,
} from './BaseNotification.styles';

type Props = {
  notification: Notification;
  resourcePath: string;
  onMarkRead: (notification: Notification, isRead: boolean) => void;
  onRedirectToResource?: () => void;
  title?: string;
  message?: string;
  ticker?: string;
  issuer?: string;
  offeringType?: OfferingType;
};

export type BaseNotificationProps = Omit<Props, 'group' | 'message' | 'groupName' | 'resourcePath'>;

/*
 * given resources[], find resource by resourceType and return id if found
 * imported by actionType specific components to construct resourcePath
 */
export const getResourceId = (
  resources: NotificationResource[],
  resourceType: NotificationResourceType
) => {
  const type = resources.find(resource => resource.resourceType === resourceType);
  return type ? type.id : undefined;
};

/*
 * Base functionality for Notification which can be overriden via props from actionType specific components
 */
const BaseNotification: React.FC<Props> = ({
  notification,
  resourcePath,
  onMarkRead,
  onRedirectToResource,
  title,
  message,
  ticker,
  issuer,
  offeringType,
}) => {
  const { actionType, isRead, publishedOn } = notification;

  const {
    group: { icon, name },
  }: NotificationDetail = notificationDisplay[actionType];

  const onToggleStatus = e => {
    e.stopPropagation();
    onMarkRead(notification, isRead ? false : true);
    e.preventDefault();
  };

  const onClick = e => {
    !isRead && onMarkRead(notification, true);
    onRedirectToResource && onRedirectToResource();
  };

  return (
    <ThemeConsumer>
      {theme => (
        <StyledLink to={resourcePath} onClick={onClick}>
          <SNotificationWrapper unread={!isRead}>
            <SNotificationMetadata unread={!isRead}>
              <SNotificationGroup>
                {icon && (
                  <Icon {...icon} fixedWidth={true} size="lg" color={theme.brand.color.dark} />
                )}
                <span>{name}</span>
              </SNotificationGroup>
              <SNotificationPublishedOn>
                {formatDistanceToNow(timeUtil.getDate(publishedOn), { addSuffix: true })}
              </SNotificationPublishedOn>
            </SNotificationMetadata>
            <SNotificationContent>
              <SNotificationContentInner>
                <SNotificationTitleGroup>
                  {/* display logic handled in notification type */}
                  {title && <SNotificationTitle>{title}</SNotificationTitle>}
                  {ticker && <SNotificationTicker>{ticker}</SNotificationTicker>}
                  {issuer && <SNotificationIssuer>{issuer}</SNotificationIssuer>}
                  {offeringType && (
                    <SNotificationOfferingType>
                      {offeringTypeLabels[offeringType]}
                    </SNotificationOfferingType>
                  )}
                </SNotificationTitleGroup>
                {message && <SNotificationMessage title={message}>{message}</SNotificationMessage>}
              </SNotificationContentInner>
              <Popover
                variant="DARK"
                placement="left"
                content={<span>Mark as {isRead ? 'Unread' : 'Read'}</span>}
              >
                <SNotificationUnread unread={!isRead} onClick={onToggleStatus}>
                  {!isRead ? (
                    <IconButton
                      icon={{
                        fixedWidth: true,
                        variant: 'solid',
                        name: 'circle',
                        size: 'xs',
                        color: theme.interactive.primary,
                      }}
                    />
                  ) : (
                    <IconButton
                      icon={{
                        fixedWidth: true,
                        variant: 'light',
                        name: 'circle',
                        size: 'xs',
                        color: theme.text.color.light,
                      }}
                    />
                  )}
                </SNotificationUnread>
              </Popover>
            </SNotificationContent>
          </SNotificationWrapper>
        </StyledLink>
      )}
    </ThemeConsumer>
  );
};

export default BaseNotification;
