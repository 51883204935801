import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import {
  CustomLegend,
  DoughnutChart,
  DoughnutChartProps,
} from '../../../../../common/components/charts';
import { getTypeColor } from '../../../colors';
import { SChart, SDatalabChart, SDoughnut } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    color_code: string | null;
    values: {
      amount: number;
    };
  }[];
};

const transformData = (chartData: Props['chartData'] = []): DoughnutChartProps['data'] => {
  const field = 'amount';

  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        data: chartData.map(item => item.values[field]),
        backgroundColor: chartData.map(item => getTypeColor(item.label)),
      },
    ],
  };
};

const getOptions = (): DoughnutChartProps['options'] => {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const value = tooltipItem.parsed;
            const total = tooltipItem.dataset.data.reduce(
              (prevTotal, curValue) => prevTotal + curValue
            );
            const formattedValue = numericUtil.formatCurrencyInBillions(tooltipItem.parsed);
            return `${formattedValue} (${numericUtil.formatPercents(value / total, 1)})`;
          },
        },
      },
    },
  };
};

const WidgetFeesByType: React.FC<Props> = ({ chartData = [] }) => {
  const transformedData = React.useMemo(() => transformData(chartData), [chartData]);
  const options = React.useMemo(() => getOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title="Fees by Type" />

      <Box.Content>
        <SDoughnut>
          <SChart>
            <DoughnutChart data={transformedData} options={options} />
          </SChart>

          <CustomLegend
            chartData={transformedData}
            chartType="doughnut"
            valueFormatter={numericUtil.formatCurrencyInBillions}
          />
        </SDoughnut>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetFeesByType;
