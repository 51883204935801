/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Shared_SectorListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type Shared_SectorListQuery = {
  __typename?: 'Query';
  sectors?: {
    __typename?: 'SectorsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'EnumLabelOfSector';
      id: Types.Sector;
      value: number;
      displayName: string;
    }> | null;
  } | null;
};

export type Shared_SubSectorListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type Shared_SubSectorListQuery = {
  __typename?: 'Query';
  subSectors?: {
    __typename?: 'SubSectorsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'EnumLabelOfSubSector';
      id: Types.SubSector;
      value: number;
      displayName: string;
    }> | null;
  } | null;
};

export const Shared_SectorListDocument = gql`
  query Shared_SectorList {
    sectors(take: 500) {
      items {
        id
        value
        displayName
      }
      totalCount
    }
  }
`;

/**
 * __useShared_SectorListQuery__
 *
 * To run a query within a React component, call `useShared_SectorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShared_SectorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShared_SectorListQuery({
 *   variables: {
 *   },
 * });
 */
export function useShared_SectorListQuery(
  baseOptions?: Apollo.QueryHookOptions<Shared_SectorListQuery, Shared_SectorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Shared_SectorListQuery, Shared_SectorListQueryVariables>(
    Shared_SectorListDocument,
    options
  );
}
export function useShared_SectorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Shared_SectorListQuery, Shared_SectorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Shared_SectorListQuery, Shared_SectorListQueryVariables>(
    Shared_SectorListDocument,
    options
  );
}
export function useShared_SectorListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Shared_SectorListQuery, Shared_SectorListQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Shared_SectorListQuery, Shared_SectorListQueryVariables>(
    Shared_SectorListDocument,
    options
  );
}
export type Shared_SectorListQueryHookResult = ReturnType<typeof useShared_SectorListQuery>;
export type Shared_SectorListLazyQueryHookResult = ReturnType<typeof useShared_SectorListLazyQuery>;
export type Shared_SectorListSuspenseQueryHookResult = ReturnType<
  typeof useShared_SectorListSuspenseQuery
>;
export type Shared_SectorListQueryResult = Apollo.QueryResult<
  Shared_SectorListQuery,
  Shared_SectorListQueryVariables
>;
export const Shared_SubSectorListDocument = gql`
  query Shared_SubSectorList {
    subSectors(take: 500) {
      items {
        id
        value
        displayName
      }
      totalCount
    }
  }
`;

/**
 * __useShared_SubSectorListQuery__
 *
 * To run a query within a React component, call `useShared_SubSectorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShared_SubSectorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShared_SubSectorListQuery({
 *   variables: {
 *   },
 * });
 */
export function useShared_SubSectorListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Shared_SubSectorListQuery,
    Shared_SubSectorListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Shared_SubSectorListQuery, Shared_SubSectorListQueryVariables>(
    Shared_SubSectorListDocument,
    options
  );
}
export function useShared_SubSectorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Shared_SubSectorListQuery,
    Shared_SubSectorListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Shared_SubSectorListQuery, Shared_SubSectorListQueryVariables>(
    Shared_SubSectorListDocument,
    options
  );
}
export function useShared_SubSectorListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Shared_SubSectorListQuery, Shared_SubSectorListQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Shared_SubSectorListQuery, Shared_SubSectorListQueryVariables>(
    Shared_SubSectorListDocument,
    options
  );
}
export type Shared_SubSectorListQueryHookResult = ReturnType<typeof useShared_SubSectorListQuery>;
export type Shared_SubSectorListLazyQueryHookResult = ReturnType<
  typeof useShared_SubSectorListLazyQuery
>;
export type Shared_SubSectorListSuspenseQueryHookResult = ReturnType<
  typeof useShared_SubSectorListSuspenseQuery
>;
export type Shared_SubSectorListQueryResult = Apollo.QueryResult<
  Shared_SubSectorListQuery,
  Shared_SubSectorListQueryVariables
>;
