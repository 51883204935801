import { Icon, Popover, SecondaryButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { MenuItem } from './MenuList';

export const StyledAdditionalActionsButton = styled(SecondaryButton)`
  border: 1px solid ${({ theme }) => theme.color.gray1};
  padding: 9px 12px;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.color.blue1};
    box-shadow: none;
  }
`;

export const SFlex = styled.div`
  display: flex;
`;

type Props = {
  isReportFavorited: boolean;
  onFavoriteReport: () => void;
  showShareReport: boolean;
  onShareReport: () => void;
};

const AdditionalActionsMenuButton: React.FC<Props> = ({
  onFavoriteReport,
  onShareReport,
  showShareReport,
  isReportFavorited,
}) => (
  <Popover
    trigger="focus"
    content={
      <React.Fragment>
        <MenuItem
          label={isReportFavorited ? 'Remove from Favorites' : 'Add to Favorites'}
          onClick={onFavoriteReport}
        />
        {showShareReport && <MenuItem label="Share a Report" onClick={onShareReport} />}
      </React.Fragment>
    }
    placement="bottomRight"
    hideArrow
  >
    <SFlex>
      <StyledAdditionalActionsButton
        type="button"
        onClick={event => {
          // In Firefox and Safari, buttons do not receive focus
          // when they're clicked.  As we're using the "focus"
          // trigger in the Popover containing this node, we
          // need to manually trigger focus so that the Popover appears.
          event.currentTarget.focus();
        }}
      >
        <Icon name="ellipsis-v" />
      </StyledAdditionalActionsButton>
    </SFlex>
  </Popover>
);

export default AdditionalActionsMenuButton;
