import { getCurrencySymbol, numericUtil, Option, timeUtil } from '@cmg/common';
import { Grid, Stack, Typography } from '@cmg/design-system';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import CurrencyFormatter from '../../../common/components/currency-formatter/CurrencyFormatter';
import PerformancePercentsWithTooltip from '../../../common/components/format/performance-percents-with-tooltip/PerformancePercentsWithTooltip';
import { isDefined } from '../../../common/helpers/helpers';
import {
  Country,
  Currency,
  ManagerAttributes,
  OfferingManager,
  OfferingStatus,
  OfferingType,
} from '../../../graphql/__generated__/index';
import { isInternationalOfferingsOn } from '../../datalab/model/utils';
import {
  OfferingProfile_ListQuery,
  OfferingProfile_Terms_PartFragment,
} from '../../offering-dl/offering-profile/graphql/__generated__/OfferingProfile';
import {
  emptyValue,
  ManagerRowItem,
} from '../../offering-dl/offering-profile/profiles/shared/types';
import BooleanIcon from '../components/boolean-icon/BooleanIcon';

export function createOptions(labels: { [key: string]: string }): Option[] {
  return Object.keys(labels).map(value => ({ label: labels[value], value }));
}

export const getCurrencyFormat = ({
  value,
  pricingCurrencyCode = 'USD',
  showInternational = false,
}: {
  value: number | null | undefined;
  pricingCurrencyCode?: string | null;
  showInternational?: boolean;
}): JSX.Element | null => {
  return !isNull(value) && !isUndefined(value) && pricingCurrencyCode ? (
    <CurrencyFormatter
      value={value}
      currency={showInternational ? pricingCurrencyCode : 'USD'}
      displayVariant="symbol"
    />
  ) : null;
};

export const getFormattedCurrencyValue = ({
  value,
  pricingCurrencyCode = 'USD',
  showInternational = false,
}: {
  value?: number | null;
  pricingCurrencyCode?: string;
  showInternational?: boolean;
}): string => {
  const currencyCode = showInternational ? pricingCurrencyCode : 'USD';
  return !isNull(value) && pricingCurrencyCode && currencyCode
    ? numericUtil.formatCurrency(value, 2, getCurrencySymbol(currencyCode))
    : '-';
};

export const getCurrencyFormatInMillions = ({
  value,
  pricingCurrencyCode = 'USD',
  showInternational = false,
  keepSmallValues,
}: {
  value?: number | null;
  pricingCurrencyCode?: string | null;
  showInternational?: boolean;
  keepSmallValues?: boolean;
}): string | null => {
  const currencyCode = showInternational ? pricingCurrencyCode : 'USD';
  return !isNull(value) && pricingCurrencyCode && currencyCode
    ? numericUtil.formatCurrencyInMillions(value, keepSmallValues, getCurrencySymbol(currencyCode))
    : null;
};

export const getCurrencyFormatInBillions = ({
  value,
  pricingCurrencyCode = 'USD',
  showInternational = false,
}: {
  value: number | null;
  pricingCurrencyCode?: string;
  showInternational?: boolean;
}): string | null => {
  const currencyCode = showInternational ? pricingCurrencyCode : 'USD';
  return !isNull(value) && pricingCurrencyCode && currencyCode
    ? numericUtil.formatCurrencyInBillions(value, getCurrencySymbol(currencyCode))
    : null;
};

export const getCurrencyRangeFormat = ({
  valueLow,
  valueHigh,
  pricingCurrencyCode,
  showInternational,
}: {
  valueLow?: number | null;
  valueHigh?: number | null;
  pricingCurrencyCode?: string;
  showInternational: boolean;
}): JSX.Element | null => {
  return !isNull(valueLow) &&
    valueLow !== undefined &&
    !isNull(valueHigh) &&
    valueHigh !== undefined ? (
    <span>
      {getCurrencyFormat({ value: valueLow, pricingCurrencyCode, showInternational })}
      {'\u2013'}
      {getCurrencyFormat({ value: valueHigh, pricingCurrencyCode, showInternational })}
    </span>
  ) : null;
};

export const getFormattedPercentageValue = (value?: number | null, decimals = 2): string =>
  numericUtil.getDisplayValueForPercents(value, decimals);

export const getFormattedPercentageRange = (
  low?: number | null,
  high?: number | null,
  decimals: number = 2
): string => {
  if (low === null || low === undefined || high === null || high === undefined) {
    return '-';
  }
  return `${getFormattedPercentageValue(low, decimals)} \u2013 ${getFormattedPercentageValue(
    high,
    decimals
  )}`;
};

export const getStyledFormattedPercentageRange = (
  low?: number | null,
  high?: number | null,
  decimals: number = 2
): JSX.Element | string => {
  if (low === null || low === undefined || high === null || high === undefined) {
    return '-';
  }
  return (
    <Grid container justifyContent="right">
      <PerformancePercentsWithTooltip value={low} precision={decimals} nullValue="-" />
      {' \u2013 '}
      <PerformancePercentsWithTooltip value={high} precision={decimals} nullValue="-" />
    </Grid>
  );
};

export const getFormattedDateValue = (v?: string | Date | null): string => {
  return timeUtil.formatAsLiteralShortDate(v ?? '') ?? '-';
};

export const getFormattedBooleanValue = (v?: boolean | null): string => {
  if (v === null || v === undefined) {
    return '-';
  }
  return v ? 'Yes' : 'No';
};

export const getFormattedCurrency = (
  currency?: Currency | string | null,
  value?: number | null
) => {
  const showInternational = isInternationalOfferingsOn();

  return getFormattedCurrencyValue({
    value,
    pricingCurrencyCode: currency as string,
    showInternational,
  });
};

export const getFormattedCurrencyInMillion = (
  currency?: Currency | string | null,
  value?: number | null
) => {
  const showInternational = isInternationalOfferingsOn();

  return (
    getCurrencyFormatInMillions({
      value,
      pricingCurrencyCode: currency as string,
      showInternational,
    }) ?? '-'
  );
};

export const managerSortFn = <T extends ManagerAttributes | OfferingManager>(
  a: Partial<T>,
  b: Partial<T>
) => {
  if (
    isNull(a.sequence) ||
    isNull(b.sequence) ||
    isUndefined(a.sequence) ||
    isUndefined(b.sequence)
  ) {
    return 1;
  }

  return (a.sequence as number) - (b.sequence as number);
};

export const getStatusChipColor = (status: OfferingStatus) => {
  switch (status) {
    case OfferingStatus.Filed:
      return 'primary';
    case OfferingStatus.Live:
      return 'success';
    case OfferingStatus.Priced:
      return 'info';
    case OfferingStatus.Postponed:
      return 'warning';
    default:
      return 'error';
  }
};

export const getSellingRestriction = (
  isRegS?: boolean | null,
  isRule144A?: boolean | null,
  emptyValue: string = '-'
) => {
  const restrictions: string[] = [];
  if (isRule144A) {
    restrictions.push('144A');
  }
  if (isRegS) {
    restrictions.push('Reg S');
  }
  return restrictions.length > 0 ? restrictions.join(', ') : emptyValue;
};

export const getDateLabel = (v?: string | Date | null): string => {
  if (!v) {
    return '';
  }
  const now = new Date();
  const targetDate = new Date(v);
  return now.valueOf() < targetDate.valueOf() ? 'Expected ' : '';
};

export const formatManagerRowFn = (
  manager: OfferingManager,
  pricingCurrency?: Currency | null
): ManagerRowItem => {
  const { name, roleDisplayName, underwritingBaseShares, pctUnderwritingBaseShares, estimatedFee } =
    manager;
  return {
    name: name ?? '-',
    role: roleDisplayName ?? '-',
    underwritingBaseShares: numericUtil.getDisplayValueForInteger(underwritingBaseShares),
    pctUnderwritingBaseShares: getFormattedPercentageValue(pctUnderwritingBaseShares),
    estimatedFee: getFormattedCurrencyInMillion(pricingCurrency, estimatedFee),
  };
};

export const isFOOffering = (type?: OfferingType | null): boolean => {
  return (
    !!type &&
    [
      OfferingType.FollowOn,
      OfferingType.MarketedFo,
      OfferingType.OvernightFo,
      OfferingType.RegisteredBlock,
      OfferingType.UnregisteredBlock,
      OfferingType.RegisteredDirect,
      OfferingType.Rights,
    ].includes(type)
  );
};

export const getOfferPriceRange = (
  offeringProfile: OfferingProfile_ListQuery,
  term?: OfferingProfile_Terms_PartFragment | null
): JSX.Element | string => {
  const showInternational = isInternationalOfferingsOn();
  const { type, attributes, pricingCurrency } = offeringProfile.offeringById ?? {};
  const { reOfferLow, reOfferHigh } = attributes ?? {};
  const { ipoRangeLow, ipoRangeHigh, offerPrice } = term ?? {};

  // offer price if available
  if (!!offerPrice) {
    return getFormattedOfferPrice(pricingCurrency, offerPrice);
  }

  // if no offer price and not FO offerring use last price range available
  if (!isFOOffering(type)) {
    return (
      getCurrencyRangeFormat({
        valueLow: ipoRangeLow,
        valueHigh: ipoRangeHigh,
        pricingCurrencyCode: pricingCurrency as string,
        showInternational,
      }) ?? '-'
    );
  }

  // if no offer price and is FO offerring use re offer range or blank
  return (
    getCurrencyRangeFormat({
      valueLow: reOfferLow,
      valueHigh: reOfferHigh,
      pricingCurrencyCode: pricingCurrency as string,
      showInternational,
    }) ?? '-'
  );
};

// if offer price has more than 2 decimal, display all decimal
export const getFormattedOfferPrice = (
  currency?: Currency | string | null,
  value?: number | null
): string => {
  const decimalSplit = value?.toString().split('.') ?? [];
  if (decimalSplit.length > 1 && decimalSplit[1].length > 2) {
    return isDefined(value) && currency
      ? numericUtil.formatCurrency(value, decimalSplit[1].length, getCurrencySymbol(currency))
      : '-';
  }
  return getFormattedCurrency(currency, value);
};

export const getCompanyRepurchase = (
  isCompanyRepurchaseIncluded?: boolean | null,
  isCompanyRepurchaseAdditional?: boolean | null
): JSX.Element | string => {
  let note = '';
  let boolValue: boolean | null = null;
  if (isCompanyRepurchaseIncluded === false && isCompanyRepurchaseAdditional === false) {
    boolValue = false;
  }
  if (isCompanyRepurchaseIncluded) {
    note = 'Included';
    boolValue = true;
  }
  if (isCompanyRepurchaseAdditional) {
    note = 'Additional';
    boolValue = true;
  }
  return (
    <Stack direction="row" alignItems="center">
      <BooleanIcon value={boolValue} />
      <Typography marginLeft={1}>{note}</Typography>
    </Stack>
  );
};

export const emptyValueTypography = <Typography paddingLeft={0.5}>{emptyValue}</Typography>;

export const isUS = (offeringProfile: OfferingProfile_ListQuery) => {
  const { exchangeCountry } = offeringProfile.offeringById ?? {};
  return exchangeCountry === Country.Us;
};
