/**
 * Datalab heat map columns
 */
export const heatmapPerformanceColumns = [
  { label: 'Open', name: 'offer_to_open' },
  { label: '1 Day', name: 'offer_to_1day' },
  { label: '3 Day', name: 'offer_to_3day' },
  { label: '7 Day', name: 'offer_to_7day' },
  { label: '14 Day', name: 'offer_to_14day' },
  { label: '30 Day', name: 'offer_to_30day' },
  { label: '90 Day', name: 'offer_to_90day' },
  { label: '180 Day', name: 'offer_to_180day' },
  { label: 'Current', name: 'offer_to_current' },
];
export const heatmapDropoffColumns = [
  { label: '1 Day', name: 'first_day_turnover' },
  { label: '2 Day', name: 'day2_turnover' },
  { label: '3 Day', name: 'day3_turnover' },
  { label: '4 Day', name: 'day4_turnover' },
  { label: '5 Day', name: 'day5_turnover' },
  { label: '6 Day', name: 'day6_turnover' },
  { label: '7 Day', name: 'day7_turnover' },
  { label: '8 Day', name: 'day8_turnover' },
  { label: '9 Day', name: 'day9_turnover' },
  { label: '10 Day', name: 'day10_turnover' },
];
export const heatmapBreakDownBy = {
  SECTOR: { name: 'sector', label: 'Sector' },
};
