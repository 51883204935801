import { Icon, MediaQuery } from '@cmg/common';
import React from 'react';

import { SIcon, SProfileDropdownButton, SUsername } from './ProfileDropdownButton.styles';

type Props = {
  userGivenAndFamilyName?: string | null;
  testId?: string;
};

const ProfileDropdownButton: React.FC<Props> = ({ userGivenAndFamilyName }) => (
  <SProfileDropdownButton>
    <SIcon>
      <Icon name="user-circle" />
    </SIcon>
    <SUsername>
      <MediaQuery.IsLargeUp>
        {matches => (matches ? `Hi ${userGivenAndFamilyName}` : 'Menu')}
      </MediaQuery.IsLargeUp>
    </SUsername>
  </SProfileDropdownButton>
);

export default ProfileDropdownButton;
