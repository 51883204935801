import { CalendarCategory } from '../../../types/domain/calendar/constants';
import {
  Column,
  countryColumn,
  customSectorColumn,
  expectedPricingDateColumn,
  hasCornerstoneColumn,
  issuerFirmNameColumn,
  leftLeadColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapColumn,
  offeringTypeColumn,
  priceRangeLivePricedColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sellingRestrictionColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { TabProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.LIVE;

export const columnsConfig: Column[] = [
  issuerFirmNameColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sellingRestrictionColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  expectedPricingDateColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  sizeSharesColumn,
  secondarySharesColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapColumn,
  priceRangeLivePricedColumn,
  leftLeadColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
  hasCornerstoneColumn,
];

export const staticColumns: Column[] = [issuerFirmNameColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c => ![secondarySharesColumn, totalBookRunnersColumn, totalNonBookRunnersColumn].includes(c)
  )
  .map(c => c.field);

export const tabConfig = {
  calendarCategory,
  defaultGroupBy: 'attributes.pricingDate',
  defaultGroupByOrderType: 'asc' as const,
  defaultOrderBy: 'issuer.name',
  defaultOrderByType: 'asc' as const,
  columnsConfig,
  staticColumns,
  defaultVisibleColumns: defaultVisibleColumns,
};

const LiveOfferingsCalendar = (props: TabProps) => {
  return <BaseDatalabCalendar {...props} {...tabConfig} />;
};

export default LiveOfferingsCalendar;
