import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  dtc.ord,
  dtc.quantity,
  dtc.quantity_yoy,
  dtc.ipo_quantity,
  dtc.ipo_quantity_yoy,
  dtc.fo_quantity,
  dtc.fo_quantity_yoy,
  dtc.capital,
  dtc.capital_yoy,
  dtc.fee,
  dtc.fee_yoy,
  dtc.file_to_offer_discount,
];

export const visibleFields: string[] = [
  dtc.ord.field,
  dtc.quantity.field,
  dtc.quantity_yoy.field,
  dtc.ipo_quantity.field,
  dtc.fo_quantity.field,
  dtc.capital.field,
  dtc.capital_yoy.field,
  dtc.fee.field,
];

export const staticFields: string[] = [dtc.ord.field, dtc.quantity.field];
