import React from 'react';

import SpinElement from './SpinElement';
import { StyledLoader } from './Spinner.styles';

type OwnProps = {
  show: boolean;
  priority?: number;
  backgroundStyle?: {
    backgroundColor?: string;
  };
  size?: 'small' | 'default';
  fullScreen?: boolean;
  testId?: string;
  className?: string;
};

export type Props = React.PropsWithChildren<OwnProps>;

const Spinner: React.FC<Props> = ({
  show,
  priority,
  fullScreen,
  children,
  testId,
  size = 'default',
  backgroundStyle = { backgroundColor: 'rgba(0,0,0,0.2)' },
  className,
}) => {
  return (
    <StyledLoader
      show={show}
      priority={priority}
      backgroundStyle={
        fullScreen
          ? {
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              ...backgroundStyle,
            }
          : backgroundStyle
      }
      foregroundStyle={
        fullScreen
          ? {
              width: '100vw',
              height: '100vh',
            }
          : undefined
      }
      message={<SpinElement size={size} testId={testId} />}
      className={className}
    >
      {children}
    </StyledLoader>
  );
};

export default Spinner;
