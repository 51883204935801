import PropTypes from 'prop-types';
import { Component } from 'react';

import { TableDataManagementType } from '../../../../../../features/shared/constants/constants';
import { getTotalPages } from '../table-helpers';

const paginationItemsPerPage = [
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export default class TablePagination extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    activePage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalRows: PropTypes.number,
    paginationType: PropTypes.oneOf([
      TableDataManagementType.CLIENT,
      TableDataManagementType.SERVER,
    ]),
    onPaginationChange: PropTypes.func,
  };

  static defaultProps = {
    itemsPerPage: paginationItemsPerPage[2].value,
    onPaginationChange: () => {},
    totalRows: 0,
  };

  constructor(props) {
    super(props);

    const { itemsPerPage, totalRows } = props;

    this.state = {
      itemsPerPage,
      activePage: 1,
      totalPages: getTotalPages(totalRows, itemsPerPage),
    };
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const { itemsPerPage, totalRows } = nextProps;

    if (itemsPerPage !== prevProps.itemsPerPage || totalRows !== prevProps.totalRows) {
      this.setState({
        itemsPerPage,
        activePage: 1,
        totalPages: getTotalPages(totalRows, itemsPerPage),
      });
    }
  }

  handleChangeItemsPerPage = itemsPerPage => {
    const { paginationType, onPaginationChange, totalRows } = this.props;

    if (paginationType === TableDataManagementType.CLIENT) {
      this.setState({
        itemsPerPage,
        activePage: 1,
        totalPages: getTotalPages(totalRows, itemsPerPage),
      });
    } else {
      onPaginationChange({ activePage: 1, itemsPerPage });
    }
  };

  handlePagination = activePage => {
    const { itemsPerPage, onPaginationChange, paginationType } = this.props;

    if (paginationType === TableDataManagementType.CLIENT) {
      this.setState({
        activePage,
      });
    } else {
      onPaginationChange({ activePage, itemsPerPage });
    }
  };

  getClientSidePagination = () => {
    const { activePage, itemsPerPage, totalPages } = this.state;

    return { activePage, itemsPerPage, totalPages };
  };

  getServerSidePagination = () => {
    const { activePage, itemsPerPage, totalPages } = this.props;

    return { activePage, itemsPerPage, totalPages };
  };

  render() {
    const { children, paginationType } = this.props;
    const pagination =
      paginationType === TableDataManagementType.CLIENT
        ? this.getClientSidePagination()
        : this.getServerSidePagination();

    return children({
      ...pagination,
      handleChangeItemsPerPage: this.handleChangeItemsPerPage,
      handlePagination: this.handlePagination,
    });
  }
}
