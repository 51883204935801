import { FormField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SCompaniesFilters = styled.div`
  display: flex;
  margin: 15px 15px 5px;
`;

export const StyledFormField = styled(FormField)`
  flex: 1 1 auto;
  margin: 0 5px;
  min-width: 200px;
  max-width: 300px;
`;
