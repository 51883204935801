import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import React from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';

import { CMGCenteredBarPlugin } from '../plugins/CMGCenteredBarPlugin';

export type Props<TData = (number | null)[]> = Omit<ChartProps<'bar', TData>, 'type'> & {
  barSizingMode?: 'chartjs' | 'CMGCenteredBarPlugin';
};

const defaultBarChartOptions: Props<undefined>['options'] = {
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => {
          const datasetLabel = tooltipItem.dataset.label;
          return `${datasetLabel ? `${datasetLabel}: ` : ''}`;
        },
      },
    },
  },
  datasets: {
    bar: {
      categoryPercentage: 0.7,
      barPercentage: 0.9,
      borderRadius: 20,
      borderSkipped: false,
    },
  },
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
};

const getBarChartOptions = <TData extends unknown>(options: Props<TData>['options']) => {
  return merge(cloneDeep(defaultBarChartOptions), options);
};

export const BarChart = <TData extends unknown>({
  data,
  options,
  plugins = [],
  barSizingMode,
}: Props<TData>) => {
  const mergedOptions = getBarChartOptions(options);

  const combinedPlugins = React.useMemo(() => {
    if (barSizingMode === 'CMGCenteredBarPlugin') {
      return [...plugins, CMGCenteredBarPlugin];
    }

    return plugins;
  }, [plugins, barSizingMode]);

  return <Bar data={data} options={mergedOptions} plugins={combinedPlugins} />;
};
