import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';

import calendarScheduleImage from '../../../../../assets/i-calendar-priced-deals.svg';
import { SNoRecentDeals, STitle } from './NoRecentDeals.styles';

/**
 * NoRecentDeals displayed when no recent deals have been made in the last 2 weeks
 */
const NoRecentDeals: React.FC = () => (
  <SNoRecentDeals data-test-id={myDashboard.noRecentDeals.testId}>
    <img src={calendarScheduleImage} alt="No Recent Deals" />
    <STitle>No Recent Deals in the last 2 weeks</STitle>
    <div>Any new deals will appear here.</div>
  </SNoRecentDeals>
);

export default NoRecentDeals;
