import { Table } from '@cmg/common';
import styled from 'styled-components/macro';

export const STableWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.border.color.dark};
  border-bottom: 1px solid ${({ theme }) => theme.border.color.dark};
`;

export const STable = styled(Table)`
  width: 100%;

  td {
    padding: 4px 0;
  }

  th,
  td {
    vertical-align: middle !important;
    width: 20%;
  }

  th:first-child,
  td:first-child {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th:last-child,
  td:last-child {
    width: 100px;
  }
`;

export const SSortButton = styled.button`
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
`;
