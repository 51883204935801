export type TableColumnProps = {
  hasSplits?: boolean | null;
  canReadAtmSelldown: boolean;
  hasIoiPermission: boolean;
  showInternational: boolean;
};

export enum TableKeys {
  type,
  status,
  publicFilingDate,
  announcementDate,
  marketTimingDisplayName,
  announcementTime,
  terminatedDate,
  atm_program_remaining,
  atm_program_remaining_in_securities,
  statusRelevantDate,
  attributes_latestGrossProceedsTotal,
  atm_attributes_latestGrossProceedsTotal,
  atm_attributes_latestProgramSize,
  attributes_latestSizeInSecuritiesTotal,
  atm_attributes_latestSizeInSecuritiesTotal,
  atm_attributes_latestProgramSizeInSecurities,
  attributes_marketCapAtPricing,
  attributes_marketCapPreOffering,
  attributes_pctMarketCapPreOffering,
  attributes_pctMarketCapAtPricing,
  attributes_latestOfferPrice,
  attributes_netPrice,
  attributes_splitAdjustedOfferPrice,
  lastAllocation,
  lastAllocation_fillPercentage,
  pctOfDeal,
  attributes_pctToLastTrade,
  attributes_pctOfferToOpen,
  attributes_pctOfferTo1Day,
  attributes_pctOfferToCurrent,
  attributes_leftLeadName,
}
