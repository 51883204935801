import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum PlCreditButtons {
  ECONOMICS = 'economics',
  PL_CREDIT = 'pl_credit',
}

export const plCreditButtonsConfig: ButtonsConfig = {
  [PlCreditButtons.ECONOMICS]: { label: 'Economic Wtd.' },
  [PlCreditButtons.PL_CREDIT]: { label: 'P&L Wtd.' },
};

export default getButtonConfigArray(plCreditButtonsConfig);
