import styled from 'styled-components/macro';

export const SProfileDropdownButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const SIcon = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
`;

export const SUsername = styled.div`
  padding-top: 1px;
  padding-left: 5px;
  line-height: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.text.color.dark};
`;
