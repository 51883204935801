import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
        border-bottom: 1px solid ${({ theme }) => theme.border.color.light};
      }

      > div {
        margin-bottom: 5px;
      }
    }
  }
`;

export const SPillContainer = styled.span`
  font-size: ${({ theme }) => theme.text.size.small};
  vertical-align: middle;
  display: inline-block;
`;

export const SNoteContent = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  white-space: pre-line;
  word-break: break-all;
`;

export const SNoteFooter = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SNoteHeader = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .icon {
    margin-right: 5px;
  }
`;
