import { datalabApi, ErrorApiResponse, SuccessApiResponse } from '@cmg/api';
import { Collapse, ToastManager } from '@cmg/common';
import { datalabTableSelector } from '@cmg/e2e-selectors';
import { GridReadyEvent } from 'ag-grid-community';
import React from 'react';

import FiltersToggleButton from '../../../common/components/filter-form-toggle-button/FilterFormToggleButton';
import { DatalabScreens } from '../../datalab/constants';
import { isDownloadExcelOn } from '../../datalab/model/utils';
import {
  ROWS_PER_PAGE,
  useAGGridGetRows,
} from '../../offering-dl/offering-profile/hooks/useAGGridGetRows';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import { useOfferingFilters } from '../components/offerings-filter-form/hooks/useOfferingFilters';
import OfferingsFilterForm from '../components/offerings-filter-form/OfferingsFilterForm';
import {
  getExcelDownloadArgs,
  getSortingModel,
} from '../components/offerings-report-table/OfferingsReportTable.model';
import GlobalLeagueTable from './components/global-league-table/GlobalLeagueTable';
import {
  alwaysVisibleColumns,
  computedColumns,
  defaultSortModel,
  excludedOfferingTypes,
  orderedGlobalLeagueColumns,
} from './GlobalLeagueScreen.model';
import {
  SScreenContent,
  SScreenWrapper,
  STableWrapper,
  StyledPanel,
} from './GlobalLeagueScreen.styles';
import { useGlobalLeagueTableQuery } from './graphql/__generated__/GlobalLeagueTable';

const GlobalLeagueScreen = () => {
  const gridRef = React.useRef<GridReadyEvent>();

  const {
    filters,
    setFilters,
    isFiltersExpanded,
    setIsFiltersExpanded,
    gqlFilterInput,
    filterCount,
  } = useOfferingFilters(DatalabScreens.GLOBAL_LEAGUE);

  const [numRowsFetched, setNumRowsFetched] = React.useState({
    showing: 0,
    total: 0,
  });

  const { queryVariables, onDataUpdated, getRows } = useAGGridGetRows({
    getSortingModel,
    defaultSortModel,
  });

  const { data, error, loading } = useGlobalLeagueTableQuery({
    ...queryVariables,
    variables: {
      ...queryVariables.variables,
      where: gqlFilterInput,
    },
  });

  React.useEffect(() => {
    gridRef.current?.api.purgeInfiniteCache();
    gridRef.current?.api.showLoadingOverlay();
    gridRef.current?.api.setRowCount(0);
  }, [filters]);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Failed to load Global League Table.');
      onDataUpdated({ error });
      return;
    }
    if (!loading && data) {
      const globalLeagueTable = data?.globalLeagueTable;
      const total = globalLeagueTable?.totalCount || 0;
      const endRow = (globalLeagueTable?.pageInfo.offset ?? 0) + ROWS_PER_PAGE;

      onDataUpdated({
        items: globalLeagueTable?.items ?? [],
        lastRow: total <= endRow ? total : undefined,
      });

      setNumRowsFetched({
        showing: endRow < total ? endRow : total,
        total,
      });
      if (total === 0) {
        gridRef?.current?.api.showNoRowsOverlay(); // display "no rows message"
      } else {
        gridRef?.current?.api.hideOverlay();
      }
    }
  }, [data, error, loading, onDataUpdated]);

  const handleFiltersToggleClick = React.useCallback(() => {
    setIsFiltersExpanded(isFiltersExpanded => !isFiltersExpanded);
  }, [setIsFiltersExpanded]);

  const onDownload = React.useCallback(
    async (selectedOptions: string[]): Promise<ErrorApiResponse | SuccessApiResponse<Blob>> => {
      const selectedColumns = gridRef?.current?.columnApi
        .getAllDisplayedColumns()
        .filter(column => !computedColumns.includes(column.getId()));
      const sortModel = gridRef?.current?.api.getSortModel();
      const downloadArgs = getExcelDownloadArgs({
        selectedColumns,
        gqlFilterInput,
        sortModel,
        selectedOptions,
        defaultSortModel,
        baseGqlFields: {},
      });
      const respond = await datalabApi.downloadGlobalLeagueTable(downloadArgs, {});
      return respond;
    },
    [gqlFilterInput, gridRef]
  );

  return (
    <SScreenWrapper>
      <ScreenHeader />
      <SScreenContent>
        <StyledPanel>
          <FiltersToggleButton
            isFiltersExpanded={isFiltersExpanded}
            filterCount={filterCount}
            onClick={handleFiltersToggleClick}
          />
          <Collapse isExpanded={isFiltersExpanded}>
            <OfferingsFilterForm
              setFilter={setFilters}
              reportScreen={DatalabScreens.GLOBAL_LEAGUE}
              excludedOfferingTypes={excludedOfferingTypes}
            />
          </Collapse>
        </StyledPanel>
        <STableWrapper data-test-id={datalabTableSelector('global-league').testId}>
          <GlobalLeagueTable
            gridRef={gridRef}
            getRows={getRows}
            isLoading={loading}
            numberOfRowsFetched={numRowsFetched.showing}
            totalRows={numRowsFetched.total}
            alwaysVisibleColumns={alwaysVisibleColumns}
            columnsDef={orderedGlobalLeagueColumns}
            onDownload={isDownloadExcelOn() ? onDownload : undefined}
            title="Global League Table"
          />
        </STableWrapper>
      </SScreenContent>
    </SScreenWrapper>
  );
};

export default GlobalLeagueScreen;
