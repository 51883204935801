import { numericUtil } from '@cmg/common';
import React from 'react';

import { SValueRenderer } from './Renderer.styles';

type Props = {
  value?: number;
  precision?: number;
  symbol?: string;
  isSymbolBefore?: boolean;
};

const CurrencyValueRenderer: React.FC<Props> = ({
  isSymbolBefore = true,
  precision = 2,
  symbol = '$',
  value = 0,
}) => (
  <SValueRenderer>
    {isSymbolBefore ? symbol : ''}
    {numericUtil.formatNumber(value, precision)}
    {isSymbolBefore ? '' : symbol}
  </SValueRenderer>
);

export default CurrencyValueRenderer;
