import { Form } from 'formik';
import styled from 'styled-components/macro';

export const StyledForm = styled(Form)`
  background-color: ${({ theme }) => theme.background.color.white};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 0 15px 15px 15px;
  justify-content: space-between;
`;

export const SToolbarSection = styled.div<{ alignItems: string }>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
`;

export const SDatalabReportSelectFieldWrapper = styled.div`
  margin-right: 15px;
`;

export const SReportModifiedDate = styled.div`
  margin-left: 15px;
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.text.color.light};
`;
