import React from 'react';
import styled from 'styled-components/macro';

import BlurOverlay from '../../../common/blur-overlay/BlurOverlay';
import SubscriptionNotice from './SubscriptionNotice';

export const SParticipationSections = styled.div`
  position: relative;
`;

/** position the subscription notice on top of my participation data
 * and passed data
 */
export const StyledSubscriptionNoticeOverlay = styled(BlurOverlay)`
  position: absolute;
  right: 0;
  bottom: 0;
  /** positions the container nicely below the participation detail header */
  top: 74px;
  left: 0;

  /* The purpose of this container is to display on top of the blurred overlay
  which is why we are adding +1 to its zIndex value */
  z-index: ${({ theme }) => theme.zIndex.contentOverlay + 1};
`;

type Props = {
  hasMyDatalab: boolean;
};

/**
 * MyDataLabSubscription renders a subscription notice overlay above its children
 * when hasMyDatalab is false
 */
const MyDataLabSubscription: React.FC<Props> = ({ children, hasMyDatalab }) => (
  <SParticipationSections>
    {children}
    {!hasMyDatalab && (
      <StyledSubscriptionNoticeOverlay>
        <SubscriptionNotice />
      </StyledSubscriptionNoticeOverlay>
    )}
  </SParticipationSections>
);

export default MyDataLabSubscription;
