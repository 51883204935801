import { numericUtil } from '@cmg/common';
import isNumber from 'lodash/isNumber';
import React from 'react';

import { OfferingShareholder } from '../../../../../../graphql/__generated__';
import {
  emptyValueTypography,
  getFormattedPercentageValue,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';

const shareholderSortFn = (
  shareholderA: OfferingShareholder,
  shareholderB: OfferingShareholder
) => {
  if (isNumber(shareholderA.preOfferingOwnership) && isNumber(shareholderB.preOfferingOwnership)) {
    return shareholderB.preOfferingOwnership - shareholderA.preOfferingOwnership;
  }
  if (!isNumber(shareholderA.preOfferingOwnership)) {
    return 1;
  }
  return -1;
};

export const useGetShareholdersData = (offeringProfile: OfferingProfile_ListQuery) =>
  React.useMemo(() => {
    const { shareholders: shareholdersData } = offeringProfile.offeringById ?? {};

    // slice used since query results are read only, use toSorted in the future (node v20)
    const shareholders = (shareholdersData ?? [])
      .slice()
      .sort(shareholderSortFn)
      .map(shareholder => ({
        name: shareholder.name,
        type: shareholder.isCarveOutParent
          ? 'Carve Out Parent'
          : shareholder.shareholderTypeDisplayName,
        preOfferingShares: isNumber(shareholder.preOfferingOwnership)
          ? numericUtil.getDisplayValueForInteger(shareholder.preOfferingOwnership)
          : emptyValueTypography,
        preOfferingPct: isNumber(shareholder.pctPreOfferingOwnership)
          ? getFormattedPercentageValue(shareholder.pctPreOfferingOwnership)
          : emptyValueTypography,
        postOfferingShares: isNumber(shareholder.postOfferingOwnership)
          ? numericUtil.getDisplayValueForInteger(shareholder.postOfferingOwnership)
          : emptyValueTypography,
        postOfferingPct: isNumber(shareholder.pctPostOfferingOwnership)
          ? getFormattedPercentageValue(shareholder.pctPostOfferingOwnership)
          : emptyValueTypography,
        postOfferingOvlt: isNumber(shareholder.postOfferingOwnershipIncludingShoe)
          ? numericUtil.getDisplayValueForInteger(shareholder.postOfferingOwnershipIncludingShoe)
          : emptyValueTypography,
        postOfferingOvltPct: isNumber(shareholder.pctPostOfferingOwnershipIncludingShoe)
          ? getFormattedPercentageValue(shareholder.pctPostOfferingOwnershipIncludingShoe)
          : emptyValueTypography,
      }));

    return { shareholders };
  }, [offeringProfile.offeringById]);
