import { Box, numericUtil } from '@cmg/common';
import React, { useMemo } from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      count: number;
      mean: number;
      median: number;
    };
  }[];
};

type RawItem = {
  y: number;
  x: string;
  count: number;
};

type ChartProps = BarChartProps<RawItem[]>;

const transformData = (chartData: Props['chartData'] = []): ChartProps['data'] => {
  return {
    datasets: [
      {
        label: 'Mean',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.mean,
            x: item.label,
            count: item.values.count,
          })
        ),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Median',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.median,
            x: item.label,
            count: item.values.count,
          })
        ),
        backgroundColor: barChartColors.light,
      },
    ],
  };
};

const getOptions = (): BarChartProps['options'] => {
  return {
    scales: {
      x: {
        title: {
          text: 'Offering Size',
        },
      },
      y: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatMultipleFactor(value) : value,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset } = tooltipItem;
            const raw = tooltipItem.raw as RawItem;
            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;

            return `${dataset.label ? `${dataset.label}: ` : ''}${numericUtil.formatMultipleFactor(
              tooltipItem.parsed.y
            )}${countString ?? ''}`;
          },
        },
      },
    },
  };
};

const WidgetFirstDayVolumeDividedByDealShares: React.FC<Props> = ({ chartData }) => {
  const chartDataMemo = useMemo(() => transformData(chartData), [chartData]);
  const optionsMemo = useMemo(() => getOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title="First Day Turnover By Size" />
      <Box.Content>
        <SChart>
          <BarChart
            barSizingMode="CMGCenteredBarPlugin"
            data={chartDataMemo}
            options={optionsMemo}
          />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetFirstDayVolumeDividedByDealShares;
