import { ToastManager } from '@cmg/common';

import {
  useShared_SectorListQuery,
  useShared_SubSectorListQuery,
} from './graphql/__generated__/Shared_Sectors';

export default function useSectorsLookup() {
  const { data: sectorsData } = useShared_SectorListQuery({
    onError: error => {
      console.error('Sectors lookup failed:', error);
      ToastManager.error('Sectors lookup failed');
    },
    fetchPolicy: 'cache-first',
  });

  const { data: subSectorsData } = useShared_SubSectorListQuery({
    onError: error => {
      console.error('SubSectors lookup failed:', error);
      ToastManager.error('SubSectors lookup failed');
    },
  });

  return {
    sectors: sectorsData?.sectors?.items ?? [],
    subSectors: subSectorsData?.subSectors?.items ?? [],
  };
}
