import { numericUtil } from '@cmg/common';
import React from 'react';

import PerformanceValue from '../performance-value/PerformanceValue';

export type Props = {
  value?: number | null;
  nullValue?: string;
  precision?: number;
};

/**
 * Formats a percent value and add css classes to colorize the result
 */
const PerformancePercents: React.FC<Props> = ({ precision = 1, value, nullValue }) => (
  <PerformanceValue
    value={value}
    nullValue={nullValue}
    formatter={value => numericUtil.formatPercents(value, precision)}
  />
);

export default PerformancePercents;
