import { createFormikField, FieldControlProps } from '@cmg/common';
import AntTreeSelect from 'antd/lib/tree-select';
import memoize from 'memoize-one';
import React from 'react';

import { STreeWrapper } from './TreeSelect.styles';

const getPopupContainer = node => node.parentNode;
const transformSectorsToTreeData = memoize(sectors =>
  sectors.map(sector => ({
    ...sector,
    children: sector.children
      ? sector.children.map(subSector => ({
          ...subSector,
        }))
      : [],
  }))
);

export interface Props extends FieldControlProps<any, any> {
  className?: string;
  maxTagCount?: number;
  treeData: any[];
  treeCheckable?: boolean;
  testId?: string;
}

// TODO - This component has been duplicated in `@cmg/common`. Delete and refactor instances to use common.
export default class TreeSelect extends React.Component<Props> {
  static defaultProps = {
    maxTagCount: 3,
    treeCheckable: true,
  };

  render() {
    const { disabled, value, treeData, onChange, maxTagCount, className, treeCheckable, testId } =
      this.props;

    const data = transformSectorsToTreeData(treeData);

    return (
      <STreeWrapper className={className} data-test-id={testId}>
        <AntTreeSelect
          disabled={disabled}
          value={value}
          treeData={data}
          onChange={onChange}
          maxTagCount={maxTagCount}
          treeCheckable={treeCheckable}
          showCheckedStrategy={AntTreeSelect.SHOW_PARENT}
          treeNodeFilterProp="title"
          placeholder="Search..."
          getPopupContainer={getPopupContainer}
          allowClear
          listHeight={400}
        />
      </STreeWrapper>
    );
  }
}

export const TreeSelectField = createFormikField<any, any, HTMLInputElement, Props>(TreeSelect);
