import reduce from 'lodash/reduce';
import { show } from 'redux-modal';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const MODAL_CONFIRM = 'MODAL_CONFIRM';
export const MODAL_CANCEL = 'MODAL_CANCEL';

export const createActionType = (...parts: string[]): string => parts.join('_');

export function createActionCreator<Payload>(...type: string[]) {
  return function (payload?: Payload, meta?) {
    return {
      type: createActionType(...type),
      payload,
      meta,
    };
  };
}

export function createApiActionCreators<RequestPayload, SuccessPayload, FailurePayload>(
  ...type: string[]
) {
  return {
    request: createActionCreator<RequestPayload>(...type, REQUEST),
    success: createActionCreator<SuccessPayload>(...type, SUCCESS),
    failure: createActionCreator<FailurePayload>(...type, FAILURE),
  };
}

export const createModalActionTypes = (modalId: string) => ({
  modal: modalId,
  confirm: createActionType(modalId, MODAL_CONFIRM),
  cancel: createActionType(modalId, MODAL_CANCEL),
});

export const createModalActionCreators = (modalId: string) => {
  const modalActionTypes = createModalActionTypes(modalId);

  return {
    modal: modalId,
    open: props => show(modalId, props),
    confirm: createActionCreator(modalActionTypes.confirm),
    cancel: createActionCreator(modalActionTypes.cancel),
  };
};

/**
 *
 * @deprecated use createReducer from reduxUtil in common package instead
 */
// TODO add: type Reducer<T> = (state: T | undefined, action: AnyAction) => T;
export const createReducer = (initialState, reducerMap) => {
  const iterator = (reducers, initial = {}, prefix: string[] = []) =>
    reduce(
      reducers,
      (acc, reducer, type) => {
        if (typeof reducer === 'function') {
          return { ...acc, [createActionType(...prefix, type)]: reducer };
        }
        if (typeof reducer !== 'object') {
          throw new Error(
            "createReducer expect 'Object' tree with 'Leafs' (A node with no children) containing function that takes arguments (state, action)"
          );
        }
        return iterator(reducer, acc, [createActionType(...prefix, type)]);
      },
      initial
    );

  const flattened = iterator(reducerMap);

  return (state = initialState, action) => {
    const reducer = flattened[action.type];
    return reducer ? reducer(state, action.payload, action.meta) : state;
  };
};
