import { Popover } from '@cmg/common';
import styled from 'styled-components/macro';

import morningStarImg from '../../../../../assets/morning_star_logo.svg';

export const SMorningStarPopoverContent = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
  width: 200px;
`;

const MorningstarLogo = () => {
  return (
    <Popover
      trigger="hover"
      variant="TOOLTIP"
      content={
        <SMorningStarPopoverContent>
          ©{new Date().getFullYear()} Morningstar. All Rights Reserved. The information contained
          herein: (1) is proprietary to Morningstar and/or its content providers; (2) may not be
          copied or distributed; and (3) is not warranted to be accurate, complete or timely.
          Neither Morningstar nor its content providers are responsible for any damages or losses
          arising from any use of this information. Past performance is no guarantee of future
          results.
        </SMorningStarPopoverContent>
      }
      placement="left"
    >
      <img
        src={morningStarImg}
        alt="Morningstar"
        style={{ marginTop: 2, marginRight: 2, height: 36, width: 97 }}
      />
    </Popover>
  );
};

export default MorningstarLogo;
