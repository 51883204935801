import { ButtonGroup, Modal, ModalContent, ModalFooter, PrimaryButton } from '@cmg/common';
import React from 'react';

export type Props = { show: boolean; onHide: () => void };

export const IPOBacklogDisabledModal: React.FC<Props> = ({ show, onHide }) => {
  return (
    <Modal title="IPO Backlog Report Not Available" size="small" show={show} onHide={onHide}>
      <ModalContent>
        <p>
          Your saved IPO Backlog report is no longer supported in DataLab. Please{' '}
          <a href="mailto:support@cmgx.io">contact CMG</a> for more information.
        </p>
      </ModalContent>
      <ModalFooter>
        <ButtonGroup>
          <PrimaryButton onClick={onHide}>Close</PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default IPOBacklogDisabledModal;
