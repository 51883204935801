import { DatalabTableColumnCategory } from '../../constants';
import { ColumnCategoryConfig } from '../advanced-filters-config';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';
import { setColumnsCategory } from '../utils';

export const columns: DatalabTableColumn[] = [
  dtc.offerings_pricing_date,
  dtc.companies_issuer,
  dtc.companies_ticker,
  dtc.companies_cik,
  dtc.companies_cusip,
  dtc.issuer_isin,
  dtc.offering_entity_structure,
  dtc.offerings_sector,
  dtc.companies_sub_sector,
  dtc.offerings_type,
  dtc.offering_size,
  dtc.total_shares_filed_excl_shoe,
  dtc.filing_details_gross_proceeds,
  dtc.filing_details_gross_proceeds_with_over_allotment,
  dtc.filing_details_market_cap_at_pricing,
  dtc.filing_details_deal_pct_at_pricing_market_cap,
  dtc.filing_details_pct_secondary_shares,
  dtc.offerings_file_to_offer_discount,
  dtc.offerings_discount_to_last_trade,
  dtc.offering_discount_to_vwap,
  { ...dtc.offering_gross_spread_pct, label: 'Gross Spread' },
  dtc.offering_first_day_turnover,
  dtc.offering_offer_to_open,
  dtc.offering_offer_to_1day,
  dtc.offering_offer_to_3day,
  dtc.offering_offer_to_7day,
  dtc.offering_offer_to_14day,
  dtc.offering_offer_to_30day,
  dtc.offering_offer_to_180day,
  dtc.offering_offer_to_one_year,
  dtc.offering_offer_to_prior_quarter,
  dtc.offering_offer_to_current,
  dtc.underwriter_name,
  { ...dtc.underwriter_role, textAlign: undefined },
  dtc.left_lead,
  dtc.offering_forward_agreement,
  dtc.first_follow_on,
];

export const visibleFields: string[] = [
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.offerings_type.field,
  dtc.offering_size.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.filing_details_deal_pct_at_pricing_market_cap.field,
  dtc.filing_details_pct_secondary_shares.field,
  dtc.offerings_file_to_offer_discount.field,
  dtc.offerings_discount_to_last_trade.field,
  dtc.offering_gross_spread_pct.field,
  dtc.offering_first_day_turnover.field,
  dtc.offering_offer_to_open.field,
  dtc.offering_offer_to_1day.field,
  dtc.offering_offer_to_7day.field,
  dtc.offering_offer_to_30day.field,
  dtc.offering_offer_to_180day.field,
  dtc.offering_offer_to_current.field,
  dtc.underwriter_name.field,
  dtc.underwriter_role.field,
  dtc.left_lead.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export const categorizedColumns: ColumnCategoryConfig = {
  [DatalabTableColumnCategory.ISSUER]: setColumnsCategory(
    [
      dtc.companies_issuer,
      dtc.companies_ticker,
      dtc.companies_cik,
      dtc.companies_cusip,
      dtc.issuer_isin,
      dtc.offering_entity_structure,
      dtc.offerings_sector,
      dtc.companies_sub_sector,
    ],
    DatalabTableColumnCategory.ISSUER
  ),
  [DatalabTableColumnCategory.OFFERING_TERMS]: setColumnsCategory(
    [
      { ...dtc.offerings_type, label: 'Type' },
      dtc.offering_size,
      dtc.filing_details_pct_secondary_shares,
      dtc.filing_details_gross_proceeds,
      dtc.filing_details_gross_proceeds_with_over_allotment,
      dtc.filing_details_market_cap_at_pricing,
      dtc.filing_details_deal_pct_at_pricing_market_cap,
      dtc.offering_forward_agreement,
      dtc.total_shares_filed_excl_shoe,
      dtc.first_follow_on,
    ],
    DatalabTableColumnCategory.OFFERING_TERMS
  ),
  [DatalabTableColumnCategory.PERFORMANCE]: setColumnsCategory(
    [
      dtc.offering_first_day_turnover,
      dtc.offering_offer_to_open,
      dtc.offering_offer_to_1day,
      dtc.offering_offer_to_3day,
      dtc.offering_offer_to_7day,
      dtc.offering_offer_to_14day,
      dtc.offering_offer_to_30day,
      dtc.offering_offer_to_180day,
      dtc.offering_offer_to_one_year,
      dtc.offering_offer_to_prior_quarter,
      dtc.offering_offer_to_current,
    ],
    DatalabTableColumnCategory.PERFORMANCE
  ),
  [DatalabTableColumnCategory.PRICING_DISCOUNT]: setColumnsCategory(
    [
      dtc.offerings_file_to_offer_discount,
      dtc.offerings_discount_to_last_trade,
      dtc.offering_discount_to_vwap,
    ],
    DatalabTableColumnCategory.PRICING_DISCOUNT
  ),
  [DatalabTableColumnCategory.TIMING]: setColumnsCategory(
    [dtc.offerings_pricing_date],
    DatalabTableColumnCategory.TIMING
  ),
  [DatalabTableColumnCategory.UNDERWRITING]: setColumnsCategory(
    [
      dtc.offering_gross_spread_pct,
      dtc.underwriter_name,
      { ...dtc.underwriter_role, textAlign: undefined },
      dtc.left_lead,
    ],
    DatalabTableColumnCategory.UNDERWRITING
  ),
};
