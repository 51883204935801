import { reportReportRowSelector } from '@cmg/e2e-selectors';
import React from 'react';

import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';
import MembersCell from './MembersCell';
import ReportCell from './ReportCell';
import UserReportPartialControlsCell from './ReportControlsCell';
import ReportLastModifiedByCell from './ReportLastModifiedByCell';
import ReportLastModifiedOnCell from './ReportLastModifiedOnCell';
import { columns, ReportTableFieldName } from './ReportTableConfig';

type Props = {
  userReportPartial: UserReportPartial;
  areControlsVisible: boolean;
  onSetControlVisibility: (id: UserReportPartial['id'] | null) => void;
  onDeleteReport: (reportId: string) => void;
};

const ReportRow = ({
  userReportPartial,
  onSetControlVisibility,
  areControlsVisible,
  onDeleteReport,
}: Props) => (
  <tr
    data-test-id={reportReportRowSelector.testId}
    key={userReportPartial.id}
    onMouseEnter={() => onSetControlVisibility(userReportPartial.id)}
    onMouseLeave={() => onSetControlVisibility(null)}
  >
    {columns.map(column => {
      switch (column.fieldName) {
        case ReportTableFieldName.REPORT_NAME:
          return <ReportCell key={column.fieldName} userReportPartial={userReportPartial} />;
        case ReportTableFieldName.LAST_MODIFIED_BY:
          return (
            <ReportLastModifiedByCell
              key={column.fieldName}
              userReportPartial={userReportPartial}
            />
          );
        case ReportTableFieldName.LAST_MODIFIED_ON:
          return (
            <ReportLastModifiedOnCell
              key={column.fieldName}
              userReportPartial={userReportPartial}
            />
          );
        case ReportTableFieldName.MEMBERS:
          return <MembersCell key={column.fieldName} userReportPartial={userReportPartial} />;
        case ReportTableFieldName.REPORT_CONTROLS:
          return (
            <UserReportPartialControlsCell
              key={String(areControlsVisible)}
              userReportPartial={userReportPartial}
              areControlsVisible={areControlsVisible}
              onHideControls={() => onSetControlVisibility(null)}
              onDeleteReport={onDeleteReport}
            />
          );
        default:
          return <td>Unknown Column Type</td>;
      }
    })}
  </tr>
);

export default ReportRow;
