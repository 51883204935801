import styled from 'styled-components/macro';

export const SSearchResultListItem = styled.div`
  padding: 3px 13px;
  line-height: 30px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  display: flex;
  align-items: center;
`;

export const STicker = styled.span`
  color: ${({ theme }) => theme.text.color.light};
  margin-right: 8px;
`;
