import { Box, RegularBreakpoints, styled, SxProps, Theme } from '@cmg/design-system';

/** makes the content horizontally scrollable on screens < 1200px */
export const StyledContentBox = styled(Box)`
  width: 100%;
  overflow-y: auto;

  > * {
    min-width: 960px; /* 1200px - sidebar-width */
  }
`;

export const boxStyle: SxProps<Theme> = {
  paddingTop: '10px',
  paddingBottom: '20px',
  paddingX: 4,
  overflow: 'scroll',
  maxHeight: 'calc(100vh - 183px)',
};

export const sideNavBoxStyle: SxProps<Theme> = {
  overflow: 'auto',
  width: theme => theme.spacing(42.875),
  minWidth: theme => theme.spacing(42.875),
  py: 3,
  bgcolor: 'background.lightGrey',
};

export const mainSectionBoxStyle: SxProps<Theme> = {
  overflow: 'auto',
  flexGrow: 1,
  width: 'calc(100% - 350px)',
  py: 3,
  px: 4,
  paddingBottom: theme => theme.spacing(12.5),
};

export const dividerStyle: SxProps<Theme> = {
  marginY: 2,
  color: theme => theme.palette.divider,
};

export const menuListStyle: SxProps<Theme> = { paddingLeft: 4, paddingTop: 0 };

export const x4_l4_m6_s12: RegularBreakpoints = { xl: 4, lg: 4, md: 6, sm: 12 };
export const x8_l8_m6_s12: RegularBreakpoints = { xl: 8, lg: 8, md: 6, sm: 12 };
export const x6_l6_m12_s12: RegularBreakpoints = { xl: 6, lg: 6, md: 12, sm: 12 };

export const x12_l12_m12_s12: RegularBreakpoints = { xl: 12, lg: 12, md: 12, sm: 12 };

export const x8_l12_m12_s12: RegularBreakpoints = { xl: 8, lg: 12, md: 12, sm: 12 };
export const x4_l12_m12_s12: RegularBreakpoints = { xl: 4, lg: 12, md: 12, sm: 12 };
export const x12_l6_m12_s12: RegularBreakpoints = { xl: 12, lg: 6, md: 12, sm: 12 };

export const x8_l6_m12_s12: RegularBreakpoints = { xl: 8, lg: 6, md: 12, sm: 12 };
export const x6_l12_m12_s12: RegularBreakpoints = { xl: 6, lg: 12, md: 12, sm: 12 };
export const x4_l6_m12_s12: RegularBreakpoints = { xl: 4, lg: 6, md: 12, sm: 12 };
export const spacing = { rowSpacing: 2, columnSpacing: 3 };
