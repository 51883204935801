import styled, { css } from 'styled-components/macro';

export const SRightAlign = styled.div<{ capitalize?: boolean }>`
  text-align: right;
  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}
`;
