import { Box } from '@cmg/common';
import React, { useState } from 'react';

import Toggler from '../../../../shared/layout/components/Toggler';
import offeringDiscountBySizeButtons, {
  OfferingDiscountBySizeButtons,
} from '../../../model/buttons/offering-discount-by-size-buttons';
import { SButtonBox } from '../shared/styled';
import WidgetBarMeanMedianPercentage from '../WidgetBarMeanMedianPercentage/WidgetBarMeanMedianPercentage';

type Props = {
  // TODO - find out what this data structure is by looking at requests in the network tab
  fromFilingData: any[];
  lastTradeData: any[];
};

const WidgetOfferingDiscountsBySize: React.FC<Props> = ({
  fromFilingData = [],
  lastTradeData = [],
}) => {
  const [activeBtn, setActiveBtn] = useState(offeringDiscountBySizeButtons[0]);

  const handleBtnChanged = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <div>
      <Box.Header title="Offering Discounts by Size" />

      <SButtonBox>
        <Toggler
          buttons={offeringDiscountBySizeButtons}
          activeButton={activeBtn}
          onChange={handleBtnChanged}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        {activeBtn.value === OfferingDiscountBySizeButtons.FROM_FILLING && (
          <WidgetBarMeanMedianPercentage
            chartData={fromFilingData}
            xScaleLabel="Offering Size"
            hideHeader
          />
        )}
        {activeBtn.value === OfferingDiscountBySizeButtons.LAST_TRADE && (
          <WidgetBarMeanMedianPercentage
            chartData={lastTradeData}
            xScaleLabel="Offering Size"
            hideHeader
          />
        )}
      </Box.Content>
    </div>
  );
};

export default WidgetOfferingDiscountsBySize;
