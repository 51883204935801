import React from 'react';

import {
  SFooterItem,
  SFooterWrapper,
  STitle,
  STitleWrapper,
  SWrapper,
} from './ScreenSubHeader.styles';

export type Props = {
  title: React.ReactNode;
  bottomData?: { value: any; label: any }[];
  rightData?: { value: any; label: any }[];
  rightButton?: React.ReactNode;
};

export default class ScreenSubHeader extends React.Component<Props> {
  render() {
    const { title, bottomData, rightData, rightButton } = this.props;

    const bottomDataArr = bottomData ? bottomData.filter(item => item.value !== null) : null;

    return (
      <SWrapper className="sub-header">
        <STitleWrapper>
          <STitle>{title}</STitle>

          {bottomDataArr && (
            <SFooterWrapper>
              {bottomDataArr.map((item, idx, arr) => (
                <span key={idx}>
                  {item.label}: <SFooterItem>{item.value}</SFooterItem>&nbsp;
                  {idx < arr.length - 1 && ','}&nbsp;
                </span>
              ))}
            </SFooterWrapper>
          )}
        </STitleWrapper>

        {rightData &&
          rightData.map((item, idx) => (
            <div className="right-box" key={idx}>
              <div className="right-box-title">{item.label}</div>
              <div className="right-box-content">{item.value}</div>
            </div>
          ))}

        {rightButton && <div className="right-button">{rightButton}</div>}
      </SWrapper>
    );
  }
}
