import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { Passed as PassedType } from '../../../../../../types/domain/my-dashboard/passed';
import CurrencyValue from '../../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../../common/dashboard-value/numeric-value/NumericValue';
import PerformanceMetricValue from '../../../../common/dashboard-value/performance-metric-value/PerformanceMetricValue';
import { SectionHeader } from '../../../common/DashboardPeriod';

const SPassed = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const passedDefault: PassedType = {
  numberOfOfferings: 1,
  capitalRaised: 51800000000000,
  performance: 0.458,
};

export type Props = {
  data?: PassedType;
  hasMyDatalab: boolean;
};

const Passed: React.FC<Props> = ({ data, hasMyDatalab }) => {
  // seed with mock data if the user does not have my data lab
  const passedData: PassedType | undefined = hasMyDatalab ? data : passedDefault;

  return (
    <SPassed data-test-id={myDashboard.passedSection.testId}>
      <SectionHeader>Passed</SectionHeader>
      <NumericValue value={passedData?.numberOfOfferings} label="# Offerings" size="large" />
      <CurrencyValue value={passedData?.capitalRaised} label="Capital Raised" size="large" />
      <PerformanceMetricValue value={data?.performance} size="large" />
    </SPassed>
  );
};

export default Passed;
