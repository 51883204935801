import { UserReport } from '../../../types/domain/report/userReport';
import { DatalabScreens } from '../constants';
import { DatalabFilterValues } from '../types';
import { getFilterSettings, getOfferingTypeConfig, getOfferingTypeInternal } from './filters.model';

export function getOverrideDatalabFilters(
  datalab: DatalabFilterValues,
  screen: DatalabScreens
): DatalabFilterValues {
  const { spac, offeringTypeValues } = datalab;
  const defaultsForScreen = getOfferingTypeConfig(screen).defaultSelectedValues;
  const offeringTypeInternal = getOfferingTypeInternal({ offeringTypeValues, spac });
  return {
    ...datalab,
    // It was decided that if the report was saved with 0 filters we should load defaults
    offeringTypeInternal:
      offeringTypeInternal.length === 0 ? defaultsForScreen : offeringTypeInternal,
  };
}

export function getReportFilterValues(report: UserReport): DatalabFilterValues {
  const { shouldFilterForAllocations } = getFilterSettings(report.datalabPage);

  return {
    typeDateValue: report.timeWindow,
    ...getOverrideDatalabFilters(report.filters.dataLab, report.datalabPage),
    // todo add support for filterForAllocations in user reports on server side
    filterForAllocations: shouldFilterForAllocations ? [] : false,
  };
}
