import { Pagination } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  fetchOrganizationUsersRequest,
  fetchSharedReportOrganizationUsersRequest,
} from '../../../shared/ducks';
import {
  deleteReportActions,
  fetchUserReportPartialsActions,
  selectFilters,
  selectUserReportPartials,
} from '../../ducks';
import { getNextOrderDirection, SortState } from './AllReportsPanel.model';
import {
  SAllReportsPanelWrapper,
  SHeader,
  SPaginationWrapper,
  SSavedReportsHeader,
  SSavedReportTableWrapper,
} from './AllReportsPanel.styles';
import ReportTable from './ReportTable';
import { ReportTableFieldName, SortOrder } from './ReportTableConfig';

const mapStateToProps = state => ({
  hasError: false,
  isLoading: false,
  userReportPartials: selectUserReportPartials(state),
  filters: selectFilters(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchOrganizationUsers: fetchOrganizationUsersRequest,
      fetchSharedReportOrganizationUsers: fetchSharedReportOrganizationUsersRequest,
      fetchDatalabUserReportPartials: fetchUserReportPartialsActions.request,
      deleteReport: deleteReportActions.request,
    },
    dispatch
  ),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const DEFAULT_ITEMS_PER_PAGE = 12;

export const AllReportsPanel: React.FC<Props> = ({
  hasError,
  isLoading,
  userReportPartials,
  actions,
  filters,
}) => {
  React.useEffect(() => {
    actions.fetchDatalabUserReportPartials({
      paginationParams: { perPage: DEFAULT_ITEMS_PER_PAGE },
    });
    actions.fetchOrganizationUsers();

    actions.fetchSharedReportOrganizationUsers({
      payload: { useCustomSectors: filters.useCustomSectors },
    });
  }, [actions, filters]);

  const [sort, setSort] = React.useState<SortState>({
    orderDirection: SortOrder.ASC,
    orderField: ReportTableFieldName.REPORT_NAME,
  });

  const handleOnChangeSort = (orderField: ReportTableFieldName) => {
    if (userReportPartials.pagination) {
      const updatedOrderDirection = getNextOrderDirection(orderField, sort);

      setSort({
        orderDirection: updatedOrderDirection,
        orderField,
      });

      actions.fetchDatalabUserReportPartials({
        paginationParams: {
          perPage: userReportPartials.pagination.perPage,
          page: userReportPartials.pagination.activePage,
          orderField,
          orderDirection: updatedOrderDirection,
        },
      });
    }
  };

  const handleOnDeleteReport = reportId => {
    if (userReportPartials.pagination) {
      actions.deleteReport({
        reportId,
        paginationParams: {
          perPage: userReportPartials.pagination.perPage,
          page: userReportPartials.pagination.activePage,
          orderField: sort.orderField,
          orderDirection: sort.orderDirection,
        },
      });
    }
  };

  return (
    <SAllReportsPanelWrapper>
      <SHeader>
        <SSavedReportsHeader>Saved Reports</SSavedReportsHeader>
        {userReportPartials.pagination && (
          <div>{userReportPartials.pagination.totalCount} Reports</div>
        )}
      </SHeader>
      {isLoading && <span>Loading...</span>}
      {hasError && <div>Could not load reports.</div>}
      {!isLoading && !hasError && (
        <SSavedReportTableWrapper>
          <ReportTable
            orderField={sort.orderField}
            orderDirection={sort.orderDirection}
            userReportPartials={userReportPartials}
            onChangeSort={handleOnChangeSort}
            onDeleteReport={handleOnDeleteReport}
          />
          {userReportPartials.pagination && userReportPartials.pagination.totalPages > 1 && (
            <SPaginationWrapper>
              <Pagination
                activePage={userReportPartials.pagination.activePage}
                totalPages={userReportPartials.pagination.totalPages}
                onChangePage={(page: number) => {
                  actions.fetchDatalabUserReportPartials({
                    paginationParams: { page, perPage: DEFAULT_ITEMS_PER_PAGE },
                  });
                }}
              />
            </SPaginationWrapper>
          )}
        </SSavedReportTableWrapper>
      )}
    </SAllReportsPanelWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllReportsPanel);
