import { ISODate, YesNoSelect } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import { v4 as uuidV4 } from 'uuid';

import { isDefined } from '../../../helpers/helpers';
import { createBetweenControl } from './inputs/BetweenControl';
import CurrencyInputControl from './inputs/CurrencyInputControl';
import DateInputControl from './inputs/DateInputControl';
import { createEnumControl } from './inputs/EnumControl';
import IntegerInputControl from './inputs/IntegerInputControl';
import NullableBooleanControl from './inputs/NullableBooleanControl';
import NumericInputControl from './inputs/NumericInputControl';
import PercentInputControl from './inputs/PercentInputControl';
import StringInputControl from './inputs/StringInputControl';

export type AdvancedFilterValue = {
  combinator: string;
  id: string;
  rules: AdvancedFilterRule[];
};

export type AdvancedFilterOperatorConfig = {
  name: string;
  label: string;
  renderer: Function;
};

export type AdvancedFilterFieldConfig = {
  label: string;
  name: string;
  operatorsConfig: AdvancedFilterOperatorConfig[];
  category: string;
};

type RangeFilterRuleValue = {
  gteq: number | ISODate;
  lteq: number | ISODate;
};

type RoleFilterRuleValue = {
  role: string;
  underwriter_id: string;
};

type AdvancedFilterRuleValue =
  | string
  | number
  | string[]
  | RangeFilterRuleValue
  | RoleFilterRuleValue;

export type AdvancedFilterRule = {
  field?: string; // DataLabTableRowField plus the extra custom filter field names
  id: string;
  operator?: operatorEnum;
  value?: AdvancedFilterRuleValue;
};

function EmptyInputControl() {
  return null;
}

export enum operatorEnum {
  NULL = 'null',
  NOT_NULL = 'not_null',
  IN = 'in',
  NOT_IN = 'not_in',
  EQUAL = 'eq',
  NOT_EQUAL = 'not_eq',
  LIKE = 'like',
  LESS_THAN = 'lt',
  GREATER_THAN = 'gt',
  // LESS_THAN_OR_EQUAL = 'lteq', // NOT USED
  // GREATER_THAN_OR_EQUAL = 'gteq', // NOT USED
  BETWEEN = 'between',
}

export const stringOperatorsConfig = [
  { name: operatorEnum.NULL, renderer: EmptyInputControl, label: 'Is blank' },
  { name: operatorEnum.NOT_NULL, renderer: EmptyInputControl, label: 'Is not blank' },
  { name: operatorEnum.EQUAL, renderer: StringInputControl, label: 'Equal to' },
  { name: operatorEnum.NOT_EQUAL, renderer: StringInputControl, label: 'Not equal to' },
  { name: operatorEnum.LIKE, renderer: StringInputControl, label: 'Includes' },
];

export const createNumericOperatorsConfig = renderer => [
  { name: operatorEnum.NULL, renderer: EmptyInputControl, label: 'Is blank' },
  { name: operatorEnum.NOT_NULL, renderer: EmptyInputControl, label: 'Is not blank' },
  { name: operatorEnum.EQUAL, renderer: renderer, label: 'Equal to' },
  { name: operatorEnum.NOT_EQUAL, renderer: renderer, label: 'Not equal to' },
  { name: operatorEnum.LESS_THAN, renderer: renderer, label: 'Less than' },
  { name: operatorEnum.GREATER_THAN, renderer: renderer, label: 'Greater than' },
  { name: operatorEnum.BETWEEN, renderer: createBetweenControl(renderer), label: 'Between' },
];

export const numericOperatorsConfig = createNumericOperatorsConfig(NumericInputControl);
export const percentOperatorsConfig = createNumericOperatorsConfig(PercentInputControl);
export const currencyOperatorsConfig = createNumericOperatorsConfig(CurrencyInputControl);
export const integerOperatorsConfig = createNumericOperatorsConfig(IntegerInputControl);

export const dateOperatorsConfig = [
  { name: operatorEnum.NULL, renderer: EmptyInputControl, label: 'Is blank' },
  { name: operatorEnum.NOT_NULL, renderer: EmptyInputControl, label: 'Is not blank' },
  { name: operatorEnum.EQUAL, renderer: DateInputControl, label: 'Equals' },
  {
    name: operatorEnum.BETWEEN,
    renderer: createBetweenControl(DateInputControl),
    label: 'Between',
  },
];

export const enumOperatorsConfig = options => [
  { name: operatorEnum.NULL, renderer: EmptyInputControl, label: 'Is blank' },
  { name: operatorEnum.NOT_NULL, renderer: EmptyInputControl, label: 'Is not blank' },
  { name: operatorEnum.IN, renderer: createEnumControl(options), label: 'Is one of' },
];

export const booleanOperatorsConfig = [
  { name: operatorEnum.EQUAL, renderer: YesNoSelect, label: 'Equals' },
];

export const nullableBooleanOperatorsConfig = [
  {
    name: operatorEnum.EQUAL,
    renderer: NullableBooleanControl,
    label: 'Equals',
  },
];

/**
 * Convert fields configuration to list of grouped select options.
 *
 * @param fieldConfigs - field configurations
 */
export const getCategorizedFieldOptions = (fieldConfigs: AdvancedFilterFieldConfig[]) => {
  const grouped: { [key: string]: AdvancedFilterFieldConfig[] } = fieldConfigs.reduce(
    (acc, curr) => {
      const category = curr.category;

      if (category) {
        acc[category] = acc[category] || [];
        acc[category].push(curr);
      }

      return acc;
    },
    {}
  );

  /**
   * Extract OTHERS category so it can be placed at the end of the options array
   */
  return Object.entries(grouped).map(([label, options]) => ({
    label,
    options: getFieldOptions(options),
  }));
};

export function getFieldOptions(fieldConfigs: AdvancedFilterFieldConfig[]) {
  return fieldConfigs.map(({ name, label, category }) => ({
    value: name,
    label,
    group: category,
  }));
}

export function getFieldRenderer(
  fieldConfigs: AdvancedFilterFieldConfig[],
  selectedRule: AdvancedFilterRule
) {
  const field = fieldConfigs.find(field => field.name === selectedRule.field);
  const operatorConfig =
    field && field.operatorsConfig.find(operator => operator.name === selectedRule.operator);

  return operatorConfig ? operatorConfig.renderer : () => null;
}

export function getFieldOperatorOptions(
  fieldConfigs: AdvancedFilterFieldConfig[],
  selectedRule: AdvancedFilterRule
) {
  const field = fieldConfigs.find(field => field.name === selectedRule.field);
  if (!field) {
    return [];
  }
  return field.operatorsConfig.map(({ name, label }) => ({ value: name, label }));
}

// undefined is used to show placeholder
export function createNewFilter(): AdvancedFilterRule {
  return { field: undefined, operator: undefined, value: undefined, id: uuidV4() };
}

export function createDefaultValue(): AdvancedFilterValue {
  return { rules: [], combinator: 'and', id: uuidV4() };
}

export function canSelectOperator(rule: AdvancedFilterRule) {
  return !!rule.field;
}

export function canSetValue(rule: AdvancedFilterRule) {
  return (
    !!rule.operator &&
    !(rule.operator === operatorEnum.NULL || rule.operator === operatorEnum.NOT_NULL)
  );
}

export function changedValidRules(value, nextValue) {
  const validRules = nextValue.rules.filter(
    rule =>
      !!rule.field &&
      !!rule.operator &&
      (rule.operator === operatorEnum.NULL ||
        rule.operator === operatorEnum.NOT_NULL ||
        // Now we allow equals on null but not on undefined
        (rule.operator === operatorEnum.EQUAL && rule.value !== undefined) ||
        isDefined(rule.value))
  );
  if (
    value &&
    nextValue.rules.length >= value.rules.length &&
    validRules.length < nextValue.rules.length &&
    validRules.every(validRule => value.rules.find(rule => isEqual(rule, validRule)))
  ) {
    // if one of rule is invalidated (changed operator or field)
    // and every valid rules is present in exiting value, do nothing
    return null;
  }
  const hasValidRulesOrValueIsSet = value || validRules.length;
  const ruleDiffers = !value || !isEqual(value.rules, validRules);
  return hasValidRulesOrValueIsSet && ruleDiffers ? validRules : null;
}

/**
 * Alternative select field options search logic.
 * Takes in account group labels when filtering options.
 *
 * @param label - option label
 * @param data - option metadata, should include group prop
 * @param input - value of fulltext search input
 */
export const groupSearch = (
  { label, data }: { label: string; data: { [key: string]: any } },
  input: string
) => {
  const normalizedInput = input.toLowerCase();
  const normalizedGroup = data.group ? data.group.toLowerCase() : '';
  const normalizedLabel = label.toLowerCase();

  return normalizedLabel.includes(normalizedInput) || normalizedGroup.includes(normalizedInput);
};
