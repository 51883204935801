import styled from 'styled-components/macro';

export const SSubscriptionNotice = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
`;

export const SNoticeList = styled.ul`
  margin-left: 15px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SNoticeContent = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: center;
`;

export const SNoticeHeader = styled.h3`
  color: ${({ theme }) => theme.brand.color.info};
`;

export const SNoticeSubHeader = styled.p`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SContact = styled.p`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.text.size.tiny};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;
