import { Checkbox } from '@cmg/common';
import React from 'react';

import { SNotificationFilter } from './NotificationFilter.styles';

type FilterProps = {
  label: React.ReactNode;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (checked: boolean) => void;
  hideTree?: boolean;
};

export const NotificationFilter: React.FC<FilterProps> = ({
  label,
  checked,
  indeterminate = false,
  onChange,
  hideTree = false,
}) => (
  <SNotificationFilter hideTree={hideTree}>
    <Checkbox value={checked} indeterminate={indeterminate} onChange={onChange}>
      {label}
    </Checkbox>
  </SNotificationFilter>
);

export default NotificationFilter;
