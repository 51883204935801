import { Option, ToastManager } from '@cmg/common';
import React from 'react';

import { SearchFilterHookResult } from '../../../../../graphql/types';
import { useOfferingsFilterForm_ShareholdersQuery } from '../graphql/__generated__/OfferingsFilterForm_Shareholders';
import { useCreateShareholderOptions } from '../OfferingsFilterForm.model';

export const useSearchShareholders = ({
  skip,
}: { skip?: boolean } = {}): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useOfferingsFilterForm_ShareholdersQuery({
    variables: {
      filter: {
        name: searchText ? { like: searchText } : undefined,
      },
    },
    skip,
    fetchPolicy: 'cache-first',
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const shareholderOptions = useCreateShareholderOptions(data?.shareholders?.items ?? []);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching shareholders');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(shareholderOptions);
    }
  }, [loading, data, error, shareholderOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: shareholderOptions,
    loadOptions,
  };
};
