import { IoiLimitType, OfferingType } from '../../../../types/domain/offering/constants';

const isEmpty = value => value === null || value === undefined || value === '';

export const validateFirmIoIForm = (ioi, offeringType) => {
  const formErrors = {};

  if (isEmpty(ioi.unitType)) {
    formErrors.unitType = 'Enter a valid unit type';
  } else if (ioi.unitType === 'dollars') {
    if (isEmpty(ioi.dollars)) {
      formErrors.dollars = 'Enter a valid dollar amount';
    } else if (ioi.dollars < 0) {
      formErrors.dollars = 'Dollar amount must be a positive number';
    }
    if (!isEmpty(ioi.realDemandDollars) && ioi.realDemandDollars < 0) {
      formErrors.realDemandDollars = 'Dollar amount must be a positive number';
    }
  } else if (ioi.unitType === 'percentage') {
    if (isEmpty(ioi.percentage)) {
      formErrors.percentage = 'Enter a valid percentage';
    } else if (ioi.percentage < 0) {
      formErrors.percentage = 'Percentage must be a positive number';
    }
    if (!isEmpty(ioi.realDemandPercentage) && ioi.realDemandPercentage < 0) {
      formErrors.realDemandPercentage = 'Percentage must be a positive number';
    }
  } else if (ioi.unitType === 'shares') {
    if (isEmpty(ioi.shares)) {
      formErrors.shares = 'Enter a valid share amount';
    } else if (ioi.shares < 0) {
      formErrors.shares = 'Share amount must be a positive number';
    }
    if (!isEmpty(ioi.realDemandShares) && ioi.realDemandShares < 0) {
      formErrors.realDemandShares = 'Share amount must be a positive number';
    }
  }

  if (isEmpty(ioi.pricingType)) {
    formErrors.pricingType = 'Enter a valid pricing type';
  } else if (ioi.pricingType === 'limit') {
    if (isEmpty(ioi.limitType)) {
      formErrors.limitType = 'Enter a valid limit type';
    } else if (
      offeringType === OfferingType.IPO &&
      (ioi.limitType === IoiLimitType.DISCOUNT || ioi.limitType === IoiLimitType.PREMIUM)
    ) {
      formErrors.limitType =
        'Premium and Discount orders are only applicable to Follow-On offerings';
    }

    if (ioi.limitType === IoiLimitType.PRICE) {
      if (isEmpty(ioi.limitPrice)) {
        formErrors.limitPrice = 'Enter a valid limit price';
      } else if (ioi.limitPrice < 0) {
        formErrors.limitPrice = 'Limit price must be a positive number';
      }
    } else {
      if (isEmpty(ioi.limitPercentage)) {
        formErrors.limitPercentage = 'Enter a valid limit percentage';
      } else if (ioi.limitPercentage < 0) {
        formErrors.limitPercentage = 'Limit percentage must be a positive number';
      }
    }
  }

  return formErrors;
};

export const validateFundIoIForm = ioi => {
  const formErrors = {};

  if (isEmpty(ioi.unitType)) {
    formErrors.unitType = 'Enter a valid unit type';
  } else if (ioi.unitType === 'dollars') {
    if (isEmpty(ioi.dollars)) {
      formErrors.dollars = 'Enter a valid dollar amount';
    } else if (ioi.dollars < 0) {
      formErrors.dollars = 'Dollar amount must be a positive number';
    }
    if (!isEmpty(ioi.realDemandDollars) && ioi.realDemandDollars < 0) {
      formErrors.realDemandDollars = 'Dollar amount must be a positive number';
    }
  } else if (ioi.unitType === 'percentage') {
    if (isEmpty(ioi.percentage)) {
      formErrors.percentage = 'Enter a valid percentage';
    } else if (ioi.percentage < 0) {
      formErrors.percentage = 'Percentage must be a positive number';
    }
    if (!isEmpty(ioi.realDemandPercentage) && ioi.realDemandPercentage < 0) {
      formErrors.realDemandPercentage = 'Percentage must be a positive number';
    }
  } else if (ioi.unitType === 'shares') {
    if (isEmpty(ioi.shares)) {
      formErrors.shares = 'Enter a valid share amount';
    } else if (ioi.shares < 0) {
      formErrors.shares = 'Share amount must be a positive number';
    }
    if (!isEmpty(ioi.realDemandShares) && ioi.realDemandShares < 0) {
      formErrors.realDemandShares = 'Share amount must be a positive number';
    }
  }

  if (isEmpty(ioi.pricingType)) {
    formErrors.pricingType = 'Enter a valid order type';
  } else if (ioi.pricingType === 'limit') {
    if (isEmpty(ioi.limitPrice)) {
      formErrors.limitPrice = 'Enter a valid limit price';
    } else if (ioi.limitPrice < 0) {
      formErrors.limitPrice = 'Limit price must be a positive number';
    }
  }

  return formErrors;
};

export const validateFundForm = (allocation, funds) => {
  const formErrors = {};

  if (isEmpty(allocation.fundId) || !funds.some(fund => fund.id === allocation.fundId)) {
    formErrors.fundId = 'Enter a valid fund type';
  }
  if (!isEmpty(allocation.shares) && allocation.shares < 0) {
    formErrors.shares = 'Shares amount must be a positive number';
  }

  return formErrors;
};

export const validateFundName = fundName => (fundName === '' ? 'Enter a valid name' : null);

export const hasErrors = formErrors => {
  if (Object.keys(formErrors).length === 0) {
    return false;
  }

  return Object.values(formErrors).some(err => Object.keys(err).length > 0);
};
