import routeFactory from '../../common/util/routeFactory';
import { CalendarCategory } from '../../types/domain/calendar/constants';

export const getCurrentDatalabScreen = () => {
  switch (window.location.pathname) {
    case routeFactory.calendarLiveOfferings.routePath:
      return CalendarCategory.LIVE;
    case routeFactory.calendarPricedOfferings.routePath:
      return CalendarCategory.PRICED;
    case routeFactory.calendarFiledOfferings.routePath:
      return CalendarCategory.FILED;
    case routeFactory.calendarPostponedOfferings.routePath:
      return CalendarCategory.POSTPONED;
    case routeFactory.calendarLockupExpirationOfferings.routePath:
      return CalendarCategory.LOCK_UP_EXPIRATION;
    case routeFactory.calendarMyOfferings.routePath:
      return CalendarCategory.MY_OFFERINGS;
    default:
      return undefined;
  }
};
