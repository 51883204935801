import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  filterCompositeColumnsOut,
  getFlatVisibleColumns,
  normalizeColumns,
} from '../table-helpers';

export default class ColumnToggler extends Component {
  static propTypes = {
    columnsConfig: PropTypes.array.isRequired,
    handleToggleViewableFields: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    visibleColumns: PropTypes.array,
  };

  toggleColumnVisibility = values => {
    const { handleToggleViewableFields } = this.props;
    if (handleToggleViewableFields) {
      handleToggleViewableFields(values);
    }
  };

  render() {
    const { children, visibleColumns, columnsConfig } = this.props;

    const columns = normalizeColumns(columnsConfig).map(c => ({
      ...c,
      visible: visibleColumns && visibleColumns.includes(c.field),
    }));

    return children({
      columns,
      simpleColumns: filterCompositeColumnsOut(columns),
      toggleColumnVisibility: this.toggleColumnVisibility,
      visibleColumns: getFlatVisibleColumns(columns),
    });
  }
}
