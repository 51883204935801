import styled from 'styled-components/macro';

export const SNotificationFilterGroup = styled.div`
  position: relative;
`;

export const SNotificationFilterGroupInner = styled.div<{ hideTree?: boolean }>`
  margin-left: 7px;
  padding-left: 7px;
  position: relative;
  min-width: 200px;

  &:after {
    position: absolute;
    content: '';
    border-left: ${({ theme }) => theme.border.width.small} solid
      ${({ theme, hideTree }) => (hideTree ? 'transparent' : theme.color.gray7)};
    height: 100%;
    left: -14px;
    bottom: 4px;
    padding-bottom: 7px;
    box-sizing: content-box;
  }

  ${SNotificationFilterGroup}:last-of-type > & {
    &:after {
      border-left: transparent;
    }
  }
`;
