import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import HorizontalLine from '../../../common/horizontal-line/HorizontalLine';
import { PeriodColumn, Section } from '../../common/DashboardPeriod';
import FollowOnPricingSummary from '../../components/follow-on-pricing-summary/FollowOnPricingSummary';
import IpoPricingSummary from '../../components/pricing-summary/IpoPricingSummary';
import UsEcm from '../../components/us-ecm/UsEcm';
import ComparisonPeriodControl from '../../controls/components/ComparisonPeriodControl';
import { getComparisonPeriodPresetOptions } from '../../controls/components/periodControls.model';
import {
  selectSellSideComparisonFollowOnPricingSummary,
  selectSellSideComparisonIpoPricingSummary,
  selectSellSideComparisonLeagueTable,
  selectSellSideComparisonUsEcm,
  selectSellSideLoading,
} from '../ducks';
import LeagueTable from './league-table/LeagueTable';
import LoadingSkeleton from './loading-skeleton/LoadingSkeleton';

/**
 * SellSide ComparisonPeriod displays a dashboard period with the data from the comparison
 * selected date range in the ComparisonPeriodControl
 */
const ComparisonPeriod: React.FC = () => {
  const { usEcm, followOnPricingSummary, ipoPricingSummary, leagueTable, loading } = useSelector(
    state => ({
      followOnPricingSummary: selectSellSideComparisonFollowOnPricingSummary(state),
      ipoPricingSummary: selectSellSideComparisonIpoPricingSummary(state),
      leagueTable: selectSellSideComparisonLeagueTable(state),
      usEcm: selectSellSideComparisonUsEcm(state),
      loading: selectSellSideLoading(state),
    }),
    shallowEqual
  );
  const presetOptions = getComparisonPeriodPresetOptions(new Date());

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <PeriodColumn data-test-id={myDashboard.comparisonPeriod.testId}>
      <ComparisonPeriodControl presetOptions={presetOptions} />
      <HorizontalLine />
      <Section>
        <UsEcm data={usEcm} />
      </Section>
      <HorizontalLine />
      <Section>
        <IpoPricingSummary data={ipoPricingSummary} />
      </Section>
      <HorizontalLine />
      <Section>
        <FollowOnPricingSummary data={followOnPricingSummary} />
      </Section>
      <HorizontalLine />
      <Section>
        <LeagueTable data={leagueTable} />
      </Section>
    </PeriodColumn>
  );
};

export default ComparisonPeriod;
