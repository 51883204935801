import { timeUtil } from '@cmg/common';

import { MyDashboard_OfferingFieldsPartsFragment } from '../../../graphql/__generated__/MyDashboard_PricedCalendar';

const UnknownFirstTradeDateGroupName = 'TBD';

/**
 * formats date string as <day>, <date>
 */

export const getFirstTradeDateGroupName = (date: string | null | undefined) => {
  if (!date) {
    return UnknownFirstTradeDateGroupName;
  }

  return `${timeUtil.formatAsDisplayDay(date)}, ${timeUtil.formatAsDisplayDate(date)}`;
};

export const groupByFirstTrade = (
  recentDeals: MyDashboard_OfferingFieldsPartsFragment[]
): Record<string, MyDashboard_OfferingFieldsPartsFragment[]> =>
  recentDeals.reduce((acc, deal) => {
    const groupName = getFirstTradeDateGroupName(deal.attributes?.firstTradeDate);
    if (!acc[groupName]) {
      acc[groupName] = [];
    }

    acc[groupName].push(deal);
    return acc;
  }, {});
