import { apiTypes, Column, PageError, Row } from '@cmg/common';
import { underwriterCreditsScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { Offering } from '../../../../types/domain/offering/offering';
import { UnderwriterCreditsAllocation } from '../../../../types/domain/underwriter-credits/underwriterCreditsAllocation';
import { UnderwriterCreditsManager } from '../../../../types/domain/underwriter-credits/underwriterCreditsManager';
import { UnderwriterCreditsOffering } from '../../../../types/domain/underwriter-credits/underwriterCreditsOffering';
import ScreenContent from '../../../shared/layout/ScreenContent';
import ScreenHeader from '../../../shared/layout/ScreenHeader';
import OfferingOverviewGrid from '../../shared/components/OfferingOverviewGrid';
import OfferingSubHeader from '../../shared/components/OfferingSubHeader';
import OfferingNotesContainer from '../../shared/offering-notes/containers/OfferingNotesContainer';
import { saveUnderwriterCreditsRequest } from '../ducks';
import { creditChanged, isValidPlCredit } from '../model/underwriter-credit.model';
import UnderwriterCreditsForm from './UnderwriterCreditsForm';

export const SContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc((100vh - 88px) - 86px);
`;

type Props = {
  error: apiTypes.GenericServerError | null;
  offering: Offering | null;
  underwriterCreditOffering: UnderwriterCreditsOffering | null;
  allocation: UnderwriterCreditsAllocation | null;
  managers: UnderwriterCreditsManager[];
  canEdit: boolean;
  saveUnderwriterCredits: typeof saveUnderwriterCreditsRequest;
};
type State = {
  prevAllocation: UnderwriterCreditsAllocation | null;
  allocation: UnderwriterCreditsAllocation | null;
  prevManagers: UnderwriterCreditsManager[];
  managers: UnderwriterCreditsManager[];
  isEditing: boolean;
  isValid: boolean;
};
export default class UnderwriterCreditsScreen extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.managers !== state.prevManagers || props.allocation !== state.prevAllocation) {
      return {
        prevAllocation: props.allocation,
        prevManagers: props.managers,
        allocation: props.allocation,
        managers: props.managers.map(m => ({ ...m })),
        isValid: isValidPlCredit(props.managers),
      };
    }

    return null;
  }

  state = {
    prevAllocation: this.props.allocation,
    prevManagers: this.props.managers,
    allocation: this.props.allocation,
    isEditing: false,
    isValid: true,
    managers: this.props.managers.map(m => ({ ...m })),
  };

  onSave = () => {
    const { underwriterCreditOffering, saveUnderwriterCredits } = this.props;
    const { allocation, managers, isValid } = this.state;

    if (!isValid || !underwriterCreditOffering || !allocation) {
      return;
    }

    saveUnderwriterCredits({
      offeringId: underwriterCreditOffering.id,
      allocation,
      managers,
    });

    this.setState({
      isEditing: false,
    });
  };

  onEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  onCancel = () => {
    const managers = this.props.managers.map(m => ({ ...m }));

    this.setState({
      isEditing: false,
      managers,
      isValid: isValidPlCredit(managers),
      allocation: this.props.allocation,
    });
  };

  handleSalesConcessionChange = (value: number | null) => {
    if (this.state.allocation) {
      this.setState({
        allocation: {
          ...this.state.allocation,
          salesConcession: value,
        },
      });
    }
  };

  handleManagerChange = (rows?: UnderwriterCreditsManager[]) => {
    if (rows) {
      const managers = rows.map(m => ({ ...m }));
      this.setState(state => ({
        managers,
        isValid: creditChanged(state.managers, managers)
          ? isValidPlCredit(managers)
          : state.isValid,
        isEditing: true,
      }));
    }
  };

  render() {
    const { underwriterCreditOffering, canEdit, offering, error } = this.props;

    const { managers, allocation, isEditing, isValid } = this.state;

    return (
      <div data-test-id={underwriterCreditsScreenSelector.testId}>
        <ScreenHeader />

        <ScreenContent renderSubHeader={() => offering && <OfferingSubHeader />}>
          {error && <PageError error={error} />}
          {underwriterCreditOffering && allocation && !error && (
            <SContainer>
              <Row>
                <Column xs={24} sm={16}>
                  <OfferingOverviewGrid offering={underwriterCreditOffering} />

                  <UnderwriterCreditsForm
                    allocation={allocation}
                    canEdit={canEdit}
                    isEditing={isEditing}
                    isValid={isValid}
                    managers={managers.map(m => ({ ...m }))}
                    offering={underwriterCreditOffering}
                    onManagerChange={this.handleManagerChange}
                    onSalesConcessionChange={this.handleSalesConcessionChange}
                    onCancel={this.onCancel}
                    onEdit={this.onEdit}
                    onSave={this.onSave}
                  />
                </Column>
                <Column xs={24} sm={8}>
                  <OfferingNotesContainer offeringId={underwriterCreditOffering.id} />
                </Column>
              </Row>
            </SContainer>
          )}
        </ScreenContent>
      </div>
    );
  }
}
