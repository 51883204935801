import { apiTypes, Checkbox, Icon, LinkButton, useInfiniteScroll } from '@cmg/common';
import React, { RefObject, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { GetNotificationsParams } from '../../../../api/notification-api';
import { Pagination } from '../../../../types/api/pagination';
import { NotificationActionType } from '../../../../types/domain/notifications/constants';
import { Notification } from '../../../../types/domain/notifications/notification';
import NotificationFilters from './filters/NotificationFilters';
import NotificationItem from './NotificationItem';
import {
  SNotificationList,
  SNotificationListFooter,
  SNotificationListHeader,
  SNotificationListHeaderLeft,
  SNotificationListLoading,
  SNotificationListTitle,
  SNotifications,
  SNotificationsEmpty,
  SNotificationsEmptyIcon,
  SNotificationsEmptyTitle,
  SNotificationsLoading,
  SNotificationUnreadOnly,
} from './NotificationList.styles';

type Props = {
  show: boolean;
  notifications: Notification[];
  loading: boolean;
  markAllAsReadLoading: boolean;
  error: apiTypes.GenericServerError | null;
  pagination: Pagination | null;
  filters: NotificationActionType[];
  availableFilters: NotificationActionType[];
  totalUnreadCount: number;
  unreadOnly: boolean;
  onFilterChange: (filters: NotificationActionType[]) => void;
  onFilterUnreadOnly: (active: boolean) => void;
  onFetchNotifications: (payload: GetNotificationsParams) => void;
  onHideNotifications: () => void;
  onMarkAsRead: (payload: { notification: Notification; isRead: boolean }) => void;
  onMarkAllAsRead: () => void;
};

export const NotificationList: React.FC<Props> = ({
  notifications,
  loading,
  markAllAsReadLoading,
  pagination,
  filters = [],
  availableFilters = [],
  totalUnreadCount = 0,
  unreadOnly = false,
  onFilterUnreadOnly,
  onFilterChange,
  onFetchNotifications,
  onHideNotifications,
  onMarkAsRead,
  onMarkAllAsRead,
}) => {
  const forwardedRef = useRef<HTMLDivElement>(null);
  const hasNext = (pagination && pagination.hasNext) || false;

  const onSetStatus = (notification: Notification, isRead: boolean) => {
    // update a single notification with READ status
    onMarkAsRead({ notification, isRead });
  };

  const onLoadMoreNotifications = () => {
    // load more notifications after the oldest one fetched
    if (notifications.length) {
      const { publishedOn } = notifications[notifications.length - 1];
      onFetchNotifications({ before: publishedOn });
    }
  };

  const bodyClickHandler = e => {
    // additionally checks NotificationFilters (in a portal) for outside clicks
    const clickInside =
      forwardedRef && forwardedRef.current && forwardedRef.current.contains(e.target);

    if (!clickInside) {
      onHideNotifications();
    }
  };

  // useInfiniteScroll custom hook returns a ref which should be attached to the list which is scrolling
  const infiniteScrollRef: RefObject<HTMLDivElement> = useInfiniteScroll({
    threshold: 1.5, // % of parent container height from bottom, 1.5x the container height here
    hasNext: !loading && hasNext, // duck loading + pagination to allow onNext
    onNext: onLoadMoreNotifications,
  });

  const loadingInitial = loading && !pagination;

  // OutsideClickHandler wraps with div, needs 'inline' to scroll properly
  return (
    <OutsideClickHandler display="inline" onOutsideClick={bodyClickHandler}>
      <SNotifications>
        <SNotificationListHeader>
          <SNotificationListHeaderLeft>
            <SNotificationListTitle>Notifications</SNotificationListTitle>
            <NotificationFilters
              forwardedRef={forwardedRef}
              onChange={onFilterChange}
              filters={filters}
              availableFilters={availableFilters}
            />
          </SNotificationListHeaderLeft>
          <SNotificationUnreadOnly>
            <Checkbox value={unreadOnly} onChange={onFilterUnreadOnly}>
              Show Unread Only
            </Checkbox>
          </SNotificationUnreadOnly>
        </SNotificationListHeader>
        <SNotificationList ref={infiniteScrollRef}>
          {loadingInitial ? (
            <SNotificationsLoading>
              <Icon name="spinner-third" size="lg" fixedWidth spin />
            </SNotificationsLoading>
          ) : (
            <React.Fragment>
              {notifications.map(n => (
                <NotificationItem
                  notification={n}
                  onMarkRead={onSetStatus}
                  onRedirectToResource={onHideNotifications}
                  key={n.id}
                />
              ))}
              {notifications.length === 0 && !loading && (
                <SNotificationsEmpty>
                  <SNotificationsEmptyIcon>
                    <Icon name="bell" size="3x" />
                  </SNotificationsEmptyIcon>
                  <SNotificationsEmptyTitle>
                    No {unreadOnly && 'unread'} notifications
                  </SNotificationsEmptyTitle>
                  <span>Check back later for updates</span>
                </SNotificationsEmpty>
              )}
            </React.Fragment>
          )}
          {hasNext && loading && (
            <SNotificationListLoading>
              <Icon name="spinner-third" size="lg" fixedWidth spin />
            </SNotificationListLoading>
          )}
        </SNotificationList>
        <SNotificationListFooter>
          {!loadingInitial && (
            <LinkButton
              onClick={onMarkAllAsRead}
              loading={markAllAsReadLoading}
              disabled={notifications.length === 0 || totalUnreadCount === 0}
            >
              Mark All as Read
            </LinkButton>
          )}
        </SNotificationListFooter>
      </SNotifications>
    </OutsideClickHandler>
  );
};

export default NotificationList;
