import isNil from 'lodash/isNil';
import React from 'react';

import DashboardValueBase, { Size } from '../dashboard-value-base/DashboardValueBase';

export type Props = {
  label?: string;
  value?: number | null;
  size?: Size;
  performance?: number | null;
};

/**
 * NumericValue displays a neutral numeric value
 * with optional label, size and performance
 */
const NumericValue: React.FC<Props> = ({ label, value, size, performance }) => {
  return (
    <DashboardValueBase
      renderValue={() => (!isNil(value) ? value : '-')}
      label={label}
      size={size}
      performance={performance}
    />
  );
};

export default NumericValue;
