import memoize from 'memoize-one';

import routeFactory from '../../../common/util/routeFactory';
import { CalendarCategory } from '../../../types/domain/calendar/constants';

export type CalendarTabType = {
  value: CalendarCategory;
  label: string;
  routePath: string;
  isAvailable: boolean;
};

export const getCalendarTabs: ({
  canViewIoi,
  showLiveAndFiled,
}: {
  canViewIoi: boolean;
  showLiveAndFiled: boolean;
}) => CalendarTabType[] = memoize(
  ({ canViewIoi, showLiveAndFiled }: { canViewIoi: boolean; showLiveAndFiled: boolean }) => {
    const calendarTabs = [
      {
        value: CalendarCategory.LIVE,
        label: 'Live',
        routePath: routeFactory.calendarLiveOfferings.routePath,
        isAvailable: showLiveAndFiled,
      },
      {
        value: CalendarCategory.PRICED,
        label: 'Priced',
        routePath: routeFactory.calendarPricedOfferings.routePath,
        isAvailable: true,
      },
      {
        value: CalendarCategory.FILED,
        label: 'Filed',
        routePath: routeFactory.calendarFiledOfferings.routePath,
        isAvailable: showLiveAndFiled,
      },
      {
        value: CalendarCategory.POSTPONED,
        label: 'Postponed',
        routePath: routeFactory.calendarPostponedOfferings.routePath,
        isAvailable: showLiveAndFiled,
      },
      {
        value: CalendarCategory.LOCK_UP_EXPIRATION,
        label: 'Lock-Up Expirations',
        routePath: routeFactory.calendarLockupExpirationOfferings.routePath,
        isAvailable: true,
      },
      {
        value: CalendarCategory.MY_OFFERINGS,
        label: 'My Offerings',
        routePath: routeFactory.calendarMyOfferings.routePath,
        isAvailable: !canViewIoi,
      },
      {
        value: CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS,
        label: 'My Offerings',
        routePath: routeFactory.calendarMyOfferingsWithAllocation.routePath,
        isAvailable: canViewIoi,
      },
    ];

    return calendarTabs.filter(tab => tab.isAvailable);
  }
);
