import { CalendarCategory } from '../../../types/domain/calendar/constants';
import {
  allocationColumn,
  Column,
  countryColumn,
  customSectorColumn,
  expectedPricingDateColumn,
  ioiTypeColumn,
  issuerFirmNameForMyOfferingColumn,
  leftLeadColumn,
  marketCapAtPricingColumn,
  marketCapAtPricingLocalCurrColumn,
  offeringTypeColumn,
  offerPriceColumn,
  offerPriceLocalCurrColumn,
  priceRangeLowColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sellingRestrictionColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { TabProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS;

export const columnsConfig: Column[] = [
  issuerFirmNameForMyOfferingColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sellingRestrictionColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  expectedPricingDateColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  sizeSharesColumn,
  secondarySharesColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapAtPricingColumn,
  priceRangeLowColumn,
  offerPriceLocalCurrColumn,
  offerPriceColumn,
  leftLeadColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
  allocationColumn,
  ioiTypeColumn,
];

export const staticColumns: Column[] = [issuerFirmNameForMyOfferingColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c => ![secondarySharesColumn, totalBookRunnersColumn, totalNonBookRunnersColumn].includes(c)
  )
  .map(c => c.field);

export const tabConfig = {
  calendarCategory: calendarCategory,
  defaultGroupBy: 'attributes.pricingDate',
  defaultGroupByOrderType: 'descWithNullFirst' as const,
  defaultOrderBy: 'issuer.name',
  defaultOrderByType: 'asc' as const,
  columnsConfig: columnsConfig,
  staticColumns: staticColumns,
  defaultVisibleColumns: defaultVisibleColumns,
};

const MyOfferingsWithAllocationsCalendar = (props: TabProps) => {
  return <BaseDatalabCalendar {...props} {...tabConfig} />;
};

export default MyOfferingsWithAllocationsCalendar;
