import { MediaQuery } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import routeFactory from '../../../../../common/util/routeFactory';
import CompanySearch from './CompanySearch';
import { useIssuerSearch } from './hooks/useIssuerSearch';
import MobileCompanySearch from './MobileCompanySearch';
import type { Company } from './types';

export const CompanySearchContainer: React.FC = () => {
  const { push } = useHistory();
  const { searchOptions } = useIssuerSearch();

  const handleItemClick = (companyId: Company['id']) => {
    push(routeFactory.companies.getUrlPath({ id: companyId }));
  };

  return (
    <MediaQuery.IsMediumUp>
      {(isMediumUp: boolean) =>
        isMediumUp ? (
          <CompanySearch onItemClick={handleItemClick} onSearchOptions={searchOptions} />
        ) : (
          <MobileCompanySearch onItemClick={handleItemClick} onSearchOptions={searchOptions} />
        )
      }
    </MediaQuery.IsMediumUp>
  );
};

export default CompanySearchContainer;
