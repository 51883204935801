import { Popover } from '@cmg/common';
import { Box, Grid, Typography } from '@cmg/design-system';
import React from 'react';

import BooleanIcon from './BooleanIcon';

export type Props = {
  value?: boolean | null;
  tooltip?: string | null;
};
const gridStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };
const iconBoxStyle = { height: 19 };

const BooleanIconWithTooltip: React.FC<Props> = ({ value, tooltip }) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={1}>
        <Box sx={iconBoxStyle}>
          <BooleanIcon value={value} />
        </Box>
      </Grid>
      <Grid item xs={11} sx={gridStyle}>
        {tooltip && tooltip.length > 20 ? (
          <Popover
            variant="TOOLTIP"
            placement="bottom"
            hideArrow
            autoAdjustOverflow
            content={<Typography width={theme => theme.spacing(50)}>{tooltip}</Typography>}
          >
            <Typography noWrap display="inline" marginLeft={1} variant="hint">
              {tooltip}
            </Typography>
          </Popover>
        ) : (
          <Typography display="inline" noWrap marginLeft={1}>
            {tooltip}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BooleanIconWithTooltip;
