import { Column, Row } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { MyParticipationSummary as MyParticipationSummaryType } from '../../../../../../types/domain/my-dashboard/myParticipationSummary';
import CurrencyValue from '../../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../../common/dashboard-value/numeric-value/NumericValue';
import PerformanceMetricValue from '../../../../common/dashboard-value/performance-metric-value/PerformanceMetricValue';
import HorizontalLine from '../../../../common/horizontal-line/HorizontalLine';
import BlurOverlay from '../../../common/blur-overlay/BlurOverlay';
import { SectionHeader } from '../../../common/DashboardPeriod';
import ParticipationDetail from './ParticipationDetail';

const StyledColumn = styled(Column)`
  margin: 5px 0;
  position: relative;
`;

export const StyledParticipationSummary = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
  /** when hasMyDatalab is false BlurOverlay is absolute positioned on top of the entire row */
  position: relative;
`;

const myParticipationSummaryDefault: MyParticipationSummaryType = {
  numberOfOfferingsPctChange: 0,
  investedPctChange: 1.47,
  numberOfOfferings: 6,
  invested: 130500000,
  performance: 0.113,
  ipos: {
    numberOfOfferings: 1,
    invested: 0.0,
    performance: 0.005,
  },
  marketedFos: {
    numberOfOfferings: 3,
    invested: 87600000,
    performance: 0.163,
  },
  overnightFos: {
    numberOfOfferings: 0,
    invested: 0.0,
    performance: 0.0,
  },
  registeredBlocks: {
    numberOfOfferings: 2,
    invested: 42900000,
    performance: 0.091,
  },
  unregisteredBlocks: {
    numberOfOfferings: 0,
    invested: 0.0,
    performance: 0.0,
  },
};

export type Props = {
  data?: MyParticipationSummaryType;
  hasMyDatalab: boolean;
};

const MyParticipationSummary: React.FC<Props> = ({ data, hasMyDatalab }) => {
  // seed with mock data if the user does not have my data lab
  const myParticipationData: MyParticipationSummaryType | undefined = hasMyDatalab
    ? data
    : myParticipationSummaryDefault;

  return (
    <div data-test-id={myDashboard.myParticipationSummarySection.testId}>
      <StyledParticipationSummary>
        <StyledColumn sm={6}>
          <SectionHeader>My Participation Summary</SectionHeader>
        </StyledColumn>
        <StyledColumn sm={5}>
          {!hasMyDatalab && <BlurOverlay />}
          <NumericValue
            value={myParticipationData?.numberOfOfferings}
            label="# Offerings"
            size="mediumLarge"
          />
        </StyledColumn>
        <StyledColumn sm={5}>
          {!hasMyDatalab && <BlurOverlay />}
          <CurrencyValue
            value={myParticipationData?.invested}
            label="Invested $"
            size="mediumLarge"
          />
        </StyledColumn>
        <StyledColumn sm={5}>
          {!hasMyDatalab && <BlurOverlay />}
          <PerformanceMetricValue value={myParticipationData?.performance} size="mediumLarge" />
        </StyledColumn>
      </StyledParticipationSummary>
      <HorizontalLine size="medium" />
      <ParticipationDetail label="IPO" data={myParticipationData?.ipos} />
      <ParticipationDetail label="Marketed FOs" data={myParticipationData?.marketedFos} />
      <ParticipationDetail label="Overnight FOs" data={myParticipationData?.overnightFos} />
      <ParticipationDetail label="Registered Blocks" data={myParticipationData?.registeredBlocks} />
      <ParticipationDetail
        label="Unregistered Blocks"
        data={myParticipationData?.unregisteredBlocks}
      />
    </div>
  );
};

export default MyParticipationSummary;
