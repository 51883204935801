import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import { DatalabScreens } from '../../../../../datalab/constants';

export type Props = {
  data?: { managerId: string; managerName: string };
};

const UnderwriterRenderer = ({ data }: Props) => {
  const canViewConverts = useCheckPermissions([permissionsByEntity.ConvertsOffering.READ]);

  if (!data) {
    return <React.Fragment>-</React.Fragment>;
  }

  // Disable link for converts as the destination screen won't show anything or show less offerings
  if (canViewConverts) {
    return <React.Fragment>{data.managerName}</React.Fragment>;
  }

  const url = routeFactory.datalab.getUrlPath({
    screen: DatalabScreens.UNDERWRITER_OFFERINGS,
    type: 'table',
    query: {
      underwriter: data.managerId,
    },
  });

  return <Link to={url}>{data.managerName}</Link>;
};

export default UnderwriterRenderer;
