import debounce from 'lodash/debounce';

/**
 * A cross browser impl of dispatching a browser resize event.
 * Adapted from: https://stackoverflow.com/questions/1818474/how-to-trigger-the-window-resize-event-in-javascript/43846782#43846782
 */
export const dispatchBrowserResizeEvent = debounce(() => {
  if (typeof Event === 'function') {
    // modern browsers
    window.dispatchEvent(new Event('resize'));
  } else {
    // for IE and other old browsers
    // causes deprecation warning on modern browsers
    const evt = window.document.createEvent('UIEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  }
}, 100);
