import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SSuggestedUserButtonIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.color.blue1};
`;

export const SSuggestedUserButton = styled.button`
  border: none;
  background: none;
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`;

export const SSuggestedPeople = styled.div`
  margin-bottom: 25px;
`;
