import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetAllManagersSectionData } from '../hooks/useGetAllManagersSectionData';
import { x6_l6_m12_s12, x12_l12_m12_s12 } from '../OfferingProfile.styles';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';
import { ManagerRowItem } from '../types';

export type ManagerTableProps = {
  managers: ManagerRowItem[];
};
export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const ManagersList: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const managers = useGetAllManagersSectionData(offeringProfile);
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const totalManagers = managers.length;
  const managersHalf = managers.length / 2 + (managers.length % 2);

  const title = React.useMemo(() => `Sales Agents (${totalManagers ?? 0})`, [totalManagers]);
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {!totalManagers || totalManagers === 0 ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <TableContainer>
              {isSmallScreen || managers.length < 6 ? (
                <Grid container columnSpacing={3} display="flex">
                  <Grid item {...x12_l12_m12_s12}>
                    <Table size="medium">
                      <TableBody>
                        {managers.map(({ name }) => {
                          return (
                            <TableRow key={name}>
                              <TableCell>{name}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              ) : (
                <Grid container columnSpacing={3} display="flex">
                  <Grid item {...x6_l6_m12_s12}>
                    <Table size="medium">
                      <TableBody>
                        {managers.slice(0, managersHalf).map(({ name }) => {
                          return (
                            <TableRow key={name}>
                              <TableCell>{name}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item {...x6_l6_m12_s12}>
                    <Table size="medium">
                      <TableBody>
                        {managers.slice(managersHalf).map(({ name }) => {
                          return (
                            <TableRow key={name}>
                              <TableCell>{name}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              )}
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ManagersList;
