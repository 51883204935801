import { styled, TableCell } from '@cmg/design-system';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: theme.spacing(14),
}));

export const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(14),
}));
