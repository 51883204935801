import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { MixedChart, MixedChartProps } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors, transparent } from '../../../colors';
import afterMarketPerfOfferButtons from '../../../model/buttons/after-market-perf-offer-buttons';
import fromFilingLastTradeButtons, {
  FromFilingLastTradeButtons,
} from '../../../model/buttons/from-filing-last-trade-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type Datapoints = {
  points: {
    x: number;
    y: number;
    ticker: string;
  }[];
  line: {
    x: number;
    y: number;
  }[];
};

type Props = {
  chartData?: {
    file_to_offer_discount: {
      offer_to_current: Datapoints;
      offer_to_day: Datapoints;
      offer_to_month: Datapoints;
      offer_to_three_months: Datapoints;
      offer_to_six_months: Datapoints;
      offer_to_open: Datapoints;
    };
    discount_to_last_trade: {
      offer_to_current: Datapoints;
      offer_to_day: Datapoints;
      offer_to_month: Datapoints;
      offer_to_three_months: Datapoints;
      offer_to_six_months: Datapoints;
      offer_to_open: Datapoints;
    };
  };
};
type RawItem = {
  x: number;
  y: number;
  ticker?: string;
};
export const transformData = (
  chartData: Props['chartData'],
  activeBtn,
  offerActiveBtn
): MixedChartProps['data'] | null => {
  if (!chartData) {
    return null;
  }

  const type =
    activeBtn.value === FromFilingLastTradeButtons.FROM_FILING
      ? 'file_to_offer_discount'
      : 'discount_to_last_trade';
  const selectedChartData = chartData[type][offerActiveBtn.value];

  const data: MixedChartProps['data'] = {
    // TODO - move to data?
    labels: selectedChartData.points.map(item => item.label),
    datasets: [
      {
        borderColor: transparent,
        data: selectedChartData.points.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
            ticker: item.ticker,
          })
        ),
        backgroundColor: barChartColors.lighter,
        pointBorderColor: barChartColors.lighter,
        pointBorderWidth: 0,
        pointRadius: 3,
        pointHoverRadius: 6,
        type: 'scatter',
      },
      {
        data: selectedChartData.line.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
          })
        ),
        pointRadius: 0,
        backgroundColor: barChartColors.light,
        borderColor: barChartColors.light,
        type: 'line',
      },
    ],
  };

  return data;
};

export const getOptions = (
  fromFilingSelected: boolean,
  offerPeriodLabel: string
): MixedChartProps['options'] => ({
  scales: {
    x: {
      title: {
        display: true,
        text: '(Discount)',
      },
      ticks: {
        callback: value => {
          if (typeof value === 'number') {
            return numericUtil.formatPercents(value, 1);
          }
          return value;
        },
      },
    },
    y: {
      ticks: {
        display: true,
        callback: value =>
          typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
      },
      title: {
        display: true,
        text: '(Aftermarket Performance)',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title(tooltipItems) {
          const raw = tooltipItems[0].raw as RawItem;

          return raw.ticker ?? '';
        },
        label: tooltipItem => {
          return [
            `${offerPeriodLabel}: ${numericUtil.formatPercents(tooltipItem.parsed.y, 1)}`,
            `${
              fromFilingSelected ? 'File to Offer Discount' : 'Discount to Last Trade'
            }: ${numericUtil.formatPercents(tooltipItem.parsed.x, 1)}`,
          ];
        },
      },
    },
  },
});

const WidgetAftermarketPerformanceRelativeToPricing = ({ chartData }: Props) => {
  const [activeBtn, setActiveBtn] = React.useState(fromFilingLastTradeButtons[0]);
  const [offerActiveBtn, setOfferActiveBtn] = React.useState(afterMarketPerfOfferButtons[1]);

  const fromFilingSelected = activeBtn.value === FromFilingLastTradeButtons.FROM_FILING;

  const transformedData = React.useMemo(
    () => transformData(chartData, activeBtn, offerActiveBtn),
    [chartData, activeBtn, offerActiveBtn]
  );
  const options = React.useMemo(
    () => getOptions(fromFilingSelected, offerActiveBtn.label),
    [fromFilingSelected, offerActiveBtn]
  );

  const handleButtonChange = activeBtn => {
    setActiveBtn(activeBtn);
  };

  const handleOfferBtnChange = btn => {
    setOfferActiveBtn(btn);
  };

  if (!transformedData) {
    return null;
  }

  return (
    <SDatalabChart>
      <Box.Header title="Aftermarket Performance Relative to Pricing" />

      <SButtonBox>
        <Toggler
          buttons={fromFilingLastTradeButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />

        <Toggler
          buttons={afterMarketPerfOfferButtons}
          activeButton={offerActiveBtn}
          onChange={handleOfferBtnChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <MixedChart data={transformedData} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetAftermarketPerformanceRelativeToPricing;
