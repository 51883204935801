import { numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { DLGWShareholder } from '../../../../features/datalab/types';
import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { sponsorTypeLabels } from '../../../shared/constants/constants';
import { DatalabScreens } from '../../constants';

export type Row = {
  shareholders?: DLGWShareholder[];
  primary_shareholder_firm_id: string;
};

export type Props = {
  label: string;
  row: Row;
};

const ShareholderRenderer: React.FC<Props> = ({ label, row }) => {
  // For now we decided to force the refresh due to a bug in global ecm
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    window.location.href = routeFactory.datalab.getUrlPath({
      screen: DatalabScreens.MARKET_PULSE,
      type: 'table',
      query: {
        sponsor: row.primary_shareholder_firm_id,
      },
    });
  };

  return (
    <Popover
      trigger="hover"
      variant="TOOLTIP"
      placement="left"
      transparentBackground
      content={
        <PopoverContentTable alignAll="right" alignFirstColumn="left">
          <thead>
            <tr>
              <th>Shareholder</th>
              <th>Sponsor type</th>
              <th colSpan={2}>Pre-offering shares</th>
              <th colSpan={2}>Post-offering shares</th>
            </tr>
          </thead>
          <tbody>
            {row.shareholders?.map((s, idx) => (
              <tr key={idx}>
                <td>{s.firm_name}</td>
                <td>{s.sponsor_type && sponsorTypeLabels[s.sponsor_type]}</td>
                <td>{numericUtil.formatInteger(s.pre_offering_ownership)}</td>
                <td>{numericUtil.formatPercents(s.pct_pre_offering_ownership, 1)}</td>
                <td>{numericUtil.formatInteger(s.post_offering_ownership)}</td>
                <td>{numericUtil.formatPercents(s.pct_post_offering_ownership, 1)}</td>
              </tr>
            ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      <Link onClick={handleClick} to="">
        {label}
      </Link>
    </Popover>
  );
};

export default ShareholderRenderer;
