import { numericUtil } from '@cmg/common';
import React from 'react';

import { SValueRenderer } from './Renderer.styles';

type Props = {
  value?: number;
  precision?: number;
};

const PercentValueRenderer: React.FC<Props> = ({ value = 0, precision = 2 }) => (
  <SValueRenderer>{numericUtil.formatNumber(value * 100, precision)}%</SValueRenderer>
);

export default PercentValueRenderer;
