/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_Underwriters_ManagerPartsFragment = {
  __typename?: 'Manager';
  id: string;
  name?: string | null;
};

export type OfferingsFilterForm_UnderwritersQueryVariables = Types.Exact<{
  order?: Types.InputMaybe<Array<Types.ManagerSortInput> | Types.ManagerSortInput>;
  where?: Types.InputMaybe<Types.ManagerFilterInput>;
}>;

export type OfferingsFilterForm_UnderwritersQuery = {
  __typename?: 'Query';
  managers?: {
    __typename?: 'ManagerCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Manager'; id: string; name?: string | null }> | null;
  } | null;
};

export const OfferingsFilterForm_Underwriters_ManagerPartsFragmentDoc = gql`
  fragment OfferingsFilterForm_Underwriters_ManagerParts on Manager {
    id
    name
  }
`;
export const OfferingsFilterForm_UnderwritersDocument = gql`
  query OfferingsFilterForm_Underwriters($order: [ManagerSortInput!], $where: ManagerFilterInput) {
    managers(order: $order, where: $where) {
      items {
        ...OfferingsFilterForm_Underwriters_ManagerParts
      }
      totalCount
    }
  }
  ${OfferingsFilterForm_Underwriters_ManagerPartsFragmentDoc}
`;

/**
 * __useOfferingsFilterForm_UnderwritersQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_UnderwritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_UnderwritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_UnderwritersQuery({
 *   variables: {
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOfferingsFilterForm_UnderwritersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_UnderwritersQuery,
    OfferingsFilterForm_UnderwritersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_UnderwritersQuery,
    OfferingsFilterForm_UnderwritersQueryVariables
  >(OfferingsFilterForm_UnderwritersDocument, options);
}
export function useOfferingsFilterForm_UnderwritersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_UnderwritersQuery,
    OfferingsFilterForm_UnderwritersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_UnderwritersQuery,
    OfferingsFilterForm_UnderwritersQueryVariables
  >(OfferingsFilterForm_UnderwritersDocument, options);
}
export function useOfferingsFilterForm_UnderwritersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingsFilterForm_UnderwritersQuery,
        OfferingsFilterForm_UnderwritersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingsFilterForm_UnderwritersQuery,
    OfferingsFilterForm_UnderwritersQueryVariables
  >(OfferingsFilterForm_UnderwritersDocument, options);
}
export type OfferingsFilterForm_UnderwritersQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_UnderwritersQuery
>;
export type OfferingsFilterForm_UnderwritersLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_UnderwritersLazyQuery
>;
export type OfferingsFilterForm_UnderwritersSuspenseQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_UnderwritersSuspenseQuery
>;
export type OfferingsFilterForm_UnderwritersQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_UnderwritersQuery,
  OfferingsFilterForm_UnderwritersQueryVariables
>;
