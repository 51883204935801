export type SummaryField = {
  field: string;
  label: string;
};

export const minField = {
  field: 'min',
  label: 'Min',
};

export const maxField = {
  field: 'max',
  label: 'Max',
};

export const meanField = {
  field: 'mean',
  label: 'Mean',
};

export const medianField = {
  field: 'median',
  label: 'Median',
};

export const sumField = {
  field: 'sum',
  label: 'Sum',
};

export const totalField = {
  field: 'sum',
  label: 'Total',
};

export const yoyField = {
  field: 'yoy',
  label: 'YoY',
};
