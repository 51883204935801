import React from 'react';

import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';

type Props = {
  userReportPartial: UserReportPartial;
};

const ReportLastModifiedByCell = ({ userReportPartial }: Props) =>
  userReportPartial.lastModified ? <td>{userReportPartial.lastModified.name}</td> : <td />;

export default ReportLastModifiedByCell;
