import { DangerButton, LinkButton, Modal } from '@cmg/common';
import React from 'react';

type Props = {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const AllocationConfirmDialog: React.FC<Props> = ({ show, onCancel, onConfirm }) => (
  <Modal
    show={show}
    onHide={onCancel}
    title="Confirm"
    closeButton
    footer={
      <React.Fragment>
        <LinkButton onClick={onCancel}>No, Keep the Allocation</LinkButton>
        <DangerButton onClick={onConfirm}>Yes, Delete the Allocation</DangerButton>
      </React.Fragment>
    }
  >
    <p>Are you sure you want to clear Allocation data?</p>
  </Modal>
);

export default AllocationConfirmDialog;
