import { DateRangePresetOptions, DateRangePresetTypes, dateRangeUtil, timeUtil } from '@cmg/common';
import {
  addDays,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import isNil from 'lodash/isNil';
import memoize from 'memoize-one';

import { DateRangeControl } from '../../../../../types/domain/my-dashboard/myDashboardParameters';

export type FormDateRange = {
  start?: string;
  end?: string;
  type: DateRangePresetTypes;
};

/**
 * Maps date range control to form date range
 */
export const mapDefaultDatePeriod = ({
  startDate,
  endDate,
  dateType,
}: DateRangeControl): FormDateRange => ({
  start: startDate,
  end: endDate,
  type: dateType,
});

/**
 * currentToComparisonType used to map the auto selection
 * of comparison type whenever a current time period is selected
 */
const currentToComparisonType = {
  [DateRangePresetTypes.CUSTOM]: DateRangePresetTypes.CUSTOM,
  [DateRangePresetTypes.TW]: DateRangePresetTypes.PREV_W,
  [DateRangePresetTypes.P2W]: DateRangePresetTypes.PREV_2W,
  [DateRangePresetTypes.PM]: DateRangePresetTypes.PREV_M,
  [DateRangePresetTypes.PQ]: DateRangePresetTypes.PREV_Q,
  [DateRangePresetTypes.YTD]: DateRangePresetTypes.PREV_YTD,
};
/**
 * mapSubmittedComparisonDatePeriod takes a current type period form value
 * and sets the mapped comparison type value
 */
export const mapSubmittedComparisonDatePeriod = (
  currentTimePeriod: FormDateRange
): DateRangeControl => {
  const { value } = dateRangeUtil.getPreset(
    currentToComparisonType[currentTimePeriod.type],
    dateRangeUtil.getPresetsArray(getComparisonPeriodPresetOptions(new Date()))
  );

  return mapSubmittedDateRangeValue(value as FormDateRange);
};

/**
 * Maps date range period to DateRangeControl
 */
export const mapSubmittedDateRangeValue = ({
  start,
  end,
  type,
}: FormDateRange): DateRangeControl => ({
  startDate: start,
  endDate: end,
  dateType: type as DateRangePresetTypes,
});

/**
 * Checks if the date range set in the date range picker is valid
 * if the type value is CUSTOM ensure start and end are not nil
 * otherwise start and end are optional
 */
export const isValidDateRange = (dtRange: {
  start?: string | null | undefined;
  end?: string | null | undefined;
  type?: string | undefined;
}): boolean => {
  if (dtRange.type === DateRangePresetTypes.CUSTOM) {
    return !isNil(dtRange.start) && !isNil(dtRange.end);
  } else {
    return true;
  }
};

export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

const thisWeekStart = (currentDate: Date) => startOfWeek(currentDate, { weekStartsOn: 1 });
const priorTwoWeeksStart = (currentDate: Date) =>
  startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });
const priorMonthStart = (currentDate: Date) => startOfMonth(subMonths(currentDate, 1));
const priorMonthEnd = (currentDate: Date) => endOfMonth(subMonths(currentDate, 1));
const priorQuarterStart = (currentDate: Date) => startOfQuarter(subQuarters(currentDate, 1));
const priorQuarterEnd = (currentDate: Date) => endOfQuarter(subQuarters(currentDate, 1));
const priorYearStart = (currentDate: Date) => startOfYear(currentDate);

export const getCurrentPeriodPresetOptionsWithDate = (
  currentDate: Date
): DateRangePresetOptions => ({
  CUSTOM: { label: 'Custom', short: 'Custom', value: { type: DateRangePresetTypes.CUSTOM } },
  TW: {
    label: 'This Week',
    short: 'TW',
    value: {
      type: DateRangePresetTypes.TW,
      start: formatDate(thisWeekStart(currentDate)), // week starts on Monday
      end: formatDate(currentDate),
    },
  },
  P2W: {
    label: 'Prior 2 Weeks',
    short: 'P2W',
    value: {
      type: DateRangePresetTypes.P2W,
      start: formatDate(priorTwoWeeksStart(currentDate)),
      end: formatDate(currentDate),
    },
  },
  PM: {
    label: 'Prior Month',
    short: 'PM',
    value: {
      type: DateRangePresetTypes.PM,
      start: formatDate(priorMonthStart(currentDate)),
      end: formatDate(priorMonthEnd(currentDate)),
    },
  },
  PQ: {
    label: 'Prior Quarter',
    short: 'PQ',
    value: {
      type: DateRangePresetTypes.PQ,
      start: formatDate(priorQuarterStart(currentDate)),
      end: formatDate(priorQuarterEnd(currentDate)),
    },
  },
  YTD: {
    label: 'Year to Date',
    short: 'YTD',
    value: {
      type: DateRangePresetTypes.YTD,
      start: formatDate(priorYearStart(currentDate)),
      end: formatDate(currentDate),
    },
  },
});

export const getCurrentPeriodPresetOptions: (currentDate: Date) => DateRangePresetOptions = memoize(
  getCurrentPeriodPresetOptionsWithDate,
  timeUtil.isSameDayEqualityFn
);

/** Comparison Date Range Presets calculated based on Current Date Ranges */
export const getComparisonPeriodPresetOptionsWithDate = (
  currentDate: Date
): DateRangePresetOptions => ({
  CUSTOM: { label: 'Custom', short: 'Custom', value: { type: DateRangePresetTypes.CUSTOM } },
  PREV_W: {
    label: 'Previous Week',
    short: 'PREVW',
    value: {
      type: DateRangePresetTypes.PREV_W,
      start: formatDate(subWeeks(thisWeekStart(currentDate), 1)),
      end: formatDate(addDays(endOfWeek(subWeeks(currentDate, 1)), 1)), // add 1 day because endOfWeek returns saturday, our week ends on sunday
    },
  },
  PREV_2W: {
    label: 'Previous 2 Weeks',
    short: 'PREV2W',
    value: {
      type: DateRangePresetTypes.PREV_2W,
      start: formatDate(subWeeks(priorTwoWeeksStart(currentDate), 2)),
      end: formatDate(addDays(endOfWeek(subWeeks(priorTwoWeeksStart(currentDate), 1)), 1)), // add 1 day because endOfWeek returns saturday, our week ends on sunday
    },
  },
  PREV_M: {
    label: 'Previous Month',
    short: 'PREVM',
    value: {
      type: DateRangePresetTypes.PREV_M,
      start: formatDate(subMonths(priorMonthStart(currentDate), 1)),
      end: formatDate(subMonths(priorMonthEnd(currentDate), 1)),
    },
  },
  PREV_Q: {
    label: 'Previous Quarter',
    short: 'PREVQ',
    value: {
      type: DateRangePresetTypes.PREV_Q,
      start: formatDate(subQuarters(priorQuarterStart(currentDate), 1)),
      end: formatDate(endOfMonth(subQuarters(priorQuarterEnd(currentDate), 1))),
    },
  },
  PREV_YTD: {
    label: 'Previous Year to Date',
    short: 'PREVYTD',
    value: {
      type: DateRangePresetTypes.PREV_YTD,
      start: formatDate(subYears(priorYearStart(currentDate), 1)),
      end: formatDate(subYears(currentDate, 1)),
    },
  },
});

export const getComparisonPeriodPresetOptions: (currentDate: Date) => DateRangePresetOptions =
  memoize(getComparisonPeriodPresetOptionsWithDate, timeUtil.isSameDayEqualityFn);
