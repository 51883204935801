import styled from 'styled-components/macro';

import { SPill } from '../../../obsolete/components/ui/Pill.styles';

export const STitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const STitle = styled.div`
  font-size: 18px;

  ${({ theme }) => theme.mediaQuery.mediumUp} {
    font-size: 24px;
  }

  .ticker {
    // TODO investigate OfferingSubHeader, XcOfferingSubHeader
    color: ${({ theme }) => theme.text.color.light};
  }

  ${SPill} {
    // TODO investigate
    font-size: 50%;
    margin-left: 5px;
    vertical-align: middle;
    display: inline-block;
  }
`;

export const SWrapper = styled.div`
  height: 86px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.background.color.white};
  border-top: 1px solid ${({ theme }) => theme.border.color.light};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.mediumUp} {
    padding: 10px 30px;
  }

  .right-box {
    margin-left: 20px;

    .right-box-title {
      font-weight: ${({ theme }) => theme.text.weight.medium};
    }

    .right-box-content {
      font-weight: ${({ theme }) => theme.text.weight.light};

      font-size: 25px;
      line-height: 25px;

      ${({ theme }) => theme.mediaQuery.mediumUp} {
        font-size: 45px;
        line-height: 45px;
      }

      .dollar {
        font-size: 15px;
        line-height: 20px;
        vertical-align: top;

        ${({ theme }) => theme.mediaQuery.mediumUp} {
          font-size: 26px;
          line-height: 36px;
        }
      }
    }
  }

  .right-button {
    margin-left: 20px;
  }

  .subheader-buttons {
    display: flex;
  }
`;

export const SFooterWrapper = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.medium};
  display: flex;
  align-items: center;
`;

export const SFooterItem = styled.span`
  color: ${({ theme }) => theme.color.gray5}; // todo verify non-standard color
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;
