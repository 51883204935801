import { LinkButton } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';
import { loadReportActions } from '../../ducks';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadDatalabReport: loadReportActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  userReportPartial: UserReportPartial;
};

type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

export const StyledLinkButton = styled(LinkButton)`
  border: none;
  color: ${({ theme }) => theme.color.blue1};

  &:focus,
  &:hover {
    box-shadow: none;
    color: ${({ theme }) => theme.color.blue1};
    text-decoration: underline;
  }
`;

const SReportLabel = styled.span`
  display: block;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const ReportCell: React.FC<Props> = ({ userReportPartial, actions }) => (
  <td>
    <StyledLinkButton
      type="button"
      onClick={() => actions.loadDatalabReport({ reportId: userReportPartial.id })}
    >
      <SReportLabel>{userReportPartial.name}</SReportLabel>
    </StyledLinkButton>
  </td>
);

export default connect<null, ReturnType<typeof mapDispatchToProps>>(
  null,
  mapDispatchToProps
)(ReportCell);
