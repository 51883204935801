import { useAuth } from '@cmg/auth';

/**
 * Hook that determines if this user should see live and filed offerings.
 *
 * Using inversion of decision technique to encapsulate decision logic.
 * Inspiration: https://martinfowler.com/articles/feature-toggles.html#ImplementationTechniques
 *
 * @returns decision to show live and filed offerings
 */
export const useDecisionShowLiveAndFiled = (): boolean => {
  const { oidcUserAccountTraits } = useAuth();
  return oidcUserAccountTraits.includes('DATALAB_UI_LIVE_FILED');
};
