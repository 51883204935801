import { Box, numericUtil } from '@cmg/common';
import React, { useState } from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import { orderAscBySelector } from '../../../../../obsolete/utils/helpers';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors, noSectorAsLast } from '../../../colors';
import avgFeeByRoleSectorButtons, {
  AvgFeeByRoleSectorButtons,
} from '../../../model/buttons/avg-fee-by-role-sector-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

const feeButtons = [AvgFeeByRoleSectorButtons.TOTAL_FEES, AvgFeeByRoleSectorButtons.AVERAGE_FEE];

type Entry = {
  label: string;
  values: {
    activeBookrunner: number;
    passiveBookrunner: number;
    coManager: number;
    coLead?: number;
  };
};

type Props = {
  chartData?: {
    TOTAL_FEES: Entry[];
    TOTAL_OFFERINGS: Entry[];
    AVERAGE_FEE: Entry[];
  };
};

const transformData = (
  chartData: Props['chartData'],
  activeBtn: any
): BarChartProps['data'] | null => {
  if (!chartData) {
    return null;
  }

  const selectedDataRaw = chartData[activeBtn.value] || chartData[activeBtn.value.toUpperCase()];
  const selectedData = noSectorAsLast(
    selectedDataRaw.sort(orderAscBySelector.bind(null, item => item.label))
  ) as Entry[]; // cast back because these old functions cast to any
  return {
    labels: selectedData.map(item => item.label),
    datasets: [
      {
        label: 'Active Bookrunner',
        data: selectedData.map(item => item.values.activeBookrunner || null),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Bookrunner',
        data: selectedData.map(item => item.values.passiveBookrunner || null),
        backgroundColor: barChartColors.light,
      },
      {
        label: 'Co-Lead Manager',
        data: selectedData.map(item => item.values.coLead || null),
        backgroundColor: barChartColors.dark,
      },
      {
        label: 'Co-Manager',
        data: selectedData.map(item => item.values.coManager || null),
        backgroundColor: barChartColors.darker,
      },
    ],
  };
};

const getChartOptions = (activeBtn: any): BarChartProps['options'] => {
  return {
    indexAxis: 'y',
    datasets: {
      bar: {
        borderRadius: 0,
        categoryPercentage: 0.6,
        maxBarThickness: 100, // overrides default thin bars
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
          callback: value => {
            if (typeof value !== 'number') {
              return value;
            }
            if (activeBtn.value in feeButtons) {
              return numericUtil.formatCurrencyInBillions(value);
            }
            return numericUtil.formatInteger(value);
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;
            const value = parsed.x;
            return `${dataset.label ? `${dataset.label}: ` : ''}${
              activeBtn.value in feeButtons
                ? numericUtil.formatCurrencyInBillions(value)
                : numericUtil.formatInteger(value)
            }`;
          },
        },
      },
    },
  };
};

const WidgetAvgFeeRoleSector: React.FC<Props> = ({ chartData }) => {
  const [activeBtn, setActiveBtn] = useState(avgFeeByRoleSectorButtons[0]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  const transformedData = React.useMemo(
    () => transformData(chartData, activeBtn),
    [chartData, activeBtn]
  );
  const chartOptions = React.useMemo(() => getChartOptions(activeBtn), [activeBtn]);

  if (!transformedData) {
    return null;
  }

  return (
    <SDatalabChart>
      <Box.Header title="By Role and Sector" />

      <SButtonBox>
        <Toggler
          activeButton={activeBtn}
          onChange={handleButtonChange}
          buttons={avgFeeByRoleSectorButtons}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <BarChart data={transformedData} options={chartOptions} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetAvgFeeRoleSector;
