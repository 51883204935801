import { useAuth } from '@cmg/auth';
import { ToastManager, useHubspotForm } from '@cmg/common';
import React from 'react';

import LoadingOverlay from '../../../../../../../common/components/indicators/loading-overlay/LoadingOverlay';
import { getAppSettings } from '../../../../../../../config/appSettings';

const hubspotTarget = 'hubspot-support-form';

const HubspotForm = () => {
  const appSettings = getAppSettings();
  const { oidcUserEmail, oidcUserGivenName, oidcUserFamilyName } = useAuth();

  const handleSubmit = $form => {
    $form.find('input[name="firstname"]').val(oidcUserGivenName).change();
    $form.find('input[name="lastname"]').val(oidcUserFamilyName).change();
    $form.find('input[name="email"]').val(oidcUserEmail).change();
  };

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: appSettings.env.hubspotSupportFormPortalId || '',
    formId: appSettings.env.hubspotSupportFormId || '',
    target: `#${hubspotTarget}`,
    onFormSubmit: handleSubmit,
  });

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Failed to load support form');
    }
  }, [error]);

  return (
    <React.Fragment>
      {(!loaded || !formCreated) && <LoadingOverlay message="Loading..." />}
      <div id={hubspotTarget} />
    </React.Fragment>
  );
};

export default HubspotForm;
