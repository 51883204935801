import React from 'react';

import { formatManagerRowFn, managerSortFn } from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { ManagerRowItem } from '../types';

export const useGetAllManagersSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): ManagerRowItem[] =>
  React.useMemo(() => {
    const { managers = [], pricingCurrency } = offeringProfile.offeringById ?? {};
    return [...managers]
      .sort(managerSortFn)
      .map(manager => formatManagerRowFn(manager, pricingCurrency));
  }, [offeringProfile.offeringById]);
