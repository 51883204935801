import { Column, numericUtil, Row } from '@cmg/common';

import { getCurrencyFormat, getCurrencyFormatInMillions } from '../../../../shared/model/utils';
import type { CompanyProfile_StockProfile_FieldsFragment } from '../../../graphql/__generated__/CompanyProfile';
import SDlHorizontal from './SDlHorizontal';

export type Props = {
  stockProfile: CompanyProfile_StockProfile_FieldsFragment;
};

const PricePerformanceTab = ({ stockProfile }: Props) => {
  const pricingCurrencyCode = stockProfile.currency as string;
  return (
    <Row>
      <Column xs={24} sm={10}>
        <SDlHorizontal>
          <dt>Last Close:</dt>
          <dd>
            {getCurrencyFormat({
              value: stockProfile.lastClose,
              pricingCurrencyCode,
              showInternational: true,
            })}
          </dd>
          <dt>52-Week High/Low:</dt>
          <dd>
            {getCurrencyFormat({
              value: stockProfile.fiftyTwoWeekHigh,
              pricingCurrencyCode,
              showInternational: true,
            })}{' '}
            /{' '}
            {getCurrencyFormat({
              value: stockProfile.fiftyTwoWeekLow,
              pricingCurrencyCode,
              showInternational: true,
            })}
          </dd>
          <dt>% Below 52-Week High:</dt>
          <dd>{numericUtil.formatPercents(stockProfile.pctTo52WeekHigh, 1, 2)}</dd>
          <dt>% Above 52-Week Low:</dt>
          <dd>{numericUtil.formatPercents(stockProfile.pctTo52WeekLow, 1, 2)}</dd>
          <dt>Market Capitalization:</dt>
          <dd>
            {getCurrencyFormatInMillions({
              value: stockProfile.marketCap,
              pricingCurrencyCode,
              showInternational: true,
            })}
          </dd>
          <dt>Enterprise Value:</dt>
          <dd>
            {getCurrencyFormatInMillions({
              value: stockProfile.enterpriseValue,
              pricingCurrencyCode,
              showInternational: true,
            })}
          </dd>
        </SDlHorizontal>
      </Column>
      <Column xs={24} sm={12} offset={2}>
        <SDlHorizontal>
          <dt>4 Week Average Volume:</dt>
          <dd>{numericUtil.formatInteger(stockProfile.averageDailyVolume4Weeks)}</dd>
          <dt>Shares O/S:</dt>
          <dd>{numericUtil.formatNumber(stockProfile.sharesOutstanding, 1, -6)}</dd>
          <dt>% Change 1-Week:</dt>
          <dd>{numericUtil.formatPercents(stockProfile.pctPriceChange1Week, 1, 2)}</dd>
          <dt>% Change MTD:</dt>
          <dd>{numericUtil.formatPercents(stockProfile.pctPriceChangeMtd, 1, 2)}</dd>
          <dt>% Change YTD:</dt>
          <dd>{numericUtil.formatPercents(stockProfile.pctPriceChangeYtd, 1, 2)}</dd>
          <dt>% Change 52-Weeks:</dt>
          <dd>{numericUtil.formatPercents(stockProfile.pctPriceChange52Weeks, 1, 2)}</dd>
        </SDlHorizontal>
      </Column>
    </Row>
  );
};

export default PricePerformanceTab;
