import { myDashboard } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import routeFactory from '../../../../../../common/util/routeFactory';
import { LeagueTableOrderField } from '../../../../../../types/domain/my-dashboard/myDashboardParameters';
import CurrencyValue from '../../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../../common/dashboard-value/numeric-value/NumericValue';
import PercentsValue from '../../../../common/dashboard-value/percents-value/PercentsValue';

const SNumericCell = styled.div`
  text-align: right;
`;

export const UnderwriterNameCellRendererFramework: React.FC<{
  data: { underwriterName };
}> = ({ data }) => (
  <Link
    to={routeFactory.datalabLeagueTable.getUrlPath()}
    data-test-id={myDashboard.leagueTableUnderwriterNameLink.testId}
  >
    <span>{data.underwriterName}</span>
  </Link>
);

const columns: ColDef[] = [
  {
    headerName: '#',
    valueGetter: ({ node }) => String((node?.rowIndex ?? 0) + 1),
    suppressColumnsToolPanel: true,
    maxWidth: 60,
    suppressSizeToFit: false,
    sortable: false,
  },
  {
    colId: LeagueTableOrderField.UNDERWRITER_NAME,
    field: 'underwriterName',
    headerName: 'Underwriter',
    cellRendererFramework: UnderwriterNameCellRendererFramework,
    suppressColumnsToolPanel: true,
    resizable: true,
  },
  {
    colId: LeagueTableOrderField.NUMBER_OF_OFFERINGS,
    field: 'numberOfOfferings',
    headerName: '# Offerings',
    cellRendererFramework: ({ data }: { data: { numberOfOfferings } }) => (
      <SNumericCell>
        <NumericValue value={data.numberOfOfferings} />
      </SNumericCell>
    ),
    suppressColumnsToolPanel: true,
    resizable: true,
  },
  {
    colId: LeagueTableOrderField.TOTAL_FEES,
    field: 'totalFees',
    headerName: 'Total Fees',
    cellRendererFramework: ({ data }: { data: { totalFees } }) => (
      <SNumericCell>
        <CurrencyValue value={data.totalFees} />
      </SNumericCell>
    ),
    resizable: true,
  },
  {
    colId: LeagueTableOrderField.WALLET_SHARE,
    field: 'walletShare',
    headerName: 'Wallet Share',
    sort: 'desc',
    cellRendererFramework: ({ data }: { data: { walletShare } }) => (
      <SNumericCell>
        <PercentsValue value={data.walletShare} />
      </SNumericCell>
    ),
    resizable: true,
  },
];

export default columns;
