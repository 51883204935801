import { OfferingFilterInput } from '../../../../graphql/__generated__';
import { CalendarCategory } from '../../../../types/domain/calendar/constants';
import { FilterValues } from '../../model/calendar-filters';
import getCalendarCategoryGraphqlWhere from './calendar-category';
import getUserFiltersGraphqlWhere from './calendar-filters';

export function getGraphqlWhere({
  userId,
  userPermissions,
  calendarCategory,
  filters,
}: {
  userId: string;
  userPermissions: string[];
  calendarCategory: CalendarCategory;
  filters?: FilterValues;
}): OfferingFilterInput {
  const calendarTabWhereClause = getCalendarCategoryGraphqlWhere({
    userId,
    userPermissions,
    calendarCategory,
  });

  const andBlock: OfferingFilterInput[] = [calendarTabWhereClause];
  if (filters) {
    andBlock.push(getUserFiltersGraphqlWhere(filters));
  }

  return {
    attributes: {
      isSupersededBy: { eq: null },
    },
    and: andBlock,
  };
}
