import { getUserId } from '@cmg/auth';

export function getDatalabUserPreferences<T>(key: string): T | null {
  const userID = getUserId();

  if (!userID) {
    throw new Error('User ID is not defined');
  }

  const preferences = localStorage.getItem(`datalab_preferences_${userID}_${key}`);

  if (!preferences) {
    return null;
  }

  return JSON.parse(preferences);
}

export function setDatalabUserPreferences<T>(key: string, value: T): void {
  const userID = getUserId();

  if (!userID) {
    throw new Error('User ID is not defined');
  }

  localStorage.setItem(`datalab_preferences_${userID}_${key}`, JSON.stringify(value));
}
