import { LinkButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

import { SHeaderItem as HeaderItem } from '../virtualized-table/HeaderItem.styles';

export const SSummaryHeaderItem = styled(HeaderItem)`
  padding: 0 5px 0 5px !important;
  background-color: ${({ theme }) => theme.background.color.dark};
  text-transform: none !important;
  text-align: right !important;
  justify-content: flex-end;

  button {
    color: ${({ theme }) => theme.text.color.white};
    text-align: left;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.text.color.white};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.text.color.white};
  }

  &:active {
    color: ${({ theme }) => theme.text.color.link};
  }
`;

// tabIndex so it is focusable and able to be the buttonComponent for DropdownButton
export const SHeaderItem = styled.div.attrs({ tabIndex: 0 })<{ onClick?: () => void }>`
  display: flex;
  color: ${({ theme }) => theme.text.color.white};
  height: 30px;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  width: 100%;
  justify-content: flex-end;
`;

export const SDropdownIconWrapper = styled.div`
  align-self: center;
  margin-left: 5px;
`;

export const SListItem = styled.li<{ active: boolean }>`
  padding: 9px 8px;

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.background.color.dark};
    `};
`;

export const SDescription = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const SLabel = styled.span`
  padding-right: 5px;
`;

export const SValue = styled.span`
  padding-left: 5px;
`;

export const SSummaryMenuList = styled.ul`
  background: ${({ theme }) => theme.background.color.menu};
  font-size: ${({ theme }) => theme.text.size.small};
  display: block;
  min-width: 0;
  position: static;
  list-style: none;
  margin-top: 2px;
`;

export const SMenu = styled.div`
  position: absolute;
`;
