import { numericUtil } from '@cmg/common';
import { Typography } from '@cmg/design-system';
import { useMemo } from 'react';

import { TableRow } from '../../../../../common/components/table/types';
import { Atm_Selldown_StructureFieldsFragment } from '../../../../../common/graphql/__generated__/commonFragments';
import { AtmStructureType, Currency } from '../../../../../graphql/__generated__';
import {
  dateTableColumn,
  decimalLocalCurrencyTableColumn,
  millionLocalCurrencyTableColumn,
} from './utils';

const emptyValue = '-';

export enum TableKeys {
  type,
  announcementDate,
  asOfDate,
  sales,
  salesInSecurities,
  averagePrice,
  programSize,
  programSizeInSecurities,
  programRemaining,
  programRemainingInSecurities,
}

export type Props = {
  pricingCurrency?: Currency | null;
};

export type AtmSelldownTableModel = TableRow<Atm_Selldown_StructureFieldsFragment, Props>;

export const dtc: Record<TableKeys, AtmSelldownTableModel> = {
  [TableKeys.type]: {
    label: '',
    headerStyles: { align: 'left', width: '12.5%', minWidth: 115 },
    contentStyles: { align: 'left' },
    render: (structure: Atm_Selldown_StructureFieldsFragment) => structure.typeDisplayName,
  },
  [TableKeys.announcementDate]: {
    label: 'Announcement Date',
    headerStyles: { align: 'left', width: '12.5%', minWidth: 134 },
    contentStyles: { align: 'left' },
    render: (structure: Atm_Selldown_StructureFieldsFragment) =>
      dateTableColumn(structure.announcementDate),
  },
  [TableKeys.asOfDate]: {
    label: 'As of Date',
    headerStyles: { align: 'left', width: '12.5%', minWidth: 140 },
    contentStyles: { align: 'left' },
    render: (structure: Atm_Selldown_StructureFieldsFragment) =>
      dateTableColumn(structure.asOfDate),
  },
  [TableKeys.sales]: {
    label: 'ATM Sales',
    headerStyles: { width: '12.5%', minWidth: 134 },
    render: (structure: Atm_Selldown_StructureFieldsFragment, { pricingCurrency }) =>
      (structure.sales === null || structure.sales === undefined) &&
      structure.type === AtmStructureType.Selldown ? (
        <Typography sx={{ color: 'gray' }}>Not Disclosed</Typography>
      ) : (
        millionLocalCurrencyTableColumn(structure.sales, pricingCurrency)
      ),
  },
  [TableKeys.salesInSecurities]: {
    label: 'ATM Sales in Securities',
    headerStyles: { width: '12.5%', minWidth: 155 },
    render: (structure: Atm_Selldown_StructureFieldsFragment) =>
      (structure.salesInSecurities === null || structure.salesInSecurities === undefined) &&
      structure.type === AtmStructureType.Selldown ? (
        <Typography sx={{ color: 'gray' }}>Not Disclosed</Typography>
      ) : (
        numericUtil.formatInteger(structure.salesInSecurities)
      ),
  },
  [TableKeys.averagePrice]: {
    label: 'ATM Average Price',
    headerStyles: { width: '12.5%', rightBorder: true, minWidth: 128 },
    contentStyles: {
      rightBorder: true,
    },
    render: (structure: Atm_Selldown_StructureFieldsFragment, { pricingCurrency }) => {
      // for empty we only want to shot the emptyValue if its a selldown
      const empty = structure.type === AtmStructureType.Selldown ? emptyValue : '';
      return structure.averagePrice
        ? decimalLocalCurrencyTableColumn(structure.averagePrice, pricingCurrency)
        : empty;
    },
  },
  [TableKeys.programSize]: {
    label: 'ATM Program Size',
    headerStyles: { width: '12.5%', minWidth: 125 },
    render: (structure: Atm_Selldown_StructureFieldsFragment, { pricingCurrency }) =>
      millionLocalCurrencyTableColumn(structure.programSize, pricingCurrency),
  },
  [TableKeys.programSizeInSecurities]: {
    label: 'ATM Program Size in Securities',
    headerStyles: { width: '12.5%', minWidth: 202 },
    render: (structure: Atm_Selldown_StructureFieldsFragment) =>
      numericUtil.formatInteger(structure.programSizeInSecurities),
  },
  [TableKeys.programRemaining]: {
    label: 'ATM Program Remaining',
    headerStyles: { width: '12.5%', minWidth: 161 },
    render: (structure: Atm_Selldown_StructureFieldsFragment, { pricingCurrency }) =>
      millionLocalCurrencyTableColumn(structure.programRemaining, pricingCurrency),
  },
  [TableKeys.programRemainingInSecurities]: {
    label: 'ATM Program Remaining Securities',
    headerStyles: { width: '12.5%', minWidth: 238 },
    render: (structure: Atm_Selldown_StructureFieldsFragment) =>
      numericUtil.formatInteger(structure.programRemainingInSecurities),
  },
};

export const useGetAtmSellDownTableModel = ({
  isAtmProgramSize,
}: {
  isAtmProgramSize: boolean;
}): AtmSelldownTableModel[] => {
  return useMemo(() => {
    return [
      dtc[TableKeys.type],
      dtc[TableKeys.announcementDate],
      dtc[TableKeys.asOfDate],
      dtc[TableKeys.sales],
      dtc[TableKeys.salesInSecurities],
      dtc[TableKeys.averagePrice],
      isAtmProgramSize ? dtc[TableKeys.programSize] : dtc[TableKeys.programSizeInSecurities],
      isAtmProgramSize
        ? dtc[TableKeys.programRemaining]
        : dtc[TableKeys.programRemainingInSecurities],
    ];
  }, [isAtmProgramSize]);
};
