import { Box, numericUtil } from '@cmg/common';
import { widgetOfferingVolumeBySector } from '@cmg/e2e-selectors';
import React, { useMemo, useState } from 'react';

import {
  CustomLegend,
  DoughnutChart,
  DoughnutChartProps,
} from '../../../../../common/components/charts';
import { orderAscBySelector } from '../../../../../obsolete/utils/helpers';
import Toggler from '../../../../shared/layout/components/Toggler';
import { getSectorColor, noSectorAsLast } from '../../../colors';
import valueAmountButtons, {
  ValueAmountButtons,
} from '../../../model/buttons/value-amount-buttons';
import { SButtonBox, SChart, SDatalabChart, SDoughnut } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    color_code: string | null;
    values: {
      value: number;
      underwritten_value: number;
      amount: number;
    };
  }[];
  title: string;
};

const transformData = (
  chartData: Props['chartData'] = [],
  activeBtn: any
): DoughnutChartProps['data'] => {
  const field = activeBtn.value;

  const sorted = noSectorAsLast(chartData.sort(orderAscBySelector.bind(null, item => item.label)));

  return {
    labels: sorted.map(item => item.label),
    datasets: [
      {
        data: sorted.map(item => item.values[field]),
        backgroundColor: sorted.map(item => getSectorColor(item)),
      },
    ],
  };
};

const getOptions = (activeBtn: any): DoughnutChartProps['options'] => ({
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          let total = 0;
          tooltipItem.dataset.data.forEach((dataValue: number) => {
            total += dataValue;
          });
          const formattedValue =
            activeBtn.value === ValueAmountButtons.VALUE
              ? numericUtil.formatCurrencyInBillions(value)
              : value;
          return `${formattedValue} (${numericUtil.formatPercents(value / total, 1)})`;
        },
      },
    },
  },
});

const WidgetOfferingVolumeBySector: React.FC<Props> = ({ chartData = [], title }) => {
  const [activeBtn, setActiveBtn] = useState(valueAmountButtons[0]);

  const chartDataMemo = useMemo(() => transformData(chartData, activeBtn), [chartData, activeBtn]);

  const options = useMemo(() => getOptions(activeBtn), [activeBtn]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart data-test-id={widgetOfferingVolumeBySector.testId}>
      <Box.Header title={title} />

      <SButtonBox>
        <Toggler
          buttons={valueAmountButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SDoughnut>
          <SChart>
            <DoughnutChart data={chartDataMemo} options={options} />
          </SChart>

          <CustomLegend
            chartData={chartDataMemo}
            chartType="doughnut"
            valueFormatter={
              activeBtn.value === ValueAmountButtons.VALUE
                ? numericUtil.formatCurrencyInBillions
                : undefined
            }
          />
        </SDoughnut>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetOfferingVolumeBySector;
