import { ChartConfigurationCustomTypesPerDataset } from 'chart.js/auto';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { Chart } from 'react-chartjs-2';

export type MixedChartProps = ChartConfigurationCustomTypesPerDataset;

const defaultMixedChartOptions: MixedChartProps['options'] = {};

const getMixedChartOptions = (options: MixedChartProps['options']) => {
  return merge(cloneDeep(defaultMixedChartOptions), options);
};

export const MixedChart = ({ data, options }: MixedChartProps) => {
  const mergedOptions = getMixedChartOptions(options);

  // @ts-ignore - react-chartjs-2 types require a 'type' property, but mixed charts define their type in each dataset
  return <Chart data={data} options={mergedOptions} />;
};
