/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalLeagueTable_FieldsFragment = {
  __typename?: 'GlobalLeagueTableRow';
  managerId: string;
  managerName?: string | null;
  numberOfBook: number;
  numberOfNonBook: number;
  offeringCount: number;
  dollarVolumeUsd?: number | null;
};

export type GlobalLeagueTableQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<
    Array<Types.GlobalLeagueTableRowSortInput> | Types.GlobalLeagueTableRowSortInput
  >;
  where?: Types.InputMaybe<Types.OfferingFilterInput>;
}>;

export type GlobalLeagueTableQuery = {
  __typename?: 'Query';
  globalLeagueTable?: {
    __typename?: 'GlobalLeagueTableRowCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'GlobalLeagueTableRow';
      managerId: string;
      managerName?: string | null;
      numberOfBook: number;
      numberOfNonBook: number;
      offeringCount: number;
      dollarVolumeUsd?: number | null;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      offset: number;
      pageSize: number;
    };
  } | null;
};

export const GlobalLeagueTable_FieldsFragmentDoc = gql`
  fragment GlobalLeagueTable_Fields on GlobalLeagueTableRow {
    managerId
    managerName
    numberOfBook
    numberOfNonBook
    offeringCount
    dollarVolumeUsd
  }
`;
export const GlobalLeagueTableDocument = gql`
  query GlobalLeagueTable(
    $skip: Int
    $take: Int
    $order: [GlobalLeagueTableRowSortInput!]
    $where: OfferingFilterInput
  ) {
    globalLeagueTable(skip: $skip, take: $take, order: $order, where: $where) {
      items {
        ...GlobalLeagueTable_Fields
      }
      totalCount
      pageInfo {
        hasNextPage
        offset
        pageSize
      }
    }
  }
  ${GlobalLeagueTable_FieldsFragmentDoc}
`;

/**
 * __useGlobalLeagueTableQuery__
 *
 * To run a query within a React component, call `useGlobalLeagueTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalLeagueTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalLeagueTableQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGlobalLeagueTableQuery(
  baseOptions?: Apollo.QueryHookOptions<GlobalLeagueTableQuery, GlobalLeagueTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalLeagueTableQuery, GlobalLeagueTableQueryVariables>(
    GlobalLeagueTableDocument,
    options
  );
}
export function useGlobalLeagueTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GlobalLeagueTableQuery, GlobalLeagueTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalLeagueTableQuery, GlobalLeagueTableQueryVariables>(
    GlobalLeagueTableDocument,
    options
  );
}
export function useGlobalLeagueTableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GlobalLeagueTableQuery, GlobalLeagueTableQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GlobalLeagueTableQuery, GlobalLeagueTableQueryVariables>(
    GlobalLeagueTableDocument,
    options
  );
}
export type GlobalLeagueTableQueryHookResult = ReturnType<typeof useGlobalLeagueTableQuery>;
export type GlobalLeagueTableLazyQueryHookResult = ReturnType<typeof useGlobalLeagueTableLazyQuery>;
export type GlobalLeagueTableSuspenseQueryHookResult = ReturnType<
  typeof useGlobalLeagueTableSuspenseQuery
>;
export type GlobalLeagueTableQueryResult = Apollo.QueryResult<
  GlobalLeagueTableQuery,
  GlobalLeagueTableQueryVariables
>;
