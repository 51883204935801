import styled, { css } from 'styled-components/macro';

import { Size } from './DashboardValueBase';

export const SDashboardValueBase = styled.div`
  margin: 0 3px;
  display: inline-block;
  text-align: right;
`;

export const SLabel = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.text.size.small};
  margin-top: 4px;
  text-align: right;
  white-space: normal;
`;

export const SPerformance = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.small};
  margin-left: 4px;
  position: absolute;
  top: -8px;
  right: -53px;
`;

export const SValue = styled.div<{
  size: Size;
}>`
  position: relative;

  ${({ size, theme }) =>
    size === 'medium' &&
    css`
      font-size: ${theme.text.size.medium};
    `}
  ${({ size, theme }) =>
    size === 'mediumLarge' &&
    css`
      font-size: ${theme.text.size.mediumLarge};
    `}
  ${({ size, theme }) =>
    size === 'large' &&
    css`
      font-size: ${theme.text.size.large};
    `}
`;
