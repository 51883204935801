import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { Icon, Popover, PrimaryButton, SecondaryButton } from '@cmg/common';
import {
  deletePopoverButtonSelector,
  deletePopoverFormCancelButtonSelector,
  deletePopoverFormConfirmButtonSelector,
  favoriteButtonSelector,
  sharePopoverButtonSelector,
  sharePopoverFormCancelButtonSelector,
  sharePopoverFormConfirmButtonSelector,
} from '@cmg/e2e-selectors';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';
import { User } from '../../../../types/domain/user/user';
import {
  selectOrganizationUsers,
  selectSharedReportOrganizationUsers,
} from '../../../shared/ducks';
import { favoriteReportActions, shareReportActions } from '../../ducks';
import UserButtonList from '../user-button-list/UserButtonList';
import UserSelectField from '../user-select-field/UserSelectField';
import {
  SControlsWrapper,
  SCurrentShareUsersWrapper,
  SDeleteButtonPopoverConfirmationWrapper,
  SDeleteButtonPopoverContent,
  SLabel,
  SSpacer,
  StyledForm,
  StyledLinkButton,
} from './ReportControlsCell.styles';

export const formSchema = yup.object().shape({
  shareUserIds: yup.array().min(1, 'Required').required('Required'),
});

const mapStateToProps = state => ({
  organizationUsers: selectOrganizationUsers(state),
  sharedReportOrganizationUsers: selectSharedReportOrganizationUsers(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      shareReport: shareReportActions.request,
      favoriteReport: favoriteReportActions.request,
    },
    dispatch
  ),
});

type OwnProps = {
  userReportPartial: UserReportPartial;
  areControlsVisible: boolean;
  onHideControls: () => void;
  onDeleteReport: (reportId: string) => void;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

enum PopoverMenu {
  'SHARE',
  'DELETE',
}

export const UserReportControlsCell: React.FC<Props> = ({
  areControlsVisible,
  userReportPartial,
  organizationUsers,
  sharedReportOrganizationUsers,
  actions: { favoriteReport, shareReport },
  onDeleteReport,
  onHideControls,
}) => {
  const { userPermissions } = useAuth();
  const [visibleMenu, setVisibleMenu] = React.useState<PopoverMenu | null>(null);
  const canUserShareReports = checkPermissions(userPermissions, [
    permissionsByEntity.SharedReports.FULL,
  ]);
  // Use organizationUsers state object to gather user details.
  // It's possible that the report is shared with users who may no
  // longer exist in the sharedReportsOrganizationUsers state object
  // For example, if the current user shares a report with a user who does not
  // have custom sectors and then the current user enables custom sectors,
  // the person whom the report was previously shared with would not
  // exist in state.shared.organization.sharedReportUsers.
  const usersWhomReportIsSharedWith: User[] =
    userReportPartial.share.sharedWith.length > 0
      ? (userReportPartial.share.sharedWith
          .map((userId: string) => organizationUsers.find(user => user.id === userId))
          .filter(user => user !== undefined) as User[])
      : [];

  const excludedUserIds = userReportPartial.share.sharedWith;

  const usersWhoReportCanBeSharedWith = sharedReportOrganizationUsers.filter(
    member => !excludedUserIds.includes(member.id)
  );

  return (
    <td>
      <SControlsWrapper>
        <StyledLinkButton
          data-test-id={favoriteButtonSelector.testId}
          isVisible={areControlsVisible || userReportPartial.isFavorite}
          onClick={() =>
            favoriteReport({
              reportId: userReportPartial.id,
              isFavorite: !userReportPartial.isFavorite,
            })
          }
        >
          {userReportPartial.isFavorite ? (
            <Icon name="star" variant="solid" />
          ) : (
            <Icon name="star" variant="regular" />
          )}
        </StyledLinkButton>
        {canUserShareReports &&
          (userReportPartial.share.sharedWith.length > 0 ||
            userReportPartial.share.canShareWith.length > 0) && (
            <Popover
              content={
                <Formik<{ shareUserIds: string[] }>
                  enableReinitialize={false}
                  initialValues={{ shareUserIds: [] }}
                  onSubmit={() => {}}
                  isInitialValid={false}
                  validateOnChange={true}
                  validateOnBlur={true}
                  validationSchema={formSchema}
                  render={({ values, setFieldValue, isValid }) => (
                    <StyledForm>
                      {usersWhomReportIsSharedWith.length > 0 && (
                        <SCurrentShareUsersWrapper>
                          <SLabel>Currently Shared With</SLabel>
                          {usersWhomReportIsSharedWith.map(user => user.username).join(', ')}
                        </SCurrentShareUsersWrapper>
                      )}
                      <UserSelectField
                        name="shareUserIds"
                        label="Share report with"
                        users={usersWhoReportCanBeSharedWith}
                      />
                      <UserButtonList
                        label="Share report with"
                        users={usersWhoReportCanBeSharedWith
                          .filter(
                            user => !values.shareUserIds || !values.shareUserIds.includes(user.id)
                          )
                          .slice(0, 5)}
                        onClickUserButton={(person: User) => {
                          setFieldValue('shareUserIds', [
                            ...(values.shareUserIds ? values.shareUserIds : []),
                            person.id,
                          ]);
                        }}
                      />
                      <SDeleteButtonPopoverConfirmationWrapper>
                        <SecondaryButton
                          data-test-id={sharePopoverFormCancelButtonSelector.testId}
                          onClick={onHideControls}
                        >
                          Cancel
                        </SecondaryButton>
                        <SSpacer />
                        <PrimaryButton
                          data-test-id={sharePopoverFormConfirmButtonSelector.testId}
                          disabled={!isValid}
                          onClick={() => {
                            shareReport({
                              reportId: userReportPartial.id,
                              shareUserIds: values.shareUserIds,
                            });
                            onHideControls();
                          }}
                        >
                          Share
                        </PrimaryButton>
                      </SDeleteButtonPopoverConfirmationWrapper>
                    </StyledForm>
                  )}
                />
              }
              visible={visibleMenu === PopoverMenu.SHARE}
              placement="left"
            >
              <StyledLinkButton
                data-test-id={sharePopoverButtonSelector.testId}
                isVisible={areControlsVisible || userReportPartial.share.isShared}
                onClick={() => setVisibleMenu(PopoverMenu.SHARE)}
              >
                <Icon name="share-alt" variant="solid" />
              </StyledLinkButton>
            </Popover>
          )}
        <Popover
          content={
            <SDeleteButtonPopoverContent>
              {userReportPartial.share.isShared && (
                <p>
                  This action will only delete the report for you.
                  <br />
                  Other people will still be able to see the report.
                </p>
              )}
              <p>Are you sure you want to delete the report?</p>
              <SDeleteButtonPopoverConfirmationWrapper>
                <SecondaryButton
                  testId={deletePopoverFormCancelButtonSelector.testId}
                  onClick={onHideControls}
                >
                  No
                </SecondaryButton>
                <SSpacer />
                <PrimaryButton
                  testId={deletePopoverFormConfirmButtonSelector.testId}
                  onClick={() => onDeleteReport(userReportPartial.id)}
                >
                  Yes
                </PrimaryButton>
              </SDeleteButtonPopoverConfirmationWrapper>
            </SDeleteButtonPopoverContent>
          }
          visible={visibleMenu === PopoverMenu.DELETE}
          placement="topRight"
        >
          <StyledLinkButton
            testId={deletePopoverButtonSelector.testId}
            isVisible={areControlsVisible}
            onClick={() => setVisibleMenu(PopoverMenu.DELETE)}
          >
            <Icon name="trash" />
          </StyledLinkButton>
        </Popover>
      </SControlsWrapper>
    </td>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserReportControlsCell);
