import styled from 'styled-components/macro';

export const SIoiTrackerScreen = styled.div`
  .align-right {
    text-align: right;
  }

  .fake-label {
    font-size: 10px;
    font-weight: ${({ theme }) => theme.text.weight.medium};

    height: 18px;
  }

  .inline-button {
    margin-left: 10px;
  }

  .space-right {
    margin-right: 7px;
  }

  .no-border {
    border: none;
  }

  .value-container {
    width: 100%;
    height: 34px;
    border: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
    border-radius: 5px;
    padding: 6px 12px;

    .value-input {
      white-space: nowrap;
      overflow-x: hidden;
    }

    &.disabled {
      background-color: ${({ theme }) => theme.background.color.light};

      .value-input {
        color: ${({ theme }) => theme.text.color.light};
      }
    }

    &.simple {
      height: auto;
      border: 0;
      padding: 0;
    }
  }

  .fund-wrapper {
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.border.color.dark};
    margin-bottom: 10px;

    &.edit {
      padding: 5px;
    }

    .table-container {
      margin-bottom: 0;
    }
  }

  .table-container {
    border-bottom: 0;
    margin-bottom: 20px;

    tr {
      display: flex;

      th,
      td {
        display: flex;
        flex: 1 1 25%;
      }
    }

    &.editing {
      tr {
        td {
          padding: 4px;
        }
      }

      .select-wrapper {
        margin-bottom: 0;
      }

      .action-column {
        display: flex;

        .select-wrapper {
          flex: 1;
        }
      }

      .inputs-group {
        .select-wrapper {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .top-gap {
    margin-top: 14px;
    display: flex;
  }
`;
