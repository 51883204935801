import {
  chartViewOnlyDatalabSections,
  DatalabScreens,
  DatalabScreenViewTypes,
  tableViewOnlyDatalabSections,
} from '../constants';
import { getTableConfig } from './table.model';

export function isInTable(section: DatalabScreens, sectionType: DatalabScreenViewTypes) {
  return (
    sectionType === DatalabScreenViewTypes.TABLE || tableViewOnlyDatalabSections.includes(section)
  );
}

export function getSummaryColumns(screen: DatalabScreens, screenType: DatalabScreenViewTypes) {
  if (
    DatalabScreens.CAPITAL_RAISED_MARKET ||
    screenType === DatalabScreenViewTypes.TABLE ||
    tableViewOnlyDatalabSections.includes(screen)
  ) {
    return getTableConfig(screen).summaryColumns;
  }
  return undefined;
}

export function hasToggler(datalabScreen: DatalabScreens) {
  return !(
    tableViewOnlyDatalabSections.includes(datalabScreen) ||
    chartViewOnlyDatalabSections.includes(datalabScreen)
  );
}
