import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SUnderwriterLabel = styled.div`
  /** our custom firm search option renderer will truncate to fit the width */
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledInfoBanner = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
  padding: 8px;
`;

export const StyledInfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.brand.color.warning};
  margin-right: 5px;
`;
