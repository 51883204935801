import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NotificationList from './components/NotificationList';
import {
  fetchNotifications,
  filterUnreadOnly,
  hideNotifications,
  markAllAsRead,
  markAsRead,
  selectAvailableNotificationFilters,
  selectFilters,
  selectFilterUnreadOnly,
  selectMarkAllAsReadLoading,
  selectNotificationFilters,
  selectNotifications,
  selectNotificationsError,
  selectNotificationsLoading,
  selectNotificationsPagination,
  selectShowSlideout,
  selectTotalUnreadCount,
  startNotificationsPolling,
  stopNotificationsPolling,
} from './ducks';
import { SSlideout } from './Notifications.styles';

const mapStateToProps = state => ({
  show: selectShowSlideout(state),
  notifications: selectNotifications(state),
  loading: selectNotificationsLoading(state),
  markAllAsReadLoading: selectMarkAllAsReadLoading(state),
  error: selectNotificationsError(state),
  pagination: selectNotificationsPagination(state),
  unreadOnly: selectFilterUnreadOnly(state),
  filters: selectNotificationFilters(state),
  availableFilters: selectAvailableNotificationFilters(state),
  totalUnreadCount: selectTotalUnreadCount(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      startNotificationsPolling,
      stopNotificationsPolling,
      fetchNotifications,
      hideNotifications,
      filterUnreadOnly,
      selectFilters,
      markAsRead,
      markAllAsRead,
    },
    dispatch
  ),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const Notifications: React.FC<Props> = ({
  show,
  notifications,
  loading,
  markAllAsReadLoading,
  error,
  pagination,
  actions,
  unreadOnly,
  filters,
  availableFilters,
  totalUnreadCount,
}) => {
  useEffect(() => {
    actions.startNotificationsPolling();
    return () => {
      actions.stopNotificationsPolling();
    };
  }, [actions]);

  // fetch notifications when show=true, reset when not true
  useEffect(() => {
    if (show) {
      actions.fetchNotifications({});
    }
  }, [actions, show]);

  return (
    <SSlideout show={show}>
      <NotificationList
        show={show}
        notifications={notifications}
        loading={loading}
        markAllAsReadLoading={markAllAsReadLoading}
        error={error}
        pagination={pagination}
        unreadOnly={unreadOnly}
        filters={filters}
        totalUnreadCount={totalUnreadCount}
        availableFilters={availableFilters}
        onFilterUnreadOnly={actions.filterUnreadOnly}
        onFilterChange={actions.selectFilters}
        onFetchNotifications={actions.fetchNotifications}
        onHideNotifications={actions.hideNotifications}
        onMarkAsRead={actions.markAsRead}
        onMarkAllAsRead={() => actions.markAllAsRead({})}
      />
    </SSlideout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
