import isNil from 'lodash/isNil';

import {
  Country,
  CustomSectorIssuerFilterInput,
  IssuerFilterInput,
  OfferingAttributesFilterInput,
  OfferingFilterInput,
  OfferingType,
  Sector,
  SubSector,
} from '../../../../graphql/__generated__';
import { isInternationalOfferingsOn } from '../../../datalab/model/utils';
import {
  SECTOR_OPTION_VALUE_PREFIX,
  SUB_SECTOR_OPTION_VALUE_PREFIX,
} from '../../../dlgw/components/offerings-filter-form/OfferingsFilterForm.model';
import {
  CalendarOfferingType,
  FilterValues,
  InternalOfferingType,
  RangeFilter,
} from '../../model/calendar-filters';

export default function getUserFiltersGraphqlWhere(filters: FilterValues): OfferingFilterInput {
  const graphqlWhereClauses = createUserFiltersGraphqlWhereClauses(filters);

  if (graphqlWhereClauses.length === 1) {
    return graphqlWhereClauses[0];
  }

  return { and: graphqlWhereClauses };
}

function createUserFiltersGraphqlWhereClauses(filters: FilterValues): OfferingFilterInput[] {
  return [
    getOfferingTypesFilterWhere(filters.offeringType),
    getManagersFilterWhere(filters.managerFirmId),
    getCountriesFilterWhere(filters.countries),
    getLeftLeadFilterWhere(filters.leftLeadFirmId),
    getFieldInRangeFilterWhere('latestGrossProceedsTotalUsd', filters.sizeInDollars),
    getFieldInRangeFilterWhere('marketCapAtPricingUsd', filters.marketCap),
    filters.useCustomSectors
      ? getCustomSectorsFilterWhere(filters.customSectorId)
      : getSectorsFilterWhere(filters.sector),
  ].filter((where): where is OfferingFilterInput => !!where);
}

export const getOfferingTypesFilterWhere = (
  offeringTypeSelections: CalendarOfferingType[]
): OfferingFilterInput => {
  const includeSpacs = offeringTypeSelections.includes(InternalOfferingType.IPO_SPACS);
  const includeIpos = offeringTypeSelections.includes(OfferingType.Ipo);
  const nonIpoTypeSelections = offeringTypeSelections.filter(
    (selection): selection is OfferingType =>
      selection !== InternalOfferingType.IPO_SPACS && selection !== OfferingType.Ipo
  );

  if (includeIpos && includeSpacs) {
    // include all IPOs (both spacs and not spacs)
    return {
      type: { in: [OfferingType.Ipo, ...nonIpoTypeSelections] },
    };
  } else if (includeIpos && !includeSpacs) {
    // Ipos and Spacs both have offering type IPO
    return {
      or: [
        {
          type: { in: [...nonIpoTypeSelections] },
        },
        {
          type: { eq: OfferingType.Ipo },
          attributes: { isSpac: { eq: false } },
        },
      ],
    };
  } else if (!includeIpos && includeSpacs) {
    // Ipos and Spacs both have offering type IPO
    return {
      or: [
        {
          type: { in: [...nonIpoTypeSelections] },
        },
        {
          type: { eq: OfferingType.Ipo },
          attributes: { isSpac: { eq: true } },
        },
      ],
    };
  } else {
    // user unselected IPOs and Spacs in the type filter
    return {
      type: { in: [...nonIpoTypeSelections] },
    };
  }
};

export const getManagersFilterWhere = (
  managerFirmIds: string[]
): OfferingFilterInput | undefined => {
  if (managerFirmIds.length === 0) {
    return undefined;
  }

  /*
   * Psuedo-code to help understand what ths syntax means:
   *
   * managers.Any(manager => managerFirmIds.Contains(manager.Id))
   */
  return {
    managers: {
      some: {
        manager: {
          id: {
            in: managerFirmIds,
          },
        },
      },
    },
  };
};

export const getCountriesFilterWhere = (countries: string[]): OfferingFilterInput | undefined => {
  if (countries.length === 0 || !isInternationalOfferingsOn()) {
    return undefined;
  }

  return {
    exchangeCountry: { in: countries as Country[] },
  };
};

export const getLeftLeadFilterWhere = (leftLeadIds: string[]): OfferingFilterInput | undefined => {
  if (leftLeadIds.length === 0) {
    return undefined;
  }

  return {
    attributes: {
      leftLeadId: { in: leftLeadIds },
    },
  };
};

export const getFieldInRangeFilterWhere = (
  attributesFieldName: keyof OfferingAttributesFilterInput,
  range: RangeFilter
): OfferingFilterInput | undefined => {
  if (!range) {
    return undefined;
  }

  if (!hasValue(range.min) && !hasValue(range.max)) {
    return undefined;
  }

  const andBlock: OfferingAttributesFilterInput[] = [];
  const where: OfferingFilterInput = {
    attributes: {
      or: [
        {
          [attributesFieldName]: { eq: null },
        },
        {
          and: andBlock,
        },
      ],
    },
  };

  if (hasValue(range.min)) {
    andBlock.push({
      [attributesFieldName]: { gte: range.min! * 1_000_000 },
    });
  }

  if (hasValue(range.max)) {
    andBlock.push({
      [attributesFieldName]: { lte: range.max! * 1_000_000 },
    });
  }

  return where;
};

const hasValue = (val?: number): boolean => !isNil(val) && !isNaN(val);

export const getSectorsFilterWhere = (sectorsField: string[]): OfferingFilterInput | undefined => {
  if (sectorsField.length === 0) {
    return undefined;
  }

  const selectedSectors = filterAndParseSectorSelections(SECTOR_OPTION_VALUE_PREFIX, sectorsField);
  const selectedSubSectors = filterAndParseSectorSelections(
    SUB_SECTOR_OPTION_VALUE_PREFIX,
    sectorsField
  );

  const orBlock: IssuerFilterInput[] = [];
  const filterWhere = {
    issuer: {
      or: orBlock,
    },
  };

  if (selectedSectors.length > 0) {
    orBlock.push({
      sector: { in: selectedSectors as Sector[] },
    });
  }

  if (selectedSubSectors.length > 0) {
    orBlock.push({
      subSector: { in: selectedSubSectors as SubSector[] },
    });
  }

  return filterWhere;
};

export const getCustomSectorsFilterWhere = (
  customSectorsField: string[]
): OfferingFilterInput | undefined => {
  if (customSectorsField.length === 0) {
    return undefined;
  }

  const selectedSectors = filterAndParseSectorSelections(
    SECTOR_OPTION_VALUE_PREFIX,
    customSectorsField
  );
  const selectedSubSectors = filterAndParseSectorSelections(
    SUB_SECTOR_OPTION_VALUE_PREFIX,
    customSectorsField
  );

  const orBlock: CustomSectorIssuerFilterInput[] = [];
  const filterWhere = {
    issuer: {
      customSectors: { or: orBlock },
    },
  };

  if (selectedSectors.length > 0) {
    orBlock.push({
      customSectorId: { in: selectedSectors },
    });
  }

  if (selectedSubSectors.length > 0) {
    orBlock.push({
      customSubsectorId: { in: selectedSubSectors },
    });
  }

  return filterWhere;
};

const filterAndParseSectorSelections = (
  sectorType: 'SECTOR' | 'SUB_SECTOR',
  selections: string[]
): string[] => selections.filter(s => s.startsWith(sectorType)).map(s => s.split(':')[1]);
