import { AccountType, getUserAccountType } from '@cmg/auth';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ScreenContent from '../shared/layout/ScreenContent';
import ScreenHeader from '../shared/layout/ScreenHeader';
import BuySideDashboard from './dashboard/buy-side/Dashboard';
import SellSideDashboard from './dashboard/sell-side/Dashboard';
import { SDashboardLayout, SLeftContent, SRightContent } from './DashboardRoute.styles';
import RecentDeals from './recent-deals/RecentDeals';

type Props = RouteComponentProps;

/**
 * DashboardRoute dashboard route responsible
 * for displaying summary comparison of data and recently
 * priced deals within a certain time frame
 */
const DashboardRoute: React.FC<Props> = () => {
  const accountType = getUserAccountType();

  return (
    <React.Fragment>
      <ScreenHeader />
      <ScreenContent>
        <SDashboardLayout data-test-id={myDashboard.myDashboardScreen.testId}>
          <SLeftContent>
            {accountType === AccountType.BUY_SIDE && <BuySideDashboard />}
            {accountType === AccountType.SELL_SIDE && <SellSideDashboard />}
          </SLeftContent>
          <SRightContent>
            <RecentDeals />
          </SRightContent>
        </SDashboardLayout>
      </ScreenContent>
    </React.Fragment>
  );
};

export default DashboardRoute;
