import { numericUtil, Popover } from '@cmg/common';
import React from 'react';

import { IoiType } from '../../../../../../graphql/__generated__/index';
import PopoverContentTable from '../../../../../shared/components/PopoverContentTable';
import {
  CompanyProfile_FundAllocation_FieldsFragment,
  CompanyProfile_LastAllocation_FieldsFragment,
} from '../../../../graphql/__generated__/CompanyProfile';
import { SRightAlign } from './FundAllocationsCell.styled';

export type Props = {
  lastAllocation: CompanyProfile_LastAllocation_FieldsFragment;
  fundAllocations: readonly CompanyProfile_FundAllocation_FieldsFragment[];
};

/**
 * A single table cell that shows the Ioi info for a particular offering.
 * If the account hasn't passed or isn't reg-m conflict then it will display the allocation in shares.
 */
const FundAllocationsCell: React.FC<Props> = ({ lastAllocation, fundAllocations }) => {
  if (lastAllocation?.ioiType === IoiType.RegM) {
    return <SRightAlign>Reg-M</SRightAlign>;
  }

  if (lastAllocation?.ioiType === IoiType.Pass) {
    return <SRightAlign>Pass</SRightAlign>;
  }

  if (!fundAllocations || fundAllocations.length <= 0) {
    return (
      <SRightAlign capitalize>{numericUtil.formatInteger(lastAllocation?.shares)}</SRightAlign>
    );
  }

  // slice used since query results are read only, use toSorted in the future (node v20)
  const mappedFundAllocations = fundAllocations
    .slice()
    .sort((a, b) => (a.shares ?? 0) - (b.shares ?? 0))
    .map(f => (
      <tr key={f.id}>
        <td>{f.fund?.name}</td>
        <td>{numericUtil.formatInteger(f.shares)}</td>
        <td>{numericUtil.formatPercents(f.percentOfFirmAllocation)}</td>
      </tr>
    ));

  return (
    <SRightAlign capitalize>
      <Popover
        trigger="hover"
        variant="TOOLTIP"
        content={
          <PopoverContentTable alignAll="right" alignFirstColumn="left">
            <thead>
              <tr>
                <th>Participating Fund</th>
                <th>Shares</th>
                <th>% Of Firm Allocation</th>
              </tr>
            </thead>
            <tbody>{mappedFundAllocations}</tbody>
          </PopoverContentTable>
        }
        placement="left"
      >
        <span>{numericUtil.formatInteger(lastAllocation?.shares)}</span>
      </Popover>
    </SRightAlign>
  );
};

export default FundAllocationsCell;
