import styled from 'styled-components/macro';

export const SLeftleadName = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: underline;
  text-decoration-style: dotted;
`;
