import React from 'react';

import { SWrapper } from './ScreenContent.styles';

export type Props = {
  children: React.ReactNode;
  idName?: string;
  renderSubHeader?: () => React.ReactNode;
};

export default class ScreenContent extends React.Component<Props> {
  render() {
    const { children, idName, renderSubHeader } = this.props;
    return (
      <SWrapper hasSubHeader={!!renderSubHeader} id={idName}>
        {renderSubHeader && renderSubHeader()}
        {children}
      </SWrapper>
    );
  }
}
