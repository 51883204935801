import { numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { DatalabScreens } from '../../../datalab/constants';
import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { managerSortFn } from '../../../shared/model/utils';
import { Offering } from '../offerings-report-table/types';
import { SLeftleadName } from './LeftleadRenderer.styles';

export type Props = Readonly<{
  data?: Offering;
  hasLink?: boolean;
}>;

const LeftLeadRenderer: React.FC<Props> = ({ data, hasLink = false }) => {
  const leftLeadName = data?.attributes?.leftLeadName ?? '';

  const hasEconomicsPercent = React.useMemo(() => {
    return data?.managers?.some(m => !!m.pctUnderwritingBaseShares);
  }, [data?.managers]);

  const url = React.useMemo(() => {
    const leftLeadId = data?.attributes?.leftLeadId;
    return leftLeadId && hasLink
      ? routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.UNDERWRITER_OFFERINGS,
          type: 'table',
          query: {
            underwriter: data?.attributes?.leftLeadId,
          },
        })
      : null;
  }, [data?.attributes?.leftLeadId, hasLink]);

  return (
    <Popover
      variant="TOOLTIP"
      placement="left"
      content={
        <PopoverContentTable>
          <thead>
            <tr>
              <th>Underwriters</th>
              <th>Role</th>
              {hasEconomicsPercent && <th>Economics</th>}
            </tr>
          </thead>
          <tbody>
            {data?.managers
              ?.slice()
              .sort(managerSortFn)
              .map((m, idx) => (
                <tr key={idx}>
                  <td>{m.name}</td>
                  <td>{m.roleDisplayName}</td>
                  {hasEconomicsPercent && (
                    <td>{numericUtil.formatPercents(m.pctUnderwritingBaseShares, 1)}</td>
                  )}
                </tr>
              ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      {!!url ? <Link to={url}>{leftLeadName}</Link> : <SLeftleadName>{leftLeadName}</SLeftleadName>}
    </Popover>
  );
};

export default LeftLeadRenderer;
