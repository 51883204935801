import styled, { css } from 'styled-components/macro';

export const SDataTable = styled.div<{ fillViewport?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  &&& th {
    vertical-align: middle;
  }

  ${({ fillViewport }) =>
    fillViewport &&
    css`
      .box-header,
      .box-footer {
        min-height: 41px;
      }
    `}

  .box-footer {
    margin: 0;
  }

  .input-wrapper {
    flex-direction: column;

    /* Ant design internal className */
    .form-control-validation {
      position: initial;
    }
  }

  [class^='box-'],
  [class*=' box-'] {
    background-color: ${({ theme }) => theme.background.color.white};
  }
`;
