import { LinkButton } from '@cmg/common';
import { addFilterSelector } from '@cmg/e2e-selectors';
import React from 'react';

type Props = {
  onClick: (...args: any[]) => any | Function; // todo convert buttons to typescript
};

export default class AddNewFilterButton extends React.Component<Props> {
  render() {
    return (
      <div className="advanced-filter-add-filter">
        <LinkButton
          onClick={this.props.onClick}
          testId={addFilterSelector.testId}
          iconLeft={{ name: 'plus' }}
        >
          New Filter
        </LinkButton>
      </div>
    );
  }
}
