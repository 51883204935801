import { Icon } from '@cmg/common';
import { showFiltersButtonSelector, showFiltersCounterSelector } from '@cmg/e2e-selectors';
import React from 'react';

import {
  SDropdownFilterCountBadge,
  SDropdownIconWrapper,
  SDropdownText,
  SFilterButtonWrapper,
  StyledFilterPanelToggleButton,
} from './FilterPanelToggleButton.styles';

export type Props = {
  isFilterPanelExpanded: boolean;
  filterCount: number;
  onClick: () => void;
};

const FilterPanelToggleButton: React.FC<Props> = ({
  isFilterPanelExpanded,
  filterCount,
  onClick,
}) => (
  <StyledFilterPanelToggleButton
    type="button"
    isFilterPanelExpanded={isFilterPanelExpanded}
    onClick={onClick}
    testId={showFiltersButtonSelector.testId}
  >
    <SFilterButtonWrapper hasFilters={filterCount > 0}>
      <SDropdownText>{isFilterPanelExpanded ? 'Hide Filters' : 'Show Filters'}</SDropdownText>
      {filterCount > 0 && (
        <SDropdownFilterCountBadge data-test-id={showFiltersCounterSelector.testId}>
          {filterCount}
        </SDropdownFilterCountBadge>
      )}
      <SDropdownIconWrapper>
        <Icon size="lg" name="angle-down" />
      </SDropdownIconWrapper>
    </SFilterButtonWrapper>
  </StyledFilterPanelToggleButton>
);

export default FilterPanelToggleButton;
