import { UnpackPromise } from '@cmg/common';

import {
  makeParameterlessFetchEntityRequest,
  makeParameterlessUpdateEntityRequest,
} from '../../utils/request-factory';
import { dlgwApiClient } from '../dlgwApiClient';

export type UserSettings = {
  useCustomSectors: boolean;
};

export type UserSettingsPayload = { settings: UserSettings };

export const fetchUserSettings = makeParameterlessFetchEntityRequest<UserSettingsPayload>(
  dlgwApiClient,
  '/myUserSettings'
);
export type FetchUserSettingsResponse = UnpackPromise<ReturnType<typeof fetchUserSettings>>;

export const updateUserSettings = makeParameterlessUpdateEntityRequest<
  UserSettingsPayload,
  UserSettingsPayload
>(dlgwApiClient, '/myUserSettings');
export type UpdateUserSettingsResponse = UnpackPromise<ReturnType<typeof updateUserSettings>>;
