import { DatalabTableColumn, dtc, masterColumns } from '../datalabTableColumns';
import { categorizedColumns, groupedColumns } from './master-columns';

const getLockupReportColumns = () => {
  const excludedCols: DatalabTableColumn[] = [
    dtc.lock_up_expiration,
    dtc.lock_up_period,
    dtc.conditional_lock_up,
    dtc.multiple_lock_ups,
    dtc.lock_up_early_release_date,
  ];

  return [...excludedCols, ...masterColumns.filter(column => excludedCols.indexOf(column) === -1)];
};

export const columns: DatalabTableColumn[] = getLockupReportColumns();

export const visibleFields: string[] = [
  dtc.lock_up_expiration.field,
  dtc.lock_up_period.field,
  dtc.conditional_lock_up.field,
  dtc.multiple_lock_ups.field,
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.offerings_type.field,
  dtc.offering_size.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.filing_details_deal_pct_at_pricing_market_cap.field,
  dtc.filing_details_pct_secondary_shares.field,
  dtc.offering_offer_to_open.field,
  dtc.offering_offer_to_1day.field,
  dtc.offering_offer_to_30day.field,
  dtc.offering_offer_to_90day.field,
  dtc.offering_offer_to_current.field,
  dtc.primary_shareholder_name.field,
];

const visibleLocalCurrFields = [
  dtc.exchange_mic.field,
  dtc.pricing_instrument_region.field,
  dtc.country_code.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export { categorizedColumns, groupedColumns, visibleLocalCurrFields };
