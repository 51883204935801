import { numericUtil } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import ValueWithMultilineTooltip from '../../../../../offering-dl/shared/components/value-with-multiline-tooltip/ValueWithMultilineTooltip';
import CurrencyValue from '../../../../common/dashboard-value/currency-value/CurrencyValue';
import PerformancePercentsValue from '../../../../common/dashboard-value/performance-percents-value/PerformancePercentsValue';
import HorizontalLine from '../../../../common/horizontal-line/HorizontalLine';
import { MyDashboard_OfferingFieldsPartsFragment } from '../../../../graphql/__generated__/MyDashboard_PricedCalendar';
import {
  SDealDetail,
  SIssuerName,
  SIssuerRow,
  SLeftLead,
  SListItem,
  SOfferingType,
  SParticipationTagWrapper,
  SRecentDeal,
  SRow,
  STicker,
  StyledParticipationTag,
} from './RecentDealItem.styles';

export type Props = {
  recentDeal: MyDashboard_OfferingFieldsPartsFragment;
};

const RecentDealItem: React.FC<Props> = ({ recentDeal }) => {
  return (
    <SListItem>
      <SRecentDeal
        data-test-id={myDashboard.recentDealsItem.testId}
        data-testid={myDashboard.recentDealsItem.testId}
      >
        <SIssuerRow>
          <Link to={routeFactory.offerings.getUrlPath({ id: recentDeal.id })}>
            <STicker>{recentDeal.issuer?.primarySymbol}</STicker>
          </Link>
          <SIssuerName title={recentDeal.issuer?.name || ''}>
            <Link to={routeFactory.offerings.getUrlPath({ id: recentDeal.id })}>
              {recentDeal.issuer?.name}
            </Link>
          </SIssuerName>
          <SParticipationTagWrapper>
            <StyledParticipationTag
              ioiType={recentDeal.lastAllocation?.ioiType}
              allocation={recentDeal.firmMetric?.allocation ?? null}
            />
          </SParticipationTagWrapper>
        </SIssuerRow>
        <SRow>
          <SDealDetail>{recentDeal.issuer?.sectorDisplayName ?? '-'}</SDealDetail>
          <SOfferingType>{recentDeal.attributes?.typeDisplayName}</SOfferingType>
          <CurrencyValue value={recentDeal.attributes?.sizeUsd} size="mediumLarge" />
        </SRow>
        <SRow>
          <SLeftLead>{recentDeal.attributes?.leftLeadName}</SLeftLead>
          <ValueWithMultilineTooltip
            value={<PerformancePercentsValue value={recentDeal.attributes?.pctOfferToCurrent} />}
            valuesTooltip={[
              numericUtil.formatCurrency(recentDeal.attributes?.sizeUsd),
              numericUtil.formatCurrency(recentDeal.attributes?.currentSharePrice),
            ]}
            labelsTooltip={['Offer Price ($)', 'Current Price ($)']}
            label=""
          />
        </SRow>
      </SRecentDeal>
      <HorizontalLine />
    </SListItem>
  );
};

export default RecentDealItem;
