import { AgGridApi, apiTypes, DataGrid, reduxUtil } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { LeagueTable as LeagueTableType } from '../../../../../../types/domain/my-dashboard/leagueTable';
import SidebarContext from '../../../../../core/components/sidebar/SidebarContext';
import { SectionHeader } from '../../../common/DashboardPeriod';
import { leagueTableUpdated, selectLeagueTableLoading, selectLeagueTableParams } from '../../ducks';
import { UnderwriterControl } from './components/underwriter-control/UnderwriterControl';
import columns from './LeagueTableGridColumns';

export const SHeaderRow = styled.div<{ isCurrentPeriod: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
  /** comparison period should align with the current period when the underwriter control
  is displayed stacked under the section header */
  margin-bottom: ${({ isCurrentPeriod }) => (!isCurrentPeriod ? '55px' : '10px')};

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    flex-direction: row;
    margin-top: 0;
    /** comparison period should align with the current period when the underwriter control
    is displayed inline with the section header */
    margin-bottom: ${({ isCurrentPeriod }) => (!isCurrentPeriod ? '30px' : '10px')};
  }
`;
export const StyledUnderwriterControl = styled(UnderwriterControl)`
  margin: 0 10px 10px 0;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    width: 300px;
  }
`;

export type Props = {
  data?: LeagueTableType[];
  isCurrentPeriod?: boolean;
};

const LeagueTable: React.FC<Props> = ({ data, isCurrentPeriod = false }) => {
  const leagueTableParams = useSelector(selectLeagueTableParams);
  const leagueTableLoading = useSelector(selectLeagueTableLoading);
  const { collapsed: sidebarCollapsed } = React.useContext(SidebarContext);
  const [gridApi, setGridApi] = React.useState<AgGridApi | null>(null);

  React.useEffect(() => {
    if (gridApi) {
      // when both tables for each column call sizeColumnToFit the second call
      // gets ignored unless we wait a bit
      setTimeout(() => gridApi.sizeColumnsToFit(), 100);
    }
  }, [sidebarCollapsed, gridApi]);
  React.useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells();
    }
  }, [data, gridApi]);

  const { onLeagueTableChange } = reduxUtil.useActions({
    onLeagueTableChange: leagueTableUpdated,
  });

  return (
    <div data-test-id={myDashboard.leagueTableSection.testId}>
      <SHeaderRow isCurrentPeriod={isCurrentPeriod}>
        <SectionHeader>League Table</SectionHeader>
        {isCurrentPeriod && (
          <StyledUnderwriterControl
            value={leagueTableParams.underwritersIds}
            onChange={value =>
              value &&
              onLeagueTableChange({
                ...leagueTableParams,
                underwritersIds: value,
              })
            }
          />
        )}
      </SHeaderRow>
      {data && (
        <DataGrid<LeagueTableType>
          onGridReady={params => {
            setGridApi(params.api);
          }}
          extended={{
            withMargin: false,
            hidePagination: true,
            hideColumnResize: true,
            hideColumnSelector: true,
          }}
          columns={columns}
          rows={data}
          resizeBy="grid"
          gridOptions={{
            suppressCellSelection: true,
            suppressRowClickSelection: true,
            overlayNoRowsTemplate: 'No Underwriters Found.',
            suppressBrowserResizeObserver: true,
          }}
          getRowNodeId={row => row.underwriterName}
          totalPages={1}
          pagination={{
            page: 1,
            perPage: 10,
          }}
          loading={!!leagueTableLoading}
          onPaginationChange={({ orderField, orderDirection }) => {
            const params = {
              orderField,
              sortOrderDirection:
                orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
            };
            onLeagueTableChange({
              ...params,
              underwritersIds: leagueTableParams.underwritersIds,
            });
          }}
        />
      )}
    </div>
  );
};

export default LeagueTable;
