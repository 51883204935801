import { Box, Column, Row } from '@cmg/common';
import { grossSpreadWidgetSelector, underwritingTermsScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { UnderwritingTerms } from '../../../../types/domain/datalab/underwriting-terms';
import { selectUnderwritingTermsCharts } from '../../ducks';
import WidgetBarMeanMedianPercentage from '../widgets/WidgetBarMeanMedianPercentage/WidgetBarMeanMedianPercentage';
import WidgetNrOfPeople from '../widgets/WidgetNrOfPeople/WidgetNrOfPeople';
import WidgetSpiderChart from '../widgets/WidgetSpiderChart/WidgetSpiderChart';

type Props = {
  datalab: UnderwritingTerms;
};

export class UnderwritingTermsScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;

    return (
      <div data-test-id={underwritingTermsScreenSelector.testId}>
        <Row>
          <Column xs={24}>
            <Box>
              <WidgetBarMeanMedianPercentage
                chartData={datalab.gross_spread}
                title="Gross Spread"
                xScaleLabel="Offering Size"
                testId={grossSpreadWidgetSelector.testId}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetBarMeanMedianPercentage
                chartData={datalab.all_in_cost}
                title="All In Cost"
                xScaleLabel="Offering Size"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetNrOfPeople chartData={datalab.nr_of_managers} title="# of Managers" />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetNrOfPeople chartData={datalab.nr_of_bookrunners} title="# of Bookrunners" />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetNrOfPeople
                chartData={datalab.nr_of_non_book_managers}
                title="# of Non-Book Managers"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24} sm={12}>
            <Box>
              <WidgetSpiderChart
                chartData={datalab.total_percent_to_bookrunners}
                title="Total % to Bookrunners"
                label="Total %"
              />
            </Box>
          </Column>

          <Column xs={24} sm={12}>
            <Box>
              <WidgetSpiderChart
                chartData={datalab.percent_to_left_lead}
                title="% to Left Lead"
                label="% to Left Lead"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetSpiderChart
                chartData={datalab.total_percent_of_non_books}
                title="Total % to Non-Books"
                label="Total %"
              />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectUnderwritingTermsCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(UnderwritingTermsScreenComponent);
