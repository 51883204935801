import { Icon, Option } from '@cmg/common';
import React from 'react';

import { UserReportPartials } from '../../types';
import UserReportPartialRow from './ReportRow';
import { SSortButton, STable, STableWrapper } from './ReportTable.styles';
import { columns, ReportTableFieldName, SortOrder } from './ReportTableConfig';

type Props = {
  orderField: ReportTableFieldName;
  orderDirection: SortOrder;
  userReportPartials: UserReportPartials;
  onChangeSort: (fieldName: ReportTableFieldName) => void;
  onDeleteReport: (reportId: string) => void;
};

const ReportTable = ({
  userReportPartials,
  onChangeSort,
  orderField,
  orderDirection,
  onDeleteReport,
}: Props) => {
  const [visibleControlsRow, setVisibleControlsRow] = React.useState<Option['value'] | null>(null);

  return (
    <STableWrapper>
      <STable striped={true} responsive={true}>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.fieldName}>
                {column.isSortable ? (
                  <SSortButton type="button" onClick={() => onChangeSort(column.fieldName)}>
                    {column.label}{' '}
                    {column.fieldName === orderField && (
                      <span>
                        {orderDirection === SortOrder.ASC ? (
                          <Icon name="sort-amount-down" variant="light" />
                        ) : (
                          <Icon name="sort-amount-up" variant="light" />
                        )}
                      </span>
                    )}
                  </SSortButton>
                ) : (
                  <span>{column.label}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userReportPartials.options.map(userReportPartial => (
            <UserReportPartialRow
              key={userReportPartial.id}
              userReportPartial={userReportPartial}
              areControlsVisible={visibleControlsRow === userReportPartial.id}
              onSetControlVisibility={(value: Option['value'] | null) =>
                setVisibleControlsRow(value)
              }
              onDeleteReport={onDeleteReport}
            />
          ))}
        </tbody>
      </STable>
    </STableWrapper>
  );
};

export default ReportTable;
