import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { DatalabScreens } from '../../constants';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  {
    ...dtc.underwriter_name,
    renderer: (v, row) => (
      <Link
        to={routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS,
          type: 'table',
          query: {
            underwriter: row.id,
          },
        })}
      >
        {v}
      </Link>
    ),
  },
  dtc.book_offerings,
  dtc.non_book_offerings,
  dtc.offerings,
  dtc.offerings_volume,
  dtc.underwritten_volume,
  dtc.follow_on_discount_from_filing,
  dtc.follow_on_discount_to_last_trade,
  dtc.gross_spread,
  dtc.pct_of_market_cap,
  dtc.first_day_turnover,
  dtc.offer_to_open,
  dtc.offer_to_1day,
  dtc.offer_to_3day,
  dtc.offer_to_7day,
  dtc.offer_to_14day,
  dtc.offer_to_30day,
  dtc.offer_to_current,
];

export const visibleFields: string[] = [
  dtc.underwriter_name.field,
  dtc.book_offerings.field,
  dtc.non_book_offerings.field,
  dtc.offerings.field,
  dtc.underwritten_volume.field,
  dtc.follow_on_discount_from_filing.field,
  dtc.follow_on_discount_to_last_trade.field,
  dtc.gross_spread.field,
  dtc.pct_of_market_cap.field,
  dtc.first_day_turnover.field,
  dtc.offer_to_open.field,
  dtc.offer_to_1day.field,
  dtc.offer_to_7day.field,
  dtc.offer_to_30day.field,
  dtc.offer_to_current.field,
];

export const staticFields: string[] = [dtc.underwriter_name.field, dtc.book_offerings.field];
