import { FlagComponent } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { Country } from '../graphql';

const SContainer = styled.div`
  position: absolute;
  top: 7px;
  display: inline-flex;
  align-items: center;
`;

const SFlag = styled(FlagComponent)`
  margin-right: 5px;
  margin-left: 5px;
  margin-top: -3px;
`;

export type Props = {
  exchangeCountry: Country | null | undefined;
  exchangeCountryDisplayName: string | null | undefined;
};

const CountryRenderer: React.FC<Props> = ({ exchangeCountry, exchangeCountryDisplayName }) => {
  if (!exchangeCountry || !exchangeCountryDisplayName) {
    return null;
  }

  return (
    <SContainer>
      <SFlag countryCode={`${exchangeCountry}`} countryDisplayName={exchangeCountryDisplayName} />
      {exchangeCountryDisplayName}
    </SContainer>
  );
};

export default CountryRenderer;
