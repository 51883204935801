import { CellMeasurer } from 'react-virtualized';

export default class CustomCellMeasurer extends CellMeasurer {
  _maybeMeasureCell() {
    const {
      cache,
      columnIndex = 0,
      parent,
      rowIndex = this.props.index || 0,
      column = {},
    } = this.props;

    if (!cache.has(rowIndex, columnIndex)) {
      const { fixedMinWidth, fixedMaxWidth } = column;
      let height;
      let width;

      if (rowIndex > 0 && cache.hasFixedHeight() && fixedMinWidth) {
        // Avoid expensive width calculation if min width is provided by the column
        height = cache.getHeight(rowIndex, columnIndex);
        width = Math.max(fixedMinWidth, cache.getWidth(0, columnIndex));
      } else {
        ({ height, width } = this._getCellMeasurements());
      }

      if (rowIndex > 0 && fixedMaxWidth) {
        width = fixedMaxWidth;
      }

      cache.set(rowIndex, columnIndex, width, height);

      // If size has changed, let Grid know to re-render.
      if (parent && typeof parent.invalidateCellSizeAfterRender === 'function') {
        parent.invalidateCellSizeAfterRender({
          columnIndex,
          rowIndex,
        });
      }
    }
  }
}
