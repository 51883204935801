import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { IpoPricingSummary as IpoPricingSummaryType } from '../../../../../types/domain/my-dashboard/ipoPricingSummary';
import CurrencyValue from '../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../common/dashboard-value/numeric-value/NumericValue';
import PerformanceMetricValue from '../../../common/dashboard-value/performance-metric-value/PerformanceMetricValue';
import { SectionHeader } from '../../common/DashboardPeriod';
import RangeValue from './components/RangeValue';

const SIPOPricingSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SIpos = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export type Props = {
  data?: IpoPricingSummaryType;
};

const IpoPricingSummary: React.FC<Props> = ({ data }) => {
  return (
    <SIPOPricingSummary data-test-id={myDashboard.ipoPricingSummarySection.testId}>
      <SectionHeader>Pricing Summary</SectionHeader>
      <SectionHeader>IPOs</SectionHeader>
      <SIpos>
        <NumericValue value={data?.numberOfOfferings} label="# Offerings" />
        <CurrencyValue value={data?.capitalRaised} label="Capital Raised" />
        <RangeValue variant="above" value={data?.above} />
        <RangeValue variant="within" value={data?.within} />
        <RangeValue variant="below" value={data?.below} />
        <PerformanceMetricValue value={data?.performance} />
      </SIpos>
    </SIPOPricingSummary>
  );
};

export default IpoPricingSummary;
