import { DateRangeField, DateRangePresetOptions, DateRangePresetTypes } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import { comparisonPeriodControlUpdated, selectComparisonPeriodControl } from '../ducks';
import {
  FormDateRange,
  isValidDateRange,
  mapDefaultDatePeriod,
  mapSubmittedDateRangeValue,
} from './periodControls.model';

export const SLabel = styled.label`
  padding-right: 8px;
`;

export const StyledForm = styled(Form)`
  margin-bottom: 10px;
`;

export const comparisonPeriodControlSchema = Yup.object().shape({
  comparisonPeriod: Yup.object({
    type: Yup.string().oneOf(Object.values(DateRangePresetTypes)),
    end: Yup.string().nullable(),
    start: Yup.string().nullable(),
  }).test(
    'date-range-invalid',
    'Start and End dates are required when a custom time period is provided',
    isValidDateRange
  ),
});

const mapStateToProps = state => ({
  comparisonPeriod: selectComparisonPeriodControl(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onChange: comparisonPeriodControlUpdated,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type FormProps = { comparisonPeriod: FormDateRange };
type OwnProps = {
  presetOptions: DateRangePresetOptions;
};
export type Props = OwnProps & StateProps & FormikProps<FormProps>;

export const ComparisonPeriodControlForm: React.FC<Props> = ({ presetOptions, handleSubmit }) => (
  <div data-test-id={myDashboard.comparisonTimePeriod.testId}>
    <StyledForm>
      <SLabel>Compare to</SLabel>
      <DateRangeField
        required
        name="comparisonPeriod"
        presetOptions={presetOptions}
        onChange={() => handleSubmit()}
      />
    </StyledForm>
  </div>
);

export const ComparisonPeriodControlFormWithFormik = withFormik<OwnProps & StateProps, FormProps>({
  enableReinitialize: true,
  mapPropsToValues: (props: StateProps) => {
    return {
      comparisonPeriod: mapDefaultDatePeriod(props.comparisonPeriod),
    };
  },
  handleSubmit: (values, { props }) => {
    props.actions.onChange({
      comparisonPeriod: mapSubmittedDateRangeValue(values.comparisonPeriod),
    });
  },
  validateOnBlur: false,
  validationSchema: comparisonPeriodControlSchema,
})(ComparisonPeriodControlForm);

const ConnectedComparisonPeriodControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComparisonPeriodControlFormWithFormik);
export default ConnectedComparisonPeriodControl;
