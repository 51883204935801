import { Group } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React from 'react';

import { SGroupLabelWrapper, StyledCheckbox } from './GroupToggler.styles';

export type Props = {
  disabled?: string[];
  values: string[];
  group: Group;
  onChange: (values: string[]) => void;
};

export const GroupToggler: React.FC<Props> = ({ disabled = [], values, group, onChange }) => {
  const handleGroupSelectionChange = (checked: boolean) => {
    const groupOptionValues = group.options.map(option => option.value);
    const groupSelection = values.filter(
      value => groupOptionValues.includes(value) && !disabled.includes(value)
    );

    let nextValues;

    if (checked || !groupSelection.length) {
      nextValues = [...values, ...groupOptionValues];
    } else {
      nextValues = values.filter(value => !groupSelection.includes(value));
    }

    // Remove possible duplicated values
    nextValues = uniqWith(nextValues, isEqual);
    onChange(nextValues);
  };
  const allSelected = group.options.every(option => values.includes(option.value));
  const someSelected = group.options.some(option => values.includes(option.value));
  const intermediate = someSelected && !allSelected;
  return (
    <SGroupLabelWrapper>
      <StyledCheckbox
        key={group.options.map(({ value }) => value).join(',')}
        indeterminate={intermediate}
        value={allSelected || someSelected}
        onChange={handleGroupSelectionChange}
      />
      {group.label}
    </SGroupLabelWrapper>
  );
};
