import { myDashboard } from '@cmg/e2e-selectors';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { MyDashboard_OfferingFieldsPartsFragment } from 'src/features/my-dashboard/graphql/__generated__/MyDashboard_PricedCalendar';

import NoRecentDeals from '../no-recent-deals/NoRecentDeals';
import RecentDealItem from './recent-deal-item/RecentDealItem';
import { groupByFirstTrade } from './RecentDealsList.model';
import { SFirstTradedDateGroupHeader } from './RecentDealsList.styles';

export type Props = {
  recentDeals: MyDashboard_OfferingFieldsPartsFragment[];
};

const RecentDealsList: React.FC<Props> = ({ recentDeals }) => {
  if (!recentDeals || isEmpty(recentDeals)) {
    return <NoRecentDeals />;
  }

  const grouped = groupByFirstTrade(recentDeals);

  return (
    <React.Fragment>
      {Object.keys(grouped).map(firstTradeDate => {
        const deals = grouped[firstTradeDate];

        return (
          <div
            key={firstTradeDate}
            data-test-id={myDashboard.recentDealsGroup.testId}
            data-testid={myDashboard.recentDealsGroup.testId}
          >
            <SFirstTradedDateGroupHeader>
              <div>First trade date:</div>
              <div>{firstTradeDate}</div>
            </SFirstTradedDateGroupHeader>
            {deals.map(deal => (
              <RecentDealItem key={deal.id} recentDeal={deal} />
            ))}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default RecentDealsList;
