import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { BarChart } from '../../../../../../common/components/charts';
import { HistoricalOfferingVolumeValues } from '../../../../../../types/domain/datalab/ipo-structure';
import Toggler from '../../../../../shared/layout/components/Toggler';
import { barChartColors } from '../../../../colors';
import valueAmountButtons from '../../../../model/buttons/value-amount-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../../shared/styled';

enum ChartValueEnum {
  AMOUNT = 'amount',
  VALUE = 'value',
}

const Buttons = [
  { value: ChartValueEnum.VALUE, label: 'VOLUME ($)' },
  { value: ChartValueEnum.AMOUNT, label: 'VOLUME (#)' },
];

type Props = {
  chartData?: HistoricalOfferingVolumeValues[];
};

export function transformData(
  chartData: HistoricalOfferingVolumeValues[] = [],
  selectedValueType: string
) {
  const datasets =
    selectedValueType === ChartValueEnum.VALUE
      ? [
          {
            label: 'Value',
            data: chartData.map(item => item.values.value),
            backgroundColor: barChartColors.ipoBg,
          },
        ]
      : [
          {
            label: 'Amount',
            data: chartData.map(item => item.values.amount),
            backgroundColor: barChartColors.ipoBg,
          },
        ];

  return {
    labels: chartData.map(item => item.label),
    datasets: datasets,
  };
}

export function getOptions(selectedValueType: string) {
  return {
    scales: {
      y: {
        ticks: {
          callback: value => {
            if (typeof value !== 'number') {
              return value;
            }

            if (selectedValueType === ChartValueEnum.VALUE) {
              return numericUtil.formatCurrencyInBillions(value);
            }
            return numericUtil.formatInteger(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;
            const { label } = dataset;
            const value = parsed.y;
            return `${label ? `${label}: ` : ''}${
              selectedValueType === ChartValueEnum.VALUE
                ? numericUtil.formatCurrencyInBillions(value)
                : numericUtil.formatInteger(value)
            }`;
          },
        },
      },
    },
  };
}

export default function WidgetHistoricalOfferingVolume({ chartData = [] }: Props) {
  const [activeBtn, setActiveBtn] = React.useState(Buttons[0]);

  const transformedData = React.useMemo(
    () => transformData(chartData, activeBtn.value),
    [chartData, activeBtn.value]
  );
  const options = React.useMemo(() => getOptions(activeBtn.value), [activeBtn.value]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart>
      <Box.Header title="Historical Offering Volume" />

      <SButtonBox>
        <Toggler
          size="small"
          activeButton={activeBtn}
          onChange={handleButtonChange}
          buttons={valueAmountButtons}
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <BarChart data={transformedData} options={options} barSizingMode="CMGCenteredBarPlugin" />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
}
