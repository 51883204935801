import { apiUtil, UnpackPromise } from '@cmg/common';
import { AxiosResponse } from 'axios';

import { getFeatureToggles } from '../../../config/appSettings';
import { BuySideDashboardDto } from '../../../types/domain/my-dashboard/buySideDashboard';
import {
  BuySideDashboardParameters,
  SellSideDashboardParameters,
} from '../../../types/domain/my-dashboard/myDashboardParameters';
import { SellSideDashboardDto } from '../../../types/domain/my-dashboard/sellSideDashboard';
import { dlgwApiClient } from '../dlgwApiClient';

export const fetchBuySideDashboard = (payload: BuySideDashboardParameters) => {
  const { useLegacyDashboard } = getFeatureToggles();
  return dlgwApiClient
    .post<BuySideDashboardParameters, AxiosResponse<BuySideDashboardDto>>(
      `/myDashboard/buySide?useLegacy=${useLegacyDashboard}`, // TODO remove legacy option once we've tested the new DotNet endpoints
      payload,
      {
        // @ts-ignore
        apiCallId: false,
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type FetchBuySideDashboardResponse = UnpackPromise<ReturnType<typeof fetchBuySideDashboard>>;

export const fetchSellSideDashboard = (payload: SellSideDashboardParameters) => {
  const { useLegacyDashboard } = getFeatureToggles();
  return dlgwApiClient
    .post<SellSideDashboardParameters, AxiosResponse<SellSideDashboardDto>>(
      `/myDashboard/sellSide?useLegacy=${useLegacyDashboard}`, // TODO remove legacy option once we've tested the new DotNet endpoints
      payload,
      {
        // @ts-ignore
        apiCallId: false,
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};

export type FetchSellSideDashboardResponse = UnpackPromise<
  ReturnType<typeof fetchSellSideDashboard>
>;
