import { FieldControlProps } from '@cmg/common';
import { ruleSelector } from '@cmg/e2e-selectors';
import { FieldArray, Formik } from 'formik';
import debounce from 'lodash/debounce';
import React from 'react';

import {
  AdvancedFilterFieldConfig,
  AdvancedFilterValue,
  canSelectOperator,
  canSetValue,
  changedValidRules,
  createDefaultValue,
  createNewFilter,
  getCategorizedFieldOptions,
  getFieldOperatorOptions,
  getFieldRenderer,
  groupSearch,
} from './advanced-filter.model';
import {
  SFilterRule,
  StyledExpandableSelectField,
  StyledSelectField,
} from './AdvancedFilter.styles';
import AddNewFilterButton from './controls/AddNewFilterButton';
import RemoveFilterButton from './controls/RemoveFilterButton';
import ValueInputControl from './controls/ValueInputControl';

export interface Props extends FieldControlProps<AdvancedFilterValue, AdvancedFilterValue> {
  fieldConfigs: AdvancedFilterFieldConfig[];
}

export default class AdvancedFilter extends React.Component<Props> {
  handleOnSubmit = debounce(values => {
    const validRules = changedValidRules(this.props.value, values);
    if (validRules !== null) {
      this.props.onChange && this.props.onChange({ ...values, rules: validRules });
    }
  }, 300);

  handleOnFieldChange = (setFieldValue, ruleIndex) => {
    setFieldValue(`rules.${ruleIndex}.operator`, null, true);
    setFieldValue(`rules.${ruleIndex}.value`, undefined, true);
  };

  handleOnOperatorChange = (setFieldValue, ruleIndex) => {
    setFieldValue(`rules.${ruleIndex}.value`, undefined, true);
  };

  render() {
    const { value, fieldConfigs } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={value || createDefaultValue()}
        isInitialValid={false}
        onSubmit={values => {
          this.handleOnSubmit(values);
        }}
        render={({ setFieldValue, values, handleSubmit }) => (
          <FieldArray
            name="rules"
            render={arrayHelpers => (
              <div>
                {values &&
                  values.rules &&
                  values.rules.map((rule, index) => {
                    const fieldOptions = getCategorizedFieldOptions(fieldConfigs);
                    const operatorOptions = getFieldOperatorOptions(fieldConfigs, rule);
                    const renderer = getFieldRenderer(fieldConfigs, rule);

                    // for only one operator pick it directly (and hide select ⇩)
                    if (operatorOptions.length === 1 && !rule.operator) {
                      setFieldValue(`rules.${index}.operator`, operatorOptions[0].value);
                    }

                    return (
                      <SFilterRule key={rule.id} data-test-id={ruleSelector.testId}>
                        <StyledExpandableSelectField
                          name={`rules.${index}.field`}
                          options={fieldOptions}
                          isSearchable
                          isClearable
                          placeholder="Select Field"
                          onChange={() => {
                            this.handleOnFieldChange(setFieldValue, index);
                            handleSubmit();
                          }}
                          filterOption={groupSearch}
                        />

                        {canSelectOperator(rule) && operatorOptions.length > 1 && (
                          <StyledSelectField
                            name={`rules.${index}.operator`}
                            options={operatorOptions}
                            placeholder="Select Criteria"
                            onChange={() => {
                              this.handleOnOperatorChange(setFieldValue, index);
                              handleSubmit();
                            }}
                          />
                        )}

                        {canSetValue(rule) && (
                          <ValueInputControl
                            name={`rules.${index}.value`}
                            renderer={renderer}
                            onChange={handleSubmit}
                          />
                        )}

                        <RemoveFilterButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                            handleSubmit();
                          }}
                        />
                      </SFilterRule>
                    );
                  })}

                <AddNewFilterButton onClick={() => arrayHelpers.push(createNewFilter())} />
              </div>
            )}
          />
        )}
      />
    );
  }
}
