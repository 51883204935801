/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyDashboard_OfferingFieldsPartsFragment = {
  __typename?: 'Offering';
  id: string;
  issuer?: {
    __typename?: 'Issuer';
    name?: string | null;
    primarySymbol?: string | null;
    sectorDisplayName?: string | null;
  } | null;
  attributes?: {
    __typename?: 'OfferingAttributes';
    typeDisplayName?: string | null;
    leftLeadName?: string | null;
    pctOfferToCurrent?: number | null;
    currentSharePrice?: number | null;
    sizeUsd?: number | null;
    firstTradeDate?: string | null;
  } | null;
  lastAllocation?: { __typename?: 'Allocation'; ioiType?: Types.IoiType | null } | null;
  firmMetric?: { __typename?: 'FirmMetric'; allocation?: number | null } | null;
};

export type MyDashboard_PricedCalendarQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.OfferingFilterInput>;
  order?: Types.InputMaybe<Array<Types.OfferingSortInput> | Types.OfferingSortInput>;
  hasIoiPermission: Types.Scalars['Boolean'];
}>;

export type MyDashboard_PricedCalendarQuery = {
  __typename?: 'Query';
  offerings?: {
    __typename?: 'OfferingCollectionSegment';
    items?: Array<{
      __typename?: 'Offering';
      id: string;
      issuer?: {
        __typename?: 'Issuer';
        name?: string | null;
        primarySymbol?: string | null;
        sectorDisplayName?: string | null;
      } | null;
      attributes?: {
        __typename?: 'OfferingAttributes';
        typeDisplayName?: string | null;
        leftLeadName?: string | null;
        pctOfferToCurrent?: number | null;
        currentSharePrice?: number | null;
        sizeUsd?: number | null;
        firstTradeDate?: string | null;
      } | null;
      lastAllocation?: { __typename?: 'Allocation'; ioiType?: Types.IoiType | null } | null;
      firmMetric?: { __typename?: 'FirmMetric'; allocation?: number | null } | null;
    }> | null;
    pageInfo: { __typename?: 'CollectionSegmentInfo'; hasNextPage: boolean; offset: number };
  } | null;
};

export const MyDashboard_OfferingFieldsPartsFragmentDoc = gql`
  fragment MyDashboard_OfferingFieldsParts on Offering {
    id
    issuer {
      name
      primarySymbol
      sectorDisplayName
    }
    attributes {
      typeDisplayName
      leftLeadName
      pctOfferToCurrent
      currentSharePrice
      sizeUsd
      firstTradeDate
    }
    lastAllocation @include(if: $hasIoiPermission) {
      ioiType
    }
    firmMetric @include(if: $hasIoiPermission) {
      allocation
    }
  }
`;
export const MyDashboard_PricedCalendarDocument = gql`
  query MyDashboard_PricedCalendar(
    $skip: Int
    $take: Int
    $where: OfferingFilterInput
    $order: [OfferingSortInput!]
    $hasIoiPermission: Boolean!
  ) {
    offerings(skip: $skip, take: $take, where: $where, order: $order) {
      items {
        ...MyDashboard_OfferingFieldsParts
      }
      pageInfo {
        hasNextPage
        offset
      }
    }
  }
  ${MyDashboard_OfferingFieldsPartsFragmentDoc}
`;

/**
 * __useMyDashboard_PricedCalendarQuery__
 *
 * To run a query within a React component, call `useMyDashboard_PricedCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDashboard_PricedCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDashboard_PricedCalendarQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      hasIoiPermission: // value for 'hasIoiPermission'
 *   },
 * });
 */
export function useMyDashboard_PricedCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyDashboard_PricedCalendarQuery,
    MyDashboard_PricedCalendarQueryVariables
  > &
    ({ variables: MyDashboard_PricedCalendarQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyDashboard_PricedCalendarQuery, MyDashboard_PricedCalendarQueryVariables>(
    MyDashboard_PricedCalendarDocument,
    options
  );
}
export function useMyDashboard_PricedCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyDashboard_PricedCalendarQuery,
    MyDashboard_PricedCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyDashboard_PricedCalendarQuery,
    MyDashboard_PricedCalendarQueryVariables
  >(MyDashboard_PricedCalendarDocument, options);
}
export function useMyDashboard_PricedCalendarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyDashboard_PricedCalendarQuery,
        MyDashboard_PricedCalendarQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyDashboard_PricedCalendarQuery,
    MyDashboard_PricedCalendarQueryVariables
  >(MyDashboard_PricedCalendarDocument, options);
}
export type MyDashboard_PricedCalendarQueryHookResult = ReturnType<
  typeof useMyDashboard_PricedCalendarQuery
>;
export type MyDashboard_PricedCalendarLazyQueryHookResult = ReturnType<
  typeof useMyDashboard_PricedCalendarLazyQuery
>;
export type MyDashboard_PricedCalendarSuspenseQueryHookResult = ReturnType<
  typeof useMyDashboard_PricedCalendarSuspenseQuery
>;
export type MyDashboard_PricedCalendarQueryResult = Apollo.QueryResult<
  MyDashboard_PricedCalendarQuery,
  MyDashboard_PricedCalendarQueryVariables
>;
