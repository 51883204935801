import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      mean: number;
      median: number;
      count: number;
    };
  }[];
  title: string;
};
type RawItem = {
  y: number;
  x: string;
  count: number;
};

type ChartProps = BarChartProps<RawItem[]>;

const transformData = (chartData: Props['chartData'] = []): ChartProps['data'] => {
  return {
    datasets: [
      {
        label: 'Mean',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.mean,
            x: item.label,
            count: item.values.count,
          })
        ),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Median',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.median,
            x: item.label,
            count: item.values.count,
          })
        ),
        backgroundColor: barChartColors.light,
      },
    ],
  };
};

const getOptions = (): ChartProps['options'] => {
  return {
    scales: {
      x: {
        title: {
          text: 'Offering Size',
          display: true,
        },
      },
      y: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatNumber(value, 1) : value,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;
            const raw = tooltipItem.raw as RawItem;

            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;

            return `${dataset.label ? `${dataset.label}: ` : ''}${numericUtil.formatNumber(
              parsed.y
            )}${countString ?? ''}`;
          },
        },
      },
    },
  };
};

const WidgetNrOfPeople = ({ chartData = [], title = '' }: Props): JSX.Element => {
  const transformedData = React.useMemo(() => transformData(chartData), [chartData]);
  const options = React.useMemo(() => getOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title={title} />

      <Box.Content>
        <SChart>
          <BarChart data={transformedData} barSizingMode="CMGCenteredBarPlugin" options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetNrOfPeople;
