import { maxField, meanField, medianField, minField } from '../../summaryFields';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  dtc.fund_name,
  dtc.ipos,
  dtc.fos,
  { ...dtc.offerings, label: '# Total', fixedMinWidth: undefined },
  dtc.pct_of_firm_offerings,
  {
    ...dtc.allocation_investment,
    label: 'Investment $',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  dtc.pct_of_firm_total,
  dtc.pct_of_firm_allocation,
  dtc.pct_of_deal,
  dtc.offer_to_open,
  dtc.offer_to_1day,
  dtc.offer_to_3day,
  dtc.offer_to_7day,
  dtc.offer_to_14day,
  dtc.offer_to_30day,
  dtc.offer_to_current,
];

export const visibleFields: string[] = [
  dtc.fund_name.field,
  dtc.ipos.field,
  dtc.fos.field,
  dtc.offerings.field,
  dtc.pct_of_firm_offerings.field,
  dtc.allocation_investment.field,
  dtc.pct_of_firm_total.field,
  dtc.pct_of_firm_allocation.field,
  dtc.pct_of_deal.field,
  dtc.offer_to_open.field,
  dtc.offer_to_1day.field,
  dtc.offer_to_3day.field,
  dtc.offer_to_7day.field,
  dtc.offer_to_14day.field,
  dtc.offer_to_30day.field,
  dtc.offer_to_current.field,
];

export const staticFields: string[] = [dtc.fund_name.field];
