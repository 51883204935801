import { UnpackPromise, urlUtil } from '@cmg/common';
import axios from 'axios';

import { getAppSettings } from '../config/appSettings';
import { ApiResponse } from '../types/api/ApiResponse';
import { PaginatedResponse } from '../types/api/pagination';
import { ISODateTime } from '../types/common';
import { NotificationActionType } from '../types/domain/notifications/constants';
import { Notification, NotificationSummary } from '../types/domain/notifications/notification';

const appSettings = getAppSettings();
const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
const baseURL = urlUtil.addSubdomainToUrl(appSettings.env.identityServiceBaseUrl, accountSubdomain);

export const notificationsApiClient = axios.create({
  baseURL: `${baseURL}api/v1/self`,
  responseType: 'json',
  headers: {
    'Client-App-Version': appSettings.client.appVersion,
    'Content-Type': 'application/json',
  },
});

/*
 * Fetch list of Notifications with cursor based pagination
 */
export type GetNotificationsParams = {
  before?: ISODateTime;
  actionTypes?: NotificationActionType[];
  isRead?: boolean;
  includeTotals?: boolean;
};
export const fetchNotifications = (params: GetNotificationsParams) => {
  return notificationsApiClient.get<
    PaginatedResponse<Notification[]>,
    ApiResponse<PaginatedResponse<Notification[]>>
  >(`/notifications${urlUtil.queryStringify(params, { arrayFormat: 'none' })}`);
};
export type GetNotificationsResponse = UnpackPromise<ReturnType<typeof fetchNotifications>>;

/*
 * Fetch Summary of Notifications including total unread and by actionType
 */
export const fetchNotificationSummary = () => {
  return notificationsApiClient.get<NotificationSummary>(`/notifications/summary`) as Promise<
    ApiResponse<NotificationSummary>
  >;
};
export type GetNotificationSummaryResponse = UnpackPromise<
  ReturnType<typeof fetchNotificationSummary>
>;

/*
 * Update isRead status on a Notification
 */
export type MarkAsReadParams = {
  isRead: boolean;
  notification: Notification;
};
export const markAsRead = async (params: MarkAsReadParams) => {
  const { notification, isRead } = params;
  return notificationsApiClient.put<{ isRead: typeof isRead }, ApiResponse<Notification>>(
    `/notifications/${notification.id}/readStatus`,
    {
      isRead,
    }
  );
};
export type PutMarkAsReadResponse = UnpackPromise<ReturnType<typeof markAsRead>>;

/*
 * Update ALL notification isRead status as read
 */
export const markAllAsRead = async () =>
  notificationsApiClient.post<{ isRead: boolean }, ApiResponse<void>>(`/notifications/readStatus`, {
    isRead: true,
  });
export type MarkAllAsReadResponse = UnpackPromise<ReturnType<typeof markAllAsRead>>;

const notificationApi = {
  fetchNotifications,
  fetchNotificationSummary,
  markAsRead,
  markAllAsRead,
};

export default notificationApi;
