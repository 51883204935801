import { useAuth } from '@cmg/auth';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { checkIsFollowingOffering } from '../../shared/follow-offering/checkIsFollowingOffering';
import { type Calendar_OfferingFieldsFragment } from '../graphql';
import CalendarIconButton from './CalendarIconButton';

const StyledCalendarIconButton = styled(CalendarIconButton)`
  ${({ solid }) =>
    solid &&
    css`
      transform: rotate(30deg);
    `}
  &:hover {
    ${({ solid }) =>
      !solid &&
      css`
        transform: rotate(30deg);
      `}
  }
`;

type Props = {
  offering: Calendar_OfferingFieldsFragment;
  onClick: (offering: Calendar_OfferingFieldsFragment) => void;
};

const FollowOfferingButton: React.FC<Props> = ({ onClick, offering }) => {
  const { oidcUserId, userPermissions } = useAuth();
  const isFollowing = checkIsFollowingOffering(offering, oidcUserId!, userPermissions);

  return (
    <StyledCalendarIconButton
      onClick={() => onClick(offering)}
      iconName="thumbtack"
      solid={isFollowing}
      popoverContent={isFollowing ? 'Unfollow' : 'Follow'}
    />
  );
};

export default FollowOfferingButton;
