import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';

import {
  SContact,
  SNoticeContent,
  SNoticeHeader,
  SNoticeList,
  SNoticeSubHeader,
  SSubscriptionNotice,
} from './SubscriptionNotice.styles';

/**
 * SubscriptionNotice displays on top of a BlurOverlay over
 * my participation and passed data when the user does not have
 * a my datalab subscription
 */
const SubscriptionNotice: React.FC = () => {
  return (
    <SSubscriptionNotice data-test-id={myDashboard.myDatalabSubscriptionNotice.testId}>
      <SNoticeContent>
        <SNoticeHeader>Subscribe to My DataLab</SNoticeHeader>
        <SNoticeSubHeader>My DataLab subscription provides access to:</SNoticeSubHeader>
        <SNoticeList>
          <li>Participation summary (track indications and allocations)</li>
          <li>Aftermarket performance (mean, median, and allocation weighted)</li>
          <li>Fund-level and P&L analytics</li>
        </SNoticeList>
        <SContact>
          Please contact&nbsp;
          <a href="mailto:support@cmgx.io" title="Contact Support">
            support@cmgx.io
          </a>
          &nbsp;for more information.
        </SContact>
      </SNoticeContent>
    </SSubscriptionNotice>
  );
};

export default SubscriptionNotice;
