import { Icon, LinkButton, PrimaryButton } from '@cmg/common';
import { ioiTrackerSaveSelector } from '@cmg/e2e-selectors';
import PropTypes from 'prop-types';
import React from 'react';

import { SLinkButton, SWrapper } from './EditControls.styles';

type Props = {
  onClickEditButton: () => void;
  onClickCancelButton: () => void;
  onClickSaveButton: () => void;
  isEditing: boolean;
  disabled?: boolean;
  testId?: string;
};

const EditControls: React.FC<Props> = ({
  onClickEditButton,
  onClickCancelButton,
  onClickSaveButton,
  isEditing,
  testId,
}) => (
  <SWrapper isEditing={isEditing}>
    {!isEditing && (
      <SLinkButton size="small" onClick={onClickEditButton} testId={testId}>
        <Icon name="edit" size="lg" />
      </SLinkButton>
    )}

    {isEditing && (
      <LinkButton size="small" onClick={onClickCancelButton}>
        Cancel
      </LinkButton>
    )}

    {isEditing && (
      <PrimaryButton
        size="small"
        onClick={onClickSaveButton}
        testId={ioiTrackerSaveSelector.testId}
      >
        Save
      </PrimaryButton>
    )}
  </SWrapper>
);

EditControls.propTypes = {
  onClickEditButton: PropTypes.func.isRequired,
  onClickCancelButton: PropTypes.func.isRequired,
  onClickSaveButton: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export default EditControls;
