import { removeFilterSelector } from '@cmg/e2e-selectors';
import React from 'react';

import TrashButton from '../../../buttons/trash-button/TrashButton';

type Props = {
  onClick: () => void;
};

export default class RemoveFilterButton extends React.Component<Props> {
  render() {
    return (
      <div className="advanced-filter-remove-filter">
        <TrashButton onClick={this.props.onClick} testId={removeFilterSelector.testId} />
      </div>
    );
  }
}
