import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import HorizontalLine from '../../../common/horizontal-line/HorizontalLine';
import { PeriodColumn, Section } from '../../common/DashboardPeriod';
import FollowOnPricingSummary from '../../components/follow-on-pricing-summary/FollowOnPricingSummary';
import IpoPricingSummary from '../../components/pricing-summary/IpoPricingSummary';
import UsEcm from '../../components/us-ecm/UsEcm';
import { getCurrentPeriodPresetOptions } from '../../controls/components/periodControls.model';
import TimePeriodControl from '../../controls/components/TimePeriodControl';
import {
  selectSellSideCurrentFollowOnPricingSummary,
  selectSellSideCurrentIpoPricingSummary,
  selectSellSideCurrentLeagueTable,
  selectSellSideCurrentUsEcm,
  selectSellSideLoading,
} from '../ducks';
import LeagueTable from './league-table/LeagueTable';
import LoadingSkeleton from './loading-skeleton/LoadingSkeleton';

/**
 * SellSide CurrentPeriod displays a dashboard period with the data from the current
 * selected date range in the TimePeriodControl
 */
const CurrentPeriod: React.FC = () => {
  const { usEcm, followOnPricingSummary, ipoPricingSummary, leagueTable, loading } = useSelector(
    state => ({
      followOnPricingSummary: selectSellSideCurrentFollowOnPricingSummary(state),
      ipoPricingSummary: selectSellSideCurrentIpoPricingSummary(state),
      leagueTable: selectSellSideCurrentLeagueTable(state),
      usEcm: selectSellSideCurrentUsEcm(state),
      loading: selectSellSideLoading(state),
    }),
    shallowEqual
  );
  const presetOptions = getCurrentPeriodPresetOptions(new Date());

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <PeriodColumn data-test-id={myDashboard.currentPeriod.testId}>
      <TimePeriodControl presetOptions={presetOptions} />
      <HorizontalLine />
      <Section>
        <UsEcm data={usEcm} />
      </Section>
      <HorizontalLine />
      <Section>
        <IpoPricingSummary data={ipoPricingSummary} />
      </Section>
      <HorizontalLine />
      <Section>
        <FollowOnPricingSummary data={followOnPricingSummary} />
      </Section>
      <HorizontalLine />
      <Section>
        <LeagueTable data={leagueTable} isCurrentPeriod />
      </Section>
    </PeriodColumn>
  );
};

export default CurrentPeriod;
