import { Option, ToastManager } from '@cmg/common';
import React, { useCallback } from 'react';

import { SortEnumType } from '../../../../../graphql/__generated__/index';
import { SearchFilterHookResult } from '../../../../../graphql/types';
import {
  useOfferingsFilterForm_UnderwritersLazyQuery,
  useOfferingsFilterForm_UnderwritersQuery,
} from '../graphql/__generated__/OfferingsFilterForm_Underwriters';
import {
  createUnderwriterOptions,
  useCreateUnderwriterOptions,
} from '../OfferingsFilterForm.model';

export const useLoadMissingUnderwriters = () => {
  const [loadUnderwriters] = useOfferingsFilterForm_UnderwritersLazyQuery();

  const loadMissingUnderwriters = useCallback(
    async (ids: string[]) => {
      const { data } = await loadUnderwriters({
        variables: {
          where: {
            id: { in: ids },
          },
        },
        fetchPolicy: 'cache-first',
      });
      return createUnderwriterOptions(data?.managers?.items ?? []);
    },
    [loadUnderwriters]
  );

  return loadMissingUnderwriters;
};

export const useSearchUnderwriters = (): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useOfferingsFilterForm_UnderwritersQuery({
    variables: {
      order: { name: SortEnumType.Asc },
      where: {
        name: searchText ? { like: searchText } : undefined,
      },
    },
    fetchPolicy: 'cache-first',
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const underwriterOptions = useCreateUnderwriterOptions(data?.managers?.items ?? [], searchText);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching underwriters');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(underwriterOptions);
    }
  }, [loading, data, error, underwriterOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: underwriterOptions,
    loadOptions,
  };
};
