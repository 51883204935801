import { Popover } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

const neutral = '#D2FAE1';

const greens = [
  '#BDF5D2',
  '#B4EFCA',
  '#ACE9C3',
  '#A4E3BC',
  '#9CDEB5',
  '#94D8AE',
  '#8CD2A7',
  '#83CC9F',
  '#7BC798',
  '#73C191',
  '#6BBB8A',
  '#63B583',
  '#5BB07C',
  '#52AA74',
  '#4AA46D',
  '#429E66',
  '#3A995F',
  '#329358',
  '#2A8D51',
  '#22884A',
];

const reds = [
  '#F5C0BD',
  '#F3B8B5',
  '#F2B1AE',
  '#F0AAA6',
  '#EFA39F',
  '#ED9C97',
  '#EC9590',
  '#EA8E89',
  '#E98781',
  '#E7807A',
  '#E67972',
  '#E4726B',
  '#E36B63',
  '#E1645C',
  '#E05D55',
  '#DE564D',
  '#DD4F46',
  '#DB483E',
  '#DA4137',
  '#D93A30',
];

const getIdx = (value, minMax, colors) => {
  if (minMax === 0) {
    return 0;
  }

  return Math.max(Math.min(Math.floor((value / minMax) * colors.length), colors.length), 1) - 1;
};

const getHeatMapColor = (min, max, value) => {
  if (
    value === undefined ||
    value === null ||
    min === undefined ||
    min === null ||
    max === undefined ||
    max === null
  ) {
    return neutral;
  }

  return value >= 0 ? greens[getIdx(value, max, greens)] : reds[getIdx(value, min, reds)];
};

export const STableRow = styled.tr<{ bold: boolean }>`
  font-weight: ${({ bold, theme }) =>
    bold ? theme.text.weight.medium : theme.text.weight.regular};
`;

const BaseHeaderCellStyles = css`
  background-color: ${({ theme }) => theme.color.gray2};
  border: 1px solid ${({ theme }) => theme.border.color.white};
  border-bottom: none;
`;

export const STableHeaderCategoryCell = styled.th`
  &&& {
    text-align: left;
    white-space: nowrap;
    ${BaseHeaderCellStyles}
  }
`;

export const STableHeaderCell = styled.th`
  &&& {
    text-align: center;
    width: 9%;
    ${BaseHeaderCellStyles}
  }
`;

export const STableDataCategoryCell = styled.td`
  text-align: left;
  white-space: nowrap;
  border: 1px solid ${({ theme }) => theme.border.color.white} !important;
`;

// eslint-disable-next-line no-unexpected-multiline
export const STableDataCell = styled(STableDataCategoryCell)<{
  minValue: number;
  maxValue: number;
  selectedValue: number | null;
}>`
  text-align: center;
  border-color: ${({ theme }) => theme.border.color.white};
  background-color: ${({ minValue, maxValue, selectedValue }) =>
    getHeatMapColor(minValue, maxValue, selectedValue)};
`;

export const StyledPopover = styled(Popover)`
  padding: 0;
`;
