/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import { Shared_CustomSectorPartsFragmentDoc } from '../../../../../shared/sectors/graphql/__generated__/Shared_CustomSectors';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SectorsGrid_AddCustomSectorMutationVariables = Types.Exact<{
  data: Types.CustomSectorAddInputData;
}>;

export type SectorsGrid_AddCustomSectorMutation = {
  __typename?: 'Mutation';
  addCustomSector?: {
    __typename?: 'AddCustomSectorPayload';
    customSector?: {
      __typename?: 'CustomSector';
      id: string;
      name?: string | null;
      children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
    } | null;
  } | null;
};

export type SectorsGrid_UpdateCustomSectorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  data: Types.CustomSectorUpdateInputData;
}>;

export type SectorsGrid_UpdateCustomSectorMutation = {
  __typename?: 'Mutation';
  updateCustomSector?: {
    __typename?: 'UpdateCustomSectorPayload';
    customSector?: {
      __typename?: 'CustomSector';
      id: string;
      name?: string | null;
      children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
    } | null;
  } | null;
};

export type SectorsGrid_DeleteCustomSectorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SectorsGrid_DeleteCustomSectorMutation = {
  __typename?: 'Mutation';
  deleteCustomSector?: {
    __typename?: 'DeleteCustomSectorPayload';
    customSector?: {
      __typename?: 'CustomSector';
      id: string;
      name?: string | null;
      children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
    } | null;
  } | null;
};

export const SectorsGrid_AddCustomSectorDocument = gql`
  mutation SectorsGrid_AddCustomSector($data: CustomSectorAddInputData!) {
    addCustomSector(input: { data: $data }) {
      customSector {
        ...Shared_CustomSectorParts
      }
    }
  }
  ${Shared_CustomSectorPartsFragmentDoc}
`;
export type SectorsGrid_AddCustomSectorMutationFn = Apollo.MutationFunction<
  SectorsGrid_AddCustomSectorMutation,
  SectorsGrid_AddCustomSectorMutationVariables
>;

/**
 * __useSectorsGrid_AddCustomSectorMutation__
 *
 * To run a mutation, you first call `useSectorsGrid_AddCustomSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectorsGrid_AddCustomSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectorsGridAddCustomSectorMutation, { data, loading, error }] = useSectorsGrid_AddCustomSectorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSectorsGrid_AddCustomSectorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectorsGrid_AddCustomSectorMutation,
    SectorsGrid_AddCustomSectorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectorsGrid_AddCustomSectorMutation,
    SectorsGrid_AddCustomSectorMutationVariables
  >(SectorsGrid_AddCustomSectorDocument, options);
}
export type SectorsGrid_AddCustomSectorMutationHookResult = ReturnType<
  typeof useSectorsGrid_AddCustomSectorMutation
>;
export type SectorsGrid_AddCustomSectorMutationResult =
  Apollo.MutationResult<SectorsGrid_AddCustomSectorMutation>;
export type SectorsGrid_AddCustomSectorMutationOptions = Apollo.BaseMutationOptions<
  SectorsGrid_AddCustomSectorMutation,
  SectorsGrid_AddCustomSectorMutationVariables
>;
export const SectorsGrid_UpdateCustomSectorDocument = gql`
  mutation SectorsGrid_UpdateCustomSector($id: ID!, $data: CustomSectorUpdateInputData!) {
    updateCustomSector(input: { id: $id, data: $data }) {
      customSector {
        ...Shared_CustomSectorParts
      }
    }
  }
  ${Shared_CustomSectorPartsFragmentDoc}
`;
export type SectorsGrid_UpdateCustomSectorMutationFn = Apollo.MutationFunction<
  SectorsGrid_UpdateCustomSectorMutation,
  SectorsGrid_UpdateCustomSectorMutationVariables
>;

/**
 * __useSectorsGrid_UpdateCustomSectorMutation__
 *
 * To run a mutation, you first call `useSectorsGrid_UpdateCustomSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectorsGrid_UpdateCustomSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectorsGridUpdateCustomSectorMutation, { data, loading, error }] = useSectorsGrid_UpdateCustomSectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSectorsGrid_UpdateCustomSectorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectorsGrid_UpdateCustomSectorMutation,
    SectorsGrid_UpdateCustomSectorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectorsGrid_UpdateCustomSectorMutation,
    SectorsGrid_UpdateCustomSectorMutationVariables
  >(SectorsGrid_UpdateCustomSectorDocument, options);
}
export type SectorsGrid_UpdateCustomSectorMutationHookResult = ReturnType<
  typeof useSectorsGrid_UpdateCustomSectorMutation
>;
export type SectorsGrid_UpdateCustomSectorMutationResult =
  Apollo.MutationResult<SectorsGrid_UpdateCustomSectorMutation>;
export type SectorsGrid_UpdateCustomSectorMutationOptions = Apollo.BaseMutationOptions<
  SectorsGrid_UpdateCustomSectorMutation,
  SectorsGrid_UpdateCustomSectorMutationVariables
>;
export const SectorsGrid_DeleteCustomSectorDocument = gql`
  mutation SectorsGrid_DeleteCustomSector($id: ID!) {
    deleteCustomSector(input: { id: $id }) {
      customSector {
        ...Shared_CustomSectorParts
      }
    }
  }
  ${Shared_CustomSectorPartsFragmentDoc}
`;
export type SectorsGrid_DeleteCustomSectorMutationFn = Apollo.MutationFunction<
  SectorsGrid_DeleteCustomSectorMutation,
  SectorsGrid_DeleteCustomSectorMutationVariables
>;

/**
 * __useSectorsGrid_DeleteCustomSectorMutation__
 *
 * To run a mutation, you first call `useSectorsGrid_DeleteCustomSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectorsGrid_DeleteCustomSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectorsGridDeleteCustomSectorMutation, { data, loading, error }] = useSectorsGrid_DeleteCustomSectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSectorsGrid_DeleteCustomSectorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectorsGrid_DeleteCustomSectorMutation,
    SectorsGrid_DeleteCustomSectorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectorsGrid_DeleteCustomSectorMutation,
    SectorsGrid_DeleteCustomSectorMutationVariables
  >(SectorsGrid_DeleteCustomSectorDocument, options);
}
export type SectorsGrid_DeleteCustomSectorMutationHookResult = ReturnType<
  typeof useSectorsGrid_DeleteCustomSectorMutation
>;
export type SectorsGrid_DeleteCustomSectorMutationResult =
  Apollo.MutationResult<SectorsGrid_DeleteCustomSectorMutation>;
export type SectorsGrid_DeleteCustomSectorMutationOptions = Apollo.BaseMutationOptions<
  SectorsGrid_DeleteCustomSectorMutation,
  SectorsGrid_DeleteCustomSectorMutationVariables
>;
