import { numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { DatalabScreens } from '../../datalab/constants';
import PopoverContentTable from '../../shared/components/PopoverContentTable';
import { type Calendar_OfferingFieldsFragment } from '../graphql';

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const ShareholderRenderer: React.FC<Props> = ({ offering }) => (
  <Popover
    placement="left"
    variant="TOOLTIP"
    content={
      <PopoverContentTable alignAll="right" alignFirstColumn="left">
        <thead>
          <tr>
            <th>Shareholder</th>
            <th colSpan={2}>Pre-offering shares</th>
            <th colSpan={2}>Post-offering shares</th>
          </tr>
        </thead>
        <tbody>
          {offering.attributes?.shareholders?.map((s, idx) => (
            <tr key={idx}>
              <td>{s.name}</td>
              <td>{numericUtil.formatInteger(s.preOfferingOwnership)}</td>
              <td>{numericUtil.formatPercents(s.pctPreOfferingOwnership, 1)}</td>
              <td>{numericUtil.formatInteger(s.postOfferingOwnership)}</td>
              <td>{numericUtil.formatPercents(s.pctPostOfferingOwnership, 1)}</td>
            </tr>
          ))}
        </tbody>
      </PopoverContentTable>
    }
  >
    <Link
      to={routeFactory.datalab.getUrlPath({
        screen: DatalabScreens.MARKET_PULSE,
        type: 'table',
        query: {
          sponsor: offering.attributes?.primaryShareholderFirmId,
        },
      })}
    >
      {offering.attributes?.primaryShareholderName}
    </Link>
  </Popover>
);

export default ShareholderRenderer;
