import { Box, Icon, LinkButton, timeUtil } from '@cmg/common';
import React from 'react';

import { Pill } from '../../../../../obsolete/components/ui';
import { UUID } from '../../../../../types/common';
import {
  OfferingNote,
  OfferingNoteCreate,
  OfferingNoteUpdate,
} from '../../../../../types/domain/offering/offering-note';
import OfferingNoteModal from './OfferingNoteModal';
import {
  SNoteContent,
  SNoteFooter,
  SNoteHeader,
  SPillContainer,
  SWrapper,
} from './OfferingNotes.styles';

export type Props = {
  offeringId: UUID;
  offeringNotes: OfferingNote[];
  createOfferingNote: (args: { offeringId: UUID; offeringNote: OfferingNoteCreate }) => void;
  updateOfferingNote: (args: {
    offeringId: UUID;
    noteId: UUID;
    offeringNote: OfferingNoteUpdate;
  }) => void;
  deleteOfferingNote: (args: { offeringId: UUID; noteId: UUID }) => void;
};

type State = {
  activeOfferingNote?: OfferingNote;
  showModal: boolean;
};

export default class OfferingNotes extends React.Component<Props, State> {
  state = {
    activeOfferingNote: undefined,
    showModal: false,
  };

  onClose = () => {
    this.setState({
      activeOfferingNote: undefined,
      showModal: false,
    });
  };

  onOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  onEdit = offeringNote => {
    this.setState({
      showModal: true,
      activeOfferingNote: offeringNote,
    });
  };

  onUpdate = (offeringNote: OfferingNoteUpdate) => {
    const { updateOfferingNote, offeringId } = this.props;

    updateOfferingNote({
      offeringId,
      noteId: offeringNote.id,
      offeringNote,
    });

    this.onClose();
  };

  onCreate = (offeringNote: OfferingNoteCreate) => {
    const { createOfferingNote, offeringId } = this.props;

    createOfferingNote({ offeringId, offeringNote });

    this.onClose();
  };

  onDelete = () => {
    const { deleteOfferingNote, offeringId } = this.props;
    const { activeOfferingNote } = this.state;

    if (activeOfferingNote) {
      deleteOfferingNote({
        offeringId,
        // @ts-ignore activeOfferingNote has been checked but TSC still complains here
        noteId: activeOfferingNote.id,
      });
    }

    // TODO close after success
    this.onClose();
  };

  render() {
    const { offeringNotes } = this.props;
    const { activeOfferingNote, showModal } = this.state;

    return (
      <Box>
        <SWrapper>
          <OfferingNoteModal
            onHide={this.onClose}
            onDelete={this.onDelete}
            onCreate={this.onCreate}
            onUpdate={this.onUpdate}
            offeringNote={activeOfferingNote}
            show={showModal}
          />

          <Box.Header title="Notes">
            <LinkButton size="small" onClick={this.onOpen}>
              <Icon size="lg" name="plus" />
            </LinkButton>
          </Box.Header>

          <Box.Content>
            <ul>
              {offeringNotes.map(offeringNote => (
                <li key={offeringNote.id}>
                  <SNoteHeader>
                    <SPillContainer>
                      <Pill type="primary" uppercase>
                        {offeringNote.subject}
                      </Pill>
                    </SPillContainer>
                    <LinkButton size="small" inline onClick={() => this.onEdit(offeringNote)}>
                      <Icon name="edit" size="lg" />
                    </LinkButton>
                  </SNoteHeader>
                  <SNoteContent>{offeringNote.note}</SNoteContent>
                  <SNoteFooter>
                    {`Last saved by ${offeringNote.lastEditedBy} ${timeUtil.formatAsDisplayDateTime(
                      offeringNote.lastEditedAt
                    )}`}
                  </SNoteFooter>
                </li>
              ))}
            </ul>
          </Box.Content>
        </SWrapper>
      </Box>
    );
  }
}
