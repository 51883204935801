import styled, { css } from 'styled-components/macro';

import SummaryHeaderItem from '../shared/SummaryHeaderItem';
import HeaderItem from './HeaderItem';

type TextAlignProps = {
  textAlign?: 'left' | 'right' | 'center';
};

const textAlignCss = css<TextAlignProps>`
  text-align: ${({ textAlign = 'left' }) => textAlign};
`;

const parentColCss = css`
  color: ${({ theme }) => theme.color.gray6};
  cursor: default;
  text-align: center;
  border-bottom: 0;
`;

type HeaderItemProps = TextAlignProps & { isParentColField?: boolean };

export const StyledSummaryHeaderItem = styled(SummaryHeaderItem)<HeaderItemProps>`
  ${textAlignCss}
  ${({ isParentColField }) => (isParentColField ? parentColCss : '')};
`;

export const StyledHeaderItem = styled(HeaderItem)<HeaderItemProps>`
  ${textAlignCss}
  ${({ isParentColField }) => (isParentColField ? parentColCss : '')};
`;

export const SColumn = styled.div<TextAlignProps & { isOddRow?: boolean }>`
  ${textAlignCss}

  ${({ isOddRow, theme }) =>
    isOddRow &&
    css`
      background: ${theme.background.color.light};
    `};
`;

export const STableWrapper = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  margin: 8px 0 8px 10px;
`;

export const SNoContent = styled.div`
  text-align: left;
  padding: 14px;
  border-bottom: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.border.color.light};
`;

export const SHeaderItemGroup = styled.div<{ firstColumn?: boolean }>`
  display: inline-block;
  background: ${({ theme }) => theme.color.gray10};

  ${({ firstColumn, theme }) =>
    firstColumn &&
    css`
      /* fix: for not correctly recounted column widths */
      width: 1em !important;
      overflow: visible;
      z-index: 10;

      &::before {
        position: absolute;
        content: ' ';
        background-color: ${theme.color.gray10};
        width: 100vw;
        min-height: 30px;
        left: 0;
        top: 0;
        z-index: -1;
      }
    `};
`;

export const SGroupLabel = styled.span<{ type: 'name' | 'value' }>`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  text-transform: uppercase;

  ${({ type, theme }) => css`
    color: ${type === 'name' ? theme.color.blue12 : theme.text.color.dark};
  `}
`;
