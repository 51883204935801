import styled from 'styled-components/macro';

export const SNotificationsFilterButton = styled.div`
  align-self: center;
  cursor: pointer;
  position: relative;
`;

export const SNotificationFilterBadge = styled.span`
  background-color: ${({ theme }) => theme.brand.color.light};
  color: ${({ theme }) => theme.text.color.white};
  font-size: ${({ theme }) => theme.text.size.small};
  position: absolute;
  left: 50%;
  bottom: 65%;
  padding: 0 6px;
  border-radius: 10px;
  line-height: 18px;
  height: 16px;
`;
