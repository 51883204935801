import type { ApolloError } from '@apollo/client/errors';
import { loggerUtil, Option, ToastManager } from '@cmg/common';
import memoize from 'memoize-one';

import { CustomSector } from '../../../graphql/__generated__';

export type GraphqlCustomSectors = {
  id: string;
  name?: string | null;
  children: { id: string; name?: string | null }[];
}[];

type CustomSectorFields = Pick<CustomSector, 'id' | 'name'>;

export const mapSectorsToOptions: (
  sectors: (CustomSectorFields & { children: CustomSectorFields[] })[] | null | undefined
) => Omit<Option, 'label'>[] = memoize((sectors: GraphqlCustomSectors) =>
  !sectors
    ? []
    : sectors
        .filter(s => !!s.name)
        .map(s => ({
          title: s.name!,
          value: s.id,
          children: (s.children || [])
            .filter(s => !!s.name)
            .map(sub => ({
              title: sub.name!,
              value: sub.id,
            })),
        }))
);

export const onAssignSectorError = (error: ApolloError) => {
  const messages = error.graphQLErrors
    .map(({ extensions, message }) =>
      extensions ? extensions.errors.map(e => e.message) : message
    )
    .flat();
  loggerUtil.error(error);
  ToastManager.error(
    `Sector assignment failed: ${messages.length ? messages.join('; ') : error.message}`
  );
};
