import styled, { css } from 'styled-components/macro';

export type HeaderProps = {
  textAlign?: string;
  width?: string;
  minWidth?: number;
  fullRightBorder?: boolean;
};

export const STableHeader = styled.th<HeaderProps>`
  min-width: ${({ minWidth }) => minWidth ?? 0}px;
  width: ${({ width }) => width};
  text-align: ${({ textAlign }) => textAlign ?? 'right'} !important;
  text-transform: none !important;
  vertical-align: middle !important;
  position: relative;
  min-height: 42px;

  ${({ theme, fullRightBorder }) =>
    fullRightBorder
      ? css`
          border-right: ${theme.border.width.small} solid ${theme.border.color.dark};
          border-bottom: ${theme.border.width.small} solid ${theme.border.color.dark};
        `
      : css`
          &::after {
            content: '';
            position: absolute;
            top: 20%;
            bottom: 20%;
            right: 0;
            width: ${theme.border.width.small};
            background-color: ${theme.border.color.dark};
            height: calc(100% - 40%);
          }
          border-bottom: ${theme.border.width.small} solid ${theme.border.color.dark};
        `}
`;

export type RowProps = {
  textAlign?: string;
  rightBorder?: boolean;
  isTotals?: boolean;
  topDivider?: boolean;
  blur?: string;
};

export const STableCell = styled.td<RowProps>`
  min-height: 42px;
  text-align: ${({ textAlign }) => textAlign ?? 'right'} !important;
  white-space: nowrap;
  ${({ theme, rightBorder, topDivider, blur }) =>
    css`
      border-right: ${
        rightBorder ? `${theme.border.width.small} solid ${theme.border.color.dark}` : null
      };
      border-top: ${
        topDivider
          ? `${theme.border.width.small} solid rgba(0, 0, 0, 0.3)`
          : theme.border.width.small + ' solid ' + theme.border.color.dark
      }};
      filter: ${blur ? `blur(${blur})` : null};
    `}

  ${({ theme, isTotals, blur }) =>
    isTotals &&
    css`
      background-color: ${isTotals ? '#EDF1F9' : null};
      font-weight: ${isTotals ? theme.text.weight.medium : null};
    `}
`;

export const SNoDataAvailable = styled.td`
  color: rgb(3, 5, 7, 0.55);
  min-height: 42px;
`;
