import React from 'react';
import styled from 'styled-components/macro';

const SMenuListItem = styled.li``;

const SMenuListItemButton = styled.button`
  background-color: transparent;
  border: none;
  margin: 5px 0;

  &:hover {
    color: ${({ theme }) => theme.color.blue1};
  }

  &:focus {
    box-shadow: none;
  }
`;

export const SMenuList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

export const MenuItem: React.FC<{ label: string; onClick: () => void }> = ({ label, onClick }) => (
  <SMenuListItem>
    <SMenuListItemButton
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      {label}
    </SMenuListItemButton>
  </SMenuListItem>
);
