import React from 'react';
import styled from 'styled-components/macro';

type Size = 'small' | 'medium';

const SHorizontalLine = styled.hr<{
  size: Size;
}>`
  height: ${({ size }) => `${size === 'small' ? '2px' : '5px'}`};
  background-color: ${({ theme }) => theme.background.color.light};
  width: 100%;
  border: none;
`;

type Props = {
  size?: Size;
};

const HorizontalLine: React.FC<Props> = ({ size = 'small' }) => <SHorizontalLine size={size} />;

export default HorizontalLine;
