import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum IpoMarketPerformanceButtons {
  OFFER_TO_MIDPOINT = 'OFFER_TO_MIDPOINT',
  PCT_MARKET_CAP = 'PCT_MARKET_CAP',
}

export const ipoMarketPerformanceButtonsConfig: ButtonsConfig = {
  [IpoMarketPerformanceButtons.OFFER_TO_MIDPOINT]: { label: 'Offer to Midpoint' },
  [IpoMarketPerformanceButtons.PCT_MARKET_CAP]: { label: 'Size % of Market Cap' },
};

export default getButtonConfigArray(ipoMarketPerformanceButtonsConfig);
