import { timeUtil } from '@cmg/common';
import React from 'react';

import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';

type Props = {
  userReportPartial: UserReportPartial;
};

const ReportLastModifiedOnCell = ({ userReportPartial }: Props) =>
  userReportPartial.lastModified ? (
    <td>{timeUtil.formatAsDisplayDate(userReportPartial.lastModified.date)}</td>
  ) : (
    <td />
  );

export default ReportLastModifiedOnCell;
