import styled from 'styled-components/macro';

import { SNumericInput } from '../../numeric-inputs/NumericInput.styles';

export const SValueInput = styled.div`
  display: inline-block;
  min-width: 200px;

  ${SNumericInput} .ant-input-number {
    height: 32px;
  }
  ${SNumericInput} .ant-input-number .ant-input-number-input {
    height: 30px;
  }
`;
