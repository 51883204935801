import styled from 'styled-components/macro';

export const InfoIconWrapper = styled.span`
  margin-left: 8px;
`;

export const PopoverContent = styled.div`
  padding: 0;
  white-space: nowrap;
`;
