import { Table } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

import { RadioButton } from '../../../../obsolete/components/ui/inputs';

export const SFieldValue = styled.div`
  height: 34px;
  line-height: 34px;
`;

export const StyledRadioButton = styled(RadioButton)`
  button {
    width: 90px;
  }
`;

export const StyledTable = styled(Table)<{ isEditing: boolean }>`
  margin-bottom: 20px;

  ${({ isEditing }) =>
    isEditing &&
    css`
      td {
        padding: 4px;
      }
    `}

  .select-wrapper {
    margin-bottom: 0;
  }

  tr {
    display: flex;

    td + td,
    th + th {
      padding-left: 0;
    }
  }

  td.action-column,
  th.action-column {
    padding: 4px 0 2px;
    width: 40px;
  }

  .value-column {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;

    .inputs-group {
      display: flex;

      > * {
        flex: 1 1;
      }

      input {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
`;

export const SFirmIndication = styled.div`
  .form-control-validation {
    position: static;
  }

  .w90 {
    width: 90px;
  }
`;

export const SButtonWrapper = styled.div`
  display: flex;
`;

export const SIoiType = styled.div`
  display: inline-block;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  padding-left: 5px;
  padding-top: 17px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.color.error};
`;

export const SBannerContent = styled.p`
  margin: 0 1em;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SLimitConfigContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SLimitType = styled.div`
  flex: 1;
`;

export const SLimitValue = styled.div`
  flex: 2;
`;
