import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { MixedChart, MixedChartProps } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors, transparent } from '../../../colors';
import afterMarketPerfOfferButtons from '../../../model/buttons/after-market-perf-offer-buttons';
import marketCapAdtvButtons, {
  MarketCapAdtvButtons,
} from '../../../model/buttons/market-cap-adtv-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type Datapoints = {
  points: {
    x: number;
    y: number;
    ticker: string;
    discount_to_last: number;
  }[];
  line: {
    x: number;
    y: number;
  }[];
};

type Props = {
  chartData?: {
    market_cap: {
      offer_to_current: Datapoints;
      offer_to_day: Datapoints;
      offer_to_month: Datapoints;
      offer_to_three_months: Datapoints;
      offer_to_six_months: Datapoints;
      offer_to_open: Datapoints;
    };
    adtv: {
      offer_to_current: Datapoints;
      offer_to_day: Datapoints;
      offer_to_month: Datapoints;
      offer_to_three_months: Datapoints;
      offer_to_six_months: Datapoints;
      offer_to_open: Datapoints;
    };
  };
};
type RawItem = {
  x: number;
  y: number;
  ticker?: string;
  discount_to_last?: number;
};

const transformData = (
  chartData: Props['chartData'],
  offerCategory: string,
  offerPeriod: string
): MixedChartProps['data'] | null => {
  if (!chartData) {
    return null;
  }

  const selectedChartData = chartData[offerCategory][offerPeriod];

  const data: MixedChartProps['data'] = {
    labels: selectedChartData.points.map(item => item.label),
    datasets: [
      {
        borderColor: transparent,
        data: selectedChartData.points.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
            ticker: item.ticker,
            discount_to_last: item.discount_to_last,
          })
        ),
        backgroundColor: barChartColors.lighter,
        pointBorderColor: barChartColors.lighter,
        pointBorderWidth: 0,
        pointRadius: 3,
        pointHoverRadius: 6,
        type: 'scatter',
      },
      {
        data: selectedChartData.line.map(
          (item): RawItem => ({
            x: item.x,
            y: item.y,
          })
        ),
        pointRadius: 0,
        backgroundColor: barChartColors.light,
        borderColor: barChartColors.light,
        type: 'line',
      },
    ],
  };

  return data;
};

const getOptions = (
  marketCapSelected: boolean,
  offerPeriodLabel: string
): MixedChartProps['options'] => {
  return {
    scales: {
      x: {
        title: {
          display: true,
          text: marketCapSelected ? '(Size as % of Market Cap)' : '(Multiple of ADTV)',
        },
        ticks: {
          callback: value => {
            if (typeof value === 'number') {
              return marketCapSelected
                ? numericUtil.formatPercents(value, 1)
                : numericUtil.formatMultipleFactor(value);
            }
            return value;
          },
        },
      },
      y: {
        ticks: {
          display: true,
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
        },
        title: {
          display: true,
          text: '(Aftermarket Performance)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title(tooltipItems) {
            const raw = tooltipItems[0].raw as RawItem;
            return raw.ticker ?? '';
          },
          label: tooltipItem => {
            const raw = tooltipItem.raw as RawItem;
            return [
              `${offerPeriodLabel}: ${numericUtil.formatPercents(tooltipItem.parsed.y, 1)}`,
              `${marketCapSelected ? 'Size as % of Market Cap' : 'Multiple of ADTV'}: ${
                marketCapSelected
                  ? numericUtil.formatPercents(tooltipItem.parsed.x, 1)
                  : numericUtil.formatMultipleFactor(tooltipItem.parsed.x)
              }`,
              `Discount to Last Trade: ${numericUtil.formatPercents(raw.discount_to_last)}`,
            ];
          },
        },
      },
    },
  };
};

const WidgetAftermarketPerformanceRelativeToSizing: React.FC<Props> = ({ chartData }) => {
  const [activeBtn, setActiveBtn] = React.useState(marketCapAdtvButtons[0]);
  const [offerActiveBtn, setOfferActiveBtn] = React.useState(afterMarketPerfOfferButtons[1]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  const handleOfferBtnChange = (btn: any) => {
    setOfferActiveBtn(btn);
  };

  const marketCapSelected = activeBtn.value === MarketCapAdtvButtons.MARKET_CAP;
  const chartDataTransformed = React.useMemo(
    () => transformData(chartData, activeBtn.value, offerActiveBtn.value),
    [chartData, activeBtn, offerActiveBtn]
  );
  const options = React.useMemo(
    () => getOptions(marketCapSelected, offerActiveBtn.label),
    [marketCapSelected, offerActiveBtn.label]
  );

  if (!chartDataTransformed) {
    return null;
  }

  return (
    <SDatalabChart>
      <Box.Header title="Aftermarket Performance Relative to Sizing" />

      <SButtonBox>
        <Toggler
          buttons={marketCapAdtvButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />

        <Toggler
          buttons={afterMarketPerfOfferButtons}
          activeButton={offerActiveBtn}
          onChange={handleOfferBtnChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <MixedChart data={chartDataTransformed} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetAftermarketPerformanceRelativeToSizing;
