import axios from 'axios';

/**
 * The datalab api client, via our api gateway
 * Unfortunately interceptors are set in Root.js instead of here due to some unknown circular dependency.
 **/
export const dlgwApiClient = axios.create({
  baseURL: `/dlgw/api/v1`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});
