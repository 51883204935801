import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetDocumentationData } from '../hooks/useGetDocumentationData';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';
import {
  StyledNewsTableContainer,
  StyledTableCell,
  StyledTextField,
} from './DocumentationWidget.styles';

type NewsTableProps = { news: { name: string; link: string }[] };

const SecNewsTable = ({ news }: NewsTableProps) => {
  const [newsSearchTerm, setNewsSearchTerm] = React.useState('');

  return news.length > 0 ? (
    <React.Fragment>
      <StyledTextField
        label="Search SEC News"
        placeholder="Search keywords..."
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
        value={newsSearchTerm}
        onChange={event => {
          setNewsSearchTerm(event.target.value);
        }}
      />
      <StyledNewsTableContainer>
        <Table>
          <TableBody>
            {news
              .filter(({ name }) => name.toLowerCase().includes(newsSearchTerm.toLowerCase()))
              .map(({ name, link }) => (
                <TableRow key={name}>
                  <StyledTableCell>
                    <Tooltip title="Opens in a new tab">
                      <a href={link} target="_blank" rel="noreferrer">
                        <Typography variant="highlight1">{name}</Typography>
                      </a>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledNewsTableContainer>
    </React.Fragment>
  ) : null;
};

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const DocumentationWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { documents, news } = useGetDocumentationData(offeringProfile);
  const title = 'Documentation';

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" textTransform="none" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          {!documents.length && !news.length ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <React.Fragment>
              <TableContainer>
                <Table>
                  <TableBody>
                    {documents.map(({ name, link }) => (
                      <TableRow key={name}>
                        <StyledTableCell>
                          <Tooltip title="Opens in a new tab">
                            <a href={link} target="_blank" rel="noreferrer">
                              <Typography variant="highlight1">{name}</Typography>
                            </a>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <SecNewsTable news={news} />
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DocumentationWidget;
