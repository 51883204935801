import styled, { css } from 'styled-components/macro';

export const StyledPerformanceValue = styled.div<{ variant: 'neutral' | 'positive' | 'negative' }>`
  ${props =>
    props.variant === 'neutral' &&
    css`
      color: ${({ theme }) => theme.text.color.dark};
    `}

  ${props =>
    props.variant === 'positive' &&
    css`
      color: ${({ theme }) => theme.text.color.positive};
    `}

  ${props =>
    props.variant === 'negative' &&
    css`
      color: ${({ theme }) => theme.text.color.negative};
    `}
`;
