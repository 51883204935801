import { PercentInput } from '@cmg/common';
import React from 'react';

type Props = {
  value?: number;
  onChange: (value: number | null) => void;
};

export const PercentInputControl: React.FC<Props> = ({ onChange, value }) => {
  const handleOnChange = newValue => {
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  return <PercentInput onChange={handleOnChange} value={value} />;
};

export default PercentInputControl;
