import { useAuth } from '@cmg/auth';
import { CenteredPage, PrimaryButton, urlUtil } from '@cmg/common';
import { loggedOutPageSelector } from '@cmg/e2e-selectors';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import routeFactory from '../../common/util/routeFactory';
import { LogoutReason } from '../shared/auth/constants';

export const SMessage = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  margin: 15px 0 40px 0;
`;
export const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 25px 0 12px;
`;

const mapStateToProps = (state, ownProps) => ({
  logoutReason: urlUtil.queryParse(ownProps.location.search).reason as LogoutReason | undefined,
});

export const logoutReasonLabels = {
  [LogoutReason.OTHER]: 'You have been successfully logged out',
  [LogoutReason.INACTIVITY_TIMEOUT]: 'You have been logged out due to inactivity',
  [LogoutReason.SESSION_EXPIRED]: 'Your session has expired',
};

export type Props = ReturnType<typeof mapStateToProps> & RouteComponentProps;

export const LoggedOutRouteComponent: React.FC<Props> = ({ history, logoutReason }) => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    // cannot be displayed if user is logged in because sidebar would show
    if (isLoggedIn) {
      history.push(routeFactory.root.routePath);
    }
  }, [isLoggedIn, history]);

  return (
    <CenteredPage testId={loggedOutPageSelector.testId}>
      {!logoutReason || logoutReason === LogoutReason.OTHER || !logoutReasonLabels[logoutReason] ? (
        <React.Fragment>
          <SHeading>{logoutReasonLabels[LogoutReason.OTHER]}</SHeading>
          <SMessage>For your security, we recommend you close your browser.</SMessage>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SHeading>{logoutReasonLabels[logoutReason]}</SHeading>
          <SMessage>Please log in again.</SMessage>
        </React.Fragment>
      )}
      <PrimaryButton
        variant="high-contrast"
        asComponent={Link}
        to={routeFactory.login.getUrlPath()}
        size="large"
        fullWidth
      >
        Return to Login Page
      </PrimaryButton>
    </CenteredPage>
  );
};

export default connect(mapStateToProps)(LoggedOutRouteComponent);
