import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Alert } from '@cmg/common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { registerOnUpdateAction as registerOnUpdateIoiTrackerAction } from '../../offering-dl/ioi-tracker/ducks';
import Spinner from '../../shared/spinner/Spinner';
import CompanyScreen from '../components/CompanyScreen';
import { useCompanyProfileQuery } from '../graphql/__generated__/CompanyProfile';
import { SContainer } from './CompanyContainer.styles';

export const CompanyContainer = () => {
  const { companyId } = useRouteMatch<{ companyId: string }>().params;

  const { data, loading, error, refetch } = useCompanyProfileQuery({
    variables: {
      id: companyId,
      // DLGW GQL implementation uses standard pattern for authorization by populating
      // the result error collection with any/all authorization failures. This is a temporary
      // solution as currently FE will show error UI when error collection is populated.
      hasIoiPermission: useCheckPermissions([permissionsByEntity.Ioi.READ]),
      hasFundIoiPermission: useCheckPermissions([permissionsByEntity.FundIoi.READ]),
      hasAtmPermission: useCheckPermissions([permissionsByEntity.ATM.READ]),
      hasSellDownPermission: useCheckPermissions([permissionsByEntity.ATMSelldown.READ]),
      hasCustomSectorsPermission: useCheckPermissions([permissionsByEntity.CustomSectors.READ]),
    },
    fetchPolicy: 'no-cache',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // since the IoiTracker is redux-based class component not supporting current patterns,
    // better to register its onUpdate on the CompanyProfile side than vice-versa
    dispatch(
      registerOnUpdateIoiTrackerAction(() => {
        refetch();
      })
    );
  }, [dispatch, refetch]);

  if (loading) {
    return <Spinner show fullScreen />;
  }

  if (error) {
    return (
      <SContainer>
        <Alert severity="error" withMargin>
          {error.message}
        </Alert>
      </SContainer>
    );
  }

  return <CompanyScreen company={data?.issuerById} error={null} />;
};
