import { checkPermissions, permissionsByEntity } from '@cmg/auth';
import { IconNameParts } from '@cmg/common';
import memoize from 'memoize-one';
import React from 'react';

import LabeledTag from '../../../../common/components/indicators/beta-tag/LabeledTag';
import routeFactory from '../../../../common/util/routeFactory';
import { AppSettings } from '../../../../config/appSettings';
import { OfferingType } from '../../../../types/domain/offering/constants';
import { isInternationalOfferingsOn } from '../../../datalab/model/utils';

export type SidebarConfigArgs = {
  userPermissions: string[];
  location: {
    pathname: string;
    query: { [key: string]: string };
  };
  params: { [key: string]: string };
  featureToggles: AppSettings['features'];
  showLiveAndFiled: boolean;
  isInternationalOffering: boolean;
  offeringType?: OfferingType;
};

export type SidebarConfigItem = {
  path?: string;
  paramsMatch?: string | string[];
  label: string;
  /** when labelComponent is provided it will be rendered instead of label, label will be used for filtering therefore still required */
  tag?: React.ReactNode;
  icon?: IconNameParts;
  key?: string;
  short?: string;
  children?: SidebarConfigItem[];
  isHidden?: (config: SidebarConfigArgs) => boolean;
  getPath?: (config: SidebarConfigArgs) => string;
  isActive?: (pathname: string) => boolean;
};

export enum SideBarMenuKey {
  MY_DASHBOARD = 'my-dashboard',
  CALENDAR = 'calendar',
  PRICED_CALENDAR = 'priced-calendar',
  DATALAB = 'datalab',
  US_ECM = 'us-ecm',
  GLOBAL_ECM = 'global-ecm',
  US_ATM = 'us-atm',
  CONVERTS = 'converts',
  MATRIX = 'matrix',
  AFTER_MARKET_MATRIX = 'after-market-matrix',
  IPO_STRUCTURE = 'ipos',
  IPO_PRICING = 'ipop',
  IPO_BACKLOCK = 'ipob',
  FOLLOW_ON_STRUCTURE = 'fos',
  FOLLOW_ON_PRICING = 'fop',
  UNDERWRITING_TERMS = 'underwriting-terms',
  UNDERWRITING_PROFILE = 'underwriting-profile',
  LOCK_UP_REPORT = 'lock-up-report',
  LEAGUE_TABLE = 'league-table',
  GLOBAL_LEAGUE_TABLE = 'global-league-table',
  SPONSOR_LEAGUE_TABLE = 'sponsor-league-table',
  MY_DATALAB = 'my-datalab',
  PARTICIPATION_SUMMARY = 'participation-summary',
  BROKER_ANALYSIS = 'broker-analysis',
  BROKER_PL = 'broker-pl',
  FUND_LEVEL_ANALYSIS = 'fund-level-analysis',
  OFFERING = 'offering',
  OFFERING_PROFILE = 'offering-profile',
  IOI_TRACKER = 'ioi-tracker',
  UNDERWRITER_CREDITS = 'underwriter-credits',
  SETTINGS = 'settings',
  MANAGE_COMPANIES = 'manage-companies',
  MANAGE_SECTORS = 'manage-sectors',
}

const sidebarConfig: SidebarConfigItem[] = [
  {
    key: SideBarMenuKey.MY_DASHBOARD,
    path: routeFactory.myDashboard.getUrlPath(),
    label: 'My Dashboard',
    icon: {
      variant: 'solid',
      name: 'tachometer-fast',
    },
  },
  {
    key: SideBarMenuKey.CALENDAR,
    path: routeFactory.calendar.getUrlPath(),
    label: 'Calendar',
    isHidden: ({ showLiveAndFiled }) => !showLiveAndFiled,
    icon: {
      variant: 'solid',
      name: 'calendar',
    },
  },
  {
    key: SideBarMenuKey.PRICED_CALENDAR,
    path: routeFactory.calendar.getUrlPath(),
    label: 'US Priced Calendar',
    isHidden: ({ showLiveAndFiled }) => showLiveAndFiled,
    icon: {
      variant: 'solid',
      name: 'calendar',
    },
  },
  {
    key: SideBarMenuKey.DATALAB,
    label: 'DataLab',
    icon: {
      variant: 'solid',
      name: 'chart-pie',
    },
    children: [
      {
        key: SideBarMenuKey.CONVERTS,
        path: routeFactory.datalabConvertsTable.routePath,
        label: 'U.S. Converts',
        short: 'CNVT',
        isActive: pathname => pathname.startsWith(routeFactory.datalabConvertsTable.routePath),
        isHidden: ({ userPermissions }) =>
          !checkPermissions(userPermissions, [permissionsByEntity.ConvertsOffering.READ]),
      },
      {
        key: SideBarMenuKey.US_ATM,
        path: routeFactory.datalabATMTable.routePath,
        label: 'U.S. ATM',
        short: 'USATM',
        isActive: pathname => pathname.startsWith(routeFactory.datalabATMTable.routePath),
        isHidden: ({ userPermissions }) =>
          !checkPermissions(userPermissions, [permissionsByEntity.ATM.READ]),
      },
      {
        key: SideBarMenuKey.GLOBAL_ECM,
        path: routeFactory.datalabGlobalEcmTable.routePath,
        label: 'Global ECM V2',
        short: 'GMRKT',
        isActive: pathname => pathname.startsWith(routeFactory.datalabGlobalEcmTable.routePath),
        isHidden: ({ featureToggles }) => !featureToggles.isGlobalEcmV2InDLOn,
      },
      {
        key: SideBarMenuKey.US_ECM,
        path: routeFactory.datalabMarketPulseChart.routePath,
        label: 'U.S. ECM',
        short: 'MRKT',
        isActive: pathname => pathname.startsWith(routeFactory.datalabMarketPulse.routePath),
      },
      {
        key: SideBarMenuKey.MATRIX,
        path: routeFactory.datalabCapitalRaisedMatrixChart.routePath,
        label: 'U.S. ECM Matrix',
        short: 'MTRX',
      },
      {
        key: SideBarMenuKey.IPO_STRUCTURE,
        path: routeFactory.datalabIpoStructureChart.routePath,
        label: 'IPO Structure',
        short: 'IPOS',
        isActive: pathname => pathname.startsWith(routeFactory.datalabIpoStructure.routePath),
      },
      {
        key: SideBarMenuKey.IPO_PRICING,
        path: routeFactory.datalabIpoPricingChart.routePath,
        label: 'IPO Pricing',
        short: 'IPOP',
        isActive: pathname => pathname.startsWith(routeFactory.datalabIpoPricing.routePath),
      },
      {
        key: SideBarMenuKey.IPO_BACKLOCK,
        path: routeFactory.datalabIpoBacklogTable.routePath,
        label: 'IPO Backlog',
        short: 'IPOB',
        isHidden: ({ showLiveAndFiled }) => !showLiveAndFiled,
      },
      {
        key: SideBarMenuKey.FOLLOW_ON_STRUCTURE,
        path: routeFactory.datalabFollowOnStructureChart.routePath,
        label: 'Follow-on Structure',
        short: 'FOS',
        isActive: pathname => pathname.startsWith(routeFactory.datalabFollowOnStructure.routePath),
      },
      {
        key: SideBarMenuKey.FOLLOW_ON_PRICING,
        path: routeFactory.datalabFollowOnPricingChart.routePath,
        label: 'Follow-on Pricing',
        short: 'FOP',
        isActive: pathname => pathname.startsWith(routeFactory.datalabFollowOnPricing.routePath),
      },
      {
        key: SideBarMenuKey.UNDERWRITING_TERMS,
        path: routeFactory.datalabUnderwritingTermsChart.routePath,
        label: 'Underwriting Terms',
        short: 'UWT',
        isActive: pathname => pathname.startsWith(routeFactory.datalabUnderwritingTerms.routePath),
      },
      {
        key: SideBarMenuKey.UNDERWRITING_PROFILE,
        path: routeFactory.datalabUnderwriterProfileChart.routePath,
        label: 'Underwriter Profile',
        short: 'UWP',
        isActive: pathname => pathname.startsWith(routeFactory.datalabUnderwriterProfile.routePath),
      },
      {
        key: SideBarMenuKey.LOCK_UP_REPORT,
        path: routeFactory.datalabLockupExpirationTable.routePath,
        label: 'Lock-up Report',
        short: 'LOCK',
      },
      {
        key: SideBarMenuKey.LEAGUE_TABLE,
        path: routeFactory.datalabLeagueTable.routePath,
        label: 'League Table',
        short: 'LT',
      },
      {
        key: SideBarMenuKey.GLOBAL_LEAGUE_TABLE,
        path: routeFactory.datalabGlobalLeagueTable.routePath,
        label: 'Global League Table',
        short: 'GLT',
        isActive: pathname => pathname.startsWith(routeFactory.datalabGlobalLeagueTable.routePath),
        tag: <LabeledTag label="NEW" />,
      },
      {
        key: SideBarMenuKey.AFTER_MARKET_MATRIX,
        path: routeFactory.datalabAftermarketMatrixTable.routePath,
        label: 'Aftermarket Matrix',
        short: 'AM',
      },
      {
        key: SideBarMenuKey.SPONSOR_LEAGUE_TABLE,
        path: routeFactory.datalabSponsorsLeagueTable.routePath,
        label: 'Sponsor League Table',
        short: 'SLT',
      },
    ],
  },
  {
    key: SideBarMenuKey.MY_DATALAB,
    label: 'My DataLab',
    icon: {
      variant: 'solid',
      name: 'chart-line',
    },
    isHidden: ({ userPermissions }) =>
      !checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]),
    children: [
      {
        key: SideBarMenuKey.PARTICIPATION_SUMMARY,
        path: routeFactory.datalabIoiParticipationChart.routePath,
        label: 'Participation Summary',
        short: 'PART',
        isActive: pathname => pathname.startsWith(routeFactory.datalabIoiParticipation.routePath),
      },
      {
        key: SideBarMenuKey.BROKER_ANALYSIS,
        path: routeFactory.datalabIoiLeagueTable.routePath,
        label: 'Broker Analysis',
        short: 'BROK',
      },
      {
        key: SideBarMenuKey.BROKER_PL,
        path: routeFactory.datalabPlLeagueTable.routePath,
        label: 'Broker P&L',
        short: 'PL',
      },
      {
        key: SideBarMenuKey.FUND_LEVEL_ANALYSIS,
        path: routeFactory.datalabFundIoiLeagueTable.routePath,
        isHidden: ({ userPermissions }) =>
          !checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ]),
        label: 'Fund Level Analysis',
        short: 'FUND',
      },
    ],
  },
  {
    key: SideBarMenuKey.OFFERING,
    label: 'Offering',
    icon: {
      variant: 'solid',
      name: 'building',
    },
    paramsMatch: [
      routeFactory.offerings.routePath,
      routeFactory.ioi.routePath,
      routeFactory.underwriterCredits.routePath,
    ],
    isHidden: ({ location }) =>
      // todo: use match function from react-router and routeFactory
      !location.pathname.includes('/offerings/') &&
      !location.pathname.includes('/ioi/') &&
      !location.pathname.includes(`/underwriter_credits/`),
    children: [
      {
        key: SideBarMenuKey.OFFERING_PROFILE,
        getPath: ({ params }) => routeFactory.offerings.getUrlPath({ id: params.offeringId }),
        label: 'Profile',
        short: 'PROF',
      },
      {
        key: SideBarMenuKey.IOI_TRACKER,
        getPath: ({ params }) => routeFactory.ioi.getUrlPath({ id: params.offeringId }),
        label: 'IOI Tracker',
        short: 'IOIS',
        isHidden: ({ userPermissions, offeringType }) =>
          !offeringType ||
          offeringType === OfferingType.CONVERTIBLE ||
          offeringType === OfferingType.ATM ||
          !checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]),
      },
      {
        key: SideBarMenuKey.UNDERWRITER_CREDITS,
        getPath: ({ params }) =>
          routeFactory.underwriterCredits.getUrlPath({ id: params.offeringId }),
        label: 'Underwriter Credits',
        short: 'SEC',
        isHidden: ({ userPermissions, isInternationalOffering, offeringType }) =>
          !offeringType ||
          offeringType === OfferingType.CONVERTIBLE ||
          offeringType === OfferingType.ATM ||
          !checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]) ||
          isInternationalOffering,
      },
    ],
  },
  {
    key: SideBarMenuKey.SETTINGS,
    label: 'Settings',
    icon: {
      variant: 'solid',
      name: 'sliders-h',
    },
    children: [
      {
        key: SideBarMenuKey.MANAGE_COMPANIES,
        path: routeFactory.settingsCompanies.getUrlPath(),
        isHidden: ({ userPermissions }) =>
          !checkPermissions(userPermissions, [permissionsByEntity.CustomSectors.READ]),
        label: 'Manage Companies',
        short: 'S&C',
      },
      {
        key: SideBarMenuKey.MANAGE_SECTORS,
        path: routeFactory.sectors.getUrlPath(),
        isHidden: ({ userPermissions }) =>
          !checkPermissions(userPermissions, [permissionsByEntity.CustomSectors.READ]),
        label: 'Manage Sectors',
        short: 'MSCT',
      },
    ],
  },
];

export const intlSidebarOverrideConfig: { [key in SideBarMenuKey]?: SidebarConfigItem } = {
  [SideBarMenuKey.US_ECM]: {
    label: 'Global ECM',
  },
  [SideBarMenuKey.MATRIX]: {
    label: 'Global ECM Matrix',
  },
  [SideBarMenuKey.PRICED_CALENDAR]: {
    label: 'Priced Calendar',
  },
};

const overrideIntlProps = (config: SidebarConfigItem): SidebarConfigItem => {
  const keys = Object.keys(intlSidebarOverrideConfig);
  const { children } = config;

  const newConfig = {
    ...config,
    // We need to also check for the children in case it has an overriding value
    children: !!children && children.length > 0 ? children.map(overrideIntlProps) : undefined,
  };

  return config.key && keys.includes(config.key)
    ? {
        ...newConfig,
        ...intlSidebarOverrideConfig[config.key],
      }
    : newConfig;
};

const getSideBarConfig = memoize(() => {
  const showInternational = isInternationalOfferingsOn();
  return showInternational ? sidebarConfig.map(overrideIntlProps) : sidebarConfig;
});

export default getSideBarConfig;
