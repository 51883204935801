import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  dtc.offering_confidential_filing_date,
  dtc.offering_public_filing_date,
  dtc.companies_issuer,
  dtc.companies_ticker,
  dtc.companies_cik,
  dtc.companies_cusip,
  dtc.issuer_isin,
  dtc.offering_entity_structure,
  dtc.offerings_sector,
  { ...dtc.offering_initial_ipo_range_low, label: 'Initial Range' },
  dtc.offering_revised_ipo_range_low,
  dtc.filing_details_size_in_dollars,
  dtc.offering_preliminary_prospectus_link,
  dtc.primary_shareholder_name,
];

export const visibleFields: string[] = [
  dtc.offering_confidential_filing_date.field,
  dtc.offering_public_filing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.offering_initial_ipo_range_low.field,
  dtc.offering_revised_ipo_range_low.field,
  dtc.filing_details_size_in_dollars.field,
  dtc.offering_preliminary_prospectus_link.field,
  dtc.primary_shareholder_name.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];
