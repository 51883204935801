import { MultiSelectField, Option } from '@cmg/common';
import React from 'react';

import { User } from '../../../../types/domain/user/user';
import { SFormGroup } from './UserSelectField.styles';

export type Props = {
  label: string;
  name: string;
  users: User[];
};

export const UserSelectField: React.FC<Props> = ({ name, label, users }) => {
  return (
    <SFormGroup>
      <MultiSelectField
        name={name}
        label={label}
        options={users.map(
          user =>
            ({
              value: user.id,
              label: user.username,
            } as Option)
        )}
        fullWidth
        withMargin
      />
    </SFormGroup>
  );
};

export default UserSelectField;
