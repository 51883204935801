import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { SPill } from './Pill.styles';

export const pillTypes = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  MEDIUM_BLUE: 'medium-blue',
  DARK_BLUE: 'dark-blue',
};

const Pill = ({ children, type, uppercase, className }) => (
  // Pill className is used by AgGridWrapper and
  <SPill withUppercase={uppercase} type={type} className={cn(className, 'pill')}>
    {children}
  </SPill>
);

Pill.propTypes = {
  children: PropTypes.node,
  uppercase: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(pillTypes)),
  className: PropTypes.string,
};

Pill.defaultProps = {
  type: 'default',
  children: ' ',
};

export default Pill;
