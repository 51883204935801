export enum CalendarCategory {
  FILED = 'FILED',
  LIVE = 'LIVE',
  PRICED = 'PRICED',
  POSTPONED = 'POSTPONED',
  LOCK_UP_EXPIRATION = 'LOCKUP',
  MY_OFFERINGS = 'MY_OFFERINGS',
  MY_OFFERINGS_WITH_ALLOCATIONS = 'MY_OFFERINGS_WITH_ALLOCATION',
  DRAFT = 'DRAFT',
}
