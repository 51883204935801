import { checkPermissions, permissionsByEntity, PublicAuthStateProps, withAuth } from '@cmg/auth';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  fetchDatalabOptionsActions,
  fetchOfferingActions,
  selectAdvisoryOptions,
  selectError as selectOfferingError,
  selectFundOptions,
  selectOffering,
} from '../../shared/ducks';
import IoiTrackerScreen from '../components/IoiTrackerScreen';
import {
  addFundActions,
  fetchFundsActions,
  fetchIoiActions,
  fetchIoiFundsActions,
  resetState,
  saveAllocationAndIoisActions,
  selectAllocation,
  selectError,
  selectFunds,
  selectIoiFunds,
  selectIoiOffering,
  selectIois,
  selectIoiType,
  updateIoiFundsActions,
} from '../ducks';

const mapStateToProps = state => ({
  ioiTrackerError: selectError(state),
  offeringError: selectOfferingError(state),
  funds: selectFunds(state),
  ioiFunds: selectIoiFunds(state),
  allocation: selectAllocation(state),
  offering: selectOffering(state),
  ioiOffering: selectIoiOffering(state),
  iois: selectIois(state),
  ioiType: selectIoiType(state),
  advisoryOptions: selectAdvisoryOptions(state),
  fundOptions: selectFundOptions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchIoiFunds: fetchIoiFundsActions.request,
      updateIoiFunds: updateIoiFundsActions.request,
      fetchIoi: fetchIoiActions.request,
      saveAllocationAndIois: saveAllocationAndIoisActions.request,
      fetchFunds: fetchFundsActions.request,
      addFund: addFundActions.request,
      fetchOffering: fetchOfferingActions.request,
      fetchDatalabOptions: fetchDatalabOptionsActions.request,
      resetState: resetState,
    },
    dispatch
  ),
});

type RouterProps = RouteComponentProps<{ offeringId: string }>;

type OwnProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouterProps & PublicAuthStateProps;

export class IoiTrackerContainerComponent extends React.Component<Props> {
  componentDidMount() {
    const { actions, match, userPermissions } = this.props;

    this.fetchData(match.params.offeringId, userPermissions);
    actions.fetchDatalabOptions();
  }

  componentDidUpdate(prevProps: Props) {
    const { actions, match, userPermissions } = this.props;

    // reload if offeringId param changes
    if (match.params.offeringId && prevProps.match.params.offeringId !== match.params.offeringId) {
      this.fetchData(match.params.offeringId, userPermissions);
      actions.fetchDatalabOptions();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  fetchData(offeringId, userPermissions) {
    const { actions } = this.props;

    actions.fetchOffering({ offeringId });
    actions.fetchIoi(offeringId);

    if (checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ])) {
      actions.fetchFunds();
      actions.fetchIoiFunds(offeringId);
    }
  }

  render() {
    const {
      funds,
      ioiFunds,
      allocation,
      ioiOffering,
      iois,
      ioiType,
      match,
      userPermissions,
      actions,
      offering,
      offeringError,
      ioiTrackerError,
    } = this.props;
    const { offeringId } = match.params;
    const error = offeringError || ioiTrackerError;

    if (!error && (!ioiOffering || !offering)) {
      return null;
    }

    // TODO: move this permission check into child/nested components
    // Should be done as a part of refactoring IoiTrackerScreen
    const canManageIOI = checkPermissions(userPermissions, [permissionsByEntity.Ioi.FULL]);
    const canViewFundIOIs = checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ]);
    const canManageFundIOIs = checkPermissions(userPermissions, [permissionsByEntity.FundIoi.FULL]);
    const fundIoisPermissions = {
      canViewFundIOIs,
      canManageFundIOIs,
    };

    return (
      <IoiTrackerScreen
        error={error}
        funds={funds}
        ioiFunds={ioiFunds}
        allocation={allocation}
        ioiOffering={ioiOffering}
        offering={offering}
        iois={iois}
        ioiType={ioiType}
        offeringId={offeringId}
        canEdit={canManageIOI}
        canManageFirmIois={canManageIOI}
        fundIoisPermissions={fundIoisPermissions}
        saveAllocationAndIois={actions.saveAllocationAndIois}
        updateIoiFunds={actions.updateIoiFunds}
        addFund={actions.addFund}
      />
    );
  }
}

const IoiTrackerContainerWithAuth = withAuth(IoiTrackerContainerComponent);

export default connect(mapStateToProps, mapDispatchToProps)(IoiTrackerContainerWithAuth);
