import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { DatalabScreens } from '../../constants';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';

export const columns: DatalabTableColumn[] = [
  {
    ...dtc.underwriter_name,
    renderer: (value, row) => (
      <Link
        to={routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.UNDERWRITER_OFFERINGS,
          type: 'table',
          query: {
            underwriter: row.id,
          },
        })}
      >
        {value}
      </Link>
    ),
  },
  dtc.book_offerings,
  dtc.non_book_offerings,
  { ...dtc.offerings, label: 'Total Offerings' },
  { ...dtc.offerings_volume, fixedMinWidth: 95 },
  { ...dtc.underwritten_volume, fixedMinWidth: 95 },
  dtc.book_fees,
  dtc.non_book_fees,
  dtc.min,
  dtc.mean,
  dtc.median,
  dtc.max,
  dtc.fees,
  dtc.wallet_share,
];

export const staticFields = [dtc.underwriter_name.field];
