import { Icon } from '@cmg/common';
import React from 'react';
import styled, { css } from 'styled-components/macro';

type StyleVariant = 'TRANSPARENT' | 'LIGHT';

export const SLoadingWrapper = styled.div<{ variant?: StyleVariant }>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ theme, variant = 'LIGHT' }) => css`
    color: ${theme.background.color.dark};
    background-color: ${theme.background.color.light};

    ${variant === 'TRANSPARENT' &&
    css`
      background-color: transparent;
    `}
  `}
`;

type Props = {
  message: string;
  variant?: StyleVariant;
};

const LoadingOverlay: React.FC<Props> = ({ message, variant }) => (
  <SLoadingWrapper variant={variant}>
    <Icon name="spinner-third" spin size="3x" />
    <h3>{message}</h3>
  </SLoadingWrapper>
);

export default LoadingOverlay;
