import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components/macro';

import { CMGTheme } from '../../../types/vendor/styled-components/styled-components';
import SectorsModal, { OnSubmit } from '../../settings/sectors/sectors-modal/SectorsModal';
import { selectUserSettings } from '../ducks';
import { useShared_CustomSectorListQuery } from './graphql/__generated__/Shared_CustomSectors';
import SectorPickerComponent from './SectorPicker';
import SectorToggleComponent from './SectorToggle';

const mapStateToProps = state => ({
  userSettings: selectUserSettings(state),
});

type OwnProps = { onSubmit: OnSubmit; isSubmitting: boolean; theme: CMGTheme };
type SectorToggleType = React.FC;
type SectorPickerType = React.FC<{ sector?: string | null; customSector?: string | null }>;
type RenderProps = {
  SectorToggle: SectorToggleType;
  SectorPicker: SectorPickerType;
  userPermissions: string[];
};
type ChildProps = OwnProps & { children: (renderProps: RenderProps) => React.ReactNode };
export type Props = ChildProps & ReturnType<typeof mapStateToProps>;

/**
 * RenderProp Component giving prepared components, props and methods for switching and updating custom-sectors
 */
export const SectorProviderComponent: React.FC<Props> = ({
  children,
  onSubmit,
  isSubmitting,
  userSettings,
  theme,
}) => {
  const { userPermissions } = useAuth();
  const [useCustomSectors, setUseCustomSectors] = useState(!!userSettings.useCustomSectors);
  const [showModal, setShowModal] = useState(false);

  const canReadCustomSectors = checkPermissions(userPermissions, [
    permissionsByEntity.CustomSectors.READ,
  ]);
  const { data: sectorListData } = useShared_CustomSectorListQuery({
    skip: !canReadCustomSectors,
  });
  const sectors = sectorListData?.customSectors?.items ?? [];

  const handleToggle = (useCustomSectors: boolean) => setUseCustomSectors(useCustomSectors);

  const pickerProps = { useCustomSectors, userPermissions, onPick: () => setShowModal(true) };
  const SectorPicker: SectorPickerType = ({ sector, customSector }) => {
    return <SectorPickerComponent {...pickerProps} sector={sector} customSector={customSector} />;
  };

  const SectorToggle: SectorToggleType = () =>
    !canReadCustomSectors ? (
      <React.Fragment>Sector</React.Fragment>
    ) : (
      <SectorToggleComponent
        value={useCustomSectors}
        onChange={handleToggle}
        color={theme.color.blue2}
      />
    );

  return (
    <React.Fragment>
      {children({ SectorToggle, SectorPicker, userPermissions })}
      <SectorsModal
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        sectors={sectors || undefined}
        onHide={() => setShowModal(false)}
        show={showModal}
      />
    </React.Fragment>
  );
};

const ConnectedSectorProvider = connect(mapStateToProps)(SectorProviderComponent);

export default withTheme(ConnectedSectorProvider);
