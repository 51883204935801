import { Box, Column, numericUtil, Row } from '@cmg/common';
import { followOnPricingSelector, widgetPerformanceHeatMapBySector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { FollowOnPricing } from '../../../../types/domain/datalab/follow-on-pricing';
import { selectFollowOnPricingCharts } from '../../ducks';
import SingleVerticalBarChart from '../widgets/charts/SingleVerticalBarChart';
import WidgetAftermarketPerformanceRelativeToPricing from '../widgets/WidgetAftermarketPerformanceRelativeToPricing/WidgetAftermarketPerformanceRelativeToPricing';
import WidgetAftermarketPerformanceRelativeToSizing from '../widgets/WidgetAftermarketPerformanceRelativeToSizing/WidgetAftermarketPerformanceRelativeToSizing';
import WidgetBarMeanMedianPercentage from '../widgets/WidgetBarMeanMedianPercentage/WidgetBarMeanMedianPercentage';
import WidgetDiscountByOfferingType from '../widgets/WidgetDiscountByOfferingType/WidgetDiscountByOfferingType';
import WidgetFirstDayVolumeDividedByDealShares from '../widgets/WidgetFirstDayVolumeDividedByDealShares/WidgetFirstDayVolumeDividedByDealShares';
import WidgetOfferingDiscountsBySize from '../widgets/WidgetOfferingDiscountsBySize/WidgetOfferingDiscountsBySize';
import WidgetPerformanceHeatMap from '../widgets/WidgetPerformanceHeatMap/WidgetPerformanceHeatMap';

type Props = {
  datalab: FollowOnPricing;
};

export class FollowOnPricingScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;

    return (
      <div data-test-id={followOnPricingSelector.testId}>
        <Row>
          <Column xs={24}>
            <Box>
              <WidgetDiscountByOfferingType
                mean={datalab.mean_discount_to_last_trade_by_offering_type}
                median={datalab.median_discount_to_last_trade_by_offering_type}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetOfferingDiscountsBySize
                fromFilingData={datalab.file_to_offer_discount}
                lastTradeData={datalab.discount_to_last_trade}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetBarMeanMedianPercentage
                chartData={datalab.all_in_cost}
                title="All In Cost"
                xScaleLabel="Offering Size"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPerformanceHeatMap
                breakDownBy="sector"
                breakDownLabel="Sector"
                chartData={datalab.performance_heat_map_by_sector}
                testId={widgetPerformanceHeatMapBySector.testId}
                title="Performance Heatmap By Sector"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPerformanceHeatMap
                breakDownBy="offering_type"
                breakDownLabel="Follow-on Type"
                chartData={datalab.performance_heat_map_by_offering_type}
                title="Performance Heatmap By Type"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <SingleVerticalBarChart
                rawData={datalab.first_day_turnover}
                title="First Day Turnover By Sector"
                yLabelFormatter={numericUtil.formatMultipleFactor}
                valueFormatter={value => numericUtil.formatNumber(value, 1)}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetFirstDayVolumeDividedByDealShares
                chartData={datalab.first_day_volume_divided_by_deal_shares}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetAftermarketPerformanceRelativeToSizing
                chartData={datalab.after_market_relative_to_sizing}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetAftermarketPerformanceRelativeToPricing
                chartData={datalab.after_market_relative_to_pricing}
              />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectFollowOnPricingCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(FollowOnPricingScreenComponent);
