import { Popover } from '@cmg/common';
import React from 'react';

import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { managerSortFn } from '../../../shared/model/utils';
import { ManagerAttributes } from '../offerings-report-table/types';
import { StyledManagers } from './ManagersRenderer.styles';

export type Props = Readonly<{
  managers: ManagerAttributes[];
}>;

const ManagersRenderer: React.FC<Props> = ({ managers }) => {
  const sortedManagers = managers.slice().sort(managerSortFn);
  return sortedManagers.length > 0 ? (
    <Popover
      variant="TOOLTIP"
      placement="top"
      content={
        <PopoverContentTable>
          <tbody>
            {sortedManagers.map((m, idx) => (
              <tr key={idx}>
                <td>{m.name}</td>
              </tr>
            ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      <StyledManagers>{sortedManagers.map(manager => manager.name).join(', ')}</StyledManagers>
    </Popover>
  ) : (
    <React.Fragment>-</React.Fragment>
  );
};

export default ManagersRenderer;
