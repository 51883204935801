import { Popover } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledPopover = styled(Popover).attrs({
  hideArrow: true,
  variant: 'LIGHT',
  placement: 'left',
})`
  .ant-popover-inner {
    width: 600px;
    max-height: 500px;
    overflow-y: scroll;
  }
`;

export const STooltipTable = styled.table`
  width: 100%;
  font-size: ${({ theme }) => theme.text.size.xxs};
  text-align: left;

  thead tr th,
  tr td {
    max-width: 276px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;
    &:last-child {
      text-align: right;
    }
  }
  thead tr th {
    color: ${({ theme }) => theme.color.gray3};
    font-weight: ${({ theme }) => theme.text.weight.regular};
    border-right: ${({ theme }) => `2px solid ${theme.color.gray1}`};
  }
  .individual-investment {
    border-bottom: ${({ theme }) => `1px solid ${theme.color.gray1}`};
  }
  .total-row {
    background: ${({ theme }) => theme.color.gray1};
    font-weight: ${({ theme }) => theme.text.weight.medium};
  }
  .combined-amount-row {
    background: ${({ theme }) => theme.color.gray2};
    font-weight: ${({ theme }) => theme.text.weight.medium};
    border-bottom: ${({ theme }) => `1px solid ${theme.color.gray1}`};
  }
  .combined-row {
    &:last-child {
      border-bottom: ${({ theme }) => `1px solid ${theme.color.gray1}`};
    }
  }
  .subtitle {
    font-size: ${({ theme }) => theme.text.size.xxs};
    color: ${({ theme }) => theme.color.gray3};
    span {
      color: ${({ theme }) => theme.color.black};
      margin: 16px;
    }
  }

  .cornerstone-type {
    font-weight: ${({ theme }) => theme.text.weight.medium};
    padding: 12px 0 0 0;
  }

  .cornerstone-header {
    font-size: ${({ theme }) => theme.text.size.xs};
    padding: 0;
  }
`;

export const HeaderLabel = styled.td.attrs({
  colspan: 4,
})`
  text-align: left !important;
`;
