import { ApolloError } from '@apollo/client';
import { IGetRowsParams } from '@cmg/common';
import React from 'react';

import { SortEnumType } from '../../../../graphql/__generated__/index';
import { NestedSortInput, QueryVariables } from '../../../../graphql/types';

export const ROWS_PER_PAGE = 100;

export type OnDataUpdatedParams = {
  items?: any[];
  lastRow?: number;
  error?: ApolloError;
};
export type UseAGGridGetRowsResult = {
  queryVariables: QueryVariables;
  onDataUpdated: ({ items, lastRow, error }: OnDataUpdatedParams) => void;
  getRows: (params: IGetRowsParams) => void;
};

export type UseAGGridGetRowsParams = {
  getSortingModel: (colId: string, sortModel: SortEnumType) => NestedSortInput;
  defaultSortModel?: NestedSortInput;
};
export const useAGGridGetRows = ({
  getSortingModel,
  defaultSortModel = {},
}: UseAGGridGetRowsParams): UseAGGridGetRowsResult => {
  const agGridParamsRef = React.useRef<IGetRowsParams>();
  const [queryVariables, setQueryVariables] = React.useState<QueryVariables>({
    variables: {
      skip: 0,
      take: ROWS_PER_PAGE,
      // Since we have a initial state with defaultSortModel we need to include to avoid 2 different initial states
      order: [defaultSortModel],
    },
    pause: true,
  });

  const onDataUpdated = React.useCallback(({ items, lastRow, error }: OnDataUpdatedParams) => {
    if (error) {
      agGridParamsRef?.current?.failCallback();
      return;
    }
    agGridParamsRef?.current?.successCallback(items ?? [], lastRow);
  }, []);

  const getRows = React.useCallback(
    (params: IGetRowsParams) => {
      const { startRow, sortModel } = params;

      const order = [
        sortModel.length > 0
          ? {
              ...getSortingModel(
                sortModel[0].colId,
                sortModel[0].sort.toUpperCase() as SortEnumType
              ),
            }
          : defaultSortModel,
      ];

      agGridParamsRef.current = params;
      setQueryVariables({
        variables: {
          skip: startRow,
          take: ROWS_PER_PAGE,
          order,
        },
        pause: false,
      });
    },
    [defaultSortModel, getSortingModel]
  );
  return {
    queryVariables,
    onDataUpdated,
    getRows,
  };
};
