import {
  DatalabTableColumn,
  dtc,
  masterColumns,
  visibleLocalCurrFields,
} from '../datalabTableColumns';
import { categorizedColumns, groupedColumns } from './master-columns';

export const columns: DatalabTableColumn[] = masterColumns;

export const visibleFields: string[] = [
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.offerings_type.field,
  dtc.filing_details_offering_price.field,
  dtc.offering_size.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.filing_details_deal_pct_at_pricing_market_cap.field,
  dtc.filing_details_pct_secondary_shares.field,
  dtc.offerings_file_to_offer_discount.field,
  dtc.offerings_discount_to_last_trade.field,
  dtc.offering_gross_spread_pct.field,
  dtc.offerings_all_in_cost.field,
  dtc.offering_offer_to_open.field,
  dtc.offering_offer_to_1day.field,
  dtc.offering_offer_to_7day.field,
  dtc.offering_offer_to_30day.field,
  dtc.offering_offer_to_180day.field,
  dtc.offering_offer_to_current.field,
  dtc.left_lead_firm_name.field,
  dtc.offering_first_day_turnover.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export { categorizedColumns, groupedColumns, visibleLocalCurrFields };
