import { Stack, Tooltip, Typography } from '@cmg/design-system';
import React, { ReactElement } from 'react';

import {
  RowProps,
  StyledTableCell,
  StyledTableRow,
} from '../../../../../common/components/table/table.styles';
import { AtmStructureType, Currency } from '../../../../../graphql/__generated__';
import KeyValueItem from '../../../../offering-dl/offering-profile/profiles/shared/components/KeyValueItem';
import { dtc, TableKeys } from '../sell-down/AtmSellDownReport.model';
import {
  SBlurText,
  SSmallBlurText,
  SSmallTotalBlurText,
} from '../sell-down/AtmSellDownReport.styles';
import { Term } from './AtmBlurTable';

const totalStyle = { bgcolor: theme => '#EDF1F9' };

export const CantReadSelldownTooltip: React.FC = ({ children }) => (
  <Tooltip
    title="Please contact sales@cmgx.io to get access to ATM Selldown Data."
    variant="info"
    placement="top-end"
  >
    {children as ReactElement}
  </Tooltip>
);

export const TooltipSmallScreen: React.FC<RowProps & { showTooltip?: boolean }> = ({
  showTooltip = true,
  children,
}) =>
  showTooltip ? (
    <CantReadSelldownTooltip>{children ?? <SSmallBlurText />}</CantReadSelldownTooltip>
  ) : (
    <SSmallBlurText />
  );

export const TooltipTableCell: React.FC<RowProps & { showTooltip?: boolean }> = ({
  showTooltip = true,
  children,
  ...props
}) =>
  showTooltip ? (
    <CantReadSelldownTooltip>
      <StyledTableCell {...props}>{children}</StyledTableCell>
    </CantReadSelldownTooltip>
  ) : (
    <StyledTableCell {...props}>{children}</StyledTableCell>
  );

export const SmallAnnouncedRow: React.FC<{
  row: Term | undefined;
  latestAsOfDate?: string | null;
  publicFilingDate?: string | null | undefined;
  showAttributes?: boolean;
  pricingCurrency?: Currency | null;
  isAtmProgramSize: boolean;
}> = ({ showAttributes, publicFilingDate, pricingCurrency, isAtmProgramSize, row }) => (
  <Stack paddingX={2} paddingY={1}>
    <Typography variant="highlight1" paddingBottom={0.5}>
      Announced
    </Typography>
    <KeyValueItem
      size="xl"
      label="Announcement Date"
      value={
        showAttributes ? (
          dtc[TableKeys.announcementDate].render(
            {
              announcementDate: publicFilingDate,
              type: AtmStructureType.Announced,
            },
            { pricingCurrency }
          )
        ) : (
          <SSmallBlurText />
        )
      }
    />
    <KeyValueItem
      size="xl"
      label={isAtmProgramSize ? 'ATM Program Size' : 'ATM Program Size in Securities'}
      value={
        isAtmProgramSize
          ? dtc[TableKeys.programSize].render(
              {
                programSize: row?.atmProgramSize,
                type: AtmStructureType.Announced,
              },
              { pricingCurrency }
            )
          : dtc[TableKeys.programSizeInSecurities].render(
              {
                programSizeInSecurities: row?.atmProgramSizeInSecurities,
                type: AtmStructureType.Announced,
              },
              { pricingCurrency }
            )
      }
    />
    <KeyValueItem size="xl" label="ATM Program Remaining" value={<TooltipSmallScreen />} />
  </Stack>
);

export const SmallSelldownRow: React.FC = () => (
  <Stack paddingX={2} paddingY={1}>
    <Typography variant="highlight1" paddingBottom={0.5}>
      Selldown
    </Typography>
    <KeyValueItem size="xl" label="Announcement Date" value={<TooltipSmallScreen />} />
    <KeyValueItem size="xl" label="As of Date" value={<TooltipSmallScreen />} />
    <KeyValueItem size="xl" label="ATM Sales" value={<TooltipSmallScreen />} />
    <KeyValueItem size="xl" label="ATM Sales in Securities" value={<TooltipSmallScreen />} />
    <KeyValueItem size="xl" label="ATM Average Price" value={<TooltipSmallScreen />} />
    <KeyValueItem size="xl" label="ATM Program Remaining" value={<TooltipSmallScreen />} />
  </Stack>
);

export const SmallRevisedRow: React.FC<{
  showAttributes?: boolean;
  row: Term;
  pricingCurrency?: Currency | null;
  isAtmProgramSize: boolean;
}> = ({ showAttributes, pricingCurrency, isAtmProgramSize, row }) => (
  <Stack paddingX={2} paddingY={1}>
    <Typography variant="highlight1" paddingBottom={0.5}>
      Revised
    </Typography>
    <KeyValueItem
      size="xl"
      label="Announcement Date"
      value={
        showAttributes ? (
          dtc[TableKeys.announcementDate].render(
            {
              announcementDate: row.revisedDate,
              type: AtmStructureType.Announced,
            },
            { pricingCurrency }
          )
        ) : (
          <TooltipSmallScreen />
        )
      }
    />
    <KeyValueItem
      size="xl"
      label={isAtmProgramSize ? 'ATM Program Size' : 'ATM Program Size in Securities'}
      value={
        isAtmProgramSize
          ? dtc[TableKeys.programSize].render(
              {
                programSize: row?.atmProgramSize,
                type: AtmStructureType.Revised,
              },
              { pricingCurrency }
            )
          : dtc[TableKeys.programSizeInSecurities].render(
              {
                programSizeInSecurities: row?.atmProgramSizeInSecurities,
                type: AtmStructureType.Revised,
              },
              { pricingCurrency }
            )
      }
    />
    <KeyValueItem size="xl" label="ATM Program Remaining" value={<TooltipSmallScreen />} />
  </Stack>
);

export const SmallTotalRow: React.FC = () => (
  <Stack paddingX={2} paddingY={1} sx={totalStyle}>
    <Typography variant="highlight1" paddingBottom={0.5}>
      Total
    </Typography>
    <KeyValueItem
      size="xl"
      label="As of Date"
      value={
        <TooltipSmallScreen>
          <SSmallTotalBlurText />
        </TooltipSmallScreen>
      }
    />
    <KeyValueItem
      size="xl"
      label="ATM Sales"
      value={
        <TooltipSmallScreen>
          <SSmallTotalBlurText />
        </TooltipSmallScreen>
      }
    />
    <KeyValueItem
      size="xl"
      label="ATM Sales In Securities"
      value={
        <TooltipSmallScreen>
          <SSmallTotalBlurText />
        </TooltipSmallScreen>
      }
    />
    <KeyValueItem
      size="xl"
      label="ATM Program Remaining"
      value={
        <TooltipSmallScreen>
          <SSmallTotalBlurText />
        </TooltipSmallScreen>
      }
    />
  </Stack>
);

export const AnnouncedTableRow: React.FC<{
  row: Term | undefined;
  publicFilingDate?: string | null | undefined;
  showAttributes?: boolean;
  pricingCurrency?: Currency | null;
  isAtmProgramSize: boolean;
}> = ({ showAttributes, publicFilingDate, pricingCurrency, isAtmProgramSize, row }) => {
  return (
    <StyledTableRow>
      <TooltipTableCell align="left" showTooltip={!showAttributes}>
        {showAttributes ? 'Announced' : <SBlurText align="left" />}
      </TooltipTableCell>
      {showAttributes ? (
        <StyledTableCell align="left">
          {dtc[TableKeys.announcementDate].render(
            {
              announcementDate: publicFilingDate,
              type: AtmStructureType.Announced,
            },
            { pricingCurrency }
          )}
        </StyledTableCell>
      ) : (
        <TooltipTableCell blur="3px">
          <SBlurText align="left" />
        </TooltipTableCell>
      )}
      <TooltipTableCell />
      <TooltipTableCell />
      <TooltipTableCell />
      <TooltipTableCell rightBorder />
      {showAttributes ? (
        <StyledTableCell>
          {isAtmProgramSize
            ? dtc[TableKeys.programSize].render(
                {
                  programSize: row?.atmProgramSize,
                  type: AtmStructureType.Announced,
                },
                { pricingCurrency }
              )
            : dtc[TableKeys.programSizeInSecurities].render(
                {
                  programSizeInSecurities: row?.atmProgramSizeInSecurities,
                  type: AtmStructureType.Announced,
                },
                { pricingCurrency }
              )}
        </StyledTableCell>
      ) : (
        <TooltipTableCell>
          <SBlurText />
        </TooltipTableCell>
      )}
      <TooltipTableCell>
        <SBlurText />
      </TooltipTableCell>
    </StyledTableRow>
  );
};

export const SelldownTableRow: React.FC<{ showAttributes?: boolean }> = ({ showAttributes }) => (
  <StyledTableRow>
    <TooltipTableCell align="left" showTooltip={!showAttributes}>
      {showAttributes ? 'Selldown' : <SBlurText align="left" />}
    </TooltipTableCell>
    <TooltipTableCell align="left">
      <SBlurText align="left" />
    </TooltipTableCell>
    <TooltipTableCell align="left">
      <SBlurText align="left" />
    </TooltipTableCell>
    <TooltipTableCell>
      <SBlurText />
    </TooltipTableCell>
    <TooltipTableCell>
      <SBlurText />
    </TooltipTableCell>
    <TooltipTableCell rightBorder>
      <SBlurText />
    </TooltipTableCell>
    <TooltipTableCell></TooltipTableCell>
    <TooltipTableCell>
      <SBlurText />
    </TooltipTableCell>
  </StyledTableRow>
);

export const RevisedTableRow: React.FC<{
  showAttributes?: boolean;
  row: Term;
  pricingCurrency?: Currency | null;
  isAtmProgramSize: boolean;
}> = ({ showAttributes, pricingCurrency, isAtmProgramSize, row }) => {
  return (
    <StyledTableRow>
      <TooltipTableCell align="left" showTooltip={!showAttributes}>
        {showAttributes ? 'Revised' : <SBlurText align="left" />}
      </TooltipTableCell>
      {showAttributes ? (
        <StyledTableCell align="left">
          {dtc[TableKeys.announcementDate].render(
            {
              announcementDate: row.revisedDate,
              type: AtmStructureType.Revised,
            },
            { pricingCurrency }
          )}
        </StyledTableCell>
      ) : (
        <TooltipTableCell blur="3px">
          <SBlurText align="left" />
        </TooltipTableCell>
      )}
      <TooltipTableCell />
      <TooltipTableCell />
      <TooltipTableCell />
      <TooltipTableCell rightBorder />
      {showAttributes ? (
        <StyledTableCell>
          {isAtmProgramSize
            ? dtc[TableKeys.programSize].render(
                {
                  programSize: row.atmProgramSize,
                  type: AtmStructureType.Revised,
                },
                { pricingCurrency }
              )
            : dtc[TableKeys.programSizeInSecurities].render(
                {
                  programSizeInSecurities: row.atmProgramSizeInSecurities,
                  type: AtmStructureType.Revised,
                },
                { pricingCurrency }
              )}
        </StyledTableCell>
      ) : (
        <TooltipTableCell>
          <SBlurText />
        </TooltipTableCell>
      )}
      <TooltipTableCell>
        <SBlurText />
      </TooltipTableCell>
    </StyledTableRow>
  );
};

export const TotalTableRow: React.FC<{ showAttributes?: boolean }> = ({ showAttributes }) => (
  <StyledTableRow isTotals noBorderBottom>
    <TooltipTableCell align="left" isTotals noBorderBottom showTooltip={!showAttributes}>
      {showAttributes ? (
        <Typography fontWeight={500}>Total</Typography>
      ) : (
        <SBlurText isTotals align="left" />
      )}
    </TooltipTableCell>
    <TooltipTableCell align="left" isTotals noBorderBottom></TooltipTableCell>
    <TooltipTableCell align="left" isTotals noBorderBottom>
      <SBlurText isTotals align="left" noBorderBottom />
    </TooltipTableCell>
    <TooltipTableCell isTotals noBorderBottom>
      <SBlurText isTotals />
    </TooltipTableCell>
    <TooltipTableCell isTotals noBorderBottom>
      <SBlurText isTotals />
    </TooltipTableCell>
    <TooltipTableCell isTotals rightBorder noBorderBottom />
    <TooltipTableCell align="left" isTotals noBorderBottom></TooltipTableCell>
    <TooltipTableCell isTotals noBorderBottom>
      <SBlurText isTotals />
    </TooltipTableCell>
  </StyledTableRow>
);
