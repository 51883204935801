import { ExpandableSelectField, SelectField } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

const selectStyles = css`
  margin-right: 6px;
`;

export const StyledSelectField = styled(SelectField)`
  ${selectStyles}
`;

export const StyledExpandableSelectField = styled(ExpandableSelectField)`
  ${selectStyles}
`;

export const SFilterRule = styled.div`
  display: flex;
  margin: 3px 0;
`;
