import styled from 'styled-components/macro';

const NOTIFICATIONS_SLIDEOUT_WIDTH = '480px';

export const SSlideout = styled.div<{ show: boolean }>`
  background: ${({ theme }) => theme.background.color.white};
  max-width: 100vw;
  width: ${NOTIFICATIONS_SLIDEOUT_WIDTH};
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: ${({ theme }) => theme.zIndex.notificationsSlideout};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: ${({ show }) =>
    show ? `translateX(0)` : `translateX(${NOTIFICATIONS_SLIDEOUT_WIDTH})`};
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
`;
