import { Box, numericUtil } from '@cmg/common';
import React from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { isInternationalOfferingsOn } from '../../../model/utils';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      value: {
        marketFO: number;
        registeredBlocks: number;
        overnightFO: number;
        unregisteredBlocks: number;
        registeredDirect: number;
        rights: number;
      };
      amount: {
        marketFO: number;
        overnightFO: number;
        registeredDirect: number;
        registeredBlocks: number;
        unregisteredBlocks: number;
        rights: number;
      };
    };
  }[];
};

type RawItem = {
  y: number;
  x: string;
  count: number;
};

type ChartProps = BarChartProps<RawItem[]>;

const transformData = (chartData: Props['chartData'] = []): ChartProps['data'] => {
  const isIntlOn = isInternationalOfferingsOn();
  const data = {
    datasets: [
      ...[
        {
          label: 'Marketed FOs',
          data: chartData.map(
            (item): RawItem => ({
              y: item.values.value.marketFO,
              count: item.values.amount.marketFO,
              x: item.label,
            })
          ),
          backgroundColor: barChartColors.lighter,
        },
        {
          label: 'Overnight FOs',
          data: chartData.map(
            (item): RawItem => ({
              y: item.values.value.overnightFO,
              count: item.values.amount.overnightFO,
              x: item.label,
            })
          ),
          backgroundColor: barChartColors.light,
        },
        {
          label: 'Registered Blocks',
          data: chartData.map(
            (item): RawItem => ({
              y: item.values.value.registeredBlocks,
              count: item.values.amount.registeredBlocks,
              x: item.label,
            })
          ),
          backgroundColor: barChartColors.dark,
        },
        {
          label: 'Unregistered Blocks',
          data: chartData.map(
            (item): RawItem => ({
              y: item.values.value.unregisteredBlocks,
              count: item.values.amount.unregisteredBlocks,
              x: item.label,
            })
          ),
          backgroundColor: barChartColors.darker,
        },
      ],
      ...(isIntlOn
        ? [
            {
              label: 'Rights',
              data: chartData.map(
                (item): RawItem => ({
                  y: item.values.value.rights,
                  count: item.values.amount.rights,
                  x: item.label,
                })
              ),
              backgroundColor: barChartColors.grayer,
            },
          ]
        : []),
    ],
  };
  return data;
};

const getChartOptions = (): ChartProps['options'] => {
  return {
    scales: {
      y: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;
            const raw = tooltipItem.raw as RawItem;
            const { label } = dataset;

            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;
            return `${label ? `${label}: ` : ''}${numericUtil.formatPercents(parsed.y)}${
              countString ?? ''
            }`;
          },
        },
      },
    },
  };
};

const WidgetBarDiscountByType: React.FC<Props> = ({ chartData = [] }) => {
  const data = React.useMemo(() => transformData(chartData), [chartData]);
  const options = React.useMemo(() => getChartOptions(), []);

  return (
    <SDatalabChart>
      <Box.Content>
        <SChart>
          <BarChart data={data} options={options} barSizingMode="CMGCenteredBarPlugin" />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetBarDiscountByType;
