import { numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { UUID } from '../../../../types/common';
import { ManagerRole } from '../../../../types/domain/offering/constants';
import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { managerRoleLabels } from '../../../shared/constants/constants';
import { DatalabScreens } from '../../constants';

export type Props = {
  row: {
    left_lead_firm_id: UUID;
    left_lead_firm_name: string;
    underwriters:
      | {
          firm_name: string;
          firm_id: UUID;
          role: ManagerRole;
          economics_percent: number | null;
        }[]
      | null;
  };
};

// TODO: Same component is used in xc offering section, we should unify them
const LeftLeadFirmNameRenderer: React.FC<Props> = ({ row }) => {
  const url = routeFactory.datalab.getUrlPath({
    screen: DatalabScreens.UNDERWRITER_OFFERINGS,
    type: 'table',
    query: {
      underwriter: row.left_lead_firm_id,
    },
  });

  const hasEconomicsPercent =
    row.underwriters &&
    row.underwriters.some(u => u.economics_percent !== null && u.economics_percent !== undefined);

  return (
    <Popover
      variant="TOOLTIP"
      placement="left"
      content={
        <PopoverContentTable>
          <thead>
            <tr>
              <th>Underwriters</th>
              <th>Role</th>
              {hasEconomicsPercent && <th>Economics</th>}
            </tr>
          </thead>
          <tbody>
            {row.underwriters &&
              row.underwriters.map((u, idx) => (
                <tr key={idx}>
                  <td>{u.firm_name}</td>
                  <td>{managerRoleLabels[u.role]}</td>
                  {hasEconomicsPercent && (
                    <td>{numericUtil.formatPercents(u.economics_percent, 1)}</td>
                  )}
                </tr>
              ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      <Link to={url}>{row.left_lead_firm_name}</Link>
    </Popover>
  );
};

export default LeftLeadFirmNameRenderer;
