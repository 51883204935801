import { Icon, TextInput } from '@cmg/common';
import styled from 'styled-components/macro';

export const SSearchWrapper = styled.div`
  margin-left: 20px;
  position: relative;
`;

export const SMobileModal = styled.div`
  min-height: 150px;
`;

export const StyledTextInput = styled(TextInput)`
  input {
    height: 44px !important; /* prioritize before InputWrapper */
  }
`;

export const StyledSearchIcon = styled(Icon)`
  margin: 5px;
`;
