import { PrimaryButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const StyledSearchButton = styled(PrimaryButton)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const SFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  padding: 10px 20px 0;

  &:last-child {
    padding-bottom: 20px;
  }
`;

export const SInputGroup = styled.div`
  display: flex;
`;

export const SFieldWrapper = styled.div<{ justifyContent?: boolean }>`
  margin: 0 15px 0 0;

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      flex: 1;
      max-width: 300px;
    `}
`;

export const SSearch = styled.div`
  max-width: 250px;
`;
