import { MultiSelect, reduxUtil } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  fetchUnderwriterOptionsActions,
  selectUnderwriterOptions,
} from '../../../../../../../datalab/ducks';
import { StyledInfoBanner, StyledInfoIcon, SUnderwriterLabel } from './UnderwriterControl.styles';

export type Props = {
  value?: string[];
  onChange: (value?: string[]) => void;
  className?: string;
};

/**
 * UnderwriterControl async multiselect underwriter search
 * limited to 10 underwriters
 */
export const UnderwriterControl: React.FC<Props> = ({ value, onChange, className }) => {
  const { fetchUnderwriterOptions } = reduxUtil.useActions({
    fetchUnderwriterOptions: fetchUnderwriterOptionsActions.request,
  });
  const underwriterOptions: { value: string; label: string }[] =
    useSelector(selectUnderwriterOptions);

  React.useEffect(() => {
    !underwriterOptions.length && fetchUnderwriterOptions();
  }, [underwriterOptions.length, fetchUnderwriterOptions]);

  const handleOnChange = newValue => {
    newValue !== value && onChange(!newValue.length ? [] : newValue);
  };

  /**
   * When the user searches for 10 items we display an info message letting them
   * know that only 10 items are allowed.
   */
  const renderMenuList =
    value?.length === 9
      ? {
          renderMenuList: children => (
            <div>
              <StyledInfoBanner>
                <StyledInfoIcon name="info-circle" size="1x" />
                <div>Only 10 Underwriters can be selected.</div>
              </StyledInfoBanner>
              {children}
            </div>
          ),
        }
      : {};

  return (
    <MultiSelect<string>
      testId={myDashboard.underwriterSearch.testId}
      className={className}
      isSearchable={value && value?.length < 10}
      onChange={handleOnChange}
      value={value}
      options={underwriterOptions}
      maxDisplayedValues={1}
      renderMultiValueLabel={children => <SUnderwriterLabel>{children}</SUnderwriterLabel>}
      {...renderMenuList}
    />
  );
};
