import { loggerUtil } from '@cmg/common';
import React from 'react';
import { render } from 'react-dom';

import { getAppSettings } from './config/appSettings';
import Root from './features/core/Root';

const appSettings = getAppSettings();

loggerUtil.initDatadog({
  clusterEnvironment: appSettings.env.clusterEnvironment,
  datadogApplicationId: appSettings.logging.datadogApplicationId,
  datadogClientToken: appSettings.logging.datadogClientToken,
  serviceName: appSettings.logging.datadogServiceName,
  allowedTracingOrigin: appSettings.logging.datadogTracingOrigin,
  isDevelopment: appSettings.env.isDevelopment,
  version: appSettings.client.appVersionBuild,
});

loggerUtil.initMixpanel({
  token: appSettings.logging.mixPanelToken,
  isDevelopment: appSettings.env.isDevelopment || !appSettings.logging.mixPanelToken, // note: isDevelopment passed here translates to mixpanels debug mode
});

render(<Root />, document.getElementById('root'));
