import { Popover } from '@cmg/common';
import React from 'react';

import ValuesGrid from '../values-grid/ValuesGrid';

export type Props = {
  value: React.ReactNode;
  valuesTooltip: React.ReactNode[];
  label: string;
  labelsTooltip: string[];
};

const ValueWithMultilineTooltip: React.FC<Props> = ({
  value,
  valuesTooltip,
  label,
  labelsTooltip,
}) => {
  const tooltipLines = valuesTooltip.map((tooltip, index) => (
    <div key={index}>
      {labelsTooltip[index]}: {valuesTooltip[index]}
      <br />
    </div>
  ));

  return (
    <Popover trigger="hover" variant="TOOLTIP" content={<div>{tooltipLines}</div>} placement="top">
      <div>
        <ValuesGrid.Value>{value}</ValuesGrid.Value>
        <ValuesGrid.Label>{label}</ValuesGrid.Label>
      </div>
    </Popover>
  );
};

export default ValueWithMultilineTooltip;
