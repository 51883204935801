import React from 'react';

import { isDefined } from '../../../../helpers/helpers';

type Value = { gteq?: any; lteq?: any };
type Props = {
  value?: Value;
  onChange: (value: Value | null) => void;
};
type Control = (InputControl: any) => React.FC<Props>;

export const createBetweenControl: Control =
  InputControl =>
  ({ onChange, value }) => {
    const handleChange = (newValue, field: 'lteq' | 'gteq') => {
      if (!value || newValue !== value[field]) {
        const newObject: Value = { ...value, [field]: newValue };
        if (isDefined(newObject.lteq) || isDefined(newObject.gteq)) {
          onChange(newObject);
        } else if (value) {
          onChange(null);
        }
      }
    };

    return (
      <div style={{ display: 'flex', minWidth: 300 }}>
        <InputControl onChange={val => handleChange(val, 'gteq')} value={(value || {}).gteq} />
        <div style={{ padding: '5px 5px 0' }}>and</div>
        <InputControl onChange={val => handleChange(val, 'lteq')} value={(value || {}).lteq} />
      </div>
    );
  };
