import React from 'react';

import PerformancePercents from '../../../../../common/components/format/performance-percents/PerformancePercents';
import DashboardValueBase, { Size } from '../dashboard-value-base/DashboardValueBase';

export type Props = {
  label?: string;
  value?: number | null;
  size?: Size;
};

/**
 * PerformancePercentsValue displays a performance percents value
 * when the number is positive it would be colored in green
 * when the number is negative it would be surrounded with parenthesis and colored in red
 * when the number is 0 it would be colored in black
 * with optional label and size
 */
const PerformancePercentsValue: React.FC<Props> = ({ label, value, size }) => {
  return (
    <DashboardValueBase
      renderValue={() => <PerformancePercents value={value} nullValue="-" />}
      label={label}
      size={size}
    />
  );
};

export default PerformancePercentsValue;
