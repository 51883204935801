import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  position: relative;
  z-index: 5;
  height: 88px;
  padding: 22px 20px;
  background-color: ${({ theme }) => theme.background.color.white};
`;

export const SAccountMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
