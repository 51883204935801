import React from 'react';

import { CornerstoneInvestmentType, Currency } from '../../../graphql/__generated__';
import { getFormattedCurrencyInMillion } from '../../shared/model/utils';
import { type Calendar_Offering_CornerstoneInvestmentFragment } from '../graphql';
import { HeaderLabel } from './CornerstoneInvestorsRenderer.styles';

export const CornerstoneInvestorTypeLabels = {
  [CornerstoneInvestmentType.OfferingParticipant]: 'Offering Participants',
  [CornerstoneInvestmentType.PrivatePlacement]: 'Private Placement',
};

export type Props = {
  cornerstoneInvestments: Calendar_Offering_CornerstoneInvestmentFragment[];
  investmentType: CornerstoneInvestmentType;
  cornerstoneInvestorsCount?: number | null;
  cornerstoneOfferingAmount?: number | null;
  pricingCurrency?: Currency | null;
};

const renderIndividual = (
  investment: Calendar_Offering_CornerstoneInvestmentFragment,
  idx: number,
  pricingCurrency?: Currency | null
) => {
  const investor = investment.investors?.[0];
  if (!investor) {
    return null;
  }
  return (
    <tr className="individual-investment" key={idx}>
      {investor && (
        <React.Fragment>
          <td>{investor.shareholder?.name}</td>
          <td>{investor.shareholder?.shareholderTypeDisplayName}</td>
          <td>{investor.isExistingCornerstoneInvestor ? 'No' : 'Yes'}</td>
          <td>{getFormattedCurrencyInMillion(pricingCurrency, investment.individualAmount)}</td>
        </React.Fragment>
      )}
    </tr>
  );
};

const renderCombined = (
  combinedInvestments: Calendar_Offering_CornerstoneInvestmentFragment,
  idx: number,
  pricingCurrency?: Currency | null
) => {
  const investors = combinedInvestments.investors ?? [];

  return (
    <React.Fragment key={idx}>
      <tr className="combined-amount-row">
        <td colSpan={3}>Combined Amount</td>
        <td>
          {getFormattedCurrencyInMillion(pricingCurrency, combinedInvestments.individualAmount)}
        </td>
      </tr>
      {investors.map((investor, idx) => (
        <tr className="combined-row" key={idx}>
          <td>{investor.shareholder?.name}</td>
          <td>{investor.shareholder?.shareholderTypeDisplayName}</td>
          <td>{investor.isExistingCornerstoneInvestor ? 'No' : 'Yes'}</td>
          <td />
        </tr>
      ))}
    </React.Fragment>
  );
};

const CornerstoneInvestorsTableContent: React.FC<Props> = ({
  cornerstoneInvestorsCount,
  cornerstoneOfferingAmount,
  cornerstoneInvestments,
  investmentType,
  pricingCurrency,
}) => {
  const individualInvestments = cornerstoneInvestments.filter(
    c => (c.investors?.length ?? 0) === 1
  );
  const combinedInvestments = cornerstoneInvestments.filter(c => (c.investors?.length ?? 0) > 1);

  return cornerstoneInvestments.length >= 1 ? (
    <React.Fragment>
      <thead>
        <tr>
          <HeaderLabel className="cornerstone-type">{`Cornerstone\u2013${CornerstoneInvestorTypeLabels[investmentType]}`}</HeaderLabel>
        </tr>
        <tr>
          <th style={{ width: '50%' }}>Investor</th>
          <th style={{ width: '15%' }}>Type</th>
          <th style={{ width: '19%' }}>New Investor</th>
          <th style={{ width: '16%' }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="total-row">
          <td colSpan={3}>{`Total ${cornerstoneInvestorsCount}`}</td>
          <td>{getFormattedCurrencyInMillion(pricingCurrency, cornerstoneOfferingAmount)}</td>
        </tr>
        {individualInvestments.length > 0 &&
          individualInvestments.map((investment, idx) =>
            renderIndividual(investment, idx, pricingCurrency)
          )}

        {combinedInvestments.length > 0 &&
          combinedInvestments.map((investment, idx) =>
            renderCombined(investment, idx, pricingCurrency)
          )}
      </tbody>
    </React.Fragment>
  ) : null;
};

export default CornerstoneInvestorsTableContent;
