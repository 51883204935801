import { Modal } from '@cmg/common';
import React from 'react';

import CompanySearch from './CompanySearch';
import { SMobileModal, SSearchWrapper, StyledTextInput } from './CompanySearch.styles';
import type { Company, CompanyOption } from './types';

type Props = {
  onItemClick: (companyId: Company['id']) => void;
  onSearchOptions: (searchTerm: string) => Promise<CompanyOption[]>;
};

const MobileCompanySearch = ({ onItemClick, onSearchOptions }: Props) => {
  const [isOverlayShown, setIsOverlayShown] = React.useState(false);

  const showOverlay = () => setIsOverlayShown(true);
  const hideOverlay = () => setIsOverlayShown(false);

  const handleItemClick = (companyId: Company['id']) => {
    hideOverlay();
    onItemClick(companyId);
  };

  return (
    <SSearchWrapper>
      <StyledTextInput onClick={showOverlay} placeholder="Search for companies…" />
      <Modal onHide={hideOverlay} show={isOverlayShown} title="Search" closeButton>
        <SMobileModal>
          <CompanySearch
            onItemClick={handleItemClick}
            onSearchOptions={onSearchOptions}
            autoFocus
            maxMenuHeight={160}
          />
        </SMobileModal>
      </Modal>
    </SSearchWrapper>
  );
};

export default MobileCompanySearch;
