import styled, { css } from 'styled-components/macro';

export const SButtonBox = styled.div`
  margin: 10px 18px 0;
  display: flex;
  justify-content: space-between;

  > div {
    flex: none;
  }
`;

export const SChart = styled.div<{ fixedHeight?: boolean }>`
  width: 100% !important;
  height: auto !important;
  min-height: 284px;
  max-height: 284px;

  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      min-height: 261px;
      max-height: 261px;
    `}

  canvas {
    width: 100% !important;
    height: auto;
  }
`;

export const SDatalabChart = styled.div<{ horizontal?: boolean }>`
  position: relative;

  > div {
    display: flex;
    flex-direction: row;
    user-select: none;
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      ${SChart} {
        flex-basis: 85%;
        width: 85% !important;
      }
    `}
`;

export const SDoughnut = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  ${SChart} {
    width: 60% !important;
  }
`;
