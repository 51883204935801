import { Icon, Popover } from '@cmg/common';
import styled from 'styled-components/macro';

export const SAccountMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 44px;
`;

export const SSectorsToggleListItem = styled.li`
  color: ${({ theme }) => theme.text.color.white};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.blue3};
  }
`;

export const SDropdownButtonWrapper = styled.div`
  margin: 0 20px;
`;

export const StyledToggleIcon = styled(Icon)`
  margin-right: 5px;
`;

export const StyledQuestionIcon = styled(Icon).attrs({ name: 'question-circle' })`
  color: ${({ theme }) => theme.text.color.light};

  &:hover {
    color: ${({ theme }) => theme.text.color.link};
  }
`;

export const StyledPopover = styled(Popover).attrs({
  hideArrow: true,
  variant: 'LIGHT',
  placement: 'bottomRight',
})`
  padding-top: 0;
`;

export const SMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SAnchorTag = styled.a`
  color: ${({ theme }) => theme.text.color.light};
  padding: 4px 0;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.text.color.link};
  }
`;

export const SMenuOption = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  padding: 4px 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.text.color.link};
  }
`;

export const StyledIconWrapper = styled.div`
  padding: 0px 8px;
  font-size: 24px;
`;
