import { useAccessToken, useAuth } from '@cmg/auth';
import { Column, Row } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import externalRouteFactory from '../../../common/util/externalRouteFactory';
import routeFactory from '../../../common/util/routeFactory';
import { selectUserSettings, setUserSettingsRequest } from '../ducks';
import AccountMenu from './components/account-menu/AccountMenu';
import CompanySearch from './components/company-search/CompanySearchContainer';
import { SAccountMenuWrapper, SWrapper } from './ScreenHeader.styles';

const mapStateToProps = state => ({
  userSettings: selectUserSettings(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setUserSettingsRequest,
    },
    dispatch
  ),
});

type OwnProps = {
  renderContent?: () => React.ReactNode;
  // Populate from 'shared' routes since the user will not have a userToken
  shareToken?: string;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const ScreenHeaderComponent: React.FC<Props> = ({
  userSettings,
  shareToken,
  renderContent,
  actions,
}) => {
  const { isLoggedIn, userPermissions, oidcUserFullName } = useAuth();
  const accessToken = useAccessToken();
  const logoutUrl = routeFactory.logout.getUrlPath();
  const myProfileUrl = externalRouteFactory.myProfile.getUrlPath();
  const manageAccountUrl = externalRouteFactory.accountSettings.getUrlPath();
  const privacyPolicyUrl = externalRouteFactory.privacyPolicy.getUrlPath();
  const token = shareToken || accessToken;

  return (
    <SWrapper>
      <Row>
        <Column xs={8}>{isLoggedIn && <CompanySearch />}</Column>
        <Column xs={4} sm={5} lg={6}>
          {renderContent && renderContent()}
        </Column>
        <Column xs={12} sm={11} lg={10}>
          <SAccountMenuWrapper>
            {token && (
              <AccountMenu
                isLoggedIn={isLoggedIn}
                userSettings={userSettings}
                userFullName={oidcUserFullName}
                token={token}
                userPermissions={userPermissions}
                logoutUrl={logoutUrl}
                myProfileUrl={myProfileUrl}
                manageAccountUrl={manageAccountUrl}
                privacyPolicyUrl={privacyPolicyUrl}
                setUserSettings={actions.setUserSettingsRequest}
              />
            )}
          </SAccountMenuWrapper>
        </Column>
      </Row>
    </SWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenHeaderComponent);
