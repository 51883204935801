import styled from 'styled-components/macro';

export const SSectorsEditModal = styled.div`
  max-width: 400px;
  margin: 0 auto;
  .ant-select-tree-dropdown {
    /* on smaller screens it will not move to top */
    max-height: 300px !important;
  }
`;

export const SManagedSectorsLinkContainer = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
  text-align: center;
  margin: 8px 0 0;
`;

export const STitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SSubtitle = styled.span`
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.light};
`;
