import {
  Box,
  CurrencyInput,
  FormLabel,
  LinkButton,
  NumericInput,
  numericUtil,
  PercentInput,
  PrimaryButton,
  Select,
  Table,
} from '@cmg/common';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React from 'react';

import TrashButton from '../../../../common/components/buttons/trash-button/TrashButton';
import { ioiPricingTypeOptions, ioiUnitTypeOptions } from '../constants';
import { validateFundName } from '../model/ioi-form-helpers';
import EditControls from './EditControls';
import FormControl from './FormControl';
import {
  SAddFundWrapper,
  SInternalDemand,
  StyledTable,
  StyledTextInput,
} from './InternalDemandWidget.styles';

const groupBy = (items, groupKey, initialValue = {}) =>
  items.reduce((acc, item) => {
    const key = item[groupKey];
    const groups = acc;
    groups[key] = groups[key] || [];
    groups[key].push(item);
    return groups;
  }, initialValue);

const styledConfig = ({ isInFormGroup }) => ({
  control: ({ menuIsOpen }, originalStyles) => ({
    borderBottomRightRadius:
      isInFormGroup || menuIsOpen ? 0 : originalStyles.borderBottomRightRadius,
    borderTopRightRadius: isInFormGroup ? 0 : originalStyles.borderTopRightRadius,
  }),
});

export function getTotalAllocation(fundAllocations) {
  return sumBy(fundAllocations, a => (!isNil(a.shares) ? a.shares : 0));
}

export function getTotalPercentOfFirmAllocation(fundAllocations) {
  return sumBy(fundAllocations, a => (!isNil(a.pctOfFirmAllocation) ? a.pctOfFirmAllocation : 0));
}

export default class InternalDemandWidget extends React.Component {
  static propTypes = {
    funds: PropTypes.array.isRequired,
    fundIois: PropTypes.array.isRequired,
    fundAllocations: PropTypes.array.isRequired,
    onIoiFundsSave: PropTypes.func.isRequired,
    addFund: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
    canAdd: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    fundAllocationsFormErrors: PropTypes.object.isRequired,
    fundIoisFormErrors: PropTypes.object.isRequired,
    setFundAllocationProp: PropTypes.func.isRequired,
    onClickDeleteFundLevelButton: PropTypes.func.isRequired,
    setFundIoiProp: PropTypes.func.isRequired,
    onClickDeleteFundIoiButton: PropTypes.func.isRequired,
    onClickAddFundLevelIoiButton: PropTypes.func.isRequired,
    onClickEditButton: PropTypes.func.isRequired,
    onClickCancelButton: PropTypes.func.isRequired,
    onClickSaveButton: PropTypes.func.isRequired,
  };

  state = {
    fundName: '',
    fundNameFormError: '',
  };

  onClickAddFundButton = () => {
    const { fundName } = this.state;
    const fundNameFormError = validateFundName(fundName);

    if (fundNameFormError) {
      this.setState({
        fundNameFormError,
      });
    } else {
      this.props.addFund(fundName);

      // TODO set after success
      this.setState({
        fundName: '',
        fundNameFormError: '',
      });
    }
  };

  onChangeFundName = value => {
    this.setState({
      fundName: value,
      fundNameFormError: '',
    });
  };

  getFundsOptions = fundId => {
    const { funds, fundAllocations } = this.props;

    const createOption = f => ({
      value: f.id,
      label: f.name,
    });

    const otherFundAllocationsIds = fundAllocations.map(f => f.id).filter(id => id !== fundId);
    const selectedFund = funds.filter(f => f.id === fundId).map(createOption)[0];

    // Filter out already used funds
    const fundsOptions = funds
      .filter(fund => !otherFundAllocationsIds.includes(fund.id))
      .map(createOption);

    return {
      selectedFund: selectedFund ? selectedFund.value : undefined,
      fundsOptions,
    };
  };

  renderIoiFundsTable(fundIois = [], fundId, shares, pctOfFirmAllocation) {
    const fund = this.props.funds.filter(f => f.id === fundId)[0];
    const persistedFundIois = fundIois.filter(fundIoi => fundIoi.unitType !== '');

    return (
      <div className="fund-wrapper" key={fundId}>
        <StyledTable striped>
          <thead>
            <tr>
              <th className="value-column">Funds/Strategy</th>
              <th className="value-column">Allocation</th>
              <th className="value-column">% of Firm Allocation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="value-column">{fund ? fund.name : ''}</td>
              <td className="value-column">{numericUtil.formatInteger(shares)}</td>
              <td className="value-column">{numericUtil.formatPercents(pctOfFirmAllocation, 2)}</td>
            </tr>
          </tbody>
        </StyledTable>
        <StyledTable striped>
          {persistedFundIois.length > 0 && (
            <thead>
              <tr>
                <th className="value-column">UNIT (IOI Type)</th>
                <th className="value-column">Quantity</th>
                <th className="value-column">Limit Price(Order Type)</th>
              </tr>
            </thead>
          )}
          <tbody>
            {persistedFundIois.map((ioi, idx) => (
              <tr key={idx}>
                <td className="value-column">
                  {ioi.unitType === 'dollars' && 'USD'}
                  {ioi.unitType === 'percentage' && '% of Offering'}
                  {ioi.unitType === 'shares' && 'Shares'}
                </td>
                <td className="value-column">
                  {ioi.unitType === 'dollars' && numericUtil.formatCurrencyInMillions(ioi.dollars)}
                  {ioi.unitType === 'percentage' && numericUtil.formatPercents(ioi.percentage, 2)}
                  {ioi.unitType === 'shares' && numericUtil.formatInteger(ioi.shares)}
                </td>
                <td className="value-column">
                  {ioi.pricingType === 'market' && 'At Market'}
                  {ioi.pricingType === 'limit' && numericUtil.formatCurrency(ioi.limitPrice)}
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    );
  }

  renderIoiFundsEdit(fundIois = [], fundId, shares) {
    const {
      fundAllocationsFormErrors,
      fundIoisFormErrors,
      setFundAllocationProp,
      onClickDeleteFundLevelButton,
      setFundIoiProp,
      onClickDeleteFundIoiButton,
    } = this.props;

    const { selectedFund, fundsOptions } = this.getFundsOptions(fundId);

    return (
      <div className="fund-wrapper edit" key={fundId}>
        <StyledTable responsive={false}>
          <thead>
            <tr>
              <td className="value-column">
                <FormLabel>Funds / Strategy</FormLabel>
              </td>
              <td className="value-column">
                <FormLabel>Allocation</FormLabel>
              </td>
              <td className="value-column" />
              <td className="action-column" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="value-column">
                <FormControl error={fundAllocationsFormErrors[fundId].fundId}>
                  <Select
                    isClearable={false}
                    options={fundsOptions}
                    value={selectedFund}
                    onChange={value => setFundAllocationProp(fundId, 'fundId', value)}
                  />
                </FormControl>
              </td>
              <td className="value-column">
                <FormControl error={fundAllocationsFormErrors[fundId].shares}>
                  <NumericInput
                    placeholder="1,000,000"
                    precision={0}
                    value={shares}
                    onChange={value => setFundAllocationProp(fundId, 'shares', value)}
                  />
                </FormControl>
              </td>
              <td className="value-column">
                <TrashButton onClick={() => onClickDeleteFundLevelButton(fundId)} />
              </td>
              <td className="action-column" />
            </tr>
          </tbody>
        </StyledTable>
        <StyledTable responsive={false}>
          <thead>
            <tr>
              <td className="value-column">
                <FormLabel>Unit (IOI Type)</FormLabel>
              </td>
              <td className="value-column">
                <FormLabel>Quantity</FormLabel>
              </td>
              <td className="value-column">
                <FormLabel>Limit Price (Order Type)</FormLabel>
              </td>
              <th className="action-column" />
            </tr>
          </thead>
          <tbody>
            {fundIois.map((ioi, idx) => (
              <tr key={idx}>
                <td className="value-column">
                  <FormControl error={fundIoisFormErrors[ioi.uuid].unitType}>
                    <Select
                      isClearable={false}
                      options={ioiUnitTypeOptions}
                      value={ioi.unitType}
                      onChange={value => setFundIoiProp(ioi.uuid, 'unitType', value)}
                    />
                  </FormControl>
                </td>
                <td className="value-column">
                  {ioi.unitType === 'dollars' && (
                    <FormControl error={fundIoisFormErrors[ioi.uuid].dollars}>
                      <CurrencyInput
                        placeholder="1,000"
                        precision={0}
                        value={ioi.dollars}
                        onChange={value => setFundIoiProp(ioi.uuid, 'dollars', value)}
                      />
                    </FormControl>
                  )}

                  {ioi.unitType === 'percentage' && (
                    <FormControl error={fundIoisFormErrors[ioi.uuid].percentage}>
                      <PercentInput
                        placeholder="1.0"
                        precision={2}
                        value={ioi.percentage}
                        onChange={value => setFundIoiProp(ioi.uuid, 'percentage', value)}
                      />
                    </FormControl>
                  )}

                  {ioi.unitType === 'shares' && (
                    <FormControl error={fundIoisFormErrors[ioi.uuid].shares}>
                      <NumericInput
                        placeholder="1,000,000"
                        precision={0}
                        value={ioi.shares}
                        onChange={value => setFundIoiProp(ioi.uuid, 'shares', value)}
                      />
                    </FormControl>
                  )}
                </td>
                <td className="value-column">
                  {!!ioi.unitType && (
                    <div className={cn({ 'inputs-group': ioi.pricingType === 'limit' })}>
                      <FormControl error={fundIoisFormErrors[ioi.uuid].pricingType}>
                        <Select
                          isClearable={false}
                          options={ioiPricingTypeOptions}
                          styledConfig={styledConfig({
                            isInFormGroup: ioi.pricingType === 'limit',
                          })}
                          value={ioi.pricingType}
                          onChange={value => setFundIoiProp(ioi.uuid, 'pricingType', value)}
                        />
                      </FormControl>

                      {ioi.pricingType === 'limit' && (
                        <FormControl error={fundIoisFormErrors[ioi.uuid].limitPrice}>
                          <CurrencyInput
                            placeholder="7.50"
                            precision={2}
                            value={ioi.limitPrice}
                            onChange={value => setFundIoiProp(ioi.uuid, 'limitPrice', value)}
                          />
                        </FormControl>
                      )}
                    </div>
                  )}
                </td>
                <td className="action-column">
                  {idx < fundIois.length - 1 && (
                    <TrashButton onClick={() => onClickDeleteFundIoiButton(ioi)} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    );
  }

  renderFundAllocationsEdit = () => {
    const { fundAllocations, fundIois } = this.props;
    const groupedFundIois = groupBy(fundIois, 'fundId');

    return (
      <div>
        {fundAllocations.map(allocation =>
          this.renderIoiFundsEdit(
            groupedFundIois[allocation.fundId],
            allocation.fundId,
            allocation.shares
          )
        )}
      </div>
    );
  };

  renderFundAllocationsReadOnly = () => {
    const { fundAllocations, fundIois } = this.props;
    const groupedFundIois = groupBy(fundIois, 'fundId');
    const totalAllocation = getTotalAllocation(fundAllocations);
    const totalPctOfFirmAllocation = getTotalPercentOfFirmAllocation(fundAllocations);

    return (
      <div>
        {fundAllocations.map(allocation =>
          this.renderIoiFundsTable(
            groupedFundIois[allocation.fundId],
            allocation.fundId,
            allocation.shares,
            allocation.pctOfFirmAllocation
          )
        )}

        <div className="fund-summary">
          <Table>
            <thead>
              <tr>
                <th className="value-column" />
                <th className="value-column">Allocation</th>
                <th className="value-column">% of Firm Allocation</th>
              </tr>
            </thead>
            <tbody className="internal-demand-summary">
              <tr>
                <td className="value-column">Total</td>
                <td className="value-column">{numericUtil.formatInteger(totalAllocation)}</td>
                <td className="value-column">
                  {numericUtil.formatPercents(totalPctOfFirmAllocation, 2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  render() {
    const {
      canEdit,
      canAdd,
      isEditing,
      onClickAddFundLevelIoiButton,
      onClickEditButton,
      onClickCancelButton,
      onClickSaveButton,
    } = this.props;

    const { fundName } = this.state;

    return (
      <SInternalDemand>
        <Box>
          <div>
            <Box.Header title="Internal Demand">
              {canEdit && (
                <EditControls
                  isEditing={isEditing}
                  onClickEditButton={onClickEditButton}
                  onClickCancelButton={onClickCancelButton}
                  onClickSaveButton={onClickSaveButton}
                />
              )}
            </Box.Header>
            <Box.Content>
              {isEditing ? this.renderFundAllocationsEdit() : this.renderFundAllocationsReadOnly()}
              {canEdit && canAdd && (
                <div>
                  <LinkButton
                    onClick={onClickAddFundLevelIoiButton}
                    className="top-gap"
                    iconLeft={{ name: 'plus', size: 'lg' }}
                  >
                    Add Fund Level IOI
                  </LinkButton>
                </div>
              )}
            </Box.Content>
          </div>
        </Box>

        {canEdit && (
          <Box>
            <Box.Header title="Add New Fund/Strategy" />
            <Box.Content>
              <SAddFundWrapper>
                <StyledTextInput
                  value={fundName}
                  onChange={this.onChangeFundName}
                  placeholder="Name…"
                />
                <PrimaryButton onClick={this.onClickAddFundButton}>Add Fund</PrimaryButton>
              </SAddFundWrapper>
            </Box.Content>
          </Box>
        )}
      </SInternalDemand>
    );
  }
}
