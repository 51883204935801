import { TextInput } from '@cmg/common';
import React from 'react';

type Props = {
  value?: string;
  onChange?: (value: string | null) => void;
};

export const StringInputControl: React.FC<Props> = ({ onChange, value }) => {
  const handleOnChange = newValue => {
    if (onChange && newValue !== value) {
      onChange(newValue === '' ? null : newValue);
    }
  };

  return <TextInput onChange={handleOnChange} value={value} />;
};

export default StringInputControl;
