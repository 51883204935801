import { Option, ToastManager } from '@cmg/common';
import React from 'react';

import { SearchFilterHookResult } from '../../../../../graphql/types';
import { useOfferingsFilterForm_AdvisersQuery } from '../graphql/__generated__/OfferingsFilterForm_Advisers';
import { useCreateAdviserOptions } from '../OfferingsFilterForm.model';

export const useSearchAdvisers = ({ skip }: { skip?: boolean } = {}): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useOfferingsFilterForm_AdvisersQuery({
    variables: {
      filter: {
        name: searchText ? { like: searchText } : undefined,
      },
    },
    skip,
    fetchPolicy: 'cache-first',
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const adviserOptions = useCreateAdviserOptions(data?.advisers?.items ?? []);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching advisers');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(adviserOptions);
    }
  }, [loading, data, error, adviserOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: adviserOptions,
    loadOptions,
  };
};
