import { BoxButton, Group, Icon, Option } from '@cmg/common';
import { ecmSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import DropdownCheckboxTree from '../../../../../common/components/dropdowns/dropdown-checkbox-tree/DropdownCheckboxTree';
import {
  columnCategoryLabel,
  columnGroupLabel,
  DatalabTableColumnCategory,
  DatalabTableColumnGroup,
} from '../../../../../features/datalab/constants';
import ReportFieldRequest from '../../../../../features/shared/components/report-field-request/ReportFieldRequest';
import { SGroupCheckboxList } from './ColumnsToggler.styles';
import { GroupToggler } from './GroupToggler';

type Column = {
  field: string;
  label: string;
  excludeFromColumnToggler?: boolean;
  visible?: boolean;
  [key: string]: any;
};

type CategoriesConfig = {
  [key in DatalabTableColumnCategory]?: { field: string; label: string; category: string }[];
};

type GroupsConfig = {
  [key in DatalabTableColumnGroup]?: { field: string; label: string; group: string }[];
};

export type Props = {
  columns: Column[];
  staticColumns?: string[];
  toggleColumnVisibility: (values: string[]) => void;
  categorizedColumns?: Partial<CategoriesConfig>;
  groupedColumns?: Partial<GroupsConfig>;
};

const getItems = (columns: Column[], excludedFields: string[]): Option[] => {
  return columns
    .filter(({ field }) => !excludedFields.includes(field))
    .map(({ field, label }) => ({
      label,
      value: field,
    }));
};

const getCategorizedItems = (
  categories: Partial<CategoriesConfig>,
  excludedFields: string[]
): Group[] => {
  return Object.entries(categories).map(([category, columns]) => ({
    label: columnCategoryLabel[category],
    options: getItems(columns as Column[], excludedFields),
  }));
};

const getGroupedItems = (groups: Partial<GroupsConfig>, excludedFields: string[]): Group[] => {
  return Object.entries(groups).map(([group, columns]) => ({
    label: columnGroupLabel[group],
    options: getItems(columns as Column[], excludedFields),
  }));
};

const renderGroupedToggler = (disabled: string[], values, onChange, groups?: Group[]) => {
  return groups?.length ? (
    <SGroupCheckboxList>
      {groups.map(group => (
        <GroupToggler
          key={group.key}
          group={group}
          values={values}
          disabled={disabled}
          onChange={onChange}
        />
      ))}
    </SGroupCheckboxList>
  ) : null;
};

export const ColumnsToggler: React.FC<Props> = ({
  columns,
  staticColumns = [],
  toggleColumnVisibility,
  categorizedColumns,
  groupedColumns,
}) => {
  const excludedFields = columns.filter(col => col.excludeFromColumnToggler).map(col => col.field);
  const values = columns.filter(c => !c.excludeFromColumnToggler && c.visible).map(c => c.field);
  const items = categorizedColumns
    ? getCategorizedItems(categorizedColumns, excludedFields)
    : getItems(columns, excludedFields);
  const groups = groupedColumns && getGroupedItems(groupedColumns, excludedFields);
  return (
    <DropdownCheckboxTree
      label={
        <BoxButton testId={ecmSelectors.oldGrid.toolbarColumnsTogglerButton.testId}>
          <Icon name="cog" />
        </BoxButton>
      }
      items={items}
      onChange={toggleColumnVisibility}
      values={values}
      disabled={staticColumns}
      searchable
      groupToggler={() =>
        renderGroupedToggler(staticColumns, values, toggleColumnVisibility, groups)
      }
      footer={({ setVisibility }) => <ReportFieldRequest onClick={() => setVisibility(false)} />}
      testId={ecmSelectors.oldGrid.toolbarDropdownCheckboxTree.testId}
    />
  );
};
