import { useAuth } from '@cmg/auth';
import { ApplicationError } from '@cmg/common';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import routeFactory from '../../../common/util/routeFactory';
import ScreenHeader from '../../shared/layout/ScreenHeader';

export const SApplicationErrorWrapper = styled.div<{ isLoggedIn: boolean }>`
  > * {
    /**
    * Bumping up the positioning of the error screen a bit
    * to account for the horizontal nav bar.
    */
    ${({ isLoggedIn }) =>
      isLoggedIn &&
      css`
        height: calc(100vh - 88px);
      `}
  }
`;

enum ErrorCode {
  FORBIDDEN = 'FORBIDDEN',
  FORBIDDEN_INTERNATIONAL = 'FORBIDDEN_INTERNATIONAL',
}

const errorCodeMap = {
  [ErrorCode.FORBIDDEN]: {
    message:
      'You do not have permission to perform this action. Access levels for your user account may not be set correctly.',
  },
  [ErrorCode.FORBIDDEN_INTERNATIONAL]: {
    message: (
      <span>
        <p>You are not permissioned for this data.</p>
        <p>
          To modify your subscription or learn more, contact{' '}
          <a href="mailto:support@cmgx.io">support@cmgx.io</a>.
        </p>
      </span>
    ),
  },
};

/**
 * Displayed when a user does not have the
 * necessary authorization levels to view
 * a requested route.
 */
const ForbiddenRoute: React.FC = () => {
  const { isLoggedIn } = useAuth();

  return (
    <React.Fragment>
      {isLoggedIn && <ScreenHeader />}
      <SApplicationErrorWrapper isLoggedIn={isLoggedIn}>
        <ApplicationError
          errorCode={ErrorCode.FORBIDDEN}
          customErrorDetails={errorCodeMap}
          returnUrl={routeFactory.root.routePath}
          variant={isLoggedIn ? 'LIGHT' : 'DARK'}
        />
      </SApplicationErrorWrapper>
    </React.Fragment>
  );
};

export default ForbiddenRoute;
