import { LinkButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.background.color.negative};
`;

type Props = {
  onClick?: () => void;
  testId?: string;
};

/**
 * For Remove actions (important changes).
 */
const TrashButton: React.FC<Props> = ({ children, ...props }) => (
  <StyledLinkButton
    {...props}
    iconLeft={{
      name: 'trash-alt',
      size: 'lg',
    }}
  />
);

export default TrashButton;
