import React from 'react';
import { useSelector } from 'react-redux';

import { performanceMetricDisplay } from '../../../dashboard/controls/controlsForm.model';
import { selectTopControls } from '../../../dashboard/controls/ducks';
import PerformancePercentsValue, {
  Props as PerformancePercentsValueProps,
} from '../performance-percents-value/PerformancePercentsValue';

export type Props = Pick<PerformancePercentsValueProps, 'size' | 'value'>;

/**
 * PerformanceMetricValue connected wrapper around PerformancePercentsValue
 * displays the label of the value currently selected in the performance metric control on the dashboard
 */
const PerformanceMetricValue: React.FC<Props> = ({ size, value }) => {
  const performanceMetric = useSelector(selectTopControls).performanceMetric;
  const performanceMetricLabel = performanceMetricDisplay[performanceMetric];

  return <PerformancePercentsValue size={size} value={value} label={performanceMetricLabel} />;
};

export default PerformanceMetricValue;
