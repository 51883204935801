import isNil from 'lodash/isNil';
import React from 'react';

import { StyledPerformanceValue } from './PerformanceValue.styles';

function getVariant(value?: number | null) {
  if (isNil(value) || value === 0) {
    return 'neutral';
  } else if (value && value < 0) {
    return 'negative';
  } else {
    return 'positive';
  }
}

export type Props = {
  value?: number | null;
  nullValue?: string;
  formatter: (value) => string;
};

/**
 * Formats a value and add css classes to colorize the result
 */
const PerformanceValue: React.FC<Props> = ({ value, nullValue, formatter }) => {
  if (isNil(value) && nullValue) {
    return <StyledPerformanceValue variant="neutral">{nullValue}</StyledPerformanceValue>;
  }

  const variant = getVariant(value);
  const formattedValue = formatter(value);

  return <StyledPerformanceValue variant={variant}>{formattedValue}</StyledPerformanceValue>;
};

export default PerformanceValue;
