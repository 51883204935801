import styled from 'styled-components/macro';

import ParticipationTag from './components/ParticipationTag';

export const SListItem = styled.div`
  margin: 5px 0;
`;

export const SRecentDeal = styled.div`
  padding: 0 15px;
`;

export const SIssuerName = styled.div`
  margin-left: 15px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SRow = styled.div.attrs(() => ({
  'data-testid': 'srow',
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  margin: 8px 0;
`;

export const SDealDetail = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;

export const SOfferingType = styled(SDealDetail)`
  padding: 0 5px;
`;

export const SLeftLead = styled.div`
  color: ${({ theme }) => theme.text.color.dark};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const STicker = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SIssuerRow = styled.div.attrs(() => ({
  'data-testid': 'sissuer-row',
}))`
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: ${({ theme }) => theme.color.blue13};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  justify-content: flex-start;
`;

export const StyledParticipationTag = styled(ParticipationTag)`
  display: flex;
  justify-content: flex-end;
`;

export const SParticipationTagWrapper = styled.div.attrs(() => ({
  'data-testid': 'sparticipation-tag-wrapper',
}))`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
