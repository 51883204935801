import React from 'react';

import { SError, SFormControl, SInput } from './FormControl.styles';

export type Props = {
  error?: string;
};

/**
 * temp input wrapper just for IOI Tracker section until this section is rewritten into Formik
 */
const FormControl: React.FC<Props> = ({ error, children }) => {
  return (
    <SFormControl>
      <SInput>{children}</SInput>
      {error && <SError>{error}</SError>}
    </SFormControl>
  );
};

export default FormControl;
