import { Modal, PrimaryButton } from '@cmg/common';
import { allReportsModalSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connectModal, InjectedProps as ModalProps } from 'redux-modal';

import AllReportsPanel from '../all-reports-panel/AllReportsPanel';
import { SFooter } from './AllReportsModal.styles';

export const ALL_REPORTS_MODAL_ID = 'ALL_REPORTS_MODAL_ID';

export const AllReportsModal: React.FC<ModalProps> = ({ handleHide, show }) => (
  <Modal testId={allReportsModalSelector.testId} show={show} onHide={handleHide} size="large">
    <AllReportsPanel />
    <SFooter>
      <PrimaryButton onClick={handleHide}>Done</PrimaryButton>
    </SFooter>
  </Modal>
);

export default connectModal({ name: ALL_REPORTS_MODAL_ID, destroyOnHide: false })(AllReportsModal);
