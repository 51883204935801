import 'antd/lib/select/style/index.css';
import 'antd/lib/tree-select/style/index.css';

import styled from 'styled-components/macro';

export const STreeWrapper = styled.div`
  // AntD's TreeSelect component relies on animations to trigger behavior.
  // To turn off animations, we need to provide the keyframes and set animation length to 0
  * {
    animation-duration: 0s !important;
  }
  @keyframes antSlideUpIn {
  }
  @keyframes antSlideUpOut {
  }

  .ant-select {
    display: block;
    min-width: 200px;
    font-family: ${({ theme }) => theme.text.family.regular};
  }

  .ant-select-selector {
    min-height: 32px;
    box-shadow: none !important;
    border-radius: ${({ theme }) => theme.border.radius.medium} !important;
  }

  .ant-tree-select-dropdown {
    border-radius: ${({ theme }) => theme.border.radius.medium};
    border: ${({ theme }) => `${theme.border.width.small} solid ${theme.interactive.primary}`};
    min-width: 200px;
    box-shadow: none;

    .ant-select-tree {
      .ant-select-tree-treenode {
        padding: 0;

        .ant-select-tree-node-content-wrapper {
          color: ${({ theme }) => theme.color.black};
        }

        .ant-select-tree-node-content-wrapper:hover {
          background: transparent;
        }
      }
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: ${({ theme }) => `1px solid ${theme.form.control.borderColor}`};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.interactive.primary};
  }

  .ant-select-focused .ant-select,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }

  .ant-select-tree-checkbox-inner {
    border-radius: ${({ theme }) => theme.border.radius.medium};
    border-color: ${({ theme }) => theme.interactive.primary};
  }

  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: ${({ theme }) => theme.interactive.primary};
  }

  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: ${({ theme }) => theme.interactive.primary};
  }

  .ant-select-tree-checkbox-checked:after {
    border: none; // A checked checkbox would otherwise have a square border around it
  }

  .ant-select-clear {
    opacity: 1;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-top: -8px;

    &:before {
      background-color: ${({ theme }) => theme.interactive.primary};
      border-radius: ${({ theme }) => theme.border.radius.small};
      content: '\\D7';
      cursor: pointer;
      padding: 0 5px 1px;
      color: ${({ theme }) => theme.color.white};
      font-size: ${({ theme }) => theme.text.size.small};
      top: 9px;
    }

    > .anticon-close-circle {
      display: none;
    }
  }

  .ant-select-selection-item-remove > * {
    line-height: 1;
    font-size: ${({ theme }) => theme.text.size.small};
    font-weight: ${({ theme }) => theme.text.weight.bold};
    stroke-width: 2;
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: ${({ theme }) => theme.interactive.primary};
    color: ${({ theme }) => theme.color.white};
    border: none;
    border-radius: ${({ theme }) => theme.border.radius.medium};
    margin-top: 4px;

    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.color.white};
      border-left: 1px solid ${({ theme }) => theme.color.white};
      line-height: 24px;
      padding-left: 3px;
      font-size: ${({ theme }) => theme.text.size.small};
      transform: none;
      top: 2px;
    }

    .ant-select-selection-item-content {
      font-size: ${({ theme }) => theme.text.size.medium};
      padding-top: 1px;
      padding-right: 3px;
    }
  }

  .ant-select-tree-switcher {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .ant-select-tree-switcher-icon {
      font-size: ${({ theme }) => theme.text.size.medium};
    }
  }

  /* ECM-1361 */
  /* antd does not allow me to turn off animations, so I had to manually search for all transitions and set them to none */
  * {
    transition: none !important;
  }
`;
