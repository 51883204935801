import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { Option } from '@cmg/common';
import sortBy from 'lodash/sortBy';

import { getFeatureToggles } from '../../../../../config/appSettings';
import { OfferingType } from '../../../../../graphql/__generated__';

export enum InternalOfferingType {
  IPO_SPACS = 'IPO_SPACS',
}

export type OfferingsFilterOfferingType = OfferingType | InternalOfferingType;

export const OfferingsFilterOfferingTypeOptions: {
  [key in OfferingsFilterOfferingType]: Option<OfferingsFilterOfferingType>;
} = {
  [OfferingType.Ipo]: { value: OfferingType.Ipo, label: 'IPO' },
  [OfferingType.Rights]: { value: OfferingType.Rights, label: 'Rights' },
  [OfferingType.OvernightFo]: { value: OfferingType.OvernightFo, label: 'Overnight FO' },
  [OfferingType.MarketedFo]: { value: OfferingType.MarketedFo, label: 'Marketed FO' },
  [OfferingType.RegisteredBlock]: {
    value: OfferingType.RegisteredBlock,
    label: 'Registered Block',
  },
  [OfferingType.UnregisteredBlock]: {
    value: OfferingType.UnregisteredBlock,
    label: 'Unregistered Block',
  },
  [InternalOfferingType.IPO_SPACS]: {
    value: InternalOfferingType.IPO_SPACS,
    label: 'IPO (SPACs)',
  },
  [OfferingType.FollowOn]: {
    value: OfferingType.FollowOn,
    label: 'Follow On',
  },
  [OfferingType.Convertible]: {
    value: OfferingType.Convertible,
    label: 'Convertible',
  },
  [OfferingType.Atm]: {
    value: OfferingType.Atm,
    label: 'Atm',
  },
  [OfferingType.RegisteredDirect]: {
    value: OfferingType.RegisteredDirect,
    label: 'Registered direct',
  },
  [OfferingType.DirectListing]: {
    value: OfferingType.DirectListing,
    label: 'Direct listing',
  },
};

const offeringTypeSortOrder: { [key in OfferingsFilterOfferingType]: number } = {
  [OfferingType.Ipo]: 1,
  [InternalOfferingType.IPO_SPACS]: 2,
  [OfferingType.FollowOn]: 3,
  [OfferingType.MarketedFo]: 4,
  [OfferingType.OvernightFo]: 5,
  [OfferingType.Rights]: 6,
  [OfferingType.RegisteredBlock]: 7,
  [OfferingType.UnregisteredBlock]: 8,
  [OfferingType.Convertible]: 9,
  [OfferingType.Atm]: 10,
  [OfferingType.RegisteredDirect]: 11,
  [OfferingType.DirectListing]: 12,
};

export const useOfferingTypes = ({
  excludedOfferingTypes: excludedOfferingTypesProp,
}: {
  excludedOfferingTypes?: OfferingType[];
} = {}) => {
  const userPermissions = getUserPermissions();
  const canViewConverts = checkPermissions(userPermissions, [
    permissionsByEntity.ConvertsOffering.READ,
  ]);
  const canViewRights = checkPermissions(userPermissions, [
    permissionsByEntity.InternationalOffering.READ,
  ]);
  const { isNewDirectOfferingFieldsOn: canViewDirects } = getFeatureToggles();

  const excludedOfferingTypes = [OfferingType.Atm.valueOf()].concat(
    excludedOfferingTypesProp ?? []
  );

  const offeringTypeOptions = sortBy(
    OfferingsFilterOfferingTypeOptions,
    option => offeringTypeSortOrder[option.value]
  ).filter(
    option =>
      !excludedOfferingTypes.includes(option.value) &&
      (canViewConverts || option.value !== OfferingType.Convertible) &&
      (canViewRights || option.value !== OfferingType.Rights) &&
      (canViewDirects || option.value !== OfferingType.DirectListing) &&
      (canViewDirects || option.value !== OfferingType.RegisteredDirect)
  );

  return { offeringTypeOptions };
};
