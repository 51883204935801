import styled, { css } from 'styled-components/macro';

/**
 * Displays a horizontal <dl>. Several of these styles were being provided by bootstrap
 * through a className (not react-bootstrap) and through our own customizations.
 *
 * All of the css responsible for styling the dl, dt, and dd elements have been moved here.
 * If there ends up being more components that use this style of layout, we can locate these
 * styles elsewhere.
 */
export const SDlHorizontal = styled.dl<{ topDelimiter?: boolean }>`
  margin-bottom: 0;

  ${({ topDelimiter, theme }) =>
    topDelimiter &&
    css`
      ${theme.mediaQuery.mediumUp} {
        border-top: 1px solid ${theme.color.gray2};
        margin-top: 15px;
        padding-top: 15px;
      }
    `}

  dt,
  dd {
    line-height: 1.75;
    font-weight: ${({ theme }) => theme.text.weight.medium};
  }

  dt {
    color: ${({ theme }) => theme.color.gray3};
    text-transform: uppercase;
    font-size: 12px;
  }

  dd {
    font-size: 13px;
  }

  /* Larger viewports, display the labels and values side by side */
  ${({ theme }) => css`
    ${theme.mediaQuery.mediumUp} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      dt {
        width: 55%;
      }

      dd {
        width: 45%;
      }
    }
  `}
`;

export default SDlHorizontal;
