import styled from 'styled-components/macro';

import ValuesGrid from '../../../../shared/components/ValuesGrid';

export const SWidgetOfferingPerformance = styled.div`
  height: 260px;
  display: flex;
  padding: 20px 10px 0px 0px;
`;

export const SPerformanceCol = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin-left: 30px;
  margin-right: 10px;

  > div:first-of-type {
    margin-bottom: 0px;
  }
`;

export const StyledValuesGrid = styled(ValuesGrid)`
  width: 85%;
`;

export const STitle = styled.div`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.text.color.dark};
`;

export const SLabels = styled.div`
  display: flex;
  font-size: 30px;
  font-weight: ${({ theme }) => theme.text.weight.light};
  color: ${({ theme }) => theme.text.color.link};
`;

export const SLabel = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.text.color.light};
`;

export const SSlash = styled.div`
  padding: 0 5px;
`;
