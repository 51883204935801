import {
  Banner,
  Box,
  CurrencyInput,
  DataGridClient,
  FormField,
  LinkButton,
  NumericInput,
  numericUtil,
  PercentAgEditor,
  PrimaryButton,
  TextInput,
} from '@cmg/common';
import { GridApi } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components/macro';

import PerformancePercents from '../../../../common/components/format/performance-percents/PerformancePercents';
import {
  CheckboxRenderer,
  CurrencyValueRenderer,
  IntegerValueRenderer,
  PercentValueRenderer,
} from '../../../../common/components/lists';
import { UnderwriterCreditsAllocation } from '../../../../types/domain/underwriter-credits/underwriterCreditsAllocation';
import { UnderwriterCreditsManager } from '../../../../types/domain/underwriter-credits/underwriterCreditsManager';
import { UnderwriterCreditsOffering } from '../../../../types/domain/underwriter-credits/underwriterCreditsOffering';
import RankingTag from '../../shared/components/RankingTag';
import { getGridDisplayedRows } from '../../shared/model/grid.model';
import { geSellingConcessionSummary } from '../model/underwriter-credit.model';

const SFields = styled.div`
  margin: 15px 0 0 5px;
`;
const SRight = styled.div`
  text-align: right;
`;

type Props = {
  allocation: UnderwriterCreditsAllocation;
  offering: UnderwriterCreditsOffering;
  canEdit?: boolean;
  isEditing?: boolean;
  isValid?: boolean;
  managers: UnderwriterCreditsManager[];
  onManagerChange: (managers?: UnderwriterCreditsManager[]) => void;
  onSalesConcessionChange: (value: number | null) => void;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
};
export default class UnderwriterCreditsForm extends React.Component<Props> {
  gridApi!: GridApi;

  handleGridReady = grid => (this.gridApi = grid.api);

  handleOnManagerChange = () =>
    this.props.onManagerChange(getGridDisplayedRows<UnderwriterCreditsManager>(this.gridApi));

  getColumns = () => [
    {
      field: 'name',
      headerName: 'Manager',
    },
    {
      field: 'pctEconomics',
      headerName: 'Est. Economics (for Concessions)',
      cellRendererFramework: PercentValueRenderer,
    },
    {
      field: 'estimatedDesignation',
      headerName: 'Estimated Designations',
      cellRendererFramework: IntegerValueRenderer,
      width: 170,
    },
    {
      field: 'estimatedCommission',
      headerName: 'Estimated Concessions',
      cellRendererFramework: CurrencyValueRenderer,
      width: 170,
    },
    {
      field: 'return1dayEconomics',
      headerName: '1 Day',
      cellRendererFramework: ({ value }) => (
        <SRight>
          <PerformancePercents precision={2} value={value} />
        </SRight>
      ),
      width: 170,
    },
    {
      field: 'returnVwap1dayEconomics',
      headerName: '1 Day VWAP',
      cellRendererFramework: ({ value }) => (
        <SRight>
          <PerformancePercents precision={2} value={value} />
        </SRight>
      ),
      width: 170,
    },
    {
      field: 'plCredit',
      headerName: 'P&L Credit',
      cellRendererFramework: PercentValueRenderer,
      cellEditorFramework: PercentAgEditor,
      editable: params => !!this.props.canEdit && !params.node.rowPinned,
      width: 100,
    },
    {
      field: 'excluded',
      headerName: 'Excluded (For Concessions)',
      cellRendererFramework: CheckboxRenderer,
      width: 100,
    },
  ];

  render() {
    const {
      allocation,
      canEdit,
      isEditing,
      isValid,
      managers,
      offering,
      onCancel,
      onEdit,
      onSave,
      onSalesConcessionChange,
    } = this.props;
    const columns = this.getColumns();

    return (
      <Box>
        <Box.Header title="Underwriter Credits" />
        <Box.Content>
          {managers.length === 0 ? (
            <p style={{ marginBottom: 15 }}>There are no managers on this offering.</p>
          ) : (
            <div>
              {isEditing && !isValid && (
                <Banner variant="error">
                  Sum of P&L Credit must be equal to 100% in order to save changes
                </Banner>
              )}

              <DataGridClient<UnderwriterCreditsManager>
                columns={columns}
                rows={managers}
                resizeBy="grid"
                getRowsPinnedToTop={geSellingConcessionSummary}
                onCellValueChange={this.handleOnManagerChange}
                onGridReady={this.handleGridReady}
              />
            </div>
          )}
          <SFields>
            <div>
              <FormField label="Selling Concession ($)" withMargin>
                <CurrencyInput
                  disabled={!isEditing}
                  placeholder="N/A"
                  precision={6}
                  value={allocation.salesConcession || undefined}
                  onChange={onSalesConcessionChange}
                />
              </FormField>
              <FormField label="Final Allocation (Shares)" withMargin>
                <NumericInput
                  disabled
                  placeholder="N/A"
                  precision={0}
                  value={allocation.shares || undefined}
                />
              </FormField>
              <RankingTag ranking={allocation.ranking || undefined} />
            </div>
            <div>
              <FormField label="1 day price ($)" withMargin>
                <TextInput
                  disabled
                  placeholder="N/A"
                  prefix="$"
                  textAlign="right"
                  value={numericUtil.formatNumber(offering.day1SharePrice)}
                />
              </FormField>
              <FormField label="1 day P&L ($)" withMargin>
                <TextInput
                  disabled
                  placeholder="N/A"
                  prefix="$"
                  textAlign="right"
                  value={numericUtil.formatNumber(offering.day1Pl)}
                />
              </FormField>
            </div>
            <div>
              <FormField label="1 day VWAP ($)" withMargin>
                <TextInput
                  disabled
                  placeholder="N/A"
                  prefix="$"
                  textAlign="right"
                  value={numericUtil.formatNumber(offering.day1VwapPrice)}
                />
              </FormField>
              <FormField label="1 day VWAP P&L ($)" withMargin>
                <TextInput
                  disabled
                  placeholder="N/A"
                  prefix="$"
                  textAlign="right"
                  value={numericUtil.formatNumber(offering.day1VwapPl)}
                />
              </FormField>
            </div>
          </SFields>

          <div>
            {canEdit && (
              <SRight>
                {!isEditing && <PrimaryButton onClick={onEdit}>Edit</PrimaryButton>}

                {isEditing && <LinkButton onClick={onCancel}>Cancel</LinkButton>}

                {isEditing && (
                  <PrimaryButton onClick={onSave} disabled={!isValid}>
                    Save
                  </PrimaryButton>
                )}
              </SRight>
            )}
          </div>
        </Box.Content>
      </Box>
    );
  }
}
