import { IconButton, Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import TextHighlighter from '../../../../../common/components/typography/text-highligher/TextHighlighter';
import routeFactory from '../../../../../common/util/routeFactory';
import { SSearchResultListItem, STicker } from './CompanySearchOption.styles';
import { CompanyOption } from './types';

export type Props = {
  option: CompanyOption;
  searchInput: string;
};

const CompanySearchOption: React.FC<Props> = ({ option, searchInput }) => {
  const theme = useTheme();

  const handleChildClick = event => {
    event.stopPropagation();
    window.open(routeFactory.companies.getUrlPath({ id: option.value }), '_blank');
  };

  return (
    <SSearchResultListItem key={option.value}>
      <STicker>{option.ticker}</STicker>
      <TextHighlighter text={option.label} search={searchInput} />
      <Popover placement="right" variant="DARK" content={<span>Opens in a new tab</span>}>
        <IconButton
          icon={{ name: 'external-link-alt' }}
          color={theme.brand.color.dark}
          onClick={handleChildClick}
          hoverColor={theme.text.color.dark}
        />
      </Popover>
    </SSearchResultListItem>
  );
};

export default CompanySearchOption;
