import { objectCamelToSnake } from '../../../common/helpers/url-helpers';
import { DatalabScreens } from '../constants';
import { DatalabFilterValues, DatalabRequest, DatalabRequestDto, FilterSettings } from '../types';
import { getSummaryColumns, isInTable } from './datalab.model';
import {
  getInternationalOfferingTypes,
  getOfferingTypeConfig,
  getOfferingTypeWithoutInternalTypes,
} from './filters.model';

/**
 * TODO: DatalabRequest and DatalabRequestDto are obfuscating a difference between a table request payload
 * and a download request payload. Two separate types should be created here along with two separate mapping
 * functions. e.g. the download will never have pagination and the table will never have include_* fields.
 */
export function createDatalabRequestDto(query: DatalabRequest): DatalabRequestDto {
  return {
    data_lab: {
      start_date_value: query.datalab.startDateValue,
      end_date_value: query.datalab.endDateValue,
      offering_type_values: query.datalab.offeringTypeValues,
      international_offering_type_values: query.datalab.internationalOfferingTypeValues,
      by_sectors_values: query.datalab.bySectorsValues,
      by_custom_sectors_values: query.datalab.byCustomSectorsValues,
      custom_filter: query.datalab.customFilter,
      market_cap_value_min: query.datalab.marketCapValueMin,
      market_cap_value_max: query.datalab.marketCapValueMax,
      selling_shareholder_pct_min: query.datalab.sellingShareholderPctMin,
      selling_shareholder_pct_max: query.datalab.sellingShareholderPctMax,
      offering_size_value_min: query.datalab.offeringSizeValueMin,
      offering_size_value_max: query.datalab.offeringSizeValueMax,
      underwriters_values: query.datalab.underwritersValues,
      filter_for_allocations: query.datalab.filterForAllocations,
      funds_values: query.datalab.fundsValues,
      sponsors_values: query.datalab.sponsorsValues,
      advisories_values: query.datalab.advisoriesValues,
      spac: query.datalab.spac,
      advanced_query: objectCamelToSnake(query.datalab.advancedQuery),
      left_lead_only: query.datalab.leftLeadOnly,
      avg_method: query.datalab.avgMethod,
      pl_credit: query.datalab.plCredit,
      countries_values: query.datalab.countries,
    },
    summary_columns: query.summaryColumns,
    page: query.page,
    per_page: query.perPage,
    order_field: query.orderField,
    order_direction: query.orderDirection,
    columns: query.columns,
    include_advisors: query.includeAdvisors,
    include_offering_notes: query.includeOfferingNotes,
    include_ioi_notes: query.includeIoiNotes,
    include_underwriters: query.includeUnderwriters,
    include_sponsors: query.includeSponsors,
    include_fund_ioi: query.includeFundIoi,
    use_custom_sectors: query.useCustomSectors,
  };
}

/**
 * Takes filters set by the user and adds some calculated fields on the way out to the api.
 */
export function decorateDatalabFilterValues(
  section: string,
  filters: DatalabFilterValues,
  filterSettings: FilterSettings
): DatalabFilterValues {
  const screen = DatalabScreens[section] ?? (section as DatalabScreens);

  let customFilter;
  if (section === DatalabScreens.LOCK_UP_EXPIRATION) {
    customFilter = 'future_expiration_date';
  } else if (section === DatalabScreens.IPO_BACKLOG) {
    customFilter = 'filed_or_live';
  }

  const { shouldFilterForAllocations } = filterSettings;

  // We will only pull defaults if by default nothing is selected
  const offeringTypeValues =
    (filters.offeringTypeInternal ?? []).length === 0
      ? getOfferingTypeConfig(screen).defaultSelectedValues
      : filters.offeringTypeValues;

  const { ioiType, useCustomSectors, ...restFilters } = filters;
  const filterForAllocations = shouldFilterForAllocations ? ioiType : false;
  const offeringTypes = getOfferingTypeWithoutInternalTypes(offeringTypeValues);

  return {
    ...restFilters,
    filterForAllocations,
    customFilter,
    internationalOfferingTypeValues: getInternationalOfferingTypes(filters.offeringTypeInternal),
    offeringTypeValues: offeringTypes,
  };
}

/**
 * Get's a payload tailored for fetching a datalab table.
 */
export function getPayload(
  section,
  sectionType,
  filters: DatalabFilterValues,
  filterSettings: FilterSettings,
  page,
  perPage,
  orderField,
  orderDirection
): DatalabRequest {
  let payload: any = {
    datalab: decorateDatalabFilterValues(section, filters, filterSettings),
    summaryColumns: getSummaryColumns(section, sectionType),
    useCustomSectors: filters.useCustomSectors,
  };

  if (isInTable(section, sectionType)) {
    payload = {
      ...payload,
      page: page,
      perPage: perPage,
      orderField: orderField,
      orderDirection: orderDirection,
    };
  }

  return payload;
}
