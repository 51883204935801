import { Box, numericUtil } from '@cmg/common';
import React, { useMemo } from 'react';

import { BarChart, BarChartProps, CustomLegend } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      preOfferingMean: number;
      preOfferingMedian: number;
      preOfferingCount: number;
      postOfferingMean: number;
      postOfferingMedian: number;
      postOfferingCount: number;
    };
  }[];
};

type RawItem = {
  y: number;
  x: string;
  count: number;
};

type ChartProps = BarChartProps<RawItem[]>;

const transformData = (chartData: Props['chartData'] = []): ChartProps['data'] => {
  return {
    datasets: [
      {
        label: 'Pre-Offering (MEAN)',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.preOfferingMean,
            x: item.label,
            count: item.values.preOfferingCount,
          })
        ),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Pre-Offering (MEDIAN)',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.preOfferingMedian,
            x: item.label,
            count: item.values.preOfferingCount,
          })
        ),
        backgroundColor: barChartColors.light,
      },
      {
        label: 'Post-Offering (MEAN)',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.postOfferingMean,
            x: item.label,
            count: item.values.postOfferingCount,
          })
        ),
        backgroundColor: barChartColors.dark,
      },
      {
        label: 'Post-Offering (MEDIAN)',
        data: chartData.map(
          (item): RawItem => ({
            y: item.values.postOfferingMedian,
            x: item.label,
            count: item.values.postOfferingCount,
          })
        ),
        backgroundColor: barChartColors.darker,
      },
    ],
  };
};

const getOptions = (): ChartProps['options'] => {
  return {
    scales: {
      x: {
        title: {
          text: 'Offering Size',
          display: true,
        },
      },
      y: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset } = tooltipItem;
            const { label } = dataset;
            const raw = tooltipItem.raw as RawItem;

            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;
            return `${label ? `${label}: ` : ''}${numericUtil.formatPercents(
              tooltipItem.parsed.y
            )}${countString ?? ''}`;
          },
        },
      },
    },
  };
};

const WidgetSizePrePostOfferingMarketCap: React.FC<Props> = ({ chartData = [] }) => {
  const transformedData = useMemo(() => transformData(chartData), [chartData]);
  const options = useMemo(() => getOptions(), []);

  return (
    <SDatalabChart horizontal>
      <Box.Header title="Sizes as a % Pre- and Post-Offering Market Cap" />

      <Box.Content>
        <SChart>
          <BarChart data={transformedData} barSizingMode="CMGCenteredBarPlugin" options={options} />
        </SChart>

        <CustomLegend chartData={transformedData} chartType="bar" />
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetSizePrePostOfferingMarketCap;
