import { ISODateTime, UUID } from '../../common';

export enum Subject {
  ALLOCATION = 'ALLOCATION',
  COMPLIANCE = 'COMPLIANCE',
  GENERAL = 'GENERAL',
  INDICATION = 'INDICATION',
  MESSAGING = 'MESSAGING',
}

type OfferingNoteBase = {
  note: string;
  subject: Subject;
};

export type OfferingNoteCreate = OfferingNoteBase;
export type OfferingNoteUpdate = OfferingNoteBase & {
  id: UUID;
};

export type OfferingNote = OfferingNoteBase & {
  id: UUID;
  lastEditedAt: ISODateTime;
  lastEditedBy: string;
};
