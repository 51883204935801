import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum SizeCapButtons {
  OFFERING_SIZE = 'OFFERING_SIZE',
  MARKET_CAP = 'MARKET_CAP',
}

export const sizeCapButtonsConfig: ButtonsConfig = {
  [SizeCapButtons.OFFERING_SIZE]: { label: 'OFFERING SIZE ($M)' },
  [SizeCapButtons.MARKET_CAP]: { label: 'MARKET CAP ($M)' },
};

export default getButtonConfigArray(sizeCapButtonsConfig);
