import styled from 'styled-components/macro';

import { SWrapper } from '../../shared/layout/ScreenContent.styles';

export const SCalendarScreen = styled.div`
  ${SWrapper} {
    /* ECM-1375: iPad scrolling bug */
    -webkit-overflow-scrolling: touch;
  }
`;

export const SContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc((100vh - 88px) - 86px);
`;
