import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import KeyValueItem from '../../shared/components/KeyValueItem';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../../shared/OfferingProfileCardItem';
import { useGetAtmUnderwritingTermsSectionData } from '../model/Atm.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

export const AtmUnderWritingTermsWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const terms = useGetAtmUnderwritingTermsSectionData(offeringProfile);
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('xl'));
  const title = 'Underwriting Terms';

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container columnSpacing={2}>
            <Grid item minWidth={theme => theme.spacing(32.5)} xs={isSmallScreen ? 12 : 3}>
              <KeyValueItem size="small" label={terms[0].name} value={terms[0].value} />
            </Grid>
            <Grid item xs={isSmallScreen ? 12 : 7}>
              <KeyValueItem size="small" label={terms[1].name} value={terms[1].value} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
