import { Box } from '@cmg/common';
import React from 'react';

import { heatmapBreakDownBy, heatmapPerformanceColumns } from '../../../constants-heatmap-columns';
import HeatMap from '../../HeatMap';

type Props = {
  chartData?: {
    items: any[];
    max_value: number;
    min_value: number;
  };
};

const WidgetAllocationPerformanceHeatMapBySector: React.FC<Props> = ({ chartData }) => {
  if (!chartData) {
    return null;
  }

  return (
    <div>
      <Box.Header title="Allocation Weighted Aftermarket Performance Heatmap" />

      <Box.Content>
        <HeatMap
          columns={heatmapPerformanceColumns}
          data={chartData}
          valueKey="value"
          breakDownBy={heatmapBreakDownBy.SECTOR}
        />
      </Box.Content>
    </div>
  );
};

export default WidgetAllocationPerformanceHeatMapBySector;
