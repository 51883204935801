import { SelectField } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

import { TreeSelectField } from '../../../../common/components/form/selects/TreeSelect';
import { AverageMethodControlField } from './components/average-method-control/AverageMethodControl';

export const SColumn = styled.div`
  display: flex;
  align-items: flex-end;

  & > * {
    margin-bottom: 0;
  }
`;

export const SControls = styled.div`
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
`;

export const StyledPerformanceMetricControl = styled(SelectField)`
  min-width: 175px;
  align-self: flex-end;
  margin-right: 0;
`;

export const StyledAverageMethodControl = styled(AverageMethodControlField)`
  min-width: 200px;
  margin-left: 15px;
  margin-right: 0;
`;

export const StyledSectorControl = styled(TreeSelectField)`
  min-width: 200px;
`;

export const SOfferingSizeControl = styled.div`
  > * {
    min-width: 175px;
    margin-bottom: 0;
  }

  .range-input-wrapper {
    display: block;
    max-width: 175px;
  }
`;

/**
 * Mobile styles
 */

const mobileFullWidth = css`
  width: 100%;

  & * {
    width: 100%;
  }
`;

export const SMobileControls = styled(SControls)`
  width: 100%;
`;

export const StyledMobileAverageMethodControl = styled(AverageMethodControlField)`
  margin-top: 10px;

  ${mobileFullWidth}
`;

export const StyledMobileSectorControl = styled(TreeSelectField)`
  width: 100%;
`;

export const SMobileOfferingSizeControl = styled.div`
  ${mobileFullWidth}
`;

export const StyledMobilePerformanceMetricControl = styled(SelectField)`
  width: 100%;
`;
