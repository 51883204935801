import { urlUtil } from '@cmg/common';

import { getAppSettings } from '../../config/appSettings';

/**
 * Instead of hard coding external URLs that our app links to, we define them in
 * a common location.
 */
const externalRouteFactory = {
  myProfile: {
    getUrlPath: () => {
      const appSettings = getAppSettings();
      const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
      const url = urlUtil.addSubdomainToUrl(appSettings.env.identitySpaBaseUrl, accountSubdomain);
      return `${url}/profile`;
    },
  },
  accountSettings: {
    getUrlPath: () => {
      const appSettings = getAppSettings();
      const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
      const url = urlUtil.addSubdomainToUrl(appSettings.env.identitySpaBaseUrl, accountSubdomain);
      return `${url}/account`;
    },
  },
  xcSpaUrl: {
    getUrlPath: () => {
      const appSettings = getAppSettings();
      const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
      const url = urlUtil.addSubdomainToUrl(appSettings.env.xcSpaBaseUrl || '', accountSubdomain);
      return url;
    },
  },
  privacyPolicy: {
    getUrlPath: () => {
      const appSettings = getAppSettings();
      const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
      const url = urlUtil.addSubdomainToUrl(appSettings.env.identitySpaBaseUrl, accountSubdomain);
      return `${url}/privacy-policy`;
    },
  },
};

export default externalRouteFactory;
