import { Icon, Popover } from '@cmg/common';
import React, { useState } from 'react';

import { NotificationActionType } from '../../../../../types/domain/notifications/constants';
import NotificationFilterGroup from './NotificationFilterGroup';
import { SNotificationFilterBadge, SNotificationsFilterButton } from './NotificationFilters.styles';

type Props = {
  filters: NotificationActionType[];
  availableFilters: NotificationActionType[];
  forwardedRef: React.Ref<HTMLDivElement>;
  onChange: (actionTypes: NotificationActionType[]) => void;
};

export const NotificationFilters: React.FC<Props> = ({
  onChange,
  filters,
  availableFilters,
  forwardedRef,
}) => {
  const [visible, setVisible] = useState(false);

  const onAddFilterChange = (actionTypes: NotificationActionType[]) => {
    onChange([
      // remove duplicate actionTypes from selected filters
      ...filters.filter(f => !actionTypes.includes(f)),
      // push actionTypes onto selected filters
      ...actionTypes,
    ]);
  };

  const onRemoveFilterChange = (actionTypes: NotificationActionType[]) => {
    // remove actionTypes from selected filters
    onChange(filters.filter(f => !actionTypes.includes(f)));
  };

  // only show filters which are in their summary
  const filterAvailable = (types: NotificationActionType[]) =>
    types.filter(type => availableFilters.includes(type));

  // props for each filter group
  const filterHandlers = { onAddFilterChange, onRemoveFilterChange };

  return (
    <Popover
      trigger={visible ? 'hover' : 'click'}
      placement="bottom"
      visible={visible}
      disabled={availableFilters.length === 0}
      onVisibleChange={visible => setVisible(visible)}
      content={
        <div ref={forwardedRef}>
          <NotificationFilterGroup
            label={<b>All Notifications</b>}
            actionTypes={filterAvailable([
              NotificationActionType.NEW_REPORT_SHARED,
              NotificationActionType.NEW_IOI_ADDED,
              NotificationActionType.NEW_OFFERING_NOTE_SHARED,
              NotificationActionType.FINAL_ALLOCATION_ENTERED,
            ])}
            selected={filters}
            {...filterHandlers}
            hideTree
          />
        </div>
      }
    >
      <SNotificationsFilterButton onClick={() => setVisible(true)}>
        <Icon name="filter" variant="light" />
        {filters.length > 0 && filters.length !== availableFilters.length && (
          <SNotificationFilterBadge>{filters.length}</SNotificationFilterBadge>
        )}
      </SNotificationsFilterButton>
    </Popover>
  );
};

export default NotificationFilters;
