import { ToastManager } from '@cmg/common';
import React from 'react';

import {
  CompanySearch_IssuerFragment,
  useCompanySearch_IssuerListLazyQuery,
} from '../graphql/__generated__/CompanySearch_IssuerList';
import type { CompanyOption } from '../types';

export type SearchIssuerFilterHookResult = {
  searchOptions: (searchTerm: string) => Promise<CompanyOption[]>;
};

/**
 * Issuer search functionality for CompanySearch widget
 *
 * To workaround the fact business requires case insensitive startsWith-based filtering
 * but the graphql query supports case insensitivity only with like-based filtering:
 * - query `primarySymbol` with uppercased `startsWith` GQL filter since it's always uppercase
 * - query `name` with `like` + `startsWith` on 1st char (both upper and lower) GQL filters
 *   and post-process the results with JS filter to simluate actual `startsWith`
 */
export const useIssuerSearch = (): SearchIssuerFilterHookResult => {
  const [searchIssuers] = useCompanySearch_IssuerListLazyQuery();

  const searchOptions = React.useCallback(
    async (newSearchTerm: string) => {
      // see the need for uppercase in the hook's JSDoc
      const searchTerm = newSearchTerm.toUpperCase();

      const issuers: CompanySearch_IssuerFragment[] = await searchIssuers({
        variables: {
          searchTerm: searchTerm!,
          searchTermFirstCharUpper: searchTerm?.charAt(0),
          searchTermFirstCharLower: searchTerm?.charAt(0).toLowerCase(),
        },
      })
        .then(({ data }) => {
          return (data?.issuers?.items || []).filter(
            issuer =>
              (issuer.primarySymbol && issuer.primarySymbol.startsWith(searchTerm)) ||
              (issuer.name && issuer.name.toUpperCase().startsWith(searchTerm))
          );
        })
        .catch(() => {
          ToastManager.error('Error occurred while loading issuers');
          return [];
        });

      return issuers.reduce<CompanyOption[]>((companyOptions, { id, name, primarySymbol }) => {
        if (id !== null && id !== undefined) {
          companyOptions.push({ value: id, label: name!, ticker: primarySymbol! });
        }
        return companyOptions;
      }, []);
    },
    [searchIssuers]
  );

  return { searchOptions };
};
