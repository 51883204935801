import { getOperationName } from '@apollo/client/utilities';
import { Icon, ToastManager } from '@cmg/common';
import React, { useState } from 'react';

import { CustomSectorType } from '../../../../../graphql/__generated__';
import { Shared_CustomSectorListDocument } from '../../../../shared/sectors/graphql/__generated__/Shared_CustomSectors';
import { useSectorsGrid_AddCustomSectorMutation } from '../graphql/__generated__/SectorsGrid_CustomSectors';
import SectorEdit from './SectorEdit';
import { StyledButton } from './SectorsGridComponents.styles';

type Props = {
  parentId?: string;
};

export const SectorAdd = ({ parentId }: Props) => {
  const [editing, setEditing] = useState(false);

  const [addCustomSector, { loading }] = useSectorsGrid_AddCustomSectorMutation({
    refetchQueries: [getOperationName(Shared_CustomSectorListDocument)!],
    onError: error => {
      const messages = error.graphQLErrors
        .map(({ extensions, message }) =>
          extensions ? extensions.errors.map(e => e.message) : message
        )
        .flat();
      console.error('CustomSector addition failed', error);
      ToastManager.error(
        `Sector addition failed: ${messages.length ? messages.join('; ') : error.message}`
      );
    },
  });

  const handleAdd = async (name: string) => {
    await addCustomSector({
      variables: {
        data: {
          name,
          type: parentId ? CustomSectorType.SubSector : CustomSectorType.Sector,
          parentId,
        },
      },
    });
    setEditing(false);
  };

  if (editing) {
    return (
      <React.Fragment>
        <SectorEdit parentId={parentId} loading={loading} onEdit={handleAdd} />
        <StyledButton onClick={() => setEditing(false)} disabled={loading}>
          Cancel
        </StyledButton>
      </React.Fragment>
    );
  }

  return (
    <StyledButton onClick={() => setEditing(true)}>
      <Icon name="plus" fixedWidth size="1x" />
      Add {parentId && 'Sub '}Sector
    </StyledButton>
  );
};

export default SectorAdd;
