import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  getOrderByType,
  orderByTypes,
  resolveGroupByProps,
  resolveOrderByProps,
} from '../table-helpers';

export default class OrderGroup extends Component {
  static propTypes = {
    groupBy: PropTypes.string,
    groupByOrderType: PropTypes.string,
    children: PropTypes.func.isRequired,
    orderBy: PropTypes.string,
    orderByType: PropTypes.string,
    onOrderChange: PropTypes.func,
    visibleColumns: PropTypes.array.isRequired,
    /**
     * Set to false if you dont want orderBy to be set on initial render only,
     * all future updates wont affect orderBy prop. Default value is true.
     *
     * The preferred way to control sorting is using redux state rather than this prop.
     */
    updateOrderBy: PropTypes.bool,
  };

  static defaultProps = {
    onOrderChange: () => {},
    updateOrderBy: true,
  };

  constructor(props) {
    super(props);

    const { groupBy, groupByOrderType, orderBy, orderByType, visibleColumns } = this.props;

    this.state = {
      ...resolveGroupByProps(groupBy, groupByOrderType, visibleColumns),
      ...resolveOrderByProps(orderBy, orderByType),
    };
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    const { groupBy, groupByOrderType, orderBy, orderByType, visibleColumns } = prevProps;
    const { updateOrderBy } = nextProps;

    if (
      groupBy !== nextProps.groupBy ||
      groupByOrderType !== nextProps.groupByOrderType ||
      orderBy !== nextProps.orderBy ||
      orderByType !== nextProps.orderByType ||
      visibleColumns.length !== nextProps.visibleColumns.length
    ) {
      this.setState({
        ...resolveGroupByProps(
          nextProps.groupBy,
          nextProps.groupByOrderType,
          nextProps.visibleColumns
        ),
        ...(updateOrderBy ? resolveOrderByProps(nextProps.orderBy, nextProps.orderByType) : {}),
      });
    }
  }

  handleGroupBy = clickedGroupBy => {
    const { groupBy, orderBy, orderByType } = this.state;
    const groupByToSet = groupBy === clickedGroupBy ? '' : clickedGroupBy;
    const defaultOrderByType = groupByToSet === orderBy ? orderByType : orderByTypes[0];

    this.setState({
      groupBy: groupByToSet,
      groupByOrderType: defaultOrderByType,
    });
  };

  handleOrderBy = orderBy => {
    const { orderByType, groupByOrderType } = getOrderByType({
      currentGroupByOrderType: this.state.groupByOrderType,
      currentOrderBy: this.state.orderBy,
      currentOrderByType: this.state.orderByType,
      orderBy,
      groupBy: this.state.groupBy,
    });

    this.setState({
      groupByOrderType,
      orderBy,
      orderByType,
    });

    this.props.onOrderChange(orderBy, orderByType);
  };

  render() {
    const { children } = this.props;
    const { groupBy, groupByOrderType, orderBy, orderByType } = this.state;

    return children({
      groupBy,
      groupByOrderType,
      handleGroupBy: this.handleGroupBy,
      handleOrderBy: this.handleOrderBy,
      orderBy,
      orderByType,
    });
  }
}
