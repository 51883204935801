import React from 'react';

import routeFactory from '../../../../../common/util/routeFactory';
import { NotificationResourceType } from '../../../../../types/domain/notifications/constants';
import BaseNotification, { BaseNotificationProps, getResourceId } from './BaseNotification';

const NewSharedReportNotification: React.FC<BaseNotificationProps> = props => {
  const {
    resources,
    publishedBy: { email },
    messageField1,
  } = props.notification;

  // path to specific resource information
  const reportId = getResourceId(resources, NotificationResourceType.SHARED_REPORT);

  const resourcePath = routeFactory.datalab.getUrlPath({
    screen: 'report', // doesn't need to be a DatalabScreen constant
    query: { report: reportId },
  });

  return (
    <BaseNotification
      {...props}
      resourcePath={resourcePath}
      title={`"${messageField1}"`}
      message={`New report shared by ${email}`}
    />
  );
};

export default NewSharedReportNotification;
