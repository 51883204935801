import { NumericInput } from '@cmg/common';
import React from 'react';

type Props = {
  value?: number;
  onChange: (value: number | null) => void;
};

export const IntegerInputControl: React.FC<Props> = ({ onChange, value }) => {
  const handleOnChange = newValue => {
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  return <NumericInput onChange={handleOnChange} value={value} precision={0} />;
};

export default IntegerInputControl;
