import { PrimaryButton } from '@cmg/common';
import { saveReportToolbarButtonSelector } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

const SSaveButtonWrapper = styled.div`
  display: flex;
  margin: 0 15px;
`;

const StyledSavePrimaryButton = styled(PrimaryButton)`
  &:focus {
    box-shadow: none;
  }
`;

type Props = { onClick: () => void };

const SaveButton: React.FC<Props> = ({ onClick }) => (
  <SSaveButtonWrapper>
    <StyledSavePrimaryButton testId={saveReportToolbarButtonSelector.testId} onClick={onClick}>
      Save
    </StyledSavePrimaryButton>
  </SSaveButtonWrapper>
);

export default SaveButton;
