import styled, { css } from 'styled-components/macro';

const hiddenCss = css`
  visibility: hidden;
  display: none;
`;

export const SHeaderItem = styled.div<{ hidden?: boolean }>`
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  color: ${({ theme }) => theme.text.color.light};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.small};
  text-transform: uppercase;
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;

  ${({ hidden }) => (hidden ? hiddenCss : '')}
`;

export const SOrderArrow = styled.span<{ hidden?: boolean }>`
  margin-left: 5px;

  ${({ hidden }) => (hidden ? hiddenCss : '')}
`;

export const SOrdering = styled.span<{ highlighted?: boolean }>`
  cursor: pointer;
  user-select: none;

  ${({ highlighted, theme }) =>
    highlighted &&
    css`
      color: ${theme.color.blue12};
    `};
`;

export const SGrouping = styled(SOrdering)<{ isActive?: boolean }>`
  height: 11px;
  margin: 0 7px 0 3.5px;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.brand.color.light};
    `};

  &:hover {
    color: ${({ theme }) => theme.text.color.link};
  }
`;
