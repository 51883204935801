import { MultiSelect } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { Option } from '../../selects/types';

type Props = {
  value?: string[];
  onChange: (value: string[] | null) => void;
};
type Control = (options: Option[]) => React.FC<Props>;

export const createEnumControl: Control =
  options =>
  ({ onChange, value }) => {
    const handleOnChange = newValue => {
      if (!isEqual(newValue, value)) {
        onChange(newValue.length === 0 ? null : newValue);
      }
    };

    return <MultiSelect onChange={handleOnChange} value={value} options={options} />;
  };
