import { all } from 'redux-saga/effects';

import { calendarSaga } from '../../features/calendar/ducks';
import { datalabSaga } from '../../features/datalab/ducks';
import { myDashboardSaga } from '../../features/my-dashboard/ducks';
import { ioiTrackerSaga } from '../../features/offering-dl/ioi-tracker/ducks';
import { offeringDlSaga } from '../../features/offering-dl/shared/ducks';
import { offeringNotesSaga } from '../../features/offering-dl/shared/offering-notes/ducks';
import { underwriterCreditsSaga } from '../../features/offering-dl/underwriter-credits/ducks';
import { sharedSaga } from '../../features/shared/ducks';
import { notificationsSaga } from '../../features/shared/notifications/ducks';

export default function* rootSaga() {
  yield all([
    calendarSaga(),
    underwriterCreditsSaga(),
    ioiTrackerSaga(),
    offeringDlSaga(),
    offeringNotesSaga(),
    sharedSaga(),
    datalabSaga(),
    notificationsSaga(),
    myDashboardSaga(),
  ]);
}
