import { ColDef, numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import UnderwriterRenderer from './underwriter-renderer/UnderwriterRenderer';

export type GlobalLeagueItem = {
  ranking: number;
  managerId: string;
  managerName: string;
  offeringCount: number;
  numberOfBook: number;
  numberOfNonBook: number;
  dollarVolumeUsd: number;
};

export const getColumnById = (columnName: string): ColDef<GlobalLeagueItem, any> =>
  dataGridColumns[columnName] ?? {};

const numberColumn = {
  valueFormatter: ({ value }) =>
    numericUtil.getDisplayValueForNumber(value as number | undefined, 0),
};

export type GlobalLeagueGridKeys = keyof GlobalLeagueItem;

export const dataGridColumns: Record<
  string,
  ColDef<GlobalLeagueItem> & { field: GlobalLeagueGridKeys }
> = {
  ranking: {
    field: 'ranking',
    headerName: 'Ranking',
    valueGetter: ({ data, node }) =>
      data && node && !isNil(node?.rowIndex) ? `${node.rowIndex + 1}` : '',
    maxWidth: 100,
    sortable: false,
  },
  manager: {
    field: 'managerName',
    headerName: 'Underwriter',
    cellRendererFramework: ({ data }) => <UnderwriterRenderer data={data} />,
    flex: 1,
  },
  numberOfBook: {
    field: 'numberOfBook',
    headerName: '# of Book',
    ...numberColumn,
    type: 'rightAligned',
    maxWidth: 150,
  },
  numberOfNonBook: {
    field: 'numberOfNonBook',
    headerName: '# of Non-Book',
    ...numberColumn,
    type: 'rightAligned',
    maxWidth: 150,
  },
  offeringCount: {
    field: 'offeringCount',
    headerName: 'Total Offerings',
    ...numberColumn,
    type: 'rightAligned',
    maxWidth: 150,
  },
  dollarVolume: {
    field: 'dollarVolumeUsd',
    headerName: 'Dollar Volume',
    valueGetter: ({ data }) => numericUtil.formatCurrencyInMillions(data?.dollarVolumeUsd),
    type: 'rightAligned',
    flex: 1,
  },
};
