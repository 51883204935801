import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { timeUtil } from '@cmg/common';
import { filterToolbar } from '@cmg/e2e-selectors';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UserReport } from '../../../../types/domain/report/userReport';
import AdditionalActionsMenuButton from './AdditionalActionsMenuButton';
import ClearButton from './ClearButton';
import DatalabReportSelectField from './DatalabReportSelectField';
import {
  SDatalabReportSelectFieldWrapper,
  SReportModifiedDate,
  SToolbarSection,
  StyledForm,
} from './DatalabToolbarForm.styles';
import FiltersToggleButton from './FilterPanelToggleButton';
import IPOBacklogDisabledModal from './IPOBacklogDisabledModal';
import SaveButton from './SaveButton';
import SaveButtonGroup from './SaveButtonGroup';

type LocationState = {
  isRedirectedFromIPOBacklog?: boolean;
};

type Props = {
  isFilterPanelExpanded: boolean;
  onReset: () => void;
  toggleFilters: () => void;
  filterCount: number;
  canSaveReport: boolean;
  report?: UserReport;
  onLoadReport: (values: { reportId: UserReport['id'] }) => void;
  onCreateReport: () => void;
  onSaveReport: () => void;
  onShareReport: () => void;
  onFavoriteReport: () => void;
};

export const DatalabToolbarForm: React.FC<Props> = React.memo(
  ({
    onReset,
    toggleFilters,
    isFilterPanelExpanded,
    filterCount,
    report,
    canSaveReport,
    onLoadReport,
    onCreateReport,
    onSaveReport,
    onShareReport,
    onFavoriteReport,
  }) => {
    const { userPermissions } = useAuth();
    const isSavedReport = !!report;
    const canUserShareReports = checkPermissions(userPermissions, [
      permissionsByEntity.SharedReports.FULL,
    ]);

    const [showIPOBacklogDisabledModal, setShowIPOBacklogDisabledModal] =
      React.useState<boolean>(false);

    const history = useHistory();
    const location = useLocation<LocationState>();

    React.useEffect(() => {
      if (location.state?.isRedirectedFromIPOBacklog) {
        setShowIPOBacklogDisabledModal(true);
        history.replace({});
      }
    }, [location, history]);

    return (
      <StyledForm data-test-id={filterToolbar.testId}>
        <SToolbarSection alignItems="flex-end">
          <SDatalabReportSelectFieldWrapper>
            <DatalabReportSelectField
              reportId={report ? report.id : undefined}
              onChange={(reportId: UserReport['id'] | null) => {
                if (reportId) {
                  onLoadReport({ reportId });
                } else {
                  onReset();
                }
              }}
            />
          </SDatalabReportSelectFieldWrapper>
          <SToolbarSection alignItems="center">
            <FiltersToggleButton
              isFilterPanelExpanded={isFilterPanelExpanded}
              filterCount={filterCount}
              onClick={toggleFilters}
            />
            {report && (
              <SReportModifiedDate>
                <span>Last Modified by {report.lastModified.name}</span>
                {report.lastModified.date && (
                  <span> {timeUtil.formatAsDisplayDate(report.lastModified.date)}</span>
                )}
              </SReportModifiedDate>
            )}
          </SToolbarSection>
        </SToolbarSection>
        <SToolbarSection alignItems="flex-end">
          {(isSavedReport || canSaveReport) && <ClearButton onClick={onReset} />}
          {!isSavedReport && canSaveReport && <SaveButton onClick={onCreateReport} />}
          {isSavedReport && (
            <React.Fragment>
              <SaveButtonGroup
                isReportShared={report!.share.isShared}
                isSaveButtonEnabled={canSaveReport}
                onSaveReport={onSaveReport}
                onCreateReport={onCreateReport}
              />
              <AdditionalActionsMenuButton
                showShareReport={
                  canUserShareReports &&
                  (report!.share.sharedWith.length > 0 || report!.share.canShareWith.length > 0)
                }
                isReportFavorited={report!.isFavorite}
                onFavoriteReport={onFavoriteReport}
                onShareReport={onShareReport}
              />
            </React.Fragment>
          )}
        </SToolbarSection>
        <IPOBacklogDisabledModal
          show={showIPOBacklogDisabledModal}
          onHide={() => setShowIPOBacklogDisabledModal(false)}
        />
      </StyledForm>
    );
  }
);

export default DatalabToolbarForm;
