import { DateRangeField, DateRangePresetOptions, DateRangePresetTypes } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import {
  comparisonPeriodControlUpdated,
  selectTimePeriodControl,
  timePeriodControlUpdated,
} from '../ducks';
import {
  FormDateRange,
  isValidDateRange,
  mapDefaultDatePeriod,
  mapSubmittedComparisonDatePeriod,
  mapSubmittedDateRangeValue,
} from './periodControls.model';

export const SLabel = styled.label`
  padding-right: 8px;
`;

export const StyledForm = styled(Form)`
  margin-bottom: 10px;
`;

export const timePeriodControlSchema = Yup.object().shape({
  timePeriod: Yup.object({
    type: Yup.string().oneOf(Object.values(DateRangePresetTypes)),
    end: Yup.string().nullable(),
    start: Yup.string().nullable(),
  }).test(
    'date-range-invalid',
    'Start and End dates are required when a custom time period is provided',
    isValidDateRange
  ),
});

const mapStateToProps = state => ({
  timePeriod: selectTimePeriodControl(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onChange: timePeriodControlUpdated,
      onComparisonPeriodChange: comparisonPeriodControlUpdated,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type FormProps = { timePeriod: FormDateRange };
type OwnProps = {
  presetOptions: DateRangePresetOptions;
};
export type Props = OwnProps & StateProps & FormikProps<FormProps>;

export const TimePeriodControlForm: React.FC<Props> = ({ presetOptions, handleSubmit }) => (
  <div data-test-id={myDashboard.currentTimePeriod.testId}>
    <StyledForm>
      <SLabel>Time Period</SLabel>
      <DateRangeField
        required
        name="timePeriod"
        presetOptions={presetOptions}
        onChange={() => handleSubmit()}
      />
    </StyledForm>
  </div>
);

export const TimePeriodControlFormWithFormik = withFormik<OwnProps & StateProps, FormProps>({
  enableReinitialize: true,
  mapPropsToValues: (props: StateProps) => ({
    timePeriod: mapDefaultDatePeriod(props.timePeriod),
  }),
  handleSubmit: (values, { props }) => {
    const { onChange, onComparisonPeriodChange } = props.actions;

    onChange({
      timePeriod: mapSubmittedDateRangeValue(values.timePeriod),
    });
    onComparisonPeriodChange({
      comparisonPeriod: mapSubmittedComparisonDatePeriod(values.timePeriod),
    });
  },
  validateOnBlur: false,
  validationSchema: timePeriodControlSchema,
})(TimePeriodControlForm);

const ConnectedTimePeriodControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimePeriodControlFormWithFormik);

export default ConnectedTimePeriodControl;
