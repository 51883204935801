import { permissionsByEntity, useAuth, useCheckPermissions } from '@cmg/auth';
import { Banner, Icon, ResponsiveDrawer, ServerErrors, useInfiniteScroll } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React, { RefObject } from 'react';

import { getGraphqlOrder, getGraphqlWhere } from '../../calendar/hooks/graphql-query';
import { tabConfig as pricedCalendarConfig } from '../../calendar/tabs/PricedOfferingsCalendar';
import { useMyDashboard_PricedCalendarQuery } from '../graphql/__generated__/MyDashboard_PricedCalendar';
import RecentDealsList from './components/recent-deals-list/RecentDealsList';
import {
  SInfiniteList,
  SListInitialLoading,
  SListScrollLoading,
  SRecentDeals,
  STitle,
  StyledCalendarIcon,
} from './RecentDeals.styles';

const pageSize = 15;

const RecentDeals: React.FC = () => {
  const { oidcUserId, userPermissions } = useAuth();
  const { data, loading, error, fetchMore } = useMyDashboard_PricedCalendarQuery({
    variables: {
      skip: 0,
      take: pageSize,
      where: getGraphqlWhere({
        calendarCategory: pricedCalendarConfig.calendarCategory,
        userId: oidcUserId!,
        userPermissions,
      }),
      order: getGraphqlOrder({
        secondaryOrder: {
          orderBy: pricedCalendarConfig.defaultOrderBy,
          orderByType: pricedCalendarConfig.defaultOrderByType,
        },
        primaryOrder: {
          orderBy: pricedCalendarConfig.defaultGroupBy,
          orderByType: pricedCalendarConfig.defaultGroupByOrderType,
        },
      }),
      // DLGW GQL implementation uses standard pattern for authorization by populating
      // the result error collection with any/all authorization failures. This is a temporary
      // solution as currently FE will show error UI when error collection is populated.
      hasIoiPermission: useCheckPermissions([permissionsByEntity.Ioi.READ]),
    },
    notifyOnNetworkStatusChange: true,
  });

  const pagination = data?.offerings?.pageInfo;

  const hasNext = Boolean(!loading && pagination?.hasNextPage);
  const showInfiniteScrollLoading = Boolean(loading && pagination?.hasNextPage);
  const initialLoading = loading && !pagination;

  const onNext = () => {
    if (pagination?.hasNextPage) {
      fetchMore({
        variables: {
          skip: pagination.offset + pageSize,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...fetchMoreResult,
          offerings: {
            ...fetchMoreResult.offerings,
            items: [...previousResult!.offerings!.items!, ...fetchMoreResult!.offerings!.items!],
            pageInfo: {
              ...fetchMoreResult.offerings!.pageInfo,
            },
          },
        }),
      });
    }
  };

  const scrollRef: RefObject<HTMLDivElement> = useInfiniteScroll({
    hasNext,
    onNext,
  });

  return (
    <ResponsiveDrawer
      testId={myDashboard.showRecentDeals.testId}
      breakpoint="xxlarge"
      header={aboveBreakpoint => {
        return (
          !aboveBreakpoint && (
            <STitle>
              <StyledCalendarIcon name="calendar-alt" size="lg" />
              <h3>Recently Priced Deals</h3>
            </STitle>
          )
        );
      }}
    >
      <SRecentDeals data-test-id={myDashboard.recentDeals.testId}>
        {/* Initial loading indicator before pagination is returned */}
        {initialLoading && (
          <SListInitialLoading>
            {/** @todo replace with loading skeleton */}
            <Icon name="spinner-third" size="2x" fixedWidth spin />
          </SListInitialLoading>
        )}
        {error && (
          <Banner showIcon={false} variant="error">
            <ServerErrors
              error={{
                code: '',
                target: '',
                details: [],
                message: error.message,
              }}
            />
          </Banner>
        )}
        {!initialLoading && (
          <SInfiniteList ref={scrollRef} data-test-id={myDashboard.recentDealsList.testId}>
            <RecentDealsList recentDeals={data?.offerings?.items ?? []} />
          </SInfiniteList>
        )}
        {showInfiniteScrollLoading && (
          <SListScrollLoading>
            <Icon name="spinner-third" size="lg" fixedWidth spin />
          </SListScrollLoading>
        )}
      </SRecentDeals>
    </ResponsiveDrawer>
  );
};

export default RecentDeals;
