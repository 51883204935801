import { Row } from '@cmg/common';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

const StyledRow = styled(Row)`
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const SLabel = styled.div`
  margin-top: 1px;
  font-size: ${({ theme }) => theme.text.size.small};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.text.color.light};
`;
const SValue = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  white-space: nowrap;
`;

type Props = {
  className?: string;
  children?: ReactNode;
};

class ValuesGrid extends React.Component<Props> {
  static Label = SLabel;
  static Value = SValue;
  static Row = StyledRow;

  render() {
    return <div {...this.props} />;
  }
}

export default ValuesGrid;
