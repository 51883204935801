import {
  DLGWCornerstoneInvestmentRowItem,
  DLGWMarketPulseTableRow,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import React from 'react';

import {
  CornerstoneInvestorType,
  CornerstoneInvestorTypeLabels,
  CornerstoneShareholderTypeLabels,
} from '../../../../../types/domain/offering/constants';
import { getFormattedCurrencyInMillion } from '../../../model/utils';
import { HeaderLabel } from './CornerstoneTooltip.styles';

type CurrencyCode = DLGWMarketPulseTableRow['pricing_currency_code'];
export type Props = {
  cornerstone_investments: DLGWCornerstoneInvestmentRowItem[];
  cornerstone_investors_offering_participants_count?: DLGWMarketPulseTableRow['cornerstone_investors_offering_participants_count'];
  cornerstone_offering_participants_amount?: DLGWMarketPulseTableRow['cornerstone_offering_participants_amount'];
  investment_type: CornerstoneInvestorType;
  pricing_currency_code: CurrencyCode;
};

const renderIndividual = (
  investment: DLGWCornerstoneInvestmentRowItem,
  idx: number,
  pricing_currency_code: CurrencyCode
) => {
  const investor = investment.investors?.[0];
  if (!investor) {
    return null;
  }
  return (
    <tr className="individual-investment" key={idx}>
      {investor && (
        <React.Fragment>
          <td>{investor.shareholder?.firm_name}</td>
          <td>
            {investor.shareholder?.sponsor_type
              ? CornerstoneShareholderTypeLabels[investor.shareholder?.sponsor_type]
              : ''}
          </td>
          <td>{investor.is_existing_cornerstone_investor ? 'No' : 'Yes'}</td>
          <td>
            {getFormattedCurrencyInMillion(pricing_currency_code, investment.individual_amount)}
          </td>
        </React.Fragment>
      )}
    </tr>
  );
};

const renderCombined = (
  combinedInvestments: DLGWCornerstoneInvestmentRowItem,
  idx: number,
  pricing_currency_code: CurrencyCode
) => {
  const investors = combinedInvestments.investors ?? [];

  return (
    <React.Fragment key={idx}>
      <tr className="combined-amount-row">
        <td colSpan={3}>Combined Amount</td>
        <td>
          {getFormattedCurrencyInMillion(
            pricing_currency_code,
            combinedInvestments.individual_amount
          )}
        </td>
      </tr>
      {investors.map((investor, idx) => (
        <tr className="combined-row" key={idx}>
          <td>{investor.shareholder?.firm_name}</td>
          <td>
            {investor.shareholder?.sponsor_type
              ? CornerstoneShareholderTypeLabels[investor.shareholder?.sponsor_type]
              : ''}
          </td>
          <td>{investor.is_existing_cornerstone_investor ? 'No' : 'Yes'}</td>
          <td />
        </tr>
      ))}
    </React.Fragment>
  );
};

const CornerstoneTooltipTableContent: React.FC<Props> = ({
  cornerstone_investors_offering_participants_count,
  cornerstone_offering_participants_amount,
  cornerstone_investments,
  investment_type,
  pricing_currency_code,
}) => {
  const individualInvestments = cornerstone_investments.filter(
    c => (c.investors?.length ?? 0) === 1
  );
  const combinedInvestments = cornerstone_investments.filter(c => (c.investors?.length ?? 0) > 1);

  return cornerstone_investments.length >= 1 ? (
    <React.Fragment>
      <thead>
        <tr>
          <HeaderLabel className="cornerstone-type">{`Cornerstone\u2013${CornerstoneInvestorTypeLabels[investment_type]}`}</HeaderLabel>
        </tr>
        <tr>
          <th style={{ width: '50%' }}>Investor</th>
          <th style={{ width: '15%' }}>Type</th>
          <th style={{ width: '19%' }}>New Investor</th>
          <th style={{ width: '16%' }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="total-row">
          <td colSpan={3}>{`Total ${cornerstone_investors_offering_participants_count}`}</td>
          <td>
            {getFormattedCurrencyInMillion(
              pricing_currency_code,
              cornerstone_offering_participants_amount
            )}
          </td>
        </tr>
        {individualInvestments.length > 0 &&
          individualInvestments.map((investment, idx) =>
            renderIndividual(investment, idx, pricing_currency_code)
          )}

        {combinedInvestments.length > 0 &&
          combinedInvestments.map((investment, idx) =>
            renderCombined(investment, idx, pricing_currency_code)
          )}
      </tbody>
    </React.Fragment>
  ) : null;
};

export default CornerstoneTooltipTableContent;
