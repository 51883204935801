import styled from 'styled-components/macro';

export const SIssuerButtons = styled.div`
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: 85%;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  > * {
    margin-right: 4px;
  }
`;

export const SIssuerTags = styled.div`
  margin-top: -3px;
`;
