import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum MarketCapAdtvButtons {
  MARKET_CAP = 'market_cap',
  ADTV = 'adtv',
}

export const marketCapAdtvButtonsConfig: ButtonsConfig = {
  [MarketCapAdtvButtons.MARKET_CAP]: { label: 'MARKET CAP' },
  [MarketCapAdtvButtons.ADTV]: { label: 'ADTV' },
};

export default getButtonConfigArray(marketCapAdtvButtonsConfig);
