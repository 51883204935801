import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const SNotificationMetadata = styled.div<{ unread: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 18px;
  background-color: ${({ unread, theme }) =>
    unread ? theme.color.blue6 : theme.background.color.light};
`;

export const SNotificationContent = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
`;

export const SNotificationContentInner = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 5px;
`;

export const SNotificationTitleGroup = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

export const SNotificationTitle = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.text.weight.bold};
    font-size: ${theme.text.size.mediumSmall};
  `}
`;

export const SNotificationIssuer = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.text.weight.regular};
    font-size: ${theme.text.size.mediumSmall};
  `};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SNotificationTicker = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.text.weight.bold};
    font-size: ${theme.text.size.mediumSmall};
    color: ${theme.text.color.white};
    background: ${theme.color.gray3};
  `}

  padding: 1px 4px;
  border-radius: 3px;
  margin-right: 8px;
`;

export const SNotificationOfferingType = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.text.size.mediumSmall};
    font-weight: ${theme.text.weight.medium};
  `}
  margin-left: 8px;
`;

export const SNotificationMessage = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.text.weight.regular};
    font-size: ${theme.text.size.mediumSmall};
    color: ${theme.text.color.light};
  `}
  margin-right: 5px;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SNotificationGroup = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.text.weight.regular};
    font-size: ${theme.text.size.small};
  `}
  span {
    padding: 0 3px;
  }
`;

export const SNotificationPublishedOn = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.text.size.medium};
    color: ${theme.text.color.light};
  `}
`;

export const SNotificationUnread = styled.div<{ unread: boolean }>`
  visibility: ${({ unread }) => (unread ? 'visible' : 'hidden')};
  height: 50px;
  padding: 15px;
`;

export const StyledLink = styled(Link)`
  &&& {
    text-decoration: none;
    color: ${({ theme }) => theme.text.color.dark};
  }
`;

export const SNotificationWrapper = styled.div<{ unread: boolean }>`
  ${({ theme, unread }) => css`
    background-color: ${unread ? theme.color.blue15 : theme.background.color.white};
    border-bottom: ${theme.border.width.small} solid ${theme.border.color.dark};
    border: ${theme.border.width.small} solid ${theme.color.gray7};
    border-radius: ${theme.border.radius.small};
  `}

  margin-bottom: 1px;

  &:hover {
    ${SNotificationUnread} {
      visibility: visible;
    }
  }
`;
