import { DropdownButton, Icon } from '@cmg/common';
import React from 'react';

import {
  SDescription,
  SHeaderItem,
  SIconWrapper,
  SLabel,
  SValue,
  SValueWrapper,
} from './SummaryHeader.styles';

export enum Aggregation {
  min = 'Min',
  max = 'Max',
  mean = 'Mean',
  median = 'Median',
  sum = 'Sum',
}

type AggregationType = { [key in keyof typeof Aggregation]?: number | null };

export type Props = {
  aggregations: AggregationType;
  formatter?: (value: number) => string | JSX.Element;
};

const SummaryHeader: React.FC<Props> = props => {
  const { aggregations, formatter } = props;
  const [selectedAggregation, setSelectedAggregation] = React.useState<Aggregation>(
    Aggregation.mean
  );
  const options = React.useMemo(
    () =>
      Object.entries(aggregations)
        .filter(([, value]) => value !== null && typeof value === 'number')
        .map(([key, value]) => ({
          label: () => (
            <SDescription>
              <SLabel>{Aggregation[key]}</SLabel>
              {formatter && value && <SValue>{formatter(value)}</SValue>}
            </SDescription>
          ),
          onClick: () => setSelectedAggregation(Aggregation[key]),
        })),
    [aggregations, formatter]
  );

  React.useEffect(() => {
    if (!aggregations[Aggregation.mean.toLowerCase()]) {
      setSelectedAggregation(Aggregation[Object.keys(aggregations)[0]]);
    }
  }, [aggregations]);

  const renderHeader: JSX.Element | null = React.useMemo(() => {
    const currentHeader = (
      <SValueWrapper>
        <div>{selectedAggregation}</div>
        {formatter && aggregations[selectedAggregation?.toLowerCase()] && (
          <div>{formatter(aggregations[selectedAggregation?.toLowerCase()])}</div>
        )}
      </SValueWrapper>
    );
    if (options.length <= 0) {
      return null;
    } else if (options.length === 1) {
      return currentHeader;
    }
    return (
      <DropdownButton
        // @ts-ignore problem with DropdownButton props, it expected a specific type of button
        buttonComponent={SHeaderItem}
        options={options}
      >
        {currentHeader}
        <SIconWrapper>
          <Icon name="angle-down" size="lg" />
        </SIconWrapper>
      </DropdownButton>
    );
  }, [aggregations, formatter, options, selectedAggregation]);

  return <React.Fragment>{renderHeader}</React.Fragment>;
};

export default SummaryHeader;
