import { AuthAccessTokenProp, withAccessToken } from '@cmg/auth';
import { apiTypes, Column, PageError, Row } from '@cmg/common';
import { ioiTrackerScreenSelector } from '@cmg/e2e-selectors';
import isEqual from 'lodash/isEqual';
import React from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidV4 } from 'uuid';

import { modifyObjectInArray, removeFromArray } from '../../../../common/helpers/helpers';
import { IoiType } from '../../../../types/domain/offering/ioi/constants';
import { IoiOffering, Offering } from '../../../../types/domain/offering/offering';
import ScreenContent from '../../../shared/layout/ScreenContent';
import ScreenHeader from '../../../shared/layout/ScreenHeader';
import OfferingOverviewGrid from '../../shared/components/OfferingOverviewGrid';
import OfferingSubHeader from '../../shared/components/OfferingSubHeader';
import OfferingNotesContainer from '../../shared/offering-notes/containers/OfferingNotesContainer';
import downloadIoi from '../model/downloadIoi';
import {
  hasErrors,
  validateFirmIoIForm,
  validateFundForm,
  validateFundIoIForm,
} from '../model/ioi-form-helpers';
import FirmIndicationWidget from './FirmIndicationWidget';
import InternalDemandWidget from './InternalDemandWidget';
import { buildFundIOIs, buildIOIs, getNewFundIoI, getNewIoI } from './IoiTrackerScreen.model';
import { SIoiTrackerScreen } from './IoiTrackerScreen.styles';

enum WidgetName {
  FirmIndication = 'FirmIndication',
  InternalDemand = 'InternalDemand',
}

const SContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc((100vh - 88px) - 86px);
`;

type OwnProps = {
  error: apiTypes.GenericServerError | null;
  funds: any[];
  ioiFunds: {
    allocations: any[];
    iois: any[];
  };
  allocation?: object;
  ioiOffering: IoiOffering;
  offering?: Offering;
  iois: any[];
  ioiType: IoiType | string;
  offeringId: string;
  canEdit: boolean;
  canManageFirmIois: boolean;
  fundIoisPermissions: {
    canViewFundIOIs?: boolean;
    canManageFundIOIs?: boolean;
  };
  saveAllocationAndIois: (params: {
    offeringId: string;
    allocationAndIois: {
      allocation: any;
      iois: any[];
      ioiType: IoiType;
    };
  }) => void;
  updateIoiFunds: (params: { offeringId: string; allocations: any[]; iois: any[] }) => void;
  addFund: () => void;
};

type Props = OwnProps & AuthAccessTokenProp;

type State = {
  isEditingInternalDemand: boolean;
  isEditingFirmIndication: boolean;
  ioisFormErrors: any;
  fundIoisFormErrors: any;
  fundAllocationsFormErrors: any;
  iois: any[];
  allocation: any;
  fundIois: any[];
  fundAllocations: any[];
  ioiType: IoiType | string;
};

export class IoiTrackerScreen extends React.Component<Props, State> {
  // State handling in this component is a mess.
  // * Prop changes are causing changes to state <- anti pattern for the amount this is happening
  // * State is initialized in componentDidMount and in a class property, instead of the constructor
  // * Way too much going on in general in this component.

  constructor(props) {
    super(props);

    this.state = {
      isEditingInternalDemand: false,
      isEditingFirmIndication: false,
      ioisFormErrors: {},
      fundIoisFormErrors: {},
      fundAllocationsFormErrors: {},
      iois: [],
      allocation: {},
      fundIois: [],
      fundAllocations: [],
      ioiType: '',
    };
  }

  componentDidMount() {
    const firmLevelProps = {
      allocation: this.props.allocation,
      iois: this.props.iois,
      ioiType: this.props.ioiType,
    };

    const fundLevelProps = this.props.ioiFunds;

    this.initializeFirmLevelState(
      firmLevelProps.allocation,
      firmLevelProps.iois,
      firmLevelProps.ioiType
    );
    this.initializeFundLevelState(
      fundLevelProps.allocations,
      fundLevelProps.iois,
      firmLevelProps.ioiType
    );
  }

  /**
   * Update form state when passed props change. This causes a reset of user entered values in the form.
   * Thus it is important that for example: if the firm level values change, but fund level don't, we don't want to reset
   * the form state of fund level, erasing any unsaved changes.
   * Refactoring all of this to formik would be the more elegant solution.
   */
  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    const firmLevelPrevProps = {
      allocation: prevProps.allocation,
      iois: prevProps.iois,
      ioiType: prevProps.ioiType,
    };
    const firmLevelNextProps = {
      allocation: nextProps.allocation,
      iois: nextProps.iois,
      ioiType: nextProps.ioiType,
    };

    const fundLevelPrevProps = prevProps.ioiFunds;
    const fundLevelNextProps = nextProps.ioiFunds;

    if (!isEqual(firmLevelPrevProps, firmLevelNextProps)) {
      this.initializeFirmLevelState(
        firmLevelNextProps.allocation,
        firmLevelNextProps.iois,
        firmLevelNextProps.ioiType
      );
    }

    if (!isEqual(fundLevelPrevProps, fundLevelNextProps)) {
      this.initializeFundLevelState(
        fundLevelNextProps.allocations,
        fundLevelNextProps.iois,
        firmLevelNextProps.ioiType
      );
    }
  }

  /**
   * Set form state based on passed in firm level data.
   */
  initializeFirmLevelState = (firmAllocation, firmIois, ioiType) => {
    const builtIois = buildIOIs(firmIois);

    this.setState({
      iois: builtIois,
      allocation: firmAllocation || {},
    });

    this.changeIoiType(ioiType);
    this.resetIoisFormErrors(builtIois);
  };

  /**
   * Set form state based on passed in fund level data.
   */
  initializeFundLevelState = (fundAllocations, fundIois, ioiType) => {
    const builtFundIois = buildFundIOIs(fundIois);

    if (this.allocationEnabled(ioiType)) {
      fundAllocations.forEach(fundAllocation => {
        const emptyFundIois = builtFundIois.filter(
          // @ts-ignore unitType is enum hence should not be an empty string
          fundIoi => fundIoi.unitType === '' && fundIoi.fundId === fundAllocation.fundId
        );

        if (emptyFundIois.length === 0) {
          const newFundIoi = getNewFundIoI(fundAllocation.fundId);
          // @ts-ignore id and unitType has invalid types
          builtFundIois.push(newFundIoi);
        }
      });
    }

    this.setState({
      fundIois: builtFundIois,
      fundAllocations,
    });

    this.resetFundIoisFormErrors(builtFundIois);
    this.resetFundAllocationsFormErrors(fundAllocations);
  };

  changeIoiType = ioiType => {
    this.setState({ ioiType });
  };

  onAllocationSave = () => {
    const { offeringId, saveAllocationAndIois } = this.props;
    const { ioiType, iois, allocation } = this.state;

    saveAllocationAndIois({
      offeringId,
      allocationAndIois: {
        allocation,
        iois,
        ioiType: ioiType as IoiType,
      },
    });
  };

  resetIoisFormErrors = iois => {
    this.setState({
      ioisFormErrors: iois.reduce(
        (acc, ioi) => ({
          ...acc,
          [ioi.uuid]: {},
        }),
        {}
      ),
    });
  };

  changeAllocation = (newValues, callback) => {
    this.setState(
      {
        allocation: {
          ...this.state.allocation,
          ...newValues,
        },
      },
      callback
    );
  };

  onChangeIoIUnitType = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        dollars: undefined,
        percentage: undefined,
        shares: undefined,
        calculateShares: undefined,
        price: undefined,
        realDemandDollars: undefined,
        realDemandPercentage: undefined,
        realDemandShares: undefined,
        unitType: value === null ? undefined : value,
      }),
    });
  };

  onChangeIoIPricingType = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        dollars: undefined,
        percentage: undefined,
        shares: undefined,
        calculateShares: undefined,
        price: undefined,
        realDemandDollars: undefined,
        realDemandPercentage: undefined,
        realDemandShares: undefined,
        unitType: null,
        limitPrice: null,
        limitType: null,
        pricingType: value,
      }),
      ioisFormErrors: {
        ...this.state.ioisFormErrors,
        [uuid]: {
          ...this.state.ioisFormErrors[uuid],
          pricingType: '',
        },
      },
    });
  };

  onChangeIoILimitType = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        limitPrice: null,
        limitPercentage: null,
        limitType: value === null ? undefined : value,
      }),
    });
  };

  onChangeIoIDollars = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        dollars: value,
      }),
    });
  };

  onChangeIoIPercentage = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        percentage: value,
      }),
    });
  };

  onChangeIoIShares = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        shares: value,
      }),
    });
  };

  onChangeIoIRealDemandDollars = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        realDemandDollars: value,
      }),
    });
  };

  onChangeIoIRealDemandPercentage = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        realDemandPercentage: value,
      }),
    });
  };

  onChangeIoIRealDemandShares = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        realDemandShares: value,
      }),
    });
  };

  onChangeIoILimitPrice = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        limitPrice: value,
      }),
    });
  };

  onChangeIoILimitPercentage = (value, uuid) => {
    this.setState({
      iois: modifyObjectInArray(this.state.iois, i => i.uuid === uuid, {
        limitPercentage: value,
      }),
    });
  };

  onClickAddIoIButton = () => {
    const newIoi = getNewIoI();

    this.onClickEditButton(WidgetName.FirmIndication);

    this.setState({
      iois: [...this.state.iois, newIoi],
      ioisFormErrors: {
        ...this.state.ioisFormErrors,
        [newIoi.uuid]: {},
      },
    });
  };

  onClickDeleteIoIButton = uuid => {
    this.setState({
      iois: removeFromArray(this.state.iois, i => i.uuid === uuid),
      ioisFormErrors: {
        ...this.state.ioisFormErrors,
        [uuid]: {},
      },
    });
  };

  onClickSaveInternalDemand = () => {
    const { funds, fundIoisPermissions } = this.props;
    const { fundAllocations, fundIoisFormErrors } = this.state;
    const fundIois = this.state.fundIois.filter(ioi => ioi.unitType !== '');

    const updatedFundIoisFormErrors = fundIois
      .map(ioi => ({
        ioi,
        formErrors: validateFundIoIForm(ioi),
      }))
      .reduce(
        (obj, { ioi, formErrors }) => ({
          ...obj,
          [ioi.uuid]: formErrors,
        }),
        {}
      );

    const fundAllocationsFormErrors = fundAllocations
      .map(allocation => ({
        allocation,
        formErrors: validateFundForm(allocation, funds),
      }))
      .reduce(
        (obj, { allocation, formErrors }) => ({
          ...obj,
          [allocation.fundId]: formErrors,
        }),
        {}
      );

    this.setState({
      fundAllocationsFormErrors,
      fundIoisFormErrors: {
        ...fundIoisFormErrors,
        ...updatedFundIoisFormErrors,
      },
    });

    if (!hasErrors(updatedFundIoisFormErrors) && !hasErrors(fundAllocationsFormErrors)) {
      const formattedfundIois = fundIois.map(ioi => ({
        ...ioi,
        shares: ioi.shares,
        dollars: ioi.dollars,
      }));

      const formattedfundAllocations = fundAllocations.map(allocation => ({
        ...allocation,
        shares: allocation.shares,
      }));

      if (fundIoisPermissions.canManageFundIOIs) {
        this.onIoiFundsSave(formattedfundIois, formattedfundAllocations);
      }

      this.setState({
        isEditingInternalDemand: false,
      });
    }
  };

  onClickSaveFirmIndication = () => {
    const { iois } = this.state;
    const { canManageFirmIois, ioiOffering } = this.props;
    const { offeringType } = ioiOffering;

    const ioisFormErrors = iois
      .map(ioi => ({
        ioi,
        formErrors: validateFirmIoIForm(ioi, offeringType),
      }))
      .reduce(
        (obj, { ioi, formErrors }) => ({
          ...obj,
          [ioi.uuid]: formErrors,
        }),
        {}
      );

    this.setState({
      ioisFormErrors,
    });

    if (!hasErrors(ioisFormErrors)) {
      if (canManageFirmIois) {
        this.onAllocationSave();
      }

      this.setState({
        isEditingFirmIndication: false,
      });
    }
  };

  onIoiFundsSave = (iois, allocations) => {
    const { offeringId, updateIoiFunds } = this.props;

    updateIoiFunds({
      offeringId,
      iois,
      allocations,
    });
  };

  onClickEditButton = (widgetName: WidgetName) => {
    if (widgetName === WidgetName.FirmIndication) {
      return this.setState({ isEditingFirmIndication: true });
    }
    return this.setState({ isEditingInternalDemand: true });
  };

  onClickCancelButton = (widgetName: WidgetName) => {
    const firmLevelProps = {
      allocation: this.props.allocation,
      iois: this.props.iois,
      ioiType: this.props.ioiType,
    };

    const fundLevelProps = this.props.ioiFunds;

    if (widgetName === WidgetName.FirmIndication) {
      this.initializeFirmLevelState(
        firmLevelProps.allocation,
        firmLevelProps.iois,
        firmLevelProps.ioiType
      );
      return this.setState({ isEditingFirmIndication: false });
    }

    if (widgetName === WidgetName.InternalDemand) {
      this.initializeFundLevelState(
        fundLevelProps.allocations,
        fundLevelProps.iois,
        firmLevelProps.ioiType
      );
      return this.setState({ isEditingInternalDemand: false });
    }
  };

  onClickExportIoI = () => {
    const { ioiOffering, accessToken } = this.props;

    return downloadIoi(ioiOffering.id, accessToken);
  };

  resetFundIoisFormErrors = fundIois => {
    this.setState({
      fundIoisFormErrors: fundIois.reduce(
        (acc, ioi) => ({
          ...acc,
          [ioi.uuid]: {},
        }),
        {}
      ),
    });
  };

  resetFundAllocationsFormErrors = fundAllocations => {
    this.setState({
      fundAllocationsFormErrors: fundAllocations.reduce(
        (acc, allocation) => ({
          ...acc,
          [allocation.fundId]: {},
        }),
        {}
      ),
    });
  };

  onClickDeleteFundIoiButton = ioi => {
    this.setState(
      {
        fundIois: removeFromArray(this.state.fundIois, i => i.uuid === ioi.uuid),
        fundIoisFormErrors: {
          ...this.state.fundIoisFormErrors,
          [ioi.uuid]: {},
        },
      },
      () => this.ensureEmptyFundIoiRecords()
    );
  };

  onClickAddFundLevelIoiButton = () => {
    const fundId = uuidV4();
    const newFundIoi = getNewFundIoI(fundId);
    this.onClickEditButton(WidgetName.InternalDemand);

    this.setState({
      fundIois: [...this.state.fundIois, newFundIoi],
      fundAllocations: [
        ...this.state.fundAllocations,
        {
          fundId,
        },
      ],
      fundIoisFormErrors: {
        ...this.state.fundIoisFormErrors,
        [newFundIoi.uuid]: {},
      },
      fundAllocationsFormErrors: {
        ...this.state.fundAllocationsFormErrors,
        [fundId]: {},
      },
    });
  };

  onClickDeleteFundLevelButton = fundId => {
    this.setState({
      fundAllocations: removeFromArray(this.state.fundAllocations, a => a.fundId === fundId),
      fundIois: removeFromArray(this.state.fundIois, i => i.fundId === fundId),
      fundIoisFormErrors: {
        ...this.state.fundIoisFormErrors,
        [fundId]: {},
      },
      fundAllocationsFormErrors: {
        ...this.state.fundAllocationsFormErrors,
        [fundId]: {},
      },
    });
  };

  ensureEmptyFundIoiRecords = () => {
    const { fundAllocations, ioiType } = this.state;
    const fundIois = [...this.state.fundIois];
    const fundIoisFormErrors = { ...this.state.fundIoisFormErrors };

    if (this.allocationEnabled(ioiType)) {
      fundAllocations.forEach(fundAllocation => {
        const emptyFundIois = fundIois.filter(
          fundIoi => fundIoi.unitType === '' && fundIoi.fundId === fundAllocation.fundId
        );

        if (emptyFundIois.length === 0) {
          const newFundIoi = getNewFundIoI(fundAllocation.fundId);
          fundIois.push(newFundIoi);
          fundIoisFormErrors[newFundIoi.uuid] = {};
        }
      });

      this.setState({
        fundIois,
        fundIoisFormErrors,
      });
    }
  };

  setFundIoiProp = (uuid, propName, value) => {
    this.setState(
      {
        fundIois: modifyObjectInArray(this.state.fundIois, i => i.uuid === uuid, {
          [propName]: value,
        }),
      },
      () => this.ensureEmptyFundIoiRecords()
    );
  };

  setFundAllocationProp = (fundId, propName, value) => {
    this.setState({
      fundAllocations: modifyObjectInArray(this.state.fundAllocations, a => a.fundId === fundId, {
        [propName]: value,
      }),
    });

    // Change also ioi's fundIds to preserve the connection of ioi and allocation
    if (propName === 'fundId') {
      this.setState({
        fundIois: this.state.fundIois.reduce(
          (acc, fundIoi) => [
            ...acc,
            {
              ...fundIoi,
              fundId: fundIoi.fundId === fundId ? value : fundIoi.fundId,
            },
          ],
          []
        ),
        fundAllocationsFormErrors: {
          ...this.state.fundAllocationsFormErrors,
          [fundId]: {},
          [value]: { ...this.state.fundAllocationsFormErrors[fundId] },
        },
      });
    }
  };

  allocationEnabled = ioiType => !ioiType || ioiType === '';

  render() {
    const {
      offeringId,
      canEdit,
      ioiOffering,
      offering,
      funds,
      addFund,
      fundIoisPermissions,
      error,
    } = this.props;

    const {
      ioiType,
      isEditingFirmIndication,
      isEditingInternalDemand,
      iois,
      allocation,
      ioisFormErrors,
      fundIois,
      fundAllocations,
      fundAllocationsFormErrors,
      fundIoisFormErrors,
    } = this.state;

    const isOnPlatform = offering?.onPlatform;
    const { offeringType } = ioiOffering;

    return (
      <div data-test-id={ioiTrackerScreenSelector.testId}>
        <SIoiTrackerScreen>
          <ScreenHeader />

          <ScreenContent renderSubHeader={() => offering && <OfferingSubHeader />}>
            {error && <PageError error={error} />}
            {!error && ioiOffering && (
              <SContainer>
                <Row>
                  <Column xs={24} sm={16}>
                    <OfferingOverviewGrid offering={ioiOffering} />

                    <FirmIndicationWidget
                      canEdit={canEdit}
                      allocationEnabled={this.allocationEnabled(ioiType)}
                      changeIoiType={this.changeIoiType}
                      changeAllocation={this.changeAllocation}
                      onChangeIoIUnitType={this.onChangeIoIUnitType}
                      onChangeIoIDollars={this.onChangeIoIDollars}
                      onChangeIoIPercentage={this.onChangeIoIPercentage}
                      onChangeIoIShares={this.onChangeIoIShares}
                      onChangeIoIPricingType={this.onChangeIoIPricingType}
                      onChangeIoILimitPrice={this.onChangeIoILimitPrice}
                      onChangeIoIRealDemandDollars={this.onChangeIoIRealDemandDollars}
                      onChangeIoIRealDemandPercentage={this.onChangeIoIRealDemandPercentage}
                      onChangeIoIRealDemandShares={this.onChangeIoIRealDemandShares}
                      onClickDeleteIoIButton={this.onClickDeleteIoIButton}
                      onClickAddIoIButton={this.onClickAddIoIButton}
                      onClickEditButton={() => this.onClickEditButton(WidgetName.FirmIndication)}
                      onClickCancelButton={() =>
                        this.onClickCancelButton(WidgetName.FirmIndication)
                      }
                      onClickSaveButton={this.onClickSaveFirmIndication}
                      onClickExportIoI={this.onClickExportIoI}
                      ioiType={ioiType}
                      isEditing={isEditingFirmIndication}
                      iois={iois}
                      ioisFormErrors={ioisFormErrors}
                      allocationShares={allocation.shares ?? null}
                      allocationRanking={allocation.ranking}
                      allocationInvestment={allocation.investment}
                      isOnPlatform={isOnPlatform}
                      offeringType={offeringType}
                      onChangeIoILimitType={this.onChangeIoILimitType}
                      onChangeIoILimitPercentage={this.onChangeIoILimitPercentage}
                    />

                    {fundIoisPermissions.canViewFundIOIs && (
                      <InternalDemandWidget
                        funds={funds}
                        fundIois={fundIois}
                        fundAllocations={fundAllocations}
                        isEditing={isEditingInternalDemand}
                        addFund={addFund}
                        onIoiFundsSave={this.onIoiFundsSave}
                        canEdit={fundIoisPermissions.canManageFundIOIs}
                        canAdd={this.allocationEnabled(ioiType)}
                        fundAllocationsFormErrors={fundAllocationsFormErrors}
                        fundIoisFormErrors={fundIoisFormErrors}
                        setFundAllocationProp={this.setFundAllocationProp}
                        onClickDeleteFundLevelButton={this.onClickDeleteFundLevelButton}
                        setFundIoiProp={this.setFundIoiProp}
                        onClickDeleteFundIoiButton={this.onClickDeleteFundIoiButton}
                        onClickAddFundLevelIoiButton={this.onClickAddFundLevelIoiButton}
                        onClickEditButton={() => this.onClickEditButton(WidgetName.InternalDemand)}
                        onClickCancelButton={() =>
                          this.onClickCancelButton(WidgetName.InternalDemand)
                        }
                        onClickSaveButton={this.onClickSaveInternalDemand}
                      />
                    )}
                  </Column>
                  <Column xs={24} sm={8}>
                    <OfferingNotesContainer offeringId={offeringId} />
                  </Column>
                </Row>
              </SContainer>
            )}
          </ScreenContent>
        </SIoiTrackerScreen>
      </div>
    );
  }
}

export default withAccessToken(IoiTrackerScreen);
