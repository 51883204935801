import { LinkButton } from '@cmg/common';
import { Form } from 'formik';
import styled from 'styled-components/macro';

export const SControlsWrapper = styled.div`
  height: 25px;
  display: flex;
  justify-content: center;
`;

export const SLabel = styled.div`
  font-size: ${({ theme }) => theme.text.size.tiny};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 24px;
  text-transform: uppercase;
`;

export const SCurrentShareUsersWrapper = styled.div`
  padding: 2px 0 10px;
`;

export const StyledLinkButton = styled(LinkButton)<{ isVisible?: boolean }>`
  color: ${({ theme }) => theme.text.color.light};
  padding: 0 5px;
  margin: 0 3px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const SDeleteButtonPopoverContent = styled.div`
  max-width: 500px;
`;

export const SSpacer = styled.div`
  margin: 0 5px;
`;

export const SDeleteButtonPopoverConfirmationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledForm = styled(Form)`
  width: 200px;
`;
