import { PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import { SButtonWrapper, SRankingTag } from './RankingTag.styles';

export const options = [
  { label: 'Top 5', value: 'top_5' },
  { label: 'Top 10', value: 'top_10' },
  { label: 'Top 15', value: 'top_15' },
  { label: 'Top 25', value: 'top_25' },
  { label: 'Top 50', value: 'top_50' },
  { label: 'Over 50', value: 'over_50' },
];

type Props = {
  editable?: boolean;
  ranking?: string;
  onChangeRanking?: (value: string) => void;
};

export default class RankingTag extends React.Component<Props> {
  renderEditView() {
    const { ranking, onChangeRanking, editable } = this.props;

    return (
      <SRankingTag>
        {options.map((option, index) => {
          const Button = option.value === ranking ? PrimaryButton : SecondaryButton;

          return (
            <SButtonWrapper key={index}>
              <Button
                disabled={!editable}
                onClick={() => onChangeRanking && onChangeRanking(option.value)}
              >
                {option.label}
              </Button>
            </SButtonWrapper>
          );
        })}
      </SRankingTag>
    );
  }

  renderSingleButton() {
    const { label } = options.filter(option => option.value === this.props.ranking)[0];

    return (
      <SButtonWrapper>
        <SRankingTag>
          <PrimaryButton disabled>{label}</PrimaryButton>
        </SRankingTag>
      </SButtonWrapper>
    );
  }

  render() {
    if (this.props.editable) {
      return this.renderEditView();
    }

    if (this.props.ranking) {
      return this.renderSingleButton();
    }

    return null;
  }
}
