import { manageCompaniesSelector } from '@cmg/e2e-selectors';

import ScreenContent from '../../shared/layout/ScreenContent';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import CompaniesGrid from './companies-grid/CompaniesGrid';

export const CompaniesRoute = () => {
  return (
    <div data-test-id={manageCompaniesSelector.testId}>
      <ScreenHeader />
      <ScreenContent>
        <CompaniesGrid />
      </ScreenContent>
    </div>
  );
};

export default CompaniesRoute;
