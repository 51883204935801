import { checkPermissions, permissionsByEntity } from '@cmg/auth';

import {
  OfferingFilterInput,
  OfferingStatus,
  OfferingType,
} from '../../../../graphql/__generated__';
import { CalendarCategory } from '../../../../types/domain/calendar/constants';
import {
  lastSaturday,
  startOfCurrentYear,
  weeksAgo,
  weeksFromNow,
  yearsAgo,
  yearsFromNow,
} from './date-helpers';

export default function getCalendarCategoryGraphqlWhere({
  userId,
  userPermissions,
  calendarCategory,
}: {
  userId: string;
  userPermissions: string[];
  calendarCategory: CalendarCategory;
}): OfferingFilterInput {
  switch (calendarCategory) {
    case CalendarCategory.LIVE:
      /*
       * Psuedo-code to help understand the GraphQL Where clause logic:
       *
       * status == LIVE
       *   && ((publicFilingDate >= ago20y && publicFilingDate <= future1y) || publicFilingDate == null)
       */
      return {
        attributes: {
          status: { eq: OfferingStatus.Live },
          or: [
            { publicFilingDate: { gte: yearsAgo(20), lte: yearsFromNow(1) } },
            { publicFilingDate: { eq: null } },
          ],
        },
      };

    case CalendarCategory.PRICED:
      /*
       * Psuedo-code to help understand the GraphQL Where clause logic:
       *
       * status == PRICED
       *   && ((firstTradeDate >= ago2w && firstTradeDate <= future1y)
       *        || (firstTradeDate == null && type != CONVERTIBLE)
       *      )
       */
      return {
        attributes: {
          status: { eq: OfferingStatus.Priced },
          or: [
            { firstTradeDate: { gte: weeksAgo(2), lte: yearsFromNow(1) } },
            { firstTradeDate: { eq: null }, type: { neq: OfferingType.Convertible } },
          ],
        },
      };

    case CalendarCategory.FILED:
      /*
       * Psuedo-code to help understand the GraphQL Where clause logic:
       *
       * status == FILED
       *   && ((publicFilingDate >= ago20y && publicFilingDate <= future1y) || publicFilingDate == null)
       */
      return {
        attributes: {
          status: { eq: OfferingStatus.Filed },
          or: [
            { publicFilingDate: { gte: yearsAgo(20), lte: yearsFromNow(1) } },
            { publicFilingDate: { eq: null } },
          ],
        },
      };

    case CalendarCategory.POSTPONED:
      /*
       * Psuedo-code to help understand the GraphQL Where clause logic:
       *
       * [POSTPONED, WITHDRAWN].Contains(status)
       *   && ((publicFilingDate >= ago20y && publicFilingDate <= future20y) || publicFilingDate == null)
       *   && postponedDate >= yearStart
       *   && postponedDate <= future20y
       */
      return {
        attributes: {
          status: { in: [OfferingStatus.Postponed, OfferingStatus.Withdrawn] },
          or: [
            { publicFilingDate: { gte: yearsAgo(20), lte: yearsFromNow(20) } },
            { publicFilingDate: { eq: null } },
          ],
          postponedDate: { gte: startOfCurrentYear(), lte: yearsFromNow(20) },
        },
      };

    case CalendarCategory.LOCK_UP_EXPIRATION:
      /*
       * Psuedo-code to help understand the GraphQL Where clause logic:
       *
       * status == PRICED
       *   && ((pricingDate >= ago20y && pricingDate <= future1y) || pricingDate == null)
       *   && lockUpExpirationDate >= lastSaturday
       *   && lockUpExpirationDate <= future2w
       */
      return {
        attributes: {
          status: { eq: OfferingStatus.Priced },
          or: [
            { pricingDate: { gte: yearsAgo(20), lte: yearsFromNow(1) } },
            { pricingDate: { eq: null } },
          ],
          lockUpExpirationDate: { gte: lastSaturday(), lte: weeksFromNow(2) },
        },
      };

    /**
     * Only for users without read:ioi permission
     * See the calendar tabs logic:
     * @type getCalendarTabs
     */
    case CalendarCategory.MY_OFFERINGS:
      return getWhereClauseForIsFollowing(userId, userPermissions);

    /**
     * Only for users with read:ioi permission
     * See the calendar tabs logic:
     * @type getCalendarTabs
     * However we still guard against a user landing here by direct URL, and handle the case they do not have that perm
     */
    case CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS:
      const hasIoi = checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]);
      if (!hasIoi) {
        // guard against a user landing on MY_OFFERINGS_WITH_ALLOCATIONS by direct URL
        return getWhereClauseForIsFollowing(userId, userPermissions);
      }

      /*
       * Psuedo-code to help understand the GraphQL Where clause logic:
       *
       * userIsFollowing || userHasPrivateDataForOffering
       */
      return {
        or: [
          getWhereClauseForIsFollowing(userId, userPermissions),
          getWhereClauseForMyOfferingsBasedOnPrivateData(userPermissions),
        ],
      };

    case CalendarCategory.DRAFT:
    default:
      throw new Error('Argument out of range');
  }
}

export const getWhereClauseForMyOfferingsBasedOnPrivateData = (
  userPermissions: string[]
): OfferingFilterInput => {
  const hasIoi = checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]);
  const hasFundIoi = checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ]);
  const privateDataOr: OfferingFilterInput[] = [];

  if (hasIoi) {
    privateDataOr.push({ ioiNotes: { any: true } });
    privateDataOr.push({ allocations: { any: true } });
    privateDataOr.push({ indicationsOfInterest: { any: true } });
  }

  if (hasFundIoi) {
    privateDataOr.push({ fundAllocations: { any: true } });
    privateDataOr.push({ fundIndicationsOfInterest: { any: true } });
  }

  /*
   * Psuedo-code to help understand the GraphQL Where clause logic:
   *
   * (status !== PRICED
   *   || (firstTrade >= ago2w && firstTradeDate <= future1y)
   *   || (firstTrade == null && type != CONVERTIBLE)
   * ) && (ioiNotes.Any()
   *         || allocations.Any()
   *         || indicationsOfInterest.Any()
   *         || fundAllocations.Any() // only if user has fundIOI permission
   *         || fundIndicationsOfInterest.Any() // only if user has fundIOI permission
   * )
   */
  return {
    and: [
      {
        or: [
          { status: { neq: OfferingStatus.Priced } },
          { firstTradeDate: { gte: weeksAgo(2), lte: yearsFromNow(1) } },
          { firstTradeDate: { eq: null }, type: { neq: OfferingType.Convertible } },
        ],
      },
      { or: privateDataOr },
    ],
  };
};

export const getWhereClauseForIsFollowing = (
  userId: string,
  userPermissions: string[]
): OfferingFilterInput => {
  /*
   * Note: This filter building based on permissions is a BE concern, need to refactor how this works
   */
  const hasSharedFollowedOfferings = checkPermissions(userPermissions, [
    permissionsByEntity.SharedFollowedOfferings.READ,
  ]);

  const some = hasSharedFollowedOfferings ? { isShared: { eq: true } } : { userId: { eq: userId } };

  /*
   * Psuedo-code to help understand the GraphQL Where clause logic:
   *
   * userOfferings.Any(uo => uo.isShared == true)
   *   OR
   * userOfferings.Any(uo => uo.UserId == userId)
   */
  return { userOfferings: { some } };
};
