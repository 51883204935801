import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  SxProps,
  Theme,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import {
  useGetUnderwriterLockupData,
  useGetUnderwriterNamesData,
  useGetUnderwriterTermsData,
} from '../model/Shared.model';
import OfferingDetailRowItem from '../OfferingDetailRowItem';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};
const dataDividerStyle: SxProps<Theme> = {
  border: 0.5,
  color: theme => theme.palette.divider,
  marginY: 1,
};
const groupSize: RegularBreakpoints = { xl: 6, lg: 6, md: 12, sm: 12 };
const sectionSize: RegularBreakpoints = { xs: 12 };

const UnderwriterWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const underwriterTerms = useGetUnderwriterTermsData(offeringProfile);
  const underwriterNames = useGetUnderwriterNamesData(offeringProfile);
  const underwriterLockups = useGetUnderwriterLockupData(offeringProfile);
  const title = 'Underwriter Terms';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container item alignItems="baseline" spacing={2}>
            <Grid item {...groupSize}>
              {underwriterTerms.map(({ name, value, value2 }) => {
                return (
                  <OfferingDetailRowItem key={name} label={name} value={value} value2={value2} />
                );
              })}
            </Grid>
            <Grid container item {...groupSize}>
              <Grid item {...sectionSize}>
                {underwriterNames.map(({ name, value }) => {
                  return <OfferingDetailRowItem key={name} label={name} value={value} />;
                })}
              </Grid>
              <Grid item {...sectionSize}>
                <Divider sx={dataDividerStyle} />
                {underwriterLockups.map(({ name, value }) => {
                  return <OfferingDetailRowItem key={name} label={name} value={value} />;
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UnderwriterWidget;
