import { CheckboxTree, DropdownContainer, Group, Option } from '@cmg/common';
import React from 'react';

import {
  SFooter,
  SHeader,
  StyledFilterInput,
  StyledInputIcon,
} from './DropdownCheckboxTree.styles';

export type Props = {
  /**
   * Dropdown label, will open popover on click.
   */
  label: React.ReactNode;
  /**
   * List of selected values.
   */
  values?: string[];
  /**
   * List of values selected by default, used to reset selection to its default state.
   */
  defaultValues?: string[];
  /**
   * List of disabled values.
   */
  disabled?: string[];
  /**
   * Enable/disable search input, disabled by default.
   */
  searchable?: boolean;
  /**
   * If supplied, hides the 'all, none, recommended' links in CheckboxTree
   */
  hideBulkActions?: boolean;
  /**
   * Test ID for this component
   */
  testId?: string;
  /**
   * Tree structure of available options
   */
  items: (Group | Option)[];
  /**
   * Callback to be called on selection changes.
   */
  onChange?: (values: string[]) => void;
  groupToggler?: () => React.ReactElement | null;
  /**
   * Style variant of dropdown popover.
   */
  popoverVariant?: 'LIGHT' | 'DARK';
  /**
   * Custom header. Has default text styling so most usages should only pass in a string.
   */
  header?: React.ReactNode;
  /**
   * Allows custom footer rendering.
   */
  footer?: (args: {
    isVisible: boolean;
    setVisibility: (visibility: boolean) => void;
  }) => React.ReactElement;
};

/**
 * Dropdown with tree checkbox structure component.
 * TODO move to @cmg/common
 */
const DropdownCheckboxTree: React.FC<Props> = ({
  label,
  disabled,
  popoverVariant,
  searchable = false,
  hideBulkActions = false,
  values,
  defaultValues,
  items,
  onChange,
  header,
  footer,
  testId,
  groupToggler,
}) => {
  return (
    <DropdownContainer
      content={args => (
        <React.Fragment>
          {header && <SHeader>{header}</SHeader>}
          <CheckboxTree
            testId={testId}
            maxHeight={searchable ? 342 : 380}
            searchable={searchable}
            hideBulkActions={hideBulkActions}
            onChange={onChange}
            renderSearchInput={({ onChange: onSearch, value }) => (
              <StyledFilterInput
                value={value || ''}
                onChange={onSearch}
                placeholder="Filter"
                prefix={<StyledInputIcon name="filter" />}
                suffix={
                  <span onClick={() => onSearch('')}>
                    <StyledInputIcon name="times-circle" />
                  </span>
                }
              />
            )}
            options={items}
            values={values}
            defaultValues={defaultValues}
            disabled={disabled}
            renderHeader={groupToggler}
          />
          {footer && <SFooter>{footer(args)}</SFooter>}
        </React.Fragment>
      )}
      popoverVariant={popoverVariant}
      autoAdjustOverflow={!searchable}
    >
      {label}
    </DropdownContainer>
  );
};

export default DropdownCheckboxTree;
