import { Box, numericUtil } from '@cmg/common';
import React, { useMemo } from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors } from '../../../colors';
import valueAmountButtons, {
  ValueAmountButtons,
} from '../../../model/buttons/value-amount-buttons';
import { isInternationalOfferingsOn } from '../../../model/utils';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: any;
  title?: string;
};

const transformData = (chartData: any = [], activeBtn: any): BarChartProps['data'] => {
  const dataKey = activeBtn.value;
  const isIntlOn = isInternationalOfferingsOn();

  return {
    labels: chartData.map((item: any) => item.label),
    datasets: [
      ...[
        {
          label: 'IPOs',
          data: chartData.map((item: any) => item.values[dataKey].ipo),
          backgroundColor: barChartColors.ipoBg,
        },
        {
          label: 'Marketed FOs',
          data: chartData.map((item: any) => item.values[dataKey].marketFO),
          backgroundColor: barChartColors.lighter,
        },
        {
          label: 'Overnight FOs',
          data: chartData.map((item: any) => item.values[dataKey].overnightFO),
          backgroundColor: barChartColors.light,
        },
        {
          label: 'Registered Blocks',
          data: chartData.map((item: any) => item.values[dataKey].registeredBlocks),
          backgroundColor: barChartColors.dark,
        },
        {
          label: 'Unregistered Blocks',
          data: chartData.map((item: any) => item.values[dataKey].unregisteredBlocks),
          backgroundColor: barChartColors.darker,
        },
      ],
      ...(isIntlOn
        ? [
            {
              label: 'Rights',
              data: chartData.map((item: any) => item.values[dataKey].rights),
              backgroundColor: barChartColors.grayer,
            },
          ]
        : []),
    ],
  };
};

const getOptions = (activeBtn: any): BarChartProps['options'] => {
  return {
    datasets: {
      bar: {
        borderRadius: 0,
        categoryPercentage: 0.3,
        maxBarThickness: 100, // overrides default thin bars
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value: any) => {
            if (typeof value === 'string') {
              return value;
            }

            if (activeBtn.value === ValueAmountButtons.VALUE) {
              return numericUtil.formatCurrencyInBillions(value);
            }
            return numericUtil.formatInteger(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const { label } = tooltipItem.dataset;
            const value = tooltipItem.parsed.y;

            return `${label ? `${label}: ` : ''}${
              activeBtn.value === ValueAmountButtons.VALUE
                ? numericUtil.formatCurrencyInBillions(value)
                : numericUtil.formatInteger(value)
            }`;
          },
        },
      },
    },
  };
};

const WidgetHistoricalOfferingVolumeSnapshot: React.FC<Props> = ({ chartData = [], title }) => {
  const [activeBtn, setActiveBtn] = React.useState(valueAmountButtons[0]);

  const transformedData = useMemo(
    () => transformData(chartData, activeBtn),
    [chartData, activeBtn]
  );
  const options = useMemo(() => getOptions(activeBtn), [activeBtn]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart>
      <Box.Header title={title || 'Historical Offering Volume'} />

      <SButtonBox>
        <Toggler
          activeButton={activeBtn}
          onChange={handleButtonChange}
          buttons={valueAmountButtons}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <BarChart data={transformedData} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetHistoricalOfferingVolumeSnapshot;
