export type ButtonsConfig = {
  [key: string]: {
    label: string;
    countValue?: string;
  };
};

export const getButtonConfigArray = (config: ButtonsConfig) => {
  return Object.entries(config).map(([key, value]) => ({
    ...value,
    value: key,
  }));
};
