import { createFormikField, FieldControlProps } from '@cmg/common';
import React from 'react';

import {
  AdvancedFilterFieldConfig,
  AdvancedFilterValue,
} from '../../../../common/components/form/advanced-filter/advanced-filter.model';
import AdvancedFilter from '../../../../common/components/form/advanced-filter/AdvancedFilter';

type OwnProps = {
  // Filter fields configuration
  config: AdvancedFilterFieldConfig[];
};
type Props = FieldControlProps<AdvancedFilterValue, AdvancedFilterValue> & OwnProps;

const DatalabAdvancedFilter: React.FC<Props> = ({ value, onChange, config }) => {
  return <AdvancedFilter fieldConfigs={config} value={value} onChange={onChange} />;
};

export default DatalabAdvancedFilter;
export const DatalabAdvancedFilterField = createFormikField<
  AdvancedFilterValue,
  AdvancedFilterValue,
  HTMLInputElement,
  Props
>(DatalabAdvancedFilter);
