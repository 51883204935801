import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { applyFilters } from '../../../../common/helpers/helpers';
import { TableDataManagementType } from '../../../../features/shared/constants/constants';
import ColumnToggler from './shared/enhancers/ColumnToggler';
import OrderGroup from './shared/enhancers/OrderGroup';
import TablePagination from './shared/enhancers/TablePagination';
import {
  applyOrdering,
  getGroupedRows,
  getItemsForPage,
  getNumberOfHeaderRows,
  getRowsWithHeaderRows,
} from './shared/table-helpers';

export const TABLE_TYPES = {
  STANDARD: 'standard',
  VIRTUALIZED: 'virtualized',
};

const DEFAULT_PAGINATION = { activePage: 1, itemsPerPage: 100 };

export default class TableWrapper extends Component {
  static propTypes = {
    columnsConfig: PropTypes.array.isRequired,
    filteredRowsToSummaries: PropTypes.func,
    filters: PropTypes.array,
    groupBy: PropTypes.string, // only virtualized
    groupByOrderType: PropTypes.string, // only virtualized
    handleToggleViewableFields: PropTypes.func,
    children: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number,
    activePage: PropTypes.number,
    totalPages: PropTypes.number,
    metaData: PropTypes.object,
    orderBy: PropTypes.string,
    orderByType: PropTypes.string,
    rows: PropTypes.array.isRequired,
    summaries: PropTypes.object,
    tableType: PropTypes.oneOf(Object.values(TABLE_TYPES)).isRequired,
    visibleColumns: PropTypes.array,
    paginationType: PropTypes.oneOf([
      TableDataManagementType.CLIENT,
      TableDataManagementType.SERVER,
    ]),
    updateOrderBy: PropTypes.bool,
    onReload: PropTypes.func,
  };

  static defaultProps = {
    paginationType: TableDataManagementType.CLIENT,
    updateOrderBy: true,
    tableType: TABLE_TYPES.STANDARD,
    onReload: () => {},
  };

  handleOnReload = ({ pagination, order }) => {
    const { orderBy, orderByType, filters, onReload } = this.props;

    const config = {
      pagination,
      order: order || { orderBy, orderByType },
      filters,
    };

    onReload(config);
  };

  handleOnOrderChange = (orderBy, orderByType) => {
    const { paginationType, itemsPerPage } = this.props;

    if (paginationType === TableDataManagementType.SERVER) {
      this.handleOnReload({
        pagination: { activePage: DEFAULT_PAGINATION.activePage, itemsPerPage },
        order: { orderBy, orderByType },
      });
    }
  };

  handleOnPaginationChange = (pagination, orderGroup) => {
    if (this.props.paginationType === TableDataManagementType.SERVER) {
      const { orderBy, orderByType } = orderGroup;
      this.handleOnReload({ pagination, order: { orderBy, orderByType } });
    }
  };

  render() {
    const {
      columnsConfig,
      filteredRowsToSummaries,
      filters,
      groupBy,
      groupByOrderType,
      handleToggleViewableFields,
      children,
      activePage,
      itemsPerPage,
      totalPages,
      metaData,
      orderBy,
      orderByType,
      rows,
      summaries,
      tableType,
      visibleColumns,
      paginationType,
      updateOrderBy,
      handleGroupByToggle,
    } = this.props;
    const isGroupByColumnVisible = visibleColumns.includes(groupBy);

    return (
      <ColumnToggler
        columnsConfig={columnsConfig}
        handleToggleViewableFields={handleToggleViewableFields}
        visibleColumns={visibleColumns}
      >
        {({
          columns,
          simpleColumns,
          toggleColumnVisibility,
          visibleColumns: visibleColumnsComp,
        }) => (
          <OrderGroup
            groupBy={isGroupByColumnVisible ? groupBy : undefined}
            groupByOrderType={groupByOrderType}
            orderBy={orderBy}
            orderByType={orderByType}
            onOrderChange={this.handleOnOrderChange}
            visibleColumns={visibleColumnsComp}
            updateOrderBy={updateOrderBy}
          >
            {orderGroup => {
              const filteredRows =
                paginationType === TableDataManagementType.SERVER
                  ? rows
                  : applyFilters(rows, filters);

              let orderedRows;
              if (paginationType === TableDataManagementType.SERVER) {
                orderedRows = filteredRows;
              } else if (tableType === TABLE_TYPES.STANDARD) {
                orderedRows = applyOrdering(
                  filteredRows,
                  orderGroup.orderBy,
                  orderGroup.orderByType
                );
              } else {
                orderedRows = applyOrdering(
                  filteredRows,
                  orderGroup.orderBy,
                  orderGroup.orderByType,
                  orderGroup.groupBy,
                  orderGroup.groupByOrderType
                );
              }

              return (
                <TablePagination
                  itemsPerPage={itemsPerPage}
                  totalRows={orderedRows.length}
                  activePage={activePage}
                  totalPages={totalPages}
                  paginationType={paginationType}
                  onPaginationChange={pagination =>
                    this.handleOnPaginationChange(pagination, orderGroup)
                  }
                >
                  {pagination => {
                    const visibleRows =
                      paginationType === TableDataManagementType.SERVER
                        ? orderedRows
                        : getItemsForPage(
                            orderedRows,
                            pagination.activePage,
                            pagination.itemsPerPage
                          );

                    const visibleRowsWithHeader =
                      tableType === TABLE_TYPES.VIRTUALIZED
                        ? getRowsWithHeaderRows(
                            getGroupedRows(visibleRows, orderGroup.groupBy),
                            columns
                          )
                        : undefined;

                    const numberOfHeaderRows =
                      tableType === TABLE_TYPES.VIRTUALIZED
                        ? getNumberOfHeaderRows(columns, visibleRows)
                        : undefined;

                    const summariesComp = filteredRowsToSummaries
                      ? { ...summaries, ...filteredRowsToSummaries(filteredRows) }
                      : summaries;

                    return children({
                      activePage: pagination.activePage,
                      itemsPerPage: pagination.itemsPerPage,
                      totalPages: pagination.totalPages,
                      handlePagination: pagination.handlePagination,
                      handleChangeItemsPerPage: pagination.handleChangeItemsPerPage,
                      orderBy: orderGroup.orderBy,
                      orderByType: orderGroup.orderByType,
                      groupBy: orderGroup.groupBy,
                      handleGroupBy: orderGroup.handleGroupBy,
                      handleOrderBy: orderGroup.handleOrderBy,
                      columns,
                      filters,
                      metaData,
                      numberOfHeaderRows,
                      rows,
                      simpleColumns,
                      summaries: summariesComp,
                      toggleColumnVisibility,
                      visibleColumns: visibleColumnsComp,
                      visibleRows: visibleRowsWithHeader || visibleRows,
                      handleGroupByToggle,
                    });
                  }}
                </TablePagination>
              );
            }}
          </OrderGroup>
        )}
      </ColumnToggler>
    );
  }
}
