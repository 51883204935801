import React from 'react';

import { SRangeValue, StyledPercentsValue, SVariantIndication } from './RangeValue.styles';

export type Props = {
  variant: 'above' | 'within' | 'below';
  value?: number | null;
};

/**
 * RangeValue displays a neutral percents value
 * with a left border that matches the provided variant:
 * above: green
 * within: yellow
 * below: red
 */
const RangeValue: React.FC<Props> = ({ variant, value }) => {
  return (
    <SRangeValue>
      <SVariantIndication variant={variant} />
      <StyledPercentsValue label={variant} value={value} precision={1} />
    </SRangeValue>
  );
};

export default RangeValue;
