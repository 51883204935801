import React, { Component } from 'react';
import styled, { css } from 'styled-components/macro';

export const SLegendUl = styled.ul<{ doughnut?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 15%;
  margin: 0;
  padding: 0 2%;
  list-style: none;

  li {
    display: flex;
    justify-content: flex-start;
    line-height: 20px;
    font-size: 12px;
    font-weight: ${({ theme }) => theme.text.weight.medium};

    .color {
      min-width: 20px;
      width: 20px;
      height: 10px;
      margin: 5px 15px -1px 0;
      border-radius: ${({ theme }) => theme.border.radius.medium};
    }

    .value {
      padding-right: 10%;
      text-align: left;
      white-space: nowrap;
      font-weight: ${({ theme }) => theme.text.weight.medium};

      .type {
        color: ${({ theme }) => theme.text.color.light};
      }
    }

    .number {
      flex: 1;
      text-align: right;
    }
  }

  ${({ doughnut }) =>
    doughnut &&
    css`
      padding: 0;
      flex: 1;
      width: 40%;

      li {
        .value {
          padding-right: 0;
          white-space: wrap;
        }
      }
    `}
`;

type Props = {
  chartData: any;
  chartType: string;
  valueFormatter?: any;
};

export class CustomLegend extends Component<Props> {
  static defaultProps = {
    valueFormatter: val => val,
  };

  renderBarLegend() {
    const { chartData } = this.props;

    return (
      <SLegendUl>
        {chartData.datasets.map((d, idx) => {
          const labelSplit = d.label.split('(');
          return (
            <li key={`bar-size-pre-post-off-market-cap-${idx}`}>
              <div className="color" style={{ backgroundColor: d.backgroundColor }} />
              <div className="value">
                <span>{labelSplit[0]}</span>
                {labelSplit[1] && <br />}
                {labelSplit[1] && <span className="type">({labelSplit[1]}</span>}
              </div>
            </li>
          );
        })}
      </SLegendUl>
    );
  }

  renderDoughnutLegend() {
    const { chartData, valueFormatter } = this.props;

    return (
      <SLegendUl doughnut>
        {chartData.labels.map((label, idx) => (
          <li key={idx}>
            <div
              className="color"
              style={{ backgroundColor: chartData.datasets[0].backgroundColor[idx] }}
            />
            <div className="value">{label}</div>
            <div className="number">{valueFormatter(chartData.datasets[0].data[idx])}</div>
          </li>
        ))}
      </SLegendUl>
    );
  }

  render() {
    const { chartType } = this.props;

    if (chartType === 'bar') {
      return this.renderBarLegend();
    } else if (chartType === 'doughnut') {
      return this.renderDoughnutLegend();
    }

    return null;
  }
}
