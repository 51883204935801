import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum AvgFeeByRoleSectorButtons {
  TOTAL_FEES = 'TOTAL_FEES',
  TOTAL_OFFERINGS = 'TOTAL_OFFERINGS',
  AVERAGE_FEE = 'AVERAGE_FEE',
}

export const avgFeeByRoleSectorButtonsConfig: ButtonsConfig = {
  [AvgFeeByRoleSectorButtons.TOTAL_FEES]: { label: 'TOTAL FEES' },
  [AvgFeeByRoleSectorButtons.TOTAL_OFFERINGS]: { label: 'TOTAL OFFERINGS' },
  [AvgFeeByRoleSectorButtons.AVERAGE_FEE]: { label: 'AVERAGE FEE' },
};

export default getButtonConfigArray(avgFeeByRoleSectorButtonsConfig);
