import { ButtonsConfig, getButtonConfigArray } from './buttons.model';

export enum FromFilingLastTradeButtons {
  FROM_FILING = 'FROM_FILING',
  LAST_TRADE = 'LAST_TRADE',
}

export const fromFillingLastTradeButtonsConfig: ButtonsConfig = {
  [FromFilingLastTradeButtons.FROM_FILING]: { label: 'FROM FILING' },
  [FromFilingLastTradeButtons.LAST_TRADE]: { label: 'LAST TRADE' },
};

export default getButtonConfigArray(fromFillingLastTradeButtonsConfig);
