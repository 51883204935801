import { numericUtil, Popover } from '@cmg/common';
import React from 'react';

import PerformancePercents from '../../../common/components/format/performance-percents/PerformancePercents';

export type Props = {
  value: number | null | undefined;
  tooltipValue: number | null | undefined;
  tooltipCurrency: string | null | undefined;
};

const PerformancePercentsRenderer: React.FC<Props> = ({ value, tooltipValue, tooltipCurrency }) => (
  <Popover
    variant="TOOLTIP"
    placement="left"
    content={
      <span>
        {numericUtil.getDisplayValueForCurrency(tooltipValue, 2, tooltipCurrency || undefined)}
      </span>
    }
  >
    <span>
      <PerformancePercents value={value} />
    </span>
  </Popover>
);

export default PerformancePercentsRenderer;
