import { SecondaryButton } from '@cmg/common';
import { clearAllReportToolbarButtonSelector } from '@cmg/e2e-selectors';
import React from 'react';

type Props = { onClick: () => void };

const ClearButton: React.FC<Props> = ({ onClick }) => (
  <SecondaryButton testId={clearAllReportToolbarButtonSelector.testId} onClick={onClick}>
    Clear All
  </SecondaryButton>
);

export default ClearButton;
