import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Collapse, Option } from '@cmg/common';
import { datalabFiltersSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { SponsorOption } from '../../../../types/domain/firm/sponsorOptions';
import { selectUserSettings } from '../../../shared/ducks';
import { useShared_CustomSectorListQuery } from '../../../shared/sectors/graphql/__generated__/Shared_CustomSectors';
import { mapSectorsToOptions } from '../../../shared/sectors/sectors.model';
import { selectFilters } from '../../ducks';
import { DatalabFilterValues, FilterSettings } from '../../types';
import DatalabFilterForm from './DatalabFilterForm';
import { SFiltersWrapper } from './DatalabFilters.styles';

const mapStateToProps = state => ({
  filters: selectFilters(state),
  userSettings: selectUserSettings(state),
});

type OwnProps = {
  filterCount: number;
  advisoryOptions: Option[];
  fundsOptions: Option[];
  sponsorOptions: Option[];
  handleSponsorSearch: (input: string) => Promise<SponsorOption[]>;
  filterSettings: FilterSettings;
  filterDefaultValues: DatalabFilterValues;
  underwriterOptions: Option[];
  spacOptions: Option[];
  onFilterChange: (values: DatalabFilterValues) => void;
  isFilterPanelExpanded: boolean;
  offeringTypesDefaultValues?: {
    value: string;
    label: string;
  }[];
};

export type Props = OwnProps & ReturnType<typeof mapStateToProps>;

export function DatalabFiltersComponent(props: Props) {
  const {
    filters,
    filterCount,
    advisoryOptions,
    fundsOptions,
    sponsorOptions,
    handleSponsorSearch,
    underwriterOptions,
    spacOptions,
    filterSettings,
    filterDefaultValues,
    onFilterChange,
    isFilterPanelExpanded,
    offeringTypesDefaultValues,
  } = props;

  const canReadCustomSectors = useCheckPermissions([permissionsByEntity.CustomSectors.READ]);
  const { data: sectorListData } = useShared_CustomSectorListQuery({
    skip: !canReadCustomSectors,
  });
  const sectors = sectorListData?.customSectors?.items ?? [];

  return (
    <SFiltersWrapper data-test-id={datalabFiltersSelector.testId}>
      <Collapse isExpanded={isFilterPanelExpanded}>
        <DatalabFilterForm
          filters={filters}
          filterCount={filterCount}
          defaultValues={filterDefaultValues}
          advisoryOptions={advisoryOptions}
          filterSettings={filterSettings}
          fundsOptions={fundsOptions}
          sponsorOptions={sponsorOptions}
          handleSponsorSearch={handleSponsorSearch}
          underwriterOptions={underwriterOptions}
          customSectorOptions={mapSectorsToOptions(sectors)}
          spacOptions={spacOptions}
          onChange={onFilterChange}
          offeringTypesDefaultValues={offeringTypesDefaultValues}
        />
      </Collapse>
    </SFiltersWrapper>
  );
}

export default connect(mapStateToProps)(DatalabFiltersComponent);
