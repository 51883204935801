import styled, { css } from 'styled-components/macro';

import { RowProps } from '../../../../../common/components/table/table.styles';

export const SSmallBlurText = styled.div<RowProps>`
  width: 70px;
  height: 12px;
  background-color: #e0e0e0;
  position: relative;
  border-radius: 8px;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  margin-top: 3px;
`;

export const SSmallTotalBlurText = styled.div<RowProps>`
  width: 70px;
  height: 12px;
  background-color: #c2c7cf;
  filter: blur(5px);
  border-radius: 8px;
`;

export const SBlurText = styled.div<RowProps>`
  height: 12px;
  background-color: #e0e0e0;
  position: relative;
  border-radius: 8px;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  margin-top: 3px;
  ${({ align, isTotals }) =>
    css`
      margin-left: ${!align ? '30%' : null};
      margin-right: ${align ? '30%' : null};
      background-color: ${isTotals ? '#c2c7cf' : '#e0e0e0'};
    `}
`;

export const SBlurTotalsText = styled.div`
  filter: blur(3px);
`;
