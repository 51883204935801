import styled, { css } from 'styled-components/macro';

import PercentsValue from '../../../../common/dashboard-value/percents-value/PercentsValue';

export const StyledPercentsValue = styled(PercentsValue)`
  text-align: left;
`;

export const SRangeValue = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  flex-shrink: 0;
`;

export const SVariantIndication = styled.div<{
  variant: 'above' | 'within' | 'below';
}>`
  &::before {
    content: '';
    height: 100%;
    margin: 0 5px;
    display: flex;
    flex-shrink: 0;

    ${({ variant, theme }) =>
      variant === 'above' &&
      css`
        border-left: 5px solid ${theme.brand.color.success};
      `}
    ${({ variant, theme }) =>
      variant === 'within' &&
      css`
        border-left: 5px solid ${theme.brand.color.warning};
      `}
    ${({ variant, theme }) =>
      variant === 'below' &&
      css`
        border-left: 5px solid ${theme.brand.color.danger};
      `}
  }
`;
