import React from 'react';

import routeFactory from '../../../../../common/util/routeFactory';
import { NotificationResourceType } from '../../../../../types/domain/notifications/constants';
import { OfferingType } from '../../../../../types/domain/offering/constants';
import BaseNotification, { BaseNotificationProps, getResourceId } from './BaseNotification';

const NewIoiAddedNotification: React.FC<BaseNotificationProps> = props => {
  const {
    resources,
    publishedBy: { email },
    messageField1: issuer,
    messageField2: offeringType,
    messageField3: ticker,
  } = props.notification;

  // path to specific resource information
  const offeringId = getResourceId(resources, NotificationResourceType.OFFERING);
  const resourcePath = routeFactory.ioi.getUrlPath({ id: offeringId });

  // issuer text previously was stored as different data
  // only display issuer if ticker or offeringType accompany it
  // then we know it should display all three fields instead of title
  const hasMetadata = issuer && (ticker || offeringType) ? true : false;

  return (
    <BaseNotification
      {...props}
      title={hasMetadata ? undefined : 'IOI Entered'} // fallback when info doesn't exist
      ticker={ticker}
      issuer={hasMetadata ? issuer : undefined} // only if ticker or offeringType exist
      offeringType={offeringType as OfferingType}
      message={`IOI entered by ${email}`}
      resourcePath={resourcePath}
    />
  );
};

export default NewIoiAddedNotification;
