import styled from 'styled-components/macro';

export const SCreateUserModalWrapper = styled.div`
  margin: -20px 0;
`;

export const SFormGroup = styled.div`
  padding: 2px 0;
`;

export const SRequired = styled.span`
  color: ${({ theme }) => theme.text.color.negative};
`;

export const SLabel = styled.div`
  font-size: ${({ theme }) => theme.text.size.tiny};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 24px;
  text-transform: uppercase;
`;

export const SCurrentShareUsersWrapper = styled.div`
  padding: 2px 0 10px;
`;

export const SSubtitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
`;
