import { numericUtil } from '@cmg/common';
import React from 'react';

import { SDashboardValueBase, SLabel, SPerformance, SValue } from './DashboardValueBase.styles';

export type Size = 'medium' | 'large' | 'mediumLarge';

type Props = {
  label?: string;
  renderValue: () => React.ReactNode;
  performance?: number | null;
  size?: Size;
  className?: string;
};

/**
 * DashboardValueBase serves as the base component
 * for displayed values on the dashboard
 *
 * DashboardValueBase should not be used directly in presentation components
 * rather it should be used by other specifically defined components
 */
const DashboardValueBase: React.FC<Props> = ({
  renderValue,
  performance,
  label,
  size = 'medium',
  className,
}) => {
  const displayPerformance = React.useMemo(
    () => numericUtil.formatPercents(performance),
    [performance]
  );

  return (
    <SDashboardValueBase className={className}>
      <SValue size={size}>
        {renderValue()}
        {displayPerformance && <SPerformance>{displayPerformance}</SPerformance>}
      </SValue>
      {label && <SLabel>{label}</SLabel>}
    </SDashboardValueBase>
  );
};

export default DashboardValueBase;
