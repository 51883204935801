import { IconButton, Popover } from '@cmg/common';
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components/macro';

export const StyledIconButton = styled(IconButton)`
  &:focus,
  &:active {
    box-shadow: none;
  }
`;

type Props = {
  solid?: boolean;
  onClick: () => void;
  iconName: 'thumbtack' | 'search';
  popoverContent: React.ReactNode;
  className?: string;
};

const CalendarIconButton: React.FC<Props> = ({
  onClick,
  solid,
  iconName: name,
  popoverContent,
  className,
}) => {
  return (
    <ThemeConsumer>
      {theme => (
        <Popover content={popoverContent} placement="top" variant="TOOLTIP">
          <StyledIconButton
            inline
            color={solid ? theme.interactive.primary : theme.background.color.dark}
            onClick={onClick}
            className={className}
            icon={solid ? { name, variant: 'solid' } : { name }}
          />
        </Popover>
      )}
    </ThemeConsumer>
  );
};

export default CalendarIconButton;
