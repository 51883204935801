import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SRadioGroup } from './RadioGroup.styles';

export default class RadioGroup extends Component {
  static propTypes = {
    children: PropTypes.any,
    activeId: PropTypes.string,
    onChange: PropTypes.func,
    canUnselect: PropTypes.bool,
    emptyId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      idOfActive: props.activeId,
    };
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (!isEqual(prevProps, nextProps)) {
      this.setState({
        idOfActive: nextProps.activeId,
      });
    }
  }

  flipRadioButton = id => {
    let nextId = id;

    if (this.props.canUnselect && this.state.idOfActive === id) {
      nextId = this.props.emptyId;
    }

    this.setState({
      idOfActive: nextId,
    });

    this.props.onChange(nextId);
  };

  renderRadios(children) {
    const radios = React.Children.map(children, child =>
      React.cloneElement(child, {
        onChange: this.flipRadioButton,
        active: child.props.id === this.state.idOfActive,
      })
    );

    return radios;
  }

  render() {
    const { children } = this.props;
    return <SRadioGroup>{this.renderRadios(children)}</SRadioGroup>;
  }
}
