import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import HorizontalLine from '../../../common/horizontal-line/HorizontalLine';
import { PeriodColumn, Section } from '../../common/DashboardPeriod';
import FollowOnPricingSummary from '../../components/follow-on-pricing-summary/FollowOnPricingSummary';
import IpoPricingSummary from '../../components/pricing-summary/IpoPricingSummary';
import UsEcm from '../../components/us-ecm/UsEcm';
import ComparisonPeriodControl from '../../controls/components/ComparisonPeriodControl';
import { getComparisonPeriodPresetOptions } from '../../controls/components/periodControls.model';
import {
  selectBuySideComparisonFollowOnPricingSummary,
  selectBuySideComparisonIpoPricingSummary,
  selectBuySideComparisonMyParticipationSummary,
  selectBuySideComparisonPassed,
  selectBuySideComparisonUsEcm,
  selectBuySideLoading,
} from '../ducks';
import LoadingSkeleton from './loading-skeleton/LoadingSkeleton';
import MyDataLabSubscription from './my-datalab-subscription/MyDataLabSubscription';
import MyParticipationSummary from './my-participation-summary/MyParticipationSummary';
import Passed from './passed/Passed';

/**
 * BuySide ComparisonPeriod displays a dashboard period with the data from the comparison
 * selected date range in the ComparisonPeriodControl
 */
const ComparisonPeriod: React.FC = () => {
  const hasMyDatalab = useCheckPermissions([permissionsByEntity.Ioi.READ]);
  const {
    usEcm,
    myParticipationSummary,
    followOnPricingSummary,
    passed,
    ipoPricingSummary,
    loading,
  } = useSelector(
    state => ({
      followOnPricingSummary: selectBuySideComparisonFollowOnPricingSummary(state),
      myParticipationSummary: selectBuySideComparisonMyParticipationSummary(state),
      ipoPricingSummary: selectBuySideComparisonIpoPricingSummary(state),
      passed: selectBuySideComparisonPassed(state),
      usEcm: selectBuySideComparisonUsEcm(state),
      loading: selectBuySideLoading(state),
    }),
    shallowEqual
  );
  const presetOptions = getComparisonPeriodPresetOptions(new Date());

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <PeriodColumn data-test-id={myDashboard.comparisonPeriod.testId}>
      <ComparisonPeriodControl presetOptions={presetOptions} />
      <HorizontalLine />
      <Section>
        <UsEcm data={usEcm} />
      </Section>
      <HorizontalLine />
      <Section>
        <IpoPricingSummary data={ipoPricingSummary} />
      </Section>
      <HorizontalLine />
      <Section>
        <FollowOnPricingSummary data={followOnPricingSummary} />
      </Section>
      <HorizontalLine />
      <MyDataLabSubscription hasMyDatalab={hasMyDatalab}>
        <Section>
          <MyParticipationSummary data={myParticipationSummary} hasMyDatalab={hasMyDatalab} />
        </Section>
        <HorizontalLine />
        <Section>
          <Passed data={passed} hasMyDatalab={hasMyDatalab} />
        </Section>
      </MyDataLabSubscription>
    </PeriodColumn>
  );
};

export default ComparisonPeriod;
