import { numericUtil } from '@cmg/common';
import { GridApi } from 'ag-grid-community';

const round = value => Math.round(value * 100) / 100;

export const calculateDynamicDemandPrices = (
  priceRangeLow: number,
  priceRangeHigh: number,
  increment: number
) => {
  let result: number[] = [];

  if (increment <= 0) {
    return result;
  }

  let price = priceRangeHigh;
  while (price >= priceRangeLow && result.length < 10) {
    result = [price, ...result];
    price = round(price - increment);
  }

  return result;
};

export function countNumericValueFactory(value, precision = 0) {
  return { title: 'COUNT', value: numericUtil.formatNumber(value, precision) };
}

export function sumNumericValueFactory(value, precision = 0) {
  return { title: 'SUM', value: numericUtil.formatNumber(value, precision) };
}

export function sumCurrencyValueFactory(value, precision = 2) {
  return { title: 'SUM', value: numericUtil.formatCurrency(value, precision) };
}

export function sumPercentValueFactory(value) {
  return { title: 'SUM', value: numericUtil.formatPercents(value) };
}

export const getGridDisplayedRows = <TRow extends any>(gridApi?: GridApi): TRow[] => {
  if (gridApi) {
    const count = gridApi.getDisplayedRowCount();
    return [...Array(count)].map((_, index) => gridApi.getDisplayedRowAtIndex(index)?.data);
  }
  return [];
};
