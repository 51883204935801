import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { UsEcm as UsEcmType } from '../../../../../types/domain/my-dashboard/usEcm';
import CurrencyValue from '../../../common/dashboard-value/currency-value/CurrencyValue';
import NumericValue from '../../../common/dashboard-value/numeric-value/NumericValue';
import PerformanceMetricValue from '../../../common/dashboard-value/performance-metric-value/PerformanceMetricValue';
import { SectionHeader } from '../../common/DashboardPeriod';

const SUsEcm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export type Props = {
  data?: UsEcmType;
};

const UsEcm: React.FC<Props> = ({ data }) => {
  return (
    <SUsEcm data-test-id={myDashboard.usEcmSection.testId}>
      <SectionHeader>US ECM</SectionHeader>
      <NumericValue
        value={data?.numberOfOfferings}
        label="# Offerings"
        performance={data?.numberOfOfferingsPctChange}
        size="large"
      />
      <CurrencyValue
        value={data?.capitalRaised}
        label="Capital Raised"
        performance={data?.capitalRaisedPctChange}
        size="large"
      />
      <PerformanceMetricValue value={data?.performance} size="large" />
    </SUsEcm>
  );
};

export default UsEcm;
