import { ButtonGroup, Icon, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import { STogglerIconWrapper, SWrapper } from './Toggler.styles';
import { TogglerButton } from './Toggler.types';

export type Props = {
  activeButton?: TogglerButton;
  buttons: TogglerButton[];
  testId?: string;
  onChange: (button: TogglerButton) => void;
  size?: 'small' | 'medium' | 'large';
  className?: string;
};

/**
 * TODO move to src/common/components/buttons
 */
export default class Toggler extends React.Component<Props> {
  render() {
    const { activeButton, onChange, buttons, testId, size = 'large', className } = this.props;

    return (
      <SWrapper data-test-id={testId} className={className}>
        <ButtonGroup>
          {buttons.map(button => {
            const CommonButton =
              activeButton && activeButton.value === button.value ? PrimaryButton : SecondaryButton;

            return (
              <CommonButton size={size} onClick={onChange.bind(this, button)} key={button.value}>
                {button.icon ? (
                  <STogglerIconWrapper>
                    <Icon name={button.icon} variant="light" size="sm" />
                  </STogglerIconWrapper>
                ) : (
                  button.label
                )}
              </CommonButton>
            );
          })}
        </ButtonGroup>
      </SWrapper>
    );
  }
}
