import React from 'react';
import { connect } from 'react-redux';

import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';
import { User } from '../../../../types/domain/user/user';
import { selectOrganizationUsers } from '../../../shared/ducks';

const mapStateToProps = state => ({
  organizationUsers: selectOrganizationUsers(state),
});

type OwnProps = {
  userReportPartial: UserReportPartial;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

export const MembersCell: React.FC<Props> = ({ userReportPartial, organizationUsers }) => {
  // Use organizationUsers state object to gather user details.
  // It's possible that the report is shared with users who may no
  // longer exist in the sharedReportsOrganizationUsers state object
  // For example, if the current user shares a report with a user who does not
  // have custom sectors and then the current user enables custom sectors,
  // the person whom the report was previously shared with would not
  // exist in state.shared.organization.sharedReportUsers.
  const shareUsers: User[] =
    userReportPartial.share && userReportPartial.share.sharedWith.length > 0
      ? (userReportPartial.share.sharedWith
          .map((userId: string) => organizationUsers.find(user => user.id === userId))
          .filter(user => user !== undefined) as User[])
      : [];

  const members =
    shareUsers.length > 0 &&
    shareUsers
      .slice(0, 2)
      .map(({ username }) => username)
      .join(', ');
  const nMore = shareUsers.length > 2 && shareUsers.length - 2;

  return (
    <td>
      <span>
        {members && members}
        {nMore && <span>, {nMore} more...</span>}
        {!members && 'Only You'}
      </span>
    </td>
  );
};

export default connect<ReturnType<typeof mapStateToProps>, OwnProps>(mapStateToProps)(MembersCell);
