import React from 'react';

import NumericValueRenderer from './NumericValueRenderer';

type Props = {
  value?: number;
};

const IntegerValueRenderer: React.FC<Props> = ({ value }) => (
  <NumericValueRenderer value={value} precision={0} />
);

export default IntegerValueRenderer;
