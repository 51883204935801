import { Tag } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const StyledTag = styled(Tag)`
  background-color: ${({ theme }) => theme.brand.color.successAlternate};
  border-radius: ${({ theme }) => theme.border.radius.large};
  font-size: ${({ theme }) => theme.text.size.tiny};
  margin-left: 8px;
  padding: 0 5px;
`;

/**
 * Renders a stylized design system 'Tag' with label.
 *
 * @usage Display this tag on any feature (e.g. in a nav link, on a page) whenever a feature
 *  is determined by product and design as being in 'beta' mode or is a 'new' feature.
 */
type Props = {
  label?: string;
};
const LabeledTag: React.FC<Props> = ({ label = '' }) => <StyledTag>{label}</StyledTag>;

export default LabeledTag;
