import React from 'react';

import { NotificationActionType } from '../../../../types/domain/notifications/constants';
import { BaseNotificationProps } from './action-types/BaseNotification';
import FinalAllocationEntered from './action-types/FinalAllocationEnteredNotification';
import NewIoiAdded from './action-types/NewIoiAddedNotification';
import NewOfferingNote from './action-types/NewOfferingNoteNotification';
import NewSharedReport from './action-types/NewSharedReportNotification';

const NotificationItem: React.FC<BaseNotificationProps> = props => {
  const { actionType } = props.notification;

  switch (actionType) {
    case NotificationActionType.NEW_REPORT_SHARED:
      return <NewSharedReport {...props} />;
    case NotificationActionType.NEW_OFFERING_NOTE_SHARED:
      return <NewOfferingNote {...props} />;
    case NotificationActionType.NEW_IOI_ADDED:
      return <NewIoiAdded {...props} />;
    case NotificationActionType.FINAL_ALLOCATION_ENTERED:
      return <FinalAllocationEntered {...props} />;
    default:
      return null;
  }
};

export default NotificationItem;
