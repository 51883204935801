export const toastMessages = {
  error: {
    saveAllocation: 'Allocation failed to save.',
    saveFund: 'Fund failed to save.',
    saveRole: 'Please review and address the messages on screen before saving.',
    saveFirm: 'Please review and address the messages on screen before saving.',
    saveUser: 'Please review and address the messages on screen before saving.',
    saveContact: 'Please review and address the messages on screen before saving.',
    saveTeam: 'Please review and address the messages on screen before saving.',
    saveConflict: 'Please review and address the messages on screen before saving.',
    updateOfferingEditor: 'Please review and address the messages on screen before saving.',
    updateIoiFunds: 'Please review and address the messages on screen before saving.',
    errorSaving: 'Error saving.',
    errorDeleting: 'Error deleting',
    notifications: {
      markAllAsReadFailed: 'Marking all notifications as read failed.',
    },
    updatedReportSharingSettings: 'Error updating report sharing settings.',
  },
  success: {
    saveAllocation: 'Allocation saved.',
    saveFund: 'Fund saved.',
    deleteRole: 'Role successfully deleted.',
    saveRole: 'Role successfully saved.',
    saveRoleNew: 'New role successfully added.',
    deleteUser: 'User successfully deleted.',
    saveUser: 'User successfully saved.',
    saveUserNew: 'New user successfully added.',
    saveContact: 'Contact successfully saved.',
    saveContactNew: 'New contact successfully saved.',
    sendWelcomeEmail: 'A welcome email has been resent.',
    deleteFirm: 'Firm successfully deleted.',
    saveFirm: 'Firm successfully saved.',
    saveFirmNew: 'New firm successfully added.',
    saveFirmRevision: 'Revision successfully saved.',
    deleteTeam: 'Team successfully deleted.',
    saveTeam: 'Team successfully saved.',
    saveTeamNew: 'New team successfully added.',
    saveConflict: 'Conflict successfully saved.',
    saved: 'Saved.',
    archivedMessage: 'Message successfully archived.',
    unarchivedMessage: 'Message successfully unarchived.',
    sendSyndicateInvites: 'Syndicate invites sent.',
    acceptSyndicateInvite: 'Invite accepted.',
    rejectSyndicateInvite: 'Invite rejected.',
    profileShared: 'Profile shared.',
    updateIoiFunds: 'Indication of interest Funds is successfully saved',
    createdReport: 'Report created.',
    updatedReport: 'Report saved.',
    updatedReportSharingSettings: 'Report sharing settings updated.',
    reportFavorited: 'Report added to favorites.',
    reportUnfavorited: 'Report removed from favorites.',
    saveCustomSector: 'Custom sector saved',
    saveCustomSubSector: 'Custom sub-sector saved',
    deleteCustomSector: 'Custom sector successfully deleted',
    deleteCustomSubSector: 'Custom sub-sector successfully deleted',
  },
};
