import styled from 'styled-components/macro';

type Props = {
  alignAll?: string;
  alignFirstColumn?: string;
  alignLastColumn?: string;
};

export const PopoverContentTable = styled.table<Props>`
  max-width: 700px;
  font-size: ${({ theme }) => theme.text.size.medium};

  thead tr th,
  tr td {
    text-align: left;
    white-space: nowrap;
    padding: 0 5px;
    font-size: ${({ theme }) => theme.text.size.medium};
    text-align: ${({ alignAll }) => alignAll || 'left'};

    &:first-child {
      padding-left: 0;
      text-align: ${({ alignFirstColumn, alignAll }) => alignFirstColumn || alignAll || 'left'};
    }

    &:last-child {
      padding-right: 0;
      text-align: ${({ alignLastColumn, alignAll }) => alignLastColumn || alignAll || 'left'};
    }
  }

  thead tr th {
    color: ${({ theme }) => theme.color.gray1};
    font-weight: ${({ theme }) => theme.text.weight.medium};
    font-size: ${({ theme }) => theme.text.size.small};
    text-transform: uppercase;
    vertical-align: middle;
  }
`;

export default PopoverContentTable;
